import React, { useState } from 'react';

import './CdsTriggerModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as ApiCalls from '../../../../../api/ApiCalls';
import { toast } from '../../../../../helpers/ToastUtils';
import { useIsMounted } from '../../../../../helpers/useIsMounted';
import { LoadingSpinner } from '../../../../common/LoadingSpinner/LoadingSpinner';
import { CdsJobTypeSelector } from '../_base/CdsJobTypeSelector';
import { CdsConfigurationForm } from '../_forms/CdsConfigurationForm';

const TriggerPhases = {
  typeSelection: 'typeSelection',
  configuration: 'configuration',
};

const CdsTriggerModal = ({ job, setIsVisible, onSuccess }) => {
  const isMounted = useIsMounted();
  const [selectedType, setSelectedType] = useState(null);
  const [currentPhase, setCurrentPhase] = useState(TriggerPhases.typeSelection);
  const [formInvalid, setFormInvalid] = useState(false);
  const [config, setConfig] = useState(job.config_data);
  const [isLoading, setIsLoading] = useState(false);
  const getButtonLabel = (which) => {
    if (which === 'Next') {
      return currentPhase === TriggerPhases.typeSelection ? 'Next' : 'Run';
    }
    return currentPhase === TriggerPhases.typeSelection ? 'Cancel' : 'Back';
  };

  const getNextButtonIcon = () => {
    return currentPhase === TriggerPhases.typeSelection ? (
      <FontAwesomeIcon icon={['far', 'cogs']} />
    ) : (
      <FontAwesomeIcon icon={['far', 'analytics']} />
    );
  };

  const handleCreateBot = () => {
    setIsLoading(true);
    const reqData = {
      file_name: job.additional_data[0].file_name,
      config_data: config,
      subtype: selectedType,
    };

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/bots/process-job/${job.id}`,
      data: reqData,
      onSuccess: (res) => {
        if (res) {
          toast.success('Cds Bot started...');
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
          if (typeof setIsVisible === 'function') {
            setIsVisible(false);
          }
        }
        if (isMounted.current) {
          setIsLoading(false);
        }
      },
      onError: (res) => {
        if (res?.data?.message) {
          toast.error(res.data.message);
        }
        if (isMounted.current) {
          setIsLoading(false);
        }
      },
      onEnd: () => {
        if (typeof setVisible === 'function') {
          setIsVisible(false);
        }
        if (isMounted.current) {
          setIsLoading(false);
        }
      },
    });
  };
  const handleNext = () => {
    if (currentPhase === TriggerPhases.typeSelection) {
      setCurrentPhase(TriggerPhases.configuration);
    } else {
      handleCreateBot();
    }
  };

  const handleBack = () => {
    if (currentPhase === TriggerPhases.configuration) {
      setCurrentPhase(TriggerPhases.typeSelection);
      setFormInvalid(false);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <>
      <section className="cds-trigger-modal-body">
        {currentPhase === TriggerPhases.typeSelection && (
          <CdsJobTypeSelector selectedType={selectedType} setSelectedType={setSelectedType} />
        )}
        {currentPhase === TriggerPhases.configuration && (
          <CdsConfigurationForm
            botJob={job}
            selectedMode={selectedType}
            setIsInvalid={setFormInvalid}
            setConfig={setConfig}
          />
        )}
      </section>
      <section className="cds-trigger-modal-actions">
        <button
          type="button"
          className="btn btn-link btn-cancel"
          onClick={handleBack}
          disabled={isLoading}
          title={getButtonLabel('Cancel')}
        >
          {getButtonLabel('Cancel')}
        </button>
        <button
          type="button"
          className="btn btn-primary btn-assess"
          onClick={handleNext}
          disabled={
            isLoading ||
            (currentPhase === TriggerPhases.typeSelection && !selectedType) ||
            formInvalid
          }
          title={getButtonLabel('Next')}
        >
          {isLoading ? <LoadingSpinner fast /> : getNextButtonIcon()} {getButtonLabel('Next')}
        </button>
        {formInvalid && (
          <p className="form-invalid">
            Your config is invalid. Please apply the necessary corrections before proceeding.
          </p>
        )}
      </section>
    </>
  );
};

export { CdsTriggerModal };
