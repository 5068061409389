import './SheetTabRows.scss';
import React, { useState, useEffect, useCallback, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Logo from 'assets/img/BackboneAI-Short-logo.svg';
import { TableScroller } from 'components/common/TableScroller/TableScroller';
import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { TutorialSheetRows } from 'components/data-ingestion/Tutorial/TutorialSheetRows';
import { TUTORIALSTEPS } from 'constants/DataIngestionConstants';
import styleVars from 'scss/vars.scss';

/**
 * Creates a table for displaying and interacting with sheet data for the first step in data ingestion
 *
 * @param {*} activeSheetData json with sheet data from /attachments/sheets
 * @param {*} goToNextSheet function to execute after the current sheet is finished
 * @returns object
 */

const SheetTabRows = ({
  activeSheetData,
  goToNextSheet,
  updateRows,
  isLastSheet,
  restartTutorial,
  doStartOnboarding,
  completedSheets,
  activeTab,
  userIsBacktracking,
}) => {
  const [header, setHeader] = useState(null);
  const [firstRow, setFirstRow] = useState(null);
  const [scroll, setScroll] = useState(0);
  const { tutorial, rowStep, setRowStep, isLoading } = useContext(DataIngestionContext);

  useEffect(() => {
    setRowStep(0);
    setHeader(activeSheetData.header_idx);
    setFirstRow(activeSheetData.data_idx);
  }, [activeSheetData]);

  const rowClass = (index) => {
    if (index === header) {
      if (tutorial.rowTutorialDone === false) {
        if (tutorial.step === TUTORIALSTEPS.HEADER) {
          return 'selected-header tutorial-row-highlight';
        }
        return 'confirmed-header';
      }
      if (rowStep === 0) {
        return 'selected-header';
      }
      return 'confirmed-header';
    }
    // Highlight both header and first data row if previously verified or if backtracking
    if (
      index === firstRow &&
      (rowStep === 1 || completedSheets.includes(activeTab) || userIsBacktracking)
    ) {
      if (tutorial.rowTutorialDone === false && tutorial.step === TUTORIALSTEPS.FIRST_DATA_ROW) {
        return 'selected-first-row tutorial-row-highlight';
      }
      return 'selected-first-row';
    }
    return null;
  };

  const handleScroll = useCallback(() => {
    setScroll(document.getElementById('table-wrapper').scrollLeft);
    const canScroll =
      document.getElementById('table-wrapper').clientWidth <
      document.getElementById('table-wrapper').scrollWidth;
    const scrollRemaining =
      document.getElementById('table-wrapper').scrollWidth -
      scroll -
      document.getElementById('table-wrapper').offsetWidth;
    if (canScroll && scrollRemaining > 10) {
      document.getElementById('table-shadow-right').style.display = 'block';
    } else {
      document.getElementById('table-shadow-right').style.display = 'None';
    }
  }, [scroll]);

  // set height and position of the shadow
  useEffect(() => {
    const shadowLocation =
      document.getElementsByClassName('scroll-right')[0].getBoundingClientRect().left - 21;
    const shadow = document.getElementById('table-shadow-right');
    shadow.style.left = `${shadowLocation}px`;
    shadow.style.height = `${document.getElementById('table-wrapper').clientHeight}px`;
  }, [scroll]);

  // set height and position of the labels
  useEffect(() => {
    const labelBB = document.getElementById('label-backbone');
    const labelCheck = document.getElementById('label-check');
    const table = document.getElementById('table-ingestion-rows');
    const headerRow = document.getElementById(`row-${header}`);
    const bbFirstRow = document.getElementById(`row-${firstRow}`);
    const doubleScroll = document.getElementById('double-scroll');

    if (headerRow && doubleScroll) {
      if (rowStep === 0) {
        const bbLocation =
          headerRow.getBoundingClientRect().top -
          table.getBoundingClientRect().top +
          doubleScroll.getBoundingClientRect().height;
        labelBB.style.top = `${bbLocation}px`;
        labelBB.style.height = `${headerRow.getBoundingClientRect().height}px`;
        labelBB.style.right = `${labelBB.getBoundingClientRect().width}px`;
      } else {
        const checkLocation =
          headerRow.getBoundingClientRect().top -
          table.getBoundingClientRect().top -
          `${bbFirstRow.getBoundingClientRect().height}` +
          doubleScroll.getBoundingClientRect().height;
        labelCheck.style.top = `${checkLocation}px`;
        labelCheck.style.height = `${headerRow.getBoundingClientRect().height}px`;
        labelCheck.style.right = `${labelCheck.getBoundingClientRect().width}px`;

        const bbLocation =
          bbFirstRow.getBoundingClientRect().top -
          table.getBoundingClientRect().top +
          doubleScroll.getBoundingClientRect().height;
        labelBB.style.top = `${bbLocation}px`;
        labelBB.style.height = `${bbFirstRow.getBoundingClientRect().height}px`;
        labelBB.style.right = `${labelBB.getBoundingClientRect().width}px`;
      }
    }
  }, [rowStep, header, firstRow, activeSheetData]);

  const DoubleScroll = (element) => {
    if (document.getElementById('double-scroll') === null) {
      const scrollbar = document.createElement('div');
      scrollbar.appendChild(document.createElement('div'));
      scrollbar.id = 'double-scroll';
      scrollbar.style.overflow = 'scroll';
      scrollbar.style.overflowY = 'hidden';
      scrollbar.style.width = '100%';
      scrollbar.firstChild.style.width = `${element.firstChild.scrollWidth}px`;
      scrollbar.firstChild.style.height = '0em';
      scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
      scrollbar.onscroll = function () {
        element.scrollLeft = scrollbar.scrollLeft;
      };
      element.onscroll = function () {
        scrollbar.scrollLeft = element.scrollLeft;
      };
      element.parentNode.insertBefore(scrollbar, element);
    } else {
      document.getElementById(
        'double-scroll'
      ).firstChild.style.width = `${element.firstChild.scrollWidth}px`;
    }
  };

  useEffect(() => {
    if (!isLoading) {
      DoubleScroll(document.getElementById('table-wrapper'));
    }
  }, [isLoading]);

  const scrollJumpPoints = [];

  useEffect(() => {
    handleScroll();
    const cells = document.getElementById('row-0').children;
    for (let i = 2; i < cells.length; i++) {
      scrollJumpPoints.push(cells[i].offsetLeft - cells[0].width - cells[1].width);
    }
  }, [handleScroll, scrollJumpPoints]);

  // reset scroll on tab change
  useEffect(() => {
    setScroll(0);
    document.getElementById('table-wrapper').scrollLeft = 0;
  }, [activeTab]);

  const doVerify = (index) => {
    if (rowStep === 0) {
      const afterUpdate = () => {
        setRowStep(1);
      };
      updateRows('header_idx', [index, firstRow], false, afterUpdate());
    } else {
      updateRows('data_idx', [header, index], false);
    }
  };

  const setRow = (index) => {
    if (rowStep === 0) {
      setHeader(index);
      doVerify(index);
      if (firstRow <= index) {
        setFirstRow(index + 1);
      }
    } else {
      setFirstRow(index);
      doVerify(index);
    }
  };

  const doInteract = (index, e) => {
    if (e.type === 'click' || e.keyCode === 32 || e.keyCode === 13) {
      setRow(index);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <>
      {tutorial.rowTutorialDone === false ? (
        <div id="modal-container">
          <TutorialSheetRows
            target="main-table"
            doVerify={() => doVerify()}
            isLastSheet={isLastSheet}
            activeSheetData={activeSheetData}
            restartTutorial={() => restartTutorial()}
            doStartOnboarding={() => doStartOnboarding()}
            setRowStep={(e) => setRowStep(e)}
          />
        </div>
      ) : null}

      <div className={`sheet-table-actions-rows step-${rowStep}`}>
        <div className="actions-header">
          <div className="actions-icon fa-layers fa-fw">
            <FontAwesomeIcon className="highlight-action" icon={['fas', 'circle']} size="lg" />
            <FontAwesomeIcon
              style={{ color: `${styleVars.colors_designWhite}` }}
              icon={['fas', 'long-arrow-down']}
              size="sm"
            />
          </div>
          {rowStep === 0 ? (
            <>
              Click the <span className="highlight-action">&nbsp; Header Row &nbsp;</span>
            </>
          ) : (
            <>
              Click the <span className="highlight-action">&nbsp; First Data Row &nbsp;</span>
            </>
          )}
          {/* of <div className="highlight-sheet">{activeSheetData.name}</div>  */}
          to {completedSheets.includes(activeTab) ? 'reselect' : 'confirm'} the
          <img
            className="logo-tmp"
            src={Logo}
            alt="Logo"
            height="30"
            width="30"
            style={{ padding: '4px' }}
          />{' '}
          AI prediction.
        </div>
        <div className="actions-scroll">
          {!isLoading ? (
            <TableScroller
              wrapper="table-wrapper"
              table=""
              row="row-0"
              scroll={scroll}
              setScroll={(e) => setScroll(e)}
            />
          ) : null}
        </div>
      </div>
      <div style={{ display: 'flex' }} className="ingestion-rows">
        <div className="label-wrapper">
          <div
            className={`label-backbone ${rowStep === 0 ? 'header-prediction' : 'row-prediction'}`}
            id="label-backbone"
          >
            <img className="logo-tmp" src={Logo} alt="Logo" height="20" width="20" />
          </div>
          {rowStep === 1 ? (
            <div className="label-check" id="label-check">
              <FontAwesomeIcon className="checked" icon={['fas', 'check-circle']} size="sm" />
            </div>
          ) : null}
        </div>
        <div className="table-wrapper" id="table-wrapper" onScroll={() => handleScroll()}>
          <table id="table-ingestion-rows" className="table-ingestion-rows">
            <tbody>
              {activeSheetData.smart_sample.smart_head.map((row, index) =>
                rowStep === 1 && index === header ? (
                  <tr id={`row-${index}`} className={rowClass(index)} key={`row-${index}`}>
                    {row.map((cell, index) => (
                      <td key={`${row}-${cell}-${index}`}>
                        <div className="cell-wrapper">
                          <div className="cell-cutoff">{cell}</div>
                        </div>
                      </td>
                    ))}
                  </tr>
                ) : (
                  <tr
                    id={`row-${index}`}
                    className={rowClass(index)}
                    key={`row-${index}`}
                    tabIndex={0}
                    onClick={(e) => doInteract(index, e)}
                    onKeyDown={(e) => doInteract(index, e)}
                  >
                    {row.map((cell, index) => (
                      <td key={`${row}-${cell}-${index}`}>
                        <div className="cell-wrapper">
                          <div className="cell-cutoff">{cell}</div>
                        </div>
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div className="table-shadow-right" id="table-shadow-right" />
      </div>
    </>
  );
};

SheetTabRows.propTypes = {
  activeSheetData: PropTypes.object.isRequired,
  goToNextSheet: PropTypes.func.isRequired,
};

export { SheetTabRows };
