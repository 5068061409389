import React, { useEffect, useReducer, useState } from 'react';

import PropTypes from 'prop-types';

import { HeaderSelector } from '../../../../databots/bots/DescriptionBuilderBot/private/HeaderSelector';
import { MappingComponent } from '../../../../databots/bots/GptGeneratorBot/private/MappingComponent';
import { BotFormLabel } from '../../_components/BotFormLabel';
import { CdsModels } from '../../CdsModels';

const ColumnStateEvents = {
  SELECTED_HEADERS_CHANGED: 'SELECTED_HEADERS_CHANGED',
  HEADER_MAPPING_CHANGED: 'HEADER_MAPPING_CHANGED',
};

const adjustColumnChoices = (state) => {
  const mappingValues = Object.values(state.headerMapping).reduce((acc, value) => {
    if (Array.isArray(value)) {
      return [...acc, ...value];
    }
    return [...acc, value];
  }, []);

  const additionalHeaderValues = state.additionalHeaders.map((header) => header.value);
  return {
    ...state,
    headerChoices: state.initialColumns?.filter((header) => !mappingValues.includes(header)),
    headerMappingChoices: state.initialColumns
      ?.filter((header) => !additionalHeaderValues.includes(header))
      .map((header) => ({ label: header, value: header })),
  };
};
const initializeFormState = ({ state, columns }) => {
  const newState = {
    initialColumns: [...columns],
    additionalHeaders: (state.additional_headers || []).map((header) => ({
      label: header,
      value: header,
    })),
    headerMapping: state.header_mapping || {},
    headerChoices: [...columns],
    headerMappingChoices: columns.map((header) => ({ label: header, value: header })),
  };
  return adjustColumnChoices(newState);
};

const handleFormStateUpdate = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case ColumnStateEvents.SELECTED_HEADERS_CHANGED:
      newState = {
        ...state,
        additionalHeaders: payload || [],
      };
      break;
    case ColumnStateEvents.HEADER_MAPPING_CHANGED:
      newState = {
        ...state,
        headerMapping: payload || {},
      };
      break;
    default:
      newState = state;
      break;
  }

  newState = adjustColumnChoices(newState);

  return newState;
};

/**
 * Component that allows the user to define configure the Sources for the CDS tool
 *
 * @param columns {string[]} - The list of columns to map
 * @param state {{additional_headers: string[], header_mapping: Object}} - The current state
 * @param setState {function} - Callback to set the state
 * @param mandatoryColumns {string[]} - The list of mandatory columns
 * @param disabled {boolean} - If true, the component is disabled
 * @param setInvalid {function} - Callback to set if the component is invalid
 * @param mappingOptions {Object} - Column mapping options object
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CdsGptSourcesForm = ({
  columns,
  state,
  setState,
  mandatoryColumns = [],
  disabled = false,
  setInvalid = undefined,
  mappingOptions = {},
}) => {
  const [mappingValid, setMappingValid] = useState(true);
  const [formState, onFormStateUpdated] = useReducer(
    handleFormStateUpdate,
    { state, columns },
    initializeFormState
  );

  useEffect(() => {
    setState({
      additional_headers: formState.additionalHeaders.map((header) => header.value),
      header_mapping: formState.headerMapping,
    });
  }, [formState, setState]);

  useEffect(() => {
    if (setInvalid) {
      setInvalid(!disabled && (!mappingValid || formState.headerMapping?.length === 0));
    }
  }, [formState.headerMapping, disabled, mappingValid, setInvalid]);

  return (
    <>
      <section className="header-mapping">
        <div className="content">
          <BotFormLabel
            label="Column Mapping"
            type="subsection"
            tooltip={CdsModels.CDS_TOOL_TIP.columnMapping}
          />
          <MappingComponent
            mapping={formState.headerMapping}
            targets={mandatoryColumns}
            choices={formState.headerMappingChoices}
            setMapping={(mapping) => {
              onFormStateUpdated({
                type: ColumnStateEvents.HEADER_MAPPING_CHANGED,
                payload: mapping,
              });
            }}
            disabled={disabled}
            setInvalid={(v) => {
              if (!v !== mappingValid) {
                setMappingValid(!v);
              }
            }}
            targetOptions={mappingOptions}
          />
        </div>
      </section>

      <section className="header-selection">
        <div className="content">
          <BotFormLabel
            label="Additional Columns"
            type="subsection"
            tooltip={CdsModels.CDS_TOOL_TIP.additionalColumns}
          />
          <HeaderSelector
            selectedHeaders={formState.additionalHeaders}
            setSelectedHeaders={(v) => {
              onFormStateUpdated({
                type: ColumnStateEvents.SELECTED_HEADERS_CHANGED,
                payload: v,
              });
            }}
            headerChoices={formState.headerChoices}
            disabled={disabled}
            canMove={false}
            showOrderControl={false}
          />
        </div>
      </section>
    </>
  );
};

CdsGptSourcesForm.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  state: PropTypes.shape({
    additional_headers: PropTypes.arrayOf(PropTypes.string),
    header_mapping: PropTypes.object,
  }).isRequired,
  setState: PropTypes.func.isRequired,
  mandatoryColumns: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  setInvalid: PropTypes.func,
  mappingOptions: PropTypes.object,
};

CdsGptSourcesForm.defaultProps = {
  mandatoryColumns: [],
  disabled: false,
  setInvalid: undefined,
  mappingOptions: {},
};

export { CdsGptSourcesForm };
