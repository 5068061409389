import React from 'react';

import './Card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Card.
 *
 * @param {Object} props the prop object
 * @param {Object} props.data Data object with icon, title and subtitle attributes
 * @param {*} props.children If children is passed, they're directly rendered instead of data
 * @param {Function} props.onClick the onClick event handler
 * @param {Object} props.style the style to be applied to the card in addition to in place styling
 * @param {Object} props.as Node element to wrap the card in
 */
const Card = ({ data, children, onClick, style, className, as = <article /> }) => {
  const renderContent = () => {
    let output = null;
    if (children) {
      output = children;
    } else if (data) {
      let icon = null;

      if (data.icon) {
        if (Array.isArray(data.icon) && data.icon.length === 2) {
          icon = <FontAwesomeIcon icon={data.icon} />;
        } else {
          icon = data.icon;
        }
      }
      output = (
        <div className="bai-card-body">
          {icon && <div className="bai-card-icon">{icon}</div>}
          {data.title && <h4 className="bai-card-header">{data.title}</h4>}
          {data.subtitle && <p className="bai-card-description">{data.subtitle}</p>}
        </div>
      );
    }
    return output;
  };

  return (
    <as.type
      className={classNames('bai-card', className, {
        clickable: !!onClick,
      })}
      onClick={onClick}
      style={style}
    >
      {renderContent()}
    </as.type>
  );
};

Card.defaultProps = {
  data: null,
  style: null,
  children: null,
  onClick: null,
  className: '',
};

Card.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  style: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export { Card };
