import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortableHandle } from 'react-sortable-hoc';
import './TableRow.scss';

const RowHandler = SortableHandle(() => (
  <div className="drag-handler">
    <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
    <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
  </div>
));

const TableRow = ({
  data,
  headers,
  order,
  moveUp,
  moveDown,
  deleteRow,
  firstRow,
  lastRow,
  actions,
}) => {
  const cells = headers.map((header) => {
    return <td key={header?.key}>{data[header?.key]}</td>;
  });

  return (
    <tr className="draggable-row">
      <td>
        <div className="firstElement">
          <RowHandler />
          {order + 1}
        </div>
      </td>
      {cells}
      {actions?.delete && actions?.move ? (
        <td>
          <div className="table-actions">
            <div className="action-move-row">
              {actions?.move ? (
                <>
                  <button
                    type="button"
                    className="btn btn-inherit"
                    onClick={() => moveUp(order)}
                    disabled={firstRow}
                  >
                    <FontAwesomeIcon icon={['fas', 'long-arrow-up']} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-inherit"
                    onClick={() => moveDown(order)}
                    disabled={lastRow}
                  >
                    <FontAwesomeIcon icon={['fas', 'long-arrow-down']} />
                  </button>
                </>
              ) : null}
            </div>
            <div className="action-delete-row">
              {actions?.delete && (
                <button type="button" className="btn btn-inherit" onClick={() => deleteRow(order)}>
                  <FontAwesomeIcon icon={['fas', 'trash']} />
                </button>
              )}
            </div>
          </div>
        </td>
      ) : null}
    </tr>
  );
};

export { TableRow };
