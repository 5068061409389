import React from 'react';

import PropTypes from 'prop-types';

import { getIconWithPopover } from '../../../../helpers/Utils';

import './BotFormLabel.scss';

const BotFormLabel = ({ label, required, tooltip = undefined, type = 'field' }) => {
  return (
    <div className={`config-field-label type--${type}`}>
      <span className="config-field-label__label">
        {label}
        {required && <span className="config-field-label__required">*</span>}
      </span>
      {tooltip && (
        <span className="config-field-label__tooltip">
          {getIconWithPopover({
            ...tooltip,
          })}
        </span>
      )}
    </div>
  );
};

BotFormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.object,
  type: PropTypes.oneOf(['field', 'section', 'subsection']),
};

BotFormLabel.defaultProps = {
  required: false,
  tooltip: undefined,
  type: 'field',
};

export { BotFormLabel };
