console.warn("Loaded Mockup Data module! You should not see this message in production!");

/**
 * Mockup data router function. Receives same args as doCall(args)
 * and executes the specific data generator for mockupDataId.
 *
 * Generator should export a single function that receives args
 * and calls one of the request methods onSuccess, onError, etc.
 *
 * Generator filename name should match the mockupDataId.
 */
export const generateData = (args) => {
  let isSuccess = false;

  if (args && args.mockupDataId) {
    try {
      const dataGenerator = require(`./generators/${args.mockupDataId}`);
      if (dataGenerator && typeof dataGenerator.default === "function") {
        dataGenerator.default(args);
        isSuccess = true;
      }
    } catch (e) {}
  }
  if (!isSuccess) {
    console.error("Unsuccessful mockup data generation", args);
    if (args && typeof args.onError === "function") {
      args.onError({ message: `No mockup generator exists for ${args.mockupDataId || "?"}` });
    }
  }
};
