import './DataJobDetailsInput.scss';

import React from 'react';

import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';

import UserRoleConstants from 'constants/UserRoleConstants';

import { PartySelectAdmin } from './PartySelectAdmin';
import { PartySelectUser } from './PartySelectUser';

const DataJobParties = ({ details, invalidCheck, setDetails, currentUser }) => {
  return (
    <section className="data-job-details-selection-wrapper">
      <Form noValidate>
        {currentUser.role === UserRoleConstants.ADMIN ? (
          <PartySelectAdmin
            details={details}
            invalidCheck={invalidCheck}
            setDetails={setDetails}
            currentUser={currentUser}
          />
        ) : (
          <PartySelectUser
            details={details}
            invalidCheck={invalidCheck}
            setDetails={setDetails}
            currentUser={currentUser}
          />
        )}
      </Form>
    </section>
  );
};

DataJobParties.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  setDetails: PropTypes.func.isRequired,
  invalidCheck: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
};

export { DataJobParties };
