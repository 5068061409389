import React, { useState, useEffect, useCallback } from 'react';

import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import useBus from 'use-bus';
import { NumberParam, useQueryParams } from 'use-query-params';

import * as ApiCalls from 'api/ApiCalls';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import { EventTypeConstants } from '../../constants/EventTypeConstants';
import { CdsTriggerModal } from './_private/_cds_configs/_modals/CdsTriggerModal';
import { ContentBotEngineLogTable } from './_private/ContentBotEngineLogTable';
import { NewCdsJobSection } from './_private/NewCdsJobSection';

import './ContentBotEngineDashboard .scss';
import { BotModalHeader } from './_private/_components/BotModalHeader';
/**
 * Dashboard panel for IDS jobs. Renders log table and optionally a creation form
 *
 * @param {boolean} enableUploader Show creation form
 * @param {integer} pageSize Page size, used in pagination
 * @param {integer} itemsLimit Row limit for log table
 * @param {function} onSetIsLoading Global loader setter
 * @param showGetStarted Whether to show the "Get Started" button
 * @param enableFilters Whether to show the filters
 *
 * @return render
 */
const ContentBotEngineDashboard = ({
  enableUploader,
  pageSize,
  onSetIsLoading,
  itemsLimit,
  showGetStarted = false,
}) => {
  const [, setIsNewJobInProgress] = useState(false);
  const [botJob, setBotJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [logData, setLogData] = useState(null);
  const [isIdsModalOpen, setIsIdsModalOpen] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const [query, setQuery] = useQueryParams({ page: NumberParam });

  const history = useHistory();
  const location = useLocation();
  const isMounted = useIsMounted();

  const [tableState, setTableState] = useState({
    page: query.page || 1,
    total: 0,
    pageSize: pageSize > 0 ? pageSize : 2,
    itemsLimit,
  });

  const doLoadLogData = useCallback(
    (timeoutId = undefined, _isModalOpen = false) => {
      // const searchInFocus = document.activeElement === searchFieldRef.current;
      setIsLoading(true);
      const page = query.page || tableState.page;

      const reqParams = {
        limit: tableState.pageSize,
        offset: page ? (page - 1) * tableState.pageSize : 0,
      };

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/bots/process-job/log-table/cds`,
        params: reqParams,
        onSuccess: (res) => {
          if (isMounted.current && !_isModalOpen) {
            if (res?.data?.results?.length) {
              setLogData(res?.data?.results);
              setTableState((t) => ({
                pageSize: t.pageSize,
                page: query.page || t.page,
                total: res?.data?.total || 0,
                itemsLimit: itemsLimit || res?.data?.total || 0,
              }));
            } else {
              setLogData(null);
            }
          }
        },
        onEnd: () => {
          if (isMounted.current) {
            setIsLoading(false);
            if (timeoutId !== undefined) {
              clearTimeout(timeoutId);
            }
          }
        },
      });
    },
    [isMounted, query.page, tableState.page, tableState.pageSize, itemsLimit]
  );

  const handleTableStateChanged = (page, pageSize) => {
    setQuery({ ...query, page });
    setTableState({ page, pageSize, total: tableState.total, itemsLimit });
  };

  const resetTableState = () => {
    if (tableState.page === 1) {
      doLoadLogData();
    }
    handleTableStateChanged(1, tableState.pageSize);
  };

  useEffect(() => {
    doLoadLogData();
  }, [doLoadLogData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isIdsModalOpen && !isLoading) {
        doLoadLogData(timer, isIdsModalOpen);
      }
    }, 30 * 1000);
    return () => clearInterval(timer);
  }, [doLoadLogData, isLoading, isIdsModalOpen]);

  useEffect(() => {
    if (typeof onSetIsLoading === 'function') onSetIsLoading(!!isLoading);
  }, [isLoading, onSetIsLoading]);

  const onCancelJob = ({ jobId }) => {
    if (isLoadingAction) {
      return;
    }
    setIsLoadingAction(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/bots/process-job/cancel/${jobId}`,
      onSuccess: (res) => {
        if (res?.data) {
          toast.info('CDS job was canceled');
          resetTableState(true);
        }
      },
      onEnd: () => setIsLoadingAction(false),
    });
  };

  useBus(EventTypeConstants.IDS_MODAL_STATE_CHANGED, (extra) => {
    if (extra?.state === 'open') {
      setIsIdsModalOpen(true);
    } else if (extra?.state === 'closed') {
      setIsIdsModalOpen(false);
    }
  });

  const onRunJob = () => {
    doLoadLogData();
  };

  const handleOnHide = () => {
    setBotJob(null);
    resetTableState();
  };
  return (
    <div className="img-bot-engine-dashboard">
      {!!enableUploader && (
        <NewCdsJobSection onJobCreated={setBotJob} setIsNewJobInProgress={setIsNewJobInProgress} />
      )}

      {botJob && (
        <Modal
          show
          onHide={() => handleOnHide()}
          className="img-bot-modal-panel cds-job-modal-panel"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <BotModalHeader botId={botJob.id} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-panel-body-text">
            <CdsTriggerModal job={botJob} onSuccess={handleOnHide} setIsVisible={handleOnHide} />
          </Modal.Body>
        </Modal>
      )}

      <ContentBotEngineLogTable
        logData={logData}
        isLogDataEmpty={!logData?.length}
        onRunJob={onRunJob}
        onCancelJob={onCancelJob}
        isLoading={isLoading}
        tableState={tableState}
        onTableStateChanged={(page, pageSize) => handleTableStateChanged(page, pageSize)}
        onGetStarted={
          showGetStarted
            ? () => history.push(`/content-databot-suite${location?.search}`)
            : undefined
        }
      />
    </div>
  );
};

export { ContentBotEngineDashboard };
