import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { ImageQualityAssessmentHeader } from 'components/image-quality-assessment/ImageQualityAssessmentHeader';
import { ImageQualityAssessmentList } from 'components/image-quality-assessment/ImageQualityAssessmentList';
import { BOT_SLUGS, IMAGE_QUALITY_ASSESSMENT_TYPES } from 'constants/BotConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import { isConstantEqual } from 'helpers/Utils';

const ViewImageQualityAssessments = () => {
  const history = useHistory();
  const { search: querySearch } = useLocation();
  const assessmentType =
    new URLSearchParams(querySearch).get('type') ?? IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY;
  const imagesKey =
    assessmentType === IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY ? 'images' : 'secondary_images';

  const [orgName, setOrgName] = useState(null);
  const [headerCount, setHeaderCount] = useState(null);
  const [configurationData, setConfigurationData] = useState({});
  const [assessmentData, setAssessmentData] = useState([]);

  const loadCount = useRef(0);
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [childMfrs, setChildMfrs] = useState(null);
  const [childMfr, setChildMfr] = useState(null);

  const { id, type: userType } = useParams();
  const priorId = useRef(id);
  const firstLoad = useRef(true);

  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();

  const isAdmin = activeUser?.role === UserRoleConstants.ADMIN;
  const isSupplier = activeUser?.role === UserRoleConstants.SUPPLIER;
  const hasChildren = activeUser?.profile?.manufacturer?.has_children || false;

  const slug = BOT_SLUGS.IMAGE_QUALITY;

  const setLoading = useCallback(
    (_isLoading) => {
      if (!isMounted.current) {
        return;
      }

      if (_isLoading === true) loadCount.current++;
      else if (_isLoading === false) loadCount.current--;

      if (loadCount.current > 0) setIsLoading(true);
      else if (loadCount.current === 0) setIsLoading(false);
      else {
        setIsLoading(false);
        console.warn('Invalid load count', loadCount.current);
      }
    },
    [setIsLoading, loadCount, isMounted]
  );

  // get data for distributors
  const getDistributorData = useCallback(() => {
    setLoading(true);
    // get header data
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/get-score-config/${slug}/${id}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setOrgName(res.data.client.name);
          setHeaderCount(res.data.total_manufacturers);
          setConfigurationData(res.data.configuration);
        }
      },
      onEnd: () => {
        setLoading(false);
      },
    });

    // get assessment data
    setLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/report-data/${slug}`,
      params: { offset: (page - 1) * pageSize, client_id: id, search, limit: pageSize },
      onSuccess: (res) => {
        if (isMounted.current) {
          setAssessmentData(
            res.data.report_data.filter((item) => {
              return (
                item.additional_data?.reports &&
                item.additional_data?.[imagesKey]?.total_products > 0
              );
            })
          );
          firstLoad.current = false;
        }
      },
      onEnd: () => {
        setLoading(false);
      },
    });
  }, [id, slug, isMounted, page, pageSize, search, imagesKey, setLoading]);

  // get data for manufacturers
  const getManufacturerData = useCallback(() => {
    // get header data
    if (isAdmin) {
      setLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/manufacturers/${id}`,
        onSuccess: (res) => {
          if (isMounted.current) {
            setOrgName(res.data.name);
          }
        },
        onEnd: () => {
          setLoading(false);
        },
      });
    } else {
      setOrgName(activeUser?.profile?.manufacturer?.name);
    }

    setLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/dashboard/details`,
      params: { manufacturer_id: id },
      onSuccess: (res) => {
        if (isMounted.current) {
          setHeaderCount(res.data.total_products);
        }
      },
      onEnd: () => {
        setLoading(false);
      },
    });

    // get assessment data
    setLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/report-data/${slug}`,
      params: { offset: (page - 1) * pageSize, manufacturer_id: id, search, limit: pageSize },
      onSuccess: (res) => {
        if (isMounted.current) {
          setAssessmentData(
            res.data.report_data.filter((item) => {
              return item.additional_data?.[imagesKey] && item.additional_data?.reports;
            })
          );
          firstLoad.current = false;
        }
      },
      onEnd: () => {
        setLoading(false);
      },
    });
  }, [id, slug, isMounted, page, pageSize, search, isAdmin, activeUser, imagesKey, setLoading]);

  useEffect(() => {
    if (isConstantEqual(userType, UserRoleConstants.MANUFACTURER)) {
      getManufacturerData();
    } else {
      getDistributorData();
    }
  }, [isMounted, userType, id, getDistributorData, getManufacturerData, page, pageSize, search]);

  useEffect(() => {
    if (activeUser?.profile?.manufacturer?.id && hasChildren) {
      setLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/manufacturers/${activeUser?.profile?.manufacturer?.id}/children`,
        onSuccess: (res) => {
          if (isMounted.current) {
            setChildMfrs(res?.data ?? null);
          }
        },
        onEnd: () => {
          setLoading(false);
        },
      });
    }
  }, [isMounted, activeUser?.profile?.manufacturer?.id, hasChildren, setLoading]);

  useEffect(() => {
    if (
      activeUser?.profile?.manufacturer?.id &&
      childMfrs &&
      id !== activeUser?.profile?.manufacturer?.id
    ) {
      const check = parseInt(id);
      setChildMfr(
        childMfrs.find((item) => {
          return item.id === check;
        })
      );
    }
  }, [activeUser, childMfrs, id]);

  useEffect(() => {
    const nextId = childMfr?.id ?? activeUser?.profile?.manufacturer?.id;
    if (isMounted.current && !firstLoad.current && nextId && nextId !== priorId.current) {
      history.push(`/image-quality/assessment/manufacturer/${nextId}`);
      priorId.current = nextId;
    }
  }, [isMounted, childMfr, history, activeUser?.profile?.manufacturer?.id, id]);

  return (
    <div className="content content-fluid view-image-quality-assessments">
      <Helmet>
        <title>
          {assessmentType === IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY ? 'Primary' : 'Secondary'}{' '}
          Image Quality Assessments
        </title>
      </Helmet>
      <ImageQualityAssessmentHeader
        id={id}
        name={orgName}
        assessmentType={assessmentType}
        userType={userType}
        count={headerCount}
        configurationData={configurationData}
        isLoadingHeader={isLoading}
        childMfrs={childMfrs}
        childMfr={childMfr}
        childMfrSelFunc={setChildMfr}
        showChildMfrs={id !== activeUser?.profile?.manufacturer?.id && isSupplier}
      />
      <ImageQualityAssessmentList
        assessmentData={assessmentData}
        isLoading={isLoading}
        assessmentType={assessmentType}
        userType={userType}
        id={id}
        page={page}
        setPage={(e) => setPage(e)}
        pageSize={pageSize}
        setPageSize={(e) => setPageSize(e)}
        search={search}
        setSearch={(e) => setSearch(e)}
      />
    </div>
  );
};

export { ViewImageQualityAssessments };
