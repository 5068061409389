import React from 'react';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotDefault = ({ description, running, completed }) => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="default"
      middle={<div className="description">{description}</div>}
      bottom={
        <div>
          <div className="databot-details__tertiary">{running ?? 0} Running</div>
          <div className="databot-details__primary">{completed ?? 0} Completed</div>
        </div>
      }
    />
  );
};

export { DatabotDefault };
