import { FILESTATUS } from 'constants/DataIngestionConstants';
import { FA_STATUS } from 'constants/FlashAssessmentConstants';
// check both of these values - if a FA is unpublished - the publish data is still set, but the status will be changed to not Published

export const isAssessmentPublished = (file) => {
  return (
    file?.flash_assessment_published_at && file?.flash_assessment_status === FA_STATUS.PUBLISHED
  );
};

export const isAssessmentAutomated = (file) => {
  // explicitly check for false in case of undefined or null
  return file?.flash_assessment_id && file?.is_flash_assessment_manual === false;
};

export const canViewAssessment = (attachment) => {
  if (
    attachment.flash_assessment_id &&
    ![FILESTATUS.PROCESSING.id, FILESTATUS.PROCESSING_REQUESTED.id].includes(
      attachment.status?.id
    ) &&
    (isAssessmentPublished(attachment) || isAssessmentAutomated(attachment))
  ) {
    return true;
  }
  return false;
};
