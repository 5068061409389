import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import BackboneLogo from 'assets/img/backbone-logo.png';
import backboneAIShort from 'assets/img/BackboneAI-Short-logo.svg';
import UserRoleConstants from 'constants/UserRoleConstants';

const CompanyLogo = ({ id, companyType, logoSrc, className }) => {
  const [logo, setLogo] = useState(null);
  const [localClassName, setLocalClassName] = useState(className);

  useEffect(() => {
    if (logoSrc === null || logoSrc === undefined) {
      if (companyType === UserRoleConstants.DISTRIBUTOR) {
        const reqCfg = {
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/distributors/${id}`,
          onSuccess: (res) => {
            setLogo(res.data.logo);
          },
          onError: () => {
            setLocalClassName('logo-backbone-small');
            setLogo(backboneAIShort);
          },
        };
        ApiCalls.doCall(reqCfg);
      } else if (companyType === UserRoleConstants.MANUFACTURER) {
        const reqCfg = {
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/manufacturers/${id}`,
          onSuccess: (res) => {
            setLogo(res.data.logo);
          },
          onError: () => {
            setLocalClassName('logo-backbone-small');
            setLogo(backboneAIShort);
          },
        };
        ApiCalls.doCall(reqCfg);
      } else {
        setLogo(BackboneLogo);
      }
    } else {
      setLogo(logoSrc);
    }
  }, [companyType, id, logoSrc]);

  return logo ? (
    <Link to="/">
      <img
        onError={() => {
          setLocalClassName('logo-backbone-small');
          setLogo(backboneAIShort);
        }}
        className={localClassName}
        src={logo}
        alt="Logo"
      />
    </Link>
  ) : null;
};

CompanyLogo.defaultProps = {
  companyType: null,
  logoSrc: null,
  id: null,
  className: 'logo-tmp',
};

CompanyLogo.propTypes = {
  id: PropTypes.number,
  companyType: PropTypes.oneOf([UserRoleConstants.DISTRIBUTOR, UserRoleConstants.MANUFACTURER]),
  logoSrc: PropTypes.string,
  className: PropTypes.string,
};

export { CompanyLogo };
