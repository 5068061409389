import * as FullStory from '@fullstory/browser';
import _get from 'lodash/get';
import { push } from 'react-router-redux';
import { call, put, takeLeading } from 'redux-saga/effects';

import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import ActionTypeConstants from 'constants/ActionTypeConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { removeAuthToken, setAuthToken } from 'helpers/AuthTokenUtils';
import * as UserUtils from 'helpers/UserUtils';
import * as Utils from 'helpers/Utils';

/**
 * Makes a login attempt. Redirects to root address if successful.
 * Validates existing username and password fields.
 * Also populates user profile on success.
 *
 * @param { payload } Object containing username and password
 */
function* submitUserLoginSaga({ payload }) {
  console.debug('submitUserLoginSaga', payload);

  const username = _get(payload, 'username') || null;
  const password = _get(payload, 'password') || null;
  const nextLocation = _get(payload, 'nextLocation') || '/';

  // Validate username and password fields by length
  if (username && password) {
    let isSuccessful = false;

    if (
      username.length >= 5 &&
      username.length <= 100 &&
      password.length >= 5 &&
      password.length <= 100
    ) {
      const apiResponse = yield call(ApiCalls.legacySyncDoCall, {
        urlPath: '/users/login/',
        method: ApiCalls.HTTP_METHODS.POST,
        data: { email: username.toLowerCase(), password },
      });

      if (apiResponse?.data?.key) {
        setAuthToken(apiResponse.data.key);

        const profileApiResponse = yield call(ApiCalls.legacySyncDoCall, {
          urlPath: '/users/get-current',
        });

        if (profileApiResponse?.data) {
          isSuccessful = true;
          yield put({ type: ActionTypeConstants.SUBMIT_USER_LOGIN_SUCCESS });
          yield put({
            type: ActionTypeConstants.GET_USER_PROFILE_SUCCESS,
            payload: profileApiResponse.data,
          });

          gaSendEvent(profileApiResponse.data);

          fullstoryIdentify(profileApiResponse.data);

          UserUtils.setShouldShowHomeWelcome(true);
          yield put({ type: ActionTypeConstants.RESET_INITIAL_LOCATION });

          yield put(push(nextLocation));
        }
      }

      if (!isSuccessful) {
        removeAuthToken();
        yield put({
          type: ActionTypeConstants.SUBMIT_USER_LOGIN_FAILURE,
          payload: {
            msg: apiResponse.isUnauthorized
              ? 'Wrong username or password.'
              : 'Could not log in. Please try again.',
          },
        });
        yield put({ type: ActionTypeConstants.GET_USER_PROFILE_FAILURE });
      }
    } else {
      removeAuthToken();
      yield put({
        type: ActionTypeConstants.SUBMIT_USER_LOGIN_FAILURE,
        payload: {
          msg: 'Wrong username or password.',
        },
      });
    }
  } else {
    removeAuthToken();
    yield put({
      type: ActionTypeConstants.SUBMIT_USER_LOGIN_FAILURE,
      payload: {
        msg: 'Please fill in your email and password.',
      },
    });
  }
}

/**
 * Logs out user and redirects to login.
 */
function* submitUserLogoutSaga() {
  console.debug('submitUserLogoutSaga');

  const apiResponse = yield call(ApiCalls.legacySyncDoCall, {
    urlPath: '/users/logout/',
    method: ApiCalls.HTTP_METHODS.POST,
  });

  if (apiResponse && !apiResponse.isError) {
    yield put({ type: ActionTypeConstants.SUBMIT_USER_LOGOUT_SUCCESS });
  } else {
    yield put({ type: ActionTypeConstants.SUBMIT_USER_LOGOUT_FAILURE });
  }

  removeAuthToken();

  yield call(() => {
    if (window.Intercom && process.env.REACT_APP_INTERCOM_ID?.length) {
      window.Intercom('shutdown');
    }
  });
  fullstoryAnonymize();

  // Even if server logout fails, we remove user profile info and redirect.
  yield put({ type: ActionTypeConstants.GET_USER_PROFILE_SUCCESS, payload: null });

  // Defines an array of routes which should not redirect to /login if auth is invalid
  const redirectExceptions = ['reset-password', 'sign-up-request'];

  if (!redirectExceptions.some((route) => window.location.href?.includes(route)))
    yield put(push('/login'));
}

/**
 * Populates current user profile.
 */
function* getUserProfileSaga() {
  console.debug('getUserProfileSaga');
  const apiResponse = yield call(ApiCalls.legacySyncDoCall, {
    urlPath: '/users/get-current',
  });

  if (apiResponse?.isError && !![500, 504].includes(apiResponse?.status)) {
    yield put({
      type: ActionTypeConstants.GET_USER_PROFILE_SERVER_ERROR,
      payload: ActionStatusConstants.SERVER_ERROR,
    });
  } else if (apiResponse?.data) {
    yield put({ type: ActionTypeConstants.GET_USER_PROFILE_SUCCESS, payload: apiResponse.data });
    fullstoryIdentify(apiResponse && apiResponse.data);
  } else {
    yield put({
      type: ActionTypeConstants.GET_USER_PROFILE_FAILURE,
      payload: { restoreUrlAfterLogin: true },
    });
    yield put({ type: ActionTypeConstants.SUBMIT_USER_LOGOUT });
  }
}

/**
 * Logic after successful authentication(login or auth)
 */
function* getUserProfileSuccessSaga({ payload }) {
  console.debug('getUserProfileSuccessSaga', payload);

  yield call(() => {
    if (
      window.Intercom &&
      process.env.REACT_APP_INTERCOM_ID?.length &&
      payload?.id &&
      payload?.email
    ) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_ID,
        email: payload.email,
        user_id: payload.id,
        name: Utils.joinNameToStr(payload.first_name, payload.last_name),
      });
    }
  });
}

function gaSendEvent(userObj) {
  if (typeof window.gtag === 'function') {
    const trackData = {
      usr: userObj.id || null,
      email: userObj.email || null,
      role: userObj.role || 'Unknown',
    };

    let companyObj = null;

    if (userObj.profile?.client) {
      companyObj = userObj.profile.client;
      trackData.distributor = companyObj.name || null;
    } else if (userObj.profile?.manufacturer) {
      companyObj = userObj.profile.manufacturer;
      trackData.supplier = companyObj.name || null;
    }

    if (companyObj) {
      if (!Number.isNaN(companyObj.tier)) {
        trackData.tier = companyObj.tier;
      }

      trackData.is_active = companyObj.is_active;
    }

    trackData.classification = !(
      userObj.role === UserRoleConstants.ADMIN ||
      /admin/i.test(userObj.first_name) ||
      /admin/i.test(userObj.last_name) ||
      /backbone/i.test(userObj.email)
    );

    window.gtag('event', 'login', trackData);
  }
}

function fullstoryIdentify(data) {
  if (!(data && data.id)) {
    return;
  }

  if (process.env.REACT_APP_FULLSTORY_TRACKING_ID) {
    const trackIdStr = `uid-${data.id}`;
    const trackEmail = data.email || null;
    const trackName = Utils.joinNameToStr(data.first_name || null, data.last_name || null);

    console.debug('FullStory identify');
    FullStory.identify(trackIdStr, {
      email: trackEmail,
      displayName: trackName,
    });
  }
}

function fullstoryAnonymize() {
  if (process.env.REACT_APP_FULLSTORY_TRACKING_ID) {
    console.debug('FullStory anonymize');
    FullStory.anonymize();
  }
}

export default function* () {
  yield takeLeading(ActionTypeConstants.SUBMIT_USER_LOGIN, submitUserLoginSaga);
  yield takeLeading(ActionTypeConstants.SUBMIT_USER_LOGOUT, submitUserLogoutSaga);
  yield takeLeading(ActionTypeConstants.GET_USER_PROFILE, getUserProfileSaga);
  yield takeLeading(ActionTypeConstants.GET_USER_PROFILE_SUCCESS, getUserProfileSuccessSaga);
}
