import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

const CreateDetailedAttributesBotModal = ({
  templateData,
  visible,
  manufacturer,
  botId,
  columnsType,
  onVisibilityChange,
  onSuccess,
  onSetConfig,
}) => {
  const isMounted = useIsMounted();

  // Modal visibilty state
  const [processedData, setProcessedData] = useState(null);
  const [visibility, setVisibility] = useState(false);
  // Init calls
  useEffect(() => {
    setVisibility(visible);

    const data = templateData.output_format.filter((item) => {
      if (item.is_changed === true) {
        return item;
      }
    });

    setProcessedData(data);
  }, [isMounted, visible]);

  const visibilityChange = (val) => {
    setVisibility(val);
    onVisibilityChange(val);
  };

  const doSaveChanges = () => {
    onSetConfig({ output_data: processedData, change_type:columnsType });
    onSuccess(true);
    visibilityChange(false);
  };

  return (
    <>
      {visibility && (
        <Modal
          onHide={() => visibilityChange(false)}
          show={visibility}
          className="modal-detailed-bot"
          centered
        >
          <Modal.Header closeButton>
            {columnsType === 'keys' ? (
              <Modal.Title>Confirm Key Corrections</Modal.Title>
            ) : (
              <Modal.Title>Confirm Label Corrections</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="block-manufacturer-select">
                <div className="row">
                  <div className="corrected-label-title col-6">
                    {columnsType === 'keys' ? (
                      <div className="title">CURRENT KEY</div>
                    ) : (
                      <div className="title">CURRENT LABEL</div>
                    )}
                  </div>
                  <div className="corrected-label-title col-6">
                    {columnsType === 'keys' ? (
                      <div className="title">CORRECTED KEY</div>
                    ) : (
                      <div className="title">CORRECTED LABEL</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                {processedData.map(function (item) {
                  return (
                    <div key={item.id} className="row corrected-label-row">
                      <div className="col-5">{item.current}</div>
                      <div className="col-2">
                        <FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} />
                      </div>

                      <div className="col-5">{item.corrected}</div>
                    </div>
                  );
                })}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              title="Cancel"
              className="btn btn-secondary btn-cancel"
              onClick={() => visibilityChange(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              title="Confirm"
              className="btn btn-primary btn-save-template"
              onClick={doSaveChanges}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

CreateDetailedAttributesBotModal.defaultProps = {
  templateData: null,
};

CreateDetailedAttributesBotModal.propTypes = {
  templateData: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSetConfig: PropTypes.any.isRequired,
};

export { CreateDetailedAttributesBotModal };
