const EXPORT_STATUSES = {
  in_progress: { value: 'in_progress', label: 'In Progress' },
  failed: { value: 'failed', label: 'Failed' },
  done: { value: 'done', label: 'Done' },
};

const ATTRIBUTE_TYPES = {
  general: { value: 'general', label: 'General Attributes' },
  detailed: { value: 'detailed', label: 'Detailed Attributes' },
  asset: { value: 'asset', label: 'Asset' },
};

const DESELECT_ALL = 'deselect_all';

export { EXPORT_STATUSES, ATTRIBUTE_TYPES, DESELECT_ALL };
