import React, { useEffect } from 'react';

import { isHexWithTransparency } from '../../../../helpers/Utils';
import { CustomBotConfigForm } from '../CustomBotConfigForm';
import { CustomBotConfigUtils } from '../CustomBotConfigUtils';
import { IdsConstants } from '../IdsConstants';
import { IdsModels } from '../IdsModels';

const IdsEnhancementConfigForm = ({
  config,
  setCustomConfig,
  isLoading,
  setValidationResult,
  isAnonymous = false,
}) => {
  useEffect(() => {
    let hasError = CustomBotConfigUtils.containsError(config);
    let message = hasError ? IdsConstants.FORM_ERROR_MESSAGES.invalid_config_form : undefined;
    if (
      config?.format_conversion?.is_enabled &&
      config?.bg_removal?.is_enabled &&
      isHexWithTransparency(config?.bg_removal?.value)
    ) {
      switch (config?.format_conversion?.value) {
        case 'jpeg':
          hasError = true;
          message = IdsConstants.FORM_ERROR_MESSAGES.jpeg_transparent;
          break;
        case 'tiff':
          message = IdsConstants.FORM_ERROR_MESSAGES.tiff_transparent;
          break;
        default:
          break;
      }
    }

    if (config?.format_conversion?.value === 'jpeg' && !config?.bg_removal?.is_enabled) {
      message = IdsConstants.FORM_ERROR_MESSAGES.jpeg_no_bg;
    }

    if (config?.keep_original_format?.is_enabled && config?.format_conversion?.is_enabled) {
      hasError = true;
      message = IdsConstants.FORM_ERROR_MESSAGES.keep_original_format_test_failed;
    }

    if (
      config?.keep_original_format?.is_enabled &&
      config?.format_conversion?.value === 'jpeg' &&
      config?.bg_removal?.is_enabled &&
      config?.bg_removal?.value === IdsModels.BG_COLOR_OPTIONS.transparent.hex
    ) {
      hasError = true;
      message =
        IdsConstants.FORM_ERROR_MESSAGES
          .keep_original_format_background_removal_output_format_test_failed;
    }

    setValidationResult?.({ isValid: !hasError, message });
  }, [config, setValidationResult]);

  return (
    <section className="custom-bot-config">
      <CustomBotConfigForm
        isAnonymous={isAnonymous}
        customConfig={config}
        setCustomConfig={setCustomConfig}
        isLoading={isLoading}
        configGroups={IdsModels.CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS}
        configModel={IdsModels.CUSTOM_ENHANCEMENT_BOT_CFG_MODEL}
      />
    </section>
  );
};

export { IdsEnhancementConfigForm };
