import React from 'react';
import './CdsBotItem.scss';

export const CdsBotItem = ({ model, isSelected, selectModal }) => {
  const { title, description, icon } = model;

  const handleOnSelected = () => {
    if (isSelected) {
      selectModal('');
    } else {
      selectModal(model.name);
    }
  };
  return (
    <>
      <div
        className={isSelected ? 'cds-bot-info info-selected' : 'cds-bot-info info-default'}
        onClick={() => handleOnSelected()}
        role="presentation"
      >
        <div className="cds-bot-item">
          <div className="cds-bot-item">
            <div className="cds-bot-title">{title}</div>
          </div>
          <div className="cds-bot-item">{icon && icon}</div>
        </div>
        <div className="cds-bot-item">
          <span className="cds-bot-item-description">{description}</span>
        </div>
      </div>
    </>
  );
};
