import ActionStatusConstants from "constants/ActionStatusConstants";
import ActionTypeConstants from "constants/ActionTypeConstants";

const initialState = {
  status: ActionStatusConstants.INITIAL,
  payload: null,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.GET_DATA_REQUEST_DELIVERY_FORMATS:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.GET_DATA_REQUEST_DELIVERY_FORMATS_SUCCESS:
      return {
        ...state,
        status: ActionStatusConstants.SUCCESS,
        payload,
      };
    default:
  }
  return state;
};
