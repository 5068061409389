import './DataIngestionFooter.scss';
import React, { useCallback, useContext, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { SHEETSTATUS } from 'constants/DataIngestionConstants';

function DataIngestionFooter({
  activeTab,
  completedSheets = [],
  goToNextSheet,
  goToPreviousSheet,
  numberOfSheets,
  path,
  sheets,
  columnsComplete,
}) {
  const { errors, isNextDisabled, setIsNextDisabled, isColumnPhaseAccessible, setIsLoading, userIsBacktracking } =
    useContext(DataIngestionContext);

  const checkForCatalogErr = useCallback(() => {
    // Check for missing catalog_item
    if (path === 'columns' && errors.errorArray.length > 0) {
      if (errors.errorArray.some((error) => error.includes('CATALOG_ITEM'))) {
        return true;
      }
    }
    return false;
  }, [errors.errorArray, path]);

  useEffect(() => {
    if (path === 'rows') {
      if (sheets.length === completedSheets.length) {
        setIsNextDisabled(false);
      } else if (completedSheets.includes(sheets.indexOf(activeTab))) {
        setIsNextDisabled(false);
      } else if (
        [
          SHEETSTATUS.USER_VERIFIED_ROWS,
          SHEETSTATUS.USER_VERIFIED_COLUMNS,
          SHEETSTATUS.USER_EDITED_COLUMNS,
          SHEETSTATUS.DISABLED_DURING_ROWS,
          SHEETSTATUS.DISABLED_DURING_COLUMNS,
          SHEETSTATUS.PENDING_COLUMN,
        ].includes(sheets?.[activeTab]?.status)
      ) {
        setIsNextDisabled(false);
      } else {
        setIsNextDisabled(true);
      }
    }
    if (path === 'columns') {
      const catalogErr = checkForCatalogErr();
      if (catalogErr && sheets?.[activeTab]?.status !== SHEETSTATUS.DISABLED_DURING_COLUMNS) {
        setIsNextDisabled(true);
        return;
      }
      if (columnsComplete || completedSheets.includes(activeTab)) {
        setIsNextDisabled(false);
      } else {
        setIsNextDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, sheets, columnsComplete, errors]);

  const nextSheet = () => {
    setIsLoading(true);
    goToNextSheet();
  };
  const previousSheet = () => {
    goToPreviousSheet();
  };
  const isLastSheet = activeTab === numberOfSheets - 1;

  const nextLabel = useCallback(() => {
    if (path === 'rows' || userIsBacktracking === true) {
      if (isLastSheet) {
        if (isColumnPhaseAccessible) {
          return 'Go to Column Labels';
        }
        return 'Finish';
      }
      return 'Next';
    }
    if (path === 'columns') {
      return isLastSheet ? 'Start Assessment' : 'Next';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, isLastSheet, activeTab, isColumnPhaseAccessible]);

  const renderButton = () => {
    return (
      <span className="d-inline-block">
        <Button disabled={isNextDisabled} className="next-button" onClick={() => nextSheet()}>
          <FontAwesomeIcon className="arrow" icon={['far', 'long-arrow-right']} />
          {nextLabel()}
        </Button>
      </span>
    );
  };

  return (
    <footer className=" content-fluid ingestion-footer ">
      <div className="footer-content">
        <div>
          Displaying Sheet {activeTab + 1} of {numberOfSheets}
        </div>
        <Button className="back-button" onClick={() => previousSheet()} variant="outline-dark">
          Go Back
        </Button>
        <>
          {isNextDisabled ? (
            <OverlayTrigger
              placement="top"
              disabled={!isNextDisabled}
              overlay={
                <Tooltip className="tooltip" id="tooltip-disabled">
                  {path === 'rows'
                    ? 'Verify your header and data rows to continue'
                    : 'Scroll to the end of each sheet to continue'}
                </Tooltip>
              }
            >
              {renderButton()}
            </OverlayTrigger>
          ) : (
            <>{renderButton()}</>
          )}
        </>
      </div>
    </footer>
  );
}

export default DataIngestionFooter;
