import React from 'react';
import './DueDate.scss';

import moment from 'moment';
import PropTypes from 'prop-types';

import { BAIDatePicker } from 'components/common/DatePicker/DatePicker';
import { formatDate8601 } from 'helpers/TimeUtils';

/**
 * Wrapper around backboen ai date picker to allow due date selection and conditional highlighting
 *
 * @param {function} onEdit onChange or the onEdit function
 * @param {Date} dueDate the selected date, leave null for none
 * @param {string} name the dispatch key if used
 * @param {boolean} editable whether the datepicker component is editable
 * @param {Date} minDate the minimum offset date if limiting selection - this is the date that any offset is counted from
 * @param {Node} children any component children to display under the selector
 * @param {function} nonEditableEl function to return the component to display when editable is false
 * @param {integer} minDaysOffset the offset amount in days
 * @param {boolean} offsetWithWeekends whether to include weekends in teh offset count
 * @param {boolean} showMinDateHighlight whether to show the miniumum date highlighted - controls strikethrough as well
 */

const BAIDueDatePicker = ({
  onEdit,
  dueDate,
  name,
  editable,
  minDate,
  children,
  nonEditableEl = () => <></>,
  minDaysOffset = 1,
  offsetWithWeekends = false,
  showMinDateHighlight = false,
  ...props
}) => {
  const onDueDateChanged = (dueDate) => {
    if (dueDate === null) {
      onEdit(null, name);
      return;
    }

    onEdit(formatDate8601(dueDate), name);
  };

  const isWeekday = (date) => {
    const day = moment(date).day();
    return day !== 0 && day !== 6;
  };

  const createStrikethroughDays = (date, offsetDate) => {
    let days = offsetDate.diff(moment(date), 'days');
    const newDate = moment(date);
    const dateRange = [];
    while (days > 1) {
      newDate.add(1, 'days');
      dateRange.push(newDate.toDate());
      days--;
    }
    return dateRange;
  };

  const addBusinessDays = (date, days) => {
    let remaining = days % 5;
    const newDate = moment(date).add(Math.floor(days / 5) * 7, 'days');
    newDate.add(1, 'days');
    while (remaining) {
      newDate.add(1, 'days');
      if (newDate.day() !== 0 && newDate.day() !== 6) {
        remaining--;
      }
    }
    return newDate;
  };

  let minOffsetDate = minDate ? moment(minDate) : moment();
  minOffsetDate.add(1, 'days');
  if (!offsetWithWeekends) {
    minOffsetDate = addBusinessDays(minDate, minDaysOffset);
  } else {
    minOffsetDate.add(minDaysOffset, 'days');
  }

  if (editable) {
    return (
      // TODO: Common components shouldn't have generic wrappers. Refactor.
      <div className="ml-auto">
        <BAIDatePicker
          popperPlacement="auto"
          dateFormat="MMMM d, yyyy"
          selected={dueDate ? moment(dueDate).toDate() : null}
          minDate={minOffsetDate.toDate()}
          className="bai-due-date-picker-value-input__control"
          onChange={onDueDateChanged}
          filterDate={isWeekday}
          disabledKeyboardNavigation
          highlightDates={
            showMinDateHighlight && [
              {
                'bai-due-date-picker-value-input__date-highlight': [
                  minDate && moment(minDate).toDate(),
                ],
              },
              {
                'bai-due-date-picker-value-input__date-strikethrough':
                  minDate && createStrikethroughDays(minDate, minOffsetDate),
              },
            ]
          }
          {...props}
        />
        {children}
      </div>
    );
  }
  return nonEditableEl();
};

BAIDueDatePicker.defaultProps = {
  onEdit: () => {},
  dueDate: null,
  name: null,
  editable: true,
  minDate: null,
  children: null,
};

BAIDueDatePicker.propTypes = {
  onEdit: PropTypes.func,
  dueDate: PropTypes.any,
  name: PropTypes.string,
  editable: PropTypes.bool,
  minDate: PropTypes.any,
  children: PropTypes.node,
  nonEditableEl: PropTypes.func,
  minDaysOffset: PropTypes.any,
  offsetWithWeekends: PropTypes.any,
  showMinDateHighlight: PropTypes.bool,
};

export { BAIDueDatePicker };
