import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { OnBlurInputProxy } from 'components/common/OnBlurInputProxy/OnBlurInputProxy';
import { mandatoryExportColumns } from 'constants/ExportConstants';
import { getDefaultTableColumnDef } from 'helpers/Utils';

import { ATTRIBUTE_TYPES } from './ExportFunctionalityAdvancedConstants';

export const BlockAttributesTableTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('export', 'Export Attribute'),
      sort: false,
      formatter: (cell, row) => {
        const output = (
          <Form.Check
            type="checkbox"
            className={classNames({
              'is-changed':
                row?.templateValue?.is_exported !== row?.originalTemplateValue?.is_exported,
            })}
            checked={!!row?.templateValue?.is_exported}
            readOnly
            onClick={() => {
              typeof row.onChangeRowValues === 'function' &&
                row.onChangeRowValues(
                  row.id,
                  row.key,
                  !row?.templateValue?.is_exported,
                  row?.templateValue?.output_name
                );
            }}
            disabled={row?.isDisabled || mandatoryExportColumns.includes(row.key)}
          />
        );

        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('name', 'Attribute Name'),
      formatter: (cell, row) => (
        <div className="attribute-name-key-cell">
          <div className="attribute-name">{row.name}</div>
          <div className="attribute-key">
            <span className="label">key:</span> <span className="value">{row.key}</span>
            {!!row.isDuplicateKey && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Multiple attributes use this key</Tooltip>}
                // trigger={isChanged ? ['hover', 'focus'] : null}
              >
                <span className="icon duplicate-icon">
                  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                </span>
              </OverlayTrigger>
            )}
          </div>
        </div>
      ),
    },
    {
      ...getDefaultTableColumnDef('type', 'Attribute Type'),
      formatter: (cell, row) => {
        let output = 'N/A';

        if (Object.keys(ATTRIBUTE_TYPES).includes(row.type)) {
          output = ATTRIBUTE_TYPES[row.type].label;
        }
        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('product_count', 'Product Count'),
    },
    {
      ...getDefaultTableColumnDef('output_format', 'Output Format'),
      sort: false,
      formatter: (cell, row) => {
        const isChanged =
          row?.templateValue?.output_name !== row?.originalTemplateValue?.output_name;

        const output = (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Changed from {!row?.originalTemplateValue?.output_name && 'default'}{' '}
                <b>
                  &quot;
                  {row?.originalTemplateValue?.output_name
                    ? row.templateValue.output_name
                    : row.name}
                  &quot;
                </b>
              </Tooltip>
            }
            trigger={isChanged ? ['hover', 'focus'] : null}
          >
            <OnBlurInputProxy
              value={row?.templateValue?.output_name || ''}
              onChange={(v) =>
                typeof row.onChangeRowValues === 'function' &&
                row.onChangeRowValues(row.id, row.key, row?.templateValue?.is_exported, v)
              }
            >
              {({ value, onChange, onBlur }) => (
                <Form.Control
                  type="text"
                  className={classNames({
                    'is-changed': isChanged,
                  })}
                  value={value || ''}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={() => onBlur()}
                  disabled={row?.isDisabled || !row?.templateValue?.is_exported}
                />
              )}
            </OnBlurInputProxy>
          </OverlayTrigger>
        );

        return output;
      },
    },
  ],
};
