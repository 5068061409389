import _cloneDeep from 'lodash/cloneDeep';

import ActionTypeConstants from 'constants/ActionTypeConstants';

const initialState = {
  messages: {},
  messagesLastUpdate: {},
};

const WebsocketMessageReducer = (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;
  const tempState = _cloneDeep(state.messages);
  const tempLastUpdate = _cloneDeep(state.messagesLastUpdate);
  switch (actionType) {
    case ActionTypeConstants.ADD_WS_MESSAGE:
      tempState[payload.channel] = payload.message;
      tempLastUpdate[payload.channel] = Date.now();
      return { messages: tempState, messagesLastUpdate: tempLastUpdate };
    case ActionTypeConstants.REMOVE_WS_CHANNEL:
      delete tempState[payload.channel];
      delete tempLastUpdate[payload.channel];
      return { messages: tempState, messagesLastUpdate: tempLastUpdate };
    case ActionTypeConstants.CLEAR_ALL_WS_MESSAGES:
      return initialState;
    default:
      return state;
  }
};

export { WebsocketMessageReducer };
