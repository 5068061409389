import ActionTypeConstants from "constants/ActionTypeConstants";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  initialLocation: null,
  previousLocation: null,
  currentLocation: null,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case LOCATION_CHANGE:
      return {
        ...state,
        initialLocation:
          !state.initialLocation &&
          !state.currentLocation &&
          !state.previousLocation &&
          payload.location.pathname &&
          !["", "/", "/login"].includes(payload.location.pathname)
            ? payload.location.pathname
            : state.initialLocation,
        previousLocation: state.currentLocation,
        currentLocation: `${payload.location.pathname}${payload.location.search}`,
      };
    case ActionTypeConstants.RESET_INITIAL_LOCATION:
      return {
        ...state,
        initialLocation: null,
      };
    default:
  }
  return state;
};
