/**
 * Strings to indicate which step of the tutorial a user is on. The header and first data row steps occur on each sheet
 */
export const TUTORIALSTEPS = {
  // Row phase steps
  HEADER: 'header',
  FIRST_DATA_ROW: 'first_data_row',
  SHEET: 'sheet',
  ROWS_DONE: 'rows_done',
  // Column phase steps
  LABELS: 'labels',
  DISABLE: 'disable',
  SCROLL: 'scroll',
  CONFIRM: 'confirm',
  COLUMNS_DONE: 'columns_done',
};

/**
 * Strings for different modal types and messages
 */
export const MODALTYPES = {
  THANK_YOU: 'thankYou',
  PROCESSING: 'processing',
  RESTART_FROM_COLUMN: 'restartFromColumn',
  RESTART_FROM_ASSESSMENT: 'restartFromAssessment',
  COLUMN_DISABLE: 'columnDisable',
  DISABLE_SHEET: 'disableSheet',
  SHEET_DISABLED: 'disableSheet',
};

export const SHEETSTATUS = {
  PENDING_ROW: 'pending_row',
  PENDING_COLUMN: 'pending_column',
  PENDING: 'pending',
  USER_EDITED_ROWS: 'user_edited_rows',
  USER_EDITED_COLUMNS: 'user_edited_columns',
  USER_VERIFIED_ROWS: 'user_verified_rows',
  USER_VERIFIED_COLUMNS: 'user_verified_columns',
  INTEGRITY_CHECKED: 'integrity_checked',
  DISABLED_DURING_ROWS: 'disabled_during_rows',
  DISABLED_DURING_COLUMNS: 'disabled_during_columns',
  FAILED: 'failed',
};

export const COLUMNSTATUS = {
  PENDING: 'pending_validation',
  DISABLED: 'disabled',
  VALIDATED: 'validated',
};

export const FILESTATUS = {
  NEW: { id: 1, name: 'New' },
  PROCESSING: { id: 2, name: 'Processing' },
  PENDING: { id: 3, name: 'Pending' },
  PROCESSING_REQUESTED: { id: 16, name: 'Processing Requested' },
};

/**
 * Default num of rows shown in Data-quality erros and warnings screen
 */
export const DEFAULT_NUM_ROWS = 15;
