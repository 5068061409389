import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { WsConstants } from 'api/WsConstants';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import * as UserUtils from 'helpers/UserUtils';
import { useWebsocket } from 'helpers/useWebsocket';

import './DataJobPanel.scss';
import { CommentsBlock } from './private/CommentsBlock';
import { DetailsBlock } from './private/DetailsBlock/DetailsBlock';
import { FilesBlock } from './private/FilesBlock';
import { HistoryBlock } from './private/HistoryBlock';
import { JobNotFound } from './private/JobNotFound';
import { StatusProgressBlock } from './private/StatusProgressBlock';
import { TitleBlock } from './private/TitleBlock';

// TODO: Unload handler -> in edit form
const DataJobPanel = ({ id }) => {
  const channel = WsConstants.WS_CHANNELS.COMMENT;
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();

  const [dataJobCommentsData, setDataJobCommentsData] = useState(null);
  const [dataJobCommentsDataStatus, setDataJobCommentsDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  const [dataJobData, setDataJobData] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const history = useHistory();
  // create websocket
  const ws = useWebsocket();
  const [wsLastUpdate, setWsLastUpdate] = useState(null);
  const { messages, messagesLastUpdate } = ws;

  const isMarkViewed = useRef(false);

  // Get Job data
  const doLoadDataJob = useCallback(
    (_id) => {
      if (isMounted.current && _id) {
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/data-requests/${_id}`,
          onSuccess: (res) => {
            if (isMounted.current) {
              setDataJobData(res?.data ?? null);
            }
          },
          onError: (err) => {
            setNotFound(err.response.status);
          },
        });
      }
    },
    [isMounted]
  );

  useEffect(() => {
    doLoadDataJob(id);
  }, [id, doLoadDataJob]);

  // add websocket channel
  useEffect(() => {
    if (id !== null && isMounted.current === true && ws?.isConnected && wsLastUpdate === null) {
      ws.addChannel(channel, id);
      setWsLastUpdate(Date.now());
    }
  }, [isMounted, ws.isConnected, ws, id, wsLastUpdate]);

  // reset on unmount
  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (!pathname.match(/`^\/data-request\/${id}`/g)) {
        ws.disconnectChannel(channel, id);
        setWsLastUpdate(null);
      }
    });
    return () => unlisten();
  }, [ws, wsLastUpdate, isMounted, history, id, wsLastUpdate]);

  // watch for new messages in that channel
  useEffect(() => {
    if (
      isMounted.current === true &&
      ws?.isConnected &&
      messages[channel]?.length > 0 &&
      wsLastUpdate < messagesLastUpdate[channel]
    ) {
      setWsLastUpdate(messagesLastUpdate[channel]);
      setDataJobCommentsDataStatus(ActionStatusConstants.ISBUSY);
      try {
        const _data = messages[channel];
        if (Object.keys(_data[0]).length === 0) {
          setDataJobCommentsDataStatus(ActionStatusConstants.SUCCESS);
          return;
        }

        if (isMounted.current === true) {
          // FIXME remove filter by isAdmin for data ingestion
          // TODO: Investigate this rule
          const tempFilteredComments = _data?.filter(
            (c) =>
              !(
                !UserUtils.isRoleAdmin(activeUser) &&
                c.content.includes('User added a comment for Header')
              )
          );

          setDataJobCommentsData(tempFilteredComments);
          setDataJobCommentsDataStatus(ActionStatusConstants.SUCCESS);
        }
      } catch (err) {
        console.error(err);
        if (isMounted.current === true) {
          setDataJobCommentsDataStatus(ActionStatusConstants.FAILURE);
        }
      }
    }
  }, [isMounted, ws?.isConnected, messages, activeUser, ws, wsLastUpdate, messagesLastUpdate]);

  // reset messages if ws disconnects for some reason
  useEffect(() => {
    if (
      isMounted.current &&
      messages[channel]?.length > 0 &&
      wsLastUpdate &&
      dataJobCommentsDataStatus === ActionStatusConstants.SUCCESS &&
      !ws?.isConnected
    ) {
      setDataJobCommentsData(null);
      setWsLastUpdate(null);
      toast.error('There was an error retrieving comments.');
    }
  }, [
    isMounted,
    dataJobCommentsDataStatus,
    setWsLastUpdate,
    ws.isConnected,
    messages,
    wsLastUpdate,
  ]);

  useEffect(() => {
    if (!isMarkViewed.current && dataJobData?.id) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/data-requests/${dataJobData?.id}/viewed`,
        errorMessage: false,
      });

      isMarkViewed.current = true;
    }
  }, [dataJobData?.id, isMarkViewed]);

  return (
    <>
      {dataJobData?.id && (
        <Helmet>
          <title>Data Job{` #${dataJobData.id}`}</title>
        </Helmet>
      )}
      <div className="data-job-panel">
        {notFound ? (
          <JobNotFound status={notFound} />
        ) : (
          <>
            <TitleBlock dataJobData={dataJobData} setDataJobData={setDataJobData} />
            <StatusProgressBlock dataJobData={dataJobData} />
            <DetailsBlock dataJobData={dataJobData} setDataJobData={setDataJobData} />
            <FilesBlock
              id={parseInt(id)}
              dataJobData={dataJobData}
              setDataJobData={(v) => setDataJobData(v)}
              dataJobCommentsData={dataJobCommentsData}
              dataJobCommentsDataStatus={dataJobCommentsDataStatus}
            />
            <CommentsBlock
              dataJobData={dataJobData}
              dataJobCommentsData={dataJobCommentsData?.filter((item) => !item?.attachment) || null}
              dataJobCommentsDataStatus={dataJobCommentsDataStatus}
            />
            <HistoryBlock dataJobData={dataJobData} />
          </>
        )}
      </div>
    </>
  );
};

export { DataJobPanel };
