import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import * as ApiCalls from 'api/ApiCalls';
import UserRoleConstants from 'constants/UserRoleConstants';
import './AssessmentAdminPanel.scss';


/**
 * Renders a panel with a list of suggestions, overview and optional completion mark
 * ability for Data Ingestion Assessment.
 *
 * @param modalStartIngestion function to show ingestion modal
 * @param hasProducts boolean for checking is manuf has products to prevent ingestion
 * @param attachmentID used for querying ingestion status of attachment
 *
 * @returns Render
 */
const AssessmentAdminPanel = ({ userRole, modalStartIngestion, hasProducts, attachmentData }) => {
  const [successfulIngestionExists, setSuccessfulIngestionExists] = useState(false);

  // Check for existing successful Celery Ingestion Task
  // Returns True if a successful ingestion task with the exact same filename and job id exist
  // Otherwise Returns false
  const queryIngestionStatus = () => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/data-ingestion/ingestion-status`,
      data: { attachment_name: attachmentData.name },
      onSuccess: (response) => {
        response?.data?.successful_ingestion_exists === false && setSuccessfulIngestionExists(false);
        response?.data?.successful_ingestion_exists === true && setSuccessfulIngestionExists(true);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  // Check if Ingestion is currently in progress
  useEffect(() => {
    if (attachmentData?.name) {
      const ingestionStatus = queryIngestionStatus();
      setSuccessfulIngestionExists(ingestionStatus);
    }
  }, [ attachmentData ]);

  // Set ingestion ability
  const canDoIngestion = () => {
    if (!successfulIngestionExists && userRole === UserRoleConstants.ADMIN) { 
      // TODO V1: only admin can start ingestion
      // TODO: logic for score threshold to start ingestion
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="assessment-admin-panel">
        <div className="overview">
          <div className="title">Backbone AI Admin Panel</div>
        </div>
        <div className="actions">
          <button
            className="btn btn-secondary btn-start-ingestion"
            type="button"
            onClick={() => modalStartIngestion(true)}
            disabled={!canDoIngestion()}
          >
            Start Ingestion
          </button>
        </div>
      </div>
    </>
  );
};

AssessmentAdminPanel.propTypes = {
  modalStartIngestion: PropTypes.func.isRequired,
  hasProducts: PropTypes.bool.isRequired,
};

export { AssessmentAdminPanel };
