import './ForgotPassword.scss';

import React, { useState } from 'react';

import { Alert } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { isEmailValid } from 'helpers/Utils';

const ForgotPassword = ({ setShowForgotPassword }) => {
  const [email, setEmail] = useState('');
  const [screen, setScreen] = useState(0);
  const [error, setError] = useState(null);

  const checkFormState = () => {
    if (email.length === 0) return 'Please fill in your email.';
    if (!isEmailValid(email)) return 'Please provide a valid email address.';
  };

  const doForgotPassword = () => {
    setError(null);
    const formState = checkFormState();

    if (formState?.length > 0) {
      setError(formState);
      return;
    }

    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/users/reset-password`,
      data: {
        email: email.toLowerCase(),
      },
      onSuccess: () => {
        setScreen(1);
      },
      onError: (err) => {
        setError(err?.response?.data?.message);
      },
      errorMessage: false,
    };
    ApiCalls.doCall(reqCfg);
  };

  const renderScreen = () => {
    return screen === 0 ? (
      <>
        <h1 className="forgot-password-heading">Reset password</h1>
        <p className="forgot-password-subheading">
          Enter the email associated with your account and we&apos;ll send an email with
          instructions to reset your password.
        </p>
        <div className="view-login-form">
          <div className="view-login-group">
            <label className="view-login-label">Email</label>
            <input
              className="view-login-input"
              type="email"
              required
              placeholder="hello@company.com"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          {error?.length > 0 && (
            <Alert className="my-3" variant="danger">
              {error || 'Could not submit. Please try again.'}
            </Alert>
          )}
          <button onClick={() => doForgotPassword()} className="view-login-submit" type="button">
            Send Instructions
          </button>
          <div className="view-login-forgot">
            Remember your password?{' '}
            <button className="link" onClick={() => setShowForgotPassword(false)} type="button">
              Login
            </button>
          </div>
        </div>
      </>
    ) : (
      <>
        <h1 className="forgot-password-heading">Email sent successfully</h1>
        <p className="forgot-password-subheading">
          We have successfully sent an email to you, please check your inbox.
        </p>
        <button
          className="view-login-submit"
          onClick={() => setShowForgotPassword(false)}
          type="button"
        >
          Login
        </button>
      </>
    );
  };

  return <div className="forgot-password-wrapper">{renderScreen()}</div>;
};

export { ForgotPassword };
