import './Overview.scss';

import React from 'react';

import { Link } from 'react-router-dom';

import { MetricsCard } from 'components/metrics/MetricsCard/MetricsCard';

const Overview = ({ overview }) => {
  const metricsCards = [
    {
      header: 'Total Products',
      total: overview?.total_processed?.toLocaleString('en-US') ?? 0,
    },
  ];

  return (
    <section className="metrics-overview">
      <div className="metrics-overview-row">
        <div className="metrics-overview-main">
          <div className="metrics-overview-main-header">
            <h1>Metrics</h1>
          </div>
          <div className="metrics-overview-main-details">
            <Link to="/weekly-metrics" className="btn btn-primary metrics-overview-main-btn">
                View Weekly Metrics
            </Link>
            <Link to="/databots-usage-metrics" className="btn btn-primary metrics-overview-main-btn">
                View Databots Metrics
            </Link>
          </div>
        </div>
        {metricsCards.map((card) => (
          <MetricsCard key={card.header} header={card.header} total={card.total} />
        ))}
      </div>
    </section>
  );
};

export { Overview };
