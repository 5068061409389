import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';

import ImgWithPlaceholder from 'components/ImgWithPlaceholder';
import { PRODUCT_ASSET_TYPES } from 'constants/ProductConstants';
import { getAzureUrl } from 'helpers/Utils';

import { AssetActions } from './AssetActions';

const Assets = ({ product, assets }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [visibleIndices, setVisibleIndices] = useState([0, 1, 2]);
  const [assetImages, setAssetImages] = useState([]);
  const [assetDocuments, setAssetDocuments] = useState([]);

  useEffect(() => {
    // TODO: Support CAD PDFs, videos (embeddable content)
    const imageFilterTypes = [
      PRODUCT_ASSET_TYPES.PRIMARY_IMAGE,
      PRODUCT_ASSET_TYPES.IMAGE,
      PRODUCT_ASSET_TYPES.IMAGE_360,
    ];

    const documentFilterTypes = [
      PRODUCT_ASSET_TYPES.FILE,
      PRODUCT_ASSET_TYPES.CAD_2D,
      PRODUCT_ASSET_TYPES.CAD_3D,
      PRODUCT_ASSET_TYPES.SDS,
      PRODUCT_ASSET_TYPES.SDS_TRANSPORT,
      PRODUCT_ASSET_TYPES.VIDEO,
    ];
    let supportedAssetTypes = PRODUCT_ASSET_TYPES;
    delete supportedAssetTypes.PRODUCT_PAGE;
    supportedAssetTypes = Object.values(supportedAssetTypes);

    const assetImages = assets
      .filter((asset) => imageFilterTypes.includes(asset.type))
      .map((asset) => {
        const url = supportedAssetTypes.includes(asset.type)
          ? getAzureUrl(product, asset.type, asset.name)
          : asset.url;

        return {
          ...asset,
          url: url ?? null,
        };
      });

    const assetDocuments = assets
      .filter((asset) => documentFilterTypes.includes(asset.type))
      .map((asset) => {
        const url = supportedAssetTypes.includes(asset.type)
          ? getAzureUrl(product, asset.type, asset.name)
          : asset.url;

        return {
          ...asset,
          url: url ?? asset.url,
          selected: false,
        };
      });

    // Move the primary_image to the front of the array
    assetImages.sort((a, b) =>
      a.type === PRODUCT_ASSET_TYPES.PRIMARY_IMAGE
        ? -1
        : b.type === PRODUCT_ASSET_TYPES.PRIMARY_IMAGE
        ? 1
        : 0
    );

    setAssetImages(assetImages);
    setAssetDocuments(assetDocuments);
  }, [assets, product]);

  const handleCarouselSelect = (selectedIndex) => {
    const prevIndex = carouselIndex;
    const size = 3;

    // Handle displaying only three image previews at a time, and changing the previews when moving to the next group
    if (selectedIndex === 0 && prevIndex === assetImages.length - 1) {
      setVisibleIndices([0, 1, 2]);
    } else if (selectedIndex > prevIndex && selectedIndex % size === 0) {
      setVisibleIndices([selectedIndex, selectedIndex + 1, selectedIndex + 2]);
    } else if (selectedIndex < prevIndex && prevIndex % size === 0) {
      setVisibleIndices([selectedIndex - 2, selectedIndex - 1, selectedIndex]);
    }
    setCarouselIndex(selectedIndex);
  };

  const renderPreviews = () => {
    if (!assetImages.length) return null;
    const images = visibleIndices.map((i) => assetImages[i]);

    return images.map((image, i) =>
      image ? (
        <div
          key={image.name}
          onClick={() => handleCarouselSelect(visibleIndices[i])}
          className={classNames('general-info-assets-carousel-preview__card', {
            'general-info-assets-carousel-preview__card--active':
              visibleIndices[i] === carouselIndex,
          })}
          aria-hidden="true"
        >
          <ImgWithPlaceholder
            src={image?.url}
            placeholder={<FontAwesomeIcon icon={['far', 'box-full']} />}
          />
        </div>
      ) : null
    );
  };

  return (
    <div className="general-info-assets">
      <div className="general-info-assets-carousel-container">
        <div className="general-info-assets-carousel-preview">{renderPreviews()}</div>
        <div className="general-info-assets-carousel">
          <Carousel
            prevIcon={
              assetImages.length > 1 && (
                <span className="oi oi-chevron-left" title="chevron left" aria-hidden="true" />
              )
            }
            nextIcon={
              assetImages.length > 1 && (
                <span className="oi oi-chevron-right" title="chevron right" aria-hidden="true" />
              )
            }
            activeIndex={carouselIndex}
            onSelect={handleCarouselSelect}
            indicators={false}
            interval={null}
          >
            {assetImages.length ? (
              assetImages.map((image) => (
                <Carousel.Item key={image.name}>
                  <div className="general-info-assets-carousel-img-wrapper">
                    <ImgWithPlaceholder
                      src={image?.url}
                      placeholder={<FontAwesomeIcon icon={['far', 'box-full']} />}
                    />
                  </div>
                </Carousel.Item>
              ))
            ) : (
              <Carousel.Item>
                <div className="general-info-assets-carousel-img-wrapper">
                  <FontAwesomeIcon icon={['far', 'box-full']} />
                </div>
              </Carousel.Item>
            )}
          </Carousel>
          <div className="general-info-assets-carousel-caption">
            {carouselIndex + 1} / {assetImages.length || 1}
          </div>
        </div>
      </div>

      <AssetActions
        assetDocuments={assetDocuments}
        assetImages={assetImages}
        setAssetDocuments={setAssetDocuments}
        setAssetImages={setAssetImages}
        productId={product?.id}
      />
    </div>
  );
};

Assets.defaultProps = {
  assets: null,
  product: null,
};

Assets.propTypes = {
  assets: PropTypes.array,
  product: PropTypes.object,
};

export { Assets };
