import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { BOT_STATUSES } from 'constants/BotConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { EVENTS, FSEvent } from 'helpers/logging';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { UserUtils } from 'helpers/UserUtils';
import { downloadExcelFile, downloadFile } from 'helpers/Utils';

import { DatabotCard } from './DatabotCard';

const Databot = ({ parentManufacturerId, manufacturerId, type, dataJobId, clientId, bot }) => {
  const [botStatusData, setBotStatusData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();

  const databotsUrlWithMfrQuery = useCallback(() => {
    let url = `/databots/${bot.slug}`;
    const iParams = queryString.parse(location?.search);

    const oParams = {};

    if ((iParams.manufacturer_id && activeUser?.role === UserRoleConstants.ADMIN) || manufacturerId)
      oParams.manufacturer_id = manufacturerId;

    if (type) oParams.type = type;

    if (dataJobId) oParams.data_request_id = dataJobId;

    if (Object.keys(oParams).length) {
      url += `?${queryString.stringify(oParams)}`;
    }

    return url;
  }, [activeUser?.role, bot.slug, manufacturerId, location?.search, dataJobId, type]);

  const handleConfigRedirect = () => {
    history.push(databotsUrlWithMfrQuery(), {
      manufacturerId,
      parentManufacturerId,
    });
  };

  const viewDetails = () => {
    const url = `${databotsUrlWithMfrQuery()}#details`;
    history.push(url, {
      manufacturerId,
      parentManufacturerId,
    });
  };

  const runBot = () => {
    if (UserUtils.isReadOnly(activeUser)) {
      toast.error("You don't have permission to perform this action");
      return;
    }

    setIsLoading(true);
    if (
      (bot.configurable && bot.status === BOT_STATUSES.NOT_RUN) ||
      bot.status === BOT_STATUSES.AWAITING_APPROVAL
    ) {
      handleConfigRedirect();
      return;
    }

    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/bots/status`,
      data: {
        ...(manufacturerId && {
          manufacturer_id: manufacturerId,
        }),
        ...(clientId && {
          client_id: clientId,
        }),
        bot_id: botStatusData.botId,
        ...(type && { type }),
        ...(dataJobId && { data_request_id: dataJobId }),
      },
      onSuccess: (res) => {
        const {
          id,
          status,
          queued_at: queuedAt,
          finished_at: finishedAt,
          has_downloaded: hasDownloaded,
        } = res.data;
        if (isMounted.current) {
          setBotStatusData({
            ...botStatusData,
            status,
            queuedAt,
            id,
            finishedAt,
            hasDownloaded,
          });
        }
      },
      onEnd: () => {
        setIsLoading(false);
        if (bot.configurable) {
          handleConfigRedirect();
        }
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const downloadReport = () => {
    const isAsset = !!botStatusData?.assetName?.length;

    if (isAsset) {
      const assetUrl = `${ApiCalls.BASE_API_URL}/bots/status/${botStatusData.id}/report?name=${
        botStatusData.assetName
      }&token=${getAuthToken()}`;

      downloadFile(assetUrl);

      return;
    }

    setIsLoading(true);
    let downloads = Number.parseInt(botStatusData.downloads);

    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/status/${botStatusData.id}/report`,
      responseType: 'arraybuffer',
      onSuccess: (res) => {
        downloadExcelFile(res.data, res.headers);
        downloads += 1;
      },
      onEnd: () => {
        if (isMounted.current) {
          setBotStatusData({
            ...botStatusData,
            downloads,
            hasDownloaded: true,
          });
        }
        FSEvent(EVENTS.DATABOT_REPORT_DOWNLOADED, {
          botStatusId: botStatusData.id,
          downloaderId: activeUser?.id,
          downloaderEmail: activeUser?.email,
        });
        setIsLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const checkStatus = () => {
    if (!isMounted.current) return;
    setIsLoading(true);
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/status/${botStatusData.id}`,
      onSuccess: (res) => {
        const { status, completed } = res.data;
        if (isMounted.current) setBotStatusData({ ...botStatusData, status, completed });
      },
      onEnd: () => {
        setIsLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  useEffect(() => {
    const {
      id,
      status,
      avgRuntime,
      downloads,
      completed,
      running,
      name,
      description,
      slug,
      reportable,
      descriptionRunning,
      descriptionCompleted,
      botId,
      hasDetails,
      requiresApproval,
      in_progress: inProgress,
      queued_at: queuedAt,
      finished_at: finishedAt,
      report_id: reportId,
      has_downloaded: hasDownloaded,
      started_by: startedBy,
      report_name: reportName,
      asset_name: assetName,
    } = bot;
    setIsLoading(true);
    setBotStatusData({
      id,
      status,
      queuedAt,
      finishedAt,
      avgRuntime,
      inProgress,
      downloads,
      completed,
      reportable,
      running,
      reportId,
      description,
      slug,
      descriptionRunning,
      hasDetails,
      descriptionCompleted,
      hasDownloaded,
      startedBy,
      requiresApproval,
      name,
      botId,
      reportName,
      assetName,
    });
    setIsLoading(false);
  }, [bot]);

  return (
    <DatabotCard
      data={botStatusData}
      runBot={runBot}
      viewDetails={viewDetails}
      manufacturer={manufacturerId}
      isLoading={isLoading}
      checkStatus={checkStatus}
      downloadReport={downloadReport}
    />
  );
};

Databot.defaultProps = {
  parentManufacturerId: null,
  manufacturerId: null,
  clientId: null,
};

Databot.propTypes = {
  parentManufacturerId: PropTypes.number,
  manufacturerId: PropTypes.number,
  clientId: PropTypes.number,
  bot: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    descriptionRunning: PropTypes.string,
    descriptionCompleted: PropTypes.string,
    status: PropTypes.string,
    avgRuntime: PropTypes.number,
    in_progress: PropTypes.number,
    downloads: PropTypes.number,
    completed: PropTypes.number,
    running: PropTypes.number,
    reportable: PropTypes.bool,
    configurable: PropTypes.bool,
    slug: PropTypes.string,
    hasDetails: PropTypes.bool,
    has_downloaded: PropTypes.bool,
    requiresApproval: PropTypes.bool,
    finished_at: PropTypes.string,
    report_id: PropTypes.number,
    bot_id: PropTypes.number,
    queued_at: PropTypes.string,
    report_name: PropTypes.string,
  }).isRequired,
};

export { Databot };
