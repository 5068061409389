import React, { useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';

import { BotConstants } from '../../../../../constants/BotConstants';
import { StyledMultiselect } from '../../../../common/StyledMultiselect/StyledMultiselect';
import { BotFormLabel } from '../../_components/BotFormLabel';
import { CdsModels } from '../../CdsModels';
import { CdsGptSourcesForm } from './CdsGptSourcesForm';

const COLUMN_MAPPING_OPTIONS = {
  Keywords: {
    multi: true,
    optional: true,
  },
  'Item Grade': {
    optional: true,
  },
};

const ColumnStateEvents = {
  SELECTED_HEADERS_CHANGED: 'SELECTED_HEADERS_CHANGED',
  COLUMN_CONTENT_CHANGED: 'COLUMN_CONTENT_CHANGED',
  UPDATE_STATE: 'UPDATE_STATE',
};

const initializeFormState = ({ state, columns }) => {
  return {
    initialColumns: [...columns],
    additionalHeaders: (state.additional_headers || []).map((header) => ({
      label: header,
      value: header,
    })),
    headerMapping: state.header_mapping || {},
    headerChoices: [...columns],
    headerMappingChoices: columns.map((header) => ({ label: header, value: header })),
    selectedHeaders: state.columns_to_concat || [],
    selectedAdditionalKeywords: BotConstants.GPT_ADDITIONAL_KEY_WORDS.filter((keyword) =>
      (state.additional_keywords || []).includes(keyword.value)
    ),
  };
};

const handleFormStateUpdate = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case ColumnStateEvents.SELECTED_HEADERS_CHANGED:
      newState = {
        ...state,
        additionalHeaders: payload || [],
      };
      break;
    case ColumnStateEvents.COLUMN_CONTENT_CHANGED:
      if (
        JSON.stringify(payload.additional_headers) !== JSON.stringify(state.additional_headers) ||
        JSON.stringify(payload.header_mapping) !== JSON.stringify(state.header_mapping)
      ) {
        newState = {
          ...state,
          ...payload,
        };
      } else {
        newState = state;
      }
      break;

    case ColumnStateEvents.UPDATE_STATE:
      newState = {
        ...state,
        [action.field]: payload,
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
};

/**
 * Component that allows the user to define configure the Sources for the CDS tool
 *
 * @param columns {string[]} - The list of columns to map
 * @param state {{additional_headers: string[], header_mapping: Object}} - The current state
 * @param setState {function} - Callback to set the state
 * @param mandatoryColumns {string[]} - The list of mandatory columns
 * @param disabled {boolean} - If true, the component is disabled
 * @param setInvalid {function} - Callback to set if the component is invalid
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CdsFeatureBenefitsSourceForm = ({
  columns,
  state,
  setState,
  mandatoryColumns = [],
  disabled = false,
  setInvalid = undefined,
}) => {
  const [formState, onFormStateUpdated] = useReducer(
    handleFormStateUpdate,
    { state, columns },
    initializeFormState
  );

  useEffect(() => {
    setState({
      additional_headers: formState.additional_headers,
      header_mapping: formState.header_mapping,
      item_grade: formState.selectedItemGrade?.value,
      additional_keywords: formState.selectedAdditionalKeywords
        ? formState.selectedAdditionalKeywords.map((keyword) => keyword.value)
        : [],
    });
  }, [formState, setState]);

  return (
    <>
      <section className="header-mapping">
        <CdsGptSourcesForm
          columns={columns}
          mandatoryColumns={mandatoryColumns}
          state={state}
          setState={(v) => {
            onFormStateUpdated({
              type: ColumnStateEvents.COLUMN_CONTENT_CHANGED,
              payload: v,
            });
          }}
          disabled={formState.isLoading || disabled}
          setInvalid={(e) => setInvalid(!disabled && e)}
          mappingOptions={COLUMN_MAPPING_OPTIONS}
        />
      </section>

      <section className="header-selection">
        <div className="content">
          <BotFormLabel
            label="Item Grade"
            type="subsection"
            tooltip={CdsModels.CDS_TOOL_TIP.itemGrade}
          />
          <StyledMultiselect
            canReset
            isClearable
            isMulti={false}
            closeMenuOnSelect
            values={formState.selectedItemGrade}
            setOnChange={(v) => {
              onFormStateUpdated({
                payload: v,
                type: ColumnStateEvents.UPDATE_STATE,
                field: 'selectedItemGrade',
              });
            }}
            options={BotConstants.GPT_GRADE_ITEMS}
            getOptionValue={(option) => option.value}
            isSearchable
            optionsSort
            isDisabled={
              formState.isLoading || formState.headerMapping['Item Grade'] !== undefined || disabled
            }
          />
        </div>
      </section>

      <section className="header-selection">
        <div className="content">
          <BotFormLabel
            label="Extra keywords"
            type="subsection"
            tooltip={CdsModels.CDS_TOOL_TIP.extraKeywords}
          />
          <StyledMultiselect
            placeholder="Select or type keywords"
            values={formState.selectedAdditionalKeywords}
            options={BotConstants.GPT_ADDITIONAL_KEY_WORDS}
            setOnChange={(e) =>
              onFormStateUpdated({
                payload: e,
                type: ColumnStateEvents.UPDATE_STATE,
                field: 'selectedAdditionalKeywords',
              })
            }
            op
            getOptionValue={(option) => option.value}
            allowUserAddCustomValue
            closeMenuOnSelect={false}
            isClearable
            isSearchable
            optionsSort
            isMulti
            isDisabled={formState.isLoading || disabled}
          />
        </div>
      </section>
    </>
  );
};

CdsFeatureBenefitsSourceForm.propTypes = {
  columns: PropTypes.array.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  mandatoryColumns: PropTypes.array,
  disabled: PropTypes.bool,
  setInvalid: PropTypes.func,
};

CdsFeatureBenefitsSourceForm.defaultProps = {
  mandatoryColumns: [],
  disabled: false,
  setInvalid: undefined,
};

export { CdsFeatureBenefitsSourceForm };
