import React, { useCallback, useEffect, useRef, useState } from 'react';

import './ImgBotEngineDashboard.scss';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import useBus from 'use-bus';
import {
  DateParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

import * as ApiCalls from 'api/ApiCalls';
import { BAIDatePicker } from 'components/common/DatePicker/DatePicker';
import { RootHooks } from 'helpers/RootHooks';
import { formatDate8601 } from 'helpers/TimeUtils';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { isRoleAdmin, isRoleSelfService } from 'helpers/UserUtils';

import { EventTypeConstants } from '../../constants/EventTypeConstants';
import { getRootMenuPortalTarget } from '../../helpers/Utils';
import { StyledMultiselect } from '../common/StyledMultiselect/StyledMultiselect';
import { ImgBotEngineLogTable } from './_private/ImgBotEngineLogTable';
import { NewIdsJobSection } from './_private/NewIdsJobSection';
import './_private/JobActions.scss';
import './_private/ImgBotEngineModalTrigger.scss';
import { isArray } from 'lodash';

/**
 * Dashboard panel for IDS jobs. Renders log table and optionally a creation form
 *
 * @param {boolean} enableUploader Show creation form
 * @param {integer} pageSize Page size, used in pagination
 * @param {integer} itemsLimit Row limit for log table
 * @param {function} onSetIsLoading Global loader setter
 * @param showGetStarted Whether to show the "Get Started" button
 * @param enableFilters Whether to show the filters
 *
 * @return JSX.Element
 */
const ImgBotEngineDashboard = ({
  enableUploader,
  pageSize,
  itemsLimit,
  onSetIsLoading,
  showGetStarted = false,
  enableFilters = false,
}) => {
  const DATES_FILTER_KEYS = [
    'created_at_after',
    'updated_at_after',
    'created_at_before',
    'updated_at_before',
  ];
  const FILTER_KEYS = ['search', 'client_id', 'manufacturer_id', 'started_by'];

  const menuPortalTarget = getRootMenuPortalTarget();
  const isMounted = useIsMounted();
  const history = useHistory();
  const location = useLocation();
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    client_id: NumberParam,
    created_at_after: DateParam,
    created_at_before: DateParam,
    updated_at_after: DateParam,
    updated_at_before: DateParam,
    manufacturer_id: NumberParam,
    search: StringParam,
    started_by: DelimitedNumericArrayParam,
  });

  const [searchText, setSearchText] = useState(query?.search || '');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [lastFilter, setLastFilter] = useState({ ...query });
  const [logData, setLogData] = useState(null);
  const [startedByUsers, setStartedByUsers] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [clients, setClients] = useState([]);

  const [isLoadingLogData, setIsLoadingLogData] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isNewJobInProgress, setIsNewJobInProgress] = useState(false);
  const [isIdsModalOpen, setIsIdsModalOpen] = useState(false);

  const { activeUser } = RootHooks.useActiveUser();
  const isUserSelfService = isRoleSelfService(activeUser);
  const isAdmin = isRoleAdmin(activeUser);
  const searchFieldRef = useRef();

  const MAX_CHARACTER_SEARCH_QUERY = 200;

  // set url params for manufacturer hierarchy
  const [tableState, setTableState] = useState({
    page: query.page || 1,
    total: 0,
    pageSize: pageSize > 0 ? pageSize : 20,
    itemsLimit,
  });

  const selectStartedBy = useCallback(() => {
    const options = query.started_by?.length ? query.started_by : [];
    return startedByUsers.filter((item) => options.some((userId) => item.value === userId));
  }, [query.started_by, startedByUsers]);

  const selectManufacturer = useCallback(() => {
    return manufacturers.filter((item) => item.value === query.manufacturer_id);
  }, [query.manufacturer_id, manufacturers]);

  const selectClient = useCallback(() => {
    return clients.filter((item) => item.value === query.client_id);
  }, [query.client_id, clients]);

  const selectedStartedBy = selectStartedBy();
  const selectedManufacturer = selectManufacturer();
  const selectedClient = selectClient();

  const checkFilterNeedReset = (query) => {
    const initialFilter = {
      search: undefined,
      started_by: undefined,
      manufacturer_id: undefined,
      client_id: undefined,
      updated_at_after: undefined,
      created_at_after: undefined,
      updated_at_before: undefined,
      created_at_before: undefined,
      page: 1,
    };
    return !Object.keys(initialFilter).every((key) => initialFilter[key] === query[key]);
  };

  const filterUsers = (res) => {
    return res?.map((user) => {
      return {
        value: user.user_id,
        label:
          user.first_name || user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.username,
      };
    });
  };

  useEffect(() => {
    if (enableFilters && !isUserSelfService) {
      const params = { 'small-view': true };
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/parents/manufacturers',
        params,
        onSuccess: (res) => {
          if (res?.data?.length) {
            const _manufacturers = res.data.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            });
            setManufacturers(_manufacturers);
          }
        },
      });

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/parents/distributors',
        params,
        onSuccess: (res) => {
          if (res?.data?.length) {
            const _clients = res.data.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            });
            setClients(_clients);
          }
        },
      });

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/bots/process-job/log-table/started-by',
        params: {},
        onSuccess: (res) => {
          if (res?.data?.results?.length) {
            const users = filterUsers(res.data.results);
            setStartedByUsers(users);
          }
        },
      });
    }
  }, [enableFilters, isUserSelfService]);

  const areArraysEqual = (firstArray, secondArray) => {
    if (firstArray.length !== secondArray.length) return false;
    for (let i = 0; i < firstArray.length; i++) {
      if (firstArray[i] !== secondArray[i]) return false;
    }
    return true;
  };
  const checkFilterChange = (currentFilter, lastFilter) => {
    const searchFilters = !FILTER_KEYS.every((key) => {
      // when is back from detail page query set as DelimitedNumericArrayParam
      // compare start_by when is an array instead the string
      if (Array.isArray(lastFilter.started_by)) {
        if (!areArraysEqual(currentFilter.started_by, lastFilter.started_by)) {
          return true;
        }
      }
      return currentFilter[key] === lastFilter[key];
    });

    const datesFilters = !DATES_FILTER_KEYS.every((key) => {
      const currentHasKey = currentFilter[key] !== undefined && currentFilter[key] !== null;
      const lastHasKey = lastFilter[key] !== undefined && lastFilter[key] !== null;

      if (currentHasKey && lastHasKey) {
        // normalizing dates to compare
        const currentFilterDateStr =
          typeof currentFilter[key] === 'string'
            ? currentFilter[key]
            : formatDate8601(currentFilter[key]);
        const lastFilterDateStr =
          typeof lastFilter[key] === 'string' ? lastFilter[key] : formatDate8601(lastFilter[key]);
        return currentFilterDateStr === lastFilterDateStr;
      }
      // check key changes
      return currentHasKey === lastHasKey;
    });
    return searchFilters || datesFilters;
  };

  const buildFilters = () => {
    const page = query.page || tableState.page;
    const filters = {
      limit: tableState.pageSize,
      offset: page ? (page - 1) * tableState.pageSize : 0,
      manufacturer_id: query.manufacturer_id,
      client_id: query.client_id,
      started_by: query?.started_by?.map((item) => item).join(','),
      search: query.search,
    };

    DATES_FILTER_KEYS.forEach((key) => {
      if (query[key] !== undefined && query[key] !== null) {
        filters[key] = formatDate8601(query[key]);
      }
    });

    if (checkFilterChange(filters, lastFilter)) {
      filters.offset = 0; // Reset offset to first page
      setQuery({ ...query, page: 1 });
      setLastFilter({ ...filters });
    }
    setLastFilter({ ...lastFilter, offset: filters.offset });
    return filters;
  };

  // TODO: Future refactoring - extract calls to reusable util
  const doLoadLogData = useCallback(
    (timeoutId = undefined, _isIdsModalOpen = false) => {
      const searchInFocus = document.activeElement === searchFieldRef.current;
      setIsLoadingLogData(true);
      const reqParams = buildFilters();

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/bots/process-job/log-table/ids`,
        params: reqParams,
        onSuccess: (res) => {
          if (isMounted.current && !_isIdsModalOpen) {
            if (res?.data?.results?.length) {
              setLogData(res?.data?.results);
              setTableState((t) => ({
                pageSize: t.pageSize,
                page: query.page || t.page,
                total: res?.data?.total || 0,
                itemsLimit: itemsLimit || res?.data?.total || 0,
              }));
              setLastFilter({ ...reqParams });
            } else {
              setLogData(null);
            }
          }
        },
        onEnd: () => {
          if (isMounted.current) {
            setIsLoadingLogData(false);
            if (timeoutId !== undefined) {
              clearTimeout(timeoutId);
            }
            if (searchInFocus) {
              searchFieldRef.current?.focus();
            }
          }
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      itemsLimit,
      isMounted,
      query.page,
      query.started_by,
      query.created_at_after,
      query.updated_at_after,
      query.created_at_before,
      query.updated_at_before,
      query.search,
      query.manufacturer_id,
      query.client_id,
    ]
  );

  const resetTableState = (forceDoLoading = false) => {
    const initialFilter = {
      search: undefined,
      started_by: undefined,
      manufacturer_id: undefined,
      client_id: undefined,
      created_at_after: undefined,
      created_at_before: undefined,
      updated_at_after: undefined,
      updated_at_before: undefined,
      page: 1,
    };

    // if the filter in initial state and
    // force doLoadLogData to update the table
    // after onRunAssessment and onCancelJob
    if (!checkFilterNeedReset(query) && forceDoLoading) {
      doLoadLogData();
    }

    setSearchText('');
    setQuery({ ...initialFilter }, 'push');
    setTableState({
      ...tableState,
      page: 1,
    });
  };

  const handleTableStateChanged = (page, pageSize) => {
    setQuery({ ...query, page });
    setTableState({ page, pageSize, total: tableState.total, itemsLimit });
  };

  const handleDateFilterChanged = (range, which) => {
    if (['created_at', 'updated_at'].includes(which)) {
      const [startDate, endDate] = range;
      setQuery(
        {
          ...query,
          [`${which}_after`]: startDate || undefined,
          [`${which}_before`]: endDate || undefined,
        },
        'push'
      );
    }
  };

  const handleFilterChanged = (selectedOptions, options, queryKey) => {
    let selected;

    switch (queryKey) {
      case 'started_by':
        selected = selectedOptions ? selectedOptions.map((user) => user.value) : [];
        setQuery({ ...query, started_by: selected?.length ? selected : undefined }, 'push');
        break;
      default:
        selected = options?.find((option) => option.value === selectedOptions?.value) || undefined;
        setQuery({ ...query, [queryKey]: selected ? selected?.value : undefined }, 'push');
        break;
    }
  };

  useBus(EventTypeConstants.IDS_MODAL_STATE_CHANGED, (extra) => {
    if (extra?.state === 'open') {
      setIsIdsModalOpen(true);
    } else if (extra?.state === 'closed') {
      setIsIdsModalOpen(false);
    }
  });

  useEffect(() => {
    doLoadLogData();
  }, [doLoadLogData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isIdsModalOpen && !isLoadingLogData) {
        doLoadLogData(timer, isIdsModalOpen);
      }
    }, 30 * 1000);
    return () => clearInterval(timer);
  }, [doLoadLogData, isLoadingLogData, isIdsModalOpen]);

  useEffect(() => {
    if (typeof onSetIsLoading === 'function') onSetIsLoading(!!isLoadingLogData);
  }, [isLoadingLogData, onSetIsLoading]);

  const onRunAssessment = ({
    jobId,
    file,
    selectedUrlHeader,
    selectedSkuHeader,
    customConfig,
    onSuccess,
    assessmentConfigId = undefined,
    enhancementConfigId = undefined,
  }) => {
    if (isLoadingAction) {
      return;
    }
    setIsLoadingAction(true);

    const reqData = {
      file_name: file,
    };

    if (selectedUrlHeader?.length) {
      reqData.header = selectedUrlHeader;
    }

    if (selectedSkuHeader?.length) {
      reqData.product_code_header = selectedSkuHeader;
    }

    if (customConfig) {
      reqData.config_data = customConfig;
    }

    if (assessmentConfigId !== undefined) {
      reqData.assessment_config_id = assessmentConfigId;
    }

    if (enhancementConfigId !== undefined) {
      reqData.enhancement_config_id = enhancementConfigId;
    }

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/bots/process-job/${jobId}`,
      data: reqData,
      onSuccess: (res) => {
        if (res?.data) {
          toast.success('Assessment started...');
          doLoadLogData();
          // resetTableState(true);
          if (typeof onSuccess === 'function') {
            onSuccess(res);
          }
        }
      },
      onEnd: () => setIsLoadingAction(false),
    });
  };

  const onRunEnhancement = ({
    jobId,
    onSuccess,
    customConfig,
    enhancementConfigId = undefined,
  }) => {
    if (isLoadingAction) {
      return;
    }
    setIsLoadingAction(true);

    const reqData = {
      ...customConfig,
    };

    if (enhancementConfigId !== undefined) {
      reqData.enhancement_config_id = enhancementConfigId;
    }
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/bots/process-job/${jobId}/enhancement`,
      data: reqData,
      onSuccess: (res) => {
        if (res?.data) {
          toast.success('Enhancement started...');
          doLoadLogData();
          // resetTableState(true);
          if (typeof onSuccess === 'function') {
            onSuccess(res);
          }
        }
      },
      onEnd: () => setIsLoadingAction(false),
    });
  };

  const onCancelJob = ({ jobId }) => {
    if (isLoadingAction) {
      return;
    }
    setIsLoadingAction(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/bots/process-job/cancel/${jobId}`,
      onSuccess: (res) => {
        if (res?.data) {
          toast.info('IDS job was canceled');
          doLoadLogData();
          // resetTableState(true);
        }
      },
      onEnd: () => setIsLoadingAction(false),
    });
  };

  const handleTextSearch = (value) => {
    setSearchText(value);
    if (typingTimeout) clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(
        () => setQuery({ ...query, search: value?.length ? value : undefined }, 'push'),
        500
      )
    );
  };

  const onJobCreated = () => {
    doLoadLogData();
    resetTableState();
  };

  return (
    <>
      <div className="img-bot-engine-dashboard">
        {!!enableUploader && (
          <NewIdsJobSection
            onRunAssessment={onRunAssessment}
            onJobCreated={() => onJobCreated()}
            setIsNewJobInProgress={setIsNewJobInProgress}
          />
        )}

        {enableFilters && (
          <div className="ids-filters-row">
            <div
              className={classNames('ids-filters-container', {
                admin: isAdmin,
                'self-service': isUserSelfService,
              })}
            >
              <div className="ids-filter keyword">
                <div className="note">Filter by Keyword</div>
                <Form.Control
                  ref={searchFieldRef}
                  value={searchText}
                  placeholder="ID, File Name (comma separated)"
                  onChange={(e) => handleTextSearch(e.target.value)}
                  aria-label="Filter by Keyword"
                  isInvalid={searchText?.length >= MAX_CHARACTER_SEARCH_QUERY}
                  disabled={isLoadingLogData || isNewJobInProgress}
                />
                <Form.Control.Feedback type="invalid">
                  Max characters reached.
                </Form.Control.Feedback>
              </div>
              {!isUserSelfService && (
                <div className="ids-filter started-by">
                  <div className="note">Filter by User</div>
                  <StyledMultiselect
                    isSearchable
                    defaultValue={[]}
                    values={selectedStartedBy}
                    options={startedByUsers}
                    setOnChange={(e) => handleFilterChanged(e, startedByUsers, 'started_by')}
                    getOptionValue={(option) => option.value}
                    isClearable
                    canReset
                    isMulti
                    closeMenuOnSelect={false}
                    menuPortalTarget={menuPortalTarget}
                    isDisabled={isLoadingLogData || isNewJobInProgress}
                  />
                </div>
              )}
              {isAdmin && (
                <>
                  <div className="ids-filter manufacturer">
                    <div className="note">Filter by Manufacturer</div>
                    <StyledMultiselect
                      isSearchable
                      defaultValue={[]}
                      values={selectedManufacturer}
                      options={manufacturers}
                      setOnChange={(e) => handleFilterChanged(e, manufacturers, 'manufacturer_id')}
                      getOptionValue={(option) => option.value}
                      isClearable
                      canReset
                      isMulti={false}
                      menuPortalTarget={menuPortalTarget}
                      isDisabled={isLoadingLogData || isNewJobInProgress}
                    />
                  </div>
                  <div className="ids-filter client">
                    <div className="note">Filter by Client</div>
                    <StyledMultiselect
                      isSearchable
                      defaultValue={[]}
                      values={selectedClient}
                      options={clients}
                      setOnChange={(e) => handleFilterChanged(e, clients, 'client_id')}
                      getOptionValue={(option) => option.value}
                      isClearable
                      canReset
                      isMulti={false}
                      menuPortalTarget={menuPortalTarget}
                      isDisabled={isLoadingLogData || isNewJobInProgress}
                    />
                  </div>
                </>
              )}
              <div className="ids-filter created-at">
                <div className="note">Filter by Created Date</div>
                <BAIDatePicker
                  isClearable
                  popperPlacement="auto"
                  dateFormat="MMMM d, yyyy"
                  className="bai-due-date-picker-value-input__control"
                  selected={query.created_at_after}
                  onChange={(v) => handleDateFilterChanged(v, 'created_at')}
                  startDate={query.created_at_after}
                  endDate={query.created_at_before}
                  selectsRange
                  disabledKeyboardNavigation
                  disabled={isLoadingLogData || isNewJobInProgress}
                />
              </div>

              <div className="ids-filter updated-at">
                <div className="note">Filter by Updated Date</div>
                <BAIDatePicker
                  isClearable
                  popperPlacement="auto"
                  dateFormat="MMMM d, yyyy"
                  className="bai-due-date-picker-value-input__control"
                  selected={query.updated_at_after}
                  onChange={(v) => handleDateFilterChanged(v, 'updated_at')}
                  startDate={query.updated_at_after}
                  endDate={query.updated_at_before}
                  selectsRange
                  disabledKeyboardNavigation
                  disabled={isLoadingLogData || isNewJobInProgress}
                />
              </div>
            </div>
            <div className="ids-filters-actions">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => resetTableState()}
                disabled={isLoadingLogData || isNewJobInProgress}
              >
                Reset
              </button>
            </div>
          </div>
        )}
        <ImgBotEngineLogTable
          logData={logData}
          isLogDataEmpty={!logData?.length}
          onRunAssessment={onRunAssessment}
          onRunEnhancement={onRunEnhancement}
          onCancelJob={onCancelJob}
          isLoading={isLoadingLogData || isLoadingAction}
          tableState={tableState}
          onTableStateChanged={(page, pageSize) => handleTableStateChanged(page, pageSize)}
          onGetStarted={
            showGetStarted
              ? () => history.push(`/image-databot-suite${location?.search}`)
              : undefined
          }
        />
      </div>
    </>
  );
};

export { ImgBotEngineDashboard };
