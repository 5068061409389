import ActionTypeConstants from 'constants/ActionTypeConstants';

// User actions

/** @deprecated */
export const submitUserLogin = ({ username, password, nextLocation }) => ({
  type: ActionTypeConstants.SUBMIT_USER_LOGIN,
  payload: { username, password, nextLocation },
});

/** @deprecated */
export const submitUserLogout = () => ({
  type: ActionTypeConstants.SUBMIT_USER_LOGOUT,
});

/** @deprecated */
export const getUserProfile = () => ({
  type: ActionTypeConstants.GET_USER_PROFILE,
});

// Product actions

// TODO: Used only in ViewProducts
/** @deprecated */
export const getAllProducts = ({ filter, sort_field, sort_order, offset, limit }) => ({
  type: ActionTypeConstants.GET_ALL_PRODUCTS,
  payload: { filter, sort_field, sort_order, offset, limit },
});

// TODO: Part of statistics
/** @deprecated */
export const getSupplierStatistics = () => ({
  type: ActionTypeConstants.GET_SUPPLIER_STATISTICS,
});

// TODO: Part of statistics
/** @deprecated */
export const getSupplierStatisticsReset = () => ({
  type: ActionTypeConstants.GET_SUPPLIER_STATISTICS_RESET,
});

// TODO: Remove and cleanup old code - used only in ViewDataTeamActions - remove if view not needed.
/** @deprecated */
export const getAllManufacturers = () => ({
  type: ActionTypeConstants.GET_ALL_MANUFACTURERS,
});

// TODO: Remove and cleanup old code - still used in Flash Assessment and View Data Request Details components
/** @deprecated */
export const getDataRequestDeliveryFormats = () => ({
  type: ActionTypeConstants.GET_DATA_REQUEST_DELIVERY_FORMATS,
});
