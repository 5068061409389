import './DatabotConfigModal.scss';

import React from 'react';

import classNames from 'classnames';

import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';

const DatabotConfigModal = ({ header, className, onSubmit, children, body }) => {
  const onClickSubmit = (setIsVisible) => {
    onSubmit({ setIsVisible });
  };

  const memoizedFooter = ({ setIsVisible }) => (
    <>
      <span
        tabIndex={0}
        role="button"
        onKeyDown={() => setIsVisible(false)}
        onClick={() => setIsVisible(false)}
        className="footer-btn footer-btn--secondary"
      >
        Cancel
      </span>
      <span
        tabIndex={0}
        role="button"
        onKeyDown={() => onClickSubmit(setIsVisible)}
        className="footer-btn footer-btn--primary"
        onClick={() => onClickSubmit(setIsVisible)}
      >
        Confirm
      </span>
    </>
  );

  return (
    <ModalPanel
      className={classNames('databot-config-modal-panel', className)}
      header={header}
      body={body}
      footer={memoizedFooter}
    >
      {({ setIsVisible }) => typeof children === 'function' && children({ setIsVisible })}
    </ModalPanel>
  );
};

export { DatabotConfigModal };
