import React from 'react';

import 'components/databots/DatabotConfig.scss';
import { ValueBlock } from 'components/common/ValueBlock/ValueBlock';

const ImageQualityReportBot = ({ handleRunBot, handleCancelBot, additionalData }) => {
  const handleSubmit = () => {
    handleRunBot();
  };

  return (
    <div className="databots-config">
      <h1 className="databots-config-heading">Image Quality Report</h1>
      <section className="databots-config-summary">
        <ValueBlock title="Manufacturer" value={additionalData.name} />
        <ValueBlock title="Total Products" value={additionalData.total_products} />
      </section>
      <section className="databots-config-body">
        <div>
          <h2 className="databots-config-body-header">Image Quality Report</h2>
          <p>
            Generates a report containing information on all of your product&apos;s primary images.
            You will be able to see the width, height, and DPI of your images.
          </p>
        </div>
        <div className="databots-config-body-actions">
          <button
            onClick={handleCancelBot}
            className="databots-config-body-actions__action databots-config-body-actions__action--cancel"
            type="button"
          >
            Cancel
          </button>

          <button
            onClick={() => handleSubmit()}
            className="databots-config-body-actions__action databots-config-body-actions__action--submit"
            type="button"
          >
            Submit
          </button>
        </div>
      </section>
    </div>
  );
};

export { ImageQualityReportBot };
