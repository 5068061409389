import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { TableRow } from './TableRow';
import './DraggableTable.scss';

/**
 * Creates a table with rows that can be dragged to rearrange them
 *
 * @param {*} headers array of objects to make up the middle headers, key specifies what data corresponds { header: 'Column Name', key: 'name', id: 1 }
 * @param {*} data array of objects with data. Keys will be tied to the header 'key' for display
 * @param {*} setData function to change the data in the parent
 * @param {*} actions object to specify which row actions are allowed { move: true, delete: false }
 * @returns object
 */

const DraggableTable = ({ headers, data, setData, actions = { move: true, delete: false } }) => {
  const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };

  const arrayMove = useCallback((array, from, to) => {
    array = array.slice();
    arrayMoveMutate(array, from, to);
    return array;
  }, []);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      const reorderedItems = arrayMove(data, oldIndex, newIndex);
      setData(reorderedItems);
    },
    [arrayMove, setData, data]
  );

  const moveUp = (index) => {
    setData((oldItems) => arrayMove(oldItems, index, index - 1));
  };

  const moveDown = (index) => {
    setData((oldItems) => arrayMove(oldItems, index, index + 1));
  };

  const deleteRow = (index) => {
    const newData = data.filter((item, i) => {
      return i !== index;
    });
    setData(newData);
  };

  const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });

  const SortableItem = SortableElement((props) => <TableRow {...props} />);

  return (
    <div className="drag-table">
      {data.length > 0 ? (
        <table className="react-bootstrap-table table">
          <thead>
            <tr>
              <th label="drag">Order</th>
              {headers.map((item) => {
                return <th key={item}>{item?.header}</th>;
              })}
              {actions?.move || actions?.delete ? <th>Actions</th> : null}
            </tr>
          </thead>
          <SortableCont
            onSortEnd={onSortEnd}
            axis="y"
            lockAxis="y"
            lockToContainerEdges
            lockOffset={['30%', '50%']}
            helperClass="helperContainerClass"
            useDragHandle
          >
            {data.map((value, index) => (
              <SortableItem
                key={`item-${index}`}
                index={index}
                order={index}
                firstRow={index === 0}
                lastRow={index + 1 === data.length}
                data={value}
                headers={headers}
                moveUp={moveUp}
                moveDown={moveDown}
                deleteRow={deleteRow}
                actions={actions}
              />
            ))}
          </SortableCont>
        </table>
      ) : null}
    </div>
  );
};

DraggableTable.defaultProps = {
  actions: PropTypes.shape({ move: true, delete: false }),
};

DraggableTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  actions: PropTypes.shape({ move: PropTypes.bool, delete: PropTypes.bool }),
};

export { DraggableTable };
