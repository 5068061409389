import React from 'react';

import { HeaderSelector } from '../../../databots/bots/common/SelectSingleHeaderSection/HeaderSelector';

const headerSelectorStyle = {
  control: (styles) => ({
    ...styles,
    height: '32px',
    minHeight: '32px',
  }),

  valueContainer: (styles) => ({
    ...styles,
    height: '32px',
    minHeight: '32px',
  }),

  indicatorsContainer: (styles) => ({
    ...styles,
    height: '32px',
    minHeight: '32px',
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: '10000',
  }),
};

const IdsHeaderSelection = ({
  selectedUrlHeader,
  setSelectedUrlHeader,
  selectedSkuHeader,
  setSelectedSkuHeader,
  headersList,
  isLoading,
}) => {
  return (
    <>
      <section className="custom-bot-config">
        <div className="custom-config-wrap">
          <section className="custom-config-block header-selector header-selector-url">
            <div className="title">Image URL Column:</div>
            <div className="helper-text">
              <p>
                Select the column header for Image URLs in your uploaded spreadsheet to accurately
                identify and process the image files.
              </p>
            </div>
            <HeaderSelector
              isMulti={false}
              placeholder="URL Column"
              selectedHeader={selectedUrlHeader}
              setSelectedHeader={setSelectedUrlHeader}
              headersList={headersList}
              selectStyle={headerSelectorStyle}
              menuPortalTarget={document.body}
              disabled={isLoading}
            />
          </section>
          <section className="custom-config-block header-selector header-selector-sku">
            <div className="title">Product Code Column:</div>
            <div className="helper-text">
              <p>
                Select the Product Codes column in your file, if available, to enhance product
                identification during processing.
              </p>
            </div>
            <HeaderSelector
              isMulti={false}
              placeholder="Product Code Column"
              selectedHeader={selectedSkuHeader}
              setSelectedHeader={setSelectedSkuHeader}
              headersList={headersList}
              selectStyle={headerSelectorStyle}
              menuPortalTarget={document.body}
              disabled={isLoading}
            />
          </section>
        </div>
      </section>
    </>
  );
};

export { IdsHeaderSelection };
