import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast as _toast } from 'react-toastify';

const doToast = (content, options) => {
  if (!content) {
    console.error('No content for toast', content, options);
    return null;
  }

  const type = (options && options.type) || _toast.TYPE.INFO;

  let icon = ['fas', 'info'];

  let label = null;

  // prettier-ignore
  if (type === _toast.TYPE.SUCCESS) { icon = ['far', 'check']; label = "Success"; }
  else if (type === _toast.TYPE.WARNING) { icon = ['fas', 'bolt']; label = "Warning"; }
  else if (type === _toast.TYPE.ERROR) { icon = ['far', 'times']; label = "Error"; }

  return _toast(
    <div className="toast-content">
      <div className="toast-icon">
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
      </div>
      <div className="toast-inner-content-wrap">
        {label ? <div className="toast-type">{label}</div> : null}
        <div className="toast-inner-content">{content}</div>
      </div>
    </div>,
    {
      type,
      ...options,
    }
  );
};

export const toast = {
  success: (content, options) => doToast(content, { ...options, type: _toast.TYPE.SUCCESS }),
  info: (content, options) => doToast(content, { ...options, type: _toast.TYPE.INFO }),
  warn: (content, options) => doToast(content, { ...options, type: _toast.TYPE.WARNING }),
  error: (content, options) => doToast(content, { ...options, type: _toast.TYPE.ERROR }),
};
