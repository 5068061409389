import React from 'react';

export const BotModalHeader = ({ botId }) => {
  return (
    <>
      <div className="ids-job-action-btns__header">Begin CDS Run</div>
      <div className="ids-job-action-btns__subheader">
        Run# <strong>{botId}</strong>
      </div>
    </>
  );
};
