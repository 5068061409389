import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TODO: Do not use this, use standard buttons
/** @deprecated */
export default function (props) {
  return (
    <div
      onClick={props.onClick}
      className={`distributor-job-details-box-btn ${props.className || ""}`}
    >
      {props.icon ? (
        <span className="distributor-job-details-box-btn__icon">
          <FontAwesomeIcon icon={["far", props.icon]} size="xs" />
        </span>
      ) : null}
      <div className="distributor-job-details-box-btn__label">{props.label}</div>
    </div>
  );
}
