import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

class TagList extends React.Component {
  onRemove(value) {
    if (this.props.onRemove) {
      this.props.onRemove(value);
    }
  }

  renderTagList() {
    let tagList = [];

    if (this.props.value && this.props.value.length) {
      for (let i = 0; i < this.props.value.length; i++) {
        const currVal = this.props.value[i];
        tagList.push(
          <Badge pill variant="primary" key={i}>
            {currVal.label}{" "}
            <span className="icon">
              <FontAwesomeIcon icon={["far", "times"]} onClick={() => this.onRemove(currVal)} />
            </span>
          </Badge>
        );
      }
    }
    return tagList;
  }

  render() {
    return <React.Fragment>{this.renderTagList()}</React.Fragment>;
  }

  static propTypes = {
    value: PropTypes.array,
    onRemove: PropTypes.func,
  };
}

export default TagList;
