import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import BootstrapTable from 'react-bootstrap-table-next';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ViewStatisticsTableDef from 'helpers/table-defs/ViewStatisticsTableDef';
import { getSupplierStatistics, getSupplierStatisticsReset } from 'store/actions/Actions';

// TODO: Remove Redux logic
const _component = ({
  getSupplierStatisticsReset,
  location,
  getSupplierStatistics,
  getSupplierStatisticsReducerResponse,
}) => {
  useEffect(() => {
    return () => {
      getSupplierStatisticsReset(); // Unmount
    };
  }, [getSupplierStatisticsReset]);

  const locationKey = _get(location, 'key');
  useEffect(() => {
    getSupplierStatistics();
  }, [getSupplierStatistics, locationKey]);

  const renderGlobalStatsItem = (value, label, description, faIcon) => {
    if (!label) {
      return null;
    }
    return (
      <div className="block-item">
        <div className="color-strip" />
        <div className="value-container">
          <div className="value">{value?.toLocaleString()}</div>
          <div className="label">{label}</div>
          {faIcon && faIcon.length ? (
            <span className="icon">
              <FontAwesomeIcon icon={['far', faIcon]} />
            </span>
          ) : null}
        </div>
        <div className="description-container">
          <div className="description">{description}</div>
        </div>
      </div>
    );
  };

  const response = getSupplierStatisticsReducerResponse;
  const globalStats = _get(response, 'payload.total_data') || null;
  const supplStats = _get(response, 'payload.statistics') || null;


  return (
    <>
      <div className="wrap-statistics-global">
        <div className="content content-fluid view-statistics-global">
          {renderGlobalStatsItem(
            globalStats?.total_processed,
            'Total Processed',
            'Quantity of processed products in last Backbone Iteration',
            'calendar-check'
          )}
          {renderGlobalStatsItem(
            globalStats?.sds_total,
            'SDS Match',
            'Number of SDS files found',
            'tasks'
          )}
          {renderGlobalStatsItem(
            globalStats?.hazmat_data,
            'Hazmat Data Collected',
            'Number of products with either UN Code or Proper Shipping Name',
            'server'
          )}
        </div>
      </div>

      <div className="content content-fluid view-statistics">
        <Helmet bodyAttributes={{ 'data-page': 'view-statistics' }}>
          <title>Manufacturer Statistics</title>
        </Helmet>

        <div className="bootstrap-table-wrap">
          <BootstrapTable
            bordered={false}
            bootstrap4
            keyField="manufacturer_id"
            data={supplStats || []}
            columns={ViewStatisticsTableDef.columns}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
          />
          {(!supplStats || supplStats?.length < 1) && (
            <div className="description value" style={{marginLeft:"1em"}}>
              <span> No data to display. </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getSupplierStatisticsReducerResponse: {
      status: state.GetSupplierStatisticsReducer.status,
      payload: state.GetSupplierStatisticsReducer.payload,
    },
  };
};

const mapDispatchToProps = {
  getSupplierStatistics,
  getSupplierStatisticsReset,
};

const shouldUpdate = (prevProps, nextProps) => {
  return !!(
    _get(prevProps, 'getSupplierStatisticsReducerResponse.status') ===
    _get(nextProps, 'getSupplierStatisticsReducerResponse.status')
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(_component, shouldUpdate))
);
