import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { Breadcrumbs } from 'components/pdp/Breadcrumbs';
import { GeneralInformation } from 'components/pdp/GeneralInformation';
import { ProcessedAttributes } from 'components/pdp/ProcessedAttributes';
import * as ProductUtils from 'helpers/ProductUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { useWindowSize } from 'helpers/useWindowSize';

const ViewProductDetails = ({ id }) => {
  const isMounted = useIsMounted();
  const [product, setProduct] = useState(null);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const isWindowSmall = useWindowSize().height <= 715;

  useEffect(() => {
    if (!id) {
      setIsProductLoading(false);
      setProduct(null);
      return;
    }

    setIsProductLoading(true);
    ApiCalls.doCall({
      urlPath: `/products/${id}`,
      params: { manufacturer_id: id },
      onSuccess: (res) => {
        if (isMounted.current) {
          if (res?.data) {
            const _data = ProductUtils.transformLegacyProduct(res.data);
            setProduct(_data);
          }
        }
      },
      onEnd: () => {
        if (isMounted.current) {
          setIsProductLoading(false);
        }
      },
    });
  }, [id, isMounted]);

  console.debug('Rendering product', product);
  return (
    <>
      <Helmet>
        <title>Product Details</title>
      </Helmet>
      <div
        className={`content content-fluid ${
          isFilterVisible && isWindowSmall ? 'view-product-details__sm' : 'view-product-details'
        }`}
      >
        {isProductLoading ? (
          <div className="products-top-status">
            <LoadingSpinner style={{ fontSize: '1.5em', marginRight: '0.5em' }} /> Loading data...
          </div>
        ) : null}

        {product?.id ? (
          <>
            <Breadcrumbs taxonomy={product.taxonomy} />
            <div className="product-info-row">
              <GeneralInformation product={product} />
            </div>
            <div className="product-info-row">
              <ProcessedAttributes
                taxonomy={product.taxonomy}
                productId={product.id}
                generalAttributes={product.general_attributes}
                isFilterVisible={isFilterVisible}
                setIsFilterVisible={setIsFilterVisible}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export { ViewProductDetails };
