import React, { useEffect, useState } from 'react';

import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';

import { ColorPicker } from 'components/common/ColorPicker/ColorPicker';
import { isHexWithAlphaValid } from 'helpers/Utils';

import { IdsModels } from './IdsModels';

const valueToSelectedColor = (v) => {
  if (isHexWithAlphaValid(v)) {
    const searchV = `#${v.replace('#', '')}`;
    const item = Object.values(IdsModels.BG_COLOR_OPTIONS).find((x) => x.hex === searchV);
    if (item) {
      return item.key;
    }
    return IdsModels.BG_COLOR_OPTIONS.custom.key;
  }
  if (v in IdsModels.BG_COLOR_OPTIONS) {
    return v;
  }
  return IdsModels.BG_COLOR_OPTIONS.custom.key;
};

const ColorPickerSelect = ({ value, disabled, options, onChange }) => {
  const [selectedColor, setSelectedColor] = useState(valueToSelectedColor(value));

  const [color, setColor] = useState(value);

  useEffect(() => {
    setColor(value);
    setSelectedColor(valueToSelectedColor(value));
  }, [value]);

  const onSelectedColorChange = (c) => {
    setSelectedColor(c);
    setColor(IdsModels.BG_COLOR_OPTIONS[c].hex);
    onChange(IdsModels.BG_COLOR_OPTIONS[c].hex);
  };

  const onColorChange = (c) => {
    setColor(c);
    onChange(c);
  };

  return (
    <>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <Form.Control
          as="select"
          value={selectedColor ?? ''}
          onChange={(e) => onSelectedColorChange(e.target.value)}
          isInvalid={false}
          disabled={disabled}
        >
          {options
            ? options.map((option, i) => (
                <option value={option.key} key={i}>
                  {option.label}
                </option>
              ))
            : []}
        </Form.Control>
      </div>
      {selectedColor === 'custom' && (
        <div style={{ display: 'inline-block', verticalAlign: 'bottom', marginLeft: '8px' }}>
          <ColorPicker selectedColor={color} onColorChange={onColorChange} disabled={disabled} />
        </div>
      )}
    </>
  );
};

ColorPickerSelect.defaultProps = {
  value: '#00000000',
  options: [],
};

ColorPickerSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
};

export { ColorPickerSelect };
