import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { isIE } from 'react-device-detect';
import { useDropzone } from 'react-dropzone';

import './FileUpload.scss';

/*
 * Template to select file (drag & drop or via explorer) or optionally a link or text item and upload.
 *
 * @param {bool} maxFileCount - maximum files that can be uploaded at once
 * @param {function} onDrop - the onClick or onDrop method used when file is selected
 * @param {bool} isFileLoading - boolean for whether the file is currently loading - used to for wheter to show loading display
 * @param {bool} isUploadComplete - boolean for whether file is successfully uploded - used to start timer for succes display
 * @param {function} setIsUploadComplete - function that is called when timer on success display ends
 * @param {function} handleAddLink - function to call when a link is added instead of file
 */
const FileUpload = ({
  maxFileCount = 1,
  onDrop,
  uploadingFileName,
  isFileLoading = false,
  isUploadComplete = false,
  setIsUploadComplete = () => {},
  onSuccessMessage,
  uploadId,
  uploadProgress = 0,
  children,
  infoText,
  onRemoveFile,
  selectedFiles,
  isDisabled = false,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: maxFileCount,
    multiple: maxFileCount !== 1,
  });

  useEffect(() => {
    let timer;
    if (isUploadComplete) {
      timer = setTimeout(() => {
        setIsUploadComplete(false);
      }, 800);
    }
    return () => {
      if (timer && isUploadComplete) {
        clearTimeout(timer);
      }
    };
  }, [isUploadComplete, setIsUploadComplete]);

  useEffect(() => {
    if (!uploadId) {
      setIsUploadComplete(false);
    }
  }, [uploadId, setIsUploadComplete]);

  useEffect(() => {
    return () => {
      setIsUploadComplete(false);
    };
  }, [setIsUploadComplete]);

  const conditionalRenderFunc = () => {
    if (isFileLoading) {
      return (
        <div className="template-upload-attachment">
          <div className="text-center uploading-header">
            <span className="uploading-text"> Uploading: </span>
            <span className="text-secondary">{uploadingFileName}</span>
          </div>
          <div className="template-upload-attachment__progress-wrapper">
            <ProgressBar
              label={`${Number(uploadProgress).toFixed(0)}%`}
              now={Number(uploadProgress).toFixed(0)}
            />
          </div>
        </div>
      );
    }

    if (isUploadComplete) {
      return (
        <div className="template-upload-attachment">
          <div className="template-upload-attachment__info">
            <div className="template-upload-attachment__info-txt">
              <FontAwesomeIcon
                size="4x"
                className="template-upload-attachment__check-icon"
                icon={['fas', 'check']}
              />
            </div>
            <div className="template-upload-attachment__info-headertxt">
              {onSuccessMessage || 'Uploaded Successfully!'}
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div
          className={`template-upload-attachment ${isDisabled ? 'upload-disabled' : ''}`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div className="template-upload-attachment__info">
            {isDragActive ? (
              <>
                <div className="template-upload-attachment__info-txt">
                  <FontAwesomeIcon
                    size="4x"
                    className="template-upload-attachment__download-icon"
                    icon={['far', 'file-download']}
                  />
                </div>
                <div className="template-upload-attachment__info-headertxt">
                  Drop Your Files Here
                </div>
              </>
            ) : (
              <>
                <div className="template-upload-attachment__info-txt">
                  <FontAwesomeIcon
                    size="4x"
                    className={`template-upload-attachment__folder-icon${isIE ? '--IE11' : ''}`}
                    icon={['fad', 'folder-upload']}
                  />
                </div>
                <div className="template-upload-attachment__info-headertxt">
                  Drop Your Files or{' '}
                  <span className="template-upload-attachment__info-headertxt-action">
                    Click Here
                  </span>{' '}
                  to Get Started!
                </div>
              </>
            )}

            <div>
              {!isDragActive && (
                <>
                  <div className="template-upload-attachment__btn-container">{children}</div>
                </>
              )}
              <div className="template-upload-attachment__info-subtxt">
                {infoText ?? 'Maximum file size: 2Gb - Excluded Files: .exe .js .sh'}
              </div>
            </div>
          </div>
        </div>
        {selectedFiles?.length > 0 && (
          <div className={`upload-content ${isDisabled ? 'upload-disabled' : ''}`}>
            {selectedFiles
              ?.filter((file) => file !== null)
              .map((file, index) => (
                <div className="upload-files-items" key={index}>
                  <div className="file-name-wrapper">{file?.name}</div>
                  <button type="button" className="text-danger" onClick={() => onRemoveFile(file)}>
                    <FontAwesomeIcon
                      className="attachments-table-row__action-icon"
                      icon={['far', 'trash-alt']}
                    />
                  </button>
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  return <div className="file-upload-template-wrapper">{conditionalRenderFunc()}</div>;
};

FileUpload.defaultProps = {
  maxFileCount: 1,
  isFileLoading: false,
  isUploadComplete: false,
  setIsUploadComplete: () => {},
  uploadProgress: 0,
  uploadId: null,
};

FileUpload.propTypes = {
  maxFileCount: PropTypes.number,
  onDrop: PropTypes.func.isRequired,
  isFileLoading: PropTypes.bool,
  isUploadComplete: PropTypes.bool,
  setIsUploadComplete: PropTypes.func,
  onSuccessMessage: PropTypes.string.isRequired,
  uploadProgress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uploadId: PropTypes.number,
};

export { FileUpload };
