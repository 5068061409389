import React, { useState } from 'react';

import './Prototype.scss';

const Prototype = () => {
  const header2 = 'WS test';
  const [message, setMessage] = useState('');

  // const wsOne = useWebsocket('other');

  return (
    <>
      <div>
        {header2}
        {/* <h1>Websocket {wsOne.isConnected ? 'Connected' : 'Disconnected'}</h1> */}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            // wsOne.sendMessage(message);
          }}
        >
          <input value={message} onChange={(e) => setMessage(e.target.value)} />
          <button type="submit">Send</button>
        </form>

        {/* {wsOne.messages?.map((m) => ( */}
        {/*   <p key={Date.now()}>{JSON.stringify(m, null, 2)}</p> */}
        {/* ))} */}
      </div>
    </>
  );
};

export { Prototype };
