import React from "react";

const _component = ({
  value = null,
  className = null,
  style = null,
  gap = false,
  nullLabel = null,
  minProgressVisible = 0,
}) => {
  const getAbsValue = (v) => {
    let output = null;

    if (typeof v === "number") {
      output = Math.max(Math.min(v, 100), 0);
    }

    return output;
  };

  const renderBlocks = (_v) => {
    // If not a number, set as 0
    // If a very small percentage, set as 3 so we have at least a small progress visible
    const v = typeof _v === "number" && _v >= 0 ? Math.max(_v, minProgressVisible) : 0;
    const output = [];

    const blocksV = Math.floor(v / 20) - 1;
    const partialV = Math.round((v % 20) * 5);

    for (let i = 0; i < 5; i++) {
      const isFull = i <= blocksV;
      const isPartial = i === blocksV + 1 && partialV > 0;

      output.push(
        <div key={i} className={`scale-block ${isFull ? "full" : isPartial ? "partial" : "empty"}`}>
          {isPartial ? (
            <div className="scale-block-remainder" style={{ width: `${partialV}%` }}></div>
          ) : null}
        </div>
      );
    }

    return output;
  };

  const absValue = getAbsValue(value);

  return (
    <div
      className={`score-scale-min${className ? ` ${className}` : ""}${gap ? ` gap` : ""}`}
      style={style}
    >
      <div className="score-scale-bar">{renderBlocks(absValue)}</div>
      <div className="score-scale-value">
        {typeof absValue === "number" && absValue >= 0
          ? `${absValue}%`
          : nullLabel
          ? nullLabel
          : ""}
      </div>
    </div>
  );
};

export default _component;
