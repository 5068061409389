import ActionStatusConstants from "constants/ActionStatusConstants";
import ActionTypeConstants from "constants/ActionTypeConstants";

const initialState = {
  status: ActionStatusConstants.INITIAL,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.GET_USER_PROFILE:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        status: ActionStatusConstants.SUCCESS,
        payload,
      };
    case ActionTypeConstants.GET_USER_PROFILE_SERVER_ERROR:
      return {
        ...state,
        status: ActionStatusConstants.SERVER_ERROR,
        payload,
      };
    case ActionTypeConstants.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        payload,
      };
    default:
  }
  return state;
};
