import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _set from 'lodash/set';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';

import * as ApiCalls from 'api/ApiCalls';
import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { BAIDueDatePicker } from 'components/common/DueDate/DueDate';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import * as DataJobDetailsConstants from 'constants/DataJobDetailsConstants';
import * as TimeUtils from 'helpers/TimeUtils';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import { AssigneeSelector } from './AssigneeSelector';
import './DetailsBlock.scss';
import { LinksSelector } from './LinksSelector';
import { PrioritySelector } from './PrioritySelector';
import { RequestorSection } from './private/RequestorSection';
import { TypeSubTypeSelector } from './TypeSubTypeSelector';

import { RootHooks } from 'helpers/RootHooks';
import { UserUtils } from 'helpers/UserUtils';

const validatorMsgs = {
  INVALID: () => 'Invalid value',
  REQUIRED: () => 'This field is required',
  LENGTH_OVER: (name, len) => `${name} should be at most ${len} characters`,
  LENGTH_UNDER: (name, len) => `${name} should be at least ${len} characters`,
};

const DATA_JOB_FIELD_PATHS = {
  DATA_REQUEST_LINK: 'data_request_link',
  NAME: 'name',
  DESCRIPTION: 'description',
  DATA_REQUEST_TYPE: 'data_request_type',
  ASSIGNEE_USER: 'assignee_user',
  DUE_DATE: 'due_date',
  PRIORITY: 'priority',
};

const dataJobValidator = yup.object().shape({
  name: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .max(100, validatorMsgs.LENGTH_OVER('Data Job Name', 100))
    .min(3, validatorMsgs.LENGTH_UNDER('Data Job Name', 3)),
  description: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .max(4000, validatorMsgs.LENGTH_OVER('Description', 4000))
    .min(3, validatorMsgs.LENGTH_UNDER('Description', 3)),
  data_request_type: yup
    .mixed()
    .test(
      'validate-type',
      validatorMsgs.REQUIRED(),
      (value) => !!(Array.isArray(value) && value.length && value.find((item) => item?.parent?.id))
    ),
  assignee_user: yup.mixed().test('validate-user', validatorMsgs.REQUIRED(), (value) => !!value.id),
});

const generateValidationMsgs = (yupErrObj) => {
  const output = {};

  if (yupErrObj?.inner?.length) {
    yupErrObj.inner.forEach((item) => {
      if (item.path && !Object.keys(output).includes(item.path)) {
        output[item.path] = item;
      }
    });
  }

  return output;
};

const convertDataJobToFormData = (dj) => {
  if (!(dj && Object.keys(dj))) {
    return null;
  }

  const output = {
    id: dj.id ?? null,
    name: dj.name ?? null,
    description: dj.description ?? null,
    data_request_type_ids: dj.data_request_type?.map((item) => item.id).sort() ?? null,
    data_request_link_ids: {
      source: [],
      target: [],
    },
    priority: dj.priority ?? null,
    assignee_user_id: dj.assignee_user?.id ?? null,
    due_date: dj.due_date ?? null,
  };

  dj.data_request_link?.length &&
    dj.data_request_link.forEach((item) =>
      item?.link_type === 'source'
        ? output.data_request_link_ids.source.push(item.source_id)
        : output.data_request_link_ids.target.push(item.target_id)
    );

  return output;
};

const extractFormDataChanges = (oldData, newData) => {
  let output = {};

  if (`${oldData?.name}` !== `${newData?.name}`) output.name = newData?.name ?? null;
  if (`${oldData?.description}` !== `${newData?.description}`)
    output.description = newData?.description ?? null;
  if (!_isEqual(oldData?.data_request_type_ids, newData?.data_request_type_ids))
    output.data_request_type_ids = newData?.data_request_type_ids ?? null;
  if (!_isEqual(oldData?.data_request_link_ids, newData?.data_request_link_ids))
    output.data_request_link_ids = newData?.data_request_link_ids ?? null;
  if (oldData?.priority !== newData?.priority) output.priority = newData?.priority ?? null;
  if (oldData?.assignee_user_id !== newData?.assignee_user_id)
    output.assignee_user_id = newData?.assignee_user_id ?? null;
  if (oldData?.due_date !== newData?.due_date) output.due_date = newData?.due_date ?? null;

  if (!Object.keys(output)?.length) {
    output = null;
  }

  return output;
};

const isEqProps = (prevProp, nextProp) => {
  return _isEqual(prevProp, nextProp);
};

const DetailsBlock = React.memo(({ dataJobData, setDataJobData }) => {
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();

  const [editableDataJobData, setEditableDataJobData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const [saveStatus, setSaveStatus] = useState(ActionStatusConstants.INITIAL);

  const [validationMsgs, setValidationMsgs] = useState({});

  const resetEditableDataJobData = useCallback(() => {
    setEditableDataJobData(_cloneDeep(dataJobData));
  }, [dataJobData]);

  useEffect(() => {
    resetEditableDataJobData();
  }, [resetEditableDataJobData]);

  const doValidateForm = (_editableDataJobData) => {
    const __editableDataJobData =
      _editableDataJobData !== undefined ? _editableDataJobData : editableDataJobData;

    let _validationMsgs = {};

    try {
      dataJobValidator.validateSync(__editableDataJobData, { abortEarly: false });
    } catch (e) {
      _validationMsgs = generateValidationMsgs(e);
    }

    setValidationMsgs(_validationMsgs);

    return _validationMsgs;
  };

  const hasValidationMsgs = (_validationMsgs) => {
    const __validationMsgs = _validationMsgs !== undefined ? _validationMsgs : validationMsgs;
    return !!(__validationMsgs && Object.keys(__validationMsgs)?.length);
  };

  const onChangeFormField = (path, newVal, shouldValidate) => {
    const newData = { ...editableDataJobData };
    _set(newData, path, newVal);
    setEditableDataJobData(newData);
    if (shouldValidate) {
      doValidateForm(newData);
    }
  };

  const doSaveDataJob = () => {
    const _validationMsgs = doValidateForm();

    if (hasValidationMsgs(_validationMsgs)) {
      toast.error('The are errors in the edited Data Job. Please correct them before saving.');
      return;
    }

    const originalFormData = convertDataJobToFormData(dataJobData);
    const editableFormData = convertDataJobToFormData(editableDataJobData);

    const formDataChanges = extractFormDataChanges(originalFormData, editableFormData);

    if (!formDataChanges) {
      toast.warn("You haven't done any changes to the Data Job edit.");
      return;
    }

    setSaveStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PATCH,
      urlPath: `/data-requests/${dataJobData.id}`,
      data: formDataChanges,
      onSuccess: (res) => {
        if (isMounted.current && res?.data) {
          toast.success('Data Job saved successfully.');
          setSaveStatus(ActionStatusConstants.SUCCESS);
          setIsEditMode(false);
          setValidationMsgs({});
          setDataJobData(res?.data);
        } else {
          toast.warn('Data Job was saved, but an issue occurred. Please refresh this page.');
          setSaveStatus(ActionStatusConstants.FAILURE);
        }
      },
      onError: () => {
        setSaveStatus(ActionStatusConstants.FAILURE);
      },
    });
  };

  const doCancelDataJobEdit = (triggerConfirmFunc) => {
    const originalFormData = convertDataJobToFormData(dataJobData);
    const editableFormData = convertDataJobToFormData(editableDataJobData);

    if (_isEqual(originalFormData, editableFormData)) {
      resetEditableDataJobData();
      setIsEditMode(false);
      return;
    }

    triggerConfirmFunc();
  };

  const isDataJobClosed = [
    DataJobDetailsConstants.statusValueConstants.CLOSED,
    DataJobDetailsConstants.statusValueConstants.CANCELED,
  ].includes(dataJobData?.status);
  const canEditGeneric = !isDataJobClosed && dataJobData?.editable;

  // FIXME unnest these components
  // eslint-disable-next-line react/no-unstable-nested-components
  const SelectDueDate = () => {
    return (
      <BAIDueDatePicker
        dueDate={editableDataJobData.due_date}
        editable={isEditMode && canEditGeneric}
        // eslint-disable-next-line react/no-unstable-nested-components
        nonEditableEl={() => (
          <Form.Control
            readOnly
            plaintext
            as="input"
            value={
              editableDataJobData?.due_date
                ? TimeUtils.formatDateStamp(editableDataJobData.due_date)
                : 'N/A'
            }
          />
        )}
        onEdit={(v) => onChangeFormField(DATA_JOB_FIELD_PATHS.DUE_DATE, v)}
        minDate={Date.now()}
        offsetWithWeekends={false}
        minDaysOffset={DataJobDetailsConstants.MIN_DUE_DATE_WORKDAYS_AHEAD}
        showMinDateHighlight
      />
    );
  };

  // FIXME unnest these components
  // eslint-disable-next-line react/no-unstable-nested-components
  const SelectLinks = () => {
    return (
      <LinksSelector
        dataJobData={editableDataJobData}
        onChange={(v) => onChangeFormField(DATA_JOB_FIELD_PATHS.DATA_REQUEST_LINK, v)}
        canAddLinks={isEditMode}
        canRemoveLinks={isEditMode && canEditGeneric}
      />
    );
  };

  // FIXME unnest these components
  // eslint-disable-next-line react/no-unstable-nested-components
  const SelectPriority = () => {
    return (
      <PrioritySelector
        value={editableDataJobData?.priority}
        onChange={(v) => onChangeFormField(DATA_JOB_FIELD_PATHS.PRIORITY, v)}
        isEditMode={isEditMode && canEditGeneric}
        isInvalid={!!validationMsgs.priority}
      />
    );
  };

  if (!editableDataJobData?.id) {
    return null;
  }

  const assigneeCompany =
    (
      editableDataJobData.assignee_user?.profile?.client ||
      editableDataJobData.assignee_user?.profile?.manufacturer
    )?.name ?? 'BackboneAI';

  // eslint-disable-next-line react/no-unstable-nested-components
  const AssigneeSection = () => {
    return (
      <section className="assignee-block">
        <section className="assignee-name">
          <AssigneeSelector
            value={editableDataJobData.assignee_user}
            onChange={(v) => onChangeFormField(DATA_JOB_FIELD_PATHS.ASSIGNEE_USER, v)}
            dataJobData={editableDataJobData}
            isEditMode={isEditMode}
          />
        </section>
        <section className="company-name">
          <Form.Group>
            <Form.Label className="on-behalf">Company:</Form.Label>
            <div className="form-control-plaintext">{assigneeCompany}</div>
          </Form.Group>
        </section>
        <section className="due-date-subblock">
          <Form.Group className="input-field input-field-due-date" controlId="dataJob.due_date">
            <Form.Label>Due Date:</Form.Label>
            <Form.Control as={SelectDueDate} isInvalid />
          </Form.Group>
        </section>
      </section>
    );
  };

  return (
    <div
      className={classNames('panel-block data-job-panel-details-block', {
        'edit-mode': isEditMode,
      })}
    >
      <div className="block-content">
        <Form noValidate>
          <section className="title-section">
            <section className="title-block">
              <div className="label-wrap">
                <div className="label">Data Job:</div>
                <div className="last-updated">
                  <span className="value">
                    Last Updated: {TimeUtils.formatMinDateStamp(editableDataJobData.updated_at)}
                  </span>
                </div>
              </div>
              <div className="title">
                <Form.Group className="input-field input-field-name" controlId="dataJob.name">
                  <Form.Control
                    plaintext={!isEditMode || !canEditGeneric}
                    as="input"
                    value={editableDataJobData.name}
                    placeholder="Please enter Data Job name"
                    onChange={(e) => onChangeFormField(DATA_JOB_FIELD_PATHS.NAME, e.target.value)}
                    onBlur={() => doValidateForm()}
                    isInvalid={!!validationMsgs?.name}
                    maxLength="100"
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationMsgs?.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </section>
            {!isEditMode && !isDataJobClosed && !UserUtils.isReadOnly(activeUser) && (
              <section className="actions-block">
                <button
                  type="button"
                  className="btn btn-secondary btn-lg"
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  Edit
                </button>
              </section>
            )}
          </section>
          <section className="description-section">
            <Form.Group
              className="input-field input-field-description"
              controlId="dataJob.description"
            >
              <Form.Label>Description:</Form.Label>
              {isEditMode && canEditGeneric ? (
                <>
                  <Form.Control
                    className="data-job-description"
                    as="textarea"
                    rows={10}
                    value={editableDataJobData.description}
                    placeholder="Please enter Data Job description"
                    onChange={(e) =>
                      onChangeFormField(DATA_JOB_FIELD_PATHS.DESCRIPTION, e.target.value)
                    }
                    onBlur={() => doValidateForm()}
                    isInvalid={!!validationMsgs?.description}
                    maxLength="4000"
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationMsgs?.description?.message}
                  </Form.Control.Feedback>
                </>
              ) : (
                <div className="form-control-plaintext data-job-description">
                  {editableDataJobData?.description}
                </div>
              )}
            </Form.Group>
          </section>
          <section className="type-subtype-section">
            <TypeSubTypeSelector
              value={editableDataJobData.data_request_type}
              onChange={(v) => onChangeFormField(DATA_JOB_FIELD_PATHS.DATA_REQUEST_TYPE, v, true)}
              isEditMode={isEditMode && canEditGeneric}
              validationMsg={validationMsgs?.data_request_type}
            />
          </section>
          <section className="links-priority-section">
            <section className="links-block">
              <Form.Group className="input-field input-field-links">
                <Form.Label>Links:</Form.Label>
                <Form.Control as={SelectLinks} />
              </Form.Group>
            </section>
            <section className="priority-block">
              <Form.Group className="input-field input-field-priority">
                <Form.Label>Priority:</Form.Label>
                <Form.Control as={SelectPriority} />
                <Form.Control.Feedback type="invalid">
                  {validationMsgs?.priority?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </section>
          </section>
          <section className="requestor-assignee-section">
            <RequestorSection editableDataJobData={editableDataJobData} />
            <AssigneeSection />
          </section>
          {isEditMode && (
            <div className="due-date-msg-container">
              <div className="due-date-msg">
                The average data job takes{' '}
                {DataJobDetailsConstants.STANDARD_PROCESSING_TIME_DAYS_NOTE} business days. If you
                would like your data job processed more quickly, please reach out to us at{' '}
                <a
                  href={`mailto:data@backbone.ai?subject=Assistance Required - Data Job #${dataJobData.id} Due Date&body=Assistance is requested from backbone.ai team in processing this Data Job more quickly than ${DataJobDetailsConstants.STANDARD_PROCESSING_TIME_DAYS_NOTE} days.
              %0D%0AThe data job can be found at ${window.location.href} .`}
                >
                  data@backbone.ai
                </a>
              </div>
            </div>
          )}
          {isEditMode && (
            <section className="actions-section">
              <ConfirmDialog
                onConfirm={() => {
                  resetEditableDataJobData();
                  setIsEditMode(false);
                }}
                headerContent="Discard changes?"
                bodyContent="You have unsaved changes. Are you sure you want to cancel editing?"
              >
                {({ onClick }) => (
                  <button
                    type="button"
                    title="Delete"
                    className="btn btn-secondary btn-lg action action-cancel"
                    onClick={() => doCancelDataJobEdit(onClick)}
                  >
                    Cancel
                  </button>
                )}
              </ConfirmDialog>
              <button
                type="button"
                className="btn btn-primary btn-lg btn-action-save"
                onClick={doSaveDataJob}
              >
                Save
              </button>
            </section>
          )}
        </Form>
      </div>
      {saveStatus === ActionStatusConstants.ISBUSY && <LoadingView text="Saving" />}
    </div>
  );
}, isEqProps);

export { DetailsBlock };
