import React, { useCallback, useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';

import * as TimeUtils from 'helpers/TimeUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import '../DetailsBlock.scss';

import { UserSelector } from '../UserSelector';

/**
 * TODO: this component commonly uses the following to check if the job was created by an admin:
 * ```editableDataJobData?.requestor_user?.profile?.manufacturer?.name || editableDataJobData?.requestor_user?.profile?.client?.name ? ```
 * At some point we need to update the model to better track when a parent or admin created the job for a child
 */
const RequestorSection = ({ editableDataJobData }) => {
  const [requestingCompany, setRequestingCompany] = useState();
  const [vicariousJob, setVicariousJob] = useState(false); // Was job created on behalf of a company that is not the requestor user's company
  const isMounted = useIsMounted();

  const doSetRequestingCompany = useCallback((co) => {
    setRequestingCompany(co);
  }, []);

  const doSetVicariousJob = useCallback((v) => {
    setVicariousJob(v);
  }, []);

  /**
   * Check if the job was created on behalf of another party.
   * IE: Parent company user created job on behalf of a child company or
   * Admin created job on behalf of any company
   */
  useEffect(() => {
    if (!isMounted || !editableDataJobData?.assignee_user) return;
    // If assignee user profile has manufacturer, the requesting company is the client
    const requestingCo = editableDataJobData.assignee_user.profile?.manufacturer
      ? editableDataJobData.client
      : editableDataJobData.manufacturer;
    if (
      // Compare requestingCo to requestor_user
      ![
        editableDataJobData?.requestor_user?.profile?.manufacturer?.id,
        editableDataJobData?.requestor_user?.profile?.client?.id,
      ].includes(requestingCo.id)
    ) {
      // The requestor user's company differs from requesting company
      doSetVicariousJob(true);
    } else {
      doSetVicariousJob(false);
    }
    doSetRequestingCompany(requestingCo);
  }, [editableDataJobData, doSetRequestingCompany, isMounted, doSetVicariousJob]);

  const CreatorUser = useCallback(
    () => (
      <>
        <UserSelector user={editableDataJobData.requestor_user} />
        {vicariousJob ? (
          // show the requestor user's company under their name
          <div className="creator-company">
            (
            {editableDataJobData?.requestor_user?.profile?.manufacturer?.name ||
              editableDataJobData?.requestor_user?.profile?.client?.name ||
              'BackboneAI'}
            )
          </div>
        ) : null}
      </>
    ),
    [editableDataJobData.requestor_user, vicariousJob]
  );

  return (
    <section className="requestor-block">
      <section className="requestor-name">
        <Form.Group className="input-field input-field-requestor" controlId="dataJob.requestor">
          <Form.Label>Created By:</Form.Label>
          <Form.Control as={CreatorUser} />
        </Form.Group>
      </section>

      <section className="company-name">
        <Form.Group>
          <Form.Label className="on-behalf">
            {/* Label is "on behalf of" if created by an admin */}
            {editableDataJobData?.requestor_user?.profile?.manufacturer?.name ||
            editableDataJobData?.requestor_user?.profile?.client?.name
              ? 'Company:'
              : 'On Behalf Of:'}
          </Form.Label>
          <Form.Control value={requestingCompany?.name || ''} plaintext readOnly as="input" />
        </Form.Group>
      </section>

      <section className="created-at">
        <Form.Group className="input-field input-field-created-at" controlId="dataJob.created_at">
          <Form.Label>Created On:</Form.Label>
          <Form.Control
            readOnly
            plaintext
            as="input"
            value={TimeUtils.formatDateStamp(editableDataJobData.created_at)}
          />
        </Form.Group>
      </section>
    </section>
  );
};

export { RequestorSection };
