import './SearchSuggestions.scss';

import React from 'react';

import { Highlighted } from 'helpers/Utils';

const SearchSuggestions = ({
  setAreSuggestionsVisible,
  areSuggestionsVisible,
  areSuggestionsLoading,
  searchSuggestions,
  setLocalSearch,
  setSearch,
  localSearch,
  search,
  onSuggestionSelected,
}) => {
  const handleSuggestionSelected = (suggestion) => {
    setSearch(suggestion.value.toString());
    setLocalSearch(suggestion.value.toString());
    setAreSuggestionsVisible(false);
    if (typeof onSuggestionSelected === 'function')
      onSuggestionSelected(suggestion.value.toString());
  };

  return (
    <div
      onBlur={() => {
        setAreSuggestionsVisible(false);
      }}
      hidden={!areSuggestionsVisible}
      className="search-suggestions"
    >
      {!areSuggestionsLoading &&
        searchSuggestions?.map((suggestion, i) => (
          <div
            key={i}
            tabIndex="0"
            onKeyDown={() => handleSuggestionSelected(suggestion)}
            role="button"
            onClick={() => handleSuggestionSelected(suggestion)}
            className="search-suggestion"
          >
            <Highlighted text={suggestion?.value?.toString()} highlight={localSearch} />
            {suggestion.type && <span className="search-suggestion__type">{suggestion.type}</span>}
          </div>
        ))}
      {!areSuggestionsLoading && searchSuggestions.length === 0 && search.length === 0 && (
        <p>Begin typing to see search results.</p>
      )}
      {!areSuggestionsLoading && searchSuggestions.length === 0 && search.length > 0 && (
        <p>No results found.</p>
      )}
      {areSuggestionsLoading && <p>Loading...</p>}
    </div>
  );
};

export { SearchSuggestions };
