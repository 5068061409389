import React, { useEffect, useRef, useState } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';

import * as ApiCalls from 'api/ApiCalls';
import { WsConstants } from 'api/WsConstants';
import { ManufacturerSelect } from 'components/common/ManufacturerSelect/ManufacturerSelect';
import * as DataLakehouseDashboardApiCalls from 'components/data-lakehouse/DataLakehouseDashboard/private/DataLakehouseDashboardApiCalls';
import { SectionImageQuality } from 'components/data-lakehouse/DataLakehouseDashboard/private/SectionImageQuality';
import { DatabotsLibrary } from 'components/databots/DatabotsLibrary';
import { DatabotsReports } from 'components/databots/DatabotsReports';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { BotConstants } from 'constants/BotConstants';
import { getDatabotLibrary } from 'helpers/BotUtils';
import { RootHooks } from 'helpers/RootHooks';
import { getMfrStateFromSearchStr, updateUrlState } from 'helpers/UrlUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { isRoleAdmin } from 'helpers/UserUtils';
import { useWebsocket } from 'helpers/useWebsocket';

const ViewDatabots = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();
  const isAdmin = isRoleAdmin(activeUser);
  const currentUserMfrId = activeUser?.profile?.manufacturer?.id;

  const [bots, setBots] = useState([]);
  const [areBotsLoading, setAreBotsLoading] = useState(false);
  const [areBotCountsLoading, setAreBotCountsLoading] = useState(false);
  const [areBotReportsLoading, setAreBotReportsLoading] = useState(false);
  const [botRuns, setBotRuns] = useState({ runningBots: 0, completeBots: 0, total: 0 });
  const [reports, setReports] = useState([]);

  const [imageQualityAssessmentData, setImageQualityAssessmentData] = useState([]);
  const [imageQualityAssessmentDataStatus, setImageQualityAssessmentDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  // WS params
  const channel = WsConstants.WS_CHANNELS.BOTS;
  const channelType = BotConstants.BOT_WS_CHANNEL_TYPES.MFR;
  const ws = useWebsocket();
  const wsLastUpdate = useRef(null);
  const { messages, messagesLastUpdate } = ws;

  // set url params for manufacturer hierarchy
  const mfrId = isAdmin
    ? getMfrStateFromSearchStr(location.search)?.manufacturer_id
    : currentUserMfrId || null;
  const brandId =
    isAdmin || currentUserMfrId
      ? getMfrStateFromSearchStr(location.search)?.child_company_id
      : null;

  const companyId = brandId || mfrId;

  const wsSubscribedCompanyId = useRef(null);

  useEffect(() => {
    if (companyId === null) return;
    setAreBotsLoading(true);
    setAreBotCountsLoading(true);
    setAreBotReportsLoading(true);
    let reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/available-bots?manufacturer_id=${companyId}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setBots(getDatabotLibrary(res.data));
        }
      },
      onEnd: () => {
        setAreBotsLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);

    reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/count/${companyId}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          const { running_bots: runningBots, complete_bots: completeBots, total } = res.data;

          setBotRuns({
            runningBots,
            completeBots,
            total,
          });
        }
      },
      onEnd: () => {
        setAreBotCountsLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);

    reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/reports?manufacturer_id=${companyId}`,
      onSuccess: (res) => {
        if (isMounted.current) setReports(res.data);
      },
      onEnd: () => {
        setAreBotReportsLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);

    DataLakehouseDashboardApiCalls.doGetImageQualityAssessment({
      id: companyId,
      type: 'manufacturer',
      isMounted,
      setImageQualityAssessmentData,
      setImageQualityAssessmentDataStatus,
    });
  }, [isMounted, companyId, isAdmin]);

  // WS subscribe/unsubscribe
  useEffect(() => {
    // Unsubscribe func on unmount
    const unmountFunc = () => {
      if (!isMounted.current && wsSubscribedCompanyId) {
        ws.disconnectChannel(channel, { type: channelType, id: wsSubscribedCompanyId.current });
        wsSubscribedCompanyId.current = null;
        wsLastUpdate.current = null;
      }
    };

    // Subscribe logic
    if (isMounted.current && ws.isConnected) {
      if (
        (!companyId && wsSubscribedCompanyId.current) ||
        (companyId && wsSubscribedCompanyId.current && companyId !== wsSubscribedCompanyId.current)
      ) {
        // Disconnect if we're changing or clearing mfr id
        ws.disconnectChannel(channel, { type: channelType, id: wsSubscribedCompanyId.current });
        wsLastUpdate.current = null;
        wsSubscribedCompanyId.current = null;
      } else if (companyId && !wsSubscribedCompanyId.current) {
        // Connect if we're selecting a new mfr id
        ws.addChannel(channel, { type: channelType, id: companyId });
        wsLastUpdate.current = Date.now();
        wsSubscribedCompanyId.current = companyId;
      }
    }

    return () => unmountFunc();
  }, [
    wsSubscribedCompanyId,
    isMounted,
    channel,
    channelType,
    ws,
    ws?.isConnected,
    companyId,
    wsLastUpdate,
  ]);

  // WS messages handler
  useEffect(() => {
    if (
      !(bots?.length && isMounted.current && wsLastUpdate.current < messagesLastUpdate[channel])
    ) {
      return;
    }

    wsLastUpdate.current = messagesLastUpdate[channel];
    const _data = messages[channel] ?? null;
    if (_data?.id && _data?.bot_id) {
      const newBots = _cloneDeep(bots);

      for (let i = 0; i < newBots.length; i++) {
        if (newBots[i]?.bots?.length) {
          const foundBot = newBots[i].bots.find((item) => item?.id === _data.bot_id);
          if (foundBot) {
            foundBot.bot_status = _cloneDeep(_data);
          }
          break;
        }
      }

      setBots(newBots);
    } else {
      console.debug('Unsupported msg', _data);
    }
  }, [isMounted, channel, ws?.isConnected, messages, wsLastUpdate, messagesLastUpdate, bots]);

  return (
    <div className="content content-fluid view-databots">
      <Helmet bodyAttributes={{ 'data-page': 'databots' }}>
        <title>Databots</title>
      </Helmet>
      {(isAdmin || currentUserMfrId) && (
        <div className="databots-section">
          <div className="bot-category-card">
            <ManufacturerSelect
              selectedManufacturer={mfrId}
              onSelectManufacturer={(v) => updateUrlState(history, location, v, null)}
              selectedBrand={brandId}
              onSelectBrand={(v) => updateUrlState(history, location, mfrId, v)}
              isDisabled={false}
            />
          </div>
        </div>
      )}
      {companyId && (
        <>
          {imageQualityAssessmentData?.length > 0 ? (
            <section className="databots-section">
              <SectionImageQuality
                isAdmin={isAdmin}
                type="manufacturer"
                imageQualityAssessmentData={imageQualityAssessmentData}
                imageQualityAssessmentDataStatus={imageQualityAssessmentDataStatus}
                id={companyId}
              />
            </section>
          ) : null}
          <DatabotsLibrary
            parentManufacturerId={brandId && mfrId}
            manufacturerId={companyId}
            isLoading={areBotsLoading && areBotCountsLoading}
            bots={bots}
            botRuns={botRuns}
          />
          <DatabotsReports
            manufacturerId={companyId}
            isLoading={areBotReportsLoading}
            reports={reports}
          />
        </>
      )}
    </div>
  );
});

export { ViewDatabots };
