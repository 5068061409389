import "./ProgressDots.scss";
import React from "react";

import PropTypes from "prop-types";

/**
 * Progress Modal.
 *
 * @param {values} - array of values in order to be displayed
 * @param {current} - current value from steps array
 *
 */

// component expects to receive an array of strings for its labels and the index(from 0) for which value is the current step.
const ProgressDots = ({ values, current }) => {
  return (
    <div className="flex-row" style={{ display: "flex", maxWidth: "40%" }}>
      {values.map((item) => (
        <div
          key={`dot-${item}`}
          className={item === current ? "progress-dot current" : "progress-dot"}
        />
      ))}
    </div>
  );
};

ProgressDots.propTypes = {
  values: PropTypes.array.isRequired,
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export { ProgressDots };
