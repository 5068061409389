export default {
  ADD_WS_MESSAGE: 'ADD_WS_MESSAGE',
  REMOVE_WS_CHANNEL: 'REMOVE_WS_CHANNEL',
  CLEAR_ALL_WS_MESSAGES: 'CLEAR_ALL_WS_MESSAGES',

  SUBMIT_USER_LOGIN: 'SUBMIT_USER_LOGIN',
  SUBMIT_USER_LOGIN_SUCCESS: 'SUBMIT_USER_LOGIN_SUCCESS',
  SUBMIT_USER_LOGIN_FAILURE: 'SUBMIT_USER_LOGIN_FAILURE',
  SUBMIT_USER_LOGIN_RESET: 'SUBMIT_USER_LOGIN_RESET',

  SUBMIT_USER_LOGOUT: 'SUBMIT_USER_LOGOUT',
  SUBMIT_USER_LOGOUT_SUCCESS: 'SUBMIT_USER_LOGOUT_SUCCESS',
  SUBMIT_USER_LOGOUT_FAILURE: 'SUBMIT_USER_LOGOUT_FAILURE',
  SUBMIT_USER_LOGOUT_RESET: 'SUBMIT_USER_LOGOUT_RESET',

  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',
  GET_USER_PROFILE_SERVER_ERROR: 'GET_USER_PROFILE_SERVER_ERROR',

  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
  GET_ALL_PRODUCTS_FAILURE: 'GET_ALL_PRODUCTS_FAILURE',
  GET_ALL_PRODUCTS_RESET: 'GET_ALL_PRODUCTS_RESET',

  GET_ALL_MANUFACTURERS: 'GET_ALL_MANUFACTURERS',
  GET_ALL_MANUFACTURERS_SUCCESS: 'GET_ALL_MANUFACTURERS_SUCCESS',
  GET_ALL_MANUFACTURERS_FAILURE: 'GET_ALL_MANUFACTURERS_FAILURE',
  GET_ALL_MANUFACTURERS_RESET: 'GET_ALL_MANUFACTURERS_RESET',

  GET_SUPPLIER_STATISTICS: 'GET_SUPPLIER_STATISTICS',
  GET_SUPPLIER_STATISTICS_SUCCESS: 'GET_SUPPLIER_STATISTICS_SUCCESS',
  GET_SUPPLIER_STATISTICS_FAILURE: 'GET_SUPPLIER_STATISTICS_FAILURE',
  GET_SUPPLIER_STATISTICS_RESET: 'GET_SUPPLIER_STATISTICS_RESET',

  GET_DATA_REQUEST_DELIVERY_FORMATS: 'GET_DATA_REQUEST_DELIVERY_FORMATS',
  GET_DATA_REQUEST_DELIVERY_FORMATS_SUCCESS: 'GET_DATA_REQUEST_DELIVERY_FORMATS_SUCCESS',

  RESET_INITIAL_LOCATION: 'RESET_INITIAL_LOCATION',
};
