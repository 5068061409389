import './SheetSelect.scss';

import React from 'react';

import { Button } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { MODALTYPES, SHEETSTATUS } from 'constants/DataIngestionConstants';

const ModalThankYou = (redirectToJobDetails) => {
  const body = <p>Thank you for your feedback. Our Data Team will follow-up with you shortly</p>;
  const footer = (
    <div>
      <Button onClick={() => redirectToJobDetails()}>OK</Button>
    </div>
  );
  return { body, footer }
};

const ModalProcessing = (redirectToJobDetails) => {
  const body = <p>Thanks for Verifying. We'll notify you when your file is done processing.</p>;
  const footer = (
    <div>
      <Button onClick={() => redirectToJobDetails()}>OK</Button>
    </div>
  );
  return { body, footer };
};

// User Is Backtracking, Alert them changes will delay processing
const ModalRestart = (
  type,
  value,
  modal,
  setModalVisible,
  setIsLoading,
  isLastSheet,
  goToNextSheet,
  attachmentId,
  updateRows,
  updateColumns,
  location,
  message,
  handleModalResponse,
  doDisableNext,
) => {
  const doResetColumns = () => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/data-ingestion/reset-columns/${attachmentId}`,
    });
  };

  // User is backtracking and made change differing from db:
  const doConfirmReverify = () => {
    doDisableNext();
    if (location.pathname.includes('rows')) {
      updateColumns(type, value, true);
      // Delete existing column predictions
      doResetColumns(attachmentId);
      if (type === 'data_idx') {
        goToNextSheet();
      }
    } else if (type === 'edit_column') {
      updateColumns(type, value, true);
      return;
    } else {
      updateColumns(type, value, true, message);
    }
    setModalVisible(false);
  };

  const cancelSelection = () => {
    setModalVisible(false);
    // TODO V2: reset the selected header to the database's value
  };

  let body = null;
  let footer = null;
  if (modal === MODALTYPES.RESTART_FROM_COLUMN) {
    // User is restarting from columns page
    body = (
      <div>
        <p>
          <b>Warning: </b> This change means your file will have to be reprocessed before you can
          verify columns...
        </p>
      </div>
    );

    footer = (
      <div>
        <Button variant="outline-dark" onClick={() => cancelSelection()}>
          Cancel
        </Button>
        <Button className="ml-2" variant="warning" onClick={() => doConfirmReverify()}>
          Confirm Changes
        </Button>
      </div>
    );
  } else {
    // User is restarting from assessment page
    body = (
      <div>
        <p>
          <b>Warning: </b> This change will delete your current progress for this file. Backbone's
          AI will need to reprocess the file before you can continue.
        </p>
      </div>
    );
    footer = (
      <div>
        <Button variant="outline-dark" onClick={() => cancelSelection()}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          variant="danger"
          onClick={(type, value) => doConfirmReverify(type, value)}
        >
          Confirm Changes
        </Button>
      </div>
    );
  }

  if (type === 'data_idx' && isLastSheet) {
    // Handle Edgecase: last data row on last sheet is only change:
    ModalProcessing();
    footer = null;
  }
  return { body, footer };
};

const ModalDisableSheet = (
  value,
  sheetName,
  setModalVisible,
  sheets,
  attachmentId,
  jobId,
  getSheets,
  onDisableSuccess,
  setIsLoading,
  updateRows
) => {
  const cancelDisable = () => {
    setModalVisible(false);
    // TODO V2: reset the selected header to the database's value
  };
  const tryDisable = () => {
    updateRows('disable_sheet', value);
  };

  let body = null;
  // If all other sheets are disabled, throw specific warning
  if (
    sheets
      .filter((sheet) => sheet.id !== value)
      .every((item) => item.status === SHEETSTATUS.DISABLED)
  ) {
    body = (
      <div key="disablehandle">
        <p>You are about to disable the only valid sheet left for this file.</p>
        <p>
          Confirm and we'll send a notification to our data team if there are product attributes in
          this page.
        </p>
      </div>
    );
  } else {
    body = (
      <div key="handleDisable">
        <p>You are about to disable this sheet.</p>
        <p>This data will not be processed. Continue?</p>
      </div>
    );
  }
  const footer = (
    <div>
      <Button variant="outline-dark" onClick={() => cancelDisable()}>
        Cancel
      </Button>
      <Button className="ml-2" variant="danger" onClick={() => tryDisable()}>
        Confirm Disable
      </Button>
    </div>
  );
  return { body, footer };
};

// Prompt for either column deletion or enablement
const ModalColumnDisable = (
  setModalVisible,
  isLoading,
  value,
  direction,
  columnData,
  updateColumns
) => {
  const tryDisableColumn = () => {
    // UpdateColumns will check for backtracking before sending call to api
    updateColumns('disable_column', value, false);
  };
  const tryEnableColumn = () => {
    updateColumns('enable_column', value, false, 'column enabled');
  };

  let body = null;
  let footer = null;
  if (direction === 'disable_column') {
    if (columnData.length > 1) {
      body = (
        <div key="handleDisable">
          You are about to disable this column. This means that the data contained in this column
          will not be processed. Continue?
        </div>)
      footer = (
        <div>
          <Button variant="outline-dark" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>
          <Button className="ml-2" variant="danger" onClick={() => tryDisableColumn()}>
            Confirm Disable
          </Button>
        </div>
      );
    } else {
      // If this is last column to disable
      body = (
        <div key="disablehandle">
          You are about to disable the only valid column left for this sheet. If you proceed,
          nothing on this sheet will be processed. Please click 'Confirm' to disable it and notify
          the BackboneAI data team.
        </div>
      );
      footer = (
        <div>
          <Button variant="outline-dark" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>
          <Button className="ml-2" variant="danger" onClick={() => tryDisableColumn()}>
            Confirm Disable
          </Button>
        </div>
      );
    }
  } else if (direction === 'enable_column') {
    body = (
      <div key="handleDisable">
        You are about to enable this column. This means that the data contained in this column will
        be processed. Continue?
      </div>
    );
    footer = (
      <div>
        <Button variant="outline-dark" onClick={() => setModalVisible(false)}>
          Cancel
        </Button>
        <button className="btn btn-primary" type="button" onClick={() => tryEnableColumn(value)}>
          Confirm Enable
        </button>
      </div>
    );
  }
  return { body, footer };
};

export { ModalThankYou, ModalProcessing, ModalRestart, ModalDisableSheet, ModalColumnDisable };
