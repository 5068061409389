import React from 'react';

import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import { getAuthToken } from 'helpers/AuthTokenUtils';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { UserUtils } from 'helpers/UserUtils';

/**
 * Authentication/authorization wrapper for router
 *
 * @param {array} allowedRoles List of roles that this route requires
 * @param {array} forbiddenRoles List of roles that are forbidden for this route
 * @param {boolean} isPublicRoute Route doesn't require any authentication/authorization
 * @param {boolean} requiresWritePermission Will redirect to homepage with an error if the user has readOnly flag
 *
 * @returns Render
 */
const AuthRoute = (props) => {
  const { allowedRoles, forbiddenRoles, isPublicRoute, requiresWritePermission, ...routeProps } = props;

  const { activeUser } = RootHooks.useActiveUser();
  const authToken = getAuthToken();

  const isLoggedIn = !!(authToken && activeUser?.email);
  const isAllowedRole = allowedRoles?.length
    ? allowedRoles.includes(activeUser?.role ?? null)
    : true;
  const isForbiddenRole = forbiddenRoles?.length
    ? forbiddenRoles.includes(activeUser?.role ?? null)
    : false;

  const isPermitted =
    !requiresWritePermission ||
    (activeUser && requiresWritePermission && !UserUtils.isReadOnly(activeUser));
  const isAuthorized = isPublicRoute || isLoggedIn;
  const isAllowed = isAuthorized && isAllowedRole && isPermitted && !isForbiddenRole;

  if (isAllowed) {
    return <Route {...routeProps} />;
  }
  if (isAuthorized) {
    toast.error("You don't have permission to access this page.");
    return <Redirect to="/" />;
  }

  return <Redirect exact from="/" to="/login" />;
};

AuthRoute.defaultProps = {
  isPublicRoute: false,
  allowedRoles: null,
  forbiddenRoles: null,
};

AuthRoute.propTypes = {
  isPublicRoute: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  forbiddenRoles: PropTypes.arrayOf(PropTypes.string),
};

export { AuthRoute };
