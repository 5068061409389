import React, { useEffect, useState } from 'react';

import _get from 'lodash/get';
import { Form } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { statusValueConstants } from 'constants/DataJobDetailsConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import * as UserUtils from 'helpers/UserUtils';

import { UserSelector } from './UserSelector';

const checkCanEdit = (currentUser, dataJobData, isEditMode) => {
  if (!isEditMode) {
    return false;
  }

  if ([statusValueConstants.CLOSED, statusValueConstants.CANCELED].includes(dataJobData?.status)) {
    return false;
  }

  if (UserUtils.isRoleAdmin(currentUser)) {
    return true;
  }

  if (dataJobData?.data_request_action_type !== 'internal_data_job') {
    const assigneeUsrProfile = dataJobData?.assignee_user?.profile;
    const currentUsrProfile = currentUser?.profile;

    if (UserUtils.isRoleClient(currentUser)) {
      return currentUsrProfile?.client?.id === assigneeUsrProfile?.client?.id;
    }

    if (UserUtils.isRoleSupplier(currentUser)) {
      return currentUsrProfile.manufacturer.id === assigneeUsrProfile?.manufacturer?.id;
    }
  }

  return true;
};

const AssigneeSelector = ({ value, onChange, dataJobData, isEditMode }) => {
  const { activeUser } = RootHooks.useActiveUser();

  const isMounted = useIsMounted();

  const [usersData, setUsersData] = useState(null);
  const [usersDataStatus, setUsersDataStatus] = useState(ActionStatusConstants.INITIAL);

  useEffect(() => {
    setUsersDataStatus(ActionStatusConstants.ISBUSY);

    const djRequestorProfile = dataJobData?.requestor_user?.profile;
    let org = null;
    let orgType = null;
    if (djRequestorProfile.manufacturer?.id && dataJobData?.client?.id) {
      org = dataJobData.client.id;
      orgType = UserRoleConstants.CLIENT;
    } else if (djRequestorProfile?.client?.id && dataJobData.manufacturer?.id) {
      org = dataJobData.manufacturer.id;
      orgType = UserRoleConstants.MANUFACTURER;
    }

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/organisation/users/`,
      params: { org, org_type: orgType },
      onSuccess: (res) => {
        if (isMounted.current) {
          setUsersDataStatus(ActionStatusConstants.SUCCESS);
          const _data = _get(res, 'data') || [];
          setUsersData(_data);
        }
      },
      onError: () => {
        if (isMounted.current) {
          setUsersDataStatus(ActionStatusConstants.FAILURE);
        }
      },
    });
  }, [
    dataJobData?.client?.id,
    dataJobData?.manufacturer?.id,
    dataJobData?.requestor_user?.profile?.client?.id,
    dataJobData?.requestor_user?.profile?.manufacturer?.id,
    dataJobData?.requestor_user?.profile,
    isMounted,
  ]);

  const SelectUser = () => (
    <UserSelector
      usersList={usersData?.length ? usersData : []}
      disabled={usersDataStatus === ActionStatusConstants.ISBUSY}
      user={value}
      isEditMode={checkCanEdit(activeUser, dataJobData, isEditMode)}
      onEdit={onChange}
      isSearchable={false}
      name="assignee_user"
      maxWidth="400px"
    />
  );

  return (
    <Form.Group className="input-field input-field-assignee" controlId="dataJob.assignee">
      <Form.Label>Assigned To:</Form.Label>
      <Form.Control as={SelectUser} />
    </Form.Group>
  );
};

export { AssigneeSelector };
