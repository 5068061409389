import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputGroup, Button, Form } from 'react-bootstrap';
import Url from 'url-parse';
import './AddLinkButton.scss';

import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';

const BodyForm = ({ handleOnChange }) => {
  return (
    <Form.Group className="share_url" controlId="formLinkInputText">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>http://</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control type="input" onChange={handleOnChange} placeholder="Enter link" />
      </InputGroup>
    </Form.Group>
  );
};

const ModalFooter = ({ setIsVisible, _onReject, _onConfirmLink }) => (
  <>
    <Button variant="secondary" onClick={() => _onReject(setIsVisible)}>
      Cancel
    </Button>
    <Button variant="primary" onClick={() => _onConfirmLink(setIsVisible)}>
      OK
    </Button>
  </>
);

const AddLinkButton = ({ handleAddLink }) => {
  const handleLinkClick = (setShowModal) => {
    if (setShowModal) {
      setShowModal(true);
    }
  };

  const [linkVal, setLinkVal] = useState(null);
  const _onConfirmLink = (setIsVisible) => {
    // add http in front of link if missing
    const parse = new Url(linkVal, {}, true);
    if (parse.protocol === '') {
      parse.set('protocol', 'http');
      parse.set('slashes', '//');
    }

    handleAddLink(parse.toString());
    setIsVisible(false);
    setLinkVal(null);
  };

  const _onReject = (setIsVisible) => {
    setLinkVal(null);
    setIsVisible(false);
  };

  const handleOnChange = (e) => {
    if (e.target && e.target.value) {
      setLinkVal(e.target.value);
    } else {
      setLinkVal(null);
    }
  };
  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onFocus={(e) => e.stopPropagation()}
      onMouseOver={(e) => e.stopPropagation()}
    >
      <ModalPanel
        header="Enter Link Below"
        body={BodyForm({ handleOnChange })}
        footer={({ setIsVisible }) => ModalFooter({ setIsVisible, _onReject, _onConfirmLink })}
        className="template-file-modal-panel"
        centered
      >
        {({ setIsVisible }) => (
          <Button
            bsPrefix="btn template-upload-attachment__btn"
            onClick={() => {
              handleLinkClick(setIsVisible);
            }}
          >
            <FontAwesomeIcon icon={['far', 'link']} />
            <span className="ml-2">Add External Link</span>
          </Button>
        )}
      </ModalPanel>
    </div>
  );
};

export { AddLinkButton };
