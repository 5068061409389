import React, { useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { BotConstants } from '../../../../../constants/BotConstants';
import { StyledMultiselect } from '../../../../common/StyledMultiselect/StyledMultiselect';
import { BotFormLabel } from '../../_components/BotFormLabel';
import { CdsModels } from '../../CdsModels';

const validateColumnName = (columnName, columnNames) => {
  if (!columnName || columnName.length === 0) {
    return 'This field is required';
  }
  if (columnNames.includes(columnName)) {
    return 'Column name already exists in the file';
  }
  return '';
};

const handleFormStateUpdate = (state, action) => {
  const { field, payload } = action;
  let newState = {
    ...state,
    config: {
      ...state.config,
      [field]: payload,
    },
  };

  if (field === 'column_name')
    newState = {
      ...newState,
      errors: {
        ...newState.errors,
        column_name: validateColumnName(payload, newState.columnNames),
      },
    };

  return newState;
};

const initializeFormState = ({ state, headers: columnNames }) => {
  const config = {
    ...state,
    length: BotConstants.GPT_DESCRIPTION_GENERATOR_LENGTHS.find(
      (option) => option.value === state.length
    ),
    tone: BotConstants.GPT_DESCRIPTION_GENERATOR_TONES.find(
      (option) => option.value === state.tone
    ),
    goal: BotConstants.GPT_DESCRIPTION_GENERATOR_GOALS.find(
      (option) => option.value === state.goal
    ),
    additional_languages: state.additional_languages?.map((language) =>
      BotConstants.GPT_GENERATOR_LANGUAGES.find((option) => option.value === language)
    ),
  };
  return {
    config,
    columnNames,
    errors: {
      column_name: validateColumnName(config.column_name, columnNames),
    },
  };
};

/**
 * Form Component that allows the user to define configure the Content for the CDS tool
 * @param {Object} state - The current state
 * @param {function} setState - Callback to set the state
 * @param {string[]} columnNames - The list of column names
 * @param {function} setInvalid - Callback to set if the form is invalid
 * @param {boolean} isLoading - If true, the component is disabled
 * @param {{options, tooltip}} languageConfig - The list of languages options
 * @param {{options, tooltip}} toneConfig - The list of tones options
 * @param {{options, tooltip}} goalConfig - The list of goals options
 * @param {{options, tooltip}} lengthConfig - The list of lengths options
 *
 * @returns {JSX.Element} Component
 */
const CdsGptContentForm = ({
  state,
  setState,
  disabled = false,
  columnNames,
  setInvalid = undefined,
  isLoading = false,
  languageConfig = {
    options: BotConstants.GPT_GENERATOR_LANGUAGES,
    tooltip: CdsModels.CDS_TOOL_TIP.additionalLanguages,
  },
  toneConfig = {
    options: BotConstants.GPT_DESCRIPTION_GENERATOR_TONES,
    tooltip: CdsModels.CDS_TOOL_TIP.tone,
  },
  goalConfig = {
    options: BotConstants.GPT_DESCRIPTION_GENERATOR_GOALS,
    tooltip: CdsModels.CDS_TOOL_TIP.goal,
  },
  lengthConfig = {
    options: BotConstants.GPT_DESCRIPTION_GENERATOR_LENGTHS,
    tooltip: CdsModels.CDS_TOOL_TIP.length,
  },
}) => {
  const [formState, onFormStateUpdated] = useReducer(
    handleFormStateUpdate,
    { state, headers: columnNames },
    initializeFormState
  );

  useEffect(() => {
    if (setInvalid) {
      const { config } = formState;
      setInvalid(
        formState.errors?.column_name?.length > 0 || !config.length || !config.tone || !config.goal
      );
    }

    setState({
      ...formState.config,
      length: formState.config.length?.value,
      tone: formState.config.tone?.value,
      goal: formState.config.goal?.value,
      additional_languages: formState.config.additional_languages?.map(
        (language) => language.value
      ),
    });
  }, [formState, setState, setInvalid]);

  return (
    <>
      <section className="new-description">
        <div className="content">
          <BotFormLabel
            label="Name"
            tooltip={CdsModels.CDS_TOOL_TIP.name}
            type="subsection"
            required
          />
          <div className="content">
            <Form.Group>
              <Form.Control
                as="input"
                value={formState.config.column_name ?? ''}
                placeholder="Name your new description (ex: SEO Description 1)"
                onChange={(e) =>
                  onFormStateUpdated({
                    field: 'column_name',
                    payload: e.target.value,
                  })
                }
                maxLength="100"
                disabled={isLoading || disabled}
                isInvalid={formState.errors?.column_name?.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                <sup>*</sup>
                {formState.errors?.column_name}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      </section>
      <section className="additional-languages">
        <div className="content">
          <BotFormLabel
            label="Additional Languages"
            tooltip={languageConfig.tooltip}
            type="subsection"
          />

          <StyledMultiselect
            values={formState.config.additional_languages}
            options={languageConfig.options}
            setOnChange={(e) =>
              onFormStateUpdated({
                field: 'additional_languages',
                payload: e,
              })
            }
            getOptionValue={(option) => option.value}
            closeMenuOnSelect={false}
            isClearable
            isMulti
            isDisabled={isLoading || disabled}
          />
        </div>
      </section>
      <section className="guidelines">
        <div className="content">
          <BotFormLabel
            label="Guidelines"
            tooltip={CdsModels.CDS_TOOL_TIP.guidelines}
            type="subsection"
          />
          <div className="content-guidelines">
            <section>
              <BotFormLabel label="Lenght" tooltip={lengthConfig.tooltip} required />
              <StyledMultiselect
                values={formState.config.length}
                options={lengthConfig.options}
                setOnChange={(e) =>
                  onFormStateUpdated({
                    field: 'length',
                    payload: e,
                  })
                }
                getOptionValue={(option) => option.value}
                closeMenuOnSelect
                canReset
                isClearable
                isMulti={false}
                isDisabled={isLoading || disabled}
                isInvalid={!isLoading && !formState.config.length}
              />
            </section>
            <section>
              <BotFormLabel label="Tone" tooltip={toneConfig.tooltip} required />
              <StyledMultiselect
                values={formState.config.tone}
                options={toneConfig.options}
                setOnChange={(e) =>
                  onFormStateUpdated({
                    field: 'tone',
                    payload: e,
                  })
                }
                getOptionValue={(option) => option.value}
                closeMenuOnSelect
                canReset
                isClearable
                isMulti={false}
                isDisabled={isLoading || disabled}
                isInvalid={!isLoading && !formState.config.tone}
              />
            </section>
            <section>
              <BotFormLabel label="Goal" tooltip={goalConfig.tooltip} required />
              <StyledMultiselect
                defaultValue={formState.config.goal}
                values={formState.config.goal}
                options={goalConfig.options}
                setOnChange={(e) =>
                  onFormStateUpdated({
                    field: 'goal',
                    payload: e,
                  })
                }
                getOptionValue={(option) => option.value}
                closeMenuOnSelect
                canReset
                isClearable
                isMulti={false}
                isDisabled={isLoading || disabled}
                isInvalid={!isLoading && !formState.config.goal}
              />
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

CdsGptContentForm.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  setInvalid: PropTypes.func,
  isLoading: PropTypes.bool,
};

CdsGptContentForm.defaultProps = {
  setInvalid: undefined,
  isLoading: false,
};

export { CdsGptContentForm };
