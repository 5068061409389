import React from 'react';

import PropTypes from 'prop-types';

/**
 * Default body template for DatabotConfigPanel.
 *
 * This is just a wrapper template to provide consistent className.
 *
 * Do NOT import this directly. Use it through the main component - <DatabotConfigPanel.Template[name] />
 *
 * @param {element} children Content
 * @return render
 */
const TemplateBody = ({ children }) => {
  return <section className="databot-config-panel-body">{children}</section>;
};

TemplateBody.defaultProps = {
  children: null,
};

TemplateBody.propTypes = {
  children: PropTypes.node,
};

export { TemplateBody };
