import React from 'react';

import { CircularProgressBar } from 'components/common/CircularProgressBar/CircularProgressBar';
import { RequestUpdateCard } from 'components/common/RequestUpdateCard/RequestUpdateCard';
import { ValueBlock } from 'components/common/ValueBlock/ValueBlock';
import * as TimeUtils from 'helpers/TimeUtils';

// Color class for circular progressbar per fill rate percentage
const getFillRateStatusClass = (fillRate) => {
  if (Number.isFinite(fillRate)) {
    if (fillRate >= 80) return CircularProgressBar.STATUSES.GOOD;
    if (fillRate >= 40) return CircularProgressBar.STATUSES.ACCEPTABLE;
    return CircularProgressBar.STATUSES.POOR;
  }
  return null;
};

/**
 * Summary data section
 *
 * @param {object} additionalData Summary data
 * @param {object} botData Selected mfr data
 * @return render
 */
const PricingDataOverview = ({ additionalData }) => {
  const fillRatePercentage = Number.isFinite(additionalData?.fill_rate)
    ? additionalData.fill_rate * 100
    : null;

  return (
    <div className="pricing-data-overview">
      <div className="heading">
        <div className="title">Pricing Data Overview:</div>
        <div className="last-run">
          <span className="label">Last Run:</span>{' '}
          <span className="value">
            {additionalData?.last_run
              ? TimeUtils.formatMinDateStamp(additionalData?.last_run)
              : 'N/A'}
          </span>
        </div>
      </div>
      <div className="overview">
        <div className="overview-item fill-rate">
          <CircularProgressBar
            percentage={fillRatePercentage}
            label="Best Practice Fill Rate"
            status={getFillRateStatusClass(fillRatePercentage)}
            isSemiCircle
            isShowPercentage
            isShowStatus
          />
        </div>
        <div className="overview-item missing-msrp">
          <ValueBlock
            title={
              <>
                Missing:
                <br />
                Published List Price
              </>
            }
            value={additionalData?.missing_published_list_price ?? 'N/A'}
          />
        </div>
        <div className="overview-item missing-ped">
          <ValueBlock
            title={
              <>
                Missing:
                <br />
                Published Effective Date
              </>
            }
            value={additionalData?.missing_published_effective_date ?? 'N/A'}
          />
        </div>
        <div className="overview-item missing-ed">
          <ValueBlock
            title={
              <>
                Missing:
                <br />
                Published Expiration Date
              </>
            }
            value={additionalData?.missing_expiration_date ?? 'N/A'}
          />
        </div>
        <div className="overview-item missing-pricing">
          {fillRatePercentage !== 100 && (
            <RequestUpdateCard
              title="Send Pricing Information"
              description="Let BackboneAI update your pricing for you."
              actionText="Send Pricing Info"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { PricingDataOverview };
