import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { Button, Col, Container, Form, Row, FormControl } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup'; // for everything

import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';
import { getAllManufacturers } from 'store/actions/Actions';

import { getAuthToken } from 'helpers/AuthTokenUtils';

const APPROVE = 'approve';
const CHANGESTATUS = 'change-status';
const DELETE = 'delete';
const CANCEL = 'cancel';

// TODO: Convert to functional component
// TODO: Remove Redux logic
class DataTeamActionsForm extends React.Component {
  initState = {
    createForm: {
      source_status: '',
      product_status: '',
      product_status_by_ids: '',
      change_status_manufacturer_id: '',
      approve_manufacturer_id: '',
      cancel_manufacturer_id: '',
      delete_manufacturer_id: '',
      approve_product_id_list: [],
      status_by_product_id_list: [],
      cancel_product_id_list: [],
      delete_product_id_list: [],
    },
    createFormErrors: {},
    selected_file: null,
    container_name: null,
    container_files: [],
    currentPage: 1,
    filesLength: 0,
    filesPerPage: 10,
    offset: 0,
    hideTable: true,
    columns: [
      { dataField: 'name', text: 'Blob name' },
      { dataField: 'updated_at', text: 'Uploaded at' },
      {
        dataField: 'link',
        text: 'Actions',
        formatter: (rowContent, row) => {
          return (
            <a
              className="btn btn-primary"
              style={{ marginRight: '0.5em' }}
              target="_blank"
              rel="noopener noreferrer"
              href={`${ApiCalls.BASE_API_URL}/download/${
                this.state.container_name
              }?filename=${row.name}&token=${getAuthToken()}`}
            >
              <span className="icon" style={{ marginRight: '0.5em' }}>
                <FontAwesomeIcon icon={['far', 'file-download']} />
              </span>
              Download
            </a>
          );
        },
      },
    ],
  };

  state = {
    ..._cloneDeep(this.initState),
  };

  createFormSchema = yup.object().shape({
    manufacturer_id: yup.string('Invalid value').required('This field is required'),
  });

  componentDidMount() {
    this.doGetAllManufacturers();
  }

  componentDidUpdate(prevProps) {
    const prevLocationKey = _get(prevProps.location, 'key') || null;
    const newLocationKey = _get(this.props.location, 'key') || null;
    if (prevLocationKey !== newLocationKey) {
      this.setState({
        ..._cloneDeep(this.initState),
      });
    }

    const prevSubmitResponse = prevProps.createDataRequestResponse;
    const newSubmitResponse = this.props.createDataRequestResponse;

    if (
      newSubmitResponse &&
      newSubmitResponse.status === ActionStatusConstants.SUCCESS &&
      prevSubmitResponse &&
      prevSubmitResponse.status !== ActionStatusConstants.SUCCESS
    ) {
      this.props.history.push({
        pathname: `/data-jobs`,
        state: { isCreateSuccess: true },
      });
    }
  }

  handlePageChange(page, sizePerPage) {
    if (this.state.currentPage !== page && this.state.offset !== sizePerPage) {
      this.setState({ currentPage: page });
      this.setState({ offset: sizePerPage });
      this.listContainerFiles(page, sizePerPage);
    }
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (this.state.currentPage === page) {
      return;
    }
    this.handlePageChange(page, currentIndex);
  };

  getMfrDropOptions() {
    const mfrs = _get(this.props, 'getAllManufacturersResponse.payload');
    let output = null;

    if (mfrs && mfrs.length) {
      output = mfrs.map((item) => {
        return (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        );
      });
    }

    return output;
  }

  getStatusDropOptions() {
    const statuses = [
      {
        id: 1,
        name: "Pending"
      },{
        id: 2,
        name: "Completed"
      },{
        id: 3,
        name: "Error"
      },{
        id: 4,
        name: "New Products"
      },{
        id: 5,
        name: "Updated Products"
      },{
        id: 6,
        name: "Canceled"
      },
    ];

    const output = statuses.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {`${item.name} : ${item.id}`}
        </option>
      );
    });

    return output;
  }

  getSourceStatusDropOptions() {
    let des_statuses = this.getStatusDropOptions()
    const all_statuses_item = (
        <option key="-1" value="-1">
          All
        </option>
      )
    des_statuses.splice(0, 0, all_statuses_item);
    
    return des_statuses
  }
  doGetAllManufacturers() {
    this.props.getAllManufacturers();
  }

  doActionById(id, action, status = null) {
    let url,
      responseText,
      method = null;
    switch (action) {
      case APPROVE:
        url = '/products/approve';
        responseText = 'Approved';
        method = ApiCalls.HTTP_METHODS.PUT;
        break;
      case CHANGESTATUS:
        url = '/products/change-status';
        responseText = 'Status Changed';
        method = ApiCalls.HTTP_METHODS.PUT;
        break;
      case CANCEL:
        url = '/products/cancel';
        responseText = 'Canceled';
        method = ApiCalls.HTTP_METHODS.PUT;
        break;
      case DELETE:
        url = '/products/delete';
        responseText = 'Deleted';
        method = ApiCalls.HTTP_METHODS.POST;
        break;
      default:
        return;
    }
    let data = { product_ids: id }

    if (status) {
      Object.assign(data, { status })
    }
    const reqCfq = {
      method,
      urlPath: url,
      data: data,
      onSuccess: () => {
        toast.success(`${responseText} products ${id}.`);
      },
      onError: (err) => {
        toast.error(err.response.data);
      },
    };
    ApiCalls.doCall(reqCfq);
  }

  doActionsByProductIds(data, action, status = null) {
    const dataArr = data.split(',');
    this.doActionById(dataArr, action, status);
  }

  doActionByManufacturer(id, action, status = null, source_status = null) {
    let url,
      responseText,
      method = null;
    switch (action) {
      case APPROVE:
        url = '/products/approve';
        responseText = 'Approved';
        method = ApiCalls.HTTP_METHODS.PUT;
        break;
      case CHANGESTATUS:
        url = '/products/change-status';
        responseText = 'Status Changed';
        method = ApiCalls.HTTP_METHODS.PUT;
        break;
      case CANCEL:
        url = '/products/cancel';
        responseText = 'Canceled';
        method = ApiCalls.HTTP_METHODS.PUT;
        break;
      case DELETE:
        url = '/products/delete';
        responseText = 'Deleted';
        method = ApiCalls.HTTP_METHODS.POST;
        break;
      default:
        return;
    }
    let data = { manufacturer_id: id }

    if (status) {
      Object.assign(data, { status })
    }
    
    if (source_status) {
      Object.assign(data, { source_status })
    }
    
    const reqCfg = {
      method,
      urlPath: url,
      data: data,
      onSuccess: () => {
        toast.success(`${responseText} all products for manufacturer ${id}.`);
      },
      onError: (err) => {
        toast.error(err.response.data);
      },
    };
    ApiCalls.doCall(reqCfg);
  }

  uploadFile(file, container) {
    if (file.size > 2e9) {
      toast.error(
        'The file you are trying to upload exceeds the 2GB attachment limit. Try putting in a shared location and adding a link instead.'
      );
      return;
    }
    const data = { file, container_name: container };

    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/upload-to-azure`,
      data,
      asFormData: true,
      onSuccess: () => {
        toast.success(`Uploaded ${file.name}.`);
      },
    };
    ApiCalls.doCall(reqCfg);
  }

  listContainerFiles(page, offset) {
    const container = this.state.container_name;
    if (!container) {
      toast.error(`No container name supplied`);
      return;
    }
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/blob-names/${container}?offset=${offset}`,

      onSuccess: (response) => {
        this.setState({ container_files: response.data.blob_files });
        this.setState({ filesLength: response.data.total });
        this.setState({ hideTable: false });
      },
      onError: () => {
        toast.error(`Something went wrong, try again later`);
      },
    };
    ApiCalls.doCall(reqCfg);
  }

  disableButton(loading, values) {
    if (loading !== true && values.every(Boolean)) {
      return false;
    }
    return true;
  }

  render() {
    const isLoading =
      _get(this.props, 'createDataRequestResponse.status') === ActionStatusConstants.ISBUSY;

    const { createForm } = this.state;
    const { createFormErrors } = this.state;

    const mfrDropOptions = this.getMfrDropOptions();
    const statusDropOptions = this.getStatusDropOptions();
    const sourceStatusDropOptions = this.getSourceStatusDropOptions();

    return (
      <Container fluid className="content content-fluid pl-0 pr-0 mt-4">
        <>
          <Helmet bodyAttributes={{ 'data-page': 'data-team' }}>
            <title>Data Team</title>
          </Helmet>
          <div className="data-request-create-form">
            <Row className="mt-4">
              <Col sm={12}>
                <Form noValidate>
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h1>Data Team Actions</h1>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>

                  {/* Change status by Manufacutrer */}
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Change Products Status by Manufacurer</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={3} controlId="sourcestatus" className="sourcestatus">
                      <Form.Control
                        as="select"
                        value={createForm.source_status}
                        onChange={(e) =>
                          this.setState({
                            createForm: { ...createForm, source_status: e.target.value },
                          })
                        }
                        isInvalid={!!createFormErrors.source_status}
                        disabled={!(sourceStatusDropOptions && sourceStatusDropOptions.length) || isLoading}
                      >
                        <option value="">From Status</option>
                        {sourceStatusDropOptions}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {createFormErrors.source_status}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={3} controlId="manufacturer" className="manufacturer">
                      <Form.Control
                        as="select"
                        value={createForm.change_status_manufacturer_id}
                        onChange={(e) =>
                          this.setState({
                            createForm: { ...createForm, change_status_manufacturer_id: e.target.value },
                          })
                        }
                        isInvalid={!!createFormErrors.change_status_manufacturer_id}
                        disabled={!(mfrDropOptions && mfrDropOptions.length) || isLoading}
                      >
                        <option value="">Select Manufacturer</option>
                        {mfrDropOptions}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {createFormErrors.product_status}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={3} controlId="manufacturer" className="manufacturer">
                      <Form.Control
                        as="select"
                        value={createForm.product_status}
                        onChange={(e) =>
                          this.setState({
                            createForm: { ...createForm, product_status: e.target.value },
                          })
                        }
                        isInvalid={!!createFormErrors.product_status}
                        disabled={!(statusDropOptions && statusDropOptions.length) || isLoading}
                      >
                        <option value="">To Status</option>
                        {statusDropOptions}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {createFormErrors.product_status}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={3}>
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to change products status for this manufacturer's products? This action cannot be undone."
                            )
                          )
                            this.doActionByManufacturer(
                              this.state.createForm.change_status_manufacturer_id,
                              CHANGESTATUS,
                              this.state.createForm.product_status,
                              this.state.createForm.source_status
                            );
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.createForm.change_status_manufacturer_id,
                          this.state.createForm.product_status,
                          this.state.createForm.source_status,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
                        </span>
                        Change status
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  {/* Change status by Product ids */}
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Change Products Status by Product Ids</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={4} controlId="statusbypordids" className="statusbypordids">
                      <FormControl
                        onChange={(e) => this.setState({ status_by_product_id_list: e.target.value })}
                        placeholder="Enter a comma separated list of product ids Ex. 11,12,23,45"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4} controlId="manufacturer" className="manufacturer">
                      <Form.Control
                        as="select"
                        value={createForm.product_status_by_ids}
                        onChange={(e) =>
                          this.setState({
                            createForm: { ...createForm, product_status_by_ids: e.target.value },
                          })
                        }
                        isInvalid={!!createFormErrors.product_status_by_ids}
                        disabled={!(statusDropOptions && statusDropOptions.length) || isLoading}
                      >
                        <option value="">Select Status</option>
                        {statusDropOptions}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {createFormErrors.product_status}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to change products status for this manufacturer's products? This action cannot be undone."
                            )
                          )
                            this.doActionsByProductIds(
                              this.state.status_by_product_id_list,
                              CHANGESTATUS,
                              this.state.createForm.product_status_by_ids
                            );
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.createForm.status_by_product_id_list,
                          this.state.createForm.product_status_by_ids,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
                        </span>
                        Change status
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  {/* START OF APPROVE PRODUCTS */}
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Approve Products by Product ID</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="approvebyid" className="approvebyid">
                      <FormControl
                        onChange={(e) => this.setState({ approve_product_id_list: e.target.value })}
                        placeholder="Enter a comma separated list of product ids Ex. 11,12,23,45"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      sm={6}
                      controlId="approvebyidbutton"
                      className="approvebyidbutton"
                    >
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to approve these products? This action cannot be undone.'
                            )
                          )
                            this.doActionsByProductIds(this.state.approve_product_id_list, APPROVE);
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.approve_product_id_list,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
                        </span>
                        Approve Products By ID
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Approve All Products for Manufacturer</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="manufacturer" className="manufacturer">
                      <Form.Control
                        as="select"
                        value={createForm.approve_manufacturer_id}
                        onChange={(e) =>
                          this.setState({
                            createForm: { ...createForm, approve_manufacturer_id: e.target.value },
                          })
                        }
                        isInvalid={!!createFormErrors.approve_manufacturer_id}
                        disabled={!(mfrDropOptions && mfrDropOptions.length) || isLoading}
                      >
                        <option value="">Select Manufacturer</option>
                        {mfrDropOptions}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {createFormErrors.approve_manufacturer_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={6}>
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to approve this manufacturer's products? This action cannot be undone."
                            )
                          )
                            this.doActionByManufacturer(
                              this.state.createForm.approve_manufacturer_id,
                              APPROVE
                            );
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.createForm.approve_manufacturer_id,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
                        </span>
                        Approve All Products
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  {/* END OF APPROVE PRODUCTS */}

                  {/* START OF CANCEL PRODUCTS */}
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Cancel Products by Product ID</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="cancelbyid" className="cancelbyid">
                      <FormControl
                        onChange={(e) => this.setState({ cancel_product_id_list: e.target.value })}
                        placeholder="Enter a comma separated list of product ids Ex. 11,12,23,45"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      sm={6}
                      controlId="cancelbyidbutton"
                      className="cancelbyidbutton"
                    >
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to cancel these products? This action cannot be undone.'
                            )
                          )
                            this.doActionsByProductIds(this.state.cancel_product_id_list, CANCEL);
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.cancel_product_id_list,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
                        </span>
                        Cancel Products By ID
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Cancel All Products for Manufacturer</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="manufacturer" className="manufacturer">
                      <Form.Control
                        as="select"
                        value={createForm.cancel_manufacturer_id}
                        onChange={(e) =>
                          this.setState({
                            createForm: { ...createForm, cancel_manufacturer_id: e.target.value },
                          })
                        }
                        isInvalid={!!createFormErrors.cancel_manufacturer_id}
                        disabled={!(mfrDropOptions && mfrDropOptions.length) || isLoading}
                      >
                        <option value="">Select Manufacturer</option>
                        {mfrDropOptions}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {createFormErrors.cancel_manufacturer_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={6}>
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to cancel this manufacturer's products? This action cannot be undone."
                            )
                          )
                            this.doActionByManufacturer(
                              this.state.createForm.cancel_manufacturer_id,
                              CANCEL
                            );
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.createForm.cancel_manufacturer_id,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
                        </span>
                        Cancel All Products
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  {/* END OF CANCEL PRODUCTS */}

                  {/* START OF DELETE PRODUCTS */}
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Delete Products by Product ID</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="deletebyid" className="deletebyid">
                      <FormControl
                        onChange={(e) => this.setState({ delete_product_id_list: e.target.value })}
                        placeholder="Enter a comma separated list of product ids Ex. 11,12,23,45"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      sm={6}
                      controlId="deletebyidbutton"
                      className="deletebyidbutton"
                    >
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to delete these products? This action cannot be undone.'
                            )
                          )
                            this.doActionsByProductIds(this.state.delete_product_id_list, DELETE);
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.delete_product_id_list,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
                        </span>
                        Delete Products By ID
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Delete All Products for Manufacturer</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="manufacturer" className="manufacturer">
                      <Form.Control
                        as="select"
                        value={createForm.delete_manufacturer_id}
                        onChange={(e) =>
                          this.setState({
                            createForm: { ...createForm, delete_manufacturer_id: e.target.value },
                          })
                        }
                        isInvalid={!!createFormErrors.delete_manufacturer_id}
                        disabled={!(mfrDropOptions && mfrDropOptions.length) || isLoading}
                      >
                        <option value="">Select Manufacturer</option>
                        {mfrDropOptions}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {createFormErrors.delete_manufacturer_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={6}>
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this manufacturer's products? This action cannot be undone."
                            )
                          )
                            this.doActionByManufacturer(
                              this.state.createForm.delete_manufacturer_id,
                              DELETE
                            );
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.createForm.delete_manufacturer_id,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
                        </span>
                        Delete All Products
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  {/* END OF DELETE PRODUCTS */}
                  {/* START OF UPLOAD FILE */}
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Upload File to Azure</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={3} controlId="selectfile">
                      <Form.File
                        onChange={(e) => this.setState({ selected_file: e.target.files[0] })}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={3} controlId="selectcontainer">
                      <Form.Control
                        onChange={(e) => this.setState({ container_name: e.target.value })}
                        placeholder="Enter a container name for the azure repo"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={6} controlId="uploadfile">
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          this.uploadFile(this.state.selected_file, this.state.container_name);
                        }}
                        disabled={this.disableButton(this.isLoading, [
                          this.state.selected_file,
                          this.state.container_name,
                        ])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'boxes-alt']} />
                        </span>
                        Upload file
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  {/* END OF UPLOAD FILE */}
                  {/* START OF DOWNLOAD FILE */}
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <h3>Download File</h3>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={6} controlId="selectcontainer">
                      <Form.Control
                        onChange={(e) => this.setState({ container_name: e.target.value })}
                        placeholder="Enter a container name for the azure repo"
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={6} controlId="uploadfile">
                      <Button
                        size="lg"
                        variant="primary"
                        style={{ marginLeft: '1em' }}
                        onClick={() => {
                          this.listContainerFiles(this.state.container_name);
                        }}
                        disabled={this.disableButton(this.isLoading, [this.state.container_name])}
                      >
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'boxes-alt']} />
                        </span>
                        View Files
                      </Button>
                    </Form.Group>

                    <div hidden={this.state.hideTable}>
                      <div className="bootstrap-table-wrap">
                        <BootstrapTable
                          keyField="id"
                          data={this.state.container_files}
                          columns={this.state.columns}
                          remote={{ pagination: true }}
                          onTableChange={this.handleTableChange}
                          pagination={paginationFactory({
                            page: this.state.currentPage,
                            sizePerPage: this.state.filesPerPage,
                            totalSize: this.state.filesLength,
                            showTotal: true,
                            // onPageChange: this.handlePageChange(this.state.currentPage, this.state.filesPerPage),
                          })}
                        >

                        </BootstrapTable>
                      </div>
                    </div>
                  </Form.Row>
                  {/* END OF DOWNLOAD FILE */}
                </Form>
              </Col>
            </Row>
          </div>
        </>
      </Container>
    );
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    getAllManufacturersResponse: {
      status: state.GetAllManufacturersReducer.status,
      payload: state.GetAllManufacturersReducer.payload,
    },
  };
};

const mapDispatchToProps = {
  getAllManufacturers,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataTeamActionsForm));
