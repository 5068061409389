import "./OKModal.scss";

import React, { useCallback } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

import { ModalPanel } from "./ModalPanelDetach";

/**
 * OKModal.
 *
 * @param {Object} - props
 * @param {function} - props.onOpen - function called on modal open
 * @param {function} - props.onClose - function called on modal close regardless of button click
 * @param {function} - props.onOk - function called on ok button click
 * @param {Object} - props.header - header string or react node
 * @param {Object} - props.body - header string or react node
 * @param {Object} - props.footer - header string or react node - this is an override for existings Ok and opt cancel button
 * @param {String} - props.className - for underlying body component
 * @param {Boolean} - props.vertCentered - boolean whether to center modal vertical, default true
 * @param {String} -  props.buttonText - text to show in ok button
 * @param {Boolean} - props.isDanger - show danger button variant
 * @param {Boolean} - props.isWarning - show warning button variant
 * @param {function} - props.setIsVisible - function to set visibility of modal
 * @param {Boolean} - props.isVisible - prop to control visibility of modal
 * @param {Boolean} - props.showCancel - prop to control visibiility of cancel button
 *
 */

const OKModal = ({
  onOpen,
  onClose,
  onOk,
  header,
  body,
  footer,
  className,
  vertCentered = true,
  buttonText = "Ok",
  isDanger = false,
  isWarning = false,
  setIsVisible,
  isVisible,
  showCancel = false,
  backdrop = 'static',
}) => {
  const handleOkClick = useCallback(() => {
    setIsVisible(false);
    typeof onOk === "function" && onOk();
  }, [onOk, setIsVisible]);

  const localHeader = useCallback(() => {
    if (header) return header;
    return <></>;
  }, [header]);

  const localBody = useCallback(() => {
    if (body) return body;
    return <>"Are you sure?"</>;
  }, [body]);

  const localFooter = useCallback(() => {
    if (footer) return footer;
    let wrapper = "";
    let variant = "primary";
    if (isDanger) variant = "danger";
    else if (isWarning) {
      wrapper = "template-okModal-warning-button";
      variant = "warning";
    }
    return (
      <>
        {showCancel && (
          <Button
            className="template-okModal-button"
            variant="secondary"
            onClick={() => {
              setIsVisible(false);
            }}
          >
            Cancel
          </Button>
        )}

        <div className={wrapper}>
          <Button className="template-okModal-button" variant={variant} onClick={handleOkClick}>
            {buttonText}
          </Button>
        </div>
      </>
    );
  }, [buttonText, footer, handleOkClick, isDanger, isWarning, setIsVisible, showCancel]);

  return (
    <ModalPanel
      onOpen={onOpen}
      onClose={onClose}
      header={localHeader}
      body={localBody}
      footer={localFooter}
      className={className}
      centered={vertCentered}
      setIsVisible={setIsVisible}
      isVisible={isVisible}
      backdrop={backdrop}
    />
  );
};

OKModal.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  header: PropTypes.any,
  vertCentered: PropTypes.bool,
  body: PropTypes.any,
  footer: PropTypes.any,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  isDanger: PropTypes.bool,
  isWarning: PropTypes.bool,
  setIsVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  showCancel: PropTypes.bool,
};

export { OKModal };
