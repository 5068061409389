import React, { useEffect, useState } from 'react';

import { PropTypes } from 'prop-types';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

import { hexToRgba, rgbaToHex } from 'helpers/Utils';

const ColorPicker = ({ selectedColor, onColorChange, disabled }) => {
  const [pickerState, setPickerState] = useState({
    displayColorPicker: false,
    color: hexToRgba(selectedColor),
  });

  const [pickerStyle, setPickerStyle] = useState(null);

  useEffect(() => {
    setPickerState({
      displayColorPicker: pickerState.displayColorPicker,
      color: hexToRgba(selectedColor),
    });
  }, [pickerState.displayColorPicker, selectedColor]);

  useEffect(() => {
    setPickerStyle(
      reactCSS({
        default: {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `rgba(${pickerState.color.r}, ${pickerState.color.g}, ${pickerState.color.b}, ${pickerState.color.a})`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      })
    );
  }, [pickerState]);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    setPickerState({
      displayColorPicker: !pickerState.displayColorPicker,
      color: pickerState.color,
    });
  };

  const handleClose = () => {
    setPickerState({ displayColorPicker: false, color: pickerState.color });
  };

  const handleChange = (color) => {
    setPickerState({ displayColorPicker: pickerState.displayColorPicker, color: color.rgb });
    onColorChange(rgbaToHex(color.rgb));
  };

  return (
    <div>
      <div style={pickerStyle?.swatch} onClick={handleClick}>
        <div style={pickerStyle?.color} />
      </div>
      {pickerState.displayColorPicker && !disabled ? (
        <div style={pickerStyle?.popover}>
          <div style={pickerStyle?.cover} onClick={handleClose} />
          <SketchPicker disableAlpha color={pickerState.color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

ColorPicker.defaultProps = {
  selectedColor: '#000000FF',
};
ColorPicker.propTypes = { selectedColor: PropTypes.string };

export { ColorPicker };
