import React, { useEffect, useState } from 'react';

import { ManufacturerSelect } from 'components/common/ManufacturerSelect/ManufacturerSelect';
import { UserDisplay } from 'components/common/UserProfileDisplay/UserDisplay';
import { ValueBlock } from 'components/common/ValueBlock/ValueBlock';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { RootHooks } from 'helpers/RootHooks';
import * as TimeUtils from 'helpers/TimeUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import * as UserUtils from 'helpers/UserUtils';
import * as Utils from 'helpers/Utils';

import * as DataLakehouseDashboardApiCalls from './DataLakehouseDashboardApiCalls';

/**
 * Summary data section
 *
 * @param {number} selectedManufacturer State object for manufacturer id
 * @param {function} setSelectedManufacturer Set state object for manufacturer
 * @param {number} selectedBrand State object for brand
 * @param {function} setSelectedBrand Set state object for manufacturer
 * @param {boolean} hasChildren Does current manufacturer have children
 * @return render
 */
const SectionSummaryCompany = ({
  selectedManufacturer = null,
  onSelectManufacturer,
  selectedBrand = null,
  onSelectBrand,
  companyId = null,
  hasChildren = false,
}) => {
  const isMounted = useIsMounted();

  const { activeUser } = RootHooks.useActiveUser();

  const companyData = activeUser?.profile?.manufacturer || activeUser?.profile?.client || null;

  const isSupplier = UserUtils.isRoleSupplier(activeUser);

  // Holds summary section data
  const [summaryData, setSummaryData] = useState(null);
  const [summaryDataStatus, setSummaryDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds main contact user data
  const [defaultAssigneeData, setDefaultAssigneeData] = useState(null);
  const [defaultAssigneeDataStatus, setDefaultAssigneeDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  // Refresh dashboard data on mfr id set
  useEffect(() => {
    DataLakehouseDashboardApiCalls.doGetSummaryCompany({
      mfrId: companyId,
      isMounted,
      setSummaryData,
      setSummaryDataStatus,
    });

    DataLakehouseDashboardApiCalls.doGetDefaultAssignee({
      mfrId: companyId,
      isMounted,
      setDefaultAssigneeData,
      setDefaultAssigneeDataStatus,
    });
  }, [isMounted, companyId]);

  const isLoadingDefaultAssignee = defaultAssigneeDataStatus === ActionStatusConstants.ISBUSY;
  const isLoadingStats = summaryDataStatus === ActionStatusConstants.ISBUSY;

  return (
    <section className="summary-company">
      <div className="company-info-summary">
        {hasChildren ? (
          <ManufacturerSelect
            selectedManufacturer={selectedManufacturer}
            onSelectManufacturer={onSelectManufacturer}
            selectedBrand={selectedBrand}
            onSelectBrand={onSelectBrand}
            isDisabled={false}
          />
        ) : (
          <ValueBlock
            title="Company"
            value={companyData?.name}
            className="company-name"
            isBorderless
          />
        )}
        <ValueBlock
          title="Main Contact"
          value={Utils.renderLoadingOrContent(
            !!defaultAssigneeData?.id && (
              <UserDisplay
                name={Utils.joinNameToStr(
                  defaultAssigneeData?.first_name,
                  defaultAssigneeData?.last_name
                )}
                picture={defaultAssigneeData?.profile?.picture}
              />
            ),
            isLoadingDefaultAssignee
          )}
          className="main-contact"
          isBorderless
        />
      </div>
      <div className="databots-stats">
        {isSupplier ? (
          <ValueBlock
            title="# of Products on BackboneAI Portal"
            value={Utils.renderLoadingOrContent(summaryData?.products_count, isLoadingStats)}
            className="products-count"
          />
        ) : (
          <ValueBlock
            title="# of Manufacturers"
            value={Utils.renderLoadingOrContent(summaryData?.manufacturers_count, isLoadingStats)}
            className="manufacturers-count"
          />
        )}
        <ValueBlock
          title="# of Active Databots"
          value={
            isSupplier
              ? Utils.renderLoadingOrContent(summaryData?.running_bots, isLoadingStats)
              : Utils.renderLoadingOrContent('0', isLoadingStats)
          }
          className="active-databots-count"
          tooltip={
            isSupplier
              ? 'The number of databots running on your data'
              : 'The number of databots running on data in your data jobs'
          }
        />
        <ValueBlock
          title="Last Delivery"
          value={Utils.renderLoadingOrContent(
            summaryData?.last_delivery
              ? TimeUtils.formatDateStamp(summaryData?.last_delivery)
              : 'N/A',
            isLoadingStats
          )}
          className="last-delivery"
          tooltip={
            isSupplier
              ? 'The most recent date a Data Job has been accepted by one of your Distributors'
              : 'The most recent date a Data Job has been accepted by your company'
          }
        />
        <ValueBlock
          title="# of Completed Databots"
          value={Utils.renderLoadingOrContent(summaryData?.complete_bots, isLoadingStats)}
          className="completed-databots-count"
          tooltip={
            isSupplier
              ? 'The number of databots completed on your data'
              : 'The number of databots completed on data in your data jobs'
          }
        />
      </div>
    </section>
  );
};

SectionSummaryCompany.propTypes = {};

export { SectionSummaryCompany };
