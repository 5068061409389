import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotStatic = ({ description }) => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="static"
      middle={<div className="description">{description}</div>}
      bottom={
        <div className="databot-details__complete">
          <FontAwesomeIcon icon={['fas', 'check-circle']} /> Complete
        </div>
      }
    />
  );
};

export { DatabotStatic };
