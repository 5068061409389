import React, { useEffect, useState, useRef } from 'react';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { Paginator } from 'components/common/Paginator/Paginator';
import { PaginatorSelect } from 'components/common/Paginator/PaginatorSelect';
import { Search } from 'components/common/Search/Search';
import { SearchSuggestions } from 'components/common/SearchSuggestions/SearchSuggestions';
import { ImageQualityRow } from 'components/image-quality-assessment/ImageQualityRow/ImageQualityRow';
import { BOT_SLUGS } from 'constants/BotConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { useIsMounted } from 'helpers/useIsMounted';
import { useOutsideClick } from 'helpers/useOutsideClick';
import './ImageQualityAssessmentList.scss';
import { isConstantEqual } from 'helpers/Utils';

const ImageQualityAssessmentList = ({
  assessmentData,
  assessmentType,
  userType,
  id,
  page,
  setPage,
  pageSize,
  setPageSize,
  search,
  setSearch,
  isLoading,
}) => {
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
  const [areSuggestionsLoading, setAreSuggestionsLoading] = useState(false);
  const [localSearch, setLocalSearch] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const searchWrapperRef = useRef(null);
  const isMounted = useIsMounted();

  const slug = BOT_SLUGS.IMAGE_QUALITY;

  const pageOptions = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  // build the search suggestions list
  useEffect(() => {
    const params = {};
    if (search) params.search = search;
    else {
      setSearchSuggestions([]);
      setAreSuggestionsLoading(false);
      return;
    }
    params.suggest = true;

    if (isConstantEqual(userType, UserRoleConstants.MANUFACTURER)) {
      params.manufacturer_id = id;
    } else {
      params.client_id = id;
    }

    setAreSuggestionsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/report-data/${slug}`,
      params,
      onSuccess: (res) => {
        const { suggestions } = res.data;

        if (isMounted.current && suggestions !== undefined) {
          setSearchSuggestions(
            suggestions.map((item) => {
              return { value: item };
            })
          );
        }
      },
      onEnd: () => {
        setAreSuggestionsLoading(false);
      },
    });
  }, [isMounted, search, slug, id, userType]);

  useOutsideClick(searchWrapperRef, () => {
    if (areSuggestionsVisible) setAreSuggestionsVisible(false);
  });

  return (
    <div className="image-quality-assessments">
      <div ref={searchWrapperRef} className="image-quality-search">
        <Search
          onFocus={() => setAreSuggestionsVisible(true)}
          search={search}
          setLocalSearch={setLocalSearch}
          setSearch={setSearch}
          onSearchSubmit={() => {
            setAreSuggestionsVisible(false);
          }}
          placeholder={`Search ${
            isConstantEqual(userType, UserRoleConstants.MANUFACTURER)
              ? UserRoleConstants.DISTRIBUTOR
              : UserRoleConstants.MANUFACTURER
          }`}
        />
        <SearchSuggestions
          search={search}
          setLocalSearch={setLocalSearch}
          setSearch={setSearch}
          searchSuggestions={searchSuggestions}
          localSearch={localSearch}
          setAreSuggestionsVisible={setAreSuggestionsVisible}
          areSuggestionsLoading={areSuggestionsLoading}
          areSuggestionsVisible={areSuggestionsVisible}
        />
      </div>
      {isLoading ? (
        <div className="loading-container">
          <LoadingSpinner
            style={{ fontSize: '4em', marginTop: '1em', marginLeft: 'auto', marginRight: 'auto' }}
          />
        </div>
      ) : assessmentData.length > 0 ? (
        <div className="assessments-table">
          <div className="pagination-top">
            <PaginatorSelect
              page={page}
              setPage={(e) => setPage(e)}
              pageSize={pageSize}
              setPageSize={(e) => setPageSize(e)}
              options={pageOptions}
              totalItems={assessmentData.length}
            />
          </div>
          <div>
            {assessmentData.map((item, i) => (
              <ImageQualityRow
                assessmentType={assessmentType}
                data={item}
                key={i}
                type={userType}
              />
            ))}
          </div>
          <div className="pagination-bottom">
            <Paginator
              page={page}
              setPage={(e) => setPage(e)}
              pageSize={pageSize}
              totalItems={assessmentData.length}
            />
          </div>
        </div>
      ) : (
        <div>No assessments found.</div>
      )}
    </div>
  );
};

export { ImageQualityAssessmentList };
