import React, { useState } from 'react';

import PropTypes from 'prop-types';

import './PassThruFileDetailsSection.scss';
import { PassThruFileDetailSelection } from './PassThruFileDetailSelection/PassThruFileDetailSelection';
import { PassThruFileUploadFile } from './PassThruFileUploadFile/PassThruFileUploadFile';
import { PassThruFileUploadFileList } from './PassThruFileUploadFileList/PassThruFileUploadFileList';

const PassThruFileDetailsSection = ({
  isUploading,
  setIsUploading,
  dispatchChange,
  state,
  invalidCheck,
}) => {
  const [uploadId, setUploadId] = useState(null);

  return (
    <div className="pass-thru-details-container">
      <div className="data-job-create-section left">
        <h2>Details</h2>
        <h3>* Required Fields</h3>
        <PassThruFileDetailSelection
          setDetails={dispatchChange}
          invalidCheck={invalidCheck}
          details={state}
        />
      </div>
      <div className="data-job-create-section right">
        <div
          className={`pass-thru-file-uploadfile-container${!invalidCheck.files ? '-invalid' : ''}`}
        >
          <PassThruFileUploadFile
            setIsUploading={(v) => setIsUploading(v)}
            isUploading={isUploading}
            setFileOrLink={(v) => dispatchChange(v)}
            setUploadId={(id) => setUploadId(id)}
            uploadId={uploadId}
            invalidCheck={invalidCheck}
          />
        </div>
        {invalidCheck.files ? (
          <div className="pass-thru-file-invalid-feedback">Please include at least one file!</div>
        ) : null}
        <div className="data-job-file-list-container">
          <PassThruFileUploadFileList
            fileList={state.files}
            linkList={state.links}
            setFileOrLink={(v) => dispatchChange(v)}
            setUploadId={(id) => setUploadId(id)}
            uploadId={uploadId}
          />
        </div>
      </div>
    </div>
  );
};

PassThruFileDetailsSection.defaultProps = {
  isUploading: null,
  setIsUploading: () => {},
};

PassThruFileDetailsSection.propTypes = {
  isUploading: PropTypes.bool,
  setIsUploading: PropTypes.func,
  dispatchChange: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  invalidCheck: PropTypes.object.isRequired,
};

export { PassThruFileDetailsSection };
