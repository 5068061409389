import React from 'react';

import PropTypes from 'prop-types';

import { DatabotConfigModal } from 'components/databots/DatabotConfigModal/DatabotConfigModal';

/**
 * Default actions template for DatabotConfigPanel.
 *
 * Passing children to this component overrides default UI and just keeps the wrapper.
 *
 * Do NOT import this directly. Use it through the main component - <DatabotConfigPanel.Template[name] />
 *
 * @param {function} onRun Event handler
 * @param {function} onCancel Event handler
 * @param {boolean} disableRun Disables Run action
 * @param {boolean} disableCancel Disables Cancel action
 * @param {string} confirmDialogClassNameSuffix Suffix for modal className. Pass the bot slug here.
 * @param {element} confirmDialogTitle Confirm dialog title contents when running an action
 * @param {element} confirmDialogBody Confirm dialog body contents when running an action
 * @param {element} showModal Whether or not to display the confirmation modal
 * @param {element} children For completely custom template, keeps only the template wrapper and renders whatever is passed here
 * @return render
 */
const TemplateActions = ({
  preRunValidate,
  onRun,
  onCancel,
  disableRun,
  disableCancel,
  confirmCallback,
  confirmDialogClassNameSuffix,
  confirmDialogTitle,
  confirmDialogBody,
  showModal,
  children,
}) => {
  const validateRun = () => {
    return typeof preRunValidate === 'function' ? !!preRunValidate() : true;
  }

  const renderActions = () => (
    <>
      <button
        disabled={!!disableCancel}
        onClick={onCancel}
        className="btn databot-config-panel-actions__action databot-config-panel-actions__action--cancel"
        type="button"
      >
        Cancel
      </button>
      {showModal ? (
        <DatabotConfigModal
          className={`databot-config-modal-panel-${confirmDialogClassNameSuffix}`}
          header={confirmDialogTitle}
          onSubmit={onRun}
          body={confirmDialogBody}
        >
          {({ setIsVisible }) => (
            <button
              disabled={!!disableRun}
              onClick={() => {
                if (!validateRun()) return;
                if (typeof confirmCallback === 'function') confirmCallback();
                setIsVisible(true);
              }}
              className="btn databot-config-panel-actions__action databot-config-panel-actions__action--submit"
              type="button"
            >
              Confirm
            </button>
          )}
        </DatabotConfigModal>
      ) : (
        <button
          disabled={!!disableRun}
          onClick={() => {
            if (validateRun()) onRun();
          }}
          className="btn databot-config-panel-actions__action databot-config-panel-actions__action--submit"
          type="button"
        >
          Run
        </button>
      )}
    </>
  );

  return <section className="databot-config-panel-actions">{children ?? renderActions()}</section>;
};

TemplateActions.defaultProps = {
  disableRun: false,
  disableCancel: false,
  confirmCallback: null,
  confirmDialogTitle: null,
  confirmDialogBody: null,
  children: null,
};

TemplateActions.propTypes = {
  onRun: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disableRun: PropTypes.bool,
  disableCancel: PropTypes.bool,
  confirmCallback: PropTypes.func,
  confirmDialogClassNameSuffix: PropTypes.string.isRequired,
  confirmDialogTitle: PropTypes.any,
  confirmDialogBody: PropTypes.any,
  children: PropTypes.node,
};

export { TemplateActions };
