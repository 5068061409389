import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";

const _component = ({ src, style, alt, className, placeholder }) => {
  const [isLoadErr, setIsLoadErr] = useState(false);

  if (src && !isLoadErr) {
    return (
      <img
        style={style}
        src={src}
        alt={alt}
        className={className}
        onError={() => setIsLoadErr(true)}
      />
    );
  } else {
    return placeholder || <FontAwesomeIcon icon={["far", "times"]} />;
  }
};

_component.propTypes = {
  style: PropTypes.object,
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.any,
};

export default _component;
