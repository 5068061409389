import React from 'react';

import imgCandles from 'assets/img/imqa-faq/candles.png';
import imgGloves from 'assets/img/imqa-faq/gloves.png';
import imgHeadphones from 'assets/img/imqa-faq/headphones.png';
import imgMugs from 'assets/img/imqa-faq/mugs.png';
import imgNotFound from 'assets/img/imqa-faq/notfound.png';

import './ImageQualityAssessmentsFaqPanel.scss';
import { ImageQualityAssessmentsFaqItem } from './ImageQualityAssessmentsFaqItem';

const faqData = [
  {
    title: 'The image report says the product is not centered. ',
    description: (
      <p>
        If a product has a harsh shadow, our technology will sometimes read this as part of the
        product image.
      </p>
    ),
    image: imgHeadphones,
    imageCaption: 'Example Image',
  },
  {
    title: 'The image report says there is only one product in the photo.',
    description: (
      <>
        <p>
          If a product image has more than one image arranged in a way where they are on top of each
          other, our technology might flag it as one product.
        </p>
        <p>To ensure that this does not happen, be sure to arrange products individually.</p>
      </>
    ),
    image: imgCandles,
    imageCaption: 'Example Image',
  },
  {
    title: 'The image report says the image is blurry.',
    description: (
      <>
        <p>
          Our neural network uses industry standard image blur rules that work on everything from
          humans to ball bearings. Because our customer base is specific to industrial companies, we
          sometimes will flag an image that is blurry that is not blurry, and will sometimes pass an
          image that is blurry! We apologize in advance.
        </p>
        <p>
          In order to help train our algorithms to get it right, we need your help! Please provide
          feedback through the button at the top of this page to let us know what we got right — and
          what we didn’t.{' '}
        </p>
      </>
    ),
    image: imgMugs,
    imageCaption: 'Example Image',
  },
  {
    title: 'The image report says my image could not be found.',
    description: (
      <p>
        Sometimes, images get lost on the internet. Don’t worry – if you gave us the image, we have
        it. Please let us know if an assessment comes out with “No image found” We try to catch all
        of them in advance!
      </p>
    ),
    image: imgNotFound,
    imageCaption: 'Example Image',
  },
  {
    title: 'The image report says there is multiple products in one photo.',
    description: (
      <p>
        When an product has more than one product that are sold together, we may say the image is
        bad based on your companies criteria. We are taking to acocunt images that have “kit” in the
        product name or description, but things like gloves and ear plugs might make it past us.
      </p>
    ),
    image: imgGloves,
    imageCaption: 'Example Image',
  },
];

const ImageQualityAssessmentsFaqPanel = ({ companyId, type }) => {
  return (
    <div className="image-quality-assessments-faq-panel">
      <ul className="faq-list">
        {faqData.map((item, i) => (
          <li className="faq-item" key={i}>
            <ImageQualityAssessmentsFaqItem data={item} companyId={companyId} type={type} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export { ImageQualityAssessmentsFaqPanel };
