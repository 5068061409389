import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingText } from 'components/common/LoadingText/LoadingText';
import * as DataJobDetailsConstants from 'constants/DataJobDetailsConstants';
import { generateDownloadUrl } from 'helpers/AttachmentUtils';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import 'mdn-polyfills/MouseEvent';

import { DatabotsBlock } from './DatabotsBlock';
import './FilesBlock.scss';
import { FilesTable } from './FilesTable/FilesTable';
import { RootHooks } from 'helpers/RootHooks';

// TODO: This component should be refactored/optimized in a future iteration
/**
 * Renders the tabs (Transformed vs. Original), Retrieves attachment data, handles batch downloads
 * @param {integer} id Data Request ID
 * @param {Object} dataJobData
 * @param {array} dataJobCommentsData
 * @param {Object} dataJobCommentsDataStatus
 * @returns Render
 */
const FilesBlock = React.memo(
  ({ id, dataJobData, setDataJobData, dataJobCommentsData, dataJobCommentsDataStatus }) => {
    const { featureFlags } = RootHooks.useFeatureFlags();
    const isMounted = useIsMounted();

    const [activeTab, setActiveTab] = useState(DataJobDetailsConstants.TAB_NAMES.ORIGINAL);
    const [transformedFiles, setTransformedFiles] = useState([]);
    const [originalFiles, setOriginalFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [areAttachmentsLoading, setAreAttachmentsLoading] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);

    // Sort data job's attachments into original vs transformed
    const sortAttachments = (files) => {
      const transformedFiles = [];
      const originalFiles = [];

      if (files?.length) {
        files.forEach((item) => {
          if (
            item.transformation_required ===
            DataJobDetailsConstants.TRANSFORMATION_REQUIRED_STATUS.DONE.value
          ) {
            transformedFiles.push(item);
          } else {
            originalFiles.push(item);
          }
        });
      }
      setTransformedFiles(transformedFiles);
      setOriginalFiles(originalFiles);
    };

    const getAttachmentData = useCallback((_id, _isMounted) => {
      setAreAttachmentsLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/data-requests/${_id}/attachments/`,
        onSuccess: (res) => {
          if (_isMounted?.current) {
            sortAttachments(res.data);
          }
        },
        onEnd: () => {
          if (_isMounted?.current) {
            setAreAttachmentsLoading(false);
          }
        },
      });
    }, []);

    useEffect(() => {
      getAttachmentData(id, isMounted);
    }, [getAttachmentData, id, isMounted]);

    const renderTabs = () => {
      const tabs = [
        {
          label: `Original Files (${originalFiles && originalFiles.length})`,
          key: DataJobDetailsConstants.TAB_NAMES.ORIGINAL,
        },
        {
          label: `Transformed Files (${transformedFiles && transformedFiles.length})`,
          key: DataJobDetailsConstants.TAB_NAMES.TRANSFORMED,
        },
      ];

      const doChangeTab = (key) => {
        setSelectedFiles([]);
        setActiveTab(key);
      };

      return tabs.map((tab) => (
        <div
          key={tab.key}
          onClick={() => doChangeTab(tab.key)}
          aria-hidden
          className={classNames('tab-selector', {
            'tab-selector--active': activeTab === tab.key,
          })}
        >
          {tab.label}
        </div>
      ));
    };

    const doSetSelectedFiles = (fileId) => {
      let tempFiles = _cloneDeep(selectedFiles);
      if (tempFiles.includes(fileId)) {
        tempFiles = tempFiles.filter((item) => item !== fileId);
        setSelectedFiles(tempFiles);
      } else {
        setSelectedFiles([...selectedFiles, fileId]);
      }
    };

    // -------------------------------------------- FILE DOWNLOADS -------------------------------------------- //
    const initiateDownload = useCallback(
      (fileId = null, zipUrl = false) => {
        const url = zipUrl || generateDownloadUrl(fileId) || null;
        const link = document.createElement('a');
        link.href = url;
        link.download = true;
        link.style.display = 'none';
        link.target = '_blank';
        link.rel = 'noopener noreferrer';

        // do header/option check for auth before running download on file
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.HEAD,
          urlPath: '/users/get-current',
          onSuccess: () => {
            if (isMounted.current) {
              const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
              });

              document.body.appendChild(link);
              link.dispatchEvent(event);
              document.body.removeChild(link);
              setDownloadSpinner(false);
            }
          },
          onError: () => {
            setDownloadSpinner(false);
          },
        });
      },
      [isMounted]
    );

    const prepareZipFile = (files) => {
      setDownloadSpinner(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        data: {
          selected_files: files,
        },
        urlPath: `/data-requests/attachments/batch-download`,
        onSuccess: (res) => {
          initiateDownload(null, res.data.zip_url);
        },
        onError: () => {
          setDownloadSpinner(false);
          toast.error(
            'We were unable to Zip these files. Please try downloading them individually.'
          );
        },
      });
    };

    const downloadSelected = () => {
      if (selectedFiles.length === 1) {
        const file = selectedFiles.pop();
        initiateDownload(file);
      } else {
        prepareZipFile(selectedFiles);
      }
    };

    const downloadAll = () => {
      const files = _cloneDeep(
        activeTab === DataJobDetailsConstants.TAB_NAMES.ORIGINAL ? originalFiles : transformedFiles
      ).map((file) => file.id);

      prepareZipFile(files);
    };

    // ---------------------------------------------------------------------------------------------------------- //

    const renderTabHeader = () => {
      return (
        <div className="tab-header">
          <div className="tab-header-title">
            {activeTab === DataJobDetailsConstants.TAB_NAMES.TRANSFORMED
              ? `Transformed Files (${transformedFiles && transformedFiles.length})`
              : `Original Files (${originalFiles && originalFiles.length})`}
          </div>
          {featureFlags.ENABLE_BATCH_ATTACHMENT_DOWNLOAD && (
            <div className="download-buttons">
              {downloadSpinner ? (
                <LoadingText
                  text="Zipping your Files. Please wait"
                  style={{
                    fontSize: '1em',
                    width: '16em',
                    margin: 'auto',
                    verticalAlign: 'baseline',
                  }}
                />
              ) : (
                <>
                  <button
                    type="button"
                    disabled={selectedFiles.length < 1}
                    className="download-btn"
                    onClick={() => downloadSelected()}
                  >
                    Download Selected
                  </button>
                  <button type="button" className="download-btn" onClick={() => downloadAll()}>
                    Download All
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      );
    };

    const renderTabBody = () => {
      return (
        <FilesTable
          activeTab={activeTab}
          dataJobData={dataJobData}
          setDataJobData={setDataJobData}
          dataJobCommentsData={dataJobCommentsData}
          dataJobCommentsDataStatus={dataJobCommentsDataStatus}
          transformedFiles={transformedFiles}
          originalFiles={originalFiles}
          selectedFiles={selectedFiles}
          doSetSelectedFiles={doSetSelectedFiles}
          getAttachmentData={() => getAttachmentData(id, isMounted)}
          areAttachmentsLoading={areAttachmentsLoading}
        />
      );
    };

    return (
      <div className="panel-block data-job-panel-files-block files-block-container">
        <div className="tab-selector-container">{renderTabs()}</div>
        <div className="tab-content">
          {renderTabHeader()}
          {renderTabBody()}
          {activeTab === DataJobDetailsConstants.TAB_NAMES.ORIGINAL ? (
            <DatabotsBlock djId={id} mfrId={dataJobData?.manufacturer?.id} />
          ) : null}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => _isEqual(prevProps, nextProps)
);

FilesBlock.defaultProps = {
  id: null,
  dataJobData: null,
  dataJobCommentsData: null,
  dataJobCommentsDataStatus: null,
};

FilesBlock.propTypes = {
  id: PropTypes.number,
  dataJobData: PropTypes.object,
  dataJobCommentsData: PropTypes.array,
  dataJobCommentsDataStatus: PropTypes.string,
};

export { FilesBlock };
