import React from "react";

const _component = ({ value = null, className = null, style = null }) => {
  const getAbsValue = (v) => {
    let output = null;

    if (typeof v === "number") {
      output = Math.max(Math.min(v, 99.9), 0.5);
    }

    return output;
  };

  const absValue = getAbsValue(value);

  return (
    <div className={`score-scale${className ? ` ${className}` : ""}`} style={style}>
      <div className="score-scale-bar">
        {typeof absValue === "number" && absValue >= 0 ? (
          <div className="score-scale-arrow" style={{ left: `${absValue}%` }}></div>
        ) : null}
      </div>
    </div>
  );
};

export default _component;
