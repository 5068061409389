import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { Dropdown, Form } from 'react-bootstrap';
import ReactDOM from 'react-dom';

import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { JOBTYPES } from 'constants/DataJobDetailsConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey, getRootMenuPortalTarget } from 'helpers/Utils';

import { DataJobFilterMenu } from './DataJobFilterMenu';
import { DataJobsFilterPeriodSelect } from './DataJobsFilterPeriodSelect';
import { DataJobsTabs } from './DataJobsTabs';

const FILTER_ACCEPTED = 'show_accepted';

/**
 * Filter component for Data Jobs listing.
 *
 * @param {object} param Props
 * @param {object} param.filter Current active filter
 * @param {function} param.onApplyFilter Handler to execute chosen filter
 * @param {function} param.onResetFilter Handler to execute resetting filter
 * @param {boolean} param.isAdvancedFilterVisible Boolean to indicate visibility of adbanced dropdown filter - used in testing
 * @returns Render
 */
const DataJobsFilter = ({
  activeFilterState,
  onApplyFilter,
  onResetFilter,
  isAdvancedFilterVisible,
  activeTab,
  setActiveTab,
  isLoading,
  totalSize,
  tableState,
}) => {
  const { activeUser } = RootHooks.useActiveUser();

  const isMounted = useIsMounted();
  const currentUserRole = activeUser?.role ?? null;
  const rootTarget = getRootMenuPortalTarget();
  const { featureFlags } = RootHooks.useFeatureFlags();

  const isDistributor = currentUserRole === UserRoleConstants.CLIENT;
  const isSupplier = currentUserRole === UserRoleConstants.SUPPLIER;
  const isSupplierWithChildren = activeUser?.profile?.manufacturer?.has_children ?? null;

  const isAdmin = currentUserRole === UserRoleConstants.ADMIN;
  const textSearchRef = useRef();

  const currentUserSupplierId = activeUser?.profile?.manufacturer?.id ?? null;

  const [filterState, setFilterState] = useState(_cloneDeep(activeFilterState));
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(
    isAdvancedFilterVisible || false
  );
  const [suppliersList, setSuppliersList] = useState(null);
  const [suppliersListStatus, setSuppliersListStatus] = useState(ActionStatusConstants.INITIAL);
  const [distributorsList, setDistributorsList] = useState(null);
  const [distributorsListStatus, setDistributorsListStatus] = useState(
    ActionStatusConstants.INITIAL
  );
  const [dataRequestTypeTree, setDataRequestTypesTree] = useState(null);
  const [dataRequestTypeTreeStatus, setDataRequestTypesTreeStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  const isBusy = [suppliersListStatus, distributorsListStatus, dataRequestTypeTreeStatus].includes(
    ActionStatusConstants.ISBUSY
  );

  const isDisabled = isBusy;

  useEffect(() => {
    const setOptionsStatus = (type, status) => {
      switch (type) {
        case 'supplier': {
          setSuppliersListStatus(status);
          break;
        }
        case 'distributor': {
          setDistributorsListStatus(status);
          break;
        }
        case 'request_type': {
          setDataRequestTypesTreeStatus(status);
          break;
        }
        default:
      }
    };

    const setOptionsData = (type, data) => {
      switch (type) {
        case 'supplier':
          setSuppliersList(sortByKey(data, 'name'));
          break;
        case 'supplier_with_children':
          setSuppliersList([activeUser?.profile?.manufacturer, ...sortByKey(data, 'name')]);
          break;
        case 'distributor':
          setDistributorsList(sortByKey(data, 'name'));
          break;
        case 'request_type':
          setDataRequestTypesTree(data);
          break;
        default:
          break;
      }
    };

    const decorateReqCfg = (type, reqCfg) => {
      switch (type) {
        case 'supplier':
          reqCfg.urlPath = '/manufacturers/';
          break;
        case 'supplier_with_children':
          const manufacturer_id = activeUser?.profile?.manufacturer?.id ?? null;
          reqCfg.urlPath = `/manufacturers/${manufacturer_id}/children`;
          break;
        case 'distributor':
          reqCfg.urlPath = '/distributors/';
          break;
        case 'request_type': {
          reqCfg.urlPath = '/data-request-types/';
          reqCfg.params = { mode: 'nested' };
          break;
        }
        default:
          break;
      }
    };

    const doLoadOptions = (type) => {
      setOptionsStatus(type, ActionStatusConstants.ISBUSY);

      const reqCfg = {
        method: ApiCalls.HTTP_METHODS.GET,
        onSuccess: (res) => {
          if (isMounted.current) {
            setOptionsStatus(type, ActionStatusConstants.SUCCESS);
            const data = _get(res, 'data') || null;
            setOptionsData(type, data);
          }
        },
        onError: () => {
          setOptionsStatus(type, ActionStatusConstants.FAILURE);
        },
      };

      decorateReqCfg(type, reqCfg);

      ApiCalls.doCall(reqCfg);
    };

    // TODO: role condition
    if (isAdmin || isDistributor) {
      doLoadOptions('supplier');
    }
    if (isAdmin || isSupplier) {
      doLoadOptions('distributor');
    }
    if (isSupplier && isSupplierWithChildren) {
      doLoadOptions('supplier_with_children');
    }
    doLoadOptions('request_type');
  }, [currentUserSupplierId, isAdmin, isDistributor, isSupplier, isMounted]);

  useEffect(() => {
    if (activeFilterState) {
      setFilterState(_cloneDeep(activeFilterState));
    }
  }, [activeFilterState]);

  const doApplyFilter = (filter) => {
    setIsFilterDropdownVisible(isAdvancedFilterVisible || false);
    onApplyFilter(filter);
  };

  const doResetFilter = () => {
    const newFilterState = {
      ...filterState,
      supplier: null,
      distributor: null,
      request_type: null,
      request_subtype: null,
      priority: null,
      status: null,
      viewed: 'all',
    };

    // Set the visibility here too, since the portal will stay outside of the DOM and show during refresh
    setIsFilterDropdownVisible(isAdvancedFilterVisible || false);
    onResetFilter(newFilterState);
  };

  const getRequestSubtypes = () => {
    let output = [];

    if (dataRequestTypeTree?.length && filterState?.request_type) {
      for (let i = 0; i < dataRequestTypeTree.length; i++) {
        if (
          dataRequestTypeTree[i].children &&
          dataRequestTypeTree[i].id === filterState.request_type
        ) {
          output = dataRequestTypeTree[i].children;
        }
      }
    }

    return output;
  };

  const changeFilterState = (key, val, shouldApply) => {
    // Determine if new filter state requires state changes
    const newFilterState = {
      ...filterState,
      [key]:
        [null, undefined, ''].includes(val) || (Array.isArray(val) && !val.length) ? null : val,
    };

    if (key === 'request_type' && val !== filterState.request_type) {
      newFilterState.request_subtype = null;
    }

    // Remove incompatible filter keys when going to passthrough files
    if (key === 'tab' && val === JOBTYPES.PASS_THRU_FILES) {
      delete newFilterState.priority;
      delete newFilterState.status;
      delete newFilterState.request_type;
      delete newFilterState.request_subtype;
      delete newFilterState.viewed;
    }

    setFilterState(newFilterState);

    if (shouldApply === true) {
      doApplyFilter(newFilterState);
    }
  };

  const isAdvancedFilterActive = () => {
    return !!(
      activeFilterState?.supplier?.length ||
      activeFilterState?.distributor?.length ||
      activeFilterState?.request_type !== null ||
      activeFilterState?.request_subtype?.length ||
      activeFilterState?.priority?.length ||
      activeFilterState?.status?.length ||
      activeFilterState?.viewed !== 'all'
    );
  };

  const filterByTabSelection = (selection) => {
    changeFilterState('tab', selection, true);
  };

  const tryChangeActiveTab = (selectedTab) => {
    if (selectedTab !== activeTab) {
      setActiveTab(selectedTab);
      filterByTabSelection(selectedTab);
    }
  };

  const renderSummary = () => {
    let indexStart,
      offset,
      indexEnd = 0;
    if (totalSize > 0 && tableState?.page_size) {
      offset = (tableState.page - 1) * tableState.page_size;

      indexStart = offset + 1;
      indexEnd = offset + tableState.page_size;
      if (indexEnd > totalSize) {
        indexEnd = totalSize;
      }
    }

    const summary =
      totalSize && indexStart && indexEnd ? (
        <div className="results-summary">
          {`Showing ${indexStart}-${indexEnd} of ${totalSize.toLocaleString('en-US')}`}
        </div>
      ) : null;

    return summary;
  };

  const tableSummary = renderSummary();

  return (
    !isLoading && (
      <>
        <div className="data-jobs-filter">
          <div className="filter-left-side">
            <div className="filter-block filter-block-text-search">
              <Form.Control
                type="text"
                placeholder="Search Data Jobs by Name or Description"
                value={_get(filterState, 'text_search') || ''}
                onChange={(e) => changeFilterState('text_search', e.target.value)}
                onKeyDown={(e) => e && e.key === 'Enter' && doApplyFilter(filterState)}
                ref={textSearchRef}
              />
              <button
                type="button"
                className="filter-block-text-search-btn"
                onClick={() =>
                  changeFilterState('text_search', textSearchRef?.current?.value ?? '', true)
                }
              >
                <span className="icon">
                  <FontAwesomeIcon icon={['far', 'search']} />
                </span>
              </button>
            </div>
          </div>
          <div className="filter-right-side">
            <div
              className="filter-block filter-block-show-type"
              style={{
                display: activeTab && activeTab === JOBTYPES.PASS_THRU_FILES ? 'none' : null,
              }}
            >
              <div className="show-type-input-wrap form-control">
                <div className="show-type-input-wrap-label">Show:</div>
                <div className="show-type-input show-type-input-partners">
                  <Form.Check
                    type="checkbox"
                    checked={_get(filterState, FILTER_ACCEPTED) === true}
                    readOnly
                    onClick={() =>
                      changeFilterState(FILTER_ACCEPTED, !filterState.show_accepted, true)
                    }
                  />
                  <span className="label">Accepted</span>
                </div>
                {featureFlags.ENABLE_CLOSED_FILTER && (
                  <div className="show-type-input show-type-input-completed">
                    <Form.Check
                      type="checkbox"
                      checked={_get(filterState, 'show_completed') === true}
                      readOnly
                      onClick={() =>
                        changeFilterState('show_completed', !filterState.show_completed, true)
                      }
                    />
                    <span className="label">Completed/Closed</span>
                  </div>
                )}
              </div>
            </div>
            <div className="filter-block filter-block-time-period">
              <DataJobsFilterPeriodSelect
                value={filterState?.time_period}
                onChange={(e) => {
                  changeFilterState('time_period', !Number.isNaN(e.value) ? e.value : null, true);
                }}
              />
            </div>
            {/* TODO: unite CSS from filter-toggle */}
            <div className="filter-block filter-block-advanced filter-toggle">
              <Dropdown
                show={isFilterDropdownVisible}
                onToggle={(isOpen) => {
                  setIsFilterDropdownVisible(isAdvancedFilterVisible || isOpen);
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <>
                    {isAdvancedFilterActive() && (
                      <span className="icon icon--advanced-filter">
                        <FontAwesomeIcon icon={['fas', 'circle']} />
                      </span>
                    )}
                    <span className="label">Filter By</span>
                  </>
                </Dropdown.Toggle>
                {ReactDOM.createPortal(
                  <Dropdown.Menu
                    bsPrefix="djl-filter-dropdown-menu dropdown-menu"
                    popperConfig={{
                      modifiers: [
                        {
                          name: 'flip',
                          options: {
                            rootBoundary: rootTarget,
                          },
                        },
                      ],
                    }}
                  >
                    <DataJobFilterMenu
                      isAdmin={isAdmin}
                      isDistributor={isDistributor}
                      isSupplier={isSupplier}
                      isSupplierWithChildren={isSupplierWithChildren}
                      suppliersList={suppliersList}
                      distributorsList={distributorsList}
                      filterState={filterState}
                      dataRequestTypeTree={dataRequestTypeTree}
                      activeTab={activeTab}
                      isDisabled={isDisabled}
                      changeFilterState={changeFilterState}
                      doResetFilter={doResetFilter}
                      doApplyFilter={doApplyFilter}
                      getRequestSubtypes={getRequestSubtypes}
                    />
                  </Dropdown.Menu>,
                  document.body
                )}
              </Dropdown>
            </div>
          </div>
        </div>
        {tableSummary}
        <DataJobsTabs
          isAdmin={isAdmin}
          activeTab={activeTab}
          tryChangeActiveTab={(v) => tryChangeActiveTab(v)}
        />
      </>
    )
  );
};

export { DataJobsFilter };
