import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { statusValueConstants, statusValuesEnum } from 'constants/DataJobDetailsConstants';
import styleVars from 'scss/vars.scss';


import { ProgressBar } from './ProgressBar/ProgressBar';

// TODO: TBD this! Statuses should come from one place?
const statusValueMap = [
  {
    value: statusValueConstants.NEW,
    statuses: [statusValueConstants.NEW],
    label: statusValuesEnum[statusValueConstants.NEW],
  },
  {
    value: statusValueConstants.IN_PROCESS,
    statuses: [statusValueConstants.IN_PROCESS],
    label: statusValuesEnum[statusValueConstants.IN_PROCESS],
  },
  {
    value: statusValueConstants.READY_FOR_APPROVAL,
    statuses: [statusValueConstants.READY_FOR_APPROVAL],
    label: statusValuesEnum[statusValueConstants.READY_FOR_APPROVAL],
  },
  {
    value: statusValueConstants.COMPLETE,
    statuses: [statusValueConstants.COMPLETE, statusValueConstants.CANCELED],
    label: statusValuesEnum[statusValueConstants.COMPLETE],
  },
  {
    value: statusValueConstants.CLOSED,
    statuses: [statusValueConstants.CLOSED],
    label: statusValuesEnum[statusValueConstants.CLOSED],
  },
  {
    value: statusValueConstants.REJECTED,
    statuses: [statusValueConstants.REJECTED],
    label: statusValuesEnum[statusValueConstants.REJECTED],
  },
];


const getProgressData = (status) => {
  const progressData = [];
  if (status === statusValueConstants.CANCELED) {
    progressData.push({
      label: statusValuesEnum[statusValueConstants.CANCELED],
      isActive: true,
      icon: (
        <div className="icon">
          <FontAwesomeIcon icon={['fas', 'lock']} />
        </div>
      ),
      className: `status-${status}`,
    });
  } else if (status === statusValueConstants.REJECTED) {
    progressData.push({
      label: statusValuesEnum[statusValueConstants.REJECTED],
      isActive: true,
      icon: (
        <div className="icon">
          <FontAwesomeIcon icon={['far', 'times']} />
        </div>
      ),
      className: `status-${status}`,
    });
  } else {
    if (status) {
      let activeIndex = null;
      for (let i = 0; i < statusValueMap.length; i++) {
        const dataItem = {
          label: statusValueMap[i].label,
          className: `status-${status}`,
          isActive: false,
          icon: null,
        };

        if (statusValueMap[i].statuses && statusValueMap[i].statuses.includes(status)) {
          activeIndex = i;
          dataItem.isActive = true;
          dataItem.icon = (
            <div className="icon">
              <FontAwesomeIcon icon={['fas', 'caret-right']} />
            </div>
          );
        } else {
          if (activeIndex === null) {
            dataItem.isDone = true;
            dataItem.icon = (
              // fontSize is slightly different to prevent squeezing of circular icon
              <div className="icon" style={{ fontSize: '0.9em', color: `${styleVars.colors_designGreen}` }}>
                <FontAwesomeIcon icon={['fas', 'check-circle']} />
              </div>
            );
          } else {
            dataItem.icon = (
              <div className="icon" style={{ fontSize: '0.7em' }}>
                <FontAwesomeIcon icon={['fas', 'lock']} />
              </div>
            );
          }
        }
        progressData.push(dataItem);
      }
    }
  }

  return progressData;
};

const StatusProgressBlock = ({ dataJobData }) => {
  return (
    <div className="panel-block data-job-panel-status-progress-block">
      <div className="progress-steps">
        <ProgressBar data={getProgressData(dataJobData?.status)} />
      </div>
    </div>
  );
};

export { StatusProgressBlock };
