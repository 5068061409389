import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ImgWithPlaceholder from 'components/ImgWithPlaceholder';

/**
 * Renderer for a Header Notification Item.
 *
 * @param {element} as Render as
 * @param {boolean} isViewed Viewed flag
 * @param {string} url Target URL
 * @param {string} image Image
 * @param {string} createdAt Created date
 * @param {function} - props.onClick - Click handler
 * @callback render
 */
const HeaderNotificationItem = ({
  as = <li />,
  isViewed,
  url,
  image,
  message,
  createdAt,
  onClick,
}) => {
  return (
    <as.type
      className={classNames('header-notification-item', {
        'is-new-item': !isViewed,
      })}
      onClick={() => typeof onClick === 'function' && onClick(url)}
    >
      <div className="left-wrap">
        <div className="notif-image-wrapper">
          <ImgWithPlaceholder
            className="notif-image"
            src={image}
            placeholder={
              <FontAwesomeIcon className="notif-image placeholder" icon={['far', 'times']} />
            }
          />
        </div>
      </div>
      <div className="center-wrap">
        <div className="message">{message}</div>
      </div>
      <div className="right-wrap">
        <div className="date">{createdAt}</div>
        <span className="icon">
          <FontAwesomeIcon icon={['far', 'chevron-right']} />
        </span>
      </div>
    </as.type>
  );
};

HeaderNotificationItem.propTypes = {
  as: PropTypes.element,
  isViewed: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { HeaderNotificationItem };
