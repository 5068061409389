import './WeeklyCompanyCard.scss';

import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MetricsTable } from 'components/metrics/MetricsTable/MetricsTable';
import { METRIC_LABELS } from 'constants/MetricsConstants';
import { getTotalMetricsByPeriods } from 'helpers/MetricsUtils';
import { WeeklyCompanyTableDef } from 'helpers/table-defs/ViewMetricsTableDef';

const WeeklyCompanyCard = ({ handleViewDashboard, expand, data, startDate, endDate, sortDir }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [weeklyCompanyColumns, setWeeklyCompanyColumns] = useState(
    WeeklyCompanyTableDef({ totals: [], startDate, endDate })
  );

  useEffect(() => {
    setWeeklyCompanyColumns(
      WeeklyCompanyTableDef({ totals: getTotalMetricsByPeriods(data.metrics), startDate, endDate })
    );
  }, [data, startDate, endDate]);

  useEffect(() => {
    if (expand) setIsCollapsed(false);
  }, [expand]);

  const renderCollapseControl = () => {
    return (
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="weekly-company-card-toggle"
        type="button"
      >
        {isCollapsed ? 'Expand' : 'Collapse'}
        <FontAwesomeIcon
          icon={['fas', isCollapsed ? 'chevron-double-down' : 'chevron-double-up']}
        />
      </button>
    );
  };

  return (
    <div className="weekly-company-card">
      <div className="weekly-company-card-header">
        <div className="weekly-company-card-header-group">
          <h3 className="weekly-company-card-header-title">{data.name}</h3>
          <button
            onClick={() => handleViewDashboard(data.type, data.id)}
            className="weekly-company-card-btn"
            type="button"
          >
            View Dashboard
          </button>
        </div>
        {renderCollapseControl()}
      </div>
      <div hidden={isCollapsed} className="weekly-company-card-table">
        <MetricsTable
          keyField="metric"
          defaultSorted={sortDir && [{ dataField: 'metric', order: sortDir }]}
          metrics={Object.keys(data.metrics).map((key) => {
            return {
              metric: METRIC_LABELS[key],
              metrics: data.metrics[key],
            };
          })}
          showViewAll={false}
          columns={weeklyCompanyColumns}
        />
      </div>
    </div>
  );
};

export { WeeklyCompanyCard };
