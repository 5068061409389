import React, { useMemo, useCallback, useState, useRef } from 'react';

import './FilesTables.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingLogoSpinner } from 'components/common/LoadingSpinner/LoadingLogoSpinner';
import { FILESTATUS } from 'constants/DataIngestionConstants';
import { TAB_NAMES } from 'constants/DataJobDetailsConstants';
import { generateDownloadUrl } from 'helpers/AttachmentUtils';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { useOutsideClickWithScroll } from 'helpers/useOutsideClick';
import { isRoleAdmin, UserUtils } from 'helpers/UserUtils';
import { getRootMenuPortalTarget } from 'helpers/Utils';

// TODO: This component should be refactored/optimized in a future iteration
/**
 *
 * @param {string} activeTab
 * @param {Object} file
 * @param {bool} isEditMode
 * @param {bool} isDisabled
 * @param {function} switchAssessment
 * @param {function} startAssessment
 * @param {function} setShowDeleteModal
 * @param {function} setShowDeleteAssessmentModal,
 * @param {function} switchActions
 * @returns Render
 */

const RowActions = ({
  activeTab,
  file,
  isEditMode,
  isDisabled,
  switchAssessment,
  startAssessment,
  setShowDeleteModal,
  setShowDeleteAssessmentModal,
  switchActions,
  editable,
}) => {
  const rootTarget = getRootMenuPortalTarget();
  const history = useHistory();
  const { featureFlags } = RootHooks.useFeatureFlags();
  const { activeUser } = RootHooks.useActiveUser();
  const [isOnboardingProcessing, setIsOnboardingProcessing] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const isMounted = useIsMounted();

  // -------------------------------------------------------- Deletions -------------------------------------------------------- //
  const canDelete =
    !UserUtils.isReadOnly(activeUser) &&
    ((activeUser?.id === file?.user.id && !isDisabled) ||
      (editable && !isDisabled) ||
      isRoleAdmin(activeUser));
  const deleteText = `Delete${file.file_type !== 'external-link' ? ' Attachment' : ''}`;
  const ref = useRef();

  const deleteAttachment = useCallback(() => {
    setShowDeleteModal(true);
  }, [setShowDeleteModal]);

  const deleteAssessment = useCallback(() => {
    setShowDeleteAssessmentModal(true);
  }, [setShowDeleteAssessmentModal]);

  // --------------------------------------------------------------------------------------------------------------------------- //
  // -------------------------------------------------------- Downloads -------------------------------------------------------- //
  const downloadFile = useCallback(() => {
    const downloadUrl = generateDownloadUrl(file.id) || null;
    if (!downloadFile) {
      return;
    }
    // do header/option check for auth before running download on file
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.HEAD,
      urlPath: '/users/get-current',
      onSuccess: () => {
        if (isMounted.current) {
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.target = '_blank';
          a.rel = 'noopener noreferrer';
          switchActions();
          a.click();
        }
      },
    });
  }, [isMounted, file.id, switchActions]);

  useOutsideClickWithScroll(ref, () => {
    if (isDropdownVisible) {
      setIsDropdownVisible(false);
    }
    switchActions();
  });

  // --------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------- Onboarding ------------------------------------------------------- //
  const startDataOnboarding = useCallback(() => {
    if (file.flash_assessment_id && file.is_flash_assessment_manual) {
      switchAssessment(true);
      return;
    }

    setIsOnboardingProcessing(true);
    if (file?.id) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/data-requests/attachments/${file.id}/ml-definitions`,
        onSuccess: () => {
          toast.success('Data Onboarding started successfully.');
        },
      });
    }
  }, [file.flash_assessment_id, file.is_flash_assessment_manual, file.id, switchAssessment]);

  const resumeDataOnboarding = useCallback(() => {
    if (file?.id) {
      let url = null;
      if (file.status.id === FILESTATUS.PROCESSING_REQUESTED.id) {
        // Processing Requested
        url = `/data-ingestion/rows/${file.id}`;
      } else if (file.status.id === FILESTATUS.PENDING.id) {
        // Processing
        if (file.sheet_status === 'rows') {
          url = `/data-ingestion/rows/${file.id}`;
        } else if (file.sheet_status === 'columns') {
          url = `/data-ingestion/columns/${file.id}`;
        } else if (file.sheet_status === 'errors_and_warnings') {
          url = `/data-ingestion/data-quality/${file.id}`;
        }
      }
      if (url) {
        history.push(url);
      }
    }
  }, [file, history]);

  const canStartDataOnboarding =
    !UserUtils.isReadOnly(activeUser) &&
    featureFlags?.ENABLE_DATA_ONBOARDING &&
    isRoleAdmin(activeUser) &&
    (featureFlags.FA_DATA_ONBOARD_FOR_ALL || file?.transformation_required === 1) &&
    // file has not been processed
    file.sheet_status === null &&
    file.status.id !== FILESTATUS.PROCESSING.id &&
    // currently confirmed to work file types by ML team
    ['xlsx', 'xls', 'otd', 'xlsm'].includes(file.file_type) &&
    file.is_flash_assessment_manual !== false;

  const onBoardingInProcess =
    isOnboardingProcessing ||
    (isRoleAdmin(activeUser) &&
      file.sheet_status === null &&
      file.status.id === FILESTATUS.PROCESSING.id);

  const canResumeDataOnboarding =
    UserUtils.isReadOnly(activeUser) &&
    featureFlags?.ENABLE_DATA_ONBOARDING &&
    isRoleAdmin(activeUser) &&
    file.file_type !== 'external-link' &&
    file.sheet_status !== null &&
    [FILESTATUS.PENDING.id, FILESTATUS.PROCESSING_REQUESTED.id].includes(file.status.id); // Processing, Processing Requested, Ingestion Requested

  const onboardingOption = useCallback(() => {
    if (canResumeDataOnboarding) {
      return (
        <Dropdown.Item
          key="resumeDataOnboarding"
          className="attachments-table-row__action"
          onClick={resumeDataOnboarding}
        >
          <FontAwesomeIcon className="attachments-table-row__action-icon" icon={['fas', 'play']} />
          <span className="ml-3">Continue Data Onboarding</span>
        </Dropdown.Item>
      );
    }

    if (onBoardingInProcess) {
      return (
        <Dropdown.Item key="dataOnboardingInProgress" className="attachments-table-row__action">
          <span className="attachments-table-row__action-icon">
            <LoadingLogoSpinner
              style={{
                width: '1rem',
                height: '1rem',
                display: 'inline-flex',
                margin: 'none',
                transform: 'none',
              }}
            />
          </span>
          <span className="ml-3">Onboarding In Progress</span>
        </Dropdown.Item>
      );
    }

    if (canStartDataOnboarding && !file.flash_assessment_id) {
      return (
        <Dropdown.Item
          key="startDataOnboarding"
          className="attachments-table-row__action"
          onClick={startDataOnboarding}
        >
          <FontAwesomeIcon className="attachments-table-row__action-icon" icon={['far', 'plus']} />
          <span className="ml-3">Start Data Onboarding</span>
        </Dropdown.Item>
      );
    }
    return null;
  }, [
    canResumeDataOnboarding,
    onBoardingInProcess,
    resumeDataOnboarding,
    startDataOnboarding,
    canStartDataOnboarding,
    file.flash_assessment_id,
  ]);

  const manualAssessmentOption = useCallback(() => {
    // TRANSFORMED FILES TAB
    if (activeTab === TAB_NAMES.TRANSFORMED && file?.id) {
      if (
        file.file_type === 'external-link' ||
        !isRoleAdmin(activeUser) ||
        UserUtils.isReadOnly(activeUser)
      )
        return null;
      if (!file.flash_assessment_id) {
        return (
          <Dropdown.Item
            key="flashTransform"
            className="attachments-table-row__action"
            onClick={startAssessment}
          >
            <FontAwesomeIcon
              className="attachments-table-row__action-icon"
              icon={['far', 'plus']}
            />
            <span className="ml-3">Create Manual Assessment</span>
          </Dropdown.Item>
        );
      }
      if (file.flash_assessment_id && file.is_flash_assessment_manual) {
        return (
          <Dropdown.Item
            key="flashTransform"
            className="attachments-table-row__action"
            onClick={startAssessment}
          >
            <FontAwesomeIcon
              className="attachments-table-row__action-icon"
              icon={['far', 'edit']}
            />
            <span className="ml-3">Edit Manual Assessment</span>
          </Dropdown.Item>
        );
      }
    } else if (activeTab === TAB_NAMES.ORIGINAL && file?.id) {
      // ORIGINAL FILES TAB
      if (file.file_type === 'external-link' || UserUtils.isReadOnly(activeUser)) return null;
      if (
        isRoleAdmin(activeUser) &&
        !file.flash_assessment_id &&
        !onBoardingInProcess &&
        !canResumeDataOnboarding
      ) {
        return (
          <Dropdown.Item
            key="flashTransform"
            className="attachments-table-row__action"
            onClick={startAssessment}
          >
            <FontAwesomeIcon
              className="attachments-table-row__action-icon"
              icon={['far', 'plus']}
            />
            <span className="ml-3">Create Manual Assessment</span>
          </Dropdown.Item>
        );
      }
      if (
        isRoleAdmin(activeUser) &&
        file.flash_assessment_id &&
        file.is_flash_assessment_manual &&
        !onBoardingInProcess
      ) {
        return (
          <Dropdown.Item
            key="flashTransform"
            className="attachments-table-row__action"
            onClick={startAssessment}
          >
            <FontAwesomeIcon
              className="attachments-table-row__action-icon"
              icon={['far', 'edit']}
            />
            <span className="ml-3">Edit Assessment</span>
          </Dropdown.Item>
        );
      }
    }
    return null;
  }, [
    activeTab,
    file.id,
    file.file_type,
    file.flash_assessment_id,
    file.is_flash_assessment_manual,
    startAssessment,
    activeUser,
    canResumeDataOnboarding,
    onBoardingInProcess,
  ]);

  const ActionDropdownChildren = useMemo(
    () =>
      activeTab === TAB_NAMES.ORIGINAL
        ? // ORIGINAL FILES TAB
          [
            onboardingOption(),
            manualAssessmentOption(),
            file.flash_assessment_id &&
            file.file_type !== 'external-link' &&
            isRoleAdmin(activeUser) &&
            !UserUtils.isReadOnly(activeUser) ? (
              <Dropdown.Item
                key="flashDelete"
                className="attachments-table-row__action"
                onClick={deleteAssessment}
              >
                <FontAwesomeIcon
                  className="attachments-table-row__action-icon"
                  icon={['far', 'trash-alt']}
                />
                <span className="ml-3">Delete Assessment</span>
              </Dropdown.Item>
            ) : null,
            file.file_type !== 'external-link' ? (
              <Dropdown.Item
                key="downloadItemTransform"
                onClick={downloadFile}
                className="attachments-table-row__action"
              >
                <FontAwesomeIcon
                  className="attachments-table-row__action-icon"
                  icon={['far', 'file-download']}
                />
                <span className="ml-3">Download</span>
              </Dropdown.Item>
            ) : null,
            canDelete ? (
              <Dropdown.Item
                key="deleteAttachmentTransform"
                onClick={deleteAttachment}
                className="attachments-table-row__action"
              >
                <FontAwesomeIcon
                  className="attachments-table-row__action-icon"
                  icon={['far', 'trash-alt']}
                />
                <span className="ml-3">{deleteText}</span>
              </Dropdown.Item>
            ) : null,
          ].filter((v) => v !== null)
        : [
            manualAssessmentOption(),
            file.flash_assessment_id &&
            file.file_type !== 'external-link' &&
            isRoleAdmin(activeUser) &&
            !UserUtils.isReadOnly(activeUser) ? (
              <Dropdown.Item
                key="flashDelete"
                className="attachments-table-row__action"
                onClick={deleteAssessment}
              >
                <FontAwesomeIcon
                  className="attachments-table-row__action-icon"
                  icon={['far', 'trash-alt']}
                />
                <span className="ml-3">Delete Assessment</span>
              </Dropdown.Item>
            ) : null,
            <Dropdown.Item
              key="download"
              onClick={downloadFile}
              className="attachments-table-row__action"
            >
              <FontAwesomeIcon
                className="attachments-table-row__action-icon"
                icon={['far', 'file-download']}
              />
              <span className="ml-3">Download</span>
            </Dropdown.Item>,
            !UserUtils.isReadOnly(activeUser) &&
            (isRoleAdmin(activeUser) || (activeUser?.id === file?.user.id && !isDisabled)) ? (
              <Dropdown.Item
                key="trash"
                onClick={deleteAttachment}
                className="attachments-table-row__action"
              >
                <FontAwesomeIcon
                  className="attachments-table-row__action-icon"
                  icon={['far', 'trash-alt']}
                />
                <span className="ml-3">Delete</span>
              </Dropdown.Item>
            ) : null,
          ],
    [
      downloadFile,
      file.user.id,
      isDisabled,
      activeUser,
      manualAssessmentOption,
      file.file_type,
      file.flash_assessment_id,
      activeTab,
      canDelete,
      deleteAssessment,
      deleteAttachment,
      deleteText,
      onboardingOption,
    ]
  );

  return (
    <div
      className="files-table actions-column actions-dropdown"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {ActionDropdownChildren.length > 0 && isEditMode && (
        <Dropdown
          show={isDropdownVisible}
          onToggle={(isOpen) => {
            setIsDropdownVisible(isOpen);
          }}
        >
          <Dropdown.Toggle
            id="actions_column_dropdown_button"
            className="actions-dropdown dropdown-toggle"
            as="div"
          >
            {/* VARIABLE DROPDOWN ICON */}
            {isOnboardingProcessing || onBoardingInProcess ? (
              <span key="action-button" className="actions-button">
                <LoadingLogoSpinner className="small-logo-spinner" />
              </span>
            ) : (
              <FontAwesomeIcon
                style={{ verticalAlign: 'middle', height: '100%' }}
                icon={['far', 'ellipsis-h']}
              />
            )}
          </Dropdown.Toggle>
          {ReactDOM.createPortal(
            <Dropdown.Menu
              ref={ref}
              popperConfig={{
                modifiers: [
                  {
                    name: 'flip',
                    options: {
                      rootBoundary: rootTarget,
                    },
                  },
                ],
              }}
            >
              {ActionDropdownChildren}
            </Dropdown.Menu>,
            document.body
          )}
        </Dropdown>
      )}
    </div>
  );
};

RowActions.defaultProps = {
  activeTab: null,
  file: null,
  isEditMode: true,
  isDisabled: false,
  switchAssessment: () => {},
  startAssessment: () => {},
  setShowDeleteModal: () => {},
  setShowDeleteAssessmentModal: () => {},
  switchActions: () => {},
};

RowActions.propTypes = {
  activeTab: PropTypes.string,
  file: PropTypes.object,
  isEditMode: PropTypes.bool,
  isDisabled: PropTypes.bool,
  switchAssessment: PropTypes.func,
  startAssessment: PropTypes.func,
  setShowDeleteModal: PropTypes.func,
  setShowDeleteAssessmentModal: PropTypes.func,
  switchActions: PropTypes.func,
};

export { RowActions };
