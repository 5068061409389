import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { CompanyMetricsCard } from 'components/metrics/CompanyMetricsCard/CompanyMetricsCard';
import { CompanyOverview } from 'components/metrics/CompanyOverview/CompanyOverview';
import { MetricsTable } from 'components/metrics/MetricsTable/MetricsTable';
import {
  IndividualManufacturersTableDef,
  UsersTableDef,
} from 'helpers/table-defs/ViewMetricsTableDef';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

const ViewClientMetrics = () => {
  const [overviewMetrics, setOverviewMetrics] = useState({});
  const [userMetrics, setUserMetrics] = useState([]);
  const [mfrMetrics, setMfrMetrics] = useState([]);
  const [dataJobMetrics, setDataJobMetrics] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState('Dashboard');

  const { id } = useParams();
  const history = useHistory();
  const isMounted = useIsMounted();

  useEffect(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/metrics/distributor/${id}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          const { overview, users, manufacturers, data_jobs: dataJobs } = res.data;
          setPageTitle(`${overview.name} Dashboard`);
          setOverviewMetrics(overview);
          setUserMetrics(sortByKey(users, 'last_name'));
          setMfrMetrics({
            total: manufacturers.total,
            manufacturers: sortByKey(manufacturers.manufacturers, 'name'),
          });
          setDataJobMetrics(dataJobs);
        }
      },
      onEnd: () => {
        setIsLoading(false);
      },
    });
  }, [isMounted, id]);

  const handleMfrRowSelected = (row) => {
    history.push(`/metrics/manufacturer/${row.backbone_id}`);
  };

  const renderHelmet = () => (
    <Helmet bodyAttributes={{ 'data-page': 'view-client-metrics' }}>
      <title>{pageTitle}</title>
    </Helmet>
  );

  return isLoading ? (
    <>
      <div className="content content-fluid view-client-metrics">{renderHelmet()}</div>
      <LoadingView />
    </>
  ) : (
    <div className="content content-fluid view-client-metrics">
      {renderHelmet()}
      <CompanyOverview showSiteUrl={false} showViewDataJobs overview={overviewMetrics}>
        <CompanyMetricsCard
          header="Total Data Jobs"
          total={dataJobMetrics.total}
          metrics={[
            {
              label: 'Drafted',
              total: dataJobMetrics.draft,
            },
            {
              label: 'Requested',
              total: dataJobMetrics.requested,
            },
            {
              label: 'Recipient',
              total: dataJobMetrics.recipient,
            },
            {
              label: 'Deleted',
              total: dataJobMetrics.deleted,
            },
          ]}
        />
        <CompanyMetricsCard
          header="Total Files"
          total={dataJobMetrics.total_files}
          metrics={[
            {
              label: 'From Manufacturer',
              total: dataJobMetrics.files_from_manufacturer,
            },
            {
              label: 'From Distributor',
              total: dataJobMetrics.files_from_distributor,
            },
            {
              label: 'From BackboneAI',
              total: dataJobMetrics.files_from_backbone,
            },
          ]}
        />
        <CompanyMetricsCard
          header="Total Comments"
          total={dataJobMetrics.total_comments}
          metrics={[
            {
              label: 'From Manufacturer',
              total: dataJobMetrics.manufacturer_comments,
            },
            {
              label: 'From Distributor',
              total: dataJobMetrics.distributor_comments,
            },
            {
              label: 'From BackboneAI',
              total: dataJobMetrics.backbone_comments,
            },
          ]}
        />
      </CompanyOverview>
      <MetricsTable
        header="User Overview"
        usePagination
        metrics={userMetrics}
        keyField="id"
        columns={UsersTableDef}
      />
      <MetricsTable
        header="Manufacturer Overview"
        handleRowSelected={handleMfrRowSelected}
        usePagination
        metrics={mfrMetrics.manufacturers ?? []}
        columns={IndividualManufacturersTableDef}
      />
    </div>
  );
};

export { ViewClientMetrics };
