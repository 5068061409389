import React from 'react';

import './CardsGrid.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Card } from './Card';

/**
 * Cards grid. Renders a padded grid of cards
 * // TODO: Needs improvement for responsiveness and arbitrary width
 *
 * @param {Object} props the prop object
 * @param {Array} props.data Array of data objects containing onclick handlers and data objects for cards
 */
const CardsGrid = React.memo(({ data }) => {
  const renderCardsList = () => {
    const output = data?.length
      ? data.map((item, i) => (
          <Card
            key={i}
            as={<li />}
            data={item.data}
            onClick={typeof item.onClick === 'function' ? item.onClick : null}
          >
            {item.children}
          </Card>
        ))
      : null;
    return output;
  };

  return (
    <ul className={classNames('bai-cards-grid', { empty: !data?.length })}>{renderCardsList()}</ul>
  );
});

CardsGrid.defaultProps = {
  data: null,
};

CardsGrid.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      children: PropTypes.node,
      data: PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.node]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      }),
    })
  ),
};

export { CardsGrid };
