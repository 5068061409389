import React from 'react';

import { formatDateStamp } from 'helpers/TimeUtils';
import { downloadFile, getDefaultTableColumnDef } from 'helpers/Utils';

export const ReportsTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('file_name', 'File Name'),
      formatter: (_cell, row) => (
        <div className="reports-table-file-details">
          <a
            href={row.file_download_url}
            target="_blank"
            rel="noreferrer noopener"
            className="reports-table-file-details__name"
            download={row.file_name}
          >
            {row.file_name}
          </a>
        </div>
      ),
    },
    {
      ...getDefaultTableColumnDef('created_by', 'Created By'),
      formatter: (_cell, row) => (
        <a href={`mailto:${row.created_by}`} rel="noopener noreferrer" target="_blank">
          {row.created_by}
        </a>
      ),
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('queued_at', 'Date Created'),
      formatter: (_cell, row) => {
        return (
          <div className="reports-table-uploaded-at">
            <span className="reports-table-uploaded-at__date">
              {row.queued_at ? formatDateStamp(row.queued_at) : 'N/A'}
            </span>
          </div>
        );
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      ...getDefaultTableColumnDef('actions', 'File Download'),
      formatter: (_cell, row) => (
        <button
          className="reports-table-download-file"
          onClick={() => downloadFile(row.file_download_url, row.file_name)}
          type="button"
        >
          Download File
        </button>
      ),
      sort: false,
    },
  ],
};
