import './LoadingSpinnerForTable.scss';
import React from 'react';

import { LoadingLogoSpinner } from 'components/common/LoadingSpinner/LoadingLogoSpinner';

const LoadingSpinnerForTable = () => {
  return (
    <div className="table-spinner-loading-spinner">
      <LoadingLogoSpinner />
    </div>
  );
};
export { LoadingSpinnerForTable };
