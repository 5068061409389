import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import React from "react";

const UnsavedChangesModal = ({ show = false, handleClose, viewReport }) => {
  return (
    <div>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>You have unsaved changes that will be lost</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel (Keep editing)
          </Button>
          <Button variant="warning" onClick={viewReport}>
            Abandon Changes and Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

UnsavedChangesModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  viewReport: PropTypes.func,
};

export default UnsavedChangesModal;
