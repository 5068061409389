import React, { useEffect, useRef } from "react";

import { Prompt } from "react-router-dom";
import { PropTypes } from "prop-types";

const UnmountPrompt = ({ active }) => {
  const beforeunloadEventListener = useRef(null);

  useEffect(() => {
    if (!!active) {
      if (beforeunloadEventListener.current === null) {
        beforeunloadEventListener.current = (e) => {
          const confirmationMessage =
            "Do you want to leave this page? Changes you made may not be saved.";

          (e || window.event).returnValue = confirmationMessage; // Gecko + IE
          return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
        };
        window.addEventListener("beforeunload", beforeunloadEventListener.current);
      }
    } else {
      if (beforeunloadEventListener.current !== null) {
        window.removeEventListener("beforeunload", beforeunloadEventListener.current);
        beforeunloadEventListener.current = null;
      }
    }
  }, [active]);

  useEffect(() => {
    const _beforeunloadEventListener = beforeunloadEventListener.current;
    return () => {
      if (_beforeunloadEventListener) {
        window.removeEventListener("beforeunload", _beforeunloadEventListener);
        beforeunloadEventListener.current = null;
      }
    };
  }, []);

  return (
    <Prompt
      when={!!active}
      message="Do you want to leave this page? Changes you made may not be saved."
    />
  );
};

UnmountPrompt.propTypes = {
  active: PropTypes.any,
};

export { UnmountPrompt };
