import "./Taxonomy.scss";
import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

// TODO: Add support for non-linear taxonomy objects (i.e. nested objects w/in array, not just an array) and adding nodes
const Taxonomy = ({ taxonomy }) => {
  const [collapsedStates, setCollapsedStates] = useState({});

  useEffect(() => {
    if (!taxonomy || !taxonomy.length) return;

    const collapsedItems = {};
    if (Array.isArray(taxonomy)) {
      taxonomy.forEach((item) => {
        collapsedItems[item] = false;
      });
      setCollapsedStates(collapsedItems);
    } else collapsedItems[taxonomy] = false;
  }, [taxonomy]);

  const toggleCollapse = (item) => {
    const collapsedState = Object.assign({}, collapsedStates);
    collapsedState[item] = !collapsedState[item];
    setCollapsedStates(collapsedState);
  };

  const renderTaxonomyItem = (data, parent = false) => {
    const item = Array.isArray(data) ? data[0] : data;
    const caretIcon = collapsedStates[item] ? "caret-right" : "caret-down";

    return (
      <div
        onClick={() => toggleCollapse(item)}
        className={classNames("taxonomy-item", {
          "taxonomy-item-parent": parent,
          "taxonomy-item--active": !collapsedStates[item],
        })}
      >
        <FontAwesomeIcon
          fixedWidth
          className={classNames("taxonomy-item-icon", {
            "taxonomy-item-icon--active": !collapsedStates[item],
          })}
          icon={["fas", caretIcon]}
        />
        <div className="taxonomy-item-label">{item}</div>
      </div>
    );
  };

  const renderTaxonomy = (data) => {
    const slicedData = data.slice();
    slicedData.shift();

    if (slicedData.length)
      return (
        <div className="taxonomy-item-child">
          {renderTaxonomyItem(slicedData, false)}
          {slicedData.length && !collapsedStates[slicedData[0]] && renderTaxonomy(slicedData)}
        </div>
      );
  };

  return (
    <div className="taxonomy">
      {taxonomy && taxonomy.length ? (
        <>
          {/* Parent node */}
          {renderTaxonomyItem(taxonomy, true)}
          {/* Child nodes */}
          {Array.isArray(taxonomy) && !collapsedStates[taxonomy[0]] && renderTaxonomy(taxonomy)}
        </>
      ) : (
        <p>No taxonomy available.</p>
      )}
    </div>
  );
};

export { Taxonomy };
