import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { BOT_STATUSES } from 'constants/BotConstants';

import './ShortDescriptionCreatorBot.scss';

/**
 * Short Description Creator databot config panel
 *
 * This is an example of using the default DatabotConfigPanel template,
 * providing a custom body and confirm dialog content.

 * @param {string} botStatusId Databot status (run) ID
 * @param {string} slug Databot slug
 * @param {string} status Databot run status
 * @param {bool} requiresApproval Whether or not the databot requires approval for changes
 * @param {bool} hasDownloadedReport Whether or not the active user has downloaded the changes report
 * @param {bool} reportName The report's file name
 * @param {function} handleRunBot Event handler
 * @param {function} handleCancelBot Event handler
 * @param {object} additionalData Databot additional data
 * @param {function} handleApproveChanges Event handler for product auxiliary process
 * @param {function} handleRejectChanges Event handler for product auxiliary process
 * @param {object} configData Databot config object
 * @param {object} setConfigData Databot config object setter
 * @param {object} botData Complete bot data
 * @return render
 */
const ShortDescriptionCreatorBot = ({
  botStatusId,
  slug,
  status,
  hasDownloadedReport,
  reportName,
  handleRunBot,
  handleCancelBot,
  additionalData,
  handleApproveChanges,
  handleRejectChanges,
  setConfigData,
  botData, // TODO: Should clean-up code to use full objects
}) => {
  const {
    name: mfrName = null,
    total_products: totalProducts = null,
    with_description: prodDescrCount = null,
    will_create: prodDescrToAdd = null,
    will_update: prodDescrToUpdate = null,
    without_description: prodNoDescrCount = null,
  } = additionalData ?? {};
  const [charLimit, setCharLimit] = useState(40);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleCharLimitChanged = (e) => {
    const { value } = e.target;

    if (value < 15 || value > 150) {
      setIsInvalid(true);
    } else setIsInvalid(false);

    setCharLimit(e.target.value);
  };

  // Renders databot data to show in template body
  const renderDetailsDataList = () => {
    const dataItems = [];

    if (Number.isFinite(prodDescrCount)) {
      const singular = prodDescrCount === 1;
      dataItems.push({
        value: prodDescrCount,
        label: `Product${singular ? '' : 's'} found with Description`,
      });
    }
    if (Number.isFinite(prodDescrToAdd)) {
      const singular = prodDescrToAdd === 1;
      dataItems.push({
        value: prodDescrToAdd,
        label: `Product${
          singular ? '' : 's'
        } will have a new Short Description Attribute field created`,
      });
    }
    if (Number.isFinite(prodDescrToUpdate)) {
      const singular = prodDescrToUpdate === 1;
      dataItems.push({
        value: prodDescrToUpdate,
        label: `Product${singular ? '' : 's'} will have ${
          singular ? 'its' : 'their'
        } Short Description Attribute cleaned up`,
      });
    }
    if (Number.isFinite(prodNoDescrCount)) {
      const singular = prodNoDescrCount === 1;
      dataItems.push({
        value: prodNoDescrCount,
        label: `Product${singular ? '' : 's'} did not provide a Description`,
      });
    }

    return dataItems.length ? (
      <>
        <div className="char-limit">
          <label className="char-limit-label" htmlFor="char-limit">
            Enter the number of characters you would like your short description to have
            <input
              id="char-limit"
              name="char-limit"
              className={classNames('char-limit-input', isInvalid && 'char-limit-input--invalid')}
              type="number"
              placeholder="Number of characters"
              onChange={handleCharLimitChanged}
              defaultValue={charLimit}
              min={15}
              max={150}
            />
          </label>
          {isInvalid && (
            <p className="char-limit-invalid">Number of characters must be between 15 and 150.</p>
          )}
        </div>
        <ul className="data-items">
          {dataItems.map((item, index) => (
            <li className="data-item" key={index}>
              <div className="kv-pair">
                <div className="value">{item.value.toLocaleString('en-US')}</div>
                <div className="label">{item.label}</div>
              </div>
            </li>
          ))}
        </ul>
      </>
    ) : null;
  };

  // Renders databot data regarding what would run action do in confirm dialog body
  const renderRunDataList = () => {
    const dataItems = [];

    if (Number.isFinite(prodDescrToAdd)) {
      const singular = prodDescrToAdd === 1;
      dataItems.push({
        value: 'Add',
        label: `${prodDescrToAdd.toLocaleString('en-US')} Short Description Attribute field${
          singular ? '' : 's'
        }`,
      });
    }
    if (Number.isFinite(prodDescrToUpdate)) {
      const singular = prodDescrToUpdate === 1;
      dataItems.push({
        value: 'Clean Up',
        label: `${prodDescrToUpdate.toLocaleString('en-US')} Short Description Attribute field${
          singular ? '' : 's'
        }`,
      });
    }

    return dataItems.length ? (
      <ul className="data-items">
        {dataItems.map((item, index) => (
          <li className="data-item" key={index}>
            <div className="kv-pair">
              <div className="value">{item.value}</div>
              <div className="label">{item.label}</div>
            </div>
          </li>
        ))}
      </ul>
    ) : null;
  };

  useEffect(() => {
    setConfigData({ char_limit: charLimit });
  }, [setConfigData, charLimit]);

  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: mfrName ?? 'N/A' },
        {
          label: 'Total Products',
          value: totalProducts ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      requiresApproval={false}
      hasDownloadedReport={hasDownloadedReport}
      reportName={reportName}
      title="Short Description Creator Databot"
      subtitle="Take any description attribute and create a custom short description that fits within your desired number of characters."
      bodyContent={renderDetailsDataList()}
      hideBodyActions={[
        BOT_STATUSES.PENDING,
        BOT_STATUSES.RUNNING,
        BOT_STATUSES.AWAITING_APPROVAL,
      ].includes(status)}
      disableActions={{
        run: !(prodDescrToAdd > 0 || prodDescrToUpdate > 0) || isInvalid,
      }}
      confirmDialogTitle="Confirm Short Description Creator"
      confirmDialogBody={
        <>
          <p>You are about to:</p>
          {renderRunDataList()}
        </>
      }
      onApproveChanges={handleApproveChanges}
      onRejectChanges={handleRejectChanges}
      onRun={() => handleRunBot(true)}
      onCancel={() => handleCancelBot()}
    />
  );
};

DatabotConfigPanel.defaultProps = {
  additionalData: null,
  requiresApproval: false,
  hasDownloadedReport: false,
  reportName: null,
  botData: null,
  botStatusId: null,
  status: null,
};

DatabotConfigPanel.propTypes = {
  botStatusId: PropTypes.number,
  slug: PropTypes.string.isRequired,
  status: PropTypes.string,
  requiresApproval: PropTypes.bool,
  hasDownloadedReport: PropTypes.bool,
  reportName: PropTypes.string,
  handleRunBot: PropTypes.func,
  handleCancelBot: PropTypes.func,
  additionalData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    total_products: PropTypes.number,
    with_description: PropTypes.number,
    will_create: PropTypes.number,
    will_update: PropTypes.number,
    without_description: PropTypes.number,
  }),
  handleApproveChanges: PropTypes.func,
  handleRejectChanges: PropTypes.func,
  botData: PropTypes.any,
};

export { ShortDescriptionCreatorBot };
