import React from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import BackboneLogo from 'assets/img/backbone-logo.svg';

import './MaintenanceView.scss';

const MaintenanceView = () => {
  const renderHelmet = () => (
    <Helmet bodyAttributes={{ maintenance: 'maintenance' }}>
      <title>Maintenance</title>
    </Helmet>
  );
  return (
    <div className="view-login">
      {renderHelmet()}
      <div className="view-login-wrapper maintenance-wrapper">
        <div className={classNames('maintenance-card')}>
          <div className="maintenance-branding">
            <a href="https://www.backbone.ai/">
              <img alt="BackboneAI logo" src={BackboneLogo} />
            </a>
          </div>
          <p className="maintenance-subheading">Our platform is currently under maintenance.</p>
          <p className="maintenance-subheading">We'll be back online soon.</p>
          <div className="maintenance-form">
            <div className="maintenance-group">
              <span className="maintenance-form--text">Don't have an account?</span>
            </div>
            <button
              className="maintenance-form--submit"
              type="button"
              onClick={() => {
                window.location = 'https://www.backbone.ai/contact/';
              }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MaintenanceView };
