import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import { Alert } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showPlaintext, setShowPlaintext] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [screen, setScreen] = useState(0);
  const getPasswordIcon = () => (showPlaintext ? 'eye-slash' : 'eye');
  const location = useLocation();

  const checkFormState = () => {
    if (password.length === 0 || passwordConfirm.length === 0) return 'Please fill in all fields.';
    if (password !== passwordConfirm) return 'Passwords must match.';
  };

  useEffect(() => {
    const params = queryString.parse(location.search);
    const { token } = params;
    if (token) setToken(token);
  }, [location.search]);

  const doResetPassword = () => {
    setError(null);
    const formState = checkFormState();

    if (formState?.length > 0) {
      setError(formState);
      return;
    }

    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/users/set-new-password`,
      data: {
        token,
        password,
        password_confirm: passwordConfirm,
      },
      onSuccess: () => {
        setScreen(1);
      },
      onError: () => {
        setError(
          'The request you used has expired. Please use your latest request link or create a new one.'
        );
      },
      errorMessage: false,
    };
    ApiCalls.doCall(reqCfg);
  };

  const renderInvalidToken = () => {
    if (token === null || token?.length === 0)
      return (
        <>
          <h1 className="forgot-password-heading">Invalid token</h1>
          <p className="forgot-password-subheading">
            A password reset token must be provided. Click{' '}
            <Link to={{ pathname: '/login', state: { showForgotPassword: true } }}>here</Link> to
            create a new request.
          </p>
          <div className="view-login-forgot">
            <Link to="/login">Back to login</Link>
          </div>
        </>
      );
  };

  const renderScreen = () => {
    return screen === 0 ? (
      <>
        <h1 className="forgot-password-heading">Create new password</h1>
        <div className="view-login-form">
          <div className="view-login-group">
            <label className="view-login-label">Password</label>
            <div className="view-login-group-addon">
              <input
                className="view-login-input"
                type={showPlaintext ? 'text' : 'password'}
                placeholder="Password"
                required
                onChange={(event) => setPassword(event.target.value)}
              />
              <FontAwesomeIcon
                onClick={() => setShowPlaintext(!showPlaintext)}
                className="password-toggle-icon"
                icon={['far', getPasswordIcon()]}
              />
            </div>
          </div>
          <div className="view-login-group">
            <label className="view-login-label">Confirm Password</label>
            <div className="view-login-group-addon">
              <input
                className="view-login-input"
                type={showPlaintext ? 'text' : 'password'}
                placeholder="Password"
                required
                onChange={(event) => setPasswordConfirm(event.target.value)}
              />
            </div>
          </div>
          {error?.length > 0 && (
            <Alert className="my-3" variant="danger">
              {error || 'Could not reset password. Please try again.'} Click{' '}
              <Link to={{ pathname: '/login', state: { showForgotPassword: true } }}>here</Link> to
              create a new request.
            </Alert>
          )}
          <button onClick={() => doResetPassword()} className="view-login-submit" type="button">
            Reset Password
          </button>
        </div>
      </>
    ) : (
      <>
        <h1 className="forgot-password-heading">Password reset successfully</h1>
        <p className="forgot-password-subheading">Your password has been successfully reset.</p>
        <div className="view-login-forgot">
          <Link to="/login">Back to login</Link>
        </div>
      </>
    );
  };

  return (
    <div className="forgot-password-wrapper">
      {token === null || token?.length === 0 ? renderInvalidToken() : renderScreen()}
    </div>
  );
};

export { ResetPassword };
