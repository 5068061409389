import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import { getIconWithPopover } from 'helpers/Utils';

import { BotFormHeaderSection } from '../../_components/BotFormHeaderSection';
import { BotFormLabel } from '../../_components/BotFormLabel';
import { CdsFeatureBenefitsContentForm } from '../_base/CdsFeatureBenefitsContentForm';
import { CdsFeatureBenefitsSourceForm } from '../_base/CdsFeatureBenefitsSourceForm';

import './CdsConfigurationForm.scss';

const MANDATORY_COLUMN_NAMES = ['Item Number', 'Product Type', 'Item Grade', 'Keywords'];

const GptFeatureBenefitsGeneratorForm = ({
  headers,
  config,
  setConfig,
  errors,
  onFormErrors,
  disabled = false,
}) => {
  return (
    <>
      <div>
        <BotFormHeaderSection
          title="GPT Features and Benefits Generator"
          description={
            <>
              <p>
                Leverage the power of <strong>AI</strong> and <strong>ChatGPT</strong> to generate
                high quality, Features and Benefits for your products.
              </p>
              <p>
                Use the configurator below to customize the way you want your content to be
                generated.
              </p>
            </>
          }
        />
      </div>

      <div>
        <BotFormLabel label="Configuration" type="section" />
        {config && Object.keys(config).length > 0 && (
          <div className="cds-content-configuration">
            <div className="cds-section">
              <Tabs defaultActiveKey="source">
                <Tab
                  eventKey="source"
                  className={classNames({ 'mb-3': true, 'cds-config-tabs': true })}
                  title={
                    <div className="tab-title">
                      <div>Source</div>
                      {errors?.sources && (
                        <div className="invalid">
                          {getIconWithPopover({
                            placement: 'top',
                            iconProp: ['far', 'exclamation-circle'],
                            content: 'This form has validation errors.',
                            title: 'Source Form',
                          })}
                        </div>
                      )}
                    </div>
                  }
                >
                  <CdsFeatureBenefitsSourceForm
                    columns={headers}
                    disabled={disabled}
                    state={config.sources || {}}
                    setState={(v) => {
                      setConfig({
                        ...config,
                        sources: v,
                      });
                    }}
                    mandatoryColumns={MANDATORY_COLUMN_NAMES}
                    setInvalid={(v) => {
                      onFormErrors('sources', v);
                    }}
                  />
                </Tab>

                <Tab
                  eventKey="content"
                  title={
                    <div className="tab-title">
                      <div>Content</div>
                      {errors?.content && (
                        <div className="invalid">
                          {getIconWithPopover({
                            placement: 'top',
                            iconProp: ['far', 'exclamation-circle'],
                            content: 'This form has validation errors.',
                            title: 'Content Form',
                          })}
                        </div>
                      )}
                    </div>
                  }
                >
                  <CdsFeatureBenefitsContentForm
                    state={config.content || {}}
                    disabled={disabled}
                    setState={(v) => {
                      setConfig({
                        ...config,
                        content: v,
                      });
                    }}
                    columnNames={headers}
                    setInvalid={(v) => {
                      onFormErrors('content', v);
                    }}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

GptFeatureBenefitsGeneratorForm.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  config: PropTypes.object.isRequired,
  setConfig: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onFormErrors: PropTypes.func,
};

GptFeatureBenefitsGeneratorForm.defaultProps = {
  errors: {},
  onFormErrors: () => {},
};

export { GptFeatureBenefitsGeneratorForm };
