import React from 'react';

import PropTypes from 'prop-types';

import './LoadingLogoSpinner.scss';

import { ReactComponent as BBLogo } from 'assets/img/backbone-logo-spinner.svg';

const LoadingLogoSpinner = ({ style }) => {
  return (
    <span style={style} className="load-logo-spinner-wrapper">
      <BBLogo className="load-logo-spinner" />
    </span>
  );
};

LoadingLogoSpinner.defaultProps = {
  style: null,
};

LoadingLogoSpinner.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
};

export { LoadingLogoSpinner };
