import React, { useEffect, useState, useCallback } from 'react';
import './ReclassifyAttributesBot.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { RECLASSIFY_ATTRIBUTES_BOT } from 'constants/BotConstants';
import { useIsMounted } from 'helpers/useIsMounted';

import { ReclassifyAttributesLogTableDef } from './ReclassifyAttributesLogTableDef';
import { ReclassifyFrom } from './ReclassifyFrom/ReclassifyFrom';
import { ReclassifyHeader } from './ReclassifyHeader/ReclassifyHeader';
import { ReclassifyTo } from './ReclassifyTo/ReclassifyTo';

const ReclassifyAttributesBot = ({
  handleRunBot,
  details,
  setConfigData,
  handleCancelBot,
  additionalData,
  manufacturerId,
}) => {
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invalidMessage, setInvalidMessage] = useState(null);
  const [reclassifyFrom, setReclassifyFrom] = useState(null);
  const [fromAttribute, setFromAttribute] = useState(null);
  const [reclassifyTo, setReclassifyTo] = useState(null);
  const [toAttribute, setToAttribute] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [logData, setLogData] = useState([]);

  useEffect(() => {
    if (details.length > 0) {
      const newLogData = details.map((item) => {
        return {
          user: item.created_by,
          from: item.config_data?.from_attribute_label,
          to: item.config_data?.to_attribute_label,
          date: item.queued_at,
        };
      });
      setLogData(newLogData);
    }
  }, [details]);

  const doGetAttributes = useCallback(() => {
    if (isMounted.current) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/products/attributes?manufacturer=${manufacturerId}`,
        onSuccess: (res) => {
          if (isMounted.current) {
            setAttributes(res.data);
            setIsLoading(false);
          }
        },
        onError: (res) => {
          console.error(res);
          setError('Error: Invalid or missing manufacturer.');
          setAttributes(null);
        },
      });
    }
  }, [manufacturerId, isMounted]);

  useEffect(() => {
    doGetAttributes();
  }, [additionalData, doGetAttributes]);

  const doSubmit = () => {
    handleRunBot();
  };

  // set config data once we have a toAttribute
  useEffect(() => {
    if (toAttribute !== null && fromAttribute !== null) {
      const configData = {
        from_attribute_id: fromAttribute.value,
        from_attribute_label: `${fromAttribute.label}: ${reclassifyFrom} attribute`,
      };
      if (reclassifyTo === RECLASSIFY_ATTRIBUTES_BOT.new.value) {
        configData.new_attribute = toAttribute;
        configData.to_attribute_label = `${toAttribute}: new detailed attribute`;
      } else {
        configData.to_attribute_id = toAttribute.value;
        configData.to_attribute_label = `${toAttribute.label}: ${reclassifyTo} attribute`;
      }
      setConfigData(configData);
    } else {
      setConfigData({});
    }
  }, [toAttribute, fromAttribute, reclassifyTo, setConfigData, reclassifyFrom]);

  // reset everything on changing from type
  useEffect(() => {
    setReclassifyTo(null);
    setToAttribute(null);
    setFromAttribute(null);
  }, [reclassifyFrom]);

  // reset the To selection when From is changed
  useEffect(() => {
    setToAttribute(null);
  }, [fromAttribute]);

  // reset selected when reclassifyTo changes to empty the dropdown
  useEffect(() => {
    setToAttribute(null);
    setInvalidMessage(null);
  }, [reclassifyTo]);

  const modalBody = () => {
    if (reclassifyTo === 'new') {
      return (
        <div>
          You are about to move all attributes from {fromAttribute.label} to a new detailed
          attribute.
        </div>
      );
    }

    return (
      <div>
        <p>
          You are about to move {fromAttribute.number} attributes of {fromAttribute.label} to{' '}
          {toAttribute.label}.
        </p>

        <p>
          {toAttribute.label} contains {toAttribute.number} attributes which may be overridden by
          the bot.
        </p>
      </div>
    );
  };

  return (
    <div className="reclassify-attributes">
      <div className="title">
        <h1>Attribute Reclassification</h1>
        <ReclassifyHeader
          attributes={attributes}
          manufacturer={additionalData.name}
          productTotal={additionalData.total_products}
        />
        <div className="reclassify-attributes-main">
          {!isLoading && error === null ? (
            <>
              <div className="title">Reclassify Attribute</div>
              <div className="reclassify-select">
                <ReclassifyFrom
                  isLoading={isLoading}
                  setIsLoading={(e) => setIsLoading(e)}
                  reclassifyFrom={reclassifyFrom}
                  setReclassifyFrom={(e) => setReclassifyFrom(e)}
                  attributes={attributes}
                  fromAttribute={fromAttribute}
                  setFromAttribute={(e) => setFromAttribute(e)}
                />
                <div className="separator">
                  <FontAwesomeIcon icon={['far', 'arrow-from-left']} />
                </div>
                <ReclassifyTo
                  isLoading={isLoading}
                  isDisabled={fromAttribute === null}
                  setIsLoading={(e) => setIsLoading(e)}
                  reclassifyTo={reclassifyTo}
                  setReclassifyTo={(e) => setReclassifyTo(e)}
                  attributes={attributes}
                  fromAttribute={fromAttribute}
                  toAttribute={toAttribute}
                  setToAttribute={(e) => setToAttribute(e)}
                  invalidMessage={invalidMessage}
                  setInvalidMessage={(e) => setInvalidMessage(e)}
                />
              </div>
              <div className="reclassify-actions">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleCancelBot()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setReclassifyFrom(false)}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setModalVisible(true)}
                  disabled={toAttribute === null || invalidMessage !== null}
                >
                  Submit
                </button>
              </div>
              {modalVisible === true ? (
                <div id="modal-container">
                  <ModalPanel
                    className="modal-on-top"
                    isVisible={modalVisible}
                    setIsVisible={setModalVisible}
                    showCancel
                    header="Confirm Attribute Reclassification"
                    body={() => modalBody()}
                    footer={
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setModalVisible(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => doSubmit()}
                        >
                          Confirm
                        </button>
                      </div>
                    }
                  />
                </div>
              ) : null}
            </>
          ) : error === null ? (
            <LoadingSpinner style={{ fontSize: '4em' }} />
          ) : (
            <div className="reclassify-errors">{error}</div>
          )}
        </div>
        {logData.length > 0 ? (
          <>
            <h3>Attribute Reclassification Log</h3>
            <BootstrapTable
              bordered={false}
              bootstrap4
              keyField="date"
              data={logData}
              columns={ReclassifyAttributesLogTableDef.columns}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export { ReclassifyAttributesBot };
