import { call, put, takeLeading } from 'redux-saga/effects';

import * as ApiCalls from 'api/ApiCalls';
import ActionTypeConstants from 'constants/ActionTypeConstants';

/**
 * Retrieves per supplier statistics
 */
// TODO: Remove this saga altogether
function* getSupplierStatistics() {
  console.debug('getSupplierStatistics');

  const apiResponse = yield call(ApiCalls.legacySyncDoCall, {
    urlPath: '/products/statistics/',
  });

  if (apiResponse?.data) {
    yield put({
      type: ActionTypeConstants.GET_SUPPLIER_STATISTICS_SUCCESS,
      payload: apiResponse.data,
    });
  } else {
    yield put({ type: ActionTypeConstants.GET_SUPPLIER_STATISTICS_FAILURE });
  }
}

export default function* () {
  yield takeLeading(ActionTypeConstants.GET_SUPPLIER_STATISTICS, getSupplierStatistics);
}
