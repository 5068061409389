import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, InputGroup } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import UserRoleConstants from 'constants/UserRoleConstants';
import * as UserUtils from 'helpers/UserUtils';

import { MfrSearch } from './MfrSearch';
import { ProductCompaniesFilter } from './ProductCompaniesFilter';
import { ProductsSearch } from './ProductsSearch';
import TagList from './TagList';

class ProductsFilter extends React.Component {
  state = {
    advSearchExpanded: false,
  };

  onSelectProduct(val) {
    this.props.history.push(`/product/${val.value}`);
  }

  onSearchProduct(val) {
    const newValues = {
      ...this.props.filterValue,
      ...val,
    };
    if (typeof this.props.onChangeFilter === 'function') {
      this.props.onChangeFilter({ filter: newValues });
    }
    this.applyFilter();
  }

  onSelectSupplier(val) {
    if (val) {
      const alreadyExistsVal =
        this.props.filterValue &&
        this.props.filterValue.mfr &&
        this.props.filterValue.mfr.some((item) => item.value === val.value);
      if (!alreadyExistsVal) {
        const newValues = {
          ...this.props.filterValue,
          mfr: [...this.props.filterValue.mfr, val],
        };
        if (typeof this.props.onChangeFilter === 'function') {
          this.props.onChangeFilter({ filter: newValues, doFilter: true });
        }
      }
    }
  }

  onCompanySelect(companies) {
    const newValues = {
      ...this.props.filterValue,
      companies,
    };
    if (typeof this.props.onChangeFilter === 'function') {
      this.props.onChangeFilter({ filter: newValues, doFilter: true });
    }
  }

  onRemoveSupplier(val) {
    if (val) {
      const existsVal =
        this.props.filterValue &&
        this.props.filterValue.mfr &&
        this.props.filterValue.mfr.some((item) => item.value === val.value);
      if (existsVal) {
        const newMfrList = this.props.filterValue.mfr.filter((v) => v.value !== val.value);
        const newValues = {
          ...this.props.filterValue,
          mfr: newMfrList,
        };
        if (typeof this.props.onChangeFilter === 'function') {
          this.props.onChangeFilter({ filter: newValues, doFilter: true });
        }
      }
    }
  }

  applyFilter() {
    if (typeof this.props.onChangeFilter === 'function') {
      this.props.onChangeFilter({ filter: this.props.filterValue, doFilter: true });
    }
  }

  clearFilter() {
    if (typeof this.props.onClearFilter === 'function') {
      this.props.onClearFilter();
    }
  }

  onChangeTextProduct(val) {
    if (val !== '') {
      const newVal = { txt: val };
      const newValues = {
        ...this.props.filterValue,
        ...newVal,
      };
      if (typeof this.props.onChangeFilter === 'function') {
        this.props.onChangeFilter({ filter: newValues });
      }
    }
  }

  onChangeText(val) {
    const newValues = {
      ...this.props.filterValue,
      ...val,
    };
    if (typeof this.props.onChangeFilter === 'function') {
      this.props.onChangeFilter({ filter: newValues });
    }
  }

  isFilterEmpty() {
    const fv = this.props.filterValue;
    if (
      fv.txt ||
      fv.name ||
      fv.pcode ||
      fv.upc ||
      fv.dscr ||
      fv.propshipname ||
      fv.mino ||
      (fv.mfr && fv.mfr.length) ||
      (fv.companies && fv.companies.length)
    ) {
      return false;
    }
    return true;
  }

  renderSummary() {
    const stats = this.props.searchResultsStats;
    const clearFiltersEl = (
      <span className="link" onClick={() => this.clearFilter()}>
        <span className="icon">
          <FontAwesomeIcon icon={['far', 'times']} />
        </span>{' '}
        Clear Filters
      </span>
    );

    if (stats && stats.total > 0) {
      const { total } = stats;
      const offset = (stats.page - 1) * stats.pageSize;
      const indexStart = offset + 1;
      let indexEnd = offset + stats.pageSize;
      if (indexEnd > total) {
        indexEnd = total;
      }
      return (
        <>
          <span className="prod-filter-summary">
            {`Showing products ${indexStart}-${indexEnd} of ${total.toLocaleString('en-US')}`}
          </span>
          {!this.isFilterEmpty(this.props.filterValue) ? (
            <span className="prod-filter-clear">( {clearFiltersEl} )</span>
          ) : null}
        </>
      );
    }
    if (!this.isFilterEmpty(this.props.filterValue)) {
      return <span className="prod-filter-clear">{clearFiltersEl}</span>;
    }
    return null;
  }

  render() {
    const fv = this.props.filterValue;

    const currentUserRole = _get(this.props, 'currentUser.role') || null;
    const isAdmin = currentUserRole === UserRoleConstants.ADMIN;

    // TODO: expanded if any search fields are active calculate
    const advSearchExpandedCalc = this.state.advSearchExpanded || this.props.forceExpand;

    return (
      <>
        <div className="prod-filter-wrap">
          <div className="prod-filter-main">
            <div className="prod-filter-text-search">
              <InputGroup size="md">
                <div className="prod-filter-product-selector">
                  <ProductsSearch
                    value={fv.txt || ''}
                    onSearchSubmit={() => this.onSearchProduct()}
                    onChangeText={(val) => this.onChangeText({ txt: val })}
                    applyFilter={() => this.applyFilter()}
                  />
                </div>
              </InputGroup>
            </div>
            {!UserUtils.isRoleSupplier(this.props.currentUser) ? (
              <div className="prod-filter-supplier-selector">
                <MfrSearch
                  selectedMfrs={fv.mfr}
                  onSearchSubmit={(val) => this.onSelectSupplier(val)}
                />
                <div className="prod-filter-taglist">
                  <TagList value={fv.mfr} onRemove={(val) => this.onRemoveSupplier(val)} />
                </div>
              </div>
            ) : (
              this.props.childrenCompanies?.length > 0 && (
                <ProductCompaniesFilter
                  onCompanySelect={(val) => this.onCompanySelect(val)}
                  companies={this.props.childrenCompanies}
                  selectedCompanies={this.props.filterValue.companies}
                />
              )
            )}
          </div>
          <div className="prod-filter-info-bar">
            <div className="prod-filter-status">{this.renderSummary()}</div>
            {isAdmin && (
              <div className="prod-filter-export-link">
                <Link to="/products-export">Export</Link>
              </div>
            )}
            <div className="prod-filter-advanced-toggle">
              <span
                className={`link${advSearchExpandedCalc ? ' active' : ''}${
                  this.props.forceExpand ? ' disabled' : ''
                }`}
                onClick={() => this.setState({ advSearchExpanded: !this.state.advSearchExpanded })}
              >
                Advanced Search{' '}
                <span className="icon">
                  <FontAwesomeIcon icon={['fas', 'caret-right']} />
                </span>
              </span>
            </div>
          </div>
          <div className={`prod-filter-advanced-wrap${advSearchExpandedCalc ? ' active' : ''}`}>
            <div className="prod-filter-form-wrap flex-form">
              <InputGroup className="flex-row mb-3">
                <FormLabel htmlFor="inpPCode">Manufacturer ID Number (SKU)</FormLabel>
                <FormControl
                  id="inpPCode"
                  type="text"
                  value={fv.pcode}
                  onChange={(e) => this.onChangeText({ pcode: e.target.value })}
                />
              </InputGroup>
              <InputGroup className="flex-row mb-3">
                <FormLabel htmlFor="inpName">Product Name</FormLabel>
                <FormControl
                  id="inpName"
                  type="text"
                  value={fv.name}
                  onChange={(e) => this.onChangeText({ name: e.target.value })}
                />
              </InputGroup>
              <InputGroup className="flex-row mb-3">
                <FormLabel htmlFor="inpDscr">Product Description</FormLabel>
                <FormControl
                  id="inpDscr"
                  type="text"
                  value={fv.dscr}
                  onChange={(e) => this.onChangeText({ dscr: e.target.value })}
                />
              </InputGroup>
              <InputGroup className="flex-row mb-3">
                <FormLabel htmlFor="inpUPC">UPC</FormLabel>
                <FormControl
                  id="inpUPC"
                  type="text"
                  value={fv.upc}
                  onChange={(e) => this.onChangeText({ upc: e.target.value })}
                />
              </InputGroup>
              <InputGroup className="flex-row mb-3">
                <FormLabel htmlFor="inpPropShipName">Proper Shipping Name</FormLabel>
                <FormControl
                  id="inpPropShipName"
                  type="text"
                  value={fv.propshipname}
                  onChange={(e) => this.onChangeText({ propshipname: e.target.value })}
                />
              </InputGroup>
              <InputGroup className="actions flex-row">
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => this.applyFilter()}
                >
                  Search{' '}
                  <span className="icon">
                    <FontAwesomeIcon icon={['far', 'search']} />
                  </span>
                </button>
              </InputGroup>
            </div>
          </div>
        </div>
      </>
    );
  }

  static propTypes = {
    filterValue: PropTypes.object.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
    onClearFilter: PropTypes.func.isRequired,
    // isPublicRoute: PropTypes.bool,
    searchResultsStats: PropTypes.object,
    forceExpand: PropTypes.bool,
    currentUser: PropTypes.object,
  };
}

export default withRouter(ProductsFilter);
