import React, { useCallback, useEffect, useRef, useState } from 'react';

import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { AssessmentsCards } from 'components/assessments/AssessmentsCards/AssessmentsCards';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { ManufacturerSelectMulti } from 'components/common/ManufacturerSelect/ManufacturerSelectMulti';
import { Search } from 'components/common/Search/Search';
import { SearchSuggestions } from 'components/common/SearchSuggestions/SearchSuggestions';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import * as QueryStringConstants from 'constants/QueryStringConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import { useOutsideClick } from 'helpers/useOutsideClick';
import { isRoleAdmin } from 'helpers/UserUtils';
import { paginate } from 'helpers/Utils';

const options = [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

const AssessmentsPerPage = ({
  totalAssessments,
  currentPage,
  limit,
  getSelectedValue,
  setLimit,
  updatePage,
}) => {
  return (
    <div className="assessments-per-page">
      {totalAssessments > 0 ? (
        <span className="assessments-number">
          Showing {(1 + (currentPage - 1) * limit).toLocaleString()}-
          {currentPage * limit < totalAssessments
            ? (currentPage * limit).toLocaleString()
            : totalAssessments.toLocaleString()}{' '}
          of {totalAssessments.toLocaleString()}
        </span>
      ) : null}
      <StyledMultiselect
        isSearchable={false}
        isClearable={false}
        options={options}
        values={getSelectedValue()}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => `${option.label} Items`}
        label="Showing:"
        isMulti={false}
        setOnChange={(item) => {
          setLimit(item.value);
          updatePage(1);
        }}
        selectWrapperStyle={{ width: '200px' }}
        selectStyle={{
          control: () => ({
            height: '33.5px',
            minHeight: '33.5px',
          }),
        }}
      />
    </div>
  );
};

const ViewAssessments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [localSearch, setLocalSearch] = useState('');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [totalAssessments, setTotalAssessments] = useState(0);
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
  const [areSuggestionsLoading, setAreSuggestionsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // companies contains the full object, selected companies is only ids
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [finalQueryString, setFinalQueryString] = useState(null);

  const history = useHistory();
  const searchWrapperRef = useRef(null);
  const urlParams = window.location.search;
  const pagination = paginate(totalAssessments, currentPage, limit);
  const isMounted = useIsMounted();

  const { activeUser } = RootHooks.useActiveUser();
  const isAdmin = isRoleAdmin(activeUser);

  const handleViewDataJob = (id) => {
    history.push(`/data-request/${id}`);
  };

  const updateHistoryState = useCallback(
    (search) => {
      history.replace({ search });
    },
    [history]
  );

  const getSelectedValue = () => {
    return (
      options.find((item) => {
        return item.value === parseInt(limit);
      }) || []
    );
  };

  const updatePage = (page) => {
    const newPage = parseInt(page);
    setOffset(newPage * limit - limit);
    setCurrentPage(newPage);
  };

  const renderHelmet = () => (
    <Helmet bodyAttributes={{ 'data-page': 'view-assessments' }}>
      <title>Assessments</title>
    </Helmet>
  );

  const resetPagination = () => {
    setCurrentPage(1);
    setOffset(0);
  };

  const onSelectCompany = (company) => {
    setSelectedBrands([company]);
    resetPagination();
  };

  const onSelectBrands = (companies) => {
    setSelectedBrands(companies);
    resetPagination();
  };

  useEffect(() => {
    if (isAdmin && !selectedManufacturer) {
      setSelectedBrands([]);
      resetPagination();
    }
  }, [isAdmin, selectedManufacturer]);

  useEffect(() => {
    const params = queryString.parse(urlParams);
    const { paramSearch, paramLimit, paramPage, manufacturer_ids: paramManufacturerIds } = params;

    if (isInitialLoad) {
      if (paramSearch) {
        setSearch(paramSearch);
        setLocalSearch(paramSearch);
      }
      if (paramLimit) setLimit(paramLimit);
      if (paramPage) {
        setCurrentPage(parseInt(paramPage));
        setOffset(paramPage * paramLimit - paramLimit);
      }
      if (paramManufacturerIds) {
        const mfrIds = paramManufacturerIds.split(',');
        setSelectedBrands(mfrIds.map((id) => parseInt(id)));
      }

      setIsInitialLoad(false);
    } else {
      const currentState = {
        search: paramSearch,
        limit: paramLimit,
        page: paramPage,
        manufacturer_ids: paramManufacturerIds,
      };

      if (search !== currentState.search && search.length > 0) {
        currentState.search = search;
        resetPagination();
      } else if (search.length === 0 && params.search?.length > 0) {
        delete currentState.search;
        resetPagination();
      }

      if (limit !== currentState.limit) {
        currentState.limit = limit;
      }
      if (offset !== currentState.offset) {
        currentState.offset = offset;
      }
      if (currentPage !== currentState.page) currentState.page = currentPage;
      if (selectedBrands.length > 0 && selectedBrands !== currentState.manufacturer_ids)
        currentState.manufacturer_ids = selectedBrands;

      updateHistoryState(
        queryString.stringify(currentState, {
          ...QueryStringConstants.defaultQueryStringOptions,
          skipNull: true,
        })
      );
      setFinalQueryString(
        queryString.stringify(currentState, {
          ...QueryStringConstants.defaultQueryStringOptions,
          skipNull: true,
        })
      );
    }
  }, [
    search,
    limit,
    offset,
    currentPage,
    updateHistoryState,
    isInitialLoad,
    urlParams,
    selectedBrands,
  ]);

  useEffect(() => {
    const query = {};
    if (search) query.search = search;
    else {
      setSearchSuggestions([]);
      setAreSuggestionsLoading(false);
      return;
    }
    query.suggest = true;
    const url = `/data-requests/assessments?${queryString.stringify(query)}`;

    setAreSuggestionsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: url,
      onSuccess: (res) => {
        const { suggestions } = res.data;

        if (isMounted.current && suggestions !== undefined) {
          setSearchSuggestions(suggestions);
        }
      },
      onEnd: () => {
        setAreSuggestionsLoading(false);
      },
    });
  }, [isMounted, search]);

  useOutsideClick(searchWrapperRef, () => {
    if (areSuggestionsVisible) setAreSuggestionsVisible(false);
  });

  return (
    <div className="content content-fluid view-assessments">
      {renderHelmet()}
      {isLoading && <LoadingView />}
      <section className="assessments-overview">
        <div className="assessments-overview-header">
          <h1>Assessments</h1>
        </div>
        <div className="header-actions">
          <div ref={searchWrapperRef} className="assessments-search">
            <Search
              onFocus={() => setAreSuggestionsVisible(true)}
              search={search}
              setLocalSearch={setLocalSearch}
              setSearch={setSearch}
              placeholder="Search Data Job Name"
            />
            <SearchSuggestions
              search={search}
              setLocalSearch={setLocalSearch}
              setSearch={setSearch}
              searchSuggestions={searchSuggestions}
              localSearch={localSearch}
              setAreSuggestionsVisible={setAreSuggestionsVisible}
              areSuggestionsLoading={areSuggestionsLoading}
              areSuggestionsVisible={areSuggestionsVisible}
            />
          </div>
          <div className="assessments-filter-companies">
            <ManufacturerSelectMulti
              selectedManufacturer={selectedManufacturer}
              onSelectManufacturer={(v) => {
                setSelectedManufacturer(v);
                onSelectCompany(v);
              }}
              hideManufacturerSection={!isAdmin}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
              onSelectBrands={onSelectBrands}
              brandSectionTitle="Filter Brands"
            />
          </div>

          <AssessmentsPerPage
            totalAssessments={totalAssessments}
            currentPage={currentPage}
            limit={limit}
            getSelectedValue={getSelectedValue}
            setLimit={setLimit}
            updatePage={updatePage}
          />
        </div>
      </section>
      {finalQueryString && (
        <AssessmentsCards
          setTotalAssessments={setTotalAssessments}
          handleViewDataJob={handleViewDataJob}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          finalQueryString={finalQueryString}
        />
      )}

      <div className="footer-actions">
        <AssessmentsPerPage
          totalAssessments={totalAssessments}
          currentPage={currentPage}
          limit={limit}
          getSelectedValue={getSelectedValue}
          setLimit={setLimit}
          updatePage={updatePage}
        />

        <div className="assessments-paginator" style={{ display: isLoading && 'none' }}>
          <div>
            {currentPage > pagination.startPage && (
              <button
                className="assessments-paginator-ctl"
                onClick={() => updatePage(currentPage - 1)}
                type="button"
              >
                Previous
              </button>
            )}
            {currentPage < pagination.endPage && (
              <button
                className="assessments-paginator-ctl"
                onClick={() => updatePage(currentPage + 1)}
                type="button"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewAssessments };
