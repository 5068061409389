import React from 'react';

import classNames from 'classnames';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import styleVars from 'scss/vars.scss';

import { IdsModels } from './IdsModels';

/**
 * Renders simplified summary for Assessment statistics data and a pie chart
 *
 * @param {object} statsData Assessment statistics data
 * @param {string} title Assessment title to be shown on top
 * @param {boolean} isLoadingIdsData Loading state
 * @param {array} additionalStatItems Additional item keys to show
 *
 * @return render
 */
const AssessmentSummary = ({ statsData, additionalStatItems, title, isLoadingIdsData }) => {
  // Transforms stats data and generates pie chart
  const renderStatsPie = (_data) => {
    const pieData = [];
    if (_data?.pass_images > 0)
      pieData.push({
        name: IdsModels.ASSESSMENT_SUMMARY_MODEL.market_ready.label,
        value: _data?.pass_images,
        color: styleVars.colors_designGreen,
      });
    if (_data?.fail_images > 0)
      pieData.push({
        name: IdsModels.ASSESSMENT_SUMMARY_MODEL.needs_work.label,
        value: _data?.fail_images,
        color: styleVars.colors_designYellow,
      });
    if (_data?.invalid_items > 0)
      pieData.push({
        name: IdsModels.ASSESSMENT_SUMMARY_MODEL.rejected.label,
        value: _data?.invalid_items,
        color: styleVars.colors_designRed,
      });

    if (!pieData?.length) {
      return null;
    }

    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            data={pieData}
            dataKey="value"
            innerRadius={55}
            outerRadius={65}
            paddingAngle={3}
            label={({ name }) => name}
            labelLine
          >
            {pieData.map((item, index) => (
              <Cell key={index} fill={item.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const renderStatsDataItems = () => {
    return Object.values(IdsModels.ASSESSMENT_SUMMARY_MODEL)
      .map((item, index) =>
        item.dataKey in statsData ? (
          <li key={index} className={`stats-data-item stats-data-item-${item.key}`}>
            <span className="label">{item.label}:</span>{' '}
            <span className="value-wrap">
              <span className="value">{statsData[item.dataKey].toLocaleString('en-US')}</span>
              {item.showPercentage && statsData?.total_items > 0 && (
                <>
                  {' '}
                  <span className="percentage-wrap">
                    (
                    <span className="value">
                      {Math.round((statsData[item.dataKey] / statsData.total_items) * 100)}
                    </span>
                    <span className="unit">%</span>)
                  </span>
                </>
              )}
            </span>
          </li>
        ) : null
      )
      ?.filter((item) => !!item);
  };

  const renderAdditionalStatDataItems = () => {
    return additionalStatItems
      ?.map((item, index) =>
        item in statsData ? (
          <li
            key={index}
            className={`stats-data-item stats-data-item-additional stats-data-item-${item}`}
          >
            <span className="label">{IdsModels.ASSESSMENT_MODEL[item].label}:</span>{' '}
            <span className="value-wrap">
              <span className="value">{statsData[item].toLocaleString('en-US')}</span>
              {statsData?.total_items > 0 && (
                <>
                  {' '}
                  <span className="percentage-wrap">
                    (
                    <span className="value">
                      {Math.round((statsData[item] / statsData.total_items) * 100)}
                    </span>
                    <span className="unit">%</span>)
                  </span>
                </>
              )}
            </span>
          </li>
        ) : null
      )
      ?.filter((item) => !!item);
  };

  return (
    <>
      <div className={classNames('left-side', { empty: !statsData })}>
        {!!title && (
          <div className="title">
            <h2>
              {title}
              {!!isLoadingIdsData && <LoadingSpinner fast />}
            </h2>
          </div>
        )}
        {statsData ? (
          <div className="stats-wrap">
            <div className="stats-text-data">
              <ul className="stats-data-items">
                {renderStatsDataItems()}
                {renderAdditionalStatDataItems()}
              </ul>
            </div>
          </div>
        ) : (
          <>
            {!isLoadingIdsData && (
              <div className="stats-wrap">
                <div className="empty-message">No assessment data available</div>
              </div>
            )}
          </>
        )}
      </div>
      {!!statsData && <div className="right-side">{renderStatsPie(statsData)}</div>}
    </>
  );
};

export { AssessmentSummary };
