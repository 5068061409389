import './ProgressBarSubmitFooter.scss';

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { Button } from 'react-bootstrap';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';

const ProgressBarSubmitFooter = ({ handleSubmit, checkAndDispatch, step, isLoading }) => {
  const doForward = () => {
    checkAndDispatch(2);
  };
  const doBack = () => {
    checkAndDispatch(1);
  };

  return (
    <div className="flex-row data-job-create-details-bottom">
      <div style={{ order: 2 }}>
        {step === 2 && (
          <Button
            style={{ order: 1 }}
            type="back"
            className="my-1 btn-secondary"
            onClick={() => doBack()}
            disabled={isLoading}
          >
            <>
              <FontAwesomeIcon icon={['far', 'long-arrow-left']} /> Back
            </>
          </Button>
        )}
        <Button
          style={{ order: 2, minWidth: '138px' }}
          type="submit"
          className="my-1"
          onClick={(e) => (step === 1 ? doForward() : handleSubmit(e))}
          disabled={isLoading}
        >
          {step === 1 && !isLoading ? (
            <>
              Next <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
            </>
          ) : (
            <>
              {isLoading ? (
                <LoadingSpinner style={{ fontSize: '2em', marginLeft: '0.5em' }} />
              ) : (
                <>Create Data Job</>
              )}
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

ProgressBarSubmitFooter.defaultProps = {
  step: 1,
  isLoading: true,
};

ProgressBarSubmitFooter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  checkAndDispatch: PropTypes.func.isRequired,
  step: PropTypes.number,
  isLoading: PropTypes.bool,
};
export { ProgressBarSubmitFooter };
