import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { DataJobs } from 'components/data-job/data-jobs/DataJobs';
import { RootHooks } from 'helpers/RootHooks';
import { UserUtils } from 'helpers/UserUtils';

const ViewDataJobs = () => {
  const { activeUser } = RootHooks.useActiveUser();

  return (
    <div className="content content-fluid view-data-jobs">
      <Helmet bodyAttributes={{ 'data-page': 'view-data-jobs' }}>
        <title>All Data Jobs</title>
      </Helmet>
      <div className="title">
        <h1>All Data Jobs</h1>

        {!UserUtils.isReadOnly(activeUser) && (
          <div>
            <Link to="/data-job-create" style={{ marginRight: 10 }}>
              <Button variant="primary">
                <span className="icon" style={{ marginRight: '0.5em' }}>
                  <FontAwesomeIcon icon={['far', 'plus']} />
                </span>
                <span className="label">Create Data Job</span>
              </Button>
            </Link>
          </div>
        )}
      </div>
      <DataJobs />
    </div>
  );
};

export { ViewDataJobs };
