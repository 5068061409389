import React, { useEffect, useCallback, useState, useRef } from 'react';
import './FilesTables.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import * as ApiCalls from 'api/ApiCalls';
import { BAIButton } from 'components/common/Button/Button';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { ImgLightbox } from 'components/common/ImgLightbox/ImgLightbox';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { OKModal } from 'components/common/ModalPanel/OKModal';
import { SelectColorDot } from 'components/common/SelectColorDot/SelectColorDot';
import { UserDisplay } from 'components/common/UserProfileDisplay/UserDisplay';
import { BotRanIcon } from 'components/databots/BotRanIcon';
import {
  assessmentTypes,
  TAB_NAMES,
  statusTransformFiles,
  EXTERNAL_LINK,
} from 'constants/DataJobDetailsConstants';
import {
  generateDownloadUrl,
  generateDownloadPreviewUrl,
  readableFileSize,
  setViewedCommentsFromLocalStorage,
} from 'helpers/AttachmentUtils';
import { canViewAssessment } from 'helpers/FlashAssessmentUtils';
import { RootHooks } from 'helpers/RootHooks';
import { formatTimeStamp, formatDateStamp } from 'helpers/TimeUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { useOutsideClick } from 'helpers/useOutsideClick';
import { UserUtils } from 'helpers/UserUtils';
import { buildTextWithUrl } from 'helpers/Utils';

import { DistributorJobComments } from '../DistributorJobComments';
import { RowActions } from './RowActions';

// TODO: Huge component, to be split to managable pieces
// TODO: This component should be refactored/optimized in a future iteration

/**
 *
 * @param {string} activeTab
 * @param {Object} file
 * @param {array} dataJobCommentsData
 * @param {Object} dataJobCommentsDataStatus
 * @param {object} viewedComments
 * @param {function} onFileDeleted
 * @param {bool} isEditMode
 * @param {bool} isDisabled
 * @param {array} statusData
 * @param {array} bots
 * @param {array} selectedFiles
 * @param {function} doSetSelectedFiles
 * @returns Render
 */

const FileRow = ({
  activeTab,
  file,
  dataJobCommentsData,
  dataJobCommentsDataStatus,
  viewedComments,
  onFileDeleted,
  isEditMode = true,
  isDisabled,
  statusData,
  bots,
  selectedFiles,
  doSetSelectedFiles,
  checkAllFilesAccepted,
  editable = false,
}) => {
  const ref = useRef();
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();

  // TODO: Rework using useLocation
  const dispatch = useDispatch();

  const [commentsVisible, setCommentsVisible] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteAssessmentModal, setShowDeleteAssessmentModal] = useState(false);
  const [showSwitchAssessmentModal, setShowSwitchAssessmentModal] = useState(false);
  const [isCommentIndicatorActive, setIsCommentIndicatorActive] = useState(false);
  const [fileStatus, setFileStatus] = useState({});

  const [localStatusData, setLocalStatusData] = useState(statusData);
  const { featureFlags } = RootHooks.useFeatureFlags();
  // Status Changes
  useEffect(() => {
    const result = statusData.filter((item) => {
      return item && item.value === file.status.id;
    });

    setLocalStatusData(statusData);

    if (result.length > 0) {
      const newStatus = {
        label: result[0].label,
        value: result[0].value,
        color: result[0].baseColor,
      };
      setFileStatus(newStatus);
    } else {
      const readOnlyStatus = {
        label: file.status.name,
        value: file.status.id,
        color: file.status.color,
      };
      setFileStatus(readOnlyStatus);

      // if there are choices for the status, and the currently selected one is not available, add to the list so the user can backtrack
      // assume the statuses delivered are the only ones allowed for this user from BE
      if (statusData.length > 0) {
        setLocalStatusData([
          ...statusData,
          {
            label: file.status.name,
            value: file.status.id,
            baseColor: file.status.color,
          },
        ]);
      }
    }
  }, [file.status, statusData]);

  // Comment Changes (viewed, unviewed)
  useEffect(() => {
    const viewedCommentsIds = viewedComments[file.id] || [];
    let isActive = false;

    for (const e in dataJobCommentsData) {
      if (!viewedCommentsIds.includes(dataJobCommentsData[e].id)) {
        isActive = true;
        break;
      }
    }

    if (viewedCommentsIds.length !== dataJobCommentsData.length) {
      isActive = true;
    }

    setIsCommentIndicatorActive(isActive);
  }, [file, dataJobCommentsData, viewedComments]);

  const switchCommentsVisibility = () => {
    if (!commentsVisible && isCommentIndicatorActive) {
      const viewedCommentsIds = viewedComments[file.id] || [];

      const commentIds = dataJobCommentsData.map((comment) => comment.id);
      viewedCommentsIds.push(...viewedCommentsIds, ...commentIds);

      viewedComments[file.id] = viewedCommentsIds;

      setViewedCommentsFromLocalStorage(viewedComments);
    }
    setCommentsVisible(!commentsVisible);
    if (isCommentIndicatorActive) {
      setIsCommentIndicatorActive(false);
    }
  };

  const switchActions = useCallback(() => {
    setActionsVisible(!actionsVisible);
  }, [actionsVisible]);

  // -------------------------------------------------------- Deletions -------------------------------------------------------- //

  const handleDeleteAttachment = async () => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.DELETE,
      urlPath: `/data-requests/attachments/${file.id}`,
      onSuccess: () => {
        onFileDeleted(file.id);
      },
    });
  };

  const handleDeleteAssessment = () => {
    const flashAssessmentId = file.flash_assessment_id;
    const isManual = file.is_flash_assessment_manual;

    // Set these state updates outside of the API call to update the UI immediately
    file.flash_assessment_id = null;
    file.is_flash_assessment_manual = null;

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.DELETE,
      urlPath: `/flash-assessments/${flashAssessmentId}`,
      onError: () => {
        // Reset the UI state if there is an error
        file.flash_assessment_id = flashAssessmentId;
        file.is_flash_assessment_manual = isManual;
      },
    });
  };

  // --------------------------------------------------------------------------------------------------------------------------- //
  // -------------------------------------------------------- Downloads -------------------------------------------------------- //

  const downloadFile = useCallback(() => {
    const downloadUrl = generateDownloadUrl(file.id) || null;
    if (!downloadFile) {
      return;
    }
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';

    switchActions();
    a.click();
  }, [file.id, switchActions]);

  useOutsideClick(ref, () => {
    switchActions();
  });

  const statusOnChange = useCallback(
    (e) => {
      setFileStatus({ value: e.value, label: e.label, color: e.baseColor });
      const reqCfg = {
        method: ApiCalls.HTTP_METHODS.PUT,
        urlPath: `/data-requests/attachments/${file.id}`,
        data: { status_id: e.value },
        onSuccess: () => {
          if (isMounted.current) {
            if (e.label === statusTransformFiles.FILE_ACCEPTED.label)
              checkAllFilesAccepted(file.id);
          }
        },
      };
      ApiCalls.doCall(reqCfg);
    },
    [checkAllFilesAccepted, file.id, isMounted]
  );

  // --------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------- Assessments ------------------------------------------------------- //
  const switchAssessment = () => {
    setShowSwitchAssessmentModal(true);
  };

  const startAssessment = useCallback(() => {
    if (file.flash_assessment_id && !file.is_flash_assessment_manual) {
      switchAssessment();
      return;
    }

    dispatch(push(`/data-request/${file.data_request}/${file.id}/flash-assessment`));
  }, [
    dispatch,
    file.data_request,
    file.id,
    file.flash_assessment_id,
    file.is_flash_assessment_manual,
  ]);

  const handleSwitchAssessment = async () => {
    const isManual = file.is_flash_assessment_manual;
    await handleDeleteAssessment();
    if (!isManual) {
      startAssessment();
    }
  };

  const goToFlashAssessmentReport = useCallback(
    // For automated assessments, pass assessmentType.AUTOMATED.
    (id, assessmentType = null) => {
      let flashAssessmentUrl = `/flash-assessment/${id}`;
      if (assessmentType === assessmentTypes.AUTOMATED) {
        flashAssessmentUrl = `/data-ingestion/assessment/${id}`;
      }
      if (isMounted.current) {
        dispatch(push(flashAssessmentUrl));
      }
    },
    [dispatch, isMounted]
  );

  // TODO Technical Debt: automated flash assessment url uses attachment id, while manual flash assessments us assessment id
  const handleGoToAssessmentReport = (id) => {
    // Determine assessment link based on manual vs automated assessment type
    if (file.flash_assessment_id && file.is_flash_assessment_manual) {
      goToFlashAssessmentReport(id, assessmentTypes.MANUAL);
    } else if (file.flash_assessment_id && !file.is_flash_assessment_manual) {
      goToFlashAssessmentReport(file.id, assessmentTypes.AUTOMATED);
    }
    goToFlashAssessmentReport(id);
    switchActions();
  };

  const isFileSelected = (id) => {
    return selectedFiles.includes(id);
  };
  // --------------------------------------------------------------------------------------------------------------------------- //
  // ----------------------------------------------------- CELL RENDERING ------------------------------------------------------ //
  const renderSelectCheckbox = () => {
    return (
      featureFlags.ENABLE_BATCH_ATTACHMENT_DOWNLOAD && (
        <div className="files-table select-column">
          <input type="checkbox" defaultChecked={file.selected} hidden name={`file-${1}`} />
          <div
            tabIndex={0}
            role="button"
            onKeyDown={() => doSetSelectedFiles(file.id)}
            className="assets-actions-body-checkbox"
            onClick={() => doSetSelectedFiles(file.id)}
          >
            <Checkbox checked={isFileSelected(file.id)} />
          </div>
        </div>
      )
    );
  };

  const renderEmptyCell = () => {
    return (
      <div className="files-table select-column">
        <div> </div>
      </div>
    );
  };

  const renderExternalLink = () => {
    return (
      <div className="files-table filename-column filename-cell">
        <div className="filename-container">
          {buildTextWithUrl(file.original_name ?? file.name, true, 'filename')}
        </div>
        <div className="filesize">External Link</div>
      </div>
    );
  };

  const renderFilename = () => {
    return (
      <div className="files-table filename-column filename-cell">
        <div className="filename-container">
          <a
            href={generateDownloadUrl(file.id)}
            target="_blank"
            rel="noreferrer noopener"
            className="filename"
          >
            {file.original_name ?? file.name}
          </a>
          <span className="preview-link">
            {featureFlags.ENABLE_IMAGE_LIGHTBOX && file?.thumbnail_name && (
              <ImgLightbox
                src={generateDownloadPreviewUrl(file.id)}
                title={file.original_name ?? file.name}
              >
                {({ doOpen }) => (
                  <button type="button" className="link" onClick={() => doOpen()}>
                    <FontAwesomeIcon icon={['far', 'file-search']} />
                  </button>
                )}
              </ImgLightbox>
            )}
          </span>
        </div>
        <div className="filesize">{readableFileSize(file.size)}</div>
      </div>
    );
  };

  const renderDateAndUser = () => {
    // Get uploader user's company name
    const userCompanyName = UserUtils.getCompany(file?.user)?.name ?? 'BackboneAI';

    return (
      <div className="files-table date-column date-cell">
        <span className="written-date">{formatDateStamp(file.created_at)}</span>
        <span className="written-time">{formatTimeStamp(file.created_at)}</span>
        <div className="upload-user-container">
          {file.user.profile && file.user.profile.picture ? (
            <UserDisplay
              name={`${file.user.first_name} ${file.user.last_name}`}
              picture={file.user.profile.picture}
              size="sm"
              singleInitial={false}
              userCompanyName={userCompanyName || null}
            />
          ) : (
            <UserDisplay
              name={`${file.user.first_name} ${file.user.last_name}`}
              size="sm"
              singleInitial
              userCompanyName={userCompanyName || null}
            />
          )}
        </div>
      </div>
    );
  };

  const renderCommentCell = () => {
    return (
      <div
        tabIndex={0}
        role="button"
        onKeyDown={switchCommentsVisibility}
        onClick={switchCommentsVisibility}
        className="files-table comment-column comment-cell"
      >
        <FontAwesomeIcon icon={['fas', 'comments']} />
        {dataJobCommentsData.length ? (
          <div
            className={`comments-indicator ${
              isCommentIndicatorActive ? '' : 'comments-indicator--inactive'
            }`}
          >
            {dataJobCommentsData.length}
          </div>
        ) : null}
      </div>
    );
  };

  const renderBots = () => {
    return (
      <div className="files-table bots-column sources-table-bots">
        {bots.length ? (
          bots
            .slice(0, 5)
            .map((bot) => (
              <BotRanIcon
                key={bot.name}
                className="sources-table-bots__icon"
                withTooltip
                tooltipText={bot.name}
                bot={bot}
              />
            ))
        ) : (
          /* TODO V2: enable this link to the databots dashboard once accessible to users
            <a href='/databots'>
              {' '}
              <BotRanIcon
                withTooltip
                tooltipText="Click here to go to the databots dashboard"
                icon={<FontAwesomeIcon size="sm" icon={['fas', 'plus']} />}
              />
            </a> */
          <LoadingSpinner className="files-row-bots-loading-spinner" />
        )}
      </div>
    );
  };

  const renderFileStatus = () => {
    return (
      activeTab === TAB_NAMES.TRANSFORMED && (
        <div className="files-table status-column">
          <SelectColorDot
            isDisabled={
              (isDisabled && (!isEditMode || !editable)) || UserUtils.isReadOnly(activeUser)
            }
            id={`inpStatus${file.id}`}
            value={fileStatus}
            data={localStatusData}
            setOnChange={statusOnChange}
          />
        </div>
      )
    );
  };

  const renderViewAssessmentButton = () => {
    return (
      <div className="files-table assessment-column assessment-column">
        {
          // TODO: Following BC-3791, remove the admin/manual checks when automated assessments can be shown again, and just display the button
          canViewAssessment(file) &&
            (UserUtils.isRoleAdmin(activeUser) || file.is_flash_assessment_manual) && (
              <BAIButton
                label="VIEW ASSESSMENT"
                onClick={() => handleGoToAssessmentReport(file.flash_assessment_id)}
              />
            )
        }
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="files-table-rows">
          {/* RENDER ROW CELLS */}
          {file.file_type !== EXTERNAL_LINK ? renderSelectCheckbox() : renderEmptyCell()}
          {file.file_type !== EXTERNAL_LINK ? renderFilename() : renderExternalLink()}
          {renderDateAndUser()}
          {renderCommentCell()}
          {renderBots()}
          {renderFileStatus()}
          {renderViewAssessmentButton()}

          {/* privacy column */}
          {featureFlags.ENABLE_PRIVACY_DISPLAY && (
            <div className="files-table privacy-column">
              <FontAwesomeIcon icon={['fas', 'user-friends']} />
              <b className="ml-2">Everyone</b>
            </div>
          )}
          <RowActions
            activeTab={activeTab}
            file={file}
            isEditMode={isEditMode}
            isDisabled={isDisabled}
            switchAssessment={switchAssessment}
            startAssessment={startAssessment}
            setShowDeleteModal={(v) => setShowDeleteModal(v)}
            setShowDeleteAssessmentModal={(v) => setShowDeleteAssessmentModal(v)}
            switchActions={() => switchActions()}
            editable={editable}
          />
        </div>
        {/* COMMENTS */}
        {commentsVisible ? (
          <DistributorJobComments
            dataJobCommentsData={dataJobCommentsData}
            dataJobCommentsDataStatus={dataJobCommentsDataStatus}
            attachmentId={file.id}
            jobId={file.data_request}
            canEditExistingComments={editable}
          />
        ) : null}
      </div>
      {/* DELETE MODALS */}
      <OKModal
        header="Delete File?"
        body={
          <span className="attachments-table-row_item_item-deletebutton-confirmbody">
            {`Are you sure you want to delete file "${file.original_name ?? file.name}"?`}
          </span>
        }
        isVisible={showDeleteModal}
        setIsVisible={setShowDeleteModal}
        onOk={handleDeleteAttachment}
        onClose={switchActions}
        showCancel
        isDanger
      />
      <OKModal
        header="Delete Assessment?"
        body={
          <span className="attachments-table-row_item_item-deletebutton-confirmbody">
            {`Are you sure you want to delete the assessment for "${
              file.original_name ?? file.name
            }"?`}
          </span>
        }
        isVisible={showDeleteAssessmentModal}
        setIsVisible={setShowDeleteAssessmentModal}
        onOk={handleDeleteAssessment}
        onClose={switchActions}
        showCancel
        isDanger
      />
      {/* SWITCH ASSESSMENT TYPE */}
      <OKModal
        header={`Switch to ${file.is_flash_assessment_manual ? 'Automated' : 'Manual'} Assessment?`}
        body={
          <span className="attachments-table-row_item_item-deletebutton-confirmbody">
            {`Switching to ${
              file.is_flash_assessment_manual ? 'an automated' : 'a manual'
            } assessment will delete your existing ${
              file.is_flash_assessment_manual ? 'manual' : 'automated'
            } assessment. Are you sure you want to delete the assessment for "${
              file.original_name ?? file.name
            }"?`}
          </span>
        }
        isVisible={showSwitchAssessmentModal}
        setIsVisible={setShowSwitchAssessmentModal}
        onOk={handleSwitchAssessment}
        onClose={switchActions}
        showCancel
        isDanger
      />
    </>
  );
};

FileRow.defaultProps = {
  activeTab: null,
  file: null,
  dataJobCommentsData: null,
  dataJobCommentsDataStatus: null,
  viewedComments: null,
  onFileDeleted: () => {},
  isEditMode: true,
  isDisabled: false,
  statusData: null,
  bots: null,
  selectedFiles: null,
  doSetSelectedFiles: () => {},
  editable: false,
};

FileRow.propTypes = {
  activeTab: PropTypes.string,
  file: PropTypes.object,
  dataJobCommentsData: PropTypes.array,
  dataJobCommentsDataStatus: PropTypes.string,
  viewedComments: PropTypes.object,
  onFileDeleted: PropTypes.func,
  isEditMode: PropTypes.bool,
  isDisabled: PropTypes.bool,
  statusData: PropTypes.array,
  bots: PropTypes.array,
  selectedFiles: PropTypes.array,
  doSetSelectedFiles: PropTypes.func,
  editable: PropTypes.bool,
};

export { FileRow };
