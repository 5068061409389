import React from 'react';

import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { BOT_STATUSES } from 'constants/BotConstants';

import { PricingDataInfoSection } from './PricingDataInfoSection';
import { PricingDataOverview } from './PricingDataOverview';
import './PricingValidationBot.scss';
import { PricingValidationLog } from './PricingValidationLog';

/**
 * Pricing Validation databot config panel
 *
 * @param {string} botStatusId Databot status (run) ID
 * @param {string} slug Databot slug
 * @param {string} status Databot run status
 * @param {bool} requiresApproval Whether or not the databot requires approval for changes
 * @param {bool} hasDownloadedReport Whether or not the active user has downloaded the changes report
 * @param {bool} reportName The report's file name
 * @param {function} handleRunBot Event handler
 * @param {function} handleCancelBot Event handler
 * @param {object} additionalData Databot additional data
 * @param {function} handleApproveChanges Event handler for product auxiliary process
 * @param {function} handleRejectChanges Event handler for product auxiliary process
 * @param {object} configData Databot config object
 * @param {object} setConfigData Databot config object setter
 * @param {object} botData Complete bot data
 * @return render
 */
const PricingValidationBot = ({
  botStatusId,
  slug,
  status,
  hasDownloadedReport,
  reportName,
  handleRunBot,
  handleCancelBot,
  additionalData,
  details,
  handleApproveChanges,
  handleRejectChanges,
}) => {
  return (
    <div className="pricing-validation-bot-wrap">
      <DatabotConfigPanel
        headingStatsData={[
          { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
          {
            label: 'Total Products',
            value: additionalData?.total_products ?? 'N/A',
            tooltip: 'Number of unique products on BackboneAI',
          },
        ]}
        botStatusId={botStatusId}
        slug={slug}
        status={status}
        requiresApproval={false}
        hasDownloadedReport={hasDownloadedReport}
        reportName={reportName}
        title="Pricing Attribute Validation Databot"
        subtitle={
          <>
            Start your data automation journey by cleaning, validating, and transforming your
            pricing data. Our Pricing Validation Databot generates a report for you to approve the
            cleaned and transformed data to meet industry standards and allow for automated
            transformations.
          </>
        }
        bodyContent={<PricingDataOverview additionalData={additionalData} />}
        hideBodyActions={[
          BOT_STATUSES.PENDING,
          BOT_STATUSES.RUNNING,
          BOT_STATUSES.AWAITING_APPROVAL,
        ].includes(status)}
        confirmDialogTitle="Confirm Pricing Validation Databot"
        confirmDialogBody={
          <p>
            You are about to:
            <br />
            <b>Accept changes being made to your pricing data</b>
          </p>
        }
        onApproveChanges={handleApproveChanges}
        onRejectChanges={handleRejectChanges}
        onRun={() => handleRunBot(true)}
        onCancel={() => handleCancelBot()}
        enableIncorrectDataReport
      />
      <PricingDataInfoSection />
      {details?.length > 0 && <PricingValidationLog details={details} />}
    </div>
  );
};

export { PricingValidationBot };
