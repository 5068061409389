import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import * as ApiCalls from 'api/ApiCalls';
import { FilterCompanies } from 'components/common/FilterCompanies/FilterCompanies';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import './ManufacturerSelectMulti.scss';

/**
 * Mfr and child company selector for multiple brand selection
 *
 * @param {number} selectedManufacturer Const for manufacturer id
 * @param {function} onSelectManufacturer Event handler for selection change
 * @param {boolean} hideManufacturerDisplay Hide the manufacturer name section
 * @param {array} selectedBrands Array of brand ids
 * @param {function} setSelectedBrands Set state function for brands
 * @param {function} onSelectBrands Event handler for selection change on brands
 * @param {boolean} isDisabled Disabled state
 * @return render
 */
const ManufacturerSelectMulti = ({
  selectedManufacturer = null,
  onSelectManufacturer = () => null,
  hideManufacturerSection = false,
  manufacturerSectionTitle = 'Manufacturer',
  selectedBrands = [],
  setSelectedBrands,
  onSelectBrands = () => null,
  brandSectionTitle = 'Level 1 Brands',
  isDisabled = false,
}) => {
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();

  // Holds local objects for control values
  const [localManufacturer, setLocalManufacturer] = useState(null);

  // Holds list of manufacturers
  const [mfrsData, setMfrsData] = useState([]);
  const [mfrsDataStatus, setMfrsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds list of child mfrs per selected mfr
  const [brandData, setBrandData] = useState([]);
  const [brandDataStatus, setBrandDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Init mfrs list
  useEffect(() => {
    if (isMounted.current) {
      setMfrsDataStatus(ActionStatusConstants.ISBUSY);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/parents/manufacturers',
        onSuccess: (res) => {
          if (isMounted.current) {
            setMfrsDataStatus(ActionStatusConstants.SUCCESS);
            const _data = res?.data || null;
            setMfrsData(_data);
          }
        },
        onError: () => {
          setMfrsDataStatus(ActionStatusConstants.FAILURE);
        },
      });
    }
  }, [isMounted]);

  useEffect(() => {
    let hasChildren = false;

    const manufacturer = localManufacturer?.obj || activeUser?.profile?.manufacturer;

    if (activeUser?.role === UserRoleConstants.ADMIN && manufacturer?.id) {
      const mfrDataItem = mfrsData.find((item) => item.id === manufacturer.id);
      hasChildren = !!mfrDataItem?.has_children;
    } else if (activeUser?.role === UserRoleConstants.SUPPLIER) {
      hasChildren = activeUser?.profile?.manufacturer?.has_children;
    }

    if (hasChildren) {
      if (isMounted.current) {
        setBrandDataStatus(ActionStatusConstants.ISBUSY);
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/manufacturers/${manufacturer.id}/children`,
          onSuccess: (res) => {
            if (isMounted.current) {
              setBrandDataStatus(ActionStatusConstants.SUCCESS);
              const _data = res?.data || null;
              const finArray = [manufacturer, ..._data];
              setBrandData(finArray);
              setSelectedBrands(finArray.map((item) => item.id));
            }
          },
          onError: () => {
            setBrandDataStatus(ActionStatusConstants.FAILURE);
          },
        });
      }
    } else {
      setBrandData([]);
    }
  }, [isMounted, mfrsData, localManufacturer, activeUser, setSelectedBrands]);

  // Generates a Select value object from object in data list, by id
  const getSelectValue = (list, id) => {
    if (id && list?.length) {
      const dataItem = list.find((item) => item.id === id);

      if (dataItem) {
        return { value: dataItem.id, label: dataItem.name, obj: dataItem };
      }
    }

    return [];
  };

  // load in values if passed
  useEffect(() => {
    if (!localManufacturer && selectedManufacturer && mfrsData?.length > 0) {
      setLocalManufacturer(getSelectValue(mfrsData, selectedManufacturer));
    }
  }, [localManufacturer, selectedManufacturer, mfrsData]);

  const displaySelectManufacturer = activeUser?.role === UserRoleConstants.ADMIN;
  const displaySelectBrand =
    ((displaySelectManufacturer && selectedManufacturer !== null) ||
      activeUser?.profile?.manufacturer?.has_children) &&
    brandData?.length;

  const _isBusy = [mfrsDataStatus, brandDataStatus].includes(ActionStatusConstants.ISBUSY);

  const _isDisabled = isDisabled || _isBusy;

  return (
    <section className="manufacturer-select">
      {hideManufacturerSection ? null : (
        <div className="manufacturer">
          {displaySelectManufacturer ? (
            <>
              <div className="title">
                <span>{manufacturerSectionTitle}</span>
                {_isBusy && <LoadingSpinner fast />}
              </div>
              <StyledMultiselect
                values={localManufacturer}
                options={
                  mfrsData
                    ? mfrsData.map((item) => {
                        return { value: item.id, label: item.name, obj: item };
                      })
                    : []
                }
                setOnChange={(v) => {
                  setLocalManufacturer(v);
                  onSelectManufacturer(v?.value || null);
                }}
                getOptionValue={(option) => option.value}
                closeMenuOnSelect
                isSearchable
                isDisabled={_isDisabled}
                isMulti={false}
                isClearable
                canReset
              />
            </>
          ) : (
            <>
              <div className="title">
                <span>{manufacturerSectionTitle}</span>
                {_isBusy && <LoadingSpinner fast />}
              </div>
              <div className="value">{activeUser?.profile?.manufacturer?.name}</div>
            </>
          )}
        </div>
      )}
      {displaySelectBrand ? (
        <div className="child-company">
          <div className="title">
            {brandSectionTitle}
            {_isBusy && <LoadingSpinner fast />}
          </div>
          <FilterCompanies
            companies={brandData}
            onCompanySelect={onSelectBrands}
            selectedCompanies={selectedBrands}
          />
        </div>
      ) : null}
    </section>
  );
};

ManufacturerSelectMulti.defaultProps = {
  selectedManufacturer: null,
  onSelectManufacturer: () => null,
  hideManufacturerSection: false,
  selectedBrands: [],
  onSelectBrands: () => null,
  isDisabled: false,
};

ManufacturerSelectMulti.propTypes = {
  selectedManufacturer: PropTypes.number,
  onSelectManufacturer: PropTypes.func,
  hideManufacturerSection: PropTypes.bool,
  selectedBrands: PropTypes.array,
  onSelectBrands: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export { ManufacturerSelectMulti };
