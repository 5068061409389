import React, { useEffect, useReducer, useState } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { PassThruModal } from 'components/common/ModalPanel/PassThruModal';
import { SmallProgressBar } from 'components/common/SmallProgressBar/SmallProgressBar';
import { UnmountPrompt } from 'components/common/UnmountPrompt/UnmountPrompt';
import { AdditionalInformation } from 'components/data-job/data-job-create/DataJobAdditionalInformation/AdditionalInformation';
import { DataJobDetailsSection } from 'components/data-job/data-job-create/DataJobDetailsSection/DataJobDetailsSection';
import { DataJobDirectionSelect } from 'components/data-job/data-job-create/DataJobDirectionSelect/DataJobDirectionSelect';
import { ProgressBarSubmitFooter } from 'components/data-job/data-job-create/ProgressBarSubmitFooter';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import DataJobCreateConstants, { DATA_JOB_DIRECTION_ENUM } from 'constants/DataJobCreateConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { formatDate8601 } from 'helpers/TimeUtils';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

/**
 * NOTE: The reducers and user constants are still not unified for client/distributor and manufacturer/supplier:
 * DetailsReducer Requestor Type follows old BE pattern of 'client' or 'manufacturer'
 * UserRoleConstants use SUPPLIER vs CLIENT
 * DetailsReducer uses MANUFACTURER and DISTRIBUTOR for 'manufacturer' and 'client
 * CurrentUser profiles use 'manufacturer' and 'client'
 *
 */
const detailsReducer = (state, action) => {
  switch (action.type) {
    case DataJobCreateConstants.DATA_JOB_DIRECTION:
      return { ...state, dataJobDirection: action.payload };
    case DataJobCreateConstants.FILE:
      return { ...state, files: [...state.files, action.payload] };
    case DataJobCreateConstants.FILE_TRANSFORMATION:
      return {
        ...state,
        files: state.files.map((f) => {
          if (f.id === action.payload.id)
            // eslint-disable-next-line no-param-reassign
            f.transformation_required = action.payload.transformation_required;
          return f;
        }),
      };
    case DataJobCreateConstants.DELETE_FILE:
      return { ...state, files: state.files.filter((f) => f.id !== action.payload) };
    case DataJobCreateConstants.DELETE_LINK:
      return { ...state, links: state.links.filter((f) => f.id !== action.payload) };
    case DataJobCreateConstants.LINK:
      return { ...state, links: [...state.links, action.payload] };
    case DataJobCreateConstants.RECIPIENT:
      // Types: 'manufacturer', 'client'
      return { ...state, recipient: action.payload };
    case DataJobCreateConstants.DISTRIBUTOR:
      return { ...state, distributor: action.payload };
    case DataJobCreateConstants.MANUFACTURER:
      return { ...state, manufacturer: action.payload };
    case DataJobCreateConstants.REQUESTOR:
      return { ...state, requestor: action.payload };
    case DataJobCreateConstants.REQUESTOR_TYPE:
      // Types: 'client' or 'manufacturer'
      return { ...state, requestor_type: action.payload };
    case DataJobCreateConstants.JOBNAME:
      return { ...state, jobName: action.payload };
    case DataJobCreateConstants.DESCRIPTION:
      return { ...state, desc: action.payload };
    case DataJobCreateConstants.JOBTYPE:
      return { ...state, dataRequestTypeId: action.payload, dataRequestSubtypeIds: [] };
    case DataJobCreateConstants.SUBTYPE:
      return { ...state, dataRequestSubtypeIds: action.payload };
    case DataJobCreateConstants.PRIORITY:
      return { ...state, priority: action.payload };
    case DataJobCreateConstants.DELIVERYFORM:
      return { ...state, expectedDeliveryFormatIds: action.payload };
    case DataJobCreateConstants.LINKEDJOB:
      return { ...state, linkedDataJob: action.payload };
    case DataJobCreateConstants.DUEDATE:
      return { ...state, dueDate: action.payload };
    case DataJobCreateConstants.VICARIOUS_JOB:
      return { ...state, vicariousJob: action.payload };
    case DataJobCreateConstants.RESET:
      return action.payload;
    default:
      return state;
  }
};
const invalidReducer = (state, action) => {
  return { ...state, ...action.payload };
};

const ViewDataJobCreate = withRouter(({ history }) => {
  const { activeUser } = RootHooks.useActiveUser();
  const isMounted = useIsMounted();
  const [dataStatus, setDataStatus] = useState(ActionStatusConstants.INITIAL);
  const [isFileUploading, setIsFileUploading] = useState(null);
  const [hasTriedSubmitPage1, setHasTriedSubmit1] = useState(false);
  const [hasTriedSubmitPage2, setHasTriedSubmit2] = useState(false);
  const [step, setStep] = useState(1);
  const [isPassThruModalVisible, setIsPassThruModalVisible] = useState(false);

  // ---------------------------------- Job Details Redux ---------------------------------- //
  const initialDetailsState = {
    dataJobDirection: null,
    files: [],
    links: [],
    recipient: null,
    distributor: null,
    manufacturer: null,
    requestor: null,
    // Note: requestor_type is never ADMIN
    requestor_type:
      activeUser?.role === UserRoleConstants.CLIENT
        ? DataJobCreateConstants.CLIENT
        : DataJobCreateConstants.MANUFACTURER,
    jobName: '',
    desc: '',
    linkedDataJob: [],
    dueDate: '',
    priority: '',
    dataRequestTypeId: null,
    dataRequestSubtypeIds: [],
    expectedDeliveryFormatIds: [],
    vicariousJob: false,
  };

  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    _cloneDeep(initialDetailsState)
  );

  const [invalidCheck, dispatchInvalidCheck] = useReducer(invalidReducer, {
    dataJobDirection: false,
    recipient: false,
    distributor: false,
    manufacturer: false,
    requestor: false,
    jobName: false,
    desc: false,
    details: false,
    dataRequestTypeId: false,
    dataRequestSubtypeIds: false,
    transformationRequired: false,
  });

  const isDirtyDetailsState = () => {
    const isDirty =
      dataStatus === ActionStatusConstants.SUCCESS || _isEqual(initialDetailsState, detailsState);
    return !isDirty;
  };

  const dispatchChangeDetails = (data) => {
    detailsDispatch(data);
  };

  // -------------------------------- Page Completion Logic -------------------------------- //
  const isPage1Complete = () => {
    return [
      activeUser?.role !== UserRoleConstants.ADMIN ? !detailsState.recipient : false,
      activeUser?.role === UserRoleConstants.ADMIN ? !detailsState.distributor : false,
      activeUser?.role === UserRoleConstants.ADMIN ? !detailsState.manufacturer : false,
      activeUser?.role === UserRoleConstants.ADMIN ? !detailsState.requestor : false,
      !detailsState.jobName,
      !detailsState.desc,
      !detailsState.dataJobDirection,
      detailsState.files.filter((f) => f.transformation_required !== null).length !==
        detailsState.files.length,
    ].includes(true);
  };

  const isPage2Complete = () => {
    return (
      !detailsState.dataRequestTypeId ||
      detailsState.dataRequestSubtypeIds === null ||
      !detailsState.dataRequestSubtypeIds.length
    );
  };

  const checkAndDispatch = (stepVal) => {
    if (step === 1) {
      setHasTriedSubmit1(true);
      if (isPage1Complete()) {
        return;
      }
    }
    setStep(stepVal);
  };

  const onSubmit = () => {
    setHasTriedSubmit2(true);
    if (isPage2Complete() || Object.values(invalidCheck).includes(true)) {
      return;
    }

    const createForm = {
      name: detailsState.jobName,
      due_date: detailsState.dueDate ? formatDate8601(detailsState.dueDate) : null,
      priority: detailsState.priority.value || null,
      data_request_type_id: detailsState.dataRequestTypeId,
      data_request_type_ids: detailsState.dataRequestSubtypeIds.map((v) => v.value),
      description: detailsState.desc,
      manufacturer_id: detailsState.manufacturer?.value,
      client_id: detailsState.distributor?.value,
      requestor_user_id: detailsState.requestor ? detailsState.requestor.value : null,
      requestor_type: detailsState.requestor_type,
      requesting_company_id: detailsState.requestor ? detailsState.requestor.value : null,
      expected_delivery_format_ids: detailsState.expectedDeliveryFormatIds.map((v) => v.value),
      attachments: detailsState.files.map((v) => v.id).concat(detailsState.links.map((v) => v.id)),
      data_request_link_ids: {
        target: detailsState.linkedDataJob.map((v) => v.target_id),
        source: [],
      },
      data_request_action_type: DATA_JOB_DIRECTION_ENUM[detailsState.dataJobDirection - 1],
      vicarious_job: detailsState[DataJobCreateConstants.VICARIOUS_JOB],
    };
    setDataStatus(ActionStatusConstants.ISBUSY);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/data-requests/`,
      data: createForm,
      onSuccess: (res) => {
        if (isMounted.current) {
          detailsDispatch({ action: DataJobCreateConstants.RESET, payload: initialDetailsState });
          setDataStatus(ActionStatusConstants.SUCCESS);
          history.push({
            pathname: `/data-request/${res.data.id}`,
            state: { isCreateSuccess: true },
          });
        }
      },
      onError: () => {
        if (isMounted.current) {
          setDataStatus(ActionStatusConstants.FAILURE);
        }
        toast.error('There was an error while submitting data');
      },
    });
  };
  useEffect(() => {
    if (hasTriedSubmitPage1) {
      const canSelectHierarchy =
        activeUser?.role === UserRoleConstants.ADMIN ||
        activeUser?.profile?.manufacturer?.has_children;

      dispatchInvalidCheck({
        payload: {
          dataJobDirection: !detailsState.dataJobDirection,
          recipient: activeUser?.role !== UserRoleConstants.ADMIN ? !detailsState.recipient : false,
          distributor:
            canSelectHierarchy || activeUser?.role === UserRoleConstants.SUPPLIER
              ? !detailsState.distributor
              : false,
          manufacturer: canSelectHierarchy ? !detailsState.manufacturer : false,
          requestor: canSelectHierarchy ? !detailsState.requestor : false,
          jobName: !detailsState.jobName,
          desc: !detailsState.desc,
          transformationRequired:
            detailsState.files.filter((f) => f.transformation_required !== null).length !==
            detailsState.files.length,
        },
      });
    }

    if (hasTriedSubmitPage2) {
      dispatchInvalidCheck({
        payload: {
          dataRequestTypeId: !detailsState.dataRequestTypeId,
          dataRequestSubtypeIds:
            detailsState.dataRequestSubtypeIds === null ||
            !detailsState.dataRequestSubtypeIds.length,
        },
      });
    }
  }, [activeUser?.role, detailsState, hasTriedSubmitPage1, hasTriedSubmitPage2]);

  const renderHelmet = () => (
    <Helmet bodyAttributes={{ 'data-page': 'create-data-job' }}>
      <title>Create Data Job</title>
    </Helmet>
  );

  // TODO: Might need to add context for global loader/disabling sections when other stuff is loading.
  const Footer = () => (
    <ProgressBarSubmitFooter
      checkAndDispatch={checkAndDispatch}
      handleSubmit={onSubmit}
      step={step}
      isLoading={dataStatus === ActionStatusConstants.ISBUSY || isFileUploading}
    />
  );

  const determineRender = () => {
    switch (step) {
      case 1:
        return (
          <>
            {renderHelmet()}
            <SmallProgressBar
              style={{ order: 1 }}
              labels={['Step 1: General Information', 'Step 2: Additional Information']}
              current={step}
            />
            <DataJobDirectionSelect
              directionValue={detailsState.dataJobDirection}
              onChange={(v) =>
                dispatchChangeDetails({
                  type: DataJobCreateConstants.DATA_JOB_DIRECTION,
                  payload: v,
                })
              }
              isInvalid={invalidCheck.dataJobDirection}
            />
            <DataJobDetailsSection
              isUploading={isFileUploading}
              setIsUploading={setIsFileUploading}
              dispatchChange={dispatchChangeDetails}
              state={detailsState}
              invalidCheck={invalidCheck}
              Footer={Footer}
            />
          </>
        );
      case 2:
        return (
          <>
            <SmallProgressBar
              style={{ order: 1 }}
              labels={['Step 1: General Information', 'Step 2: Additional Information']}
              current={step}
            />
            <AdditionalInformation
              dispatch={dispatchChangeDetails}
              createForm={detailsState}
              createFormErrors={invalidCheck}
              Footer={Footer}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handlePassThruClick = (setIsPassThruModalVisible) => {
    if (setIsPassThruModalVisible) {
      setIsPassThruModalVisible(true);
    }
  };

  return (
    <>
      {/*
          // TODO: Additional condition to disable unmount prompt while
          and after submitting the form so you can navigate away
        */}
      <UnmountPrompt active={isDirtyDetailsState()} />
      <div className="content content-fluid view-data-job-create">
        <div className="title-block">
          <div className="title">Create Data Job</div>
          <Button
            variant="custom"
            onClick={() => {
              handlePassThruClick(setIsPassThruModalVisible);
            }}
            className="pass-btn"
          >
            Send Pass Through File
          </Button>
        </div>
        {determineRender()}
      </div>
      <PassThruModal
        history={history}
        isVisible={isPassThruModalVisible}
        setIsVisible={setIsPassThruModalVisible}
      />
    </>
  );
});

export { ViewDataJobCreate };
