import React, { useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Overlay } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BackboneLogo from 'assets/img/backbone-logo.svg';
import backboneAIShort from 'assets/img/BackboneAI-Short-logo.svg';
import { CompanyLogo } from 'components/common/CompanyLogo/CompanyLogo';
import { UserProfileCircle } from 'components/common/UserProfileCircle/UserProfileCircle';
import { HeaderNotifications } from 'components/header-notifications/HeaderNotifications';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { UserUtils } from 'helpers/UserUtils';
import * as Utils from 'helpers/Utils';
import * as Actions from 'store/actions/Actions';

import { HeaderAdmin } from './HeaderAdmin';
import { HeaderClient } from './HeaderClient';
import { HeaderSelfService } from './HeaderSelfService';
import { HeaderSupplier } from './HeaderSupplier';
import { RequestDataModalPanel } from './private/RequestDataModalPanel';

const Header = () => {
  const { activeUser } = RootHooks.useActiveUser();

  const dispatch = useDispatch();

  const location = useLocation();

  const isUserLoggedIn = !!activeUser?.email?.length;
  const userRole = activeUser?.role ?? null;
  const clientID = activeUser?.profile?.client?.id ?? null;
  const manufID = activeUser?.profile?.manufacturer?.id ?? null;
  const isUserReadOnly = UserUtils.isReadOnly(activeUser);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const userMenuAnchor = useRef();

  const getRootPathItem = () => {
    const pathArray = location.pathname ? location.pathname.split('/') : null;
    const rootPath = pathArray && pathArray[1] ? pathArray[1] : '';
    return rootPath;
  };

  const doUserLogout = () => {
    dispatch(Actions.submitUserLogout());
    setIsUserMenuOpen(false);
  };

  const renderUserLabel = () => {
    let userLabel = null;

    const username = activeUser?.email ?? null;
    if (username) {
      const name = Utils.joinNameToStr(
        activeUser?.first_name ?? null,
        activeUser?.last_name ?? null
      );

      userLabel = name?.length ? `${name} (${username})` : username;
    }

    return userLabel;
  };

  const getLogo = (clientID, manufID) => {
    if (Number.isInteger(clientID)) {
      return (
        <CompanyLogo
          logoSrc={activeUser?.profile?.client?.logo ?? null}
          id={clientID}
          companyType={UserRoleConstants.DISTRIBUTOR}
        />
      );
    }
    if (Number.isInteger(manufID)) {
      return (
        <CompanyLogo
          logoSrc={activeUser?.profile?.manufacturer?.logo ?? null}
          id={manufID}
          companyType={UserRoleConstants.MANUFACTURER}
        />
      );
    }
    return <CompanyLogo className="logo-backbone-small" logoSrc={backboneAIShort} />;
  };

  const renderUserHeader = (userRole) => {
    switch (userRole) {
      case UserRoleConstants.SUPPLIER:
        return <HeaderSupplier getRootPathItem={getRootPathItem} location={location} />;
      case UserRoleConstants.CLIENT:
        return <HeaderClient getRootPathItem={getRootPathItem} />;
      case UserRoleConstants.ADMIN:
        return <HeaderAdmin location={location} getRootPathItem={getRootPathItem} />;
      case UserRoleConstants.SELF_SERVICE:
        return <HeaderSelfService location={location} getRootPathItem={getRootPathItem} />;
      default:
        return null;
    }
  };

  const renderRequestDataButton = (userRole) => {
    if (isUserReadOnly) {
      return null;
    }
    switch (userRole) {
      case UserRoleConstants.SUPPLIER:
        return (
          <RequestDataModalPanel>
            {({ setIsVisible }) => (
              <button
                type="button"
                className="btn btn-primary btn-sm btn-request-data"
                onClick={() => setIsVisible(true)}
              >
                Invite Distributors
              </button>
            )}
          </RequestDataModalPanel>
        );
      case UserRoleConstants.CLIENT:
        return (
          <RequestDataModalPanel>
            {({ setIsVisible }) => (
              <button
                type="button"
                className="btn btn-primary btn-sm btn-request-data"
                onClick={() => setIsVisible(true)}
              >
                Invite Manufacturers
              </button>
            )}
          </RequestDataModalPanel>
        );
      case UserRoleConstants.ADMIN:
        return (
          <RequestDataModalPanel>
            {({ setIsVisible }) => (
              <button
                type="button"
                className="btn btn-primary btn-sm btn-request-data"
                onClick={() => setIsVisible(true)}
              >
                Invite Companies
              </button>
            )}
          </RequestDataModalPanel>
        );
      default:
        return null;
    }
  };

  return (
    <header className="hdr">
      <div className="hdr-row hdr-row-top">
        <div className="content content-fluid">
          {isUserLoggedIn ? (
            <div className="hdr-block hdr-block-logo-tmp">{getLogo(clientID, manufID)}</div>
          ) : (
            <div className="hdr-block hdr-block-logo-tmp" />
          )}
          {isUserLoggedIn ? (
            <div className="hdr-block hdr-block-user">
              <div className="user-notifications-wrap">
                <HeaderNotifications userRole={userRole} />
              </div>
              <div
                className="user-profile-circle-wrap"
                onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                ref={userMenuAnchor}
                aria-hidden="true"
              >
                <UserProfileCircle
                  name={Utils.joinNameToStr(
                    activeUser?.first_name ?? null,
                    activeUser?.last_name ?? null
                  )}
                  imageSrc={activeUser?.profile?.picture ?? null}
                  size="md"
                />
              </div>
              <Overlay
                target={userMenuAnchor.current}
                show={isUserMenuOpen}
                placement="bottom-end"
                onHide={() => setIsUserMenuOpen(false)}
                rootClose
                rootCloseEvent="click"
              >
                {({
                  placement,
                  scheduleUpdate,
                  arrowProps,
                  outOfBoundaries,
                  show: _show,
                  ...props
                }) => (
                  <div
                    {...props}
                    className="hdr-user-profile-menu"
                    style={{
                      ...props.style,
                    }}
                  >
                    <ul className="user-menu-items">
                      <li
                        className={`user-menu-item user-menu-item-title${
                          userRole ? ` role-${userRole.toLowerCase()}` : ''
                        }`}
                      >
                        <span>{renderUserLabel()}</span>
                      </li>
                      <li className="user-menu-item user-menu-item-products">
                        <span className="link" onClick={doUserLogout} aria-hidden="true">
                          <span className="icon">
                            <FontAwesomeIcon icon={['far', 'sign-out']} />
                          </span>
                          <span className="label">Logout</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </Overlay>
            </div>
          ) : null}
          <div className="hdr-block hdr-block-logo-backbone">
            <img className="logo-backbone" src={BackboneLogo} alt="Backbone" />
          </div>
        </div>
      </div>
      {isUserLoggedIn ? (
        <div className="hdr-row hdr-row-nav">
          <div className="content content-fluid">
            <div className="hdr-block hdr-block-main-menu">
              <nav className="main-menu">
                <ul className="main-menu-items">{renderUserHeader(userRole)}</ul>
              </nav>
              <div className="request-data">{renderRequestDataButton(userRole)}</div>
            </div>
          </div>
        </div>
      ) : null}
    </header>
  );
};

export { Header };
