import './Bots.scss';

import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const BotInformationalCard = ({ bot, ran = false }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => setIsFlipped(!isFlipped)}
      onClick={() => setIsFlipped(!isFlipped)}
      className={classNames('bot-informational-card', {
        'bot-informational-card__ran': ran,
        'bot-informational-card--flipped': isFlipped,
      })}
    >
      {isFlipped ? (
        bot.description
      ) : (
        <>
          <FontAwesomeIcon className="cubes-icon" icon={['far', 'cubes']} />
          <span>{bot.name}</span>
        </>
      )}
    </div>
  );
};

export { BotInformationalCard };
