import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import { useLocation } from 'react-router';

import * as ApiCalls from 'api/ApiCalls';
import { FileSelector } from 'components/databots/bots/common/FileSelector/FileSelector';
import { UploadBotFile } from 'components/databots/bots/common/UploadBotFile/UploadBotFile';
import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import './GapAnalysisFifBot.scss';

import { HeaderSelector } from './private/HeaderSelector';

/**
 * GAP Analysis FIF bot
 *
 * @see {@link GapAnalysisFifBot}
 */
const GapAnalysisFifBot = ({
  botStatusId,
  slug,
  status,
  handleRunBot,
  handleCancelBot,
  additionalData,
  setConfigData,
}) => {
  // TODO: In a future iteration, maybe standardize all bot context objects as props
  // (context, config, additionalData, setters, runners, etc.)
  const location = useLocation();
  const dataJobId = queryString.parse(location?.search).data_request_id;

  const isMounted = useIsMounted();

  // Selectable lists of primary/secondary files
  const [allFiles, setAllFiles] = useState(null);
  const [primFilesList, setPrimFilesList] = useState(null);
  const [secFilesList1, setSecFilesList1] = useState(null);
  const [secFilesList2, setSecFilesList2] = useState(null);

  // File selections
  const [selPrimFile, setSelPrimFile] = useState(null);
  const [selSecFile1, setSelSecFile1] = useState(null);
  const [selSecFile2, setSelSecFile2] = useState(null);

  // Header column lists
  const [primHeadersList, setPrimHeadersList] = useState(null);
  const [secHeadersList1, setSecHeadersList1] = useState(null);
  const [secHeadersList2, setSecHeadersList2] = useState(null);

  // Header column selections
  const [selPrimHeader, setSelPrimHeader] = useState(null);
  const [selSecHeader1, setSelSecHeader1] = useState(null);
  const [selSecHeader2, setSelSecHeader2] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // Reset file lists on props change
  useEffect(() => {
    setAllFiles(additionalData?.attachments ?? null);
    setPrimFilesList(additionalData?.attachments ?? null);
    setSecFilesList1(additionalData?.attachments ?? null);
    setSecFilesList2(additionalData?.attachments ?? null);
  }, [additionalData?.attachments]);

  // Reset secondary lists when primary list changes
  useEffect(() => {
    if (allFiles?.length) {
      setSecFilesList1(allFiles.filter((file) => file !== selPrimFile));
      setSecFilesList2(allFiles.filter((file) => file !== selPrimFile));
    }
    setSelSecFile1(null);
    setSelSecFile2(null);
  }, [selPrimFile, allFiles]);

  // Reset each secondary list depending on the other selection
  useEffect(() => {
    if (allFiles?.length) {
      setSecFilesList1(allFiles.filter((file) => file !== selPrimFile && file !== selSecFile2));
    }
  }, [selPrimFile, selSecFile2, allFiles]);

  useEffect(() => {
    if (allFiles?.length) {
      setSecFilesList2(allFiles.filter((file) => file !== selPrimFile && file !== selSecFile1));
    }
  }, [selPrimFile, selSecFile1, allFiles]);

  // Load column headers per file.
  // - If file obj has an ID(original file), retrieve headers from BE
  // - If file obj has a header list(uploaded secondary), use that
  const doLoadHeaders = useCallback(
    (fileObj, setHeadersListFunc, setSelHeaderFunc) => {
      setSelHeaderFunc(null);

      if (fileObj?.id) {
        setIsLoading(true);

        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/data-requests/attachment/${fileObj.id}/headers`,
          onSuccess: (res) => {
            if (isMounted.current) {
              if (res?.data?.headers?.length) {
                setHeadersListFunc(res.data.headers.map((item) => item));
              } else {
                setHeadersListFunc(null);
              }
            }
          },
          onEnd: () => setIsLoading(false),
        });
      } else if (fileObj?.headers?.length) {
        setHeadersListFunc(fileObj?.headers);
      } else {
        setHeadersListFunc(null);
      }
    },
    [isMounted]
  );

  // Load primary column headers for selected file
  useEffect(() => {
    doLoadHeaders(selPrimFile, setPrimHeadersList, setSelPrimHeader);
  }, [selPrimFile, doLoadHeaders]);

  // Load secondary column headers for selected file 1
  useEffect(() => {
    doLoadHeaders(selSecFile1, setSecHeadersList1, setSelSecHeader1);
  }, [selSecFile1, doLoadHeaders]);

  // Load secondary column headers for selected file 2
  useEffect(() => {
    doLoadHeaders(selSecFile2, setSecHeadersList2, setSelSecHeader2);
  }, [selSecFile2, doLoadHeaders]);

  // Update bot config on form changes
  useEffect(() => {
    const botConfigObj = {
      primary_file_data: null,
      other_files_data: [],
    };

    if (selPrimFile?.location && selPrimHeader) {
      botConfigObj.primary_file_data = {
        container_name: selPrimFile?.location,
        file_name: selPrimFile?.name,
        column_to_compare: selPrimHeader,
      };
    }

    if (selSecFile1?.location && selSecHeader1) {
      botConfigObj.other_files_data.push({
        container_name: selSecFile1?.location,
        file_name: selSecFile1?.name,
        column_to_compare: selSecHeader1,
      });
    }
    if (selSecFile2?.location && selSecHeader2) {
      botConfigObj.other_files_data.push({
        container_name: selSecFile2?.location,
        file_name: selSecFile2?.name,
        column_to_compare: selSecHeader2,
      });
    }

    setConfigData(botConfigObj);
  }, [
    setConfigData,
    selPrimFile?.location,
    selPrimFile?.name,
    selPrimHeader,
    selSecFile1?.location,
    selSecFile1?.name,
    selSecHeader1,
    selSecFile2?.location,
    selSecFile2?.name,
    selSecHeader2,
  ]);

  // Run handler assembles config object and triggers bot run
  const onRunBot = () => {
    handleRunBot(false, `/data-request/${dataJobId}`);
  };

  const renderBotBody = () => (
    <>
      <section className="primary-file-selection">
        <div className="title">Step 1: Select Primary File</div>
        <div className="content">
          <FileSelector
            selectedFile={selPrimFile}
            filesList={primFilesList}
            setSelectedFile={setSelPrimFile}
            disabled={isLoading}
          />
          <UploadBotFile
            saveToDataJob
            modalTitle="Upload Primary File"
            dataJobId={dataJobId}
            onUploadedFiles={(uploadedFiles) => {
              if (uploadedFiles?.length) {
                setPrimFilesList([...primFilesList, ...uploadedFiles]);
                setAllFiles([...allFiles, ...uploadedFiles]);
                setSelPrimFile(uploadedFiles[0]);
              }
            }}
            disabled={isLoading}
          />
        </div>
      </section>

      <section className="secondary-file-selection">
        <div className="title">Step 2: Select Secondary File(s)</div>
        <div className="content">
          <FileSelector
            selectedFile={selSecFile1}
            filesList={secFilesList1}
            setSelectedFile={setSelSecFile1}
            disabled={isLoading || !selPrimFile}
          />

          <FileSelector
            selectedFile={selSecFile2}
            filesList={secFilesList2}
            setSelectedFile={setSelSecFile2}
            disabled={isLoading || !selPrimFile}
          />
          <UploadBotFile
            saveToDataJob
            modalTitle="Upload Secondary File"
            dataJobId={dataJobId}
            onUploadedFiles={(uploadedFiles) => {
              if (uploadedFiles?.length) {
                setSecFilesList1([...secFilesList1, ...uploadedFiles]);
                setSecFilesList2([...secFilesList2, ...uploadedFiles]);
                setAllFiles([...allFiles, ...uploadedFiles]);
                setSelSecFile1(uploadedFiles[0]);
                setSelSecFile2(null);
              }
            }}
            disabled={isLoading || !selPrimFile}
          />
        </div>
      </section>

      <section className="header-selection">
        <div className="title">
          Step 3: Select the primary attribute column you would like to match.
        </div>
        <div className="note">*Only the first sheet of the file will be analyzed.</div>
        <div className="content">
          <HeaderSelector
            selectorLabel="Primary File"
            fileLabel={selPrimFile?.original_name}
            selectedHeader={selPrimHeader}
            headersList={primHeadersList}
            headerSetterFunc={setSelPrimHeader}
            disabled={isLoading || !selPrimFile}
          />
          <div className="icon-divider">
            <span className="icon">
              <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
            </span>
          </div>
          <HeaderSelector
            selectorLabel="Secondary File 1"
            fileLabel={selSecFile1?.original_name}
            selectedHeader={selSecHeader1}
            headersList={secHeadersList1}
            headerSetterFunc={setSelSecHeader1}
            disabled={isLoading || !selSecFile1}
          />
          <div className="icon-divider">
            <span className="icon">
              <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
            </span>
          </div>
          <HeaderSelector
            selectorLabel="Secondary File 2"
            fileLabel={selSecFile2?.original_name}
            selectedHeader={selSecHeader2}
            headersList={secHeadersList2}
            headerSetterFunc={setSelSecHeader2}
            disabled={isLoading || !selSecFile2}
          />
        </div>
      </section>
    </>
  );

  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
        {
          label: 'Total Products',
          value: additionalData?.total_products ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      title="Gap Analysis Databot"
      subtitle="Start your data automation journey by comparing files to see where the gap in products lie. Simply upload a primary file and secondary files, select what you would like to cross-reference, and let our databots do the rest."
      bodyContent={renderBotBody()}
      requiresApproval
      preRunValidate={() => {
        if (!(selPrimFile?.location && selPrimHeader)) {
          toast.error('Please select primary file and attribute column.');
          return false;
        }
        if (!((selSecFile1 && selSecHeader1) || (selSecFile2 && selSecHeader2))) {
          toast.error('Please select at least one secondary file and an attribute column for it.');
          return false;
        }
        return true;
      }}
      onRun={onRunBot}
      onCancel={() => handleCancelBot(`/data-request/${dataJobId}`)}
      confirmDialogTitle="Run Gap Analysis Databot?"
      confirmDialogBody={
        <>
          <p>You are about to:</p>
          Run the Gap Analysis Databot
        </>
      }
    />
  );
};

export { GapAnalysisFifBot };
