import React, { useCallback, useEffect, useState } from 'react';

import _get from 'lodash/get';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import * as ApiCalls from 'api/ApiCalls';
import noNotificationImage from 'assets/img/no-notifications.svg';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { BotDetails } from 'components/databots/BotDetails/BotDetails';
import FlashAssessmentAttrItem from 'components/flash-assessment/FlashAssessmentAttrItem';
import FlashAssessmentOverview from 'components/flash-assessment/FlashAssessmentOverview/FlashAssessmentOverview';
import FlashAssessmentSuggestions from 'components/flash-assessment/FlashAssessmentSuggestions/FlashAssessmentSuggestions';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { RootHooks } from 'helpers/RootHooks';

const _component = ({ id }) => {
  const [data, setData] = useState(null);
  const [dataStatus, setDataStatus] = useState(ActionStatusConstants.INITIAL);
  const [percentComplete, setPercentComplete] = useState('');
  const [countComplete, setCountComplete] = useState('');
  const { activeUser } = RootHooks.useActiveUser();

  const loadData = useCallback(() => {
    if (id < 0) {
      return;
    }
    setDataStatus(ActionStatusConstants.ISBUSY);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/flash-assessments/${id}`,
      onSuccess: (res) => {
        setDataStatus(ActionStatusConstants.SUCCESS);
        const _data = _get(res, 'data') || null;
        setData(_data);
        setPercentComplete(`${calculatePercentComplete(_data.attributes)}%`); // cannot calculate based on data since resetting
      },
      onError: () => {
        setDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }, [id]);

  const calculatePercentComplete = (attributes) => {
    const completedSuggestions = attributes.filter(
      (item) => item.suggestion && item.suggestion.completed === true
    ).length;
    setCountComplete(completedSuggestions);
    return parseInt(
      (completedSuggestions / attributes.filter((item) => item.suggestion).length) * 100
    );
  };

  useEffect(() => {
    loadData();
  }, [id, loadData]);

  const renderData = () => {
    if (!(data && data.id)) {
      return null;
    }

    const generalAttributes = data.attributes
      ? data.attributes.filter((item) => item.attribute && item.attribute.type === 1)
      : null;
    const detailedAttributes = data.attributes
      ? data.attributes.filter((item) => item.attribute && item.attribute.type === 2)
      : null;

    const smartSuggestions = data.attributes
      ? data.attributes
          .map((item) => {
            if (
              item.suggestion &&
              item.suggestion.suggestion &&
              item.attribute &&
              item.attribute.name
            ) {
              return item;
            }
            return '';
          })
          .filter((item) => item)
      : null;

    const suggestionsCount = smartSuggestions.length;

    const handleCheckBox = (event) => {
      setDataStatus(ActionStatusConstants.ISBUSY);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.PUT,
        urlPath: `/flash-assessments/suggestions/${event.target.id}/complete/`,
        data: { completed: event.target.checked },
        onSuccess: () => {
          loadData();
        },
        onError: () => {
          setDataStatus(ActionStatusConstants.FAILURE);
        },
      });
      setDataStatus(ActionStatusConstants.SUCCESS);
    };

    return (
      <Container fluid className="flash-assessment-grid pl-0 pr-0 mt-4">
        <Row>
          <Col sm={12}>
            <FlashAssessmentOverview data={data} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={6}>
            <section className="section-general-attrs">
              <div className="title">Attributes Analysis 1</div>
              <div className="content">
                {generalAttributes ? (
                  <ul className="attr-list">
                    {generalAttributes.map((item, index) => (
                      <li key={index}>
                        <FlashAssessmentAttrItem data={item} />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No Attributes Analysis 1</p>
                )}
              </div>
            </section>
          </Col>
          <Col sm={6}>
            <section className="section-detailed-attrs">
              <div className="title">Attributes Analysis 2</div>
              <div className="content">
                {detailedAttributes ? (
                  <ul className="attr-list">
                    {detailedAttributes.map((item, index) => (
                      <li key={index}>
                        <FlashAssessmentAttrItem data={item} />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No Attributes Analysis 2</p>
                )}
              </div>
            </section>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col sm={12}>
            <FlashAssessmentSuggestions
              dataStatus={dataStatus}
              smartSuggestions={smartSuggestions}
              percentComplete={percentComplete}
              countComplete={countComplete}
              suggestionsCount={suggestionsCount}
              handleCheckBox={(event) => handleCheckBox(event)}
            />
          </Col>
        </Row>

        <BotDetails
          heading={
            data.data_request.has_transformed_file
              ? 'How We Improved Your Score'
              : 'Bots In Progress:'
          }
          subheading={`We ${
            data.data_request.has_transformed_file ? 'ran' : 'are running'
          } the following bots to your file(s) to help improve the overall data quality
          score. Click on the “bot” to learn more.`}
          manual={data.manual === true}
          ran={data.data_request.has_transformed_file}
          automated={data.manual === false}
        />
      </Container>
    );
  };

  const assessmentOrg = _get(data, 'manufacturer.name');
  const userOrg = activeUser?.profile?.manufacturer;

  const renderTitle = () =>
    userOrg?.has_children && userOrg?.name !== assessmentOrg ? (
      <div>
        <h1>Flash Assessment: {userOrg?.name}</h1>
        <h3>Brand: {assessmentOrg}</h3>
      </div>
    ) : (
      <h1>Flash Assessment: {assessmentOrg}</h1>
    );

  return (
    <div className="content content-fluid view-flash-assessment">
      <Helmet bodyAttributes={{ 'data-page': 'view-flash-assessment' }}>
        <title>Flash Assessment: {assessmentOrg || 'N/A'}</title>
      </Helmet>

      {data && data.id ? (
        <div>
          {renderTitle()}
          {renderData()}
        </div>
      ) : dataStatus === ActionStatusConstants.ISBUSY ? (
        <div className="top-status">
          <LoadingSpinner style={{ fontSize: '1.5em', marginRight: '0.5em' }} /> Loading data...
        </div>
      ) : null}
      {[ActionStatusConstants.SUCCESS, ActionStatusConstants.FAILURE].includes(dataStatus) &&
      !(data && data.id) ? (
        <div className="not-found">
          <img className="not-found-image" src={noNotificationImage} alt="Backbone" />
          <h3 className="not-found-label">The requested item does not exist.</h3>
        </div>
      ) : null}
    </div>
  );
};

export default _component;
