import React, { useCallback, useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { BotDetails } from 'components/databots/BotDetails/BotDetails';
import { AssessmentAdminPanel } from 'components/ingestion-assessment/AssessmentAdminPanel/AssessmentAdminPanel';
import AttributeAnalytics from 'components/ingestion-assessment/AttributeAnalytics/AttributeAnalytics';
import { IngestionAssessmentOverview } from 'components/ingestion-assessment/IngestionAssessmentOverview/IngestionAssessmentOverview';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';

/**
 * Data Ingestion Assessment screen
 *
 * @param {Object} param Props
 * @param {Number} param.attachmentId
 *
 * @returns Render
 */
const ViewDataIngestionAssessment = ({ attachmentId }) => {
  const { activeUser } = RootHooks.useActiveUser();
  const userRole = activeUser?.role ?? null;
  const [attachmentData, setAttachmentData] = useState({});
  const [requestData, setRequestData] = useState({});
  const [flashAssessment, setFlashAssessment] = useState(null);
  const [requestorType, setRequestorType] = useState(null);
  const [hasProducts, setHasProducts] = useState(true);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [modalFooter, setModalFooter] = useState(null);

  const history = useHistory();

  const getAttachmentData = useCallback(() => {
    const getrequestData = (jobId) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/data-requests/${jobId}`,
        onSuccess: (res) => {
          setRequestData(res.data);
          setRequestorType(
            res.data.requestor_user.profile.client !== null ? 'client' : 'manufacturer'
          );
          setHasProducts(res.data.manufacturer?.has_products);
        },
      });
    };

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/attachments/${attachmentId}`,
      onSuccess: (res) => {
        setAttachmentData(res.data);
        getrequestData(res.data.data_request);
      },
    });

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/flash-assessments/attachment/${attachmentId}`,
      onSuccess: (res) => {
        setFlashAssessment(res.data);
      },
    });
  }, [attachmentId]);

  const loadSmartSuggestionsData = useCallback((flashAssessmentId) => {
    if (flashAssessmentId) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/flash-assessments/${flashAssessmentId}/suggestions/`,
      });
    }
  }, []);

  const loadSmartSuggestionsAttributesData = useCallback(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/flash-assessments/attributes/`,
    });
  }, []);

  useEffect(() => {
    getAttachmentData();
    loadSmartSuggestionsAttributesData();
  }, [getAttachmentData, loadSmartSuggestionsAttributesData]);

  useEffect(() => {
    loadSmartSuggestionsData(flashAssessment?.id);
  }, [loadSmartSuggestionsData, flashAssessment]);

  function getRequestorName() {
    if (requestorType && requestorType === 'client') {
      return requestData?.requestor_user?.profile?.client?.name;
    }
    return requestData?.requestor_user?.profile?.manufacturer?.name;
  }

  const goToRows = () => {
    history.push(`/data-ingestion/rows/${attachmentData.id}`);
  };

  const ModalRestart = () => {
    const body = (
      <div>
        <p>
          <b>Warning: </b> You are about to go back to step 1: Verifying Rows.
        </p>
      </div>
    );
    const footer = (
      <div>
        <Button variant="outline-dark" onClick={() => setModalVisible(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => goToRows()}>
          Continue
        </Button>
      </div>
    );
    setModalBody(body);
    setModalFooter(footer);
    setModalVisible(true);
  };

  const onStartIngestionSuccess = () => {
    setModalVisible(false);
    toast.success("Ingestion Started - we'll notify you as soon as it's finished.");
  };

  const startIngestion = () => {
    console.debug('Starting data ingestion...');
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/data-ingestion/process-data`,
      data: {
        manufacturer_id: requestData.manufacturer.id,
        file_name: attachmentData.name,
        data_request_id: requestData.id,
      },
      onSuccess: () => {
        onStartIngestionSuccess();
      },
      onError: (res) => {
        toast.error('Something went wrong with ingestion.');
        console.error(res);
      },
    });
  };

  const modalStartIngestion = () => {
    const body = (
      <div>
        <p>This will trigger the automatic data ingestion. Proceed?</p>
      </div>
    );
    const footer = (
      <div className="row">
        <Button variant="outline-dark mr-2" onClick={() => setModalVisible(false)}>
          Cancel
        </Button>
        <Button className="secondary-btn" onClick={() => startIngestion()}>
          Continue
        </Button>
      </div>
    );
    setModalBody(body);
    setModalFooter(footer);
    setModalVisible(true);
  };

  return (
    <DataIngestionContext.Provider
      value={{
        attachmentData,
        requestData,
      }}
    >
      <>
        {modalVisible === true ? (
          <div id="modal-container">
            <ModalPanel
              isVisible={modalVisible}
              setIsVisible={setModalVisible}
              showCancel
              body={modalBody}
              footer={modalFooter}
            />
          </div>
        ) : null}
        <div className="content content-fluid view-ingestion-assessment">
          <Helmet bodyAttributes={{ 'data-page': 'view-ingestion-assessment' }}>
            <title>Data Assessment: {attachmentData.name || 'N/A'}</title>
          </Helmet>
          <span className="page-title">Assessment {getRequestorName()}</span>
          {userRole === UserRoleConstants.ADMIN ? (
            <button
              type="button"
              onClick={() => ModalRestart()}
              className="btn btn-secondary restart-button"
            >
              Restart Assessment
            </button>
          ) : null}
          <div>
            <IngestionAssessmentOverview
              attachmentData={attachmentData}
              requestData={requestData}
              flashAssessmentData={flashAssessment}
            />
          </div>
          <div>
            <BotDetails
              heading="How Data Bots Improved your Score"
              subheading={`Databots were used to automatically improve the overall data quality score. Click on the
          "databot" to learn more.`}
              manual={false}
              automated
              ran
            />
          </div>
          <div>{attachmentId ? <AttributeAnalytics id={attachmentId} /> : null}</div>
          {userRole === UserRoleConstants.ADMIN ? (
            <AssessmentAdminPanel
              userRole={userRole}
              modalStartIngestion={() => modalStartIngestion()}
              hasProducts={hasProducts}
              attachmentData={attachmentData}
            />
          ) : null}
        </div>
      </>
    </DataIngestionContext.Provider>
  );
};

export { ViewDataIngestionAssessment };
