import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';

import { useIsMounted } from '../../../helpers/useIsMounted';
import { getIconWithPopover, wrapWithTooltip } from '../../../helpers/Utils';
import variables from '../../../scss/vars.scss';
import { StyledMultiselect } from '../../common/StyledMultiselect/StyledMultiselect';
import { IdsAssessmentConfigForm } from './_ids_configs/IdsAssessmentConfigForm';
import { IdsEnhancementConfigForm } from './_ids_configs/IdsEnhancementConfigForm';
import { IdsExternalDeliveryForm } from './_ids_configs/IdsExternalDeliveryForm';
import { CustomBotConfigUtils } from './CustomBotConfigUtils';
import { IdsConstants } from './IdsConstants';

import './IdsConfigSelector.scss';

const IdsConfigSelector = ({
  configuration,
  setConfiguration,
  isLoading,
  setValidationResult,
  type = 'assessment',
}) => {
  const isMounted = useIsMounted();
  const [originalConfig] = useState(cloneDeep(configuration));
  const [customSelectedItem, setCustomSelectedItem] = useState(null);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const [selectedConfigItem, setSelectedConfigItem] = useState(null);
  const [configOptions, setConfigOptions] = useState(null);
  const [isConfigListLoading, setIsConfigListLoading] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    if (!['assessment', 'enhancement'].includes(type)) {
      return;
    }
    setIsConfigListLoading(true);
    CustomBotConfigUtils.loadIdsConfigs({
      slugs: [
        IdsConstants.IDS_CONFIG_TYPES[
          type === 'assessment' ? 'ids_initial_assessment' : 'ids_enhancement'
        ].slug,
      ],
      onSuccess: (res) => {
        const _defaultConfigItem = {
          value: cloneDeep(originalConfig),
          label: 'Default',
        };
        let _selectedConfigItem;
        setConfigOptions([
          _defaultConfigItem,
          ...res?.data?.map((it) => {
            const item = { value: it, label: it.name };
            if (it.is_default) _selectedConfigItem = item;
            return item;
          }),
        ]);
        setSelectedConfigItem(_selectedConfigItem ?? _defaultConfigItem);
      },
      onEnd: () => {
        setIsConfigListLoading(false);
      },
    });
  }, [originalConfig, type, isMounted]);

  useEffect(() => {
    if (selectedConfigItem) {
      setConfiguration(selectedConfigItem.value);
      if (!selectedConfigItem?.isCustom) {
        setIsEditEnabled(false);
        setLastSelectedItem(selectedConfigItem);
        setCustomSelectedItem({
          value: { configuration: cloneDeep(selectedConfigItem?.value?.configuration) },
          label: 'Custom',
          isCustom: true,
        });
      }
    }
  }, [setConfiguration, selectedConfigItem]);

  useEffect(() => {
    if (isEditEnabled) {
      setSelectedConfigItem(customSelectedItem);
    } else {
      setSelectedConfigItem(lastSelectedItem);
    }
  }, [lastSelectedItem, customSelectedItem, isEditEnabled]);

  return (
    <>
      {type !== 'external_delivery' ? (
        <section className="custom-bot-config-selector">
          <div className="custom-bot-config-selector-header">
            <div className="title">
              <span>Presets</span>{' '}
              {getIconWithPopover({
                content: (
                  <>
                    Select a preset configuration. You can also <strong>edit</strong> the preset
                    configuration using the <u>button to the right</u>.
                  </>
                ),
                iconProp: ['far', 'question-circle'],
              })}
            </div>
          </div>
          <div className="custom-bot-config-selector-body">
            <div className="custom-bot-config-selector-body-select">
              <StyledMultiselect
                id={`ids-${type}-config-select`}
                options={configOptions}
                values={selectedConfigItem}
                isMulti={false}
                getOptionValue={(option) => option.value}
                setOnChange={setSelectedConfigItem}
                selectStyle={{
                  option: (styles, { data }) => {
                    return {
                      ...styles,
                      fontWeight: data?.value?.is_default ? 'bold' : undefined,
                      color: !data?.value?.id ? variables.colors_designGrey550 : undefined,
                    };
                  },
                }}
              />
            </div>

            {wrapWithTooltip({
              content: <p>{isEditEnabled ? 'Cancel Edit' : 'Edit'}</p>,
              target: (
                <button
                  type="button"
                  className={classNames(
                    'btn',
                    'btn-primary',
                    'btn-edit',
                    isEditEnabled ? 'enabled' : undefined
                  )}
                  onClick={() => setIsEditEnabled(!isEditEnabled)}
                >
                  {isEditEnabled ? (
                    <FontAwesomeIcon icon={['fas', 'edit']} />
                  ) : (
                    <FontAwesomeIcon icon={['fal', 'edit']} />
                  )}
                </button>
              ),
            })}
          </div>
        </section>
      ) : undefined}
      {type === 'assessment' ? (
        <IdsAssessmentConfigForm
          config={configuration.configuration}
          setCustomConfig={(cfg) => setConfiguration({ ...configuration, configuration: cfg })}
          isLoading={!isEditEnabled || isLoading || isConfigListLoading}
          setValidationResult={setValidationResult}
        />
      ) : undefined}
      {type === 'enhancement' ? (
        <IdsEnhancementConfigForm
          config={configuration.configuration}
          setCustomConfig={(cfg) => setConfiguration({ ...configuration, configuration: cfg })}
          isLoading={!isEditEnabled || isLoading || isConfigListLoading}
          setValidationResult={setValidationResult}
        />
      ) : undefined}
      {type === 'external_delivery' ? (
        <IdsExternalDeliveryForm
          config={configuration.configuration}
          setCustomConfig={(cfg) => setConfiguration({ ...configuration, configuration: cfg })}
          isLoading={isLoading}
          setValidationResult={setValidationResult}
        />
      ) : undefined}
    </>
  );
};

export { IdsConfigSelector };
