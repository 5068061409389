import React from 'react';

import _isEqual from 'lodash/isEqual';

import { getIconWithPopover } from '../../../helpers/Utils';
import { CustomBotConfigField } from './CustomBotConfigField';

/**
 * Iterates through static custom config model and generates form split in groups
 *
 * @param {object} customConfig Current customConfig state
 * @param {function} setCustomConfig onChange handler for customConfig
 * @param {boolean} isLoading Loading state
 * @param {object} configGroups The config groups object definitions
 * @param {object} configModel The config object definitions
 *
 * @return render
 */
const CustomBotConfigForm = React.memo(
  ({
    customConfig,
    setCustomConfig,
    isLoading,
    configGroups,
    configModel,
    isAnonymous = false,
  }) => (
    <div className="custom-config-wrap">
      {Object.values(configGroups).map((groupItem, groupIndex) => (
        // Iterating through custom config groups(assessment, enhancement, additional params)
        <div
          className={`custom-config-block custom-config-block-${groupItem.key}`}
          key={groupIndex}
        >
          <div className="title">
            <span>{groupItem.label}</span>
            {getIconWithPopover({
              iconProp: ['far', 'question-circle'],
              content: groupItem.helperText,
              title: groupItem.title || groupItem.label,
            })}
          </div>
          <ul className="config-items">
            {Object.values(configModel)
              // Iterating through fields in the custom config model, filtered by the current group.
              .filter((modelItem) => modelItem.group === groupItem.key)
              .map((modelItem, modelIndex) => (
                <li key={modelIndex}>
                  <CustomBotConfigField
                    customConfig={customConfig}
                    model={modelItem}
                    value={modelItem.get(customConfig)}
                    onChange={setCustomConfig}
                    invalidMsg={modelItem.getErrMsg?.(customConfig)}
                    disabled={!!modelItem.isDisabled?.(customConfig) || isLoading || isAnonymous}
                  />
                </li>
              ))}
          </ul>
        </div>
      ))}
    </div>
  ),
  (prevProps, nextProps) => _isEqual(prevProps, nextProps)
);

export { CustomBotConfigForm };
