import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import queryString from 'query-string';
import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router';

import * as ApiCalls from 'api/ApiCalls';
import { SmallSelectFileSection } from 'components/databots/bots/common/SmallSelectFileSection/SmallSelectFileSection';
import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import { HeaderSelector } from './private/HeaderSelector';
import './ShortDescriptionCreatorFifBot.scss';

/**
 * @see {@link ShortDescriptionCreatorBot}
 */
const ShortDescriptionCreatorFifBot = ({
  botStatusId,
  slug,
  status,
  handleRunBot,
  handleCancelBot,
  additionalData,
  setConfigData,
}) => {
  const location = useLocation();
  const dataJobId = queryString.parse(location?.search).data_request_id;

  const isMounted = useIsMounted();

  // Selectable lists of files
  const [filesList, setFilesList] = useState(null);

  // File selection
  const [selFile, setSelFile] = useState(null);

  // Header column list
  const [headersList, setHeadersList] = useState(null);

  // Header columns selection
  const [selHeaders, setSelHeaders] = useState(null);

  // Character limit input and validation
  const [charLimit, setCharLimit] = useState(15);
  const [charLimitValid, setCharLimitValid] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  // Reset file list on props change
  useEffect(() => {
    setFilesList(additionalData?.attachments ?? null);
  }, [JSON.stringify(additionalData?.attachments)]);

  // Validate char limit field on change
  useEffect(() => {
    const intV = parseInt(charLimit);
    if (Number.isFinite(intV) && intV >= 15 && intV <= 150) {
      setCharLimitValid(true);
    } else {
      setCharLimitValid(false);
    }
  }, [charLimit]);

  // Load column headers for file.
  const doLoadHeaders = useCallback(
    (fileObj, setHeadersListFunc, setSelHeaderFunc) => {
      setHeadersListFunc(null);
      setSelHeaderFunc(null);

      if (fileObj?.id) {
        setIsLoading(true);

        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/data-requests/attachment/${fileObj.id}/headers`,
          onSuccess: (res) => {
            if (isMounted.current) {
              if (res?.data?.headers?.length) {
                setHeadersListFunc(res.data.headers.map((item) => item));
              } else {
                setHeadersListFunc(null);
              }
            }
          },
          onError: () => {
            if (isMounted.current) {
              setHeadersListFunc(null);
            }
          },
          onEnd: () => setIsLoading(false),
        });
      } else {
        setHeadersListFunc(null);
      }
    },
    [isMounted]
  );

  // Load column headers for selected file
  useEffect(() => {
    doLoadHeaders(selFile, setHeadersList, setSelHeaders);
  }, [selFile, doLoadHeaders]);

  // Update bot config on form changes
  useEffect(() => {
    const botConfigObj = {
      char_limit: charLimit,
      file_name: selFile?.name,
      headers: selHeaders,
    };

    setConfigData(botConfigObj);
  }, [setConfigData, selFile?.location, selFile?.name, charLimit, JSON.stringify(selHeaders)]);

  const renderBotBody = () => (
    <>
      <SmallSelectFileSection
        title="Step 1: Select Your File"
        dataJobId={dataJobId}
        selectedFile={selFile}
        setSelectedFile={setSelFile}
        filesList={filesList}
        setFilesList={setFilesList}
        isLoading={isLoading}
      />
      <section className="descr-length-selection">
        <div className="title">
          Step 2: How many characters would you like your short description to be?
        </div>
        <div className="content">
          <div className="char-limit">
            <label className="char-limit-label" htmlFor="char-limit-input">
              <input
                id="char-limit-input"
                name="char-limit-input"
                className={classNames('char-limit-input', {
                  invalid: !charLimitValid,
                })}
                type="number"
                placeholder="Number of characters"
                onChange={(e) => setCharLimit(e.target.value)}
                defaultValue={charLimit}
                min={15}
                max={150}
              />
              {!charLimitValid && (
                <div className="char-limit-invalid">
                  Number of characters must be between 15 and 150.
                </div>
              )}
            </label>
          </div>
        </div>
      </section>

      {!!selFile && (
        <section className="header-selection">
          <div className="title">Step 3: Select column to run short description creator</div>
          <div className="content">
            {headersList?.length && (
              <HeaderSelector
                selectedHeaders={selHeaders}
                headersList={headersList}
                headerSetterFunc={setSelHeaders}
                disabled={isLoading || !selFile}
              />
            )}
            {!isLoading && !headersList?.length && (
              <Alert variant="danger">No available headers for this file</Alert>
            )}
          </div>
        </section>
      )}
    </>
  );

  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
        {
          label: 'Total Products',
          value: additionalData?.total_products ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      title="Short Description Creator"
      subtitle="Take any description attribute and create a custom short description that fits within your desired number of characters."
      bodyContent={renderBotBody()}
      disableActions={{
        run: !(charLimitValid && selFile),
      }}
      requiresApproval
      preRunValidate={() => {
        if (!(selFile?.location && selHeaders?.length)) {
          toast.error('Please select file and at least one attribute column.');
          return false;
        }
        return true;
      }}
      onRun={() => handleRunBot(false, `/data-request/${dataJobId}`)}
      onCancel={() => handleCancelBot(`/data-request/${dataJobId}`)}
      confirmDialogTitle="Run Short Description Creator Databot?"
      confirmDialogBody={
        <>
          <p>You are about to:</p>
          Run the Short Description Creator Databot
        </>
      }
    />
  );
};

export { ShortDescriptionCreatorFifBot };
