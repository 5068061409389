import _cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';

import { JOBTYPES } from 'constants/DataJobDetailsConstants';
import * as QueryStringConstants from 'constants/QueryStringConstants';

const __initFilterState = {
  page: 1,
  page_size: 25,
  sort_field: 'id',
  sort_order: 'desc',
  time_period: null,
  supplier: null,
  distributor: null,
  request_type: null,
  request_subtype: null,
  priority: null,
  status: null,
  show_accepted: true,
  show_completed: true,
  viewed: 'all',
  text_search: null,
  tab: JOBTYPES.DATA_JOBS_ALL,
};

const __getFilterStateFromUrlSearchStr = (searchStr) => {
  let fParams = {};

  const qParams = queryString.parse(searchStr, QueryStringConstants.defaultQueryStringOptions);

  const parseNonZeroInt = (v) => {
    if (v && v.length) {
      const vNum = Number.parseInt(v);
      if (!Number.isNaN(vNum) && vNum >= 1) {
        return vNum;
      }
    }
    return null;
  };

  const parseBool = (v) => {
    if (v === 'true') return true;
    if (v === 'false') return false;
    return null;
  };

  const parseValue = (v, parseNum = true) => {
    if (v && !Array.isArray(v)) return [parseNum ? parseNonZeroInt(v) : `${v}`];

    return v?.length && Array.isArray(v)
      ? v
          .map((item) => (parseNum ? parseNonZeroInt(item) : `${item}`))
          .filter((item) => ![undefined, null, ''].includes(item))
      : null;
  };

  fParams.page = parseNonZeroInt(qParams.page) || 1;
  fParams.page_size = parseNonZeroInt(qParams.page_size) || 25;
  fParams.sort_field = qParams.sort_field?.length ? qParams.sort_field : null;
  fParams.sort_order = ['asc', 'desc'].includes(qParams.sort_order) ? qParams.sort_order : null;
  fParams.time_period = ['7', '14', '30'].includes(qParams.time_period)
    ? Number.parseInt(qParams.time_period)
    : null;

  fParams.supplier = parseValue(qParams.supplier);
  fParams.distributor = parseValue(qParams.distributor);
  fParams.request_type = parseNonZeroInt(qParams.request_type);
  fParams.request_subtype = parseValue(qParams.request_subtype);
  fParams.priority = parseValue(qParams.priority);
  fParams.status = parseValue(qParams.status, false);

  fParams.show_accepted = parseBool(qParams.show_accepted);
  fParams.show_completed = parseBool(qParams.show_completed);
  fParams.viewed = qParams.viewed === 'all' ? 'all' : parseBool(qParams.viewed);
  fParams.text_search = qParams.text_search?.length ? qParams.text_search : null;
  fParams.tab = qParams.tab?.length ? qParams.tab : null;
  fParams = Object.fromEntries(Object.entries(fParams).filter(([k, v]) => k && v !== null));

  fParams = { ...__initFilterState, ...fParams };

  return fParams;
};

const __convertUrlStateToFilterState = (urlFilterState) => {
  return {
    time_period: urlFilterState?.time_period,
    supplier: urlFilterState?.supplier,
    distributor: urlFilterState?.distributor,
    request_type: urlFilterState?.request_type,
    request_subtype: urlFilterState?.request_subtype,
    priority: urlFilterState?.priority,
    status: urlFilterState?.status,
    show_accepted: urlFilterState?.show_accepted,
    show_completed: urlFilterState?.show_completed,
    viewed: urlFilterState?.viewed === 'all' ? 'all' : !!urlFilterState?.viewed,
    text_search: urlFilterState?.text_search,
    tab: urlFilterState?.tab,
  };
};

const __convertUrlStateToDataTableState = (urlFilterState) => {
  return {
    page: urlFilterState?.page,
    page_size: urlFilterState?.page_size,
    sort_field: urlFilterState?.sort_field,
    sort_order: urlFilterState?.sort_order,
  };
};

const __getUrlSearchStrFromFilterState = (filterState, dataTableState) => {
  let qParams = {
    ...__initFilterState,
    ...dataTableState,
    ...filterState,
  };

  qParams = Object.fromEntries(
    Object.entries(qParams).filter(([k, v]) => k && v !== __initFilterState[k])
  );

  const qString = queryString.stringify(qParams, {
    ...QueryStringConstants.defaultQueryStringOptions,
    skipNull: true,
  });

  return qString?.length ? qString : null;
};

const __getApiParamsFromUrlState = (fParams) => {
  const paramNames = [
    'time_period',
    'supplier',
    'distributor',
    'request_type',
    'request_subtype',
    'priority',
    'status',
    'show_accepted',
    'show_completed',
    'viewed',
    'text_search',
    'sort_field',
    'sort_order',
    'tab',
  ];

  let reqParams = _cloneDeep(fParams);
  reqParams = Object.fromEntries(
    Object.entries(reqParams)
      .filter(([k, v]) => {
        return (
          paramNames.includes(k) &&
          !([null, undefined, ''].includes(v) || (Array.isArray(v) && !v.length))
        );
      })
      .map(([k, v]) => {
        switch (k) {
          case 'sort_field':
          case 'sort_order':
            return [`${k}`, v];
          case 'text_search':
            return [`filter_text`, v];
          default:
            return [`filter_${k}`, v];
        }
      })
  );

  reqParams.offset = (fParams.page - 1) * fParams.page_size;
  reqParams.limit = fParams.page_size;

  return reqParams;
};

/**
 * Decorates data jobs list with additional table-related structures.
 *
 * @param {*} data Data jobs list
 * @param {*} onDeleteDataJob Delete handler
 * @param {*} userProfile User profile object
 * @returns Decorated input
 */
const decorateDataJobsData = (data, onDeleteDataJob, userProfile) => {
  const localData = data;
  if (data && data.data_requests && data.data_requests.length) {
    for (let i = 0; i < data.data_requests.length; i++) {
      localData.data_requests[i].onDeleteDataJob = onDeleteDataJob;
      localData.data_requests[i].currentUserProfile = userProfile;
    }
  }

  return localData;
};

export {
  __initFilterState,
  __getFilterStateFromUrlSearchStr,
  __convertUrlStateToFilterState,
  __convertUrlStateToDataTableState,
  __getUrlSearchStrFromFilterState,
  __getApiParamsFromUrlState,
  decorateDataJobsData,
};
