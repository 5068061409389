import React from 'react';

import PropTypes from 'prop-types';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';

/**
 * Default running message template for DatabotConfigPanel.
 *
 * This is just a wrapper template to provide consistent className.
 *
 * Do NOT import this directly. Use it through the main component - <DatabotConfigPanel.Template[name] />
 *
 * @param {element} children Content
 * @return render
 */
const TemplateRunningMessage = ({ children, onCancel }) => {
  return (
    <section className="databot-config-panel-running-message">
      {children || (
        <>
          <div className="running-message">
            <p>
              Working on your data... <LoadingSpinner fast />
            </p>
          </div>
          <div className="running-actions">
            <button type="button" className="btn btn-secondary cancel" onClick={() => onCancel()}>
              Cancel
            </button>
          </div>
        </>
      )}
    </section>
  );
};

TemplateRunningMessage.defaultProps = {
  children: null,
};

TemplateRunningMessage.propTypes = {
  children: PropTypes.node,
};

export { TemplateRunningMessage };
