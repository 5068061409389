import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { BOT_SLUGS } from 'constants/BotConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { isConstantEqual } from 'helpers/Utils';

/**
 * Get mfrs list
 *
 * @param {object} isMounted ref
 * @param {function} setMfrsData Result callback
 * @param {function} setMfrsDataStatus Status callback
 */
const doGetMfrs = ({ isMounted, setMfrsData, setMfrsDataStatus }) => {
  if (isMounted.current) {
    setMfrsDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/parents/manufacturers', // TODO: thin backend strips objects to id+name only
      onSuccess: (res) => {
        if (isMounted.current) {
          setMfrsDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setMfrsData(_data);
        }
      },
      onError: () => {
        setMfrsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get child mfr per selected mfr.
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setChildMfrsData Result callback
 * @param {function} setChildMfrsDataStatus Status callback
 */
const doGetChildMfrs = ({ mfrId, isMounted, setChildMfrsData, setChildMfrsDataStatus }) => {
  if (isMounted.current) {
    setChildMfrsDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/manufacturers/${mfrId}/children`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setChildMfrsDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setChildMfrsData(_data);
        }
      },
      onError: () => {
        setChildMfrsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get mfr object
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setMfrData Result callback
 * @param {function} setMfrDataStatus Status callback
 */
const doGetMfr = ({ mfrId, isMounted, setMfrData, setMfrDataStatus }) => {
  if (isMounted.current) {
    setMfrDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/manufacturers/${mfrId}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setMfrDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setMfrData(_data);
        }
      },
      onError: () => {
        setMfrDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get summary data
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setSummaryData Result callback
 * @param {function} setSummaryDataStatus Status callback
 */
const doGetSummary = ({ mfrId, isMounted, setSummaryData, setSummaryDataStatus }) => {
  if (isMounted.current) {
    setSummaryDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/dashboard/details',
      params: {
        manufacturer_id: mfrId,
      },
      onSuccess: (res) => {
        if (isMounted.current) {
          setSummaryDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setSummaryData(_data);
        }
      },
      onError: () => {
        setSummaryDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get summary data for company stats
 *
 * @param {object} isMounted ref
 * @param {function} setSummaryData Result callback
 * @param {function} setSummaryDataStatus Status callback
 */
const doGetSummaryCompany = ({ mfrId, isMounted, setSummaryData, setSummaryDataStatus }) => {
  if (isMounted.current) {
    setSummaryDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/homepage/summary',
      params: {
        manufacturer_id: mfrId,
      },
      onSuccess: (res) => {
        if (isMounted.current) {
          setSummaryDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setSummaryData(_data);
        }
      },
      onError: () => {
        setSummaryDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get databots list
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setDatabotsData Result callback
 * @param {function} setDatabotsDataStatus Status callback
 */
const doGetDatabots = ({ mfrId, isMounted, setDatabotsData, setDatabotsDataStatus }) => {
  if (isMounted.current) {
    setDatabotsDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/available-bots`,
      params: {
        manufacturer_id: mfrId ?? null,
      },
      onSuccess: (res) => {
        if (isMounted.current) {
          setDatabotsDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setDatabotsData(_data);
        }
      },
      onError: () => {
        setDatabotsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get databots list
 *
 * @param {object} isMounted ref
 * @param {function} setDatabotsData Result callback
 * @param {function} setDatabotsDataStatus Status callback
 */
const doGetDatabotsSupplier = ({
  mfrId,
  isMounted,
  setDatabotsSupplierData,
  setDatabotsSupplierDataStatus,
}) => {
  if (isMounted.current) {
    setDatabotsSupplierDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/homepage/running-bots`,
      params: {
        manufacturer_id: mfrId || null,
      },
      onSuccess: (res) => {
        if (isMounted.current) {
          setDatabotsSupplierDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setDatabotsSupplierData(_data);
        }
      },
      onError: () => {
        setDatabotsSupplierDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get data jobs list
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setDatabotsData Result callback
 * @param {function} setDatabotsDataStatus Status callback
 */
const doGetDataJobs = ({ mfrId, isMounted, setDataJobsData, setDataJobsDataStatus }) => {
  if (isMounted.current) {
    setDataJobsDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/data-requests/',
      params: {
        offset: 0,
        limit: 5,
        sort_field: 'updated_at',
        sort_order: 'desc',
        filter_supplier: mfrId ? [mfrId] : null,
        filter_viewed: 'all',
        filter_tab: 'all_data_jobs',
      },
      onSuccess: (res) => {
        if (isMounted.current) {
          setDataJobsDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setDataJobsData(_data);
        }
      },
      onError: () => {
        setDataJobsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get default assignee for current company
 *
 * @param {object} isMounted ref
 * @param {function} setDatabotsData Result callback
 * @param {function} setDatabotsDataStatus Status callback
 */
const doGetDefaultAssignee = ({
  mfrId = null,
  isMounted,
  setDefaultAssigneeData,
  setDefaultAssigneeDataStatus,
}) => {
  if (isMounted.current) {
    setDefaultAssigneeDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/organisation/users/?default_assignee',
      params: {
        org: mfrId,
        org_type: mfrId ? UserRoleConstants.MANUFACTURER : null,
      },
      onSuccess: (res) => {
        if (isMounted.current) {
          setDefaultAssigneeDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data?.length && res.data[0]?.id ? res.data[0] : null;
          setDefaultAssigneeData(_data);
        }
      },
      onError: () => {
        setDefaultAssigneeDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get most recent image quality assessment
 *
 * @param {object} isMounted ref
 * @param {string} type manufacturer or distributor
 * @param {number} id org id of current dashboard
 */
const doGetImageQualityAssessment = ({
  isMounted,
  type,
  id,
  setImageQualityAssessmentData,
  setImageQualityAssessmentDataStatus,
}) => {
  const slug = BOT_SLUGS.IMAGE_QUALITY;
  if (isMounted.current) {
    setImageQualityAssessmentDataStatus(ActionStatusConstants.ISBUSY);

    const params = { offset: 0, limit: 3 };
    if (isConstantEqual(type, UserRoleConstants.MANUFACTURER)) {
      params.manufacturer_id = id;
    } else {
      params.client_id = id;
    }

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/report-data/${slug}`,
      params,
      onSuccess: (res) => {
        if (isMounted.current) {
          setImageQualityAssessmentData(
            res.data.report_data.filter((item) => {
              return (
                item.additional_data?.images &&
                item.additional_data?.reports &&
                item.additional_data?.images?.total_products &&
                item.additional_data?.images?.total_products > 0
              );
            })
          );
          setImageQualityAssessmentDataStatus(ActionStatusConstants.SUCCESS);
        }
      },
      onError: () => {
        setImageQualityAssessmentDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

export {
  doGetSummary,
  doGetMfrs,
  doGetChildMfrs,
  doGetMfr,
  doGetDatabots,
  doGetDatabotsSupplier,
  doGetDataJobs,
  doGetDefaultAssignee,
  doGetSummaryCompany,
  doGetImageQualityAssessment,
};
