import React, { useCallback, useEffect, useRef, useState } from 'react';

import './DataJobs.scss';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { useHistory, useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { JOBTYPES } from 'constants/DataJobDetailsConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';

import { DataJobsContext } from './DataJobsContext';
import { DataJobsFilter } from './DataJobsFilter';
import {
  __initFilterState,
  __getFilterStateFromUrlSearchStr,
  __convertUrlStateToFilterState,
  __convertUrlStateToDataTableState,
  __getUrlSearchStrFromFilterState,
  __getApiParamsFromUrlState,
  decorateDataJobsData,
} from './DataJobsHelpers';
import { DataJobsTable } from './DataJobsTable';

/**
 * Filterable data table for listing Data Jobs
 *
 * @param {Object} props
 * @returns Render
 */
const DataJobs = () => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const { activeUser } = RootHooks.useActiveUser();

  const [activeTab, setActiveTab] = useState(JOBTYPES.DATA_JOBS_ALL);
  const [filterState, setFilterState] = useState(_cloneDeep(null));
  const [dataTableState, setDataTableState] = useState(_cloneDeep(__initFilterState));

  const [dataJobsData, setDataJobsData] = useState(null);
  const lastUrlParams = useRef(false);
  const lastUrlSearch = useRef(false);

  const onApplyFilter = useCallback(
    (newFilterState, newDataTableState) => {
      const localNewDataTableState = _cloneDeep(newDataTableState);
      const isEqFilterState = _isEqual(filterState, newFilterState);
      const isEqDataTableState = _isEqual(dataTableState, newDataTableState);
      if (isEqFilterState && isEqDataTableState) {
        return;
      }

      if (!isEqFilterState) {
        localNewDataTableState.page = 1;
      }

      // Remove sorting state if changing tabs
      if (newFilterState.tab !== filterState.tab) {
        delete localNewDataTableState.sort_field;
        delete localNewDataTableState.sort_order;
      }

      const qStr = __getUrlSearchStrFromFilterState(newFilterState, localNewDataTableState);

      history.push({
        search: qStr?.length ? qStr : null,
      });
    },
    [filterState, history, dataTableState]
  );

  const doInitialize = useCallback(
    (isForced = false) => {
      if (
        !isForced &&
        lastUrlSearch.current !== false &&
        lastUrlSearch.current === location?.search
      ) {
        return;
      }

      lastUrlSearch.current = location?.search;

      const fParams = location?.search
        ? __getFilterStateFromUrlSearchStr(location.search)
        : _cloneDeep(__initFilterState);
      if (fParams) {
        if (activeUser?.role === UserRoleConstants.CLIENT) {
          delete fParams.distributor;
        } else if (
          activeUser?.role === UserRoleConstants.SUPPLIER &&
          !activeUser?.profile?.manufacturer?.has_children
        ) {
          delete fParams.supplier;
        }

        if (
          !isForced &&
          lastUrlParams.current !== false &&
          _isEqual(lastUrlParams.current, fParams)
        ) {
          return;
        }

        lastUrlParams.current = fParams;

        setFilterState(__convertUrlStateToFilterState(fParams));
        setDataTableState(__convertUrlStateToDataTableState(fParams));

        // Set initial tab from url
        const selectedTab = Object.keys(JOBTYPES).find((key) => JOBTYPES[key] === fParams.tab);
        setActiveTab(JOBTYPES[selectedTab]);

        const reqParams = __getApiParamsFromUrlState(fParams);

        setIsLoading(true);
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath:
            fParams?.tab === JOBTYPES.PASS_THRU_FILES ? `/pass-thru-files/` : `/data-requests/`,
          params: reqParams,
          onSuccess: (res) => {
            if (isMounted.current === true) {
              setIsLoading(false);
              const _data = _get(res, 'data') || null;
              setDataJobsData(
                decorateDataJobsData(
                  _data,
                  () => {
                    if (dataTableState?.page === 1) {
                      doInitialize(true);
                    } else {
                      onApplyFilter(filterState, { ...dataTableState, page: 1 });
                    }
                  },
                  activeUser
                )
              );
            }
          },
          onError: () => {
            if (isMounted.current === true) {
              setIsLoading(false);
            }
          },
        });
      }
    },
    [location, activeUser, setIsLoading, isMounted, filterState, dataTableState, onApplyFilter]
  );

  const onResetFilter = useCallback(
    (newFilterState, newDataTableState) => {
      const qStr = __getUrlSearchStrFromFilterState(newFilterState, newDataTableState);

      history.push({
        search: qStr?.length ? qStr : null,
      });

      doInitialize({ isForced: true });
    },
    [history, doInitialize]
  );

  useEffect(() => {
    if (lastUrlSearch.current !== location?.search) {
      doInitialize({ isForced: true });
    }
  }, [location, doInitialize, lastUrlSearch]);

  useEffect(() => {
    doInitialize();
  }, [doInitialize]);

  const goToDataRequestDetails = (itemId) => {
    if (!(itemId > 0)) {
      return;
    }
    history.push(`/data-request/${itemId}`);
  };

  return (
    <div className="data-jobs">
      <DataJobsFilter
        activeFilterState={filterState}
        onApplyFilter={(filter) => onApplyFilter(filter, dataTableState)}
        onResetFilter={(filter) => onResetFilter(filter, dataTableState)}
        activeTab={activeTab}
        setActiveTab={(v) => setActiveTab(v)}
        isLoading={isLoading}
        totalSize={(dataJobsData && dataJobsData.total) || 0}
        tableState={dataTableState}
      />
      <DataJobsContext.Provider value={{ isLoading, setIsLoading }}>
        <DataJobsTable
          data={(dataJobsData && dataJobsData.data_requests) || null}
          totalSize={(dataJobsData && dataJobsData.total) || 0}
          tableState={dataTableState}
          onTableStateChange={(newDataTableState) => onApplyFilter(filterState, newDataTableState)}
          onRowClick={(id) => goToDataRequestDetails(id)}
          activeTab={activeTab}
        />
      </DataJobsContext.Provider>
    </div>
  );
};

export { DataJobs };
