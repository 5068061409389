import './RequestUpdateCard.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const RequestUpdateCard = ({ title, description, actionText }) => {
  return (
    <div className="request-update-card">
      <div className="request-update-card-title">{title}</div>
      <div>{description}</div>
      <Link to="/data-job-create">
        <button type="button" className="btn btn-secondary request-update-card-btn">
          {actionText}
        </button>
      </Link>
    </div>
  );
};

RequestUpdateCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
};

export { RequestUpdateCard };
