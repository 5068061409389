import React, { useEffect, useState } from 'react';

import { Accordion, Card, Button } from 'react-bootstrap';

import * as ApiCalls from '../../../api/ApiCalls';
import { IdsConstants } from './IdsConstants';
import './IdsSelfServiceQuotaBanner.scss';

const IdsSelfServiceQuotaBanner = () => {
  const [, setIsQuotaInfoLoading] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [quotaInfo, setQuotaInfo] = useState({});

  const loadQuotaInfo = () => {
    setIsQuotaInfoLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/bots/process-job/quota',
      onSuccess: (res) => {
        setQuotaInfo(res.data);
      },
      onEnd: () => {
        setIsQuotaInfoLoading(false);
      },
    });
  };

  useEffect(() => {
    loadQuotaInfo();
  }, []);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const requestUpgrade = () => {
    setIsRequestLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: '/bots/process-job/request-upgrade',
      onSuccess: () => {
        loadQuotaInfo();
      },
      onEnd: () => {
        setIsRequestLoading(false);
      },
    });
  };

  return (
    <>
      {quotaInfo?.account_tier === IdsConstants.SELF_SERVICE_ACCOUNT_TIERS.free_trial.key && (
        <>
          <Accordion>
            <Card className="ids-trial-banner-card">
              <Card.Header>
                <div className="ids-trial-banner-header">
                  <div>
                    <Card.Text className="ids-trial-banner-title">Free Trial</Card.Text>
                    <Card.Subtitle>
                      You are currently on a <strong>free trial</strong>. You have used{' '}
                      <strong>{quotaInfo?.usage?.total}</strong> out of your monthly quota of{' '}
                      <strong>{quotaInfo?.usage_quota}</strong> images
                    </Card.Subtitle>
                  </div>

                  <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={handleToggle}>
                    {isExpanded ? 'Hide Details' : 'Show Details'}
                  </Accordion.Toggle>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="ids-trial-card-body">
                  <div className="ids-trial-banner-content">
                    <ul>
                      <li>
                        <strong>{quotaInfo?.usage?.pending_extraction}</strong> pending extraction
                      </li>
                      <li>
                        <strong>{quotaInfo?.usage?.extracted}</strong> pending initial assessment
                      </li>
                      <li>
                        <strong>{quotaInfo?.usage?.assessment?.success}</strong> assessed/enhanced
                      </li>
                      <li>
                        <strong>{quotaInfo?.usage?.assessment?.canceled}</strong> assessed
                        (canceled)
                      </li>
                    </ul>
                    <p className="note">
                      <em>
                        <strong>Note:</strong> The current usage displayed above may be higher than
                        the actual usage once the pending items are processed.
                      </em>
                    </p>
                  </div>
                  <div className="ids-trial-banner-action">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={requestUpgrade}
                      disabled={isRequestLoading || quotaInfo?.has_upgrade_request}
                    >
                      Upgrade Account!
                    </button>
                    {quotaInfo?.has_upgrade_request && <small>A request already exists</small>}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </>
      )}
    </>
  );
};

export { IdsSelfServiceQuotaBanner };
