import moment from 'moment';

import {
  FULL_DATE,
  TIME,
  DATE_8601,
  TIME_8601,
  MIN_DATE,
  MONTH_YEAR,
  MIN_YEAR_DATE,
} from 'constants/DateTimeConstants';

/**
 * formatDateTimeStamp.
 * Format a datetimestamp string into a moment timestapm formatted like 'Month Day, Year [at] Hours:Minutes AM/PM"
 *
 * @param {string} timeString - the date-timestamp as a string
 * @param {string} seperator - string to seperate date and time part of return with
 * @returns {moment} - moment object representing the formatted DateTimestamp
 */
export const formatDateTimeStamp = (timeString, seperator = '') => {
  return moment(timeString).format(`${FULL_DATE}${seperator} ${TIME}`);
};

/**
 * formatTimeStamp.
 * Format a timestamp string into a moment timestapm formatted like 'Hours:Minutes AM/PM"
 *
 * @param {string} timeString - the timestamp as a string
 * @returns {moment} - moment object representing the formatted timestamp
 */
export const formatTimeStamp = (timeString) => {
  return moment(timeString).format(TIME);
};

/**
 * formatDateStamp.
 * Format a datestamp string into a moment datestamp formatted like 'Month Day, Year"
 *
 * @param {string} dateString - the datestamp as a string
 * @returns {moment} - moment object representing the formatted datestamp
 */
export const formatDateStamp = (dateString) => {
  return moment(dateString).format(FULL_DATE);
};

/**
 * Formats a datestamp string into a moment datestamp formatted like 'Month/Day/Year (06/22/2021)"
 *
 * @param {string} dateString - the datestamp as a string
 * @returns {moment} - moment object representing the formatted datestamp
 */
export const formatMinDateStamp = (dateString) => {
  return moment(dateString).format(MIN_DATE);
};

/**
 * Formats a datestamp string into a moment datestamp formatted like 'Month/Day/Year (06/22/21)"
 *
 * @param {string} dateString - the datestamp as a string
 * @returns {moment} - moment object representing the formatted datestamp
 */
export const formatMinYearDateStamp = (dateString) => {
  return moment(dateString).format(MIN_YEAR_DATE);
};

/**
 * Formats a datestamp string into a moment object formatted like 'Year-Month-Day'
 *
 * @param {string} dateString - the datestamp as a string
 * @returns {moment} - moment object representing the formatted datestamp
 */
export const formatDate8601 = (dateString) => {
  return moment(dateString).format(DATE_8601);
};

/**
 * Formats a datetimestamp string into a moment object formatted like 'Year-Month-Day 13:01:01'
 *
 * @param {string} timeString - the datetimestamp as a string
 * @returns {moment} - moment object representing the formatted DateTimestamp
 */
export const formatDateTime8601 = (timeString, separator = '') => {
  return moment(timeString).format(`${DATE_8601}${separator} ${TIME_8601}`);
};

/**
 * Converts an ISO8601 string (provided as YYYY-MM-DD) into a native Date object
 *
 * @param {string} date - The date as YYYY-MM-DD
 * @returns {Date} - A native Date object representing the specified date
 */
export const convertIso8601ToDate = (date) => {
  const splitDate = date.split('-');
  return new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
};

/**
 * Formatted the total of seconds in the runtime as string like 00:00:00
 *
 * @param {string} runtime - The runtime formatted as seconds
 */
export const formattedRuntime = (runtime) => {
  const hours = Math.floor(runtime / 3600);
  const minutes = Math.floor((runtime % 3600) / 60);
  const seconds = Math.floor(runtime % 60);
  const formattedRuntime = `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(
    -2
  )}:${`0${seconds}`.slice(-2)}`;

  return formattedRuntime;
};

/**
 * Formats a given date string into a more readable "Month Year" format.
 *
 * @param {string} dateString - The date string to be formatted. 
 *                              Expected format is "YYYY-MM-DD".
 * @returns {string} The date formatted as "Month Year" (e.g., "February 2024").
 *
 * @example
 * // returns "February 2024"
 * formattedMonthYear("2024-02-02");
 *
 * @note
 * This function requires the moment.js library to run. Ensure that moment.js is
 * included in your project dependencies and properly imported.
 * 
 * The 'MONTH_YEAR' constant used in the format method should be defined 
 * as 'MMMM YYYY' to match the expected output format.
 */
export const formattedMonthYear = (dateString) => {
  // Ensure 'MONTH_YEAR' is defined as 'MMMM YYYY'
  const MONTH_YEAR = 'MMMM YYYY';
  const formattedDate = moment(dateString).format(MONTH_YEAR);
  return formattedDate;
};
