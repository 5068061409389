import React, { useState } from 'react';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { getRootMenuPortalTarget } from 'helpers/Utils';
import styleVars from 'scss/vars.scss';

const HeaderSelector = ({ selectedHeaders, headersList, headerSetterFunc, disabled }) => {
  const menuPortalTarget = getRootMenuPortalTarget();

  const [isCustomSelectorVisible, setIsCustomSelectorVisible] = useState(false);

  // Check whether given header should be a checkbox or into the dropdown.
  const isCheckboxHeader = (header) => /description|detail/i.test(header);

  // Handle checkbox changes
  const toggleHeader = (header) => {
    if (selectedHeaders?.includes(header)) {
      headerSetterFunc(selectedHeaders.filter((item) => item !== header));
    } else {
      headerSetterFunc([...(selectedHeaders ?? []), header]);
    }
  };

  // Handle header changes from dropdown
  // Append dropdown values to checkbox selections
  const onChangeHeaderDropdown = (v) => {
    let newHeaders = selectedHeaders?.length
      ? selectedHeaders.filter((item) => isCheckboxHeader(item))
      : [];

    if (v?.length) {
      newHeaders = [...newHeaders, ...v.map((item) => item.value)];
    }

    headerSetterFunc(newHeaders);
  };

  const renderCheckboxes = () => {
    let output = null;

    const availableCheckboxes = headersList?.filter((item) => isCheckboxHeader(item));

    if (availableCheckboxes?.length) {
      output = availableCheckboxes.map((item, i) => (
        <li className="header-selector-list-item" key={i}>
          <label htmlFor={`header-selector-check-${i}`}>
            <input
              onClick={() => toggleHeader(item)}
              defaultChecked={selectedHeaders?.includes(item)}
              type="checkbox"
              name={`header-selector-check-${i}`}
              id={`header-selector-check-${i}`}
            />
            <span className="label">{item}</span>
          </label>
        </li>
      ));
    }

    return output;
  };

  const customHeadersListOptions = headersList?.length
    ? headersList
        .filter((item) => !isCheckboxHeader(item))
        .map((item) => {
          return { value: item, label: item };
        })
    : [];

  const customHeadersListSelectedOptions = selectedHeaders?.length
    ? selectedHeaders
        .filter((item) => !isCheckboxHeader(item))
        .map((item) => {
          return { value: item, label: item };
        })
    : [];

  return (
    <div className="header-selector">
      <ul className="header-selector-list">
        {renderCheckboxes()}
        {!!customHeadersListOptions?.length && (
          <li className="header-selector-list-item custom-selector">
            <label htmlFor="header-selector-check-custom">
              <input
                onClick={() => {
                  if (isCustomSelectorVisible) {
                    onChangeHeaderDropdown(null);
                  }
                  setIsCustomSelectorVisible(!isCustomSelectorVisible);
                }}
                checked={!!isCustomSelectorVisible}
                readOnly
                type="checkbox"
                name="header-selector-check-custom"
                id="header-selector-check-custom"
              />
              <span className="label">I&apos;d like to select a different column.</span>
            </label>
            {!!isCustomSelectorVisible && (
              <StyledMultiselect
                defaultValue={customHeadersListSelectedOptions}
                options={customHeadersListOptions}
                setOnChange={(v) => onChangeHeaderDropdown(v)}
                getOptionValue={(option) => option.value}
                backspaceRemovesValue={false}
                useOnBlur={false}
                isSearchable
                optionsSort
                closeMenuOnSelect={false}
                isMulti
                hideSelectedOptions={false}
                placeholder=""
                isDisabled={disabled}
                menuPortalTarget={menuPortalTarget}
                selectStyle={{
                  option: (provided, state) => {
                    return {
                      color: state.isSelected && `${styleVars.colors_designBlack}`,
                      display: 'inline-flex',
                      backgroundColor: state.isFocused && `${styleVars.colors_designOrangeLight}`,
                      ':before': {
                        backgroundColor: state.isSelected
                          ? `${styleVars.colors_designGreen}`
                          : `${styleVars.colors_designWhite}`,
                        borderRadius: 10,
                        borderWidth: 1,
                        display: 'inline-flex',
                        content: '" "',
                        marginRight: 8,
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        height: 10,
                        width: 10,
                      },
                    };
                  },
                }}
              />
            )}
          </li>
        )}
      </ul>
    </div>
  );
};

export { HeaderSelector };
