import React, { useCallback, useEffect, useState } from 'react';

import queryString from 'query-string';
import { useLocation } from 'react-router';

import * as ApiCalls from 'api/ApiCalls';
import { SelectSingleHeaderSection } from 'components/databots/bots/common/SelectSingleHeaderSection/SelectSingleHeaderSection';
import { SmallSelectFileSection } from 'components/databots/bots/common/SmallSelectFileSection/SmallSelectFileSection';
import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { isArchive } from 'helpers/AttachmentUtils';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import './GenericFifBot.scss';
import { GenericFifBotConstants } from './private/GenericFifBotConstants';

const GenericFifBot = ({
  botStatusId,
  slug,
  status,
  handleRunBot,
  handleCancelBot,
  additionalData,
  setConfigData,
}) => {
  // TODO: In a future iteration, maybe standardize all bot context objects as props
  // (context, config, additionalData, setters, runners, etc.)
  const location = useLocation();
  const dataJobId = queryString.parse(location?.search).data_request_id;

  const isMounted = useIsMounted();

  // Holds the values users are able to select from
  const [filesList, setFilesList] = useState(null);
  const [headersList, setHeadersList] = useState(null);

  // Information holding user selections
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedHeader, setSelectedHeader] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [validSelections, setValidSelections] = useState(false);
  const [isZipFile, setIsZipFile] = useState(false);

  // Reset file lists on props change
  useEffect(() => {
    setFilesList(additionalData?.attachments ?? null);
  }, [additionalData]);

  // Load column headers per file.
  // - If file obj has an ID(original file), retrieve headers from BE
  // - If file obj has a header list(uploaded secondary), use that
  const doLoadHeaders = useCallback(
    (file) => {
      setSelectedHeader(null);

      if (file?.id) {
        setIsLoading(true);

        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/data-requests/attachment/${file.id}/headers`,
          onSuccess: (res) => {
            if (isMounted.current && res?.data?.headers?.length) {
              setHeadersList(res.data.headers.map((item) => item));
            } else {
              setHeadersList(null);
            }
          },
          onEnd: () => setIsLoading(false),
        });
      } else if (file?.headers?.length) {
        setHeadersList(file?.headers);
      } else {
        setHeadersList(null);
      }
    },
    [isMounted]
  );

  // Reset selected primary file on list change
  useEffect(() => {
    setSelectedFile(null);
  }, [filesList]);

  // Load primary column headers for selected file
  useEffect(() => {
    if (isArchive(selectedFile)) {
      setIsZipFile(true);
    } else {
      doLoadHeaders(selectedFile);
    }
  }, [selectedFile, doLoadHeaders]);

  // Update bot config on form changes
  useEffect(() => {
    let botConfigObj = {};

    if (selectedFile?.location) {
      botConfigObj = {
        container_name: selectedFile?.location,
        file_name: selectedFile?.name,
      };

      if (!isZipFile && selectedHeader) {
        botConfigObj.selected_header = selectedHeader.value;
      }
      setValidSelections(true);
    } else {
      setValidSelections(false);
    }

    setConfigData(botConfigObj);
  }, [selectedFile?.location, isZipFile, selectedFile?.name, selectedHeader, setConfigData]);

  // Run handler assembles config object and triggers bot run
  const onRunBot = () => {
    handleRunBot(false, `/data-request/${dataJobId}`);
  };

  const renderBotBody = () => {
    const typeValidationRegex = new RegExp(
      `\\.(xls|xlsx|csv${GenericFifBotConstants.CONFIG[slug].allowImgArchive ? '|zip' : ''})$`,
      'i'
    );

    return (
      <>
        <SmallSelectFileSection
          title="Step 1: Select Your File"
          dataJobId={dataJobId}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          filesList={filesList}
          setFilesList={setFilesList}
          isLoading={isLoading}
          typeValidation={typeValidationRegex}
        />
        {selectedFile && !isArchive(selectedFile) && (
          <SelectSingleHeaderSection
            title="Step 2: Select the column containing the image URLs"
            placeholder="Select a column"
            selectedFile={selectedFile}
            selectedHeader={selectedHeader}
            setSelectedHeader={setSelectedHeader}
            headersList={headersList}
            isLoading={isLoading}
            showFirstSheetOnlyNote
            isMulti={false}
            disabled={isLoading || !selectedFile}
          />
        )}
      </>
    );
  };

  if (!slug) {
    return null;
  }

  if (!Object.keys(GenericFifBotConstants.CONFIG).includes(slug)) {
    console.error('Invalid slug for generic bot', slug);
  }

  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
        {
          label: 'Total Products',
          value: additionalData?.total_products ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      className="generic-fif-bot"
      status={status}
      title={GenericFifBotConstants.CONFIG[slug].title}
      subtitle={GenericFifBotConstants.CONFIG[slug].subtitle}
      bodyContent={renderBotBody()}
      requiresApproval
      preRunValidate={() => {
        if (!(selectedFile?.location && selectedHeader) && !isZipFile) {
          toast.error('Please select a file and a column.');
          return false;
        }
        return true;
      }}
      disableActions={{ run: !validSelections }}
      onRun={onRunBot}
      onCancel={() => handleCancelBot(`/data-request/${dataJobId}`)}
      confirmDialogTitle={GenericFifBotConstants.CONFIG[slug].confirmDialogTitle}
      confirmDialogBody={
        <>
          <p>You are about to:</p>
          {GenericFifBotConstants.CONFIG[slug].confirmDialogBodyText}
        </>
      }
    />
  );
};

export { GenericFifBot };
