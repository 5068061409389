import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { mandatoryExportColumns } from 'constants/ExportConstants';

import { ATTRIBUTE_TYPES } from './ExportFunctionalityAdvancedConstants';

/**
 * showHideFilterChange - Controls UI toggle states of hide/show buttons.
 * Note: ATTRIBUTE_TYPES are subject to change / mutation, so it was built to not use state arrays to control their UI.
 * TODO: Build a state object instead
 * @param {string} attributeType
 * @param {array} editableTemplateData
 * @param {array} originalTemplateData
 * @returns {boolean}
 *
 */
const showHideFilterChange = (attributeType, editableTemplateData, originalTemplateData) => {
  if (editableTemplateData && originalTemplateData) {
    const isShown = !!editableTemplateData?.attribute_types?.includes(attributeType);
    const isShownOriginal = !!originalTemplateData?.attribute_types?.includes(attributeType);
    return isShown !== isShownOriginal;
  }
  return false;
};

/**
 * BlockAttributesTableFilter - Filter by attribute type widget.
 *
 * NOTE: This component changes only the table template. It does not change the table itself.
 * The table is rendered in the BlockAttributeTable Component when the template changes.
 * onChange is a parent function to set the new template.
 * NOTE: BlockAttributesTable has a useEffect() to rerender the table based on onChange(newTemplateData)
 * TODO: This component tree is a good candidate for refactoring with TypeScript / backend processing
 *
 * @param {object} editableTemplateData Editable template object - the state of the active template
 * @param {object} originalTemplateData Original template object
 * @param {function} onChange Accepts new template data.
 * @param {boolean} isDisabled Disabled state
 * @param {array} attributesData all attributes data, regardless of state and template
 * @return render
 */
const BlockAttributesTableFilter = React.memo(
  ({ editableTemplateData, originalTemplateData, onChange, isDisabled, attributesData }) => {
    const [bulkSelectTypes, setBulkSelectTypes] = useState([]);

    useEffect(() => {
      // Initialize State
      if (originalTemplateData?.attribute_types)
        setBulkSelectTypes(
          originalTemplateData?.attribute_types.map((i) => {
            return { type: i, isSelected: true };
          })
        );
    }, [originalTemplateData]);

    /**
     * showHideAttributeType - Toggles what attribute types are in the template.
     * (BlockAttributesTable renders only the attributes w/ attribute types in the template)
     * This method does not change the `is_exported` status of attributes
     *
     * @return {void}  This method indirectly rerenders the table.
     *
     */
    const showHideAttributeType = (attrType) => {
      const newTemplateData = editableTemplateData ? _cloneDeep(editableTemplateData) : {};
      if (newTemplateData?.attribute_types?.includes(attrType)) {
        newTemplateData.attribute_types = newTemplateData.attribute_types.filter(
          (item) => item !== attrType
        );
      } else {
        if (newTemplateData?.attribute_types?.length) {
          newTemplateData.attribute_types.push(attrType);
        } else {
          newTemplateData.attribute_types = [attrType];
        }
      }
      typeof onChange === 'function' && onChange(newTemplateData);
    };

    /**
     * doSelectAllOfAttrType - Selects/Deselects all the attributes of the attribute type, attrType
     *
     * NOTE: Each Company has their own definition for each Attribute Type.
     * (EG: 'general attributes' are not the same set of attributes for each company)
     * The attributes in `editableTemplateData` do not contain attribute types.
     * This method cross-checks `editableTemplateData's` attributes (editableTemplateData.output_format)
     * against the company definition for each attribute, found in `attributesData`.
     *
     * @param {string} attrType string of attribute type
     * @return {void}  This method indirectly rerenders the table via onchange(newTemplateData)
     *
     */
    const doExportAllOfType = (attrType) => {
      const newTemplateData = editableTemplateData ? _cloneDeep(editableTemplateData) : {};
      const filteredData = attributesData.filter((i) => attrType === i.type);
      newTemplateData.output_format.forEach((row) => {
        // Check if each attribute is in the filteredData & is not disabled or a mandatory
        if (
          filteredData.find(
            (attr) => !mandatoryExportColumns.includes(row.key) && attr.id === row.id
          )
        ) {
          // eslint-disable-next-line no-param-reassign
          row.is_exported = !row.is_exported;
        }
      });

      // Update parent template data
      typeof onChange === 'function' && onChange(newTemplateData);

      // Update state of select/deselect checkboxes
      const newArr = bulkSelectTypes.map((obj) => {
        return obj.type === attrType ? { ...obj, isSelected: !obj.isSelected } : obj;
      });

      setBulkSelectTypes(newArr);
    };

    const deselectAll = () => {
      const newTemplateData = editableTemplateData ? _cloneDeep(editableTemplateData) : {};
      // Set attribute to is_exported = false if not a mandatory attribute
      newTemplateData?.output_format.forEach((row) => {
        if (!mandatoryExportColumns.includes(row.key))
          // eslint-disable-next-line no-param-reassign
          row.is_exported = false;
      });

      // Update parent Template
      typeof onChange === 'function' && onChange(newTemplateData);

      // Update select/deselect checkboxes
      const newArr = bulkSelectTypes.map((obj) => {
        return { ...obj, isSelected: false };
      });
      setBulkSelectTypes(newArr);
    };

    const renderShowHideSwitches = () =>
      // Show/Hide switches
      Object.keys(ATTRIBUTE_TYPES).map((item, i) => (
        <li
          className={classNames('filter-attribute-types-item', `show-type-input-${item}`, {
            'is-disabled': isDisabled,
            'is-changed': showHideFilterChange(item, editableTemplateData, originalTemplateData),
          })}
          key={i}
        >
          <Form.Switch
            id={`switch-${item}`}
            checked={!!editableTemplateData?.attribute_types?.includes(item)}
            readOnly
            onClick={() => showHideAttributeType(item)}
            disabled={isDisabled}
          />
          <span className="label">{ATTRIBUTE_TYPES[item].label}</span>
        </li>
      ));

    const checkSelected = (item) => {
      const obj = bulkSelectTypes?.find((i) => i.type === item);
      const result = !!obj?.isSelected;
      return result;
    };

    const renderSelectAllCheckboxes = () =>
      Object.keys(ATTRIBUTE_TYPES).map((item, i) => (
        <li
          className={classNames('filter-attribute-types-item', `show-type-input-${item}`, {
            'is-disabled': isDisabled,
          })}
          key={i}
        >
          <Form.Check
            type="checkbox"
            readOnly
            id={`checkbox-${item}`}
            onClick={() => doExportAllOfType(item)}
            checked={!!checkSelected(item)}
          />
          <span className="label">{ATTRIBUTE_TYPES[item].label}</span>
        </li>
      ));

    return (
      <section className="block block-attributes-table-filter">
        <div className="title">Show/Hide Attribute Types</div>
        <div className="filter-btn-container">
          <ul className="filter filter-attribute-types">{renderShowHideSwitches()}</ul>
        </div>
        <div className="title">Select/Deselect All of Attribute Type</div>
        <div className="filter-btn-container">
          <ul className="filter filter-attribute-types">{renderSelectAllCheckboxes()}</ul>
          <button
            type="button"
            className="deselect-btn btn btn-light btn-sm"
            onClick={() => deselectAll()}
            style={{ display: editableTemplateData?.attribute_types.length === 0 && 'none' }}
            disabled={bulkSelectTypes?.every((type) => type.isSelected === false)}
          >
            Deselect All
          </button>
        </div>
      </section>
    );
  }
);

BlockAttributesTableFilter.defaultProps = {
  editableTemplateData: null,
  originalTemplateData: null,
  isDisabled: false,
  attributesData: null,
};

BlockAttributesTableFilter.propTypes = {
  editableTemplateData: PropTypes.object,
  originalTemplateData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  attributesData: PropTypes.array,
};

export { BlockAttributesTableFilter };
