import React, { useEffect, useState, useCallback } from 'react';
import './PricingUpdateBot.scss';

import BootstrapTable from 'react-bootstrap-table-next';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { useIsMounted } from 'helpers/useIsMounted';

import { PricingUpdateFileList } from './PricingUpdateFileList/PricingUpdateFileList';
import { PricingUpdateFileUpload } from './PricingUpdateFileUpload/PricingUpdateFileUpload';
import { PricingUpdateHeader } from './PricingUpdateHeader/PricingUpdateHeader';
import { PricingUpdateLogTableDef } from './PricingUpdateLogTableDef';

const PricingUpdateBot = ({
  handleRunBot,
  details,
  configData,
  setConfigData,
  handleCancelBot,
  additionalData,
  manufacturerId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFileUploading, setIsFileUploading] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [distributorList, setDistributorList] = useState([]);
  const [files, setFiles] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [logData, setLogData] = useState([]);

  const isMounted = useIsMounted();

  // configure the results of the log table
  useEffect(() => {
    if (details.length > 0) {
      const newLogData = details.map((item) => {
        return {
          user: `${item.bot_status?.started_by.first_name} ${item.bot_status?.started_by.last_name} (${item.bot_status?.started_by.email})`,
          data_job: item.data_request?.id,
          status: item.data_request?.status,
          sent_to: item.bot_status?.config_data?.client_name,
          date: item.bot_status?.queued_at,
          actions: null,
        };
      });
      setLogData(newLogData);
    }
  }, [details]);

  const doGetDistributors = useCallback(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/manufacturers/${manufacturerId}/distributors`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setDistributorList(
            res.data.map((item) => ({ value: item.id, label: item.name, obj: item }))
          );
          setIsLoading(false);
        }
      },
      onError: (res) => {
        console.error(res);
      },
    });
  }, [manufacturerId, isMounted]);

  useEffect(() => {
    doGetDistributors();
  }, [additionalData, doGetDistributors, isMounted]);

  const doSubmit = () => {
    handleRunBot();
  };

  // set config data once we have a distributor and files
  useEffect(() => {
    if (files.length > 0 && distributor !== null) {
      const configData = {
        files: files.map((file) => file.id),
        client_id: distributor.value,
        client_name: distributor.label,
      };
      setConfigData(configData);
    } else {
      setConfigData({});
    }
  }, [files, distributor, setConfigData]);

  const handleSelectDistributor = (e) => {
    setDistributor(e);
  };

  const modalBody = () => {
    return (
      <div>
        <p>
          You are about to create a new Pricing Update Data Job with {files.length}{' '}
          {files.length > 1 ? 'attachments' : 'attachment'} for {distributor.label}.
        </p>
      </div>
    );
  };

  return (
    <div className="pricing-update">
      <PricingUpdateHeader
        manufacturer={additionalData.name}
        productTotal={additionalData.total_products}
      />
      <div className="title">
        <h3>Pricing Update</h3>
      </div>
      <div className="subtitle">
        Send pricing updates to a distributor and the pricing information will be updated to the
        Distributor’s specific format.
      </div>
      <div className="bot-main">
        {!isLoading ? (
          <>
            <div className="bot-form">
              <div className="bot-upload">
                <div className="uploadfile-container">
                  <PricingUpdateFileUpload
                    setIsUploading={setIsFileUploading}
                    isUploading={isFileUploading}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>
                <div className="file-list-container">
                  <PricingUpdateFileList fileList={files} setFiles={setFiles} />
                </div>
              </div>
              <div className="bot-select">
                <StyledMultiselect
                  id="distribSelect"
                  placeholder="Select Distributor"
                  values={distributor}
                  options={distributorList}
                  setOnChange={(e) => {
                    handleSelectDistributor(e);
                  }}
                  isSearchable
                  isMulti={false}
                />
              </div>
            </div>
            <div className="bot-actions">
              <button type="button" className="btn btn-secondary" onClick={() => handleCancelBot()}>
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setModalVisible(true)}
                // only enable once we have a valid config data
                disabled={JSON.stringify(configData) === '{}'}
              >
                Submit
              </button>
            </div>
            {modalVisible && (
              <div id="modal-container">
                <ModalPanel
                  className="modal-on-top"
                  isVisible={modalVisible}
                  setIsVisible={setModalVisible}
                  showCancel
                  header="Confirm Pricing Update"
                  body={() => modalBody()}
                  footer={
                    <div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setModalVisible(false)}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-primary" type="button" onClick={() => doSubmit()}>
                        Confirm
                      </button>
                    </div>
                  }
                />
              </div>
            )}
          </>
        ) : (
          <LoadingSpinner style={{ fontSize: '4em' }} />
        )}
      </div>
      {logData.length > 0 && (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a id="details">
            <h3>Pricing Update Log</h3>
          </a>
          <BootstrapTable
            bordered={false}
            bootstrap4
            keyField="date"
            data={logData}
            columns={PricingUpdateLogTableDef.columns}
          />
        </>
      )}
    </div>
  );
};

export { PricingUpdateBot };
