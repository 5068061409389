import './SelectColorDot.scss';
import React from 'react';

import PropTypes from 'prop-types';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import styleVars from 'scss/vars.scss';

/*
Component expects the following props.
id = html id of the element
options = array of objects in the form {label: "l", value: "v", color: "#ffffff" }
values = state object to store values in
setOnChange = function to resolve on change
isDisabled = boolean
*/

const SelectColorDot = ({ id, value, data, isDisabled = false, setOnChange }) => {
  const dot = (color = styleVars.colors_designGrey550, singleValue = false) =>
    !singleValue
      ? {
          alignItems: 'center',
          display: 'flex',
          ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: value ? '" "' : null,
            display: 'table',
            marginRight: 8,
            height: 10,
            width: 10,
          },
        }
      : {
          backgroundColor: color,
          borderRadius: '10px',
          content: '',
          display: 'table',
          marginRight: '8px',
          height: '10px',
          width: '10px',
        };

  const colourStyles = {
    option: (styles, { data, isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? styleVars.colors_designGrey550 : isSelected ? styleVars.colors_designWhite : data.color,
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  return (
    <>
      {data && data.length > 0 && data[0] ? (
        <StyledMultiselect
          id={id}
          values={[value]}
          options={data}
          isDisabled={isDisabled}
          menuPortalTarget={document.body}
          setOnChange={setOnChange}
          isMulti={false}
          isSearchable
          selectStyle={colourStyles}
        />
      ) : (
        <div className="select-color-dot_no-options">
          {value && value.color && <span style={{ ...dot(value.color, true) }} />}
          {value && value.label}
        </div>
      )}
    </>
  );
};

SelectColorDot.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  data: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  setOnChange: PropTypes.func,
};

export { SelectColorDot };
