import './DataJobDirectionSelect.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Alert, Form } from 'react-bootstrap';
import { isIE } from 'react-device-detect';

const DataJobDirectionSelect = ({ directionValue, onChange, isInvalid }) => {
  const doOnChange = (v) => {
    if (typeof onChange === 'function') {
      onChange(v);
    } else {
      console.error('No onChange handler');
    }
  };
  const OptionCard = ({ onClick, isChecked, title, subtitle }) => {
    return (
      <div
        className={classNames(
          'option-wrap',
          { [`option-wrap-isIE`]: isIE },
          {
            active: isChecked,
            invalid: isInvalid,
          }
        )}
        onClick={onClick}
      >
        <div className="option-input">
          <Form.Check type="radio" checked={isChecked} readOnly />
        </div>
        <div className="option-label">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
      </div>
    );
  };

  return (
    <section className={classNames('data-job-direction-select', { invalid: isInvalid })}>
      <span className="title">
        <h2>Select an Option *</h2>
      </span>
      <div className="options-list">
        <OptionCard
          controlId="requestDataSelector"
          onClick={() => directionValue !== 1 && doOnChange(1)}
          isChecked={directionValue === 1}
          title="Request Data"
          subtitle="You need information about a product, or something else"
        />
        <OptionCard
          controlId="sendDataSelector"
          onClick={() => directionValue !== 2 && doOnChange(2)}
          isChecked={directionValue === 2}
          title="Send Data"
          subtitle="You want to communicate product changes, or something else"
        />
        <OptionCard
          controlId="draftDataSelector"
          onClick={() => directionValue !== 3 && doOnChange(3)}
          isChecked={directionValue === 3}
          title="Draft Data Job"
          subtitle="You want to collaborate within your company before reaching out to other partners"
        />
      </div>
      <div className="invalid-feedback">Please select one of the options.</div>
      <Alert variant="warning" style={{ marginTop: '0.5em' }} show={directionValue === 3}>
        Draft Job: Please remember that all the activity, comments and attachments will be visible
        by the Recipient once you publish this Data Job.
      </Alert>
    </section>
  );
};

DataJobDirectionSelect.defaultProps = {
  directionValue: 1,
  isInvalid: false,
};

DataJobDirectionSelect.propTypes = {
  directionValue: PropTypes.oneOf([1, 2, 3]),
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
};

export { DataJobDirectionSelect };
