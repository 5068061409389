import './SmallProgressBar.scss';
import React from 'react';

import PropTypes from 'prop-types';

// component expects to receive an array of strings for its labels and the index(from 0) for which value is the current step.
const SmallProgressBar = ({ labels, current }) => {
  const getSections = labels.map((item, i) => {
    const barStyle = () => {
      if (i === 0) {
        return { order: 1, borderRadius: '10px 0 0 10px' };
      }
      if (i === labels.length - 1) {
        return { order: 1, borderRadius: '0 10px 10px 0' };
      }
      return { order: 1 };
    };

    return (
      <div key={`small-section-${i}`} className="small-progress-bar-section" style={{ order: i }}>
        <div
          className={i + 1 <= current ? 'small-progress-bar-done' : 'small-progress-bar-todo'}
          style={barStyle()}
        />
        <div
          className={
            i + 1 === current ? 'small-progress-label-current' : 'small-progress-label-todo'
          }
          style={{ order: 2 }}
        >
          {item}
        </div>
      </div>
    );
  });

  return (
    <div
      className="flex-row small-progress-bar"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {getSections}
    </div>
  );
};

SmallProgressBar.propTypes = {
  labels: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
};

export { SmallProgressBar };
