import React, { useEffect, useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import * as ApiCalls from 'api/ApiCalls';
import { useIsMounted } from 'helpers/useIsMounted';

import './DatabotsUsageMetrics.scss';
import { DatabotsUsageMetricsTableDef } from './DatabotsUsageMetricsTableDef';

const DatabotsUsageMetrics = ({ setIsLoading }) => {
  const [botDownloadsData, setBotDownloadsData] = useState(null);
  const [botDownloadsDataLoading, setBotDownloadsDataLoading] = useState(false);

  const [botStartsData, setBotStartsData] = useState(null);
  const [botStartsDataLoading, setBotStartsDataLoading] = useState(false);

  const isMounted = useIsMounted();

  useEffect(() => {
    setIsLoading(!!(botDownloadsDataLoading || botStartsDataLoading));
  }, [botDownloadsDataLoading, botStartsDataLoading, setIsLoading]);

  useEffect(() => {
    if (isMounted.current) {
      setBotDownloadsDataLoading(true);

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/queries/bots-downloads-end-users',
        onSuccess: (res) => {
          if (isMounted.current) {
            setBotDownloadsData(res?.data ?? null);
          }
        },
        onEnd: () => {
          if (isMounted.current) {
            setBotDownloadsDataLoading(false);
          }
        },
      });

      setBotStartsDataLoading(true);

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/queries/bots-started-end-users',
        onSuccess: (res) => {
          if (isMounted.current) {
            setBotStartsData(res?.data ?? null);
          }
        },
        onEnd: () => {
          if (isMounted.current) {
            setBotStartsDataLoading(false);
          }
        },
      });
    }
  }, [isMounted, setIsLoading]);

  return (
    <div className="databots-usage-metrics">
      <h3>Downloads</h3>

      <BootstrapTable
        bordered={false}
        bootstrap4
        keyField="id"
        data={botDownloadsData?.length ? botDownloadsData : []}
        columns={DatabotsUsageMetricsTableDef.botDownloadsColumns}
      />

      <h3>Starts</h3>

      <BootstrapTable
        bordered={false}
        bootstrap4
        keyField="id"
        data={botStartsData?.length ? botStartsData : []}
        columns={DatabotsUsageMetricsTableDef.botStartsColumns}
      />
    </div>
  );
};

export { DatabotsUsageMetrics };
