import './WeeklyOverview.scss';

import React, { useEffect, useState } from 'react';

import queryString from 'query-string';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingText } from 'components/common/LoadingText/LoadingText';
import { WeekPicker } from 'components/common/WeekPicker/WeekPicker';
import { WeeklyMetricsCard } from 'components/metrics/WeeklyMetricsCard/WeeklyMetricsCard';
import { formatDate8601 } from 'helpers/TimeUtils';
import { useIsMounted } from 'helpers/useIsMounted';

const WeeklyOverview = ({ startDate, endDate, setStartDate, setEndDate, tier = 1 }) => {
  const [metrics, setMetrics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    setIsLoading(true);
    const query = {};
    query.tier = tier;
    if (endDate) query.end_date = formatDate8601(endDate);
    if (startDate) query.start_date = formatDate8601(startDate);

    const url = `/weekly-metrics?${queryString.stringify(query)}`;

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: url,
      onSuccess: (res) => {
        if (isMounted.current) {
          const {
            customers,
            customer_users: customerUsers,
            active_customers: activeCustomers,
            active_customer_users: activeCustomerUsers,
            actions,
          } = res.data;

          const customerMetrics = {
            header: 'Customers',
            currentPeriodTotal: customers.current_period,
            previousPeriodTotal: customers.previous_period,
          };

          const customerUserMetrics = {
            header: 'Customer User Accounts',
            currentPeriodTotal: customerUsers.current_period,
            previousPeriodTotal: customerUsers.previous_period,
          };

          const customerActiveMetrics = {
            header: 'Customers - Active',
            currentPeriodTotal: activeCustomers.current_period,
            previousPeriodTotal: activeCustomers.previous_period,
          };

          const customerUserActiveMetrics = {
            header: 'Customer User Accounts - Active',
            currentPeriodTotal: activeCustomerUsers.current_period,
            previousPeriodTotal: activeCustomerUsers.previous_period,
          };

          const actionMetrics = {
            header: 'Actions',
            currentPeriodTotal: actions.current_period,
            previousPeriodTotal: actions.previous_period,
          };

          const metrics = [
            customerMetrics,
            customerUserMetrics,
            customerActiveMetrics,
            customerUserActiveMetrics,
            actionMetrics,
          ];

          const totalCurrentPeriod = metrics.reduce(
            (acc, curr) => acc + curr.currentPeriodTotal,
            0
          );

          const totalPreviousPeriod = metrics.reduce(
            (acc, curr) => acc + curr.previousPeriodTotal,
            0
          );

          const totalMetrics = {
            header: 'Totals',
            currentPeriodTotal: totalCurrentPeriod,
            previousPeriodTotal: totalPreviousPeriod,
          };

          metrics.push(totalMetrics);
          setMetrics(metrics);
        }
      },
      onEnd: () => {
        setIsLoading(false);
      },
    });
  }, [isMounted, setIsLoading, startDate, endDate, tier]);

  return (
    <section className="metrics-overview">
      <div className="metrics-overview-row">
        <div className="metrics-overview-weekly">
          <h1>Weekly Dashboard Insights</h1>
          <div id="weekpicker-container" className="flex-group">
            <WeekPicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      <div className="metrics-overview-row-cards">
        {isLoading && (
          <div className="metrics-overview-row-loading">
            <LoadingText />
          </div>
        )}
        {!isLoading && (
          <>
            {metrics.map((metric) => (
              <WeeklyMetricsCard
                key={metric.header}
                header={metric.header}
                currentPeriodTotal={metric.currentPeriodTotal}
                previousPeriodTotal={metric.previousPeriodTotal}
              />
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export { WeeklyOverview };
