import './DataJobDetailsInput.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { Form, Col } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import DataJobCreateConstants from 'constants/DataJobCreateConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

/**
 * Populates the party selection admins.
 * Admin user first selects Distributor; this action populates manufacturer List.
 * Finally a requestor list is populated from these two.
 *
 * NOTE: the reducers and user constants are still not unified for client/distributor and manufacturer/supplier:
 * DetailsReducer Requestor Type follows old BE pattern of 'client' or 'manufacturer'
 * UserRoleConstants use SUPPLIER vs CLIENT
 * DetailsReducer uses MANUFACTURER and DISTRIBUTOR for 'manufacturer' and 'client
 * CurrentUser profiles use 'manufacturer' and 'client'
 *
 * @param {} details
 * @param {} invalidCheck
 * @param {} setDetails
 * @param {} currentUser
 * @returns Dropdown selectors for distributor, manufacturer, and requestor
 */
const PartySelectAdmin = ({ details, invalidCheck, setDetails, currentUser }) => {
  const isMounted = useIsMounted();
  const [adminDistributorList, setAdminDistributorList] = useState(null);
  const [adminManufacturerList, setAdminManufacturerList] = useState(null);
  const [requestorList, setRequestorList] = useState(null);

  const [selectedDistributor, setSelectedDistributor] = useState([details.distributor]);
  const [selectedManufacturer, setSelectedManufacturer] = useState([details.manufacturer]);
  const [selectedRequestor, setSelectedRequestor] = useState([details.requestor]);

  const getRequestorDropOptions = useCallback(() => {
    return sortByKey(requestorList, 'label') || [];
  }, [requestorList]);

  const updateDistributorList = useCallback(() => {
    if (!currentUser || !isMounted.current) return;
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/distributors/`,
      onSuccess: (r) => {
        if (isMounted.current) {
          setAdminDistributorList(
            r.data.map((item) => ({ value: item.id, label: item.name, obj: item })),
            'label'
          );
        }
      },
      onError: () => {
        toast.error({ message: 'Unable to get distributor list!' });
      },
    });
  }, [currentUser, isMounted]);

  useEffect(() => {
    if (!currentUser || !isMounted.current) return;
    updateDistributorList();
  }, [currentUser, isMounted, updateDistributorList]);

  const onRequestorSelected = useCallback(
    (value) => {
      setSelectedRequestor(value);
      if (value !== null && typeof value.value === 'string') {
        // eslint-disable-next-line no-param-reassign
        value = {
          ...value,
          value: Number(value.value.replace('manufacturer-', '')),
        };
        setDetails({
          type: DataJobCreateConstants.REQUESTOR_TYPE,
          payload: DataJobCreateConstants.MANUFACTURER,
        });
      } else {
        setDetails({
          type: DataJobCreateConstants.REQUESTOR_TYPE,
          payload: DataJobCreateConstants.CLIENT,
        });
      }
      setDetails({ type: DataJobCreateConstants.REQUESTOR, payload: value });
    },
    [setDetails]
  );

  const updateRequestorList = useCallback(
    (type, value) => {
      let newRequestorList = [];
      if (type === DataJobCreateConstants.MANUFACTURER) {
        newRequestorList = [...selectedDistributor, value].filter((el) => el !== null);
      } else if (type === DataJobCreateConstants.DISTRIBUTOR) {
        setSelectedManufacturer([]);
        newRequestorList = [value].filter((el) => el !== null);
      }

      if (
        selectedRequestor !== null &&
        !newRequestorList.some((el) => el.label === selectedRequestor.label)
      ) {
        onRequestorSelected(null);
      }
      setRequestorList(newRequestorList);
    },
    [onRequestorSelected, selectedDistributor, selectedRequestor]
  );

  const onDistributorSelected = useCallback(
    (value) => {
      setSelectedDistributor([value]);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/distributors/${value.value}`,
        onSuccess: (r) => {
          if (isMounted.current) {
            setAdminManufacturerList(
              r.data.manufacturers.map((item) => ({
                // Appended the "manufacturer" tag to the manuf id since across the manufacturer and client objects, some records may have the same id, causing some options to not be displayed in the dropdown

                value: `manufacturer-${item.id}`,
                label: item.name,
                obj: item,
              })),
              'label'
            );
          }
        },
      });

      setDetails({ type: DataJobCreateConstants.DISTRIBUTOR, payload: value });
      updateRequestorList(DataJobCreateConstants.DISTRIBUTOR, value);
    },
    [isMounted, setDetails, updateRequestorList]
  );

  const onManufacturerSelected = useCallback(
    (value) => {
      setSelectedManufacturer([value]);
      updateRequestorList(DataJobCreateConstants.MANUFACTURER, value);
      // Remove the 'manufacturer-' tag from the manufacturer id value before updating it in the Store
      // eslint-disable-next-line no-param-reassign
      value = value ? { ...value, value: Number(value.value.replace('manufacturer-', '')) } : null;
      setDetails({ type: DataJobCreateConstants.MANUFACTURER, payload: value });
    },
    [setDetails, updateRequestorList]
  );

  const SelectDistributor = useCallback(() => {
    return (
      <StyledMultiselect
        id="distribSelect"
        className={invalidCheck.distributor ? 'is-invalid' : null}
        values={selectedDistributor || []}
        options={adminDistributorList}
        optionsSort
        setOnChange={onDistributorSelected}
        isSearchable
        isMulti={false}
        isDisabled={!adminDistributorList}
        isInvalid={invalidCheck.distributor}
      />
    );
  }, [adminDistributorList, invalidCheck.distributor, onDistributorSelected, selectedDistributor]);

  const SelectManufacturer = useCallback(() => {
    return (
      <StyledMultiselect
        id="mfrSelect"
        className={invalidCheck.manufacturer ? 'is-invalid' : null}
        values={selectedManufacturer || []}
        options={adminManufacturerList}
        optionsSort
        setOnChange={onManufacturerSelected}
        isSearchable
        isMulti={false}
        isDisabled={!adminManufacturerList}
        isInvalid={invalidCheck.manufacturer}
      />
    );
  }, [
    adminManufacturerList,
    invalidCheck.manufacturer,
    onManufacturerSelected,
    selectedManufacturer,
  ]);

  const SelectRequestor = useCallback(() => {
    return (
      <StyledMultiselect
        id="reqSelect"
        className={invalidCheck.requestor ? 'is-invalid' : null}
        values={selectedRequestor || []}
        options={getRequestorDropOptions()}
        optionsSort
        setOnChange={onRequestorSelected}
        isSearchable
        isMulti={false}
        isDisabled={!requestorList}
        isInvalid={invalidCheck.requestor}
      />
    );
  }, [
    getRequestorDropOptions,
    invalidCheck.requestor,
    onRequestorSelected,
    requestorList,
    selectedRequestor,
  ]);

  return (
    <Form.Row>
      <Form.Group as={Col} controlId="distributor.controlInp">
        <Form.Label className="data-job-details-selection-text">Distributor *</Form.Label>
        <Form.Control as={SelectDistributor} />
        <Form.Control.Feedback type="invalid">Please select a distributor!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} controlId="manufacturer.controlInp">
        <Form.Label className="data-job-details-selection-text">Manufacturer *</Form.Label>
        <Form.Control as={SelectManufacturer} />
        <Form.Control.Feedback type="invalid"> Please select a manufacturer!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} controlId="request.controlInp">
        <Form.Label className="data-job-details-selection-text">Define Requestor *</Form.Label>
        <Form.Control as={SelectRequestor} />
        <Form.Control.Feedback type="invalid">Please select a requestor!</Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
};

export { PartySelectAdmin };
