import React from 'react';

import PropTypes from 'prop-types';

import { HeaderSelector } from './HeaderSelector';
import './SelectSingleHeaderSection.scss';

const SelectSingleHeaderSection = ({
  title,
  selectedFile,
  selectedHeader,
  setSelectedHeader,
  headersList,
  isLoading,
  placeholder,
  showFirstSheetOnlyNote,
  isMulti = true,
}) => {
  return (
    <section className="header-selection">
      <div className="title">{title}</div>
      {!!showFirstSheetOnlyNote && (
        <div className="note">*Only the first sheet of the file will be analyzed.</div>
      )}
      <div className="content">
        <HeaderSelector
          isMulti={isMulti}
          placeholder={placeholder}
          selectedHeader={selectedHeader}
          setSelectedHeader={setSelectedHeader}
          headersList={headersList}
          disabled={isLoading || !selectedFile}
        />
      </div>
    </section>
  );
};

SelectSingleHeaderSection.defaultProps = {
  selectedFile: null,
  selectedHeader: null,
  placeholder: null,
  headersList: null,
  isMulti: true,
};

SelectSingleHeaderSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placeholder: PropTypes.string,
  selectedFile: PropTypes.object,
  selectedHeader: PropTypes.object,
  setSelectedHeader: PropTypes.func.isRequired,
  headersList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
};

export { SelectSingleHeaderSection };
