import React, { useCallback, useState, useEffect } from 'react';

import { BlockBlobClient } from '@azure/storage-blob';

import * as ApiCalls from 'api/ApiCalls';
import { FileUpload } from 'components/common/FileUpload/FileUpload';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { splitExt } from 'helpers/Utils';
import './NewJobSection.scss';

/**
 * Upload panel for creating a new Bot job
 * @param {function} onJobCreated Callback handler for successful start.
 * @param {function} setIsNewJobInProgress setter for flag
 * @param {string} infoText text information about file upload
 * @param {int} maxFileCount max file accept to upload
 * @param {function} onJobCreated Callback handler for successful start.
 * @param {function} onSelectFiles Callback handler when is need behavior to upload files onSelectFiles should receive files as parameter
 * @param {function} isFileValid function to validate files before upload,
 * @param {int} concurrencyUpload number of concurrency open to upload file default is 4,
 * @param {int} blockSizeUploadFile block size default is 4MB block size
 * @param {string} defaultConfigType configuration  tye,
 * @param {string} onSuccessMessage successful message to upload file',
 * @param {string} botType types available IDS and CDS.
 *
 * @return render
 */
const NewJobFileUpload = ({
  infoText,
  onJobCreated,
  onSelectFiles,
  isFileValid,
  setIsNewJobInProgress,
  botType,
  maxFileCount = 1,
  concurrencyUpload = 4,
  blockSizeUploadFile = 4 * 1024 * 1024, // 4MB block size
  onSuccessMessage = 'File successfully added!',
}) => {
  const isMounted = useIsMounted();
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFileName, setUpLoadingFileName] = useState(false);

  const reset = () => {
    setIsUploadComplete(false);
    setUploadProgress(0);
    setIsUploading(false);
  };

  useEffect(() => {
    if (typeof setIsNewJobInProgress === 'function') {
      setIsNewJobInProgress(isUploading || isUploadComplete);
    }
  });

  const showToast = useCallback(
    (message, type = 'error') => {
      if (isMounted.current) {
        toast[type](message);
      }
    },
    [isMounted]
  );

  const onUploadError = useCallback(
    (err) => {
      if (!err?.response?.data?.message) {
        let toastMessage = 'There was an error creating the job. Please try again.';
        if (err?.code === 'ERR_NETWORK') {
          toastMessage = 'Network error encountered while creating job. Please retry.';
        } else if (err?.response?.status === 400) {
          toastMessage = err?.response?.data?.message;
        }

        showToast(toastMessage);
      }
      reset();
    },
    [showToast]
  );

  const doCreateBotRun = useCallback(
    (files, blobName) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/bots/process-job`,
        data: {
          file_name: files[0].name,
          blob_name: blobName,
          type: botType,
        },
        onSuccess: (res) => {
          const [fName, fExt] = splitExt(res.data?.additional_data[0]?.file_name);
          showToast(`Successfully uploaded "${fName}.${fExt}"`, 'success');

          setIsUploadComplete(true);
          onJobCreated(res.data);
        },
        onError: (err) => {
          onUploadError(err);
        },
        onEnd: () => {
          setIsUploadComplete(false);
          setIsUploading(false);
          setUploadProgress(0);
          setUpLoadingFileName(null);
        },
      });
    },
    [botType, showToast, onJobCreated, onUploadError]
  );

  const doUploadFile = useCallback(
    (files, { sasUrl, blobName }) => {
      setUpLoadingFileName(files[0].name);
      const blobClient = new BlockBlobClient(sasUrl);

      blobClient
        .uploadData(files[0], {
          blockSize: blockSizeUploadFile,
          concurrency: concurrencyUpload,
          blobHTTPHeaders: {
            blobContentType: files[0].type,
          },
          onProgress: (progress) => {
            const percentage = ((progress.loadedBytes / files[0].size) * 100).toFixed(0);
            if (percentage >= 0) setUploadProgress(percentage);
          },
        })
        .then(() => {
          doCreateBotRun(files, blobName);
        })
        .catch((err) => {
          onUploadError(err);
        });
    },
    [blockSizeUploadFile, concurrencyUpload, doCreateBotRun, onUploadError]
  );

  const beginCreateBotRun = useCallback(
    (files) => {
      setIsUploading(true);
      setUploadProgress(0);
      setIsUploadComplete(false);

      const reqBody = {
        file_name: files[0].name,
        file_size: files[0].size,
      };

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/bots/process-job/prepare',
        data: reqBody,
        onSuccess: (res) => {
          if (!res.data.sas_url) {
            showToast('There was an error creating the job. Please try again.');
            reset();
          } else {
            doUploadFile(files, { blobName: res.data.blob_name, sasUrl: res.data.sas_url });
          }
        },
        onError: (err) => {
          onUploadError(err);
        },
      });
    },
    [showToast, doUploadFile, onUploadError]
  );

  const onLocalSelectFiles = (files) => {
    if (typeof onSelectFiles === 'function') {
      onSelectFiles(files);
    } else {
      if (isFileValid(files) && files?.length) {
        beginCreateBotRun(files);
      }
    }
  };

  return (
    <>
      <FileUpload
        onDrop={onLocalSelectFiles}
        maxFileCount={maxFileCount}
        isFileLoading={isUploading}
        uploadProgress={uploadProgress}
        isUploadComplete={isUploadComplete}
        setIsUploadComplete={setIsUploadComplete}
        uploadingFileName={uploadingFileName}
        onSuccessMessage={onSuccessMessage}
        infoText={infoText}
      />
    </>
  );
};

export { NewJobFileUpload };
