import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { statusValueConstants, JOBTYPES } from 'constants/DataJobDetailsConstants';
import { RootHooks } from 'helpers/RootHooks';

const priorityList = [
  { id: 1, name: 'Low' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'High' },
];

const statusList = [
  { id: statusValueConstants.NEW, name: 'New' },
  { id: statusValueConstants.IN_PROCESS, name: 'In Process' },
  { id: statusValueConstants.READY_FOR_APPROVAL, name: 'Ready for Approval' },
  { id: statusValueConstants.COMPLETE, name: 'Data Accepted' },
  { id: statusValueConstants.CANCELED, name: 'Canceled' },
  { id: statusValueConstants.CLOSED, name: 'Closed' },
  { id: statusValueConstants.REJECTED, name: 'Rejected' },
];

const viewOptions = [
  { name: 'All', id: 'all' },
  { name: 'Read', id: true },
  { name: 'Unread', id: false },
];

const DataJobFilterMenu = ({
  isAdmin,
  isDistributor,
  isSupplier,
  isSupplierWithChildren,
  suppliersList,
  distributorsList,
  filterState,
  dataRequestTypeTree,
  activeTab,
  isDisabled,
  changeFilterState,
  doResetFilter,
  doApplyFilter,
  getRequestSubtypes,
}) => {
  const { featureFlags } = RootHooks.useFeatureFlags();

  const renderFilterField = (key, label, options, isMulti, filterState, isClearable) => {
    const localOptions = options;
    const convertedOptions =
      (localOptions &&
        localOptions.length &&
        localOptions.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })) ||
      [];

    let values = _get(filterState, key);

    if (!isMulti && !Array.isArray(values) && values !== undefined && values !== null) {
      values = [values];
    }

    if (values && values.length) {
      values = values.map((item) => convertedOptions.find((o) => o.value === item));
    }

    const convertOption = (v) => {
      let output = null;

      if (isMulti) {
        if (v && v.length) {
          output = v.map((item) => item.value);
        } else {
          output = [];
        }
      } else if (v && v.value !== undefined && v.value !== null) {
        output = v.value;
      }
      return output;
    };

    return (
      <Form.Group
        controlId={key}
        className={`${key} justify-content-between`} // TODO: No styling classes
        as={Row}
      >
        <Form.Label column sm={4}>
          {label}
        </Form.Label>
        <Col sm={7}>
          <StyledMultiselect
            id={`${key}_multiselect_datajob_filterby`}
            values={!isMulti && values}
            defaultValue={isMulti && values}
            options={convertedOptions}
            setOnChange={(v) => changeFilterState(key, convertOption(v))}
            getOptionValue={(option) => option.value}
            closeMenuOnSelect
            isSearchable
            isDisabled={isDisabled}
            isMulti={isMulti}
            isClearable={(isMulti && isClearable !== false) || isClearable === true}
            customClearText="Clear"
            canReset
          />
        </Col>
      </Form.Group>
    );
  };

  return (
    <Container fluid className="filter-dropdown-wrap">
      <Row className="header">
        <Col>
          <div className="header">
            <div className="title">Filter By</div>
          </div>
        </Col>
      </Row>
      <Row className="body">
        <Col>
          {(isAdmin || isDistributor) &&
            renderFilterField('supplier', 'Manufacturer', suppliersList, true, filterState)}
          {(isAdmin || isSupplier) &&
            renderFilterField('distributor', 'Distributor', distributorsList, true, filterState)}
          {isSupplier &&
            isSupplierWithChildren &&
            renderFilterField('supplier', 'Manufacturer', suppliersList, true, filterState)}
          {activeTab !== JOBTYPES.PASS_THRU_FILES &&
            renderFilterField('priority', 'Priority', priorityList, true, filterState)}
          {activeTab !== JOBTYPES.PASS_THRU_FILES &&
            renderFilterField('status', 'Status', statusList, true, filterState)}
          {activeTab !== JOBTYPES.PASS_THRU_FILES &&
            renderFilterField(
              'request_type',
              'Request Type',
              dataRequestTypeTree,
              false,
              filterState,
              true
            )}
          {activeTab !== JOBTYPES.PASS_THRU_FILES &&
            renderFilterField(
              'request_subtype',
              'Request Sub-Type',
              getRequestSubtypes(),
              true,
              filterState
            )}
          {activeTab !== JOBTYPES.PASS_THRU_FILES &&
            featureFlags.ENABLE_READ_FILTER &&
            renderFilterField('viewed', 'Read/Unread', viewOptions, false, filterState)}
        </Col>
      </Row>
      <Row className="actions">
        <Col sm={6}>
          <Button
            className="reset-button"
            variant="link"
            onClick={() => doResetFilter()}
            disabled={isDisabled}
          >
            Reset Filters
          </Button>
        </Col>
        <Col sm={6} style={{ textAlign: 'right' }}>
          <Button
            className="apply-button"
            variant="primary"
            onClick={() => doApplyFilter(filterState)}
            disabled={isDisabled}
          >
            <span className="icon">
              <FontAwesomeIcon icon={['far', 'filter']} />
            </span>{' '}
            Apply Filters
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export { DataJobFilterMenu };
