import { useCallback, useEffect, useState } from 'react';

import * as ApiCalls from 'api/ApiCalls';

export const useDefaultConfigData = ({ defaultConfigType, onApiError }) => {
  const [configData, setConfigData] = useState(null);

  const fetchConfigData = useCallback(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/process-job/default-config/${defaultConfigType}`,
      onSuccess: (res) => {
        setConfigData(res.data);
      },
      onError: (err) => {
        if (onApiError) {
          onApiError(err);
        }
        return null;
      },
    });
  }, [defaultConfigType, onApiError]);
  useEffect(() => {
    fetchConfigData();
  }, [fetchConfigData]);

  return configData;
};
