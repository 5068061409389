import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { Databot } from 'components/databots/Databot/Databot';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { BOT_STATUSES } from 'constants/BotConstants';
import { parseDatabotData } from 'helpers/BotUtils';
import { sortByKey } from 'helpers/Utils';

/**
 * Databots section rendering a fixed list of available databots
 *
 * @param {array} databotsData Available bots data list
 * @param {string} databotsDataStatus Loading status for bot data
 * @param {object} mfrId Selected mfr id
 * @param {boolean} isAdmin Is user admin
 * @param {string} title Custom title string
 * @return render
 */
const SectionDatabots = ({
  parentManufacturerId,
  databotsData,
  databotsDataStatus,
  mfrId,
  title,
}) => {
  const history = useHistory();
  const location = useLocation();

  const convertDatabotsData = () => {
    if (!databotsData?.length) return null;

    const automatedBots = sortByKey(
      databotsData.filter((bot) => bot.automated),
      'created_at',
      true
    );

    const manualBots = sortByKey(
      databotsData.filter((bot) => !bot.automated),
      'name'
    );

    return [...automatedBots, ...manualBots].slice(0, 8);
  };

  // Filter raw bots list
  const dashboardBots = convertDatabotsData();

  const renderDatabots = () => {
    let output = null;

    if (dashboardBots?.length && mfrId) {
      output = dashboardBots.slice(0, 8).map((item, i) => (
        <li className="databot-item" key={i}>
          <Databot
            parentManufacturerId={parentManufacturerId}
            manufacturerId={mfrId}
            bot={{
              ...parseDatabotData(item),
              status: item.automated ? item.bot_status?.status : BOT_STATUSES.STATIC,
            }}
          />
        </li>
      ));
    }

    return output;
  };

  return (
    <section className="data-lakehouse-dashboard-databots">
      <div className="heading">
        <div className="title">
          <span>{title?.length ? title : 'Databots'}</span>
          {databotsDataStatus === ActionStatusConstants.ISBUSY && <LoadingSpinner fast />}
        </div>
        <div className="actions">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => history.push(`/databots${location?.search}`)}
          >
            <span className="label">View All Databots</span>
            <span className="icon" style={{ marginLeft: '0.5em' }}>
              <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
            </span>
          </button>
        </div>
      </div>
      <div className="databots-list-wrap">
        {dashboardBots?.length && mfrId ? (
          <ul className="databots-list">{renderDatabots()}</ul>
        ) : (
          databotsDataStatus === ActionStatusConstants.SUCCESS && (
            <div className="empty-notice">
              <Alert variant="primary">No Databots found.</Alert>
            </div>
          )
        )}
      </div>
    </section>
  );
};

SectionDatabots.defaultProps = {
  parentManufacturerId: null,
  databotsData: null,
  databotsDataStatus: ActionStatusConstants.INITIAL,
  mfrId: null,
  title: null,
};

SectionDatabots.propTypes = {
  parentManufacturerId: PropTypes.number,
  databotsData: PropTypes.array,
  databotsDataStatus: PropTypes.oneOf(Object.values(ActionStatusConstants)),
  mfrId: PropTypes.number,
  title: PropTypes.string,
};

export { SectionDatabots };
