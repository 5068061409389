export const validationCategory = {
  key: 'validation',
  name: 'Validators',
  description: 'We validate by checking industry standard formats.',
  databotsDescription: 'Databots validate by checking industry standard formats.',
};

export const builderCategory = {
  key: 'builder',
  name: 'Builders',
  description: 'We take information you provided and create industry standard descriptions.',
  databotsDescription:
    'Databots take information you provided and create industry standard descriptions.',
};

export const reportCategory = {
  key: 'reports',
  name: 'Reports',
  databotsDescription:
    'Databots check the quality of your data and will show you where your data can improve.',
};

export const databaseCategory = {
  key: 'database',
  name: 'Database',
  databotsDescription: 'Databots that update data ingested by BackboneAI.',
};

export const BOT_CATEGORIES = [
  reportCategory,
  builderCategory,
  validationCategory,
  databaseCategory,
];

export const BOT_STATUSES = {
  NOT_RUN: 'not_run',
  PENDING: 'pending',
  RUNNING: 'running',
  COMPLETED: 'completed',
  STATIC: 'static',
  DEFAULT: 'default',
  FAILED: 'failed',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  AWAITING_APPROVAL: 'awaiting_approval',
  CANCELED: 'canceled',
  UNDER_REVIEW: 'under_review',
  QUALITY_CHECK: 'quality_check',
};

export const BOT_STATUS_LABELS = {
  not_run: 'Not Run',
  pending: 'Pending',
  running: 'Running',
  completed: 'Completed',
  static: 'Static',
  default: 'Default',
  failed: 'Failed',
  approved: 'Approved',
  rejected: 'Rejected',
  awaiting_approval: 'Awaiting Approval',
  canceled: 'Canceled',
  under_review: 'Under Review',
};

export const BOT_SLUGS = {
  PRICING_UPDATE: 'pricing-update',
  BRAND_NAME_ASSIGNMENT: 'brand-name-assignment',
  ATTRIBUTE_RECLASSIFICATION: 'attribute-reclassification',
  IMAGE_QUALITY_REPORT: 'image-quality-report',
  IMAGE_QUALITY: 'image-quality',
  PRICING_VALIDATION: 'pricing-validation',
  SHORT_DESCRIPTION_CREATOR: 'short-description-creator',
  DETAILED_ATTRIBUTE_LABEL_CORRECTION: 'attribute-label-correction',
  ATTRIBUTES_FILL: 'attributes-fill',
  ORDER_DETAILS: 'order-details',
  GAP_ANALYSIS_FIF: 'gap-analysis',
  SHORT_DESCRIPTION_CREATOR_FIF: 'premium-short-description-creator',
  DESCRIPTION_BUILDER: 'description-builder',
  //  CAD_IMAGE_RENDER: 'cad-image-render',
  IMAGE_QUALITY_FIF: 'premium-image-quality',
  ACTION_SHOT_DETECTION: 'action-shot-detection',
  IMAGE_CROPPING: 'image-cropping',
  PLACEHOLDER_IMAGE_DETECTION: 'placeholder-image-detection',
  BACKGROUND_REMOVAL: 'background-removal',
  DUPLICATED_IMAGE_DETECTION: 'duplicated-image-detection',
  IMAGE_ENLARGEMENT: 'image-enlargement',
  WATERMARK_DETECTION_FIF: 'watermark-detection-fif',
  TAXONOMY_FILL: 'taxonomy-fill',
  DIGITAL_ASSET_LINK_VALIDATOR_FIF: 'digital-asset-link-validator',
  ALT_TEXT_CREATOR: 'alt-text-creator',
  GPT_DESCRIPTION_GENERATOR: 'gpt-description-generator',
  GPT_FEATURE_AND_BENEFITS_GENERATOR: 'gpt-fnb-generator',
  ATTRIBUTE_COMPRESSION: 'attribute-compression',
};

export const RECLASSIFY_ATTRIBUTES_BOT = {
  general: { label: 'General', value: 'general' },
  detailed: { label: 'Detailed', value: 'detailed' },
  new: { label: '', value: 'new' },
};

export const IMAGE_QUALITY_ASSESSMENT_KEY_MAPPING = {
  min_image_ppi: {
    label: 'Minimum PPI:',
    sort: 1,
    doMap: (value) => {
      return `${value}px`;
    },
  },
  blur_acceptance: {
    label: 'Is the image blurry?',
    sort: 2,
    doMap: (value) => {
      if (value > 0.7) return 'Yes';
      return 'No';
    },
  },
  is_image_centered: {
    label: 'Is the image centered?',
    sort: 3,
    doMap: (value) => {
      if (value > 0.7) return 'Yes';
      return 'No';
    },
  },
  min_image_size: {
    label: 'Minimum Image Size:',
    sort: 4,
    doMap: (value) => {
      return `${value}px`;
    },
  },
  background_ratio_acceptance: {
    label: 'Is the image perfectly cropped?',
    sort: 5,
    doMap: (value) => {
      if (value > 0.5) return 'Yes';
      return 'No';
    },
  },
  more_than_one_product: {
    label: 'Can the image have more than one product?',
    sort: 6,
    doMap: (value) => {
      if (value) return 'Yes';
      return 'No';
    },
  },
};

export const IMAGE_QUALITY_ASSESSMENT_DEFAULT_CONFIG = {
  min_image_size: 725,
  min_image_ppi: 72,
  blur_acceptance: 0.7,
  background_ratio_acceptance: 0.6,
  is_image_centered: 0.9,
  more_than_one_product: false,
};

export const IMAGE_QUALITY_ASSESSMENT_CONFIG_EXCLUDE = [
  'min_padding_percentage',
  'identify_kit_products',
];

export const IMAGE_QUALITY_ASSESSMENT_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const BOT_TYPES = {
  FILE_IN_FLIGHT: 'file_in_flight',
  DATABASE: 'database',
  CDS: 'CDS',
  IDS: 'IDS',
};

export const BOT_WS_CHANNEL_TYPES = {
  DJ: 'dj',
  STATUS: 'status',
  MFR: 'mfr',
};

const GPT_DESCRIPTION_GENERATOR_TONES = [
  {
    value: 'technical',
    label: 'Technical',
    helper: 'Focuses on precise technical details and specifications of the product.',
  },
  {
    value: 'conversational',
    label: 'Conversational',
    helper: 'Uses friendly and approachable language to describe the product.',
  },
  {
    value: 'professional',
    label: 'Professional',
    helper:
      'Uses language that appeals to emotions and aims to convince the reader to buy the product.',
  },
  {
    value: 'persuasive',
    label: 'Persuasive',
    helper: 'Uses business language and terminology to convey authority and expertise.',
  },
  {
    value: 'descriptive',
    label: 'Descriptive',
    helper: 'Uses vivid language and sensory appeal to describe the product and its benefits.',
  },
];

const GPT_CHARACTER_GENERATOR_LENGTHS = [
  {
    value: 'short',
    label: 'Short (50-100 characters)',
  },
  {
    value: 'medium',
    label: 'Medium (100-300 characters)',
  },
  {
    value: 'long',
    label: 'Long (300-500 characters)',
  },
];

const GPT_DESCRIPTION_GENERATOR_LENGTHS = [
  {
    value: 'short',
    label: 'Short (10-50 words)',
  },
  {
    value: 'medium',
    label: 'Medium (50-150 words)',
  },
  {
    value: 'long',
    label: 'Long (100-400 words)',
  },
];

const GPT_DESCRIPTION_GENERATOR_GOALS = [
  { value: 'catalog', label: 'Product Catalog' },
  {
    value: 'seo',
    label: 'SEO Content',
  },
  {
    value: 'ecommerce',
    label: 'Ecommerce',
  },
  {
    value: 'social',
    label: 'Social Media Post',
  },
  {
    value: 'email',
    label: 'Email Marketing',
  },
];

const GPT_FNB_GENERATOR_GOALS = [
  ...GPT_DESCRIPTION_GENERATOR_GOALS,
  {
    value: 'marketing_sales',
    label: 'Marketing & Sales',
  },
];

const GPT_FNB_GENERATOR_TONES = [
  ...GPT_DESCRIPTION_GENERATOR_TONES,
  {
    value: 'marketing_sales',
    label: 'Marketing & Sales',
  },
];

const GPT_GENERATOR_LANGUAGES = [
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
  {
    value: 'it',
    label: 'Italian',
  },
  {
    value: 'fr',
    label: 'French',
  },
];

const GPT_GRADE_ITEMS = [
  {
    value: 'premium',
    label: 'Premium',
  },
  {
    value: 'standard',
    label: 'Standard',
  },
  {
    value: 'economy',
    label: 'Economy',
  },
];

const GPT_ADDITIONAL_KEY_WORDS = [
  { label: 'Durable', value: 'durable' },
  { label: 'Innovative', value: 'innovative' },
  { label: 'Versatile', value: 'versatile' },
  { label: 'Efficient', value: 'efficient' },
  { label: 'Compact', value: 'compact' },
  { label: 'Powerful', value: 'powerful' },
  { label: 'Reliable', value: 'reliable' },
  { label: 'Lightweight', value: 'lightweight' },
  { label: 'Ergonomic', value: 'ergonomic' },
  { label: 'Stylish', value: 'stylish' },
  { label: 'Advanced', value: 'advanced' },
  { label: 'Wireless', value: 'wireless' },
  { label: 'Secure', value: 'secure' },
  { label: 'Automatic', value: 'automatic' },
  { label: 'Precision', value: 'precision' },
  { label: 'Effortless', value: 'effortless' },
  { label: 'Streamlined', value: 'streamlined' },
  { label: 'Smart', value: 'smart' },
  { label: 'High-Use', value: 'high-use' },
  { label: 'Long-lasting', value: 'long-lasting' },
  { label: 'High-quality', value: 'high-quality' },
  { label: 'Eco-friendly', value: 'eco-friendly' },
  { label: 'Cutting-edge', value: 'cutting-edge' },
  { label: 'Time-saving', value: 'time-saving' },
  { label: 'Energy-saving', value: 'energy-saving' },
  { label: 'High-performance', value: 'high-performance' },
  { label: 'Weather-resistant', value: 'weather-resistant' },
  { label: 'Long-lasting', value: 'long-lasting' },
  { label: 'Space-saving', value: 'space-saving' },
  { label: 'Cost-effective', value: 'cost-effective' },
  { label: 'Noise-canceling', value: 'noise-canceling' },
  { label: 'Crystal-clear', value: 'crystal-clear' },
  { label: 'Fast-charging', value: 'fast-charging' },
  { label: 'Hassle-free', value: 'hassle-free' },
  { label: 'All-in-one', value: 'all-in-one' },
  { label: 'State-of-the-art', value: 'state-of-the-art' },
  { label: 'Engineered design', value: 'engineered design' },
  { label: 'Low maintenance', value: 'low maintenance' },
  { label: 'Versatile applications', value: 'versatile applications' },
  { label: 'Enhanced safety', value: 'enhanced safety' },
];

const GPT_FORMATTING_OPTIONS = [
  {
    label: 'Bullet Points',
    value: 'bullet-point',
  },
  {
    label: 'Sentences',
    value: 'sentence',
  },
];

const ATTRIBUTES_COMPRESSION_CHARACTERS_LENGTH = [
  {
    label: '40 characters',
    value: 40,
  },
  {
    label: '80 characters',
    value: 80,
  },
  {
    label: '100 characters',
    value: 100,
  },
  {
    label: '200 characters',
    value: 200,
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

const BotConstants = {
  validationCategory,
  builderCategory,
  reportCategory,
  databaseCategory,
  BOT_CATEGORIES,
  BOT_STATUSES,
  BOT_STATUS_LABELS,
  BOT_SLUGS,
  RECLASSIFY_ATTRIBUTES_BOT,
  IMAGE_QUALITY_ASSESSMENT_KEY_MAPPING,
  IMAGE_QUALITY_ASSESSMENT_DEFAULT_CONFIG,
  IMAGE_QUALITY_ASSESSMENT_CONFIG_EXCLUDE,
  IMAGE_QUALITY_ASSESSMENT_TYPES,
  BOT_TYPES,
  BOT_WS_CHANNEL_TYPES,
  GPT_DESCRIPTION_GENERATOR_TONES,
  GPT_DESCRIPTION_GENERATOR_LENGTHS,
  GPT_CHARACTER_GENERATOR_LENGTHS,
  GPT_DESCRIPTION_GENERATOR_GOALS,
  GPT_GENERATOR_LANGUAGES,
  GPT_GRADE_ITEMS,
  GPT_ADDITIONAL_KEY_WORDS,
  GPT_FORMATTING_OPTIONS,
  GPT_FNB_GENERATOR_GOALS,
  GPT_FNB_GENERATOR_TONES,
  ATTRIBUTES_COMPRESSION_CHARACTERS_LENGTH,
};

export { BotConstants };
