import './SheetSelect.scss';
import React, { Fragment, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { TUTORIALSTEPS, SHEETSTATUS } from 'constants/DataIngestionConstants';

const Tabs = ({ path, sheets, activeTab, completedSheets, changeActiveTab, handleDisable }) => {
  const { tutorial, errors } = useContext(DataIngestionContext);

  const checkIsTabDisabled = (index) => {
    if (index === 0) return false;
    if (completedSheets?.includes(index - 1)) {
      // Check for catalog item error
      if (path === 'columns' && errors.errorArray.length > 0) {
        if (errors.errorArray.some((error) => error.includes('CATALOG_ITEM'))) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const renderDisableTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Disable sheet
    </Tooltip>
  );

  let tabList = null;
  if (sheets.length > 0) {
    tabList = sheets.map((sheet, index) => (
      <Fragment key={`tab-${sheet.id}`}>
        {/* active tab */}
        {activeTab === index ? (
          <button
            type="button"
            className={`sheet-tab active-tab 
              ${tutorial.step === TUTORIALSTEPS.SHEET && 'tutorial-highlight-tabs'}
              ${
                [SHEETSTATUS.DISABLED_DURING_ROWS, SHEETSTATUS.DISABLED_DURING_COLUMNS].includes(
                  sheet.status
                ) && 'disabled-tab'
              }
              `}
            key={`${sheet.name}-${sheet.id}`}
            onClick={() => changeActiveTab(index)}
          >
            {sheet.errors.length > 0 ? (
              <div className="error">
                <FontAwesomeIcon icon={['fas', 'ban']} size="xs" />
              </div>
            ) : null}
            <div className="sheet-name">{sheet.name}</div>
            {![SHEETSTATUS.DISABLED_DURING_ROWS, SHEETSTATUS.DISABLED_DURING_COLUMNS].includes(
              sheet.status
            ) ? (
              <OverlayTrigger placement="top" overlay={renderDisableTooltip}>
                <div className="disable-tab">
                  <FontAwesomeIcon
                    icon={['fas', 'times-circle']}
                    size="xs"
                    onClick={(e) => {
                      handleDisable(sheet.id);
                      e.stopPropagation();
                    }}
                  />
                </div>
              </OverlayTrigger>
            ) : null}
          </button>
        ) : (
          // Non-active tabs
          <button
            type="button"
            className={`sheet-tab ${
              tutorial.step === TUTORIALSTEPS.SHEET && 'tutorial-highlight-tabs'
            }`}
            key={`${sheet.name}-${sheet.id}`}
            onClick={() => {
              changeActiveTab(index);
            }}
            disabled={checkIsTabDisabled(index)}
          >
            {sheet.errors.length > 0 || sheet.status === SHEETSTATUS.DISABLED_DURING_ROWS ? (
              <div className="error">
                <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} size="xs" />
              </div>
            ) : null}
            <div className="sheet-name">{sheet.name}</div>
            {[
              SHEETSTATUS.USER_EDITED_ROWS,
              SHEETSTATUS.USER_EDITED_COLUMNS,
              SHEETSTATUS.DISABLED_DURING_ROWS,
              SHEETSTATUS.DISABLED_DURING_COLUMNS,
              SHEETSTATUS.USER_VERIFIED_ROWS,
              SHEETSTATUS.USER_VERIFIED_COLUMNS,
            ].includes(sheet.status) && index < activeTab ? (
              <div className="validated-tab">
                <FontAwesomeIcon icon={['fas', 'check-circle']} size="xs" />
              </div>
            ) : null}
          </button>
        )}
      </Fragment>
    ));
  }
  return tabList;
};

export { Tabs };
