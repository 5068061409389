import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { isIE } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';

import { LoadingView } from 'components/common/LoadingView/LoadingView';
import ProductsFilter from 'components/ProductsFilter';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import ViewProductsTableDef from 'helpers/table-defs/ViewProductsTableDef';
import * as UserUtils from 'helpers/UserUtils';
import styleVars from 'scss/vars.scss';
import { getAllProducts } from 'store/actions/Actions';

// TODO: Convert to functional component
// TODO: Remove Redux logic (getAllProducts)
// TODO: Huge component, to be split to managable pieces

class ViewProducts extends React.Component {
  initState = {
    productListPage: 1,
    productListPageSize: 25,
    productListSortField: null,
    productListSortOrder: null,
    filter: {
      txt: '',
      mfr: [],
      pcode: '',
      name: '',
      dscr: '',
      mino: '',
      upc: '',
      propshipname: '',
      companies: [],
    },
    advSearchExpandedForced: false,
  };

  state = {
    ..._cloneDeep(this.initState),
  };

  componentDidMount() {
    this.initFromQueryParams();
  }

  isEmptyFilter(filter) {
    return !(
      filter &&
      (filter.txt ||
        (filter.mfr && filter.mfr.length) ||
        (filter.companies && filter.companies.length) ||
        filter.name ||
        filter.pcode ||
        filter.upc ||
        filter.dscr ||
        filter.propshipname ||
        filter.mino)
    );
  }

  initFromQueryParams() {
    const sParams = { ..._cloneDeep(this.initState) };

    let qParams = null;
    if (this.props.location && this.props.location.search) {
      qParams = queryString.parse(this.props.location.search);

      // prettier-ignore
      if (qParams) {
        if (!UserUtils.isRoleSupplier(this.props.currentUser)) {
          const mfrItems = this.decodeMfrQuery(qParams.mfr);
          if (mfrItems && mfrItems.length) { sParams.filter.mfr = mfrItems }
        }  else {
          const {companies} = qParams
          if (companies && companies.length) { sParams.filter.companies = companies.split(',').map(company => parseInt(company))}
        }
        if (qParams.txt) { sParams.filter.txt = qParams.txt; }
        if (qParams.pcode) { sParams.filter.pcode = qParams.pcode; }
        if (qParams.name) { sParams.filter.name = qParams.name; }
        if (qParams.dscr) { sParams.filter.dscr = qParams.dscr; }
        if (qParams.mino) { sParams.filter.mino = qParams.mino; }
        if (qParams.upc) { sParams.filter.upc = qParams.upc; }
        if (qParams.propshipname) { sParams.filter.propshipname = qParams.propshipname; }
        if (parseInt(qParams.p) > 0) { sParams.productListPage = parseInt(qParams.p); }
        if ([25,50,100].includes(parseInt(qParams.psize))) { sParams.productListPageSize = parseInt(qParams.psize); }
        if (qParams.srt) { sParams.productListSortField = qParams.srt; }
        if (['asc','desc'].includes(qParams.srtdir)) { sParams.productListSortOrder = qParams.srtdir; }
      }
    }

    sParams.advSearchExpandedForced = !!(
      sParams.filter.name ||
      sParams.filter.pcode ||
      sParams.filter.upc ||
      sParams.filter.dscr ||
      sParams.filter.propshipname ||
      sParams.filter.mino
    );

    console.debug('Parsing query params', qParams, sParams);

    this.setState(sParams, () => this.doGetAllProducts());
  }

  decodeMfrQuery(mfrQueryArray) {
    const mfrObjArray = [];

    // if only 1 mfr is in query, it's returned as a string. More mfrs are returned as array.
    if (mfrQueryArray && !Array.isArray(mfrQueryArray)) {
      mfrQueryArray = [mfrQueryArray];
    }

    if (mfrQueryArray && mfrQueryArray.length) {
      for (let i = 0; i < mfrQueryArray.length; i++) {
        const mfrItem = mfrQueryArray[i];
        const mfrItemArray = mfrItem.split('--');
        if (
          mfrItemArray &&
          mfrItemArray.length === 2 &&
          mfrItemArray[0].length &&
          mfrItemArray[1].length
        ) {
          mfrObjArray.push({ value: mfrItemArray[0], label: mfrItemArray[1] });
        }
      }
    }

    return mfrObjArray;
  }

  encodeMfrQuery(mfrArray) {
    const mfrStringArray = [];

    if (mfrArray && mfrArray.length) {
      for (let i = 0; i < mfrArray.length; i++) {
        const mfrItem = mfrArray[i];
        if (mfrItem) {
          mfrStringArray.push(`${mfrItem.value}--${mfrItem.label}`);
        }
      }
    }

    return mfrStringArray;
  }

  doGetAllProducts() {
    const filterPayload = {};

    filterPayload.offset = (this.state.productListPage - 1) * this.state.productListPageSize;
    filterPayload.limit = this.state.productListPageSize;
    const advSearch = {};
    if (this.state.filter) {
      if (this.state.filter.name) advSearch.name = this.state.filter.name;
      if (this.state.filter.pcode) advSearch.code = this.state.filter.pcode;
      if (this.state.filter.upc) advSearch.upc = this.state.filter.upc;
      if (this.state.filter.dscr) advSearch.description = this.state.filter.dscr;
      if (this.state.filter.propshipname)
        advSearch.proper_shipping_name = this.state.filter.propshipname;
      if (this.state.filter.mino) advSearch.mino_code = this.state.filter.mino;
      if (this.state.filter.mfr && this.state.filter.mfr.length)
        advSearch.manufacturers = this.state.filter.mfr.map((item) => item.value);
      if (this.state.filter.companies && this.state.filter.companies.length)
        advSearch.companies = this.state.filter.companies;
    }

    let filter = null;

    if (!_isEmpty(advSearch) || this.state.filter.txt) {
      filter = {};
      if (!_isEmpty(advSearch)) {
        filter.advanced_search = advSearch;
      }
      if (this.state.filter.txt) {
        filter.keyword = this.state.filter.txt;
      }
    }

    if (filter) {
      filterPayload.filter = filter;
    }

    if (this.state.productListSortField) {
      filterPayload.sort_field = this.state.productListSortField;
      filterPayload.sort_order = this.state.productListSortOrder || 'asc';
    } else {
      if (this.isEmptyFilter(this.state.filter)) {
        filterPayload.sort_field = 'updated_at';
        filterPayload.sort_order = this.state.productListSortOrder || 'desc';
      }
    }
    this.props.getAllProducts(filterPayload);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.initFromQueryParams();
    }
  }

  // prettier-ignore
  updateUrlQuery({ filter, p, psize, srt, srtdir }) {
    const queryObj = {};

    // prettier-ignore
    if (filter) {
      if (!UserUtils.isRoleSupplier(this.props.currentUser)) {
        const mfrQuery = this.encodeMfrQuery(filter.mfr);
        if (mfrQuery && mfrQuery.length) {queryObj.mfr = mfrQuery}
      } else {
        const companiesQuery = filter.companies
        if (companiesQuery && companiesQuery.length) {queryObj.companies = companiesQuery.join(',')}
      }
      if (filter.txt) {queryObj.txt = filter.txt}
      if (filter.pcode) {queryObj.pcode = filter.pcode}
      if (filter.name) {queryObj.name = filter.name}
      if (filter.dscr) {queryObj.dscr = filter.dscr}
      if (filter.mino) {queryObj.mino = filter.mino}
      if (filter.upc) {queryObj.upc = filter.upc}
      if (filter.propshipname) {queryObj.propshipname = filter.propshipname}
    }

    if (p > 1) {queryObj.p = p}
    if (psize > 0 && psize !== this.initState.productListPageSize) {queryObj.psize = psize}
    if (srt && srt.length && srt !== this.initState.productListSortField ) {queryObj.srt = srt}
    if (srtdir && srtdir.length && srtdir !== this.initState.productListSortOrder ) {queryObj.srtdir = srtdir}

    if (this.props.location && this.props.location.search) {
      const queryObjPrev = queryString.parse(this.props.location.search);
      console.debug("About to change filter URL query", queryObj, queryObjPrev);
      if (_isEqual(queryObj, queryObjPrev)) {
        console.debug("Search query is the same");
        return;
      }
    }

    console.debug("Generating filter URL query", filter, queryObj);

    const qString = queryString.stringify(queryObj, { skipNull: true });

    this.props.history.push({
      search: qString && qString.length ? `?${qString}` : null,
    });
  }

  goToProductDetails(productId) {
    if (!(productId > 0)) {
      return;
    }

    this.props.history.push(`/product/${productId}`);
  }

  // Handle paging, sorting, page size changes
  onTableChange(type, { page, sizePerPage, sortField, sortOrder }) {
    if (
      this.state.productListPage === page &&
      this.state.productListPageSize === sizePerPage &&
      this.state.productListSortField === sortField &&
      this.state.productListSortOrder === sortOrder
    ) {
      return;
    }
    if (['pagination', 'sort'].includes(type)) {
      const resetPage = !(
        this.state.productListPageSize === sizePerPage &&
        this.state.productListSortField === sortField &&
        ((!this.state.productListSortOrder && !sortOrder) ||
          this.state.productListSortOrder === sortOrder)
      );

      this.updateUrlQuery({
        filter: this.state.filter,
        p: resetPage ? this.initState.productListPage : page,
        psize: sizePerPage,
        srt: sortField,
        srtdir: sortOrder,
      });
    }
  }

  onChangeFilter({ filter, doFilter = false }) {
    const fcpy = _cloneDeep(filter);
    const srt = this.state.productListSortField;
    const srtdir = this.state.productListSortOrder;
    this.setState({ filter: fcpy }, () => {
      if (doFilter) {
        this.updateUrlQuery({
          filter: this.state.filter,
          p: this.initState.productListPage,
          psize: this.initState.productListPageSize,
          srt,
          srtdir,
        });
      }
    });
  }

  onClearFilter() {
    const srt = this.state.productListSortField;
    const srtdir = this.state.productListSortOrder;

    this.setState(
      {
        ..._cloneDeep(this.initState),
      },
      () => {
        this.updateUrlQuery({
          filter: this.state.filter,
          p: this.initState.productListPage,
          psize: this.initState.productListPageSize,
          srt,
          srtdir,
        });
      }
    );
  }

  sortByRelevance() {
    this.updateUrlQuery({
      filter: this.state.filter,
      p: this.initState.productListPage,
      psize: this.initState.productListPageSize,
      srt: null,
      srtdir: null,
    });
  }

  isLastPageBeyondMax() {
    const currPageMaxOffset = this.state.productListPage * this.state.productListPageSize;
    return currPageMaxOffset >= 10000;
  }

  renderSummary() {
    let indexStart,
      offset,
      indexEnd = 0;

    const total =
      this.props.getAllProductsResponse.total ||
      this.props.getAllProductsResponse.products.length ||
      0;
    if (total > 0 && this.state.productListPageSize) {
      offset = (this.state.productListPage - 1) * this.state.productListPageSize;
      indexStart = offset + 1;
      indexEnd = offset + this.state.productListPageSize;
      if (indexEnd > total) {
        indexEnd = total;
      }
    }

    const summary =
      total && indexStart && indexEnd ? (
        <div className="results-summary">
          {`Showing ${indexStart}-${indexEnd} of ${total.toLocaleString('en-US')}`}
        </div>
      ) : null;
    return summary;
  }

  render() {
    const isLoading =
      this.props.getAllProductsResponse.status === ActionStatusConstants.ISBUSY &&
      !(
        this.props.getAllProductsResponse.products &&
        this.props.getAllProductsResponse.products.length > 0
      );

    return (
      <>
        <div className={`content content-fluid view-products${isLoading ? ' loading' : ''}`}>
          <Helmet bodyAttributes={{ 'data-page': 'view-products' }}>
            <title>Products Processed by BackboneAI</title>
          </Helmet>
          <h1>Products Processed by BackboneAI</h1>
          <ProductsFilter
            filterValue={this.state.filter}
            onChangeFilter={({ filter, doFilter = false }) =>
              this.onChangeFilter({ filter, doFilter })
            }
            onClearFilter={() => this.onClearFilter()}
            searchResultsStats={{
              total: _get(this.props, 'getAllProductsResponse.total', null) || 0,
              page: this.state.productListPage,
              pageSize: this.state.productListPageSize,
            }}
            childrenCompanies={_get(this.props, 'getAllProductsResponse.children', []) || []}
            forceExpand={this.state.advSearchExpandedForced}
            currentUser={this.props.currentUser}
          />
          {isLoading ? <div className="products-top-status">Loading data...</div> : null}

          {this.props.getAllProductsResponse.status === ActionStatusConstants.SUCCESS &&
          !(
            this.props.getAllProductsResponse.products &&
            this.props.getAllProductsResponse.products.length > 0
          ) ? (
            <div className="products-top-status">No products found.</div>
          ) : null}

          {this.props.getAllProductsResponse.status === ActionStatusConstants.FAILURE ? (
            <div className="products-top-status">
              <Alert variant="danger">Could not get products list from server.</Alert>
            </div>
          ) : null}

          {this.props.getAllProductsResponse.products &&
          this.props.getAllProductsResponse.products.length > 0 ? (
            <>
              {!this.isEmptyFilter(this.state.filter) && this.state.productListSortField ? (
                <span className="link" onClick={() => this.sortByRelevance()}>
                  Sort by Relevance
                </span>
              ) : !this.isEmptyFilter(this.state.filter) ? (
                <span>Sorted by Relevance</span>
              ) : null}

              <div className="bootstrap-table-wrap">
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    page: this.state.productListPage,
                    sizePerPage: this.state.productListPageSize,
                    sizePerPageList: [
                      { text: '25', value: 25 },
                      { text: '50', value: 50 },
                      { text: '100', value: 100 },
                    ],
                    totalSize: this.props.getAllProductsResponse
                      ? this.props.getAllProductsResponse.total
                      : null,
                    showTotal: true,
                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => {
                      const selectOpts =
                        (options &&
                          options.map((item) => {
                            return { value: item.page, label: item.text };
                          })) ||
                        [];

                      const getSelectValue = () => {
                        if (currSizePerPage && selectOpts && selectOpts.length) {
                          for (let i = 0; i < selectOpts.length; i++) {
                            if (selectOpts[i].value === parseInt(currSizePerPage)) {
                              return selectOpts[i];
                            }
                          }
                        }
                        return null;
                      };

                      return (
                        <>
                          <Select
                            className="page-size-selector"
                            options={selectOpts}
                            value={getSelectValue()}
                            onChange={(v) => v && onSizePerPageChange(v.value)}
                            isClearable={false}
                            menuPlacement="auto"
                            isSearchable={false}
                            isMulti={false}
                            components={{
                              SingleValue: ({ children, ...props }) => {
                                if (
                                  !(children && typeof children === 'string' && children.length)
                                ) {
                                  return <span className="selected-wrapper empty">Select...</span>;
                                }
                                return (
                                  <span className="selected-wrapper">
                                    <span className="selected-prefix">Showing:</span>&nbsp;
                                    <span className="selected-value">{children}</span>&nbsp;
                                    <span className="selected-suffix">items</span>
                                  </span>
                                );
                              },
                              IndicatorSeparator: null,
                              DropdownIndicator: () => (
                                <FontAwesomeIcon
                                  icon="caret-down"
                                  style={{ marginLeft: '8px', marginRight: '8px' }}
                                />
                              ),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '.5rem',
                              colors: {
                                ...theme.colors,
                                primary: styleVars.colors_selectOrangeLight,
                                primary25: styleVars.colors_selectOrangeLight,
                              },
                            })}
                            styles={{
                              container: (styles) => ({
                                ...styles,
                                width: '13em',
                              }),
                              control: (styles) => ({
                                ...styles,
                                borderWidth: 0,
                                height: isIE ? '3em' : 'auto',
                              }),
                              menu: (styles) => ({
                                ...styles,
                                marginTop: '.3rem',
                              }),
                            }}
                          />
                        </>
                      );
                    },
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <>
                      <BootstrapTable
                        remote
                        {...paginationTableProps}
                        onTableChange={(type, newState) => this.onTableChange(type, newState)}
                        defaultSorted={
                          this.state.productListSortField
                            ? [
                                {
                                  dataField: this.state.productListSortField,
                                  order: this.state.productListSortOrder,
                                },
                              ]
                            : null
                        }
                        bordered={false}
                        bootstrap4
                        keyField="id"
                        data={this.props.getAllProductsResponse.products}
                        columns={ViewProductsTableDef.columns}
                        rowEvents={{
                          onClick: (e, row, rowIndex) =>
                            this.goToProductDetails(row && row.id ? row.id : null),
                        }}
                      />
                      {paginationProps.totalSize > 0 ? (
                        <div className="react-bootstrap-table-pagination">
                          <PaginationListStandalone {...paginationProps} />
                          {this.renderSummary()}
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                      ) : null}
                    </>
                  )}
                </PaginationProvider>
              </div>
              {this.isLastPageBeyondMax() ? (
                <div className="products-top-status">
                  <Alert variant="info">
                    <div style={{ textAlign: 'center' }}>
                      You reached the maximum number of pages. Please use more specific search
                      criteria to list see more items.
                    </div>
                  </Alert>
                </div>
              ) : null}
            </>
          ) : null}
          <div className="loading-ovly">
            <LoadingView />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    getAllProductsResponse: {
      status: state.GetAllProductsReducer.status,
      err:
        state.GetAllProductsReducer.payload && state.GetAllProductsReducer.payload.err
          ? state.GetAllProductsReducer.payload.err
          : null,
      products:
        state.GetAllProductsReducer.payload && state.GetAllProductsReducer.payload.products
          ? state.GetAllProductsReducer.payload.products
          : null,
      total:
        state.GetAllProductsReducer.payload && state.GetAllProductsReducer.payload.total
          ? state.GetAllProductsReducer.payload.total
          : null,
      children: state.GetAllProductsReducer.payload?.children,
    },
  };
};

const mapDispatchToProps = {
  getAllProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewProducts));
