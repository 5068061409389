import React, { useEffect, useCallback, useMemo } from 'react';

import 'helpers/LibFontAwesome';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'Root.scss';

import _isEqual from 'lodash/isEqual';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { MaintenanceView } from 'components/common/MaintenanceView/MaintenanceView';
import { Footer } from 'components/layout/Footer';
import { Header } from 'components/layout/Header';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import ActionTypeConstants from 'constants/ActionTypeConstants';
import { RootHooks } from 'helpers/RootHooks';
import { RootRoutes } from 'RootRoutes';

/**
 * Initialize global app state - get user profile and feature flags,
 * then render layout and router depending on state
 *
 * @returns Render
 */
const Root = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  // TODO: Refactor this to not use redux
  // Holds intermediate data while we are still using redux for user data mgmt
  const reduxUser = useSelector((state) => state?.GetUserProfileReducer.payload || {});
  const reduxUserStatus = useSelector((state) => state?.GetUserProfileReducer.status || null);

  const { activeUser, setActiveUser, activeUserStatus, setActiveUserStatus } =
    RootHooks.useActiveUser();

  const { featureFlags } = RootHooks.useFeatureFlags();

  // TODO: Rework this to happen here, not through redux
  // Get current user on first load
  useEffect(() => {
    dispatch({ type: ActionTypeConstants.GET_USER_PROFILE });
  }, [dispatch]);

  // TODO: Possible optimizations for reducing not needed calls
  // Set active user when redux user becomes available
  useEffect(() => {
    setActiveUser(reduxUser);
  }, [setActiveUser, JSON.stringify(reduxUser)]);

  // TODO: When user mgmt is moved here from redux, this won't be needed
  // Updates loading status from redux
  useEffect(() => {
    setActiveUserStatus(reduxUserStatus);
  }, [setActiveUserStatus, reduxUserStatus]);

  // TODO: Separation of concerns. Rework layout so layout config(wrappers) can be set into the views directly, not here
  // Renders wrapped layout if needed
  const mainView = useMemo(() => {
    const showMinimalLayout = !!(
      location?.pathname &&
      ['/login', '/reset-password', '/sign-up', '/sign-up-request'].includes(location?.pathname)
    );

    return (
      <>
        <Helmet titleTemplate="Backbone - %s" defaultTitle="Backbone" />
        {!showMinimalLayout && <Header />}
        <div className={`${showMinimalLayout ? '' : 'main-content'}`}>
          <RootRoutes userRole={activeUser?.role ?? null} />
        </div>
        {!showMinimalLayout && <Footer />}
      </>
    );
  }, [activeUser?.role, location?.pathname]);

  // TODO: Tangled logic. Needs simplification
  // Renders view, maintenance or loading screen
  const renderAppWithProfile = useCallback(() => {
    // TODO: Implement global loader
    let view = <LoadingView />;

    // We need a response from getUserProfile, no matter successful or failed.
    const shouldRenderApp =
      activeUserStatus === ActionStatusConstants.SUCCESS ||
      activeUserStatus === ActionStatusConstants.FAILURE;

    if (
      featureFlags?.SHOW_MAINTENANCE_PAGE_ALWAYS === true ||
      (!shouldRenderApp && activeUserStatus === ActionStatusConstants.SERVER_ERROR)
    ) {
      view = <MaintenanceView />;
    } else if (shouldRenderApp) {
      view = mainView;
    }

    return view;
  }, [activeUserStatus, featureFlags?.SHOW_MAINTENANCE_PAGE_ALWAYS, mainView]);

  return (
    <>
      {renderAppWithProfile()}
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={7000}
        newestOnTop
        icon={false}
      />
    </>
  );
};

export { Root };
