import React from 'react';

import { Helmet } from 'react-helmet';

import { DataJobPanel } from 'components/data-job/DataJobPanel/DataJobPanel';

const ViewDataJob = ({ id }) => {
  return (
    <>
      <div className="content content-fluid view-data-job">
        <Helmet>
          <title>View Data Job</title>
        </Helmet>
        <DataJobPanel id={id} />
      </div>
    </>
  );
};

export { ViewDataJob };
