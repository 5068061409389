import './LoadingText.scss';

import React from 'react';

import PropTypes from 'prop-types';

/**
 * LoadingText.
 *
 * @param {Object} prop.style style prop for loading text
 * @param {string} prop.text custom text
 */
const LoadingText = ({ style, text }) => {
  return (
    <p className="loading-text" style={style}>
      {text ?? 'Loading'}
    </p>
  );
};

LoadingText.defaultProps = {
  style: null,
  text: null,
};

LoadingText.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
};

export { LoadingText };
