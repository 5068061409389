import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import ScoreScaleMin from 'components/ScoreScaleMin';

const _component = ({ data }) => {
  const getScoreStatus = (score, isLabel = false) => {
    let output = null;

    if (typeof score === 'number') {
      if (score >= 95) {
        output = isLabel ? 'Good' : 'good';
      } else if (score >= 75) {
        output = isLabel ? 'Acceptable' : 'acceptable';
      } else {
        output = isLabel ? 'Poor' : 'poor';
      }
    }

    return output;
  };

  const renderSuggestionInteraction = () => {
    const suggestion = _get(data, "suggestion.suggestion");
    const criteria = _get(data, "criteria")

    const triggerEl = (
      <div className={`suggestion${suggestion || criteria ? " clickable" : ""}`}>
        <span className="icon">
          <FontAwesomeIcon icon={['fas', 'info']} />
        </span>
      </div>
    );
    let output = triggerEl;

    if (suggestion || criteria) {
      output = (
        <OverlayTrigger
          trigger="click"
          placement="top"
          rootClose={true}
          overlay={
            <Popover className="popover-attr-tooltip">
              <Popover.Content>{suggestion || criteria}</Popover.Content>
            </Popover>
          }
        >
          {triggerEl}
        </OverlayTrigger>
      );
    }

    return output;
  };

  return (
    <div className="attr-item">
      <div className="label">{data.label}</div>
      {renderSuggestionInteraction()}
      <ScoreScaleMin
        value={
          typeof data.percentage === 'number' && typeof data.value === 'number'
            ? Math.round(data.percentage)
            : null
        }
        className={getScoreStatus(data.percentage)}
        nullLabel="N/A"
        minProgressVisible="0.5"
      />
    </div>
  );
};

export default _component;
