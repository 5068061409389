import React from 'react';

import './DataLakehouseDashboard.scss';
import './DataLakehouseDashboardSelfService.scss';

import { SectionImgBotEngine } from './private/SectionImgBotEngine';

/**
 * Renders Data Lakehouse dashboard for SelfService role
 *
 * @return render
 */
const DataLakehouseDashboardSelfService = () => {
  return (
    <div className="data-lakehouse-dashboard data-lakehouse-dashboard-self-service">
      <SectionImgBotEngine showStats />
    </div>
  );
};

export { DataLakehouseDashboardSelfService };
