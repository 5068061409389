import { BotConstants } from 'constants/BotConstants';

const CONFIG = {
  [BotConstants.BOT_SLUGS.ACTION_SHOT_DETECTION]: {
    title: 'Image Transformation: Action Shot Detection',
    subtitle:
      'Start your data automation journey by identifying action shots. Simply upload a file or select a file from the dropdown, and let our databots do the rest.',
    confirmDialogTitle: 'Run Action Shot Detection Databot?',
    confirmDialogBodyText: 'Run the Action Shot Detection Databot',
    allowImgArchive: true,
  },
  [BotConstants.BOT_SLUGS.BACKGROUND_REMOVAL]: {
    title: 'Image Transformation: Background Removal',
    subtitle:
      'Remove image shadows and backgrounds easily! To get started, upload a .zip file of images, or a .xlsx/.csv with a column for image links.',
    confirmDialogTitle: 'Run Background Removal Databot?',
    confirmDialogBodyText: 'Run the Background Removal Databot',
    allowImgArchive: true,
  },
  [BotConstants.BOT_SLUGS.DUPLICATED_IMAGE_DETECTION]: {
    title: 'Image Transformation: Duplicated Image Detection',
    subtitle:
      'To get started, upload a .zip file of images, or a .xlsx/.csv with a column for image links.',
    confirmDialogTitle: 'Run Duplicated Image Detection Databot?',
    confirmDialogBodyText: 'Run the Duplicated Image Detection Databot',
    allowImgArchive: true,
  },
  [BotConstants.BOT_SLUGS.IMAGE_CROPPING]: {
    title: 'Image Transformation: Image Cropping',
    subtitle:
      'Remove excess white space and ensure optimum product image fit. To get started, upload a .zip file of images, or a .xlsx/.csv with a column for image links.',
    confirmDialogTitle: 'Run Image Cropping Databot?',
    confirmDialogBodyText: 'Run the Image Cropping Databot',
    allowImgArchive: true,
  },
  [BotConstants.BOT_SLUGS.IMAGE_ENLARGEMENT]: {
    title: 'Image Transformation: Image Enlargement',
    subtitle:
      'To get started, upload a .zip file of images, or a .xlsx/.csv with a column for image links. We will scale your images by preserving the aspect ratio until the largest dimension reaches 1000px limit. Example: 100x20 image will be set to 1000x200. If your images are too small, results may be inaccurate',
    confirmDialogTitle: 'Run Image Enlargement Databot?',
    confirmDialogBodyText: 'Run the Image Enlargement Databot',
    allowImgArchive: true,
  },
  [BotConstants.BOT_SLUGS.IMAGE_QUALITY_FIF]: {
    title: 'Image Quality Assessment Databot',
    subtitle:
      'Start your data automation journey by validating digital assets according to your needs. Simply upload a file or select a file from the dropdown, and let our databots do the rest.',
    confirmDialogTitle: 'Run Image Quality Assessment Databot?',
    confirmDialogBodyText: 'Run the Image Quality Assessment Databot',
    allowImgArchive: true,
  },
  [BotConstants.BOT_SLUGS.PLACEHOLDER_IMAGE_DETECTION]: {
    title: 'Image Transformation: Placeholder Image Detection',
    subtitle:
      'To get started, upload a .zip file of images, or a .xlsx/.csv with a column for image links.',
    confirmDialogTitle: 'Run Placeholder Image Detection Databot?',
    confirmDialogBodyText: 'Run the Placeholder Image Detection Databot',
    allowImgArchive: true,
  },
  [BotConstants.BOT_SLUGS.WATERMARK_DETECTION_FIF]: {
    title: 'Watermark Detection Databot',
    subtitle:
      'To get started, upload a .zip file of images, or a .xlsx/.csv with a column for image links.',
    confirmDialogTitle: 'Run Watermark Detection Databot?',
    confirmDialogBodyText: 'Run the Watermark Detection Databot',
    allowImgArchive: true,
  },
  [BotConstants.BOT_SLUGS.DIGITAL_ASSET_LINK_VALIDATOR_FIF]: {
    title: 'Digital Asset Link Validator Databot',
    subtitle: 'To get started, upload a .csv/.xlsx with a column for image links.',
    confirmDialogTitle: 'Run Digital Asset Link Validator Databot?',
    confirmDialogBodyText: 'Run the Digital Asset Link Validator Databot',
    allowImgArchive: false,
  },
};

const GenericFifBotConstants = {
  CONFIG,
};

export { GenericFifBotConstants };
