/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import './SelectionModal.scss';

import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import { isIE } from 'react-device-detect';

/**
 * Modal with an array of selection cards.
 *
 * @callback {function} onContinue - called when user clicks 'Continue' button. Param selectedItem sent to callback
 * @param {object} dialogClassName
 * @param {any} header
 * @param {object} footer - optional Default is OK / Cancel
 * @param {array} options - array of objects containing selection options
 * @param {object[]} options - array of objects containing selection options
 * @param {string} options.title - title of each option
 * @param {string} options.key - key value for the option (identifies option to the parent)
 * @param {string} options.description - description of each option
 * @param {boolean} options.ischecked - option selected
 * @callback {function} options.callback - performed on click of option
 * @param {boolean} centered
 * @param {boolean} isVisible
 * @param {function} setIsVisible
 * @param {string} backdrop
 * @returns render selection modal
 */

const SelectionModal = ({
  onOpen = () => {},
  onContinue,
  dialogClassName,
  header,
  footer,
  options,
  centered = true,
  isVisible,
  setIsVisible,
  backdrop,
}) => {
  const [checkedItem, setCheckedItem] = useState(null);

  const _setIsVisible = useCallback(
    (state) => {
      if (state) {
        typeof onOpen === 'function' && onOpen();
      } else {
        typeof onContinue === 'function' && onContinue();
      }
      setIsVisible(state);
    },
    [onContinue, onOpen, setIsVisible]
  );

  const renderPart = (part) => {
    if (part) {
      if (typeof part === 'function') {
        return part({ setIsVisible: _setIsVisible });
      }
      return part;
    }

    return null;
  };

  const renderOptions = () => {
    const cards = options.map((choice, i) => (
      <div
        role="dialog"
        controlId={`controlId-${i}`}
        key={`choice ${i}`}
        onClick={() => setCheckedItem(i)}
        isChecked={checkedItem === i}
        title={choice.title}
        className={classNames(
          'option-wrap',
          { [`option-wrap-isIE`]: isIE },
          {
            active: checkedItem === i,
          }
        )}
      >
        <div className="option-input">
          <Form.Check
            type="radio"
            readOnly
            checked={checkedItem === i}
            label={choice.title}
            className="form-check-label"
          />
          <div className="subtitle">{choice.description}</div>
        </div>
      </div>
    ));
    return cards;
  };

  const triggerOnContinue = () => {
    onContinue(options[checkedItem]);
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => _setIsVisible(false)}
      onFocus={() => {
        typeof onOpen === 'function' && onOpen();
      }}
      backdrop={backdrop}
      className={classNames('modal-panel-detach')}
      dialogClassName={classNames(dialogClassName)}
      centered={centered}
    >
      <Modal.Header closeButton>
        {header && <Modal.Title>{renderPart(header)}</Modal.Title>}
      </Modal.Header>
      <Modal.Body className="modal-panel-detach-body-text options-list">
        {renderOptions()}
      </Modal.Body>
      {footer && <Modal.Footer>{renderPart(footer)}</Modal.Footer>}
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsVisible(false)}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={checkedItem === null}
          onClick={() => triggerOnContinue()}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SelectionModal.defaultProps = {
  onOpen: () => {},
  onContinue: () => {},
  header: null,
  footer: null,
  options: [],
  centered: true,
  isVisible: false,
  setIsVisible: () => {},
  backdrop: 'static',
};

SelectionModal.propTypes = {
  onOpen: PropTypes.func,
  onContinue: PropTypes.func,
  header: PropTypes.any,
  footer: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      description: PropTypes.string,
      ischecked: PropTypes.boolean,
      callback: PropTypes.func,
    })
  ),
  centered: PropTypes.bool,
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  backdrop: PropTypes.string,
};

export { SelectionModal };
