import './EditModal.scss';
import React, { useState, useContext, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { ModalRestart } from 'components/data-ingestion/DataIngestionSheetSelect/SheetModals';
import { MODALTYPES, SHEETSTATUS } from 'constants/DataIngestionConstants';

/**
 * Creates a modal for editing column names
 *
 * @param {*} id id of the column
 * @param {*} value current value for the column
 * @param {*} options array of alternative values
 * @param {*} handleDataChanged function for updating the value and marking it verified
 * @param {*} editParentVisible function for updating the visibility value of parent
 * @param {*} sheetName sheet name being edited
 * @param {*} errorMessage pass an object containing error messages
 * @param {*} sheets pass an object containing sheets
 * @param {*} activeSheetData pass an object containing data for active sheet
 * @param {*} doUpdateSheet function for updating the sheet corresponding to update column
 * @returns object
 */

const EditModal = ({
  id,
  value,
  originalValue,
  options,
  handleDataChanged,
  editParentVisible,
  sheetName,
  errorMessage = null,
  activeSheetData,
  doUpdateSheet,
  setIsLoading,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [searchDisplay, setSearchDisplay] = useState({
    label: (
      <div>
        <FontAwesomeIcon style={{ marginRight: '8px' }} icon={['far', 'search']} size="sm" /> Search
        Labels
      </div>
    ),
    value: '',
  });
  const [sortedOptions, setSortedOptions] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [commentValue, setCommentValue] = useState('');
  const [visible, setVisible] = useState(true);
  const [prevId, setPrevId] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [modalFooter, setModalFooter] = useState(null);
  const {
    jobData,
    attachmentData,
    userIsBacktracking,
    backtrackingChanges,
    setBacktrackingChanges,
  } = useContext(DataIngestionContext);
  const location = useLocation();

  useEffect(() => {
    setSortedOptions([...options.sort()]);
  }, [options]);

  // hacky solution to make the user hitting escape not break functionality
  if (id !== prevId) {
    setVisible(true);
    setSelectedValue(value);
    setPrevId(id);
  }

  useEffect(() => {
    editParentVisible(visible);
  }, [editParentVisible, visible]);

  const doValidateEdit = (modalConfirmed = false) => {
    // Show backtrack warning if this is first backtracking change
    if (!modalConfirmed) {
      // Modal hasn't been confirmed yet
      if (
        // but don't throw this warning if user is making first changes coming from rows
        // (!sheets.some((sheet) => sheet.status === SHEETSTATUS.PENDING_COLUMN) && // If no sheets have pending col status and
        //   sheets.some((sheet) => sheet.status === SHEETSTATUS.USER_EDITED_COLUMNS)) ||  // at least one sheet has been edited
        userIsBacktracking &&
        backtrackingChanges === 0
      ) {
        // direction is 'edit_column'
        showModal(MODALTYPES.RESTART_FROM_ASSESSMENT);
        return;
      }
    }
    setIsLoading(true);
    let altArray = [];
    if (options !== null) {
      altArray = options.filter((option) => option !== selectedValue);
    } else {
      altArray = [];
    }

    const altIndex = altArray.indexOf(selectedValue);
    if (altIndex !== -1) {
      altArray.splice(altIndex, 1);
    }
    altArray.push(value);
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/columns/${id}`,
      data: { bb_header: selectedValue, bb_alternate_headers: altArray },
      onSuccess: () => {
        // Update sheet
        const sheetData = { column_edited: value, status: SHEETSTATUS.USER_EDITED_COLUMNS };
        doUpdateSheet(sheetData, activeSheetData.id, 'Column edited');
        handleDataChanged(id);
        setBacktrackingChanges((e) => e + 1);
        setVisible(false);
      },
      onError: (e) => {
        console.error(e);
      },
    };
    ApiCalls.doCall(reqCfg);
    // Comments may be added without forcing ML reprocessing (?)
    if (commentValue.length > 0) {
      const comment = `User added a comment for Header ${selectedValue} of sheet ${sheetName}: ${commentValue}`;
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/data-requests/${jobData.id}/comments/`,
        data: {
          content: comment,
          attachment_id: attachmentData.id,
        },
      });
    }
  };

  const header = `Edit Column Label - ${originalValue}`;

  useEffect(() => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/attachments/sheets/columns/options`,
      onSuccess: (res) => {
        const options = [];
        res.data.forEach((element) => {
          options.push({ value: element.name, label: element.name });
        });
        setSearchOptions(options);
      },
      onError: (e) => {
        console.error(e);
      },
    };
    ApiCalls.doCall(reqCfg);
  }, []);

  const handleModalResponse = () => {
    doValidateEdit(true);
    setModalVisible(false);
    setVisible(false);
  };
  const showModal = (modal) => {
    let details = null;
    if (modal === MODALTYPES.RESTART_FROM_ASSESSMENT) {
      details = ModalRestart(
        'edit_column',
        value,
        modal,
        setModalVisible,
        null,
        null,
        null,
        null,
        null,
        null,
        location,
        null,
        handleModalResponse
      );
    }
    if (details !== null) {
      setModalVisible(true);
      setModalBody(details.body);
      setModalFooter(details.footer);
    }
  };

  const body = (
    <>
      {errorMessage !== null ? (
        <div className="edit-error">
          ERROR: {value} {errorMessage[value]}
        </div>
      ) : null}
      <div className="edit-body">
        <div className="edit-selection">
          <span>What is the correct label for this column?</span>
          <button
            type="button"
            className={value === selectedValue ? 'selected' : ''}
            onClick={() => setSelectedValue(value)}
          >
            {value}
          </button>
          {options !== null
            ? sortedOptions.map((option) => (
                <button
                  type="button"
                  key={`${option}`}
                  className={option === selectedValue ? 'selected' : ''}
                  onClick={() => setSelectedValue(option)}
                >
                  {option}
                </button>
              ))
            : null}
          <span>Don't see the correct label? Select:</span>
          <div className="search-label">
            <StyledMultiselect
              values={searchDisplay}
              options={searchOptions}
              setOnChange={(e) => {
                setSelectedValue(e.value);
                setSearchDisplay(e);
              }}
              isSearchable
              isMulti={false}
            />
          </div>
          <button
            type="button"
            className={selectedValue === 'OTHER' ? 'selected' : ''}
            onClick={() => setSelectedValue('OTHER')}
          >
            <FontAwesomeIcon style={{ marginRight: '8px' }} icon={['fas', 'pencil']} size="sm" />
            OTHER
          </button>
        </div>
        <div className="edit-comments">
          <span>Comments</span>
          <textarea
            className="add-comment-textarea"
            value={commentValue}
            onChange={(v) => {
              setCommentValue(v.target.value);
            }}
            maxLength={4000}
            style={{ resize: 'none' }}
          />
        </div>
      </div>
    </>
  );

  const enableButton = () => {
    if (value !== selectedValue || commentValue) {
      return false;
    }
    return true;
  };

  const footer = (
    <div className="edit-footer">
      <button type="button" onClick={() => doValidateEdit()} disabled={enableButton()}>
        Validate
      </button>
    </div>
  );

  return (
    <>
      {modalVisible === true ? (
        <div id="modal-container">
          <ModalPanel
            className="modal-on-top"
            dialogClassName="edit-modal-dialog"
            isVisible={modalVisible}
            setIsVisible={setModalVisible}
            showCancel
            body={modalBody}
            footer={modalFooter}
          />
        </div>
      ) : (
        <ModalPanel
          className="column-edit-panel"
          dialogClassName="edit-modal-dialog"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          header={header}
          footer={footer}
          body={body}
        />
      )}
    </>
  );
};

EditModal.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleDataChanged: PropTypes.func.isRequired,
  editParentVisible: PropTypes.func.isRequired,
  sheetName: PropTypes.string.isRequired,
};

export { EditModal };
