import React from 'react';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { getRootMenuPortalTarget } from 'helpers/Utils';

const HeaderSelector = ({
  selectedHeader,
  headersList,
  headerSetterFunc,
  selectorLabel,
  fileLabel,
  disabled,
}) => {
  const menuPortalTarget = getRootMenuPortalTarget();
  return (
    <div className="header-selector">
      <div className="selector-label">{selectorLabel}</div>
      <div className="file-label">File: {fileLabel ?? 'N/A'}</div>
      <StyledMultiselect
        values={selectedHeader ? { value: selectedHeader, label: selectedHeader } : []}
        options={
          headersList?.length
            ? headersList.map((item) => {
                return { value: item, label: item };
              })
            : []
        }
        setOnChange={(v) => {
          headerSetterFunc(v.value || null);
        }}
        getOptionValue={(option) => option.value}
        closeMenuOnSelect
        optionsSort
        isSearchable
        isMulti={false}
        isClearable
        canReset
        isDisabled={disabled}
        menuPortalTarget={menuPortalTarget}
      />
    </div>
  );
};

export { HeaderSelector };
