import React, { useEffect, useState } from 'react';

import moment from 'moment';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { WeekPicker } from 'components/common/WeekPicker/WeekPicker';
import { CompanyOverview } from 'components/metrics/CompanyOverview/CompanyOverview';
import { MetricsTable } from 'components/metrics/MetricsTable/MetricsTable';
import { WeeklyMetricsCard } from 'components/metrics/WeeklyMetricsCard/WeeklyMetricsCard';
import { METRIC_LABELS } from 'constants/MetricsConstants';
import { WeeklyCustomerMetricTableDef } from 'helpers/table-defs/ViewMetricsTableDef';
import { formatDate8601 } from 'helpers/TimeUtils';
import { useIsMounted } from 'helpers/useIsMounted';

const Customer = ({ children, type, isOverviewLoading, pageTitle, overviewMetrics }) => {
  const [fallbackMsg, setFallbackMsg] = useState(null);
  const [weeklyMetrics, setWeeklyMetrics] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [areMetricsLoading, setAreMetricsLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment().day(1).toDate());
  const [endDate, setEndDate] = useState(moment().day(7).toDate());
  const { id } = useParams();
  const isMounted = useIsMounted();

  useEffect(() => {
    setAreMetricsLoading(true);
    const query = {};
    query.tier = 1;
    if (endDate) query.end_date = formatDate8601(endDate);
    if (startDate) query.start_date = formatDate8601(startDate);

    const url = `/weekly-metrics/${type}/${id}?${queryString.stringify(query)}`;

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: url,
      onSuccess: (res) => {
        if (isMounted.current) {
          const {
            customer_users: customerUsers,
            active_customer_users: activeCustomerUsers,
            actions,
            metrics,
          } = res.data;

          const customerUserMetrics = {
            header: 'Customer User Accounts',
            currentPeriodTotal: customerUsers.current_period,
            previousPeriodTotal: customerUsers.previous_period,
          };

          const customerUserActiveMetrics = {
            header: 'Customer User Accounts - Active',
            currentPeriodTotal: activeCustomerUsers.current_period,
            previousPeriodTotal: activeCustomerUsers.previous_period,
          };

          const actionMetrics = {
            header: 'Actions',
            currentPeriodTotal: actions.current_period,
            previousPeriodTotal: actions.previous_period,
          };

          const weeklyMetrics = [customerUserMetrics, customerUserActiveMetrics, actionMetrics];
          setWeeklyMetrics(weeklyMetrics);
          setMetrics(metrics);
        }
      },
      onError: (err) => {
        if (err.response.status === 404)
          setFallbackMsg(
            'This company is not a paying customer, or does not exist. Please try again with a different customer.'
          );
      },
      onEnd: () => {
        setAreMetricsLoading(false);
      },
    });
  }, [isMounted, id, startDate, endDate, type]);

  const renderMetricsTables = () => {
    const columns = Object.keys(metrics).map((key) => WeeklyCustomerMetricTableDef(metrics[key]));
    const data = [];
    Object.keys(metrics).forEach((key) => {
      const obj = {};
      metrics[key].forEach((metric, i) => {
        const key = `total_${i}`;
        obj[key] = metric.total;
      });
      data.push(obj);
    });

    return Object.keys(metrics).map((key, i) => (
      <MetricsTable
        className="metrics-history-table"
        key={METRIC_LABELS[key]}
        header={METRIC_LABELS[key]}
        keyField="metrics-table-period"
        metrics={[data[i]]}
        showViewAll={false}
        columns={columns[i]}
      />
    ));
  };
  const renderHelmet = () => (
    <Helmet bodyAttributes={{ 'data-page': `view-customer-${type}-metrics` }}>
      <title>{pageTitle}</title>
    </Helmet>
  );

  return areMetricsLoading || isOverviewLoading ? (
    <div className={`content content-fluid view-customer-${type}-metrics`}>
      {renderHelmet()}
      <LoadingView />
    </div>
  ) : fallbackMsg ? (
    <div className={`content content-fluid view-customer-${type}-metrics`}>{fallbackMsg}</div>
  ) : (
    <div className={`content content-fluid view-customer-${type}-metrics`}>
      {renderHelmet()}
      <CompanyOverview
        showSiteUrl={false}
        headerChildren={
          <div id="weekpicker-container" className="flex-group">
            <WeekPicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoading={areMetricsLoading}
            />
          </div>
        }
        showInline={false}
        showViewDataJobs
        overview={overviewMetrics}
      >
        {!areMetricsLoading && (
          <div className="metrics-overview-row-cards">
            {weeklyMetrics.map((metric) => (
              <WeeklyMetricsCard
                key={metric.header}
                header={metric.header}
                currentPeriodTotal={metric.currentPeriodTotal}
                previousPeriodTotal={metric.previousPeriodTotal}
              />
            ))}
          </div>
        )}
      </CompanyOverview>

      <section className="metrics-details">
        <div className="metrics-details-header">
          <h3 className="metrics-details-heading">All Metrics History</h3>
          <p className="metrics-details-subheading">
            * Data before June 28, 2021 may not be available.
          </p>
        </div>
        {!areMetricsLoading && renderMetricsTables()}
        <div className="metrics-details-header">
          <h3 className="metrics-details-heading">Overview</h3>
        </div>
        {!isOverviewLoading && children}
      </section>
    </div>
  );
};

export { Customer };
