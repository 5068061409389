import './DataIngestionHeader.scss';

import React, { useContext, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';

const DataIngestionHeader = () => {
  const {
    attachmentData,
    jobData,
    errors,
    userIsBacktracking,
    setUserIsBacktracking,
    backtrackingChanges,
    isColumnPhaseAccessible,
    isAssessmentAccessible,
    setIsChangingStages,
  } = useContext(DataIngestionContext);
  const jobId = attachmentData.data_request;
  const phase = useLocation().pathname;
  const [showErrors, setShowErrors] = useState(false);
  const history = useHistory();

  const skipAhead = () => {
    setIsChangingStages(true);
    setUserIsBacktracking(false);
    history.push(
      `/data-ingestion/${phase.includes('rows') ? 'columns' : 'assessment'}/${attachmentData.id}`
    );
  };

  // -------------------------------------- Errors -------------------------------------- //
  const errorPanel = () => {
    if (showErrors) {
      return (
        <>
          {errors.errorArray.map((item) => (
            <div key={item}>{item}</div>
          ))}
          {errors.errorArray.length > 2 ? (
            <button
              type="button"
              className="button-inherit error-toggle"
              onClick={() => setShowErrors(false)}
            >
              Show Less{' '}
              <FontAwesomeIcon
                className="ingestion-link-icon"
                icon={['fas', 'chevron-up']}
                size="sm"
              />
            </button>
          ) : null}
        </>
      );
    }

    return (
      <>
        {errors.errorArray.slice(0, 2).map((item) => (
          <div key={item}>{item}</div>
        ))}
        {errors.errorArray.length > 2 ? (
          <button
            type="button"
            className="button-inherit error-toggle"
            onClick={() => setShowErrors(true)}
          >
            Show More{' '}
            <FontAwesomeIcon
              className="ingestion-link-icon"
              icon={['fas', 'chevron-down']}
              size="sm"
            />
          </button>
        ) : null}
      </>
    );
  };
  // -------------------------------------------------------------------------------- //

  // -------------------------- Backtracking / Skip Button -------------------------- //

  const renderSkipStepButton = () => {
    const canJumpToCols = () => {
      if (backtrackingChanges > 0 || !isColumnPhaseAccessible) {
        return 'none';
      }
      return '';
    };
    const canJumpToAssessment = () => {
      if (backtrackingChanges > 0 || !isAssessmentAccessible) {
        return 'none';
      }
      return '';
    };

    return (
      <div
        className="resume-button"
        style={{ display: phase.includes('rows') ? canJumpToCols() : canJumpToAssessment() }}
      > 
        <Button onClick={() => skipAhead()}>
          {phase.includes('rows') ? 'Jump to column verification' : 'Jump to Assessment'}
        </Button>
      </div>
    );
  };

  const renderBacktrackWarning = () => {
    const rowsMessage = () => (
      <>
        <p>You previously verified headers and first data rows.</p>
        <p>Your previous selections are highlighted.</p>
        <p>
          You are welcome to make changes, but selections that deviate from your previous choices
          will result in a delay before you can continue the ingestion process.
        </p>
      </>
    );

    const columnsMessage = () => (
      <>
        <p>You previously verified your column data.</p>
        <p>
          You are welcome to make changes, but selections that deviate from your previous choices
          will result in a delay before you can continue the ingestion process.
        </p>
      </>
    );

    return (
      <div className="ingestion-errors warnings">
        <div className="title">Sheet warnings</div>
        {backtrackingChanges === 0 ? (
          phase.includes('rows') ? (
            rowsMessage()
          ) : (
            columnsMessage()
          )
        ) : (
          <>
            <p>Thanks for confirming.</p>
            <p>Please finish the verifying this step to continue.</p>
          </>
        )}
        {renderSkipStepButton()}
      </div>
    );
  };

  // -------------------------------------------------------------------------------- //

  return (
    <div className="content-fluid ingestion-header ">
      <h1>
        Data Onboarding -{' '}
        {phase.includes('rows') ? 'Header and First Row Definitions' : 'Column Labels'}
      </h1>
      <div className="ingestion-job-link-container">
        <Link className="ingestion-job-link" to={`/data-request/${jobId}`} target="_blank">
          Data Job: {jobData.name}
        </Link>
        <FontAwesomeIcon
          className="ingestion-link-icon"
          icon={['far', 'chevron-right']}
          size="sm"
        />
      </div>
      <div className="ingestion-items">
        <div className="ingestion-border-box">
          <div>File Uploaded:</div>
          <FontAwesomeIcon className="file-icon" icon={['far', 'file']} size="lg" />
          <span className="attachment-name"> {attachmentData.name}</span>
        </div>
        {phase.includes('columns') && errors.errorArray.length > 0 ? (
          <div className="ingestion-errors">
            <div className="title">Sheet Errors</div>
            {errorPanel()}
          </div>
        ) : userIsBacktracking ? (
          renderBacktrackWarning()
        ) : null}
      </div>
    </div>
  );
};

export { DataIngestionHeader };
