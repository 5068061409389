import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import './ManufacturerSelect.scss';

/**
 * Mfr and child company selector
 *
 * @param {number} selectedManufacturer Const for manufacturer id
 * @param {function} onSelectManufacturer Event handler for selection change
 * @param {number} selectedBrand Const for brand
 * @param {function} setSelectedBrand Set state object for manufacturer
 * @param {function} onSelectBrand Event handler for selection change
 * @param {boolean} isDisabled Disabled state
 * @return render
 */
const ManufacturerSelect = ({
  selectedManufacturer = null,
  onSelectManufacturer = () => null,
  selectedBrand = null,
  onSelectBrand = () => null,
  isDisabled = false,
}) => {
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();
  const [firstLoad, setFirstLoad] = useState(true);

  // Holds local objects for control values
  const [localManufacturer, setLocalManufacturer] = useState(null);
  const [localBrand, setLocalBrand] = useState(null);

  // Holds list of manufacturers
  const [mfrsData, setMfrsData] = useState([]);
  const [mfrsDataStatus, setMfrsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds list of child mfrs per selected mfr
  const [brandData, setBrandData] = useState([]);
  const [brandDataStatus, setBrandDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Init mfrs list
  useEffect(() => {
    if (isMounted.current) {
      setMfrsDataStatus(ActionStatusConstants.ISBUSY);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/parents/manufacturers',
        onSuccess: (res) => {
          if (isMounted.current) {
            setMfrsDataStatus(ActionStatusConstants.SUCCESS);
            const _data = res?.data || null;
            setMfrsData(_data);
          }
        },
        onError: () => {
          setMfrsDataStatus(ActionStatusConstants.FAILURE);
        },
      });
    }
  }, [isMounted]);

  useEffect(() => {
    let hasChildren = false;

    const mfrId = activeUser?.profile?.manufacturer?.id || selectedManufacturer;

    if (mfrsData?.length > 0 && mfrId) {
      const mfrDataItem = mfrsData.find((item) => item.id === mfrId);

      hasChildren = !!mfrDataItem?.has_children;
    }

    if (hasChildren) {
      if (isMounted.current) {
        setBrandDataStatus(ActionStatusConstants.ISBUSY);
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/manufacturers/${mfrId}/children`,
          onSuccess: (res) => {
            if (isMounted.current) {
              setBrandDataStatus(ActionStatusConstants.SUCCESS);
              const _data = res?.data || null;
              setBrandData(_data);
            }
          },
          onError: () => {
            setBrandDataStatus(ActionStatusConstants.FAILURE);
          },
        });
      }
    } else {
      setBrandData(null);
    }
  }, [isMounted, mfrsData, selectedManufacturer, activeUser]);

  // Generates a Select value object from object in data list, by id
  const getSelectValue = (list, id) => {
    if (id && list?.length) {
      const dataItem = list.find((item) => item.id === id);

      if (dataItem) {
        return { value: dataItem.id, label: dataItem.name, obj: dataItem };
      }
    }

    return [];
  };

  // load in values if passed
  useEffect(() => {
    if (!localManufacturer && selectedManufacturer && mfrsData?.length > 0) {
      setLocalManufacturer(getSelectValue(mfrsData, selectedManufacturer));
    }
  }, [localManufacturer, selectedManufacturer, mfrsData]);

  useEffect(() => {
    if (!localBrand && selectedBrand && brandData?.length > 0 && firstLoad) {
      setLocalBrand(getSelectValue(brandData, selectedBrand));
      setFirstLoad(false);
    }
  }, [localBrand, selectedBrand, brandData, firstLoad]);

  const displaySelectManufacturer = activeUser?.role === UserRoleConstants.ADMIN;
  const displaySelectBrand =
    ((displaySelectManufacturer && selectedManufacturer) ||
      activeUser?.profile?.manufacturer?.has_children) &&
    brandData?.length;

  const _isBusy = [mfrsDataStatus, brandDataStatus].includes(ActionStatusConstants.ISBUSY);

  const _isDisabled = isDisabled || _isBusy;

  return (
    <section className="manufacturer-select">
      <div className="manufacturer">
        {displaySelectManufacturer ? (
          <>
            <div className="title">
              <span>Manufacturer</span>
              {_isBusy && <LoadingSpinner fast />}
            </div>
            <StyledMultiselect
              values={localManufacturer}
              options={
                mfrsData
                  ? mfrsData.map((item) => {
                      return { value: item.id, label: item.name, obj: item };
                    })
                  : []
              }
              setOnChange={(v) => {
                setLocalManufacturer(v);
                onSelectManufacturer(v?.value || null);
              }}
              getOptionValue={(option) => option.value}
              closeMenuOnSelect
              isSearchable
              isDisabled={_isDisabled}
              isMulti={false}
              isClearable
              canReset
            />
          </>
        ) : (
          <>
            <div className="title">
              <span>Company</span>
              {_isBusy && <LoadingSpinner fast />}
            </div>
            <div className="value">{activeUser?.profile?.manufacturer?.name}</div>
          </>
        )}
      </div>
      {displaySelectBrand && (
        <div className="child-company">
          <div className="title">Level 1 Brand</div>
          <StyledMultiselect
            values={localBrand}
            options={
              brandData
                ? brandData.map((item) => {
                    return { value: item.id, label: item.name, obj: item };
                  })
                : []
            }
            setOnChange={(v) => {
              setLocalBrand(v);
              onSelectBrand(v?.value || null);
            }}
            getOptionValue={(option) => option.value}
            closeMenuOnSelect
            isSearchable
            isDisabled={_isDisabled}
            isMulti={false}
            isClearable
            canReset
          />
        </div>
      )}
    </section>
  );
};

ManufacturerSelect.defaultProps = {
  selectedManufacturer: null,
  onSelectManufacturer: () => null,
  selectedBrand: null,
  onSelectBrand: () => null,
  isDisabled: false,
};

ManufacturerSelect.propTypes = {
  selectedManufacturer: PropTypes.number,
  onSelectManufacturer: PropTypes.func,
  selectedBrand: PropTypes.number,
  onSelectBrand: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export { ManufacturerSelect };
