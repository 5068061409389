import './TableScroller.scss';
import React, { useState, useEffect, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

/**
 * Creates a pair of arrows to enable horizontal scrolling of a div
 *
 * @param {*} wrapper HTML id of the element to be scrolled
 * @param {*} scrollJumpPoints array with points to make scroll jump to; not implemented yet
 * @param {*} onScrollRight function to be executed when the right button is clicked
 * @param {*} onScrollLeft function to be executed when the left button is clicked
 * @returns object
 */

const TableScroller = ({
  scroll = 0,
  setScroll,
  wrapper,
  scrollJumpPoints,
  onScrollRight,
  onScrollLeft,
  tutorialMode = false,
}) => {
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [canScrollLeft, setCanScrollLeft] = useState(true);

  const handleScroll = useCallback(() => {
    const wrapperTarget = document.getElementById(wrapper);
    setScroll(wrapperTarget.scrollLeft);

    const canScroll = wrapperTarget.clientWidth < wrapperTarget.scrollWidth;
    const scrollRemaining = wrapperTarget.scrollWidth - scroll - wrapperTarget.offsetWidth;
    if (canScroll && scrollRemaining > 10) {
      setCanScrollRight(true);
    } else {
      setCanScrollRight(false);
    }
    if (canScroll && scroll > 20) {
      setCanScrollLeft(true);
    } else {
      setCanScrollLeft(false);
    }
  }, [wrapper, scroll]);

  const scrollRight = (scroll, wrapper) => {
    if (tutorialMode) {
      return;
    }
    document.getElementById(wrapper).scrollLeft +=
      document.getElementById(wrapper).clientWidth - 100;
    // TO DO jump to jumppoint to left of new val
    if (onScrollRight) {
      onScrollRight();
    }
    handleScroll();
  };

  const scrollLeft = (scroll, wrapper) => {
    if (tutorialMode) {
      return;
    }
    document.getElementById(wrapper).scrollLeft -=
      document.getElementById(wrapper).clientWidth - 100;
    if (onScrollLeft) {
      onScrollLeft();
    }
    handleScroll();
  };

  useEffect(() => {
    if (document.getElementById(wrapper) !== null) {
      handleScroll();
    }
  }, [handleScroll, wrapper]);

  return (
    <div className={`scroll-actions ${tutorialMode ? 'scroll-tutorial' : ''}`}>
      <button
        type="button"
        className="scroll-left"
        onClick={() => scrollLeft(scroll, wrapper)}
        disabled={!canScrollLeft}
      >
        <FontAwesomeIcon icon={['fas', 'angle-double-left']} size="lg" />
      </button>
      <span className="scroll-label">scroll</span>
      <button
        type="button"
        className="scroll-right"
        onClick={() => scrollRight(scroll, wrapper)}
        disabled={!canScrollRight}
      >
        <FontAwesomeIcon icon={['fas', 'angle-double-right']} size="lg" />
      </button>
    </div>
  );
};

TableScroller.propTypes = {
  wrapper: PropTypes.string.isRequired,
  scrollJumpPoints: PropTypes.array,
  onScrollRight: PropTypes.func,
  onScrollLeft: PropTypes.func,
};

export { TableScroller };
