import './DataQualityTable.scss';
import React, { useState, useEffect, useCallback, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { TableScroller } from 'components/common/TableScroller/TableScroller';
import { DataIngestionDataQualityContext } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQualityContext';
import { COLUMNSTATUS, DEFAULT_NUM_ROWS } from 'constants/DataIngestionConstants';
import { toast } from 'helpers/ToastUtils';
import { excelHeaders } from 'helpers/Utils';

// TODO: Huge component, to be split to managable pieces

/**
 * Creates a table for displaying and interesting with sheet data for the first step in data ingestion
 *
 * @param {*} activeSheetData json with sheet data from /attachments/sheets
 * @param {*} updateSheet function to execute if the current sheet is updated
 * @param {*} doDownload function to download the current file
 * @returns object
 */

const DataQualityTable = ({ activeSheetData, updateSheet, doDownload, sheetCount }) => {
  const [scroll, setScroll] = useState(0);
  const [columnData, setColumnData] = useState(null);
  const [allTableData, setAllTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalBody, setModalBody] = useState();
  const [modalFooter, setModalFooter] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [dataChanged, setDataChanged] = useState(0);
  const [errorDetails, setErrorDetails] = useState([]);

  const [isSheetLoading, setIsSheetLoading] = useState(true);

  const context = useContext(DataIngestionDataQualityContext);

  const {
    showGoodRows,
    setShowGoodRows,
    sheetHasNoErrors,
    setSheetHasNoErrors,
    setAllRowsHaveErrors,
    maxNumRowsToShow,
    setMaxNumRowsToShow,
    numRowsInSheet,
    setNumRowsInSheet,
    inconsistentRows,
    setInconsistentRows,
  } = context;

  const getSheetData = () => {
    setIsSheetLoading(true);
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/attachments/sheets/${activeSheetData.id}/columns`,
      onSuccess: (res) => {
        setColumnData(
          res.data.filter((column) => {
            return [COLUMNSTATUS.VALIDATED, COLUMNSTATUS.DISABLED].includes(column.status);
          })
        );
        setIsSheetLoading(false);
        setShowGoodRows(false);
        setMaxNumRowsToShow(DEFAULT_NUM_ROWS);
      },
      onError: (e) => {
        console.error(e);
        setIsSheetLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const getErrorDetails = () => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/attachments/sheets/errordetails`,
      params: { sheets: sheetCount },
      onSuccess: (res) => {
        setErrorDetails(res.data);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  useEffect(() => {
    getErrorDetails();
  }, []);

  useEffect(() => {
    if (activeSheetData) {
      getSheetData();
    }
  }, [activeSheetData, dataChanged]);

  const handleScroll = useCallback(() => {
    setScroll(document.getElementById('table-wrapper').scrollLeft);
    const canScroll =
      document.getElementById('table-wrapper').clientWidth <
      document.getElementById('table-wrapper').scrollWidth;
    const scrollRemaining =
      document.getElementById('table-wrapper').scrollWidth -
      scroll -
      document.getElementById('table-wrapper').offsetWidth;
    if (canScroll && scrollRemaining > 10) {
      document.getElementById('table-shadow-right').style.display = 'block';
    } else {
      document.getElementById('table-shadow-right').style.display = 'None';
    }
  }, [scroll]);

  const doDisable = (id) => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/columns/${id}`,
      data: { status: COLUMNSTATUS.DISABLED },
      onSuccess: () => {
        toast.success('Column Disabled');
        updateSheet('deleted_column');
        setModalVisible(false);
        setDataChanged((e) => e + 1);
      },
      onError: (e) => {
        console.error(e);
        setIsSheetLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const DoubleScroll = (element) => {
    if (document.getElementById('double-scroll') === null) {
      const scrollbar = document.createElement('div');
      scrollbar.appendChild(document.createElement('div'));
      scrollbar.id = 'double-scroll';
      scrollbar.style.overflow = 'scroll';
      scrollbar.style.overflowY = 'hidden';
      scrollbar.style.width = '100%';
      scrollbar.firstChild.style.width = `${element.firstChild.scrollWidth}px`;
      scrollbar.firstChild.style.height = '0em';
      scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
      scrollbar.onscroll = function () {
        element.scrollLeft = scrollbar.scrollLeft;
      };
      element.onscroll = function () {
        scrollbar.scrollLeft = element.scrollLeft;
      };
      element.parentNode.insertBefore(scrollbar, element);
    } else {
      document.getElementById(
        'double-scroll'
      ).firstChild.style.width = `${element.firstChild.scrollWidth}px`;
    }
  };

  useEffect(() => {
    if (!isLoading && !isSheetLoading) {
      DoubleScroll(document.getElementById('table-wrapper'));
    }
  }, [isLoading, isSheetLoading]);

  // function for making the delete handler on columns work
  const handleDisable = (id, name) => {
    let body = null;
    if (columnData.length > 1) {
      body = (
        <div key="handleDelete">
          You are about to disable this column. This means that the data contained in this column
          will not be processed. Continue?
          <div className="btn-container">
            <button
              type="button"
              className="secondary-btn sm"
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </button>
            <button type="button" className="secondary-btn sm" onClick={() => doDisable(id, false)}>
              Confirm
            </button>
          </div>
        </div>
      );
    } else {
      body = (
        <div key="disablehandle">
          You are about to disable the only valid column left for this sheet. If you proceed,
          nothing on this sheet will be processed. Please click
          <button type="button" className="btn-link" onClick={() => doDisable(id, name, true)}>
            here
          </button>
          to disable it and notify the BackboneAI data team.
        </div>
      );
    }
    setModalTitle(null);
    setModalBody(body);
    setModalFooter(null);
    setModalVisible(true);
  };

  const doEnable = (id) => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/columns/${id}`,
      data: { status: COLUMNSTATUS.VALIDATED },
      onSuccess: () => {
        toast.success('Column Enabled');
        updateSheet(null, null, null, 'Column Enabled');
        setModalVisible(false);
        setDataChanged((e) => e + 1);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  // function for making the enable handler on culomns work
  const handleEnable = (id) => {
    let body = null;
    body = (
      <div key="handleDelete">
        You are about to enable this column. This means that the data contained in this column will
        be processed. Continue?
        <div className="btn-container">
          <button type="button" className="secondary-btn sm" onClick={() => setModalVisible(false)}>
            Cancel
          </button>
          <button type="button" className="secondary-btn sm" onClick={() => doEnable(id, false)}>
            Confirm
          </button>
        </div>
      </div>
    );
    setModalTitle(null);
    setModalBody(body);
    setModalFooter(null);
    setModalVisible(true);
  };

  // placeholder with modal. eventualyl cells will be directly eidted
  const handleEdit = (id) => {
    const body = (
      <div key="handleDelete">
        <p>For V1, please download the file to make corrections.</p>
        <p>V2 is coming soon and will let you fix issues on this screen.</p>
      </div>
    );
    const footer = (
      <div className="edit-footer">
        <button type="button" className="btn btn-secondary" onClick={() => setModalVisible(false)}>
          Cancel
        </button>
        <button type="button" className="btn btn-primary" onClick={() => doDownload()}>
          Download
        </button>
      </div>
    );
    setModalTitle('Download the File to Make Corrections');
    setModalBody(body);
    setModalFooter(footer);
    setModalVisible(true);
  };

  // set height for the table blur on right
  useEffect(() => {
    if (!isLoading) {
      document.getElementById('table-shadow-right').style.height = `${
        document.getElementById('table-wrapper').clientHeight
      }px`;
    }
  }, [isLoading, scroll]);

  // make scrolling work when loading is done
  useEffect(() => {
    if (!isLoading) {
      handleScroll();
    }
  }, [handleScroll, isLoading]);

  // convert the activeSheetData into an array of arrays we can use to build a table
  useEffect(() => {
    if (activeSheetData && columnData) {
      const originalObject = _get(activeSheetData, 'smart_sample.smart_warn_err');
      const tempData = [];
      const tempInconsistentRows = [];

      for (let i = 0; i < (originalObject.length || 1000); i++) {
        const tempAllRows = [];
        for (let j = 0; j < columnData.length; j++) {
          tempAllRows[j] = `${originalObject[i][j]}`;
          // Record which rows contain errors or warnings

          if (columnData[j]?.errors?.length > 0) {
            columnData[j].errors.forEach((error) => {
              error.rows.forEach(
                (row) => !tempInconsistentRows.includes(row) && tempInconsistentRows.push(row)
              );
            });
          }
          if (columnData[j]?.warnings?.length > 0) {
            columnData[j].warnings.forEach((warning) => {
              warning.rows.forEach(
                (row) => !tempInconsistentRows.includes(row) && tempInconsistentRows.push(row)
              );
            });
          }
        }
        tempData.push(tempAllRows);
      }
      setAllTableData(tempData);
      setInconsistentRows(tempInconsistentRows);
      if (tempInconsistentRows.length === 0) {
        setSheetHasNoErrors(true);
      } else {
        setSheetHasNoErrors(false);
      }
      if (tempInconsistentRows.length < DEFAULT_NUM_ROWS) {
        setShowGoodRows(true);
      }
      setNumRowsInSheet(allTableData.length);
      if (tempInconsistentRows.length === allTableData.length) {
        setAllRowsHaveErrors(true);
      } else {
        setAllRowsHaveErrors(false);
      }
    }
  }, [columnData, allTableData.length]);
  // build the table cell with proper errors and warnings
  const buildTableCell = (cellData, colIndex, rowIndex) => {
    if (columnData !== undefined) {
      let cellHtml = null;
      // check the column to see if it has errors
      if (columnData[colIndex] !== undefined && columnData[colIndex].errors.length > 0) {
        // check to see if there is an error for this row
        for (let i = 0; i < columnData[colIndex].errors.length; i++) {
          if (columnData[colIndex].errors[i].rows.includes(rowIndex)) {
            // get all of the errors in the cell
            const cellErrors = [];
            const errorObject = columnData[colIndex].errors;
            const errorIndexes = Object.keys(errorObject).filter((val) =>
              errorObject[val].rows.includes(rowIndex)
            );
            for (let j = 0; j < errorIndexes.length; j++) {
              cellErrors.push(errorObject[errorIndexes[j]].key);
            }
            cellHtml = (
              <OverlayTrigger
                placement="top"
                overlay={renderErrorTooltip(cellErrors, columnData[colIndex].bb_header)}
              >
                <td
                  key={`${rowIndex}-${cellData}-${colIndex}`}
                  className={
                    columnData[colIndex].status === COLUMNSTATUS.DISABLED
                      ? 'cell-ignored'
                      : 'cell-error'
                  }
                  onClick={() => handleEdit()}
                >
                  <div className="cell-wrapper">
                    {cellData === null ? '#' : null}
                    <div className="cell-cutoff">{cellData}</div>
                    <span className="cell-error-icon">
                      <span className="oi oi-ban" aria-hidden />
                      {cellErrors.length > 1 ? <span>({cellErrors.length})</span> : null}
                    </span>
                  </div>
                </td>
              </OverlayTrigger>
            );
            return cellHtml;
          }
        }
      }
      // check the column to see if it has warnings and that no error was set for this cell
      if (columnData[colIndex] !== undefined && columnData[colIndex].warnings.length > 0) {
        // check to see if there is an error for this row
        for (let i = 0; i < columnData[colIndex].warnings.length; i++) {
          if (columnData[colIndex].warnings[i].rows.includes(rowIndex)) {
            // get all of the warnings in the cell
            const cellWarnings = [];
            const warningObject = columnData[colIndex].warnings;
            const warningIndexes = Object.keys(warningObject).filter((val) =>
              warningObject[val].rows.includes(rowIndex)
            );
            for (let j = 0; j < warningIndexes.length; j++) {
              cellWarnings.push(warningObject[warningIndexes[j]].key);
            }
            cellHtml = (
              <OverlayTrigger
                placement="top"
                overlay={renderWarningTooltip(cellWarnings, columnData[colIndex].bb_header)}
              >
                <td
                  key={`${rowIndex}-${cellData}-${colIndex}`}
                  className={
                    columnData[colIndex].status === COLUMNSTATUS.DISABLED
                      ? 'cell-ignored'
                      : 'cell-warning'
                  }
                  onClick={() => handleEdit()}
                >
                  <div className="cell-wrapper">
                    <div className="cell-cutoff">{cellData}</div>
                    <span className="oi oi-warning" aria-hidden />
                  </div>
                </td>
              </OverlayTrigger>
            );
            return cellHtml;
          }
        }
      }
      cellHtml = (
        <td
          key={`${rowIndex}-${cellData}-${colIndex}`}
          onClick={() => handleEdit()}
          className={columnData[colIndex].status === COLUMNSTATUS.DISABLED ? 'cell-ignored' : null}
        >
          <div className="cell-wrapper">
            <div className="cell-cutoff">{cellData}</div>
          </div>
        </td>
      );
      return cellHtml;
    }
  };

  const buildTooltipMessage = (fullMessage, column) => {
    if (typeof fullMessage === 'string') {
      return fullMessage;
    }
    return fullMessage.find((val) => val.column === column).message;
  };

  const renderErrorTooltip = (errors, column, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {errors.map((error) =>
        errorDetails?.find((val) => val.code === error) === undefined ||
        [null, 'TBD', 'null'].includes(
          errorDetails?.find((val) => val.code === error).full_message
        ) ? (
          <>There is an unidentified data error in this cell.</>
        ) : (
          <p>
            {buildTooltipMessage(
              errorDetails?.find((val) => val.code === error).full_message,
              column
            )}
          </p>
        )
      )}
    </Tooltip>
  );

  const renderWarningTooltip = (warnings, column, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {warnings.map((warning) =>
        errorDetails?.find((val) => val.code === warning) === undefined ||
        [null, 'TBD', 'null'].includes(
          errorDetails?.find((val) => val.code === warning).full_message
        ) ? (
          <>There is an unidentified data warning in this cell.</>
        ) : (
          <p>
            {buildTooltipMessage(
              errorDetails?.find((val) => val.code === warning).full_message,
              column
            )}
          </p>
        )
      )}
    </Tooltip>
  );

  const renderRows = useCallback(
    (row, rowIndex) => {
      // By default, only show rows with errors and warnings
      if (showGoodRows === true) {
        return row.map((cell, cellIndex) => buildTableCell(cell, cellIndex, rowIndex));
      }
      // edgecase: first inconsistent row is > maxNumRowsToShow
      if (!showGoodRows && inconsistentRows[0] >= maxNumRowsToShow) {
        rowIndex += inconsistentRows[0];
      }

      if (inconsistentRows.includes(rowIndex)) {
        const rows = row.map((cell, cellIndex) => buildTableCell(cell, cellIndex, rowIndex));
        if (rows.length > 0) {
          return rows;
        }
        return <p>nice, this sheet has no errors!</p>;
      }
      return null;
    },
    [showGoodRows, inconsistentRows]
  );

  const renderDisableTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Disable column
    </Tooltip>
  );

  const renderEnableTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Enable column
    </Tooltip>
  );

  // reset scroll on load
  useEffect(() => {
    setScroll(0);
    document.getElementById('table-wrapper').scrollLeft = 0;
  }, [isSheetLoading]);

  return (
    <>
      <div className="sheet-table-actions">
        <div className="actions-header">
          <div className="actions-icon fa-layers fa-fw">
            <FontAwesomeIcon
              className="highlight-action"
              icon={['fas', 'check-circle']}
              size="lg"
            />
          </div>
          {isSheetLoading
            ? 'Loading ...'
            : sheetHasNoErrors
            ? 'No warnings or errors on this sheet. Click the next tab to continue.'
            : 'Please update any missing data.'}{' '}
        </div>
        <div className="actions-scroll">
          {columnData && allTableData ? (
            <TableScroller
              wrapper="table-wrapper"
              table=""
              row="row-0"
              scroll={scroll}
              setScroll={(e) => setScroll(e)}
            />
          ) : null}
        </div>
      </div>
      {modalVisible === true ? (
        <div id="modal-container">
          <ModalPanel
            isVisible={modalVisible}
            setIsVisible={setModalVisible}
            showCancel
            header={modalTitle}
            body={modalBody}
            footer={modalFooter}
            centered={false}
          />
        </div>
      ) : null}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="table-wrapper-quality" id="table-wrapper" onScroll={() => handleScroll()}>
          {columnData &&
          columnData.length > 0 &&
          allTableData.length > 0 &&
          columnData.length === allTableData[0].length ? (
            <>
              <table id="main-table">
                <thead>
                  <tr className="row-letter">
                    {columnData.map((cell, index) => (
                      <th
                        key={`excelheader-${index}`}
                        className={cell.status === COLUMNSTATUS.DISABLED ? 'cell-ignored' : null}
                      >
                        <div className="cell-letter">
                          <div
                            className="header-secondary"
                            style={{
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                          >
                            {excelHeaders(index)}
                          </div>
                          {/*
                          TODO: remake column enable/disable once we have definitions for it
                          cell.status === COLUMNSTATUS.DISABLED ? (
                            <OverlayTrigger placement="top" overlay={renderEnableTooltip}>
                              <button
                                type="button"
                                className="button-inherit"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => handleEnable(cell.id)}
                              >
                                <FontAwesomeIcon icon={['far', 'undo']} size="sm" />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger placement="top" overlay={renderDisableTooltip}>
                              <button
                                type="button"
                                className="button-inherit"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => handleDisable(cell.id)}
                              >
                                <FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />
                              </button>
                            </OverlayTrigger>
                          ) */}
                        </div>
                      </th>
                    ))}
                  </tr>
                  <tr className="header-original" id="row-original">
                    {columnData.map((cell, index) => (
                      <th
                        className={cell.status === COLUMNSTATUS.DISABLED ? 'cell-ignored' : null}
                        key={`original-${excelHeaders(index)}`}
                      >
                        <div className="cell-cutoff cell-wrapper">{cell.original_header}</div>
                      </th>
                    ))}
                  </tr>
                  <tr id="row-backbone" className="row-backbone">
                    {columnData.map((cell, index) => (
                      <th
                        className={cell.status === COLUMNSTATUS.DISABLED ? 'cell-ignored' : null}
                        key={`backbone-${index}`}
                        id={`bb-${cell.id}`}
                      >
                        <div className="cell-backbone cell-wrapper">
                          <div className="cell-text">{cell.bb_header}</div>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allTableData
                    .slice(
                      showGoodRows ? 0 : inconsistentRows[0],
                      // handle Edgecase: first bad row index is > maxNumRowsToShow
                      !showGoodRows && inconsistentRows[0] >= maxNumRowsToShow
                        ? inconsistentRows[0] + maxNumRowsToShow
                        : maxNumRowsToShow
                    )
                    .map((row, rowIndex) => (
                      <tr id={`row-${rowIndex}`} key={`row-${rowIndex}`}>
                        {renderRows(row, rowIndex)}
                      </tr>
                    ))}
                </tbody>
                {isSheetLoading ? (
                  <div className="loading-wrapper sheet-loading">
                    <LoadingSpinner style={{ fontSize: '5em' }} />
                  </div>
                ) : null}
              </table>
            </>
          ) : null}
          {isLoading ? (
            <div className="loading-wrapper">
              <LoadingSpinner style={{ fontSize: '5em' }} />
            </div>
          ) : null}
        </div>
        <div className="table-shadow-right" id="table-shadow-right" />
      </div>
    </>
  );
};

DataQualityTable.propTypes = {
  activeSheetData: PropTypes.object.isRequired,
  updateSheet: PropTypes.func.isRequired,
  doDownload: PropTypes.func.isRequired,
};

export { DataQualityTable };
