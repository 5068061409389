import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { sortByKey } from 'helpers/Utils';

/**
 * Get mfrs list
 *
 * @param {object} isMounted ref
 * @param {function} setMfrsData Result callback
 * @param {function} setMfrsDataStatus Status callback
 */
const doGetMfrs = ({ isMounted, setMfrsData, setMfrsDataStatus }) => {
  if (isMounted.current) {
    setMfrsDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/parents/manufacturers',
      onSuccess: (res) => {
        if (isMounted.current) {
          setMfrsDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setMfrsData(sortByKey(_data, 'name'));
        }
      },
      onError: () => {
        setMfrsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get child mfr per selected mfr.
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setChildMfrsData Result callback
 * @param {function} setChildMfrsDataStatus Status callback
 */
const doGetChildMfrs = ({ mfrId, isMounted, setChildMfrsData, setChildMfrsDataStatus }) => {
  if (isMounted.current) {
    setChildMfrsDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/manufacturers/${mfrId}/children`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setChildMfrsDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setChildMfrsData(sortByKey(_data, 'name'));
        }
      },
      onError: () => {
        setChildMfrsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get product count per mfr id
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setProdCountData Result callback
 * @param {function} setProdCountDataStatus Status callback
 */
const doGetProdCount = ({ mfrId, isMounted, setProdCountData, setProdCountDataStatus }) => {
  if (isMounted.current) {
    setProdCountDataStatus(ActionStatusConstants.ISBUSY);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/manufacturers/${mfrId}?with=product_count`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setProdCountDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setProdCountData(_data);
        }
      },
      onError: () => {
        setProdCountDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get templates by mfr id
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setTemplatesData Result callback
 * @param {function} setTemplatesDataStatus Status callback
 */
const doGetTemplates = ({ mfrId, isMounted, setTemplatesData, setTemplatesDataStatus }) => {
  if (isMounted.current) {
    setTemplatesDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/products/export/templates/',
      params: { manufacturer: mfrId },
      onSuccess: (res) => {
        if (isMounted.current) {
          setTemplatesDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setTemplatesData(_data);
        }
      },
      onError: () => {
        setTemplatesDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get template by id
 *
 * @param {int} id
 * @param {object} isMounted ref
 * @param {function} setTemplateData Result callback
 * @param {function} setTemplateDataStatus Status callback
 */
const doGetTemplate = ({ templateId, isMounted, setTemplateData, setTemplateDataStatus }) => {
  if (isMounted.current) {
    setTemplateDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/products/export/templates/${templateId}/`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setTemplateDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setTemplateData(_data);
        }
      },
      onError: () => {
        setTemplateDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get attributes list by manufacturer id
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setAttributesData Result callback
 * @param {function} setAttributesDataStatus Status callback
 */
const doGetAttributes = ({ mfrId, isMounted, setAttributesData, setAttributesDataStatus }) => {
  if (isMounted.current) {
    setAttributesDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/products/export/attributes/',
      params: { manufacturer_id: mfrId, offset: 0, limit: 2000 },
      onSuccess: (res) => {
        if (isMounted.current) {
          setAttributesDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setAttributesData(_data);
        }
      },
      onError: () => {
        setAttributesDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get statuses list for current exports
 *
 * @param {object} isMounted ref
 * @param {function} setExportsStatusData Result callback
 * @param {function} setExportsStatusDataStatus Status callback
 */
const doGetExportsStatus = ({ isMounted, setExportsStatusData, setExportsStatusDataStatus }) => {
  if (isMounted.current) {
    setExportsStatusDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/product-export-history/?type=advanced',
      onSuccess: (res) => {
        if (isMounted.current) {
          setExportsStatusDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setExportsStatusData(_data);
        }
      },
      onError: () => {
        setExportsStatusDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Get distributors by mfr id
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setDistributorsData Result callback
 * @param {function} setDistributorsDataStatus Status callback
 */
const doGetDistributors = ({
  mfrId,
  isMounted,
  setDistributorsData,
  setDistributorsDataStatus,
}) => {
  if (isMounted.current) {
    setDistributorsDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/manufacturers/${mfrId}/distributors`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setDistributorsDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setDistributorsData(sortByKey(_data, 'name'));
        }
      },
      onError: () => {
        setDistributorsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Save template. Covers both creating a new template(POST) or update if ID exists(PUT).
 *
 * @param {object} template Template object
 * @param {object} isMounted ref
 * @param {function} onSaveTemplate Success callback
 * @param {function} setSaveTemplateStatus Status callback
 */
const doSaveTemplate = ({ template, isMounted, onSaveTemplate, setSaveTemplateStatus }) => {
  if (isMounted.current) {
    setSaveTemplateStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: template?.id ? ApiCalls.HTTP_METHODS.PUT : ApiCalls.HTTP_METHODS.POST,
      urlPath: `/products/export/templates/${template?.id ? `${template?.id}/` : ''}`,
      data: template,
      successMessage: 'Template saved successfully',
      onSuccess: (res) => {
        if (isMounted.current) {
          setSaveTemplateStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          onSaveTemplate(_data);
        }
      },
      onError: () => {
        setSaveTemplateStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

/**
 * Start Export
 *
 * @param {object} template Template object
 * @param {object} isMounted ref
 * @param {function} onStartExport Success callback
 * @param {function} setStartExportStatus Status callback
 */
const doStartExport = ({ template, isMounted, onStartExport, setStartExportStatus }) => {
  if (isMounted.current) {
    setStartExportStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: '/product-export-history/advanced/export',
      data: template,
      onSuccess: () => {
        if (isMounted.current) {
          setStartExportStatus(ActionStatusConstants.SUCCESS);
          onStartExport();
        }
      },
      onError: () => {
        setStartExportStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

export {
  doGetMfrs,
  doGetChildMfrs,
  doGetProdCount,
  doGetTemplates,
  doGetTemplate,
  doGetAttributes,
  doGetExportsStatus,
  doGetDistributors,
  doSaveTemplate,
  doStartExport,
};
