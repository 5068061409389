import queryString from 'query-string';

import * as QueryStringConstants from 'constants/QueryStringConstants';

export const getMfrStateFromSearchStr = (searchStr) => {
  const params = {};

  const queryData = queryString.parse(searchStr, QueryStringConstants.defaultQueryStringOptions);

  const parseNonZeroInt = (v) => {
    if (v && v.length) {
      const vNum = Number.parseInt(v);
      if (!Number.isNaN(vNum) && vNum >= 1) {
        return vNum;
      }
    }
    return null;
  };

  params.manufacturer_id = parseNonZeroInt(queryData.manufacturer_id) ?? null;
  params.child_company_id = parseNonZeroInt(queryData.child_company_id) ?? null;

  return params;
};

export const updateUrlState = (history, location, manufacturerId, brandId) => {
  const oldParams = {
    manufacturer_id: getMfrStateFromSearchStr(location.search)?.manufacturer_id ?? null,
    child_company_id: getMfrStateFromSearchStr(location.search)?.child_company_id ?? null,
  };

  const params = {
    manufacturer_id: manufacturerId ?? null,
    child_company_id: brandId ?? null,
  };

  if (oldParams === params) return;

  const qStr = queryString.stringify(params, {
    ...QueryStringConstants.defaultQueryStringOptions,
    skipNull: true,
  });

  history.push({
    search: qStr?.length ? qStr : null,
  });
};
