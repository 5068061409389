import './UserProfileCircle.scss';

import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import imgProfilePlaceholder from 'assets/img/profile-placeholder.jpg';
import styleVars from 'scss/vars.scss';

const UserProfileCircle = ({
  name = null,
  imageSrc = null,
  singleInitial = false,
  size = 'sm',
  showBorder = true,
}) => {
  const [userPic, setUserPic] = useState(imageSrc);

  // TODO: Add support for "medium" and "small" icon permutations according to design guide
  const bgColorList = [
    {
      initialColor: styleVars.colors_designGreen,
      bgColor: styleVars.colors_designGreenLight,
    },
    {
      initialColor: styleVars.colors_designBlue,
      bgColor: styleVars.colors_designBlueLight,
    },
    {
      initialColor: styleVars.colors_designOrange,
      bgColor: styleVars.colors_designYellowLight,
    },
  ];

  const getColor = (initials) => {
    const key =
      ((initials.charCodeAt(0) || 0) + (initials.charCodeAt(1) || 0)) % bgColorList.length;

    return bgColorList[key];
  };

  const getInitials = (name) => {
    let output = '';

    if (name && name.length) {
      const namesArray = name.split(' ');
      output += `${namesArray[0].charAt(0)}`.toUpperCase();
      /* saving this for if we decide on multiple characters in temp names
      if (namesArray) {
        if (namesArray[0] && namesArray[0].length) {
          output += `${namesArray[0].charAt(0)}`;
        }
        if (namesArray[1] && namesArray[1].length && !singleInitial) {
          output += `${namesArray[1].charAt(0)}`;
        }
      } */
    }

    return output || null;
  };

  const handleImageError = () => {
    setUserPic(null);
  };

  const namePlaceholder = getInitials(name);

  let output = null;

  if (userPic) {
    output = (
      <img
        className={`profile-pic ${size}`}
        src={userPic}
        alt="user avatar"
        onError={handleImageError}
      />
    );
  } else if (namePlaceholder) {
    output = (
      <span
        className={classNames(
          'profile-pic',
          {
            'profile-pic--noneBorder': showBorder,
          },
          'name-placeholder'
        )}
        style={{
          backgroundColor: getColor(namePlaceholder).bgColor,
          color: getColor(namePlaceholder).initialColor,
        }}
      >
        <span>{namePlaceholder}</span>
      </span>
    );
  } else {
    output = (
      <img
        className={classNames(
          'profile-pic',
          {
            'profile-pic--noneBorder': showBorder,
          },
          'image-placeholder'
        )}
        src={imgProfilePlaceholder}
        alt="user avatar placeholder"
      />
    );
  }

  return <div className={`user-profile-circle ${size}`}>{output}</div>;
};

UserProfileCircle.defaultProps = {
  imageSrc: null,
  singleInitial: false,
  size: 'sm',
};

UserProfileCircle.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  singleInitial: PropTypes.bool,
  size: PropTypes.string,
};

export { UserProfileCircle };
