import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import ScoreScaleBar from 'components/ScoreScaleBar';

import { wrapWithPopover } from '../../../helpers/Utils';

/**
 * Renders a single bar graph for an Assessment statistics data item
 *
 * @param {string} title Item title
 * @param {string} tooltip Info tooltip contents
 * @param {number} value Percentage value
 *
 * @return render
 */
const AssessmentStatBarItem = ({ label, tooltip: { title, content }, value, reversed = false }) => {
  return (
    <>
      <div className="title">{label}</div>
      {title
        ? wrapWithPopover({
            target: (
              <div className={classNames('info-btn', { clickable: !!title })}>
                <span className="icon">
                  <FontAwesomeIcon className="tooltip-icon" icon={['fas', 'info']} />
                </span>
              </div>
            ),
            content,
            title,
          })
        : null}
      <ScoreScaleBar value={value} nullLabel="N/A" minProgressVisible={0.5} reversed={reversed} />
    </>
  );
};

export { AssessmentStatBarItem };
