import React, { useEffect, useState } from 'react';

import 'components/databots/DatabotConfig.scss';
import './BrandNameAssignmentBot.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { BOT_STATUSES } from 'constants/BotConstants';
import { BrandNameTableDef } from 'helpers/table-defs/ViewBotsTableDef';

const BrandNameAssignmentBot = ({
  configData,
  setConfigData,
  botStatusId,
  slug,
  status,
  hasDownloadedReport,
  reportName,
  handleRunBot,
  handleCancelBot,
  additionalData,
}) => {
  const { name: mfrName = null, total_products: totalProducts = null } = additionalData ?? {};

  const [brandNames, setBrandNames] = useState([]);

  useEffect(() => {
    const brandNames =
      additionalData?.brand_names?.map((brandName) => {
        return { ...brandName, updated_brand_name: brandName.brand_name };
      }) ?? [];
    setBrandNames(brandNames);
  }, [additionalData]);

  const setBrandNamesData = () => {
    setConfigData(
      brandNames.map((brandName) => {
        return {
          old: brandName.brand_name,
          new: brandName.updated_brand_name,
        };
      })
    );
  };

  const paginationTotalRenderer = (from, to, size) => {
    return (
      <div className="pagination-total">
        Showing <span>{from}</span> to <span>{to}</span> of <span>{size}</span> results
      </div>
    );
  };

  const renderConfigBody = () => {
    return (
      <div className="assign-brand-names">
        <h2 className="assign-brand-names-header">Assign Brand Names</h2>
        <div className="assign-brand-names-table">
          <BootstrapTable
            keyField="brand_name"
            bootstrap4
            bordered={false}
            data={brandNames}
            columns={BrandNameTableDef.columns}
            cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
            pagination={paginationFactory({
              hideSizePerPage: true,
              paginationTotalRenderer: (from, to, size) => paginationTotalRenderer(from, to, size),
              showTotal: true,
            })}
          />
        </div>
      </div>
    );
  };

  const renderConfirmDialogue = () => {
    return (
      <div>
        <p className="label">You are about to rename the following brand names:</p>
        <div className="details">
          <div className="details-row">
            <p className="details-row-label">Original Brand Name</p>
            <p className="details-row-label">New Brand Name</p>
          </div>
          {configData?.length &&
            configData?.map((brandName) => (
              <div key={brandName.old} className="details-row">
                <p className="details-row-value">{brandName.old ?? 'N/A'}</p>
                <p className="details-row-value">{brandName.new ?? 'N/A'}</p>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: mfrName ?? 'N/A' },
        {
          label: 'Total Products',
          value: totalProducts ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      requiresApproval
      hasDownloadedReport={hasDownloadedReport}
      reportName={reportName}
      title="Brand Name Assignment Databot"
      bodyContent={renderConfigBody()}
      hideBodyActions={[
        BOT_STATUSES.PENDING,
        BOT_STATUSES.RUNNING,
        BOT_STATUSES.AWAITING_APPROVAL,
      ].includes(status)}
      disableActions={{
        run: !(brandNames.length > 0),
      }}
      confirmCallback={setBrandNamesData}
      confirmDialogTitle="Brand Name Assignment Bot"
      confirmDialogBody={renderConfirmDialogue}
      onRun={() => handleRunBot(false)}
      onCancel={() => handleCancelBot()}
    />
  );
};

export { BrandNameAssignmentBot };
