import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import GetAllManufacturersReducer from './GetAllManufacturersReducer';
import GetAllProductsReducer from './GetAllProductsReducer';
import GetDataRequestDeliveryFormatsReducer from './GetDataRequestDeliveryFormatsReducer';
import GetProductByIdReducer from './GetProductByIdReducer';
import GetSupplierStatisticsReducer from './GetSupplierStatisticsReducer';
import GetUserProfileReducer from './GetUserProfileReducer';
import LocationChangeReducer from './LocationChangeReducer';
import UserLoginReducer from './UserLoginReducer';
import UserLogoutReducer from './UserLogoutReducer';
import { WebsocketMessageReducer } from './WebsocketMessageReducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    GetAllProductsReducer,
    GetProductByIdReducer,
    LocationChangeReducer,
    UserLoginReducer,
    UserLogoutReducer,
    GetUserProfileReducer,
    GetSupplierStatisticsReducer,
    GetAllManufacturersReducer,
    GetDataRequestDeliveryFormatsReducer,
    WebsocketMessageReducer,
  });
