import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { DatabotFilesTable } from 'components/data-sources/DatabotFilesTable';
import ActionStatusConstants from 'constants/ActionStatusConstants';

/**
 * Render Source files table section
 *
 * @param {number} mfrId Mfr Id
 * @return render
 */
const SectionSourceFiles = ({ mfrId }) => {
  const history = useHistory();
  const location = useLocation();

  const [dataStatus, setDataStatus] = useState(ActionStatusConstants.INITIAL);

  return (
    <section className="source-files">
      <div className="heading">
        <div className="title">
          <span>Source Files</span>
          {dataStatus === ActionStatusConstants.ISBUSY && <LoadingSpinner fast />}
        </div>
        <div className="actions">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => history.push(`/data-sources${location?.search}`)}
          >
            View All Source Files -&gt;
          </button>
        </div>
      </div>
      <div className="source-files-wrap">
        {!!mfrId && (
          <DatabotFilesTable
            type={DatabotFilesTable.TYPES.SOURCE_FILES}
            mfrId={mfrId}
            pageSize={5}
            onDataStatusChange={setDataStatus}
            emptyNotice
          />
        )}
      </div>
    </section>
  );
};

SectionSourceFiles.defaultProps = {
  mfrId: null,
};

SectionSourceFiles.propTypes = {
  mfrId: PropTypes.number,
};

export { SectionSourceFiles };
