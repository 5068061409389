import React from 'react';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';

import { DistributorJobComments } from './DistributorJobComments';

const CommentsBlock = ({ dataJobData, dataJobCommentsData, dataJobCommentsDataStatus }) => {
  const isEditMode = !['closed', 'canceled'].includes(dataJobData?.status);

  return (
    <div className="panel-block data-job-panel-comments-block">
      <div className="block-title">
        <div className="title">
          <span className="label">Comments</span>
          {!!dataJobCommentsData?.length && (
            <span className="comments-count">({dataJobCommentsData?.length})</span>
          )}
        </div>
      </div>
      <div className="block-content">
        {dataJobData?.id &&
        dataJobData?.id !== undefined &&
        ![ActionStatusConstants.ISBUSY, ActionStatusConstants.INITIAL].includes(
          dataJobCommentsDataStatus
        ) ? (
          <DistributorJobComments
            dataJobCommentsData={dataJobCommentsData}
            dataJobCommentsDataStatus={dataJobCommentsDataStatus}
            jobId={dataJobData.id}
            canEditExistingComments={isEditMode}
          />
        ) : (
          <div className="panel-block data-job-panel-comments-block">
            <div className="loadingspinner">
              <LoadingSpinner />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { CommentsBlock };
