import React, { useCallback, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { Button, ListGroup } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { FileUpload } from 'components/common/FileUpload/FileUpload';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { checkFileExtAndSize } from 'helpers/Utils';

const BaseDataFillFileUpload = ({
  manufacturerId,
  isUploading,
  setIsUploading,
  files,
  setFiles,
  setUploadHeaders,
  botStatusId,
  setConfigData,
  setExportHeaders,
  setAreHeadersUpdating,
  slug,
}) => {
  const isMounted = useIsMounted();
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('0');
  const [uploadId, setUploadId] = useState(null);
  const [attachCount, setAttachCount] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(ActionStatusConstants.INITIAL);
  const [deleteProgFile, setDeleteProgFile] = useState(null);

  const getExportHeaders = useCallback(async () => {
    setAreHeadersUpdating(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/additional-data/${slug}?manufacturer_id=${manufacturerId}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setExportHeaders(res.data?.headers || []);
          setAreHeadersUpdating(false);
        }
      },
      onError: () => {
        if (isMounted.current) {
          setExportHeaders([]);
          setAreHeadersUpdating(false);
        }
      },
    });
  }, [isMounted, manufacturerId, setExportHeaders, setAreHeadersUpdating, slug]);

  const handleUpload = useCallback(
    async (data) => {
      getExportHeaders();
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/bots/upload`,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
        isFileFormData: true,
        onProgress: (progressEvent) => {
          const percentage = ApiCalls.getProgressPercent(progressEvent);
          if (percentage >= 0) setUploadProgress(percentage.toFixed(0));
        },
        onSuccess: (response) => {
          if (isMounted.current) {
            const {
              headers,
              id,
              file_upload_name: fileUploadName,
              file_name: file,
              container_name: container,
            } = response.data;

            setUploadHeaders(headers || []);
            setIsUploadComplete(true);
            setIsUploading(false);
            setUploadId(id);
            setFiles([...files, fileUploadName]);
            setConfigData({ file_name: file, container_name: container });
          }
        },
        onError: () => {
          if (isMounted.current) {
            setIsUploading(false);
          }
        },
      });
    },
    [
      isMounted,
      setIsUploading,
      setUploadHeaders,
      setUploadId,
      files,
      setFiles,
      setConfigData,
      getExportHeaders,
    ]
  );

  const onFileClicked = useCallback(
    async (e) => {
      const typeCallback = (name) => toast.error(`File type of "${name}" not allowed.`);
      const sizeCallback = () =>
        toast.error(
          'The file you are trying to upload exceeds the 2GB attachment limit. Try putting in a shared location and adding a link instead.'
        );

      const file = checkFileExtAndSize(e, typeCallback, sizeCallback);
      if (!file) {
        return;
      }
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('bot_status_id', botStatusId);
      handleUpload(formData);
    },
    [handleUpload, setIsUploading, botStatusId]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // filter to only selected amount of files
      if (acceptedFiles.length > 0) {
        onFileClicked(acceptedFiles);
      }
    },
    [onFileClicked]
  );

  useEffect(() => {
    setAttachCount(files.length);
  }, [files, files.length]);

  const doDeleteFile = useCallback(
    (delFile) => {
      if (!delFile) {
        return;
      }
      setDeleteProgFile({ name: delFile });

      setUploadStatus(ActionStatusConstants.ISBUSY);

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.DELETE,
        urlPath: `/bots/upload`,
        params: { file_name: delFile, bot_status_id: botStatusId },
        onSuccess: () => {
          setUploadStatus(ActionStatusConstants.SUCCESS);
          setFiles(files.filter((file) => file !== delFile));
          setUploadHeaders([]);
          toast.success(`File successfully removed.`);
          setConfigData({ file_name: null, container_name: null });
        },
        onError: () => {
          setUploadStatus(ActionStatusConstants.FAILURE);
        },
        onEnd: () => {
          setDeleteProgFile(null);
        },
      });
    },
    [files, setFiles, botStatusId, setUploadHeaders, setConfigData]
  );

  const deleteButtonRender = useCallback(
    (item) => {
      return (
        <ConfirmDialog
          onConfirm={() => doDeleteFile(item)}
          headerContent="Delete File?"
          bodyContent={
            <span className="item-deletebutton-confirmbody">
              {`Are you sure you want to delete file "${item}"?`}
            </span>
          }
        >
          {({ onClick }) => (
            <Button
              variant="outline-danger"
              title="Delete"
              className="item-deletebutton"
              disabled={uploadStatus === ActionStatusConstants.ISBUSY}
              onClick={() => {
                return uploadStatus !== ActionStatusConstants.ISBUSY && onClick();
              }}
            >
              {!deleteProgFile || (deleteProgFile && item.id !== deleteProgFile.id) ? (
                <FontAwesomeIcon icon={['far', 'trash-alt']} />
              ) : (
                <LoadingSpinner style={{ fontSize: '1em' }} />
              )}
            </Button>
          )}
        </ConfirmDialog>
      );
    },
    [deleteProgFile, doDeleteFile, uploadStatus]
  );

  // usecallback to memoize render of list items and only rerender when deps change
  const renderListItems = useCallback(() => {
    return [
      ...files.map((f) => {
        return (
          <ListGroup.Item className="item-listgroup" key={f}>
            <span className="itemtext">{f}</span>
            {deleteButtonRender(f)}
          </ListGroup.Item>
        );
      }),
    ];
  }, [deleteButtonRender, files]);

  return (
    <>
      <div className="upload-file-container">
        {files.length === 0 ? (
          <FileUpload
            onDrop={onDrop}
            isFileLoading={isUploading}
            maxFileCount={1}
            uploadProgress={uploadProgress}
            isUploadComplete={isUploadComplete}
            uploadId={uploadId}
            setIsUploadComplete={setIsUploadComplete}
            onSuccessMessage="File Successfully Added!"
          />
        ) : null}
      </div>
      <div className="file-list-container">
        <div className="upload-list">
          <div className="headertext">Files{` (${attachCount})`}</div>
          <ListGroup variant="flush">{renderListItems()}</ListGroup>
        </div>
      </div>
    </>
  );
};

BaseDataFillFileUpload.propTypes = {
  setIsUploading: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
};

export { BaseDataFillFileUpload };
