import React from 'react';

import YouTube from 'react-youtube';
import './YouTubeVideo.scss';

const YouTubeVideo = ({ videoId, showControls = true }) => {
  const videoOpts = {
    width: '100%',
    height: '100%',
    playerVars: {
      controls: showControls ? 1 : 0,
      showinfo: 0,
      rel: 0,
      iv_load_policy: 3,
      modestbranding: 1,
    },
  };
  return <YouTube videoId={videoId} className="youtube-container" opts={videoOpts} />;
};

export { YouTubeVideo };
