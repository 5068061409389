import React from 'react';

import { TUTORIALSTEPS } from 'constants/DataIngestionConstants';

const DataIngestionContext = React.createContext({
  attachmentData: {},
  jobData: {},
  tutorial: { step: TUTORIALSTEPS.HEADER },
  errors: {},
});

export { DataIngestionContext };
