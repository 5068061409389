import './ReclassifyTo.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-bootstrap';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { RECLASSIFY_ATTRIBUTES_BOT } from 'constants/BotConstants';

const ReclassifyTo = ({
  attributes,
  isDisabled,
  reclassifyTo,
  setReclassifyTo,
  fromAttribute,
  toAttribute,
  setToAttribute,
  invalidMessage,
  setInvalidMessage,
}) => {
  const [toOptions, setToOptions] = useState([]);

  useEffect(() => {
    if (attributes !== null && reclassifyTo !== null) {
      if (reclassifyTo === RECLASSIFY_ATTRIBUTES_BOT.general.value) {
        const finArray = attributes.general_attributes.attributes
          .map((item) => {
            return { label: item.name, value: item.id, number: item.product_count };
          })
          .filter((item) => item.value !== fromAttribute?.value);
        setToOptions(finArray);
      } else if (reclassifyTo === RECLASSIFY_ATTRIBUTES_BOT.detailed.value) {
        const finArray = attributes.detailed_attributes.attributes
          .map((item) => {
            return { label: item.name, value: item.id, number: item.product_count };
          })
          .filter((item) => item.value !== fromAttribute?.value);
        setToOptions(finArray);
      } else {
        setToOptions([]);
      }
    }
  }, [attributes, reclassifyTo, fromAttribute]);

  const handleNewAttribute = ({ target: { value } }) => {
    setToAttribute(value);
    if (value && value.length > 100) {
      setInvalidMessage('Error: Value too long. Please enter a value of 100 characters or less.');
    } else if (
      value &&
      attributes.detailed_attributes.attributes.filter((item) => item.name === value).length > 0
    ) {
      setInvalidMessage('Error: Value already exists. Please select it from Detailed Attributes');
    } else {
      setInvalidMessage(null);
    }
  };

  const SelectTo = () => {
    if (['general', 'detailed', null].includes(reclassifyTo)) {
      return (
        <StyledMultiselect
          placeholder={`Select ${
            reclassifyTo ? RECLASSIFY_ATTRIBUTES_BOT[reclassifyTo]?.label : ''
          } Attribute`}
          values={toAttribute || []}
          options={toOptions}
          optionLimit={100}
          optionsSort
          setOnChange={(e) => setToAttribute(e)}
          isSearchable
          menuPlacement="auto"
          menuPortalTarget={document.body}
          isMulti={false}
          isDisabled={!(toOptions && toOptions.length) || reclassifyTo === null || isDisabled}
          selectWrapperStyle={{ maxHeight: '3rem' }}
        />
      );
    }
    return (
      <Form.Control
        as="input"
        value={toAttribute !== null ? toAttribute : ''}
        placeholder="Name your new description (ex: SEO Description 1)"
        onChange={handleNewAttribute}
        maxLength="100"
      />
    );
  };

  return (
    <section className={classNames('reclassify-to-actions', { disabled: isDisabled })}>
      <div>
        <h4>To</h4>
        <div className="to-radio">
          <label htmlFor="radio-to-general">
            <input
              id="radio-to-general"
              type="radio"
              name="radioTo"
              value="general"
              checked={reclassifyTo === 'general'}
              disabled={isDisabled}
              onChange={() => setReclassifyTo('general')}
            />
            General
          </label>
          <label htmlFor="radio-to-detailed">
            <input
              id="radio-to-detailed"
              type="radio"
              name="radioTo"
              value="detailed"
              checked={reclassifyTo === 'detailed'}
              disabled={isDisabled}
              onChange={() => setReclassifyTo('detailed')}
            />
            Detailed
          </label>
          <label htmlFor="radio-to-new">
            <input
              id="radio-to-new"
              type="radio"
              name="radioTo"
              value="new"
              checked={reclassifyTo === 'new'}
              disabled={isDisabled}
              onChange={() => setReclassifyTo('new')}
            />
            Create New Detailed Attribute
          </label>
        </div>
        {SelectTo()}
      </div>
      {invalidMessage !== null ? <div className="reclassify-invalid">{invalidMessage}</div> : null}
    </section>
  );
};

export { ReclassifyTo };
