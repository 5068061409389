import React, { useContext, useEffect, useState } from 'react';

import queryString from 'query-string';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingComponent } from 'components/common/LoadingComponent/LoadingComponent';
import { LoadingSpinnerForTable } from 'components/common/LoadingSpinner/LoadingSpinnerForTable';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { JOBTYPES } from 'constants/DataJobDetailsConstants';
import * as QueryStringConstants from 'constants/QueryStringConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import { DataJobsContext } from './DataJobsContext';
import { ModalPublishDraftJob } from './ModalPublishDraftJob';
import { ViewDataJobsTableDef } from './ViewDataJobsTableDef';
import { ViewPassThruFilesTableDef } from './ViewPassThruFilesTableDef';

const NoDataIndication = ({ displayData }) =>
  displayData && displayData?.length === 0 ? (
    <div className="no-jobs-found-alert">
      No jobs found. Please check your Filters and Search Terms above.
    </div>
  ) : (
    <div style={{ height: '12em' }}>
      <LoadingComponent className="tableNoDataLoader" text="" />
    </div>
  );

const SizePerPageRender = ({ options, currSizePerPage, onSizePerPageChange, location }) => {
  const qParams = queryString.parse(
    location?.search,
    QueryStringConstants.defaultQueryStringOptions
  );

  const selectOpts =
    (options &&
      options.map((item) => {
        return { value: item.page, label: item.text };
      })) ||
    [];
  const getSelectValue = () => {
    const currSizePage = qParams?.page_size || 25;
    if (currSizePerPage && selectOpts && selectOpts.length) {
      for (let i = 0; i < selectOpts.length; i++) {
        if (selectOpts[i].value === parseInt(currSizePage)) {
          return selectOpts[i];
        }
      }
    }
    return null;
  };

  return (
    <StyledMultiselect
      isClearable={false}
      isSearchable={false}
      options={selectOpts}
      values={getSelectValue()}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => `${option.label} Items`}
      label="Showing:"
      isMulti={false}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      setOnChange={(v) => {
        if (v) {
          onSizePerPageChange(v.value);
        }
      }}
      selectWrapperStyle={{ maxWidth: '200px' }}
      selectStyle={{
        control: () => ({
          height: '33.5px',
          minHeight: '33.5px',
        }),
      }}
    />
  );
};

/**
 * Component that displays a data table with Data Jobs.
 *
 * @param {object} param Props
 * @param {object} data Current data page
 * @param {number} totalSize Total size of data
 * @param {object} tableState Pagination and sort state
 * @param {function} onTableStateChange Handle sort/pagination changes
 * @param {function} onRowClick Handle row clicks
 * @returns Render
 */
const DataJobsTable = ({
  data,
  totalSize,
  tableState,
  onTableStateChange,
  onRowClick,
  activeTab,
}) => {
  const isMounted = useIsMounted();
  const location = useLocation();
  const { activeUser } = RootHooks.useActiveUser();
  const currentUserRole = activeUser?.role ?? null;
  const { featureFlags } = RootHooks.useFeatureFlags();

  const [displayData, setDisplayData] = useState(data);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [jobTableColumns, setJobTableColumns] = useState(null);

  const dataJobsContext = useContext(DataJobsContext);

  useEffect(() => {
    data && setDisplayData(data);
  }, [data]);

  useEffect(() => {
    setJobTableColumns(ViewDataJobsTableDef({ setShowPublishModal }));
  }, [setShowPublishModal, activeUser]);

  const isDistributor = currentUserRole === UserRoleConstants.CLIENT;
  const isSupplier = currentUserRole === UserRoleConstants.SUPPLIER;
  const isAdmin = currentUserRole === UserRoleConstants.ADMIN;

  /**
   * Determine columns present based on user type and activeTab
   */
  const tableColumns =
    // Hide 'Manufacturer' and 'Distributor in 'Sent Jobs' and 'Received Jobs' Tabs
    [
      JOBTYPES.DATA_JOBS_SENT,
      JOBTYPES.DATA_JOBS_RECEIVED,
      JOBTYPES.DATA_JOBS_ALL,
      JOBTYPES.DATA_JOBS_INTERNAL,
    ].includes(activeTab) && jobTableColumns
      ? jobTableColumns.columns.filter((item) => {
          if (activeUser?.profile.manufacturer?.has_children && item.dataField === 'manufacturer') {
            return true;
          }
          if (item && item.dataField && ['distributor', 'manufacturer'].includes(item.dataField)) {
            return false;
          }
          return true;
        })
      : // PassThruFiles Table uses ViewPassThruFilesTableDef.js ...
      activeTab === JOBTYPES.PASS_THRU_FILES
      ? ViewPassThruFilesTableDef.columns
      : jobTableColumns &&
        jobTableColumns.columns.filter((item) => {
          if (!isAdmin) {
            if (item && item.dataField) {
              if (item.dataField === 'distributor' && isDistributor) {
                return false;
              }
              if (item.dataField === 'manufacturer' && isSupplier) {
                return false;
              }
            }
          }
          return true;
        });

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (
      parseInt(tableState.page) === parseInt(page) &&
      parseInt(tableState.page_size) === parseInt(sizePerPage) &&
      (tableState.sort_field || null) === (sortField || null) &&
      (tableState.sort_order || null) === (sortOrder || null)
    ) {
      return;
    }
    if (['pagination', 'sort'].includes(type)) {
      onTableStateChange({
        ...tableState,
        page,
        page_size: sizePerPage,
        sort_field: sortField,
        sort_order: sortOrder,
      });
    }
  };

  const renderSummary = () => {
    let indexStart,
      offset,
      indexEnd = 0;
    if (totalSize > 0 && tableState?.page_size) {
      offset = (tableState.page - 1) * tableState.page_size;

      indexStart = offset + 1;
      indexEnd = offset + tableState.page_size;
      if (indexEnd > totalSize) {
        indexEnd = totalSize;
      }
    }

    const summary =
      totalSize && indexStart && indexEnd ? (
        <div className="results-summary">
          {`Showing ${indexStart}-${indexEnd} of ${totalSize.toLocaleString('en-US')}`}
        </div>
      ) : null;

    return summary;
  };

  const tableSummary = renderSummary();

  const rowClasses = (row) => {
    let classes = null;
    if (featureFlags.ENABLE_READ_FILTER) {
      classes = row.viewed ? 'data-jobs-table-read' : 'data-jobs-table-unread';
    } else classes = 'data-jobs-table-read';

    // remove link cursor for pass thru files
    if (activeTab === JOBTYPES.PASS_THRU_FILES) {
      classes += ' pass-thru-file-row-cursor';
    }
    return classes;
  };

  const options = {
    custom: true,
    page: tableState?.page,
    sizePerPage: tableState?.page_size || 25,
    sizePerPageList:
      displayData && displayData.length
        ? [
            { text: '25', value: 25 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
          ]
        : [],
    totalSize,
    showTotal: true,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) =>
      location &&
      isMounted.current && (
        <SizePerPageRender
          options={options}
          currSizePerPage={currSizePerPage}
          onSizePerPageChange={onSizePerPageChange}
          location={location}
        />
      ),
  };

  const doPublishJob = (jobId, directionValue) => {
    dataJobsContext.setIsLoading(true);
    const payload = {
      id: jobId,
      data_request_action_type: directionValue,
    };

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PATCH,
      urlPath: `/data-requests/${jobId}`,
      data: payload,
      onSuccess: () => {
        toast.success('Job Published Successfully!');
        onRowClick(jobId);
        dataJobsContext.setIsLoading(false);
      },
      onError: () => {
        dataJobsContext.setIsLoading(false);
        toast.error('Unable to publish this job');
      },
    });
  };

  // this is janky - but the bootstrap table lib has to have a noDataIndication prop to allow an overlay with pagination
  return (
    <div className="bootstrap-table-wrap">
      {dataJobsContext.isLoading && tableColumns !== null ? (
        <LoadingSpinnerForTable />
      ) : (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <>
                {tableColumns && (
                  <BootstrapTable
                    remote
                    {...paginationTableProps}
                    loading={displayData && displayData.length && dataJobsContext.isLoading}
                    noDataIndication={<NoDataIndication displayData={displayData} />}
                    overlay={overlayFactory({
                      spinner: <LoadingSpinnerForTable />,
                      styles: {
                        overlay: (base) => ({
                          ...base,
                          background: 'rgba(255, 255, 255, 0.85)',
                        }),
                      },
                    })}
                    onTableChange={(type, newState) => onTableChange(type, newState)}
                    defaultSorted={
                      tableState?.sort_field
                        ? [
                            {
                              dataField: tableState.sort_field,
                              order: tableState.sort_order,
                            },
                          ]
                        : null
                    }
                    bordered={false}
                    bootstrap4
                    keyField="id"
                    rowClasses={rowClasses}
                    data={displayData && displayData.length ? displayData : []}
                    columns={tableColumns}
                    rowEvents={{
                      onClick: (e, row) => {
                        if (activeTab !== JOBTYPES.PASS_THRU_FILES) {
                          onRowClick((row && row.id) || null);
                        }
                      },
                    }}
                  />
                )}
                {paginationProps.totalSize > 0 ? (
                  <div className="react-bootstrap-table-pagination">
                    <PaginationListStandalone {...paginationProps} />
                    {tableSummary}
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                ) : null}
              </>
            );
          }}
        </PaginationProvider>
      )}
      {showPublishModal && (
        <ModalPublishDraftJob
          showPublishModal={showPublishModal}
          setShowPublishModal={(v) => setShowPublishModal(v)}
          doPublishJob={(jobId, directionValue) => doPublishJob(jobId, directionValue)}
        />
      )}
    </div>
  );
};

DataJobsTable.propTypes = {};

export { DataJobsTable };
