import './ReclassifyHeader.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * LoadingText.
 * @param manufacturer the manufacturer name
 * @param attributes an int counting the number of product attributes for the manufacturer
 * @param productTotal an int counting the total projects for the manufacturer
 */
const ReclassifyHeader = ({ attributes, manufacturer, productTotal }) => {
  return (
    <section className={classNames('reclassify-header-section')}>
      <div className="header-card card-manufacturer">
        <div className="title">Manufacturer</div>
        <div className="content">{manufacturer}</div>
      </div>
      <div className="header-card card-total">
        <div className="title">Total Products</div>
        <div className="content">{productTotal?.toLocaleString()}</div>
      </div>
      <div className="header-card card-detailed">
        <div className="title">Detailed Attributes</div>
        <div className="content">{attributes?.detailed_attributes?.total.toLocaleString()}</div>
      </div>
    </section>
  );
};

ReclassifyHeader.defaultProps = {
  manufacturer: null,
  productTotal: null,
};

ReclassifyHeader.propTypes = {
  manufacturer: PropTypes.string,
  productTotal: PropTypes.number,
};

export { ReclassifyHeader };
