import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { components } from 'react-select';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { statusValueConstants, statusValuesEnum } from 'constants/DataJobDetailsConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import { UserUtils } from 'helpers/UserUtils';

// Dropdown Label Values for Statuses from Backend
const statusValueMap = [
  {
    value: statusValueConstants.NEW,
    statuses: [statusValueConstants.NEW],
    label: statusValuesEnum[statusValueConstants.NEW],
  },
  {
    value: statusValueConstants.IN_PROCESS,
    statuses: [
      statusValueConstants.IN_PROCESS,
      statusValueConstants.FLASH_ASSESSMENT_PENDING,
      statusValueConstants.CLARIFICATION_NEEDED,
    ],
    label: statusValuesEnum[statusValueConstants.IN_PROCESS],
  },
  {
    value: statusValueConstants.READY_FOR_APPROVAL,
    statuses: [statusValueConstants.READY_FOR_APPROVAL, statusValueConstants.READY_TO_SHARE],
    label: statusValuesEnum[statusValueConstants.READY_FOR_APPROVAL],
  },
  {
    value: statusValueConstants.COMPLETE,
    statuses: [statusValueConstants.COMPLETE, 'completed'],
    label: statusValuesEnum[statusValueConstants.COMPLETE],
  },
  {
    value: statusValueConstants.CLOSED,
    statuses: [statusValueConstants.CLOSED],
    label: statusValuesEnum[statusValueConstants.CLOSED],
  },
  {
    value: statusValueConstants.CANCELED,
    statuses: [statusValueConstants.CANCELED],
    label: statusValuesEnum[statusValueConstants.CANCELED],
  },
  {
    value: statusValueConstants.REJECTED,
    statuses: [statusValueConstants.REJECTED],
    label: statusValuesEnum[statusValueConstants.REJECTED],
  },
];

// Using the statusValueMap, get the corresponding value for the current status
const getMappedSelectedOption = (value) => {
  const matchingStatus = statusValueMap.find((item) => item?.statuses?.includes(value));
  return matchingStatus ? [{ value: matchingStatus.value, label: matchingStatus.label }] : [];
};

// Customize visualization of selected option for Select component
const CustomSelectSingleValue = (props) => {
  const selectedLabel = props?.data?.label ?? null;

  const output = selectedLabel ? (
    <>
      <span className="label">Status:</span> <span className="value">{selectedLabel}</span>
    </>
  ) : (
    <span className="label">Select Status</span>
  );

  return (
    <components.SingleValue {...props}>
      <span className="selected-value-wrapper">{output}</span>
    </components.SingleValue>
  );
};

// get confirm dialog word depending on status
const getConfirmActionWord = (status) => {
  switch (status) {
    case statusValueConstants.CLOSED:
      return 'Close';
    case statusValueConstants.CANCELED:
      return 'Cancel';
    case statusValueConstants.REJECTED:
      return 'Reject';
    default:
      return 'Reopen';
  }
};

/**
 * Status selector dropdown.
 *
 * @param {object} dataJobData Data Job object
 * @param {function} setDataJobData Updates dataJobData object
 * @param {boolean} disabled Disables status change
 * @return render
 */
const StatusSelector = ({ dataJobData, setDataJobData }) => {
  const isMounted = useIsMounted();

  const { activeUser } = RootHooks.useActiveUser();

  // Hold intermediate values during confirmation dialog
  const [confirmStatusModalVisible, setConfirmStatusModalVisible] = useState(false);
  const [confirmStatusValue, setConfirmStatusValue] = useState(null);
  const [confirmStatusComment, setConfirmStatusComment] = useState(null);

  const [saveStatusStatus, setSaveStatusStatus] = useState(ActionStatusConstants.INITIAL);
  const [statusOptions, setStatusOptions] = useState([]);

  const statusValue = dataJobData?.status ?? null;

  useEffect(() => {
    const getAvailableStatuses = () => {
      // Get Statuses Available to User
      const reqCfg = {
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/data-requests/${dataJobData.id}/available-statuses`,
        onSuccess: (res) => {
          const availableStatuses = statusValueMap.filter((status) =>
            res.data.includes(status.value)
          );
          setStatusOptions(availableStatuses);
        },
        onError: (e) => {
          console.error(e);
        },
      };
      ApiCalls.doCall(reqCfg);
    };
    isMounted && dataJobData && getAvailableStatuses();
  }, [dataJobData, isMounted]);

  // Saves selected status and updates root data job with response from server
  const doSaveStatus = ({ status, comment }) => {
    if (!(status || dataJobData?.id)) {
      console.error('doSaveStatus called with invalid params', status, comment, dataJobData);
      return;
    }

    const reqData = { status };

    if (comment?.length) {
      reqData.comment_content = comment;
    }

    setSaveStatusStatus(ActionStatusConstants.ISBUSY);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PATCH,
      urlPath: `/data-requests/${dataJobData.id}`,
      data: reqData,
      onSuccess: (res) => {
        if (isMounted.current) {
          const newDataJobData = res?.data ?? null;
          if (newDataJobData?.id) {
            setSaveStatusStatus(ActionStatusConstants.SUCCESS);
            setDataJobData(newDataJobData);
          } else {
            setSaveStatusStatus(ActionStatusConstants.FAILURE);
          }
        }
      },
      onError: () => {
        if (isMounted.current) {
          setSaveStatusStatus(ActionStatusConstants.FAILURE);
        }
      },
    });
  };

  // Trigger onChangeStatus when the confirm dialog is passed
  const onConfirmChange = () => {
    const newStatus = { status: confirmStatusValue };

    if (confirmStatusComment?.length) {
      newStatus.comment = confirmStatusComment;
    }

    doSaveStatus(newStatus);

    setConfirmStatusModalVisible(false);
    setConfirmStatusValue(null);
    setConfirmStatusComment(null);
  };

  // Show confirm dialog if either reopening(last value was closed)
  // or closing(next value is closed). Otherwise just fire onChangeStatus.
  const onChangeShowConfirmDialog = (_status) => {
    if (
      [
        statusValueConstants.CLOSED,
        statusValueConstants.CANCELED,
        statusValueConstants.REJECTED,
      ].includes(_status?.value) ||
      [
        statusValueConstants.CLOSED,
        statusValueConstants.CANCELED,
        statusValueConstants.REJECTED,
      ].includes(statusValue)
    ) {
      setConfirmStatusValue(_status?.value ?? null);
      setConfirmStatusModalVisible(true);
    } else {
      doSaveStatus({ status: _status?.value ?? null });
    }
  };

  return (
    <div className="data-job-panel-status-selector">
      <ModalPanel
        header="Change Data Job Status?"
        body={
          <>
            <p className="confirm-message">
              You are about to {getConfirmActionWord(confirmStatusValue)} this Data Job. Please
              provide a reason / resolution:
            </p>
            <Form.Control
              type="text"
              className="status-change-reason"
              value={confirmStatusComment || ''}
              onChange={(e) => setConfirmStatusComment(e.target.value)}
            />
          </>
        }
        footer={
          <>
            <Button variant="secondary" onClick={() => setConfirmStatusModalVisible(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmChange}>
              OK
            </Button>
          </>
        }
        className="modal-panel-data-job-panel-status-selector"
        setIsVisible={setConfirmStatusModalVisible}
        isVisible={confirmStatusModalVisible}
      />

      {saveStatusStatus === ActionStatusConstants.ISBUSY && <LoadingSpinner fast />}

      <StyledMultiselect
        values={getMappedSelectedOption(statusValue)}
        options={statusOptions}
        isDisabled={!statusOptions.length || UserUtils.isReadOnly(activeUser)}
        setOnChange={onChangeShowConfirmDialog}
        isMulti={false}
        customComponents={{
          SingleValue: CustomSelectSingleValue,
        }}
      />

      {saveStatusStatus === ActionStatusConstants.ISBUSY && <LoadingView text="Saving" />}
    </div>
  );
};

StatusSelector.defaultProps = { dataJobData: null };

StatusSelector.propTypes = {
  dataJobData: PropTypes.any,
  setDataJobData: PropTypes.func.isRequired,
};

export { StatusSelector };
