import './LoadingComponent.scss';
import React from 'react';

import { LoadingLogoSpinner } from 'components/common/LoadingSpinner/LoadingLogoSpinner';
import { LoadingText } from 'components/common/LoadingText/LoadingText';

const LoadingComponent = ({ className, text }) => {
  return (
    <div className={`component-spinner ${className}`}>
      <div className="component-spinner-content">
        <div className="component-spinner-loading-spinner">
          <LoadingLogoSpinner />
        </div>
        <div className="component-spinner-label">
          {text && <LoadingText style={{ textAlign: 'left', margin: 'auto' }} text={text} />}
        </div>
      </div>
    </div>
  );
};
export { LoadingComponent };
