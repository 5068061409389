import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { DatabotsUsageMetrics } from 'components/metrics/DatabotsUsageMetrics/DatabotsUsageMetrics';

const ViewDatabotsUsageMetrics = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="content content-fluid view-databots-usage-metrics">
      <Helmet bodyAttributes={{ 'data-page': 'databots-usage-metrics' }}>
        <title>Databots Usage Metrics</title>
      </Helmet>
      <h1>Databots Usage Metrics</h1>
      {!!isLoading && <LoadingView />}
      <DatabotsUsageMetrics setIsLoading={setIsLoading} />
    </div>
  );
};

export { ViewDatabotsUsageMetrics };
