import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { RootHooks } from 'helpers/RootHooks';
import { formatDate8601, formattedMonthYear } from 'helpers/TimeUtils';
import { UserUtils } from 'helpers/UserUtils';

import { getRootMenuPortalTarget } from '../../helpers/Utils';
import { StyledMultiselect } from '../common/StyledMultiselect/StyledMultiselect';
import { IdsRegularStats } from './_private/_user_stats/IdsRegularStats';
import { IdsServiceStats } from './_private/_user_stats/IdsServiceStats';
import { IdsSelfServiceQuotaBanner } from './_private/IdsSelfServiceQuotaBanner';
import { IdsShortDescriptionBlock } from './_private/IdsShortDescriptionBlock';

import './ImgBotEngineStats.scss';

const ImgBotEngineStats = ({ title = null, showDescription = true }) => {
  const history = useHistory();
  const location = useLocation();
  const menuPortalTarget = getRootMenuPortalTarget();

  const { activeUser } = RootHooks.useActiveUser();
  const isSelfService = UserUtils.isRoleSelfService(activeUser);
  const [selectedDate, setSelectedDate] = useState({});
  const [availableDates, setAvailableDates] = useState([]);

  const [isStatisticsLoading, setIsStatisticsLoading] = useState(false);
  const [statistics, setStatistics] = useState({});
  const [emptyStatistics, setEmptyStatistics] = useState(false);

  useEffect(() => {
    const BASE_URL_PATH = '/bots/process-job/ids/usage';
    const urlPath = selectedDate
      ? `${BASE_URL_PATH}?reference_date=${formatDate8601(selectedDate?.value)} `
      : BASE_URL_PATH;
    setIsStatisticsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath,
      onSuccess: (res) => {
        setStatistics(res.data);
        if (
          [
            res.data?.jobs?.not_started ?? 0,
            res.data?.jobs?.started ?? 0,
            res.data?.jobs?.canceled ?? 0,
          ].every((x) => x === 0) &&
          isSelfService
        ) {
          setEmptyStatistics(true);
        }
      },
      onEnd: () => {
        setIsStatisticsLoading(false);
      },
    });
  }, [isSelfService, selectedDate]);

  useEffect(() => {
    setIsStatisticsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/bots/process-job/ids/usage/filters',
      onSuccess: (res) => {
        const results = (res.data?.results || []).map((item) => {
          return {
            value: item,
            label: formattedMonthYear(item),
          };
        });
        setAvailableDates(results);
        setSelectedDate((results.length > 0 ? results[0] : {}) || {});
      },
      onEnd: () => {
        setIsStatisticsLoading(false);
      },
    });
  }, []);

  const getCurrentMonthName = () => {
    const date = new Date();
    return date.toLocaleString('en', { month: 'long' });
  };

  const getTitle = () => {
    if (title) {
      return title;
    }
    return isSelfService ? `${getCurrentMonthName()} Usage Overview` : 'Monthly Usage';
  };

  return (
    <>
      {isSelfService && <IdsSelfServiceQuotaBanner />}

      {showDescription && <IdsShortDescriptionBlock />}

      <div className="ids-summary-wrapper">
        <div className="header">
          <div className="title">
            <div>
              <div>{getTitle()}</div>
              {isSelfService ? null : (
                <div className="ids-regular-dates">
                  <div className="ids-month-filter-icon">
                    <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
                  </div>
                  <div className="ids-months-filter">
                    <StyledMultiselect
                      isSearchable
                      defaultValue={selectedDate}
                      values={selectedDate}
                      options={availableDates}
                      setOnChange={setSelectedDate}
                      getOptionValue={(option) => option.value}
                      isClearable={false}
                      hideSelectedOptions={false}
                      canReset
                      isMulti={false}
                      menuPortalTarget={menuPortalTarget}
                      isDisabled={isStatisticsLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {emptyStatistics ? (
          <div className="empty-statistics">
            <div className="title">You have not run any jobs this month.</div>
            <div className="description">
              <button
                type="button"
                className="btn btn-primary btn-request-data"
                onClick={() => history.push(`/image-databot-suite${location?.search}`)}
              >
                Get Started!
              </button>
            </div>
          </div>
        ) : (
          <>
            {isSelfService ? (
              <IdsServiceStats statistics={statistics} isStatisticsLoading={isStatisticsLoading} />
            ) : (
              <IdsRegularStats statistics={statistics} isStatisticsLoading={isStatisticsLoading} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export { ImgBotEngineStats };
