import escapeHtml from 'escape-html';
import { Text, Element } from 'slate';
import { jsx } from 'slate-hyperscript';

const TAGS = {
  quote: 'block-quote',
  p: 'paragraph',
  link: 'link',
  blist: 'bulleted-list',
  nlist: 'numbered-list',
  li: 'list-item',
  h1: 'heading-one',
  h2: 'heading-two',
  mention: 'mention',
};

// converts what what entered to code for db
export const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (!string) {
      return '<span></span>';
    }
    return string;
  }

  const children = node.children.map((n) => serialize(n)).join('');

  switch (node.type) {
    case TAGS.quote:
      return `<blockquote>${children}</blockquote>`;
    case TAGS.p:
      return `<p>${children}</p>`;
    case TAGS.link:
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case TAGS.blist:
      return `<ul>${children}</ul>`;
    case TAGS.h1:
      return `<h1>${children}</h1>`;
    case TAGS.h2:
      return `<h2 >${children}</h2>`;
    case TAGS.li:
      return `<li >${children}</li>`;
    case TAGS.nlist:
      return `<ol >${children}</ol>`;
    case TAGS.mention:
      // mention-id will reflect the user id of the user tagged
      return `<span class="comment-mention" mention-display="${node.tag.display}" mention-id="${node.tag.id}" mention-email="${node.tag.email}">@${node.tag.display}</span>`;
    default:
      return children;
  }
};

export const normalize = (node) => {
  if (Text.isText(node)) {
    return;
  }

  // Ensure that block and inline nodes have at least one text child.
  if (Element.isElement(node) && node.children.length === 0) {
    node.children.push({ text: '' });
  }
};

// called when you edit a comment
export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }
  if (el.nodeName === 'BR') {
    return null;
  }

  const children = Array.from(el.childNodes).map(deserialize).flat();

  if (el.nodeName === 'STRONG') {
    return children.map((child) => jsx('text', { bold: true }, child));
  }
  if (el.nodeName === 'CODE') {
    return children.map((child) => jsx('text', { code: true }, child));
  }
  if (el.nodeName === 'EM') {
    return children.map((child) => jsx('text', { italic: true }, child));
  }
  if (el.nodeName === 'U') {
    return children.map((child) => jsx('text', { underline: true }, child));
  }

  switch (el.nodeName) {
    case 'A':
      return jsx(
        'element',
        {
          type: 'link',
          url: el.getAttribute('href'),
        },
        children
      );
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'H1':
      return jsx('element', { type: 'heading-one' }, children);
    case 'H2':
      return jsx('element', { type: 'heading-two' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children);
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children);
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote' }, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'SPAN':
      // special if for our mentions
      if (el.textContent.search('class="comment-mention"')) {
        if (children.length > 0) {
          return jsx(
            'element',
            {
              mention: true,
              type: 'mention',
              tag: {
                display: el.getAttribute('mention-display') || JSON.stringify(children).slice(3, -2),
                id: el.getAttribute('mention-id'),
                email: el.getAttribute('mention-email'),
              },
            },
            children
          );
        }
      }
      return jsx('text');
    default:
      return jsx('text', { text: el.textContext });
  }
};
