import React from 'react';

import { formatMinDateStamp } from 'helpers/TimeUtils';
import { getDefaultTableColumnDef, getIconWithPopover, wrapWithTooltip } from 'helpers/Utils';
import * as Utils from 'helpers/Utils';

import { IdsUtils } from './IdsUtils';
import { JobActions } from './JobActions';

const ImgBotEngineLogTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('job_id', 'ID'),
      sort: false,
      formatter: (_cell, row) => {
        return row?.process_job?.id ?? 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('file', 'File'),
      sort: false,
      formatter: (_cell, row) => {
        const [fName, fExt] = Utils.splitExt(
          row?.process_job?.additional_data[0]?.original_file_name
        );
        const countCandidates = [
          row?.process_job?.statistics?.initial?.total_items,
          row?.process_job?.usage_statistics?.extraction?.count,
        ];

        const imgCount = countCandidates.find((c) => Number.isInteger(c));

        return (
          <>
            {wrapWithTooltip({
              target: (
                <div className="file-block">
                  <div className="file-block-name">
                    <span className="fname">{fName}</span>
                    <span className="fext">.{fExt}</span>
                  </div>
                  <div className="file-block-img-count">
                    {imgCount !== null ? (
                      <>
                        <span className="label">Images:</span>
                        <span className="value">{imgCount}</span>
                      </>
                    ) : (
                      <span className="label is-empty">Not assessed yet</span>
                    )}
                  </div>
                </div>
              ),
              content: row?.process_job?.additional_data[0]?.original_file_name ?? null,
            })}
          </>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('updated_at', 'Last Updated'),
      sort: false,
      formatter: (_cell, row) => {
        return row?.process_job?.updated_at
          ? formatMinDateStamp(row?.process_job?.updated_at)
          : 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('user', 'User'),
      sort: false,
      formatter: (_cell, row) => {
        let name = Utils.joinNameToStr(
          row?.requestor_user?.first_name ?? null,
          row?.requestor_user?.last_name ?? null
        );

        if (!name?.length) {
          name = row?.requestor_user?.email;
        }

        return name ?? 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('status', 'Status'),
      sort: false,
      formatter: (_cell, row) => {
        const phase = IdsUtils.getPhaseLabel(row?.process_job);
        const status = IdsUtils.getStatusLabel(row?.process_job);
        const tooltipInfo = IdsUtils.getTooltipInfo(row?.process_job);

        return (
          <div className="status-block">
            <div className="status-block-phase">{phase}</div>
            <div className="status-block-status">
              {IdsUtils.getStatusIcon(row?.process_job)}
              <div className="status-block-status-info">
                <span className="status-block-status-info-step">{status.step} </span>
                <span className="status-block-status-info-status">
                  <span className="status-block-status-info-status">{status.status}</span>
                  {status?.progress ? (
                    <>
                      <span className="status-block-status-info-progress">
                        - {status.progress}%
                      </span>
                    </>
                  ) : undefined}
                  {tooltipInfo &&
                    getIconWithPopover({
                      iconProp: ['far', tooltipInfo.icon],
                      content: tooltipInfo.msg,
                    })}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('images', 'Images'),
      sort: false,
      formatter: (_cell, row) => {
        let output = null;

        if (Number.isInteger(row?.process_job?.statistics?.initial?.total_items)) {
          output = row.process_job.statistics.initial.total_items;
        }

        return output?.toLocaleString('en-US');
      },
    },
    {
      ...getDefaultTableColumnDef('market_ready', 'Market Ready'),
      sort: false,
      formatter: (_cell, row) => {
        let output = null;

        const totalCount = Number.isInteger(row?.process_job?.statistics?.initial?.total_items)
          ? row.process_job.statistics.initial.total_items
          : null;

        const goodCountInit = Number.isInteger(row?.process_job?.statistics?.initial?.pass_images)
          ? row.process_job.statistics.initial.pass_images
          : null;

        const goodCountFinal = Number.isInteger(row?.process_job?.statistics?.final?.pass_images)
          ? row.process_job.statistics.final.pass_images
          : null;

        let goodCountCurrent = null;
        let goodPercentageCurrent = null;

        if (goodCountFinal !== null) goodCountCurrent = goodCountFinal;
        else if (goodCountInit !== null) goodCountCurrent = goodCountInit;

        if (totalCount > 0 && goodCountCurrent !== null) {
          goodPercentageCurrent = Math.round((goodCountCurrent / totalCount) * 100);
        }

        if (goodPercentageCurrent !== null) {
          output = (
            <div className="market-ready-block">
              <div className="percentage-current">
                <span className="value">{goodPercentageCurrent}</span>
                <span className="unit">%</span>
              </div>
              <div className="counts-block">
                <div className="counts-init">
                  <span className="label">Initial:</span>
                  <span className="value">
                    {goodCountInit !== null ? goodCountInit.toLocaleString('en-US') : 'N/A'}
                  </span>
                </div>
                <div className="counts-final">
                  {goodCountFinal !== null ? (
                    <>
                      <span className="label">Final:</span>
                      <span className="value">
                        {goodCountFinal !== null ? goodCountFinal.toLocaleString('en-US') : 'N/A'}
                      </span>
                    </>
                  ) : (
                    <span className="label is-empty">Not enhanced yet</span>
                  )}
                </div>
              </div>
            </div>
          );
        }

        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('ids-actions'),
      sort: false,
      formatter: (_cell, row) => <JobActions jobRow={row} />,
    },
  ],
};

export { ImgBotEngineLogTableDef };
