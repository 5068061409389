import './AssessmentsCard.scss';

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';

import { AssessmentsTableDef } from 'helpers/table-defs/ViewAssessmentsTableDef';

const AssessmentsCard = ({ data, handleViewDataJob }) => {

  return (
    <div className="assessments-card">
      <div className="assessments-card-header">
        <h3 className="assessments-card-header-title">
          <Link to={`/data-request/${data.id}`}>Data Job: {data.name}</Link>
        </h3>
        <button
          className="assessments-card-btn"
          onClick={() => handleViewDataJob(data.id)}
          type="button"
        >
          View Data Job Details
          <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
        </button>
      </div>
      <div className="assessments-table">
        <BootstrapTable
          bordered={false}
          bootstrap4
          keyField="id"
          data={data.attachments}
          columns={AssessmentsTableDef.columns}
        />
      </div>
    </div>
  );
};

export { AssessmentsCard };
