import { call, put, takeLeading } from 'redux-saga/effects';

import * as ApiCalls from 'api/ApiCalls';
import ActionTypeConstants from 'constants/ActionTypeConstants';

function* getAllManufacturersSaga(action) {
  console.debug('getAllManufacturersSaga', action);

  const res = yield call(ApiCalls.legacySyncDoCall, {
    urlPath: '/manufacturers/',
  });

  if (res?.data) {
    yield put({
      type: ActionTypeConstants.GET_ALL_MANUFACTURERS_SUCCESS,
      payload: res.data,
    });
  } else {
    yield put({
      type: ActionTypeConstants.GET_ALL_MANUFACTURERS_FAILURE,
    });
  }
}

function* getDataRequestDeliveryFormats() {
  const res = yield call(ApiCalls.legacySyncDoCall, {
    urlPath: '/data-requests/delivery-formats',
  });

  if (res?.data) {
    yield put({
      type: ActionTypeConstants.GET_DATA_REQUEST_DELIVERY_FORMATS_SUCCESS,
      payload: res.data,
    });
  } else {
    yield put({
      type: ActionTypeConstants.GET_DATA_REQUEST_DELIVERY_FORMATS_FAILURE,
    });
  }
}

export default function* () {
  yield takeLeading(ActionTypeConstants.GET_ALL_MANUFACTURERS, getAllManufacturersSaga);
  yield takeLeading(
    ActionTypeConstants.GET_DATA_REQUEST_DELIVERY_FORMATS,
    getDataRequestDeliveryFormats
  );
}
