import { useState, useEffect } from 'react';

import { singletonHook } from 'react-singleton-hook';

import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';

const initActiveUser = {
  activeUser: null,
  setActiveUser: () => {},
  activeUserStatus: ActionStatusConstants.INITIAL,
  setActiveUserStatus: () => {},
};

/**
 * Context for active user - currently this is the logged-in user, at some point
 * this might be a selectable View-as user when logged-in as admin in which case
 * we need to add another structure that also holds the logged in user separately.
 *
 * activeUser is the user object itself
 * activeUserStatus is the loading state for the user object - this might get obsolete
 */
const useActiveUser = singletonHook(initActiveUser, () => {
  const [activeUser, setActiveUser] = useState(initActiveUser.activeUser);
  const [activeUserStatus, setActiveUserStatus] = useState(initActiveUser.activeUserStatus);

  return {
    activeUser,
    setActiveUser,
    activeUserStatus,
    setActiveUserStatus,
  };
});

const initFeatureFlags = {
  featureFlags: {},
  setFeatureFlags: () => {},
};

/**
 * Context for feature flags
 */
const useFeatureFlags = singletonHook(initFeatureFlags, () => {
  const [featureFlags, setFeatureFlags] = useState(initFeatureFlags.featureFlags);

  useEffect(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/feature-flag/current`,
      onSuccess: (res) => {
        if (res?.data?.length) {
          const featureFlagsObject =
            res.data.reduce((a, v) => ({ ...a, [v.name]: v.value }), {}) ?? {};
          setFeatureFlags(featureFlagsObject);
        }
      },
    });
  }, [setFeatureFlags]);

  return { featureFlags, setFeatureFlags };
});

/**
 * Root hooks hold global context objects used throughout the system.
 */
const RootHooks = {
  useActiveUser,
  useFeatureFlags,
};

export { RootHooks };
