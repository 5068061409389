import React, { useEffect, useRef } from 'react';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { AuthRoute } from 'components/AuthRoute';
import { ViewAssessments } from 'components/views/ViewAssessments';
import { ViewClientMetrics } from 'components/views/ViewClientMetrics';
import { ViewCustomerClientMetrics } from 'components/views/ViewCustomerClientMetrics';
import { ViewCustomerManufacturerMetrics } from 'components/views/ViewCustomerManufacturerMetrics';
import { ViewDatabots } from 'components/views/ViewDatabots';
import { ViewDatabotsConfig } from 'components/views/ViewDatabotsConfig';
import { ViewDatabotsUsageMetrics } from 'components/views/ViewDatabotsUsageMetrics';
import { ViewDataIngestion } from 'components/views/ViewDataIngestion';
import { ViewDataIngestionAssessment } from 'components/views/ViewDataIngestionAssessment';
import { ViewDataIngestionDataQuality } from 'components/views/ViewDataIngestionDataQuality';
import { ViewDataJob } from 'components/views/ViewDataJob';
import { ViewDataJobCreate } from 'components/views/ViewDataJobCreate';
import { ViewDataJobs } from 'components/views/ViewDataJobs';
import { ViewDataLakehouseDashboard } from 'components/views/ViewDataLakehouseDashboard';
import { ViewDataSources } from 'components/views/ViewDataSources';
import ViewDataTeamActions from 'components/views/ViewDataTeamActions';
import { ViewFeatureFlags } from 'components/views/ViewFeatureFlags';
import ViewFlashAssessment from 'components/views/ViewFlashAssessment';
import ViewFlashAssessmentAttributes from 'components/views/ViewFlashAssessmentAttributes';
import { ViewHomeScreen } from 'components/views/ViewHomeScreen';
import { ViewImageQualityAssessments } from 'components/views/ViewImageQualityAssessments';
import { ViewImageQualityAssessmentsFaq } from 'components/views/ViewImageQualityAssessmentsFaq';
import { ViewImgBotEngineAbout } from 'components/views/ViewImgBotEngineAbout';
import { ViewImgBotEngineConfig } from 'components/views/ViewImgBotEngineConfig';
import { ViewContentBotEngineDashboard } from 'components/views/ViewContentBotEngineDashboard';
import { ViewImgBotEngineDashboard } from 'components/views/ViewImgBotEngineDashboard';
import { ViewImgBotEngineDetails } from 'components/views/ViewImgBotEngineDetails';
import { ConnectedLogin as ViewLogin } from 'components/views/ViewLogin';
import { ViewManufacturerMetrics } from 'components/views/ViewManufacturerMetrics';
import { ViewMetrics } from 'components/views/ViewMetrics';
import { ViewNotifications } from 'components/views/ViewNotifications';
import { ViewProductDetails } from 'components/views/ViewProductDetails';
import ViewProducts from 'components/views/ViewProducts';
import { ViewProductsExport } from 'components/views/ViewProductsExport';
import { ViewPrototype } from 'components/views/ViewPrototype';
import { ViewResetPassword } from 'components/views/ViewResetPassword';
import { ViewSignUp } from 'components/views/ViewSignUp';
import { ViewSignUpRequest } from 'components/views/ViewSignUpRequest';
import ViewStatistics from 'components/views/ViewStatistics';
import { ViewWeeklyMetrics } from 'components/views/ViewWeeklyMetrics';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';

const RootRoutes = () => {
  const location = useLocation();
  const lastLocation = useRef(location?.pathname ?? null);
  const { activeUser } = RootHooks.useActiveUser();
  const { featureFlags } = RootHooks.useFeatureFlags();

  useEffect(() => {
    if (location?.pathname !== lastLocation.current) {
      window.scrollTo(0, 0);
      lastLocation.current = location.pathname;
    }
  }, [location?.pathname, lastLocation]);

  return (
    <Switch>
      <AuthRoute exact path="/" render={() => <ViewHomeScreen />} />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/products"
        render={() => <ViewProducts currentUser={activeUser} />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/products-import-export"
        render={() => <ViewProductsExport />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/assessments"
        render={() => <ViewAssessments />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/data-sources"
        render={() => <ViewDataSources />}
      />

      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN, UserRoleConstants.CLIENT]}
        exact
        path="/statistics"
        render={() => <ViewStatistics />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/metrics"
        render={() => <ViewMetrics />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/weekly-metrics"
        render={() => <ViewWeeklyMetrics />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/databots-usage-metrics"
        render={() => <ViewDatabotsUsageMetrics />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/weekly-metrics/distributor/:id"
        render={() => <ViewCustomerClientMetrics />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/weekly-metrics/manufacturer/:id"
        render={() => <ViewCustomerManufacturerMetrics />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/metrics/manufacturer/:id"
        render={() => <ViewManufacturerMetrics />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/metrics/distributor/:id"
        render={() => <ViewClientMetrics />}
      />
      <AuthRoute isPublicRoute exact path="/reset-password" render={() => <ViewResetPassword />} />
      <AuthRoute isPublicRoute exact path="/login" render={() => <ViewLogin />} />
      {!!featureFlags.ENABLE_SIGN_UP_PAGE && (
        <AuthRoute isPublicRoute exact path="/sign-up" render={() => <ViewSignUp />} />
      )}
      <AuthRoute isPublicRoute exact path="/sign-up-request" render={() => <ViewSignUpRequest />} />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        path="/product/:id"
        render={(props) => <ViewProductDetails id={props.match.params.id} />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/data-job-create"
        render={() => <ViewDataJobCreate />}
        requiresWritePermission
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/data-jobs"
        render={() => <ViewDataJobs />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/data-request/:id"
        render={(props) => <ViewDataJob id={props.match.params.id} />}
      />
      <AuthRoute
        // allowed roles to be changed in v2
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/data-ingestion/rows/:attachmentId"
        render={(props) => <ViewDataIngestion attachmentId={props.match.params.attachmentId} />}
      />
      <AuthRoute
        // allowed roles to be changed in v2
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/data-ingestion/columns/:attachmentId"
        render={(props) => <ViewDataIngestion attachmentId={props.match.params.attachmentId} />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/data-ingestion/assessment/:attachmentId"
        render={(props) => (
          <ViewDataIngestionAssessment attachmentId={props.match.params.attachmentId} />
        )}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/data-ingestion/data-quality/:attachmentId"
        render={(props) => (
          <ViewDataIngestionDataQuality attachmentId={props.match.params.attachmentId} />
        )}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/flash-assessment/:id"
        render={(props) => <ViewFlashAssessment id={props.match.params.id} />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/data-request/:id/:attachmentId/flash-assessment"
        render={(props) => <ViewFlashAssessmentAttributes {...props} />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/prototype"
        render={<ViewPrototype />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/data-request/:id/flash-assessment"
        render={(props) => <ViewFlashAssessmentAttributes {...props} />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        requiresWritePermission
        exact
        path="/data_team"
        render={(props) => <ViewDataTeamActions {...props} />}
      />
      <AuthRoute exact path="/notifications" render={() => <ViewNotifications />} />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN, UserRoleConstants.SUPPLIER]}
        exact
        path="/databots"
        render={() => <ViewDatabots />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/databots/:slug"
        render={() => <ViewDatabotsConfig />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/data-lakehouse/dashboard"
        render={() => <ViewDataLakehouseDashboard />}
      />
      <AuthRoute
        allowedRoles={[UserRoleConstants.ADMIN]}
        exact
        path="/features"
        render={() => <ViewFeatureFlags />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/image-quality/assessment/:type/:id"
        render={() => <ViewImageQualityAssessments />}
      />
      <AuthRoute
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        exact
        path="/image-quality/assessment/:type/:id/faq"
        render={(props) => (
          <ViewImageQualityAssessmentsFaq
            type={props.match.params.type}
            id={props.match.params.id}
          />
        )}
      />

      <AuthRoute
        exact
        path="/content-databot-suite"
        forbiddenRoles={[UserRoleConstants.SELF_SERVICE]}
        render={() => <ViewContentBotEngineDashboard />}
      />
      <AuthRoute exact path="/image-databot-suite" render={() => <ViewImgBotEngineDashboard />} />

      <AuthRoute
        exact
        path="/image-databot-suite/configs"
        render={() => <ViewImgBotEngineConfig />}
      />

      <AuthRoute exact path="/image-databot-suite/help" render={() => <ViewImgBotEngineAbout />} />

      <AuthRoute
        exact
        path="/image-databot-suite/:id"
        render={(props) => <ViewImgBotEngineDetails id={props.match.params.id} />}
      />
      <Route
        render={() => {
          let originalUrl = window.location.href;
          if (originalUrl?.length > 100) {
            originalUrl = originalUrl.substring(0, 100);
          }
          toast.warn(`The requested page ${originalUrl} was not found.`);
          return <Redirect to="/" />;
        }}
      />
    </Switch>
  );
};

export { RootRoutes };
