import React from 'react';

import UserPlaceholder from 'assets/img/profile-placeholder.jpg';
import { generateDownloadUrl, readableFileSize } from 'helpers/AttachmentUtils';
import { formatDateStamp, formatTimeStamp } from 'helpers/TimeUtils';
import { getDefaultTableColumnDef } from 'helpers/Utils';

export const AssessmentsTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('file_name', 'File Name'),
      formatter: (_cell, row) => (
        <div className="assessments-table-file-details">
          <a
            href={generateDownloadUrl(row.id)}
            target="_blank"
            rel="noreferrer noopener"
            className="assessments-table-file-details__name"
          >
            {row.fileName}
          </a>
          <span className="assessments-table-file-details__size">
            <i>{readableFileSize(row.fileSize)}</i>
          </span>
        </div>
      ),
    },
    {
      ...getDefaultTableColumnDef('transformation_status', 'Original/Transformed'),
      formatter: (_cell, row) => row.type,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('uploaded_by', 'Uploaded By'),
      formatter: (_cell, row) => {
        return (
          <div className="assessments-table-user-img">
            <img src={row.uploadedBy.image ?? UserPlaceholder} alt="" />
            <div className="assessments-table-user-img-name">{row.uploadedBy.name}</div>
          </div>
        );
      },
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('uploaded_at', 'File Uploaded'),
      formatter: (_cell, row) => {
        const formattedDate = formatDateStamp(row.createdAt);
        const formattedTime = formatTimeStamp(row.createdAt);

        return (
          <div className="assessments-table-uploaded-at">
            <span className="assessments-table-uploaded-at__date">{formattedDate}</span>{' '}
            {formattedTime}
          </div>
        );
      },
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('flashAssessmentPublishedAt', 'Assessment Published'),
      formatter: (_cell, row) => {
        let output = null;
        if (row?.flashAssessmentPublishedAt) {
          const formattedDate = formatDateStamp(row.flashAssessmentPublishedAt);
          const formattedTime = formatTimeStamp(row.flashAssessmentPublishedAt);

          output = (
            <div className="assessments-table-uploaded-at">
              <span className="assessments-table-uploaded-at__date">{formattedDate}</span>{' '}
              {formattedTime}
            </div>
          );
        }

        return output;
      },
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('actions', ''),
      formatter: (_cell, row) => (
        <button
          className="assessments-table-view-assessment"
          onClick={() => (window.location.href = `/flash-assessment/${row.flashAssessmentId}`)}
          type="button"
        >
          View Assessment
        </button>
      ),
      sort: false,
    },
  ],
};
