import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { containsUrl, linkify, transformHtmlNodes } from 'helpers/Utils';

const AttributesTable = ({ sortDirection, sortAttributes, sections, rows }) => {
  const isRowEven = (name) => (rows.indexOf(name) + 1) % 2 === 0;

  const renderSortIcon = () => {
    const sortIcon =
      sortDirection === 'asc' ? 'sort-up' : sortDirection === 'desc' ? 'sort-down' : 'sort';

    return <FontAwesomeIcon size="sm" icon={['fas', sortIcon]} />;
  };

  const renderColumnValue = (value) => {
    if (typeof value === 'undefined' || value === null) return;

    if (containsUrl(value))
      return ReactHtmlParser(linkify(value), {
        transform: (node, index) => transformHtmlNodes(node, index, 'link'),
      });

    if (typeof value === 'boolean') return value.toString();
    if (typeof value === 'object')
      return Object.keys(value).length === 0 ? null : JSON.stringify(value);

    return value;
  };

  const renderTableRow = (section) => (
    <div className="attributes-table-row" key={section.label}>
      <div className="attributes-table-row-category">
        <span>{section.label}</span>
      </div>
      <div className="attributes-table-row-info">
        <ul>
          {section.columns.map((column) => (
            <li
              className={classNames('attributes-table-row-cell', {
                'attributes-table-row-cell--alt': isRowEven(column.name),
              })}
              key={column.name}
            >
              <p className="attributes-table-row-attribute">{column.name}</p>
              <p className="attributes-table-row-value">{renderColumnValue(column.value)}</p>
            </li>
          ))}
          {section.columns.length === 0 && (
            <li className="attributes-table-row-cell">
              <p className="attributes-table-row-value">No data available.</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );

  return (
    <div className="attributes-table">
      <div className="attributes-table-header">
        <div className="attributes-table-header-category">
          <span onClick={sortAttributes}>
            Category
            {renderSortIcon()}
          </span>
        </div>
        <div className="attributes-table-header-info">
          <li className="attributes-table-header-cell">
            <span className="attributes-table-header-attribute">Attribute</span>
            <span className="attributes-table-header-value">Value</span>
          </li>
        </div>
      </div>
      {sections.map((section) => !section.filteredOut && renderTableRow(section))}
      {sections.reduce((acc, curr) => {
        return !curr.filteredOut ? acc + 1 : acc;
      }, 0) === 0 && (
        <p className="attributes-table-row attributes-table-row--none">
          No categories selected. Please select a category to see data.
        </p>
      )}
    </div>
  );
};

AttributesTable.propTypes = {
  sortDirection: PropTypes.string,
  sortAttributes: PropTypes.func,
  sections: PropTypes.array,
  rows: PropTypes.array,
};

export { AttributesTable };
