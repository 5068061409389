import React, { useCallback, useState } from 'react';

import { Form } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import * as ApiCalls from '../../api/ApiCalls';
import BackboneLogo from '../../assets/img/backbone-logo.svg';
import ActionStatusConstants from '../../constants/ActionStatusConstants';
import { toast } from '../../helpers/ToastUtils';
import './SignUpRequest.scss';

// TODO: refactor to reduce duplicate code with SignUp component
const validatorMsgs = {
  INVALID: () => 'Invalid value',
  REQUIRED: () => 'This field is required',
  LENGTH_OVER: (name, len) => `${name} should be at most ${len} characters`,
  LENGTH_UNDER: (name, len) => `${name} should be at least ${len} characters`,
};

const regFormFields = {
  first_name: { key: 'first_name', label: 'First Name' },
  last_name: { key: 'last_name', label: 'Last Name' },
  email: { key: 'email', label: 'Work Email' },
  // message: { key: 'message', label: 'Message' },
  captcha_token: { key: 'captcha_token', label: 'Captcha Token' },
};

const regFormYupShape = yup.object().shape({
  first_name: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .matches(/\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/, 'Please enter valid name')
    .max(100, validatorMsgs.LENGTH_OVER(regFormFields.first_name.label, 100))
    .min(3, validatorMsgs.LENGTH_UNDER(regFormFields.first_name.label, 3)),
  last_name: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .matches(/\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/, 'Please enter valid name')
    .max(100, validatorMsgs.LENGTH_OVER(regFormFields.last_name.label, 100))
    .min(3, validatorMsgs.LENGTH_UNDER(regFormFields.last_name.label, 3)),
  email: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .email()
    .max(150, validatorMsgs.LENGTH_OVER(regFormFields.email.label, 100))
    .min(5, validatorMsgs.LENGTH_UNDER(regFormFields.email.label, 5)),
  // message: yup
  //   .string(validatorMsgs.INVALID())
  //   .max(256, validatorMsgs.LENGTH_OVER(regFormFields.message.label, 256)),
});

const initRegFormData = Object.values(regFormFields).reduce((a, v) => {
  return { ...a, [v.key]: '' };
}, {});

const SignUpRequest = () => {
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isLoading, setIsLoading] = useState(false);
  const [regForm, setRegForm] = useState({ ...initRegFormData });
  const [validationMsgs, setValidationMsgs] = useState({});
  const [submitRegStatus, setSubmitRegStatus] = useState(ActionStatusConstants.INITIAL);

  const generateValidationMsgs = useCallback(
    (yupErrObj, field) => {
      const output = field?.length ? { ...validationMsgs } : {};

      if (field?.length && field in output) {
        delete output[field];
      }

      if (yupErrObj?.inner?.length) {
        yupErrObj.inner.forEach((item) => {
          if (!field || (field && field === item.path)) {
            if (item.path && !Object.keys(output).includes(item.path)) {
              output[item.path] = item;
            }
          }
        });
      }

      return output;
    },
    [JSON.stringify(validationMsgs)]
  );

  const doValidateForm = (field) => {
    let _validationMsgs = {};

    try {
      regFormYupShape.validateSync(regForm, { abortEarly: false });
    } catch (e) {
      _validationMsgs = generateValidationMsgs(e, field);
    }

    setValidationMsgs(_validationMsgs);

    return _validationMsgs;
  };

  const setRegFormField = useCallback(
    (f, v) => {
      if (Object.keys(regFormFields).includes(f)) {
        setRegForm({ ...regForm, [f]: v });
      }
    },
    [JSON.stringify(regForm)]
  );

  const doSubmitRegForm = () => {
    const _validationMsgs = doValidateForm();
    if (_validationMsgs && Object.keys(_validationMsgs).length) {
      toast.error('Please fill in all required fields correctly.');
      return;
    }

    setIsLoading(true);
    executeRecaptcha('RegisterSignUpRequestFromForm').then((token) => {
      if (!token) {
        toast.error('Captcha failed. Please try again.');
        setIsLoading(false);
        return;
      }

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/users/signup-request',
        data: {
          user_data: {
            first_name: regForm.first_name,
            last_name: regForm.last_name,
            email: regForm.email,
            // message: regForm.message,
            recaptcha_token: token,
          },
        },
        onSuccess: (response) => {
          if (response?.data?.detail) {
            if (response.data.detail === 'ok') {
              setSubmitRegStatus(ActionStatusConstants.SUCCESS);
            } else {
              toast.warn(response.data.detail);
            }
          }
        },
        onError: (error) => {
          if (!error?.response?.data?.message) {
            toast.error('Something went wrong. Please try again.');
          }
        },
        onEnd: () => setIsLoading(false),
      });
    });
  };

  const renderField = useCallback(
    ({
      key,
      placeholder,
      type,
      afterField,
      labelAppend,
      maxLength = undefined,
      rows = undefined,
    }) => {
      if (!(key && Object.keys(regFormFields).includes(key))) {
        console.error('No form key', key);
        return null;
      }

      return (
        <Form.Group className={`input-field input-field-${key}`} controlId={`regForm.${key}`}>
          <Form.Label>
            {regFormFields[key].label}
            {labelAppend}
          </Form.Label>
          <Form.Control
            as={type !== 'textarea' ? 'input' : 'textarea'}
            type={type !== 'textarea' ? type ?? 'text' : 'text'}
            value={regForm[key]}
            placeholder={
              placeholder?.length ? placeholder : `Please enter ${regFormFields[key].label}`
            }
            onChange={(e) => setRegFormField(key, e.target.value)}
            onBlur={() => doValidateForm(key)}
            isInvalid={key in validationMsgs}
            disabled={isLoading}
            maxLength={maxLength || 100}
            rows={rows || 1}
          />
          {afterField}
          <Form.Control.Feedback type="invalid">
            {key in validationMsgs && validationMsgs[key]?.message}
          </Form.Control.Feedback>
        </Form.Group>
      );
    },
    [doValidateForm, JSON.stringify(regForm), setRegFormField, JSON.stringify(validationMsgs)]
  );

  return (
    <>
      <div className="view-login-wrapper login-register">
        <div className="view-login-card">
          {submitRegStatus === ActionStatusConstants.SUCCESS ? (
            <>
              <h1 className="view-login-heading">Success!</h1>
              <h2 className="view-login-subheading">
                Your signup request was registered successfully.
                <br />
                We will contact you shortly.
              </h2>

              <div className="actions-links">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => history.push('/login')}
                >
                  Back to Login
                </button>
              </div>
            </>
          ) : (
            <>
              <h1 className="view-login-heading">Let's Get Started!</h1>
              <h2 className="view-login-subheading">
                Complete The Information Below
                <br /> And Our Team Will Contact You Shortly
              </h2>

              <div className="reg-form">
                {renderField({ key: regFormFields.first_name.key })}
                {renderField({ key: regFormFields.last_name.key })}
                {renderField({ key: regFormFields.email.key, type: 'email' })}
                {/*{renderField({*/}
                {/*  key: regFormFields.message.key,*/}
                {/*  type: 'textarea',*/}
                {/*  maxLength: 256,*/}
                {/*  rows: 5,*/}
                {/*})}*/}
              </div>
              <div className="actions">
                <button
                  className="btn btn-primary btn-lg btn-sign-up"
                  type="button"
                  onClick={doSubmitRegForm}
                  disabled={isLoading || !executeRecaptcha}
                >
                  Sign Up
                </button>
              </div>
              <div className="actions-links">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => history.push('/login')}
                >
                  Back to Login
                </button>
              </div>
            </>
          )}
          <div className="view-login-branding">
            <a href="https://www.backbone.ai/">
              <img alt="BackboneAI logo" src={BackboneLogo} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export { SignUpRequest };
