import './MetricsCard.scss';

import React from 'react';

const MetricsCard = ({ header, total }) => {
  return (
    <article className="main-metrics-card">
      <div className="main-metrics-card-header">
        <span>{total ?? 0}</span>
        <h2>{header ?? 'N/A'}</h2>
      </div>
    </article>
  );
};

export { MetricsCard };
