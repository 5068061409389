import classNames from 'classnames';

const getClassName = (rootPathName, itemClass, isActive) => {
  let _isActive = isActive;

  if (_isActive === null || _isActive === undefined) {
    _isActive = rootPathName === itemClass;
  }

  return classNames('main-menu-item', `main-menu-item-${itemClass}`, { active: !!_isActive });
};

const HeaderUtils = {
  getClassName,
};

export { HeaderUtils };
