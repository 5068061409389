import './UserComment.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'react-moment';

import { UserDisplay } from 'components/common/UserProfileDisplay/UserDisplay';
import { FULL_DATE, TIME } from 'constants/DateTimeConstants';
import * as Utils from 'helpers/Utils';

const UserComment = ({
  as = <div />,
  authorName,
  authorImageSrc,
  commentBody,
  createdAt,
  className,
  textBold = false,
}) => {
  return (
    <as.type className={classNames('user-comment', className)}>
      <div
        className={classNames('comment-body', {
          'plain-text': typeof commentBody === 'string' || textBold,
        })}
      >
        {ReactHtmlParser(commentBody, {
          transform: Utils.transformHtmlNodes,
        })}
      </div>
      <div className="comment-meta">
        <UserDisplay name={authorName} picture={authorImageSrc} size="sm" singleInitial />
        <div className="comment-created-at">
          <Moment format={`${FULL_DATE} [at] ${TIME}`} date={createdAt} />
        </div>
      </div>
    </as.type>
  );
};

UserComment.propTypes = {
  as: PropTypes.element,
  authorName: PropTypes.string.isRequired,
  authorImageSrc: PropTypes.string,
  commentBody: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  createdAt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { UserComment };
