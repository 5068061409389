import './BaseDataFillExportTable.scss';
import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import { BaseDataFillExportTableDef } from 'helpers/table-defs/ViewBotsTableDef';

/**
 * File export table for Bot
 *
 * @param {array} exportData Table contents
 * @return render
 */
const BaseDataFillExportTable = ({ exportData }) => {
  return (
    <section className="data-fill-export">
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="id"
        data={exportData}
        columns={BaseDataFillExportTableDef.columns}
      />
    </section>
  );
};

export { BaseDataFillExportTable };
