import './AttributeAnalytics.scss';

import React, { useState, useEffect, createRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import * as ApiCalls from 'api/ApiCalls';

import { AnalyticsBar } from './AnalyticsBar';

/**
 * Admin panel for flash assessments
 * @id id for associated assesment, required
 * @returns object
 */

const AttributeAnalytics = ({ id }) => {
  // move this up to view eventually
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const attributeRefs = [];

  useEffect(() => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/attachments/${id}/assessment/attributes`,
      onSuccess: (res) => {
        setData(res.data.attribute_tooltip_data);
      },
      onError: (e) => {
        console.error(e);
      },
    };
    ApiCalls.doCall(reqCfg);
  }, [id]);

  useEffect(() => {
    if (expanded === true) {
      setRenderData(data);
    } else {
      try {
        setRenderData(data.slice(0, 10));
      } catch (e) {
        if (e instanceof TypeError) {
          setRenderData([]);
        } else {
          throw e;
        }
      }
    }
  }, [data, expanded]);

  return (
    <div className="section-attribute-analytics">
      <div className="attributes-title">
        <h4>Attribute Analytics</h4>
        <div className="attribute-legend">
          <div className="icon-valid">
            <div className="color-box" /> Valid Values
          </div>
          <div className="icon-fill">
            <div className="color-box" /> Fill Rate
          </div>
        </div>
      </div>
      <div className="attributes-display">
        {renderData.length > 0 ? (
          <>
            {renderData.map((attribute) => {
              const newRef = createRef();
              attributeRefs.push(newRef);
              return (
                <div key={`attribute-${attribute.attribute_name}`} className="attr-item">
                  <AnalyticsBar
                    label={attribute.attribute_name}
                    fillRate={attribute.fill_rate}
                    valid={attribute.valid_fill_rate}
                    tooltip={attribute.tool_tip_text}
                    ref={newRef}
                  />
                </div>
              );
            })}
          </>
        ) : null}
      </div>
      <div className="attributes-footer">
        {expanded === false ? (
          <button onClick={() => setExpanded(true)}>
            Show More Attibutes <FontAwesomeIcon icon={['fas', 'caret-down']} size="xs" />
          </button>
        ) : (
          <button onClick={() => setExpanded(false)}>
            Show Fewer Attibutes <FontAwesomeIcon icon={['fas', 'caret-down']} size="xs" />
          </button>
        )}
      </div>
    </div>
  );
};

AttributeAnalytics.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default AttributeAnalytics;
