import React from 'react';

import './PaginatorSelect.scss';
import PropTypes from 'prop-types';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';

/**
 * Page number selector we've used all over the place
 *
 * @param {number} page the current page
 * @param {function} setPage the function to set a different page state
 * @param {number} pageSize number of items per page
 * @param {function} setPageSize the function to set a different page size
 * @param {array} options array of options ofr page size as {value: 25, label: '25'}
 * @param {number} totalItems total items to paginate
 * @return render
 */

const PaginatorSelect = ({
  page,
  setPage,
  pageSize = 25,
  setPageSize,
  options = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
  totalItems,
}) => {
  const getSelectedValue = () => {
    return (
      options.find((item) => {
        return item.value === parseInt(pageSize);
      }) || []
    );
  };
  return (
    <div className="pagination-select">
      {totalItems > 0 ? (
        <span className="assessments-number">
          Showing {(1 + (page - 1) * pageSize).toLocaleString()}-
          {page * pageSize < totalItems
            ? (page * pageSize).toLocaleString()
            : totalItems.toLocaleString()}{' '}
          of {totalItems.toLocaleString()}
        </span>
      ) : null}
      <StyledMultiselect
        isSearchable={false}
        isClearable={false}
        options={options}
        values={getSelectedValue()}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => `${option.label} Items`}
        label="Showing:"
        isMulti={false}
        setOnChange={(item) => {
          setPageSize(item.value);
          setPage(1);
        }}
        selectWrapperStyle={{ width: '170px' }}
        selectStyle={{
          control: () => ({
            height: '33.5px',
            minHeight: '33.5px',
          }),
        }}
      />
    </div>
  );
};

PaginatorSelect.defaultProps = {
  pageSize: 25,
  options: [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
};

PaginatorSelect.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  options: PropTypes.array,
  totalItems: PropTypes.number.isRequired,
};

export { PaginatorSelect };
