import './ReclassifyFrom.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-bootstrap';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { RECLASSIFY_ATTRIBUTES_BOT } from 'constants/BotConstants';

const ReclassifyFrom = ({
  attributes,
  reclassifyFrom,
  setReclassifyFrom,
  fromAttribute,
  setFromAttribute,
}) => {
  const [fromOptions, setFromOptions] = useState([]);

  useEffect(() => {
    if (attributes !== null && reclassifyFrom !== null) {
      if (reclassifyFrom === 'general') {
        const finArray = attributes.general_attributes.attributes.map((item) => {
          return { label: item.name, value: item.id, number: item.product_count };
        });
        setFromOptions(finArray);
      } else if (reclassifyFrom === 'detailed') {
        const finArray = attributes.detailed_attributes.attributes.map((item) => {
          return { label: item.name, value: item.id, number: item.product_count };
        });
        setFromOptions(finArray);
      }
    }
  }, [attributes, reclassifyFrom]);

  const SelectFrom = () => {
    return (
      <StyledMultiselect
        id="inpType"
        placeholder={`Select ${
          reclassifyFrom ? RECLASSIFY_ATTRIBUTES_BOT[reclassifyFrom]?.label : ''
        } Attribute`}
        values={fromAttribute || []}
        options={fromOptions}
        optionLimit={100}
        optionsSort
        setOnChange={(e) => setFromAttribute(e)}
        isSearchable
        menuPlacement="auto"
        menuPortalTarget={document.body}
        isMulti={false}
        isDisabled={!(fromOptions && fromOptions.length) || reclassifyFrom === null}
        selectWrapperStyle={{ maxHeight: '3rem' }}
      />
    );
  };

  return (
    <section className={classNames('reclassify-from-actions')}>
      <div>
        <h4>Reclassify From</h4>
        <div className="from-radio">
          <label htmlFor="radio-from-general">
            <input
              id="radio-from-general"
              type="radio"
              name="radioFrom"
              value="general"
              checked={reclassifyFrom === 'general'}
              onChange={() => setReclassifyFrom('general')}
            />
            General
          </label>
          <label htmlFor="radio-from-detailed">
            <input
              id="radio-from-detailed"
              type="radio"
              name="radioFrom"
              value="detailed"
              checked={reclassifyFrom === 'detailed'}
              onChange={() => setReclassifyFrom('detailed')}
            />
            Detailed
          </label>
        </div>
        <Form.Control as={SelectFrom} />
      </div>
    </section>
  );
};

export { ReclassifyFrom };
