import './BotDetails.scss';

import React, { useEffect, useState } from 'react';

import queryString from 'query-string';

import * as ApiCalls from 'api/ApiCalls';
import { BotCategoryCard } from 'components/databots/BotCategoryCard/BotCategoryCard';
import { BOT_CATEGORIES } from 'constants/BotConstants';
import { mapBotsToCategories } from 'helpers/BotUtils';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';

const BotDetails = ({ heading, subheading, ran, manual = false, automated = false }) => {
  const [bots, setBots] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const { featureFlags } = RootHooks.useFeatureFlags();

  useEffect(() => {
    setIsLoading(true);
    const query = {};
    if (!featureFlags || !featureFlags?.ENABLE_MANUAL_BOTS || !featureFlags?.ENABLE_AUTOMATED_BOTS)
      return;
    if (manual && !automated) query.automated = false;
    if (automated && !manual) query.automated = true;
    const url = `/available-bots?${queryString.stringify(query)}`;

    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: url,
      onSuccess: (res) => {
        if (!isMounted.current) return;
        let manualBots,
          automatedBots = [];

        if (featureFlags.ENABLE_MANUAL_BOTS) {
          manualBots = res.data.filter((bot) => !bot.automated);
        }
        if (featureFlags.ENABLE_AUTOMATED_BOTS) {
          automatedBots = res.data.filter((bot) => bot.automated);
        }

        setBots([...manualBots, ...automatedBots]);
      },
      onEnd: () => {
        setIsLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);
  }, [isMounted, automated, manual, featureFlags]);

  useEffect(() => {
    let categories = [];

    BOT_CATEGORIES.forEach((category) => {
      const categoryObj = {
        ...category,
        bots: [],
      };
      categories.push(categoryObj);
    });

    categories = mapBotsToCategories(categories, bots);

    setCategories(categories);
  }, [bots]);

  return (
    bots.length > 0 && (
      <section className="bot-details">
        <div className="bot-details-info">
          <h2 className="bot-details-info__heading">{heading}</h2>
          <p className="bot-details-info__subheading">{subheading}</p>
        </div>
        {!isLoading &&
          categories
            .filter((category) => category.bots.length > 0)
            .map((category) => (
              <BotCategoryCard ran={ran} key={category.name} category={category} />
            ))}
      </section>
    )
  );
};

export { BotDetails };
