import React from 'react';

import { DistributorJobLinks } from 'components/distributor-job/DistributorJobLinks/DistributorJobLinks';
import { DistributorJobLinkSearch } from 'components/distributor-job/DistributorJobLinkSearch/DistributorJobLinkSearch';

const LinksSelector = ({ onChange, dataJobData, canAddLinks, canRemoveLinks }) => {
  return (
    <>
      {canAddLinks && (
        <DistributorJobLinkSearch
          id="inpDataLink"
          onEdit={onChange}
          data={dataJobData?.data_request_link}
          viewingId={`${dataJobData?.id ?? ''}`}
        />
      )}
      <DistributorJobLinks
        data={dataJobData.data_request_link}
        onUpdate={onChange}
        editable={canRemoveLinks}
        confirmDeletePrompt={false}
      />
    </>
  );
};

export { LinksSelector };
