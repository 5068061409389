export default {
  FILE: 'file',
  LINK: 'link',
  RECIPIENT: "recipient",
  DISTRIBUTOR: "distributor",
  MANUFACTURER: "manufacturer",
  SENDER: "sender",
  SENDER_TYPE: "senderType",
  DELETE_FILE: "deleteFile",
  DELIVERYFORM: "deliveryFormatId",
  DUEDATE: "dueDate",
  RESET: "reset",
};
