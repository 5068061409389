import React from 'react';

import _get from 'lodash/get';
import Moment from 'react-moment';

import { MIN_DATE } from 'constants/DateTimeConstants';
import { generatePassThruFileDownloadUrl } from 'helpers/AttachmentUtils';
import * as Utils from 'helpers/Utils';

const downloadFile = (row) => {
  const downloadUrl = generatePassThruFileDownloadUrl(row.id) || null;
  if (!downloadFile) {
    return;
  }
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';

  // switchActions();
  a.click();
};

// TODO: make it a function so it can accept global vars
export const ViewPassThruFilesTableDef = {
  columns: [
    {
      ...Utils.getDefaultTableColumnDef('pass_thru_filename', 'File Name'),
      formatter: (_cell, row) => {
        if (row.name) {
          return (
            <>
              <button
                onClick={() => downloadFile(row)}
                type="button"
                className="link pass-thru-name-link"
              >
                {row.original_name}
              </button>
            </>
          );
        }
        return 'N/A';
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('pass_thru_created_at', 'Date'),
      formatter: (_cell, row) => {
        if (row.created_at) {
          return <Moment format={MIN_DATE} date={row.created_at} />;
        }
        return 'N/A';
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('pass_thru_user', 'Uploaded By'),
      formatter: (_cell, row) => {
        // TODO: Extract reusable logic
        const uploaderUserObj = _get(row, 'user');
        const nameStr = Utils.joinNameToStr(
          _get(uploaderUserObj, 'first_name'),
          _get(uploaderUserObj, 'last_name')
        );

        let objOrgId, mfrId, clientId;
        if (uploaderUserObj?.profile?.client) {
          objOrgId = _get(row, 'manufacturer_id') || null;
          mfrId = _get(row, 'manufacturer_id') || null;
        } else if (uploaderUserObj?.profile?.manufacturer) {
          objOrgId = _get(row, 'client_id') || null;
          clientId = _get(row, 'client_id') || null;
        }

        const orgObj =
          _get(uploaderUserObj, 'profile.client') ||
          _get(uploaderUserObj, 'profile.manufacturer') ||
          'BackboneAI';

        const orgCode = _get(orgObj, `code[${objOrgId}]`) || null;

        const currentUserOrgCode =
          _get(row, `currentUserProfile.profile.client.code[${mfrId}]`) ||
          _get(row, `currentUserProfile.profile.manufacturer.code[${clientId}]`) ||
          null;
        return (
          <div className="user-info user-info-requestor">
            <div className="name">{nameStr || 'N/A'}</div>
            {orgObj ? (
              <div className="organization">
                {orgObj?.name || orgObj}
                {orgCode && orgCode !== currentUserOrgCode ? ` (${orgCode})` : null}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('pass_thru_sender', 'Sent From'),
      formatter: (_cell, row) => {
        // TODO: Extract reusable logic
        const userObj = _get(row, 'sender_user');

        let objOrgId, mfrId, clientId;
        if (userObj?.profile?.client) {
          objOrgId = _get(row, 'manufacturer_id') || null;
          mfrId = _get(row, 'manufacturer_id') || null;
        } else if (userObj?.profile?.manufacturer) {
          objOrgId = _get(row, 'client_id') || null;
          clientId = _get(row, 'client_id') || null;
        }

        const orgObj =
          _get(userObj, 'profile.client') || _get(userObj, 'profile.manufacturer') || null;

        const orgCode = _get(orgObj, `code[${objOrgId}]`) || null;

        const currentUserOrgCode =
          _get(row, `currentUserProfile.profile.client.code[${mfrId}]`) ||
          _get(row, `currentUserProfile.profile.manufacturer.code[${clientId}]`) ||
          null;
        return (
          <div className="user-info user-info-requestor">
            <div className="name">{orgObj?.name || 'N/A'}</div>
            {orgObj ? (
              <div className="organization">
                {orgCode && orgCode !== currentUserOrgCode ? ` (${orgCode})` : null}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('pass_thru_recipient', 'Sent To'),
      formatter: (_cell, row) => {
        const userObj = _get(row, 'recipient_user');

        let objOrgId, mfrId, clientId;
        if (userObj?.profile?.client) {
          objOrgId = _get(row, 'manufacturer.id') || null;
          mfrId = _get(row, 'manufacturer.id') || null;
        } else if (userObj?.profile?.manufacturer) {
          objOrgId = _get(row, 'client.id') || null;
          clientId = _get(row, 'client.id') || null;
        }

        const orgObj =
          _get(userObj, 'profile.client') || _get(userObj, 'profile.manufacturer') || null;

        const orgCode = _get(orgObj, `code[${objOrgId}]`) || null;

        const currentUserOrgCode =
          _get(row, `currentUserProfile.profile.client.code[${mfrId}]`) ||
          _get(row, `currentUserProfile.profile.manufacturer.code[${clientId}]`) ||
          null;

        return (
          <div className="user-info user-info-recipient">
            <div className="name pass-thru-name">{orgObj?.name || 'N/A'}</div>
            {orgObj ? (
              <div className="organization">
                {orgCode && orgCode !== currentUserOrgCode ? ` (${orgCode})` : null}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('file_download', 'File Download'),
      sort: false,
      formatter: (_cell, row) => {
        return (
          <>
            <button className="download-file" onClick={() => downloadFile(row)} type="button">
              Download File
            </button>
          </>
        );
      },
    },
  ],
};
