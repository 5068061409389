import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  IMAGE_QUALITY_ASSESSMENT_KEY_MAPPING,
  IMAGE_QUALITY_ASSESSMENT_DEFAULT_CONFIG,
  IMAGE_QUALITY_ASSESSMENT_CONFIG_EXCLUDE,
} from 'constants/BotConstants';

import {snakeCaseToPhrase} from 'helpers/Utils';

/**
 * Render Image Quality Assessment Configuration Data
 *
 * @param {object} configurationData configuration data object for display
 * @return render
 */
const ImageQualityAssessmentConfiguration = ({ configurationData }) => {
  const [data, setData] = useState(null);
  const [usingDefault, setUsingDefault] = useState([]);

  const mapping = IMAGE_QUALITY_ASSESSMENT_KEY_MAPPING;
  const defaultConfig = IMAGE_QUALITY_ASSESSMENT_DEFAULT_CONFIG;

  useEffect(() => {
    if (configurationData === null) {
      setData(defaultConfig);
      setUsingDefault(Object.keys(defaultConfig));
    } else {
      const currentData = configurationData;
      const defaultsArray = [];

      Object.keys(defaultConfig).forEach((value) => {
        if (!Object.keys(currentData).includes(value)) {
          currentData[value] = defaultConfig[value];
          defaultsArray.push(value);
        }
      });
      setData(currentData);
      setUsingDefault(defaultsArray);
    }
  }, [configurationData, defaultConfig]);

  return (
    <>
      {data
        ? Object.keys(data)
            .filter((key) => {
              return !IMAGE_QUALITY_ASSESSMENT_CONFIG_EXCLUDE.includes(key);
            })
            .sort((a, b) => {
              return (
                (mapping[a]?.sort === undefined) - (mapping[b]?.sort === undefined) ||
                +(mapping[a]?.sort > mapping[b]?.sort) ||
                -(mapping[a]?.sort < mapping[b]?.sort)
              );
            })
            .map((key) => {
              return (
                <div className="configuration-item" key={key}>
                  {mapping[key] ? mapping[key]?.label : `${snakeCaseToPhrase(key)}:`}{' '}
                  {mapping[key] ? mapping[key].doMap(data[key]) : String(data[key])}
                  {usingDefault.includes(key) ? '*' : null}
                </div>
              );
            })
        : null}
      {data && usingDefault.length > 0 ? (
        <div className="configuration-message">*Databot default configuration</div>
      ) : null}
    </>
  );
};

ImageQualityAssessmentConfiguration.defaultProps = {
  configurationData: null,
};

ImageQualityAssessmentConfiguration.propTypes = {
  configurationData: PropTypes.object,
};

export { ImageQualityAssessmentConfiguration };
