import ActionStatusConstants from "constants/ActionStatusConstants";
import ActionTypeConstants from "constants/ActionTypeConstants";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  status: ActionStatusConstants.INITIAL,
  payload: null,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.SUBMIT_USER_LOGIN:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.SUBMIT_USER_LOGIN_SUCCESS:
      return {
        ...state,
        status: ActionStatusConstants.SUCCESS,
        payload,
      };
    case ActionTypeConstants.SUBMIT_USER_LOGIN_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        payload,
      };
    case LOCATION_CHANGE:
    case ActionTypeConstants.SUBMIT_USER_LOGIN_RESET:
      return {
        ...initialState,
      };
    default:
  }
  return state;
};
