import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { toast } from 'helpers/ToastUtils';

const ViewFeatureFlags = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [disableAll, setDisableAll] = useState(false);
  const [flagList, setFlagList] = useState([]);

  const doGetFlags = useCallback(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/feature-flag`,
      onSuccess: (res) => {
        setFlagList(res?.data);
      },
      onError: () => {
        setFlagList([]);
      },
      onEnd: () => {
        setIsLoading(false);
        setDisableAll(false);
      },
    });
  }, []);

  useEffect(() => {
    doGetFlags();
  }, [doGetFlags]);

  const doUpdate = (flag) => {
    const data = flag;
    data.value = !flag.value;
    setDisableAll(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/feature-flag/${flag.id}`,
      data,
      onSuccess: (res) => {
        toast.success('Flag updated');
        const tempFlagList = flagList;
        tempFlagList[tempFlagList.findIndex((v) => v.id === res.data.id)] = res.data;
        setFlagList(tempFlagList);
      },
      onError: (res) => {
        console.error(res);
        toast.error('Updating flag failed');
      },
      onEnd: () => {
        setIsLoading(false);
        setDisableAll(false);
      },
    });
  };

  const buildList = () => {
    if (flagList.length === 0) {
      return <div>No feature flags have been created/</div>;
    }
    return flagList
      .sort((a, b) => a.name < b.name)
      .map((flag) => (
        <li key={flag.id} className="flag-item">
          <div className="flag-row">
            <span className="flag-name">{flag.name}</span>
            <label htmlFor={`radio-on_${flag.name}`}>
              <input
                id={`radio-on_${flag.name}`}
                type="radio"
                name={flag.name}
                value="on"
                checked={flag.value === true}
                onChange={() => doUpdate(flag)}
                disabled={disableAll}
              />
              Enabled
            </label>
            <label htmlFor={`radio-off_${flag.name}`}>
              <input
                id={`radio-off_${flag.name}`}
                type="radio"
                name={flag.name}
                value="off"
                checked={flag.value === false}
                onChange={() => doUpdate(flag)}
                disabled={disableAll}
              />
              Disabled
            </label>
          </div>
          <div className="flag-row">{flag.description}</div>
        </li>
      ));
  };

  return (
    <div className="content content-fluid feature-flags">
      <Helmet bodyAttributes={{ 'admin-page': 'feature-flags' }}>
        <title>Set Feature Flags</title>
      </Helmet>
      <div className="title">
        <h1>Set Feature Flags</h1>
        <div className="feature-flags-main">
          {!isLoading ? (
            <ul className="flag-list">{buildList()}</ul>
          ) : (
            <LoadingSpinner style={{ fontSize: '4em' }} />
          )}
        </div>
      </div>
    </div>
  );
};

export { ViewFeatureFlags };
