import './PricingValidationLog.scss';
import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { PricingValidationLogTableDef } from 'helpers/table-defs/ViewBotsTableDef';

/**
 * Log table for Pricing Validation Bot
 *
 * @param {array} details Table contents
 * @return render
 */
const PricingValidationLog = ({ details }) => {
  return (
    <section className="pricing-validation-log">
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="id"
        data={details}
        pagination={paginationFactory({
          hideSizePerPage: true,
        })}
        columns={PricingValidationLogTableDef.columns}
      />
    </section>
  );
};

export { PricingValidationLog };
