import React from 'react';

import { formatDateStamp } from 'helpers/TimeUtils';
import { downloadFile, getDefaultTableColumnDef } from 'helpers/Utils';

export const DALCLogTableTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('user', 'Created By'),
      formatter: (_cell, row) => (
        <a href={`mailto:${row.user}`} rel="noopener noreferrer" target="_blank">
          {row.user}
        </a>
      ),
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('date', 'Date Created'),
      formatter: (_cell, row) => {
        return (
          <div className="reports-table-uploaded-at">
            <span className="reports-table-uploaded-at__date">
              {row.date ? formatDateStamp(row.date) : 'N/A'}
            </span>
          </div>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: '200px', textAlign: 'center' };
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      ...getDefaultTableColumnDef('original_keys', 'Previous key'),
      formatter: (_cell, row) => {
        let value = '';
        value = row.original_keys.map((item, index) => (
          <div key={index} className="log-table-key">
            {item.current}
          </div>
        ));

        return <div className="log-table-keys">{value}</div>;
      },
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('corrected_keys', 'Corrected key'),
      formatter: (_cell, row) => {
        let value = '';
        value = row.original_keys.map((item, index) => (
          <div key={index} className="log-table-key">
            {item.corrected}
          </div>
        ));

        return <div className="log-table-keys">{value}</div>;
      },
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('actions', 'File Download'),
      formatter: (_cell, row) => (
        <button
          className="reports-table-download-file"
          onClick={() => downloadFile(row.file_download_url, row.file_name)}
          type="button"
        >
          Download File
        </button>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: '130px', textAlign: 'center' };
      },
      sort: false,
    },
  ],
};
