import "./FlashAssessmentSuggestions.scss";

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form } from "react-bootstrap";

import ActionStatusConstants from "constants/ActionStatusConstants";

const FlashAssessmentSuggestions = ({
  dataStatus,
  smartSuggestions,
  percentComplete,
  countComplete,
  suggestionsCount,
  handleCheckBox,
}) => {
  const ProgressBar = () => {
    return (
      <div className="progress-bar">
        <div className="filler" style={{ width: `${percentComplete}` }} />
      </div>
    );
  };

  return (
    <section className="section-smart-suggestions">
      <Row>
        <Col sm={3} className="suggestion-summary">
          <div className="title">Smart Suggestions</div>
          <div className="content">
            {smartSuggestions && smartSuggestions.length ? (
              <React.Fragment>
                <div className="percent">{percentComplete}</div>
                <div>
                  Completed {countComplete}/{suggestionsCount} tasks
                </div>
                <div>
                  <ProgressBar />
                </div>
              </React.Fragment>
            ) : (
              <p>No suggestions at this time.</p>
            )}
          </div>
        </Col>
        <Col sm={9}>
          <Row>
            {smartSuggestions ? (
              <React.Fragment>
                {smartSuggestions.map((item, index) => (
                  <Col sm={6} key={index}>
                    <Row>
                      <Col sm={2}>
                        <span className="fa-layers fa-fw number-box" key={index}>
                          <FontAwesomeIcon icon={["fal", "circle"]} size="3x" className="circle" />
                          <span className="fa-layers-text number">{index + 1}</span>
                        </span>
                      </Col>
                      <Col sm={10}>
                        <span className="suggestion-content" key={index}>
                          <span className="attribute-name">{`${item.attribute.name}: `}</span>
                          {item.suggestion.suggestion}
                        </span>
                        <div>
                          <Form>
                            <div key={index} className="mb-3">
                              <Form.Check
                                checked={item.suggestion.completed}
                                onChange={(event) => {
                                  handleCheckBox(event);
                                }}
                                type="checkbox"
                                id={item.suggestion.id}
                                label={
                                  item.suggestion.completed &&
                                  dataStatus !== ActionStatusConstants.ISBUSY
                                    ? "Completed"
                                    : dataStatus === ActionStatusConstants.ISBUSY
                                    ? "Updating..."
                                    : item.suggestion.completed
                                    ? "Completed"
                                    : "Mark as complete"
                                }
                              />
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </React.Fragment>
            ) : (
              <p>No suggestions at this time.</p>
            )}
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default FlashAssessmentSuggestions;
