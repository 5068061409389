export default {
  DATA_JOB_DIRECTION: "dataJobDirection",
  FILE: "file",
  DELETE_FILE_TRANSFORMATION: "deleteFileTransformation",
  FILE_TRANSFORMATION: "fileTransformation",
  LINK: "link",
  RECIPIENT: "recipient",
  DISTRIBUTOR: "distributor",
  MANUFACTURER: "manufacturer",
  REQUESTOR: "requestor",
  REQUESTOR_TYPE: "requestorType",
  JOBNAME: "jobName",
  DESCRIPTION: "desc",
  DELETE_FILE: "deleteFile",
  DELETE_LINK: "deleteLink",
  JOBTYPE: "jobType",
  STEP: "step",
  SUBTYPE: "requestSubType",
  PRIORITY: "priority",
  DELIVERYFORM: "deliveryFormatId",
  LINKEDJOB: "linkedDataJob",
  DUEDATE: "dueDate",
  RESET: "reset",
  CLIENT: "client",
  VICARIOUS_JOB: "vicariousJob",
};

export const DATA_JOB_DIRECTION_ENUM = ["request_data", "send_data", "internal_data_job"];

export const DATA_JOB_DIRECTION_CONSTANTS = {
  REQUEST_DATA_JOB: "request_data",
  SEND_DATA_JOB: "send_data",
  INTERNAL_DATA_JOB: "internal_data_job",
};
