import React from 'react';

const ImageDataCard = ({ data, title, report, doDownloadFile, doDownloadImages }) => {
  const { total, old, good, bad } = data;

  return (
    <div className="assessment-data">
      <div className="data-title">{title}</div>
      <div className="data-stats">
        <span className="value">{total?.toLocaleString()}</span>
        {old && `from ${old.toLocaleString()}`}
      </div>
      <div className="ratings">
        <div className="good">Good: {good?.toLocaleString()}</div>
        <div className="bad">Bad: {bad?.toLocaleString()}</div>
      </div>
      <div className="actions">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => doDownloadFile(report)}
          disabled={!report}
        >
          Download Detailed Report
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => doDownloadImages(report)}
        >
          Download Images
        </button>
      </div>
    </div>
  );
};

export { ImageDataCard };
