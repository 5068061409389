import './Search.scss';

import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const Search = ({
  setLocalSearch,
  search,
  setSearch,
  placeholder,
  withButton = true,
  withClear = true,
  onClear,
  onFocus,
  onBlur,
  onSearchSubmit,
  onKeyDown,
}) => {
  const [timeoutStatus, setTimeoutStatus] = useState(0);
  const inputRef = useRef();

  const doClearSearch = () => {
    if (typeof onClear === 'function') {
      onClear();
    } else {
      if (typeof setLocalSearch === 'function') setLocalSearch('');
      setSearch('');
    }
  };

  const debouncedSearch = (value) => {
    if (typeof setLocalSearch === 'function') setLocalSearch(value);

    if (timeoutStatus) clearTimeout(timeoutStatus);
    setTimeoutStatus(setTimeout(() => setSearch(value), 500));
  };

  const handleSearchSubmit = () => {
    if (typeof onSearchSubmit === 'function') {
      onSearchSubmit();
      return;
    }

    setSearch(inputRef?.current?.value ?? '');
  };

  useEffect(() => {
    inputRef.current.value = search;
  }, [search]);

  return (
    <section className="search">
      <div className="search-input-container">
        <input
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) => debouncedSearch(e.target.value)}
          defaultValue={search}
          className={classNames('search-input', { 'search-input--full': !withButton })}
          type="text"
          ref={inputRef}
          placeholder={placeholder ?? 'Search...'}
          onKeyDown={onKeyDown}
        />
        {withClear && inputRef?.current?.value?.length > 0 && (
          <button onClick={doClearSearch} className="search-clear-btn" type="button">
            <FontAwesomeIcon icon={['far', 'times']} />
          </button>
        )}
      </div>
      {withButton && (
        <button onClick={handleSearchSubmit} className="search-btn" type="button">
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'search']} />
          </span>
        </button>
      )}
    </section>
  );
};

export { Search };
