import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEqual from 'lodash/isEqual';

import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';

// Attr labels
const LABEL = {
  MIN_ORD_QTY: 'Minimum Order Quantity',
  ORD_INCR_QTY: 'Order Increment Quantity',
  CASE_UOM: 'Case Unit of Measure',
  SELL_UOM: 'Selling Unit of Measure',
};

// Generates render-friendly data
const getUiData = (additionalData) => {
  // Labeled data by attribute
  const uiData = {
    order_incr_qty: { labelSrc: LABEL.MIN_ORD_QTY, labelDst: LABEL.ORD_INCR_QTY, data: [] },
    min_order_qty: { labelSrc: LABEL.ORD_INCR_QTY, labelDst: LABEL.MIN_ORD_QTY, data: [] },
    case_uom: { labelSrc: LABEL.SELL_UOM, labelDst: LABEL.CASE_UOM, data: [] },
    sell_uom: { labelSrc: LABEL.CASE_UOM, labelDst: LABEL.SELL_UOM, data: [] },
  };

  if (additionalData) {
    // Empty Order incr qty that will be filled by Min order qty
    if (additionalData.grouped_min_order_qty?.length) {
      uiData.order_incr_qty.data = additionalData.grouped_min_order_qty.map((item) => {
        return { value: item.value, count: item.count };
      });
    }

    // Empty Min order qty that will be filled by Order incr qty
    if (additionalData.grouped_order_incr_qty?.length) {
      uiData.min_order_qty.data = additionalData.grouped_order_incr_qty.map((item) => {
        return { value: item.value, count: item.count };
      });
    }

    // Empty Sell uom that will be filled by Case uom
    if (additionalData.grouped_case_uom?.length) {
      uiData.sell_uom.data = additionalData.grouped_case_uom.map((item) => {
        return { value: item.value, count: item.count };
      });
    }

    // Empty Case uom that will be filled by Sell uom
    if (additionalData.grouped_sell_uom?.length) {
      uiData.case_uom.data = additionalData.grouped_sell_uom.map((item) => {
        return { value: item.value, count: item.count };
      });
    }

    // Min order qty and Order incr qty empty, so adding default min qty for these products
    if (additionalData.missing_min_incr_qty > 0) {
      const defaultMinQty = { value: '1', count: additionalData.missing_min_incr_qty };
      uiData.order_incr_qty.data.push(defaultMinQty);
      uiData.min_order_qty.data.push(defaultMinQty);
    }

    // Sell uom and Case uom empty, so adding default uom for these products
    if (additionalData.missing_sell_case_uom > 0) {
      const defaultUom = { value: 'EA', count: additionalData.missing_sell_case_uom };
      uiData.case_uom.data.push(defaultUom);
      uiData.sell_uom.data.push(defaultUom);
    }
  }

  return uiData;
};

/**
 * Body panel for Quantity Details Intelligent Fill bot
 */
const OrderDetailsBotBodyPanel = React.memo(
  ({ slug, onRun, onCancel, additionalData }) => {
    const uiData = getUiData(additionalData);

    const isAnyData =
      uiData &&
      (uiData.order_incr_qty.data.length ||
        uiData.min_order_qty.data.length ||
        uiData.case_uom.data.length ||
        uiData.sell_uom.data.length);

    // Renders row for summary table
    const renderSummaryRow = (labelDst, item, key) => {
      return (
        <tr key={key}>
          <td>{labelDst}</td>
          <td>{item.value}</td>
          <td>{item.count}</td>
        </tr>
      );
    };

    // Renders row block for details table
    const renderDetailsRow = (labelSrc, labelDst, item, key) => {
      return (
        <div className="row-block" key={key}>
          <div className="block-values">
            <div className="grid-col grid-col-title">{labelDst}</div>
            <div className="grid-col grid-col-value">N/A</div>
            <div className="grid-col grid-col-skus">{item.count}</div>
          </div>
          <div className="child-block">
            <div className="child-block-titles">
              <div className="grid-col grid-col-title">Found Order Attributes</div>
            </div>
            <div className="child-block-values">
              <div className="grid-col grid-col-title">{labelSrc}</div>
              <div className="grid-col grid-col-value">{item.value}</div>
            </div>
          </div>
        </div>
      );
    };

    // Generates summary/details rows using the render-friendly data
    const renderDataRows = (isSummary) => {
      const output = [];

      if (isAnyData) {
        Object.values(uiData).forEach((itemI, i) => {
          itemI.data?.forEach((itemJ, j) => {
            output.push(
              isSummary
                ? renderSummaryRow(itemI.labelDst, itemJ, `${i}_${j}`)
                : renderDetailsRow(itemI.labelSrc, itemI.labelDst, itemJ, `${i}_${j}`)
            );
          });
        });
      }

      return output;
    };

    const renderSummaryTable = () => (
      <table className="intelligent-fill-output-table">
        <thead>
          <tr>
            <th>Intelligent Fill Output</th>
            <th>Value</th>
            <th>Total SKUs</th>
          </tr>
        </thead>
        <tbody>{renderDataRows(true)}</tbody>
      </table>
    );

    return (
      <div className="order-details-bot-body-panel">
        {isAnyData ? (
          <>
            <div className="block-left">
              <div className="description">
                The Quantity Details Intelligent Fill scans your products for pairs of order detail
                attributes. Our databot fills in the missing information based on valid fill rates.
              </div>
              <div className="intelligent-fill-output-block">{renderSummaryTable()}</div>
              <DatabotConfigPanel.TemplateActions
                onRun={onRun}
                onCancel={onCancel}
                showModal
                confirmDialogClassNameSuffix={slug}
                confirmDialogTitle="Confirm Order Details Intelligent Fill"
                confirmDialogBody={
                  <>
                    <p>You are about to make the following changes:</p>
                    {renderSummaryTable()}
                  </>
                }
              />
            </div>
            <div className="block-right">
              <div className="order-attributes-block">
                <div className="top-titles">
                  <div className="grid-col grid-col-title">Missing Order Attributes</div>
                  <div className="grid-col grid-col-value">Value</div>
                  <div className="grid-col grid-col-skus">Total SKUs</div>
                </div>
                <div className="row-blocks">{renderDataRows(false)}</div>
              </div>
            </div>
          </>
        ) : (
          <div className="empty-notice">
            <div className="icon">
              <FontAwesomeIcon icon={['far', 'check-circle']} />
            </div>
            <div className="message">Your data is already up to date.</div>
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => _isEqual(prevProps, nextProps)
);

export { OrderDetailsBotBodyPanel };
