import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import queryString from 'query-string';

import UserPlaceholder from 'assets/img/profile-placeholder.jpg';
import { PRODUCT_ASSET_LABELS, PRODUCT_ASSET_TYPES } from 'constants/ProductConstants';
import {
  getHistoryMetricsDateRangeLabel,
  getPeriodDateRange,
  getPeriodDateRangeLabel,
} from 'helpers/MetricsUtils';
import { formatDateStamp } from 'helpers/TimeUtils';
import { getDefaultTableColumnDef, joinNameToStr } from 'helpers/Utils';

export const OverallManufacturersTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('manufacturer_name', 'Manufacturer'),
      formatter: (_cell, row) => {
        return (
          <div className="metrics-table-user-img">
            <img src={row.logo_url ?? UserPlaceholder} alt="" />
            <div className="metrics-table-user-img-name">{row.manufacturer_name}</div>
          </div>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('manufacturer_name_hidden', 'Manufacturer'),
      formatter: (_cell, row) => row.manufacturer_name,
      hidden: true,
    },
    {
      ...getDefaultTableColumnDef('backbone_id', 'Backbone ID'),
      formatter: (_cell, row) => row.backbone_id,
    },
    {
      ...getDefaultTableColumnDef('manufacturer_short_name', 'Short Name'),
      formatter: (_cell, row) => row.manufacturer_short_name,
    },
    {
      ...getDefaultTableColumnDef('distributors', 'Distributors'),
      formatter: (_cell, row) => row.distributors || 0,
    },
    {
      ...getDefaultTableColumnDef('users', 'Users'),
      formatter: (_cell, row) => row.users || 0,
    },
    {
      ...getDefaultTableColumnDef('data_jobs', 'Data Jobs'),
      formatter: (_cell, row) => row.data_jobs || 0,
    },
    {
      ...getDefaultTableColumnDef('data_job_attachments', 'Attachments'),
      formatter: (_cell, row) => row.data_job_attachments || 0,
    },
    {
      ...getDefaultTableColumnDef('data_job_comments', 'Comments'),
      formatter: (_cell, row) => row.data_job_comments || 0,
    },
    {
      ...getDefaultTableColumnDef('products', 'Products'),
      formatter: (_cell, row) => row.products || 0,
    },
    {
      ...getDefaultTableColumnDef('assets', 'Assets'),
      formatter: (_cell, row) => row.assets || 0,
    },
    {
      ...getDefaultTableColumnDef('sds', 'SDS'),
      formatter: (_cell, row) => row.sds || 0,
    },
    {
      ...getDefaultTableColumnDef('hazmat_data', 'HAZMAT Data'),
      formatter: (_cell, row) => row.hazmat_data || 0,
    },
    {
      ...getDefaultTableColumnDef('last_updated', 'Last Updated'),
      formatter: (_cell, row) => (row.last_updated ? formatDateStamp(row.last_updated) : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('new_products', 'New Products'),
      formatter: (_cell, row) => row.new_products || 0,
    },
  ],
};

export const OverallClientsTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('client_name', 'Distributor'),
      formatter: (_cell, row) => {
        return (
          <div className="metrics-table-user-img">
            <img src={row.logo_url ?? UserPlaceholder} alt="" />
            <div className="metrics-table-user-img-name">{row.client_name}</div>
          </div>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('client_name_hidden', 'Distributor'),
      formatter: (_cell, row) => row.client_name,
      hidden: true,
    },
    {
      ...getDefaultTableColumnDef('backbone_id', 'Backbone ID'),
      formatter: (_cell, row) => row.backbone_id,
    },
    {
      ...getDefaultTableColumnDef('manufacturers', 'Manufacturers'),
      formatter: (_cell, row) => row.manufacturers || 0,
    },
    {
      ...getDefaultTableColumnDef('users', 'Users'),
      formatter: (_cell, row) => row.users || 0,
    },
    {
      ...getDefaultTableColumnDef('data_jobs', 'Data Jobs'),
      formatter: (_cell, row) => row.data_jobs || 0,
    },
    {
      ...getDefaultTableColumnDef('data_job_attachments', 'Attachments'),
      formatter: (_cell, row) => row.data_job_attachments || 0,
    },
    {
      ...getDefaultTableColumnDef('data_job_comments', 'Comments'),
      formatter: (_cell, row) => row.data_job_comments || 0,
    },
  ],
};

export const UsersTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('user', 'User'),
      formatter: (_cell, row) => (
        <div className="metrics-table-user-img">
          <img src={row.picture ?? UserPlaceholder} alt="" />
          <div className="metrics-table-user-img-name">
            {joinNameToStr(row.first_name, row.last_name)}
          </div>
        </div>
      ),
    },
    {
      ...getDefaultTableColumnDef('id', 'Backbone ID'),
      formatter: (_cell, row) => row.id,
    },
    {
      ...getDefaultTableColumnDef('job_title', 'Job Title'),
      formatter: (_cell, row) => row.job_title ?? 'N/A',
    },
    {
      ...getDefaultTableColumnDef('email', 'Email'),
      formatter: (_cell, row) => (
        <a className="link" href={`mailto:${row.email}`}>
          {row.email}
        </a>
      ),
    },
    {
      ...getDefaultTableColumnDef('date_joined', 'Date Joined'),
      formatter: (_cell, row) => (row.date_joined ? formatDateStamp(row.date_joined) : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('last_login', 'Last Login'),
      formatter: (_cell, row) => (row.last_login ? formatDateStamp(row.last_login) : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('default_assignee', 'Default Assignee'),
      formatter: (_cell, row) => (
        <div
          className={classNames('metrics-table-icon', {
            'metrics-table-icon--success': row.default_assignee,
            'metrics-table-icon--invalid': !row.default_assignee,
          })}
        >
          <FontAwesomeIcon icon={['far', row.default_assignee ? 'check-circle' : 'times-circle']} />
        </div>
      ),
    },
  ],
};

export const IndividualManufacturersTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('name', 'Name'),
      formatter: (_cell, row) => {
        return (
          <div className="metrics-table-user-img">
            <img src={row.logo_url ?? UserPlaceholder} alt="" />
            <div className="metrics-table-user-img-name">{row.name}</div>
          </div>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('short_name', 'Short Name'),
      formatter: (_cell, row) => row.short_name,
    },
    {
      ...getDefaultTableColumnDef('backbone_id', 'Backbone ID'),
      formatter: (_cell, row) => row.backbone_id,
    },
  ],
};

export const IndividualDistributorsTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('name', 'Name'),
      formatter: (_cell, row) => {
        return (
          <div className="metrics-table-user-img">
            <img src={row.logo_url ?? UserPlaceholder} alt="" />
            <div className="metrics-table-user-img-name">{row.name}</div>
          </div>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('id', 'Backbone ID'),
      formatter: (_cell, row) => row.id,
    },
  ],
};

export const ProductsTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('total', 'Total'),
      formatter: (_cell, row) => row.total || 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('processed', 'Processed'),
      formatter: (_cell, row) => row.processed || 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('under_review', 'Under Review'),
      formatter: (_cell, row) => row.under_review || 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('sds', 'SDS'),
      formatter: (_cell, row) => row.sds || 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('last_updated', 'Last Updated'),
      formatter: (_cell, row) => (row.last_updated ? formatDateStamp(row.last_updated) : 'N/A'),
      sort: false,
    },
    {
      ...getDefaultTableColumnDef('new_products', 'New Products'),
      formatter: (_cell, row) => row.new_products || 0,
      sort: false,
    },
  ],
};

export const AssetsTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef(PRODUCT_ASSET_TYPES.CAD_2D, PRODUCT_ASSET_LABELS.CAD_2D),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.CAD_2D] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(PRODUCT_ASSET_TYPES.CAD_3D, PRODUCT_ASSET_LABELS.CAD_3D),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.CAD_3D] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(PRODUCT_ASSET_TYPES.IMAGE, PRODUCT_ASSET_LABELS.IMAGE),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.IMAGE] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(PRODUCT_ASSET_TYPES.IMAGE_360, PRODUCT_ASSET_LABELS.IMAGE_360),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.IMAGE_360] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(
        PRODUCT_ASSET_TYPES.PRIMARY_IMAGE,
        PRODUCT_ASSET_LABELS.PRIMARY_IMAGE
      ),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.PRIMARY_IMAGE] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(
        PRODUCT_ASSET_TYPES.PRODUCT_PAGE,
        PRODUCT_ASSET_LABELS.PRODUCT_PAGE
      ),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.PRODUCT_PAGE] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(PRODUCT_ASSET_TYPES.SDS, PRODUCT_ASSET_LABELS.SDS),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.SDS] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(
        PRODUCT_ASSET_TYPES.SDS_TRANSPORT,
        PRODUCT_ASSET_LABELS.SDS_TRANSPORT
      ),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.SDS_TRANSPORT] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(PRODUCT_ASSET_TYPES.VIDEO, PRODUCT_ASSET_LABELS.VIDEO),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.VIDEO] ?? 0,
      sort: false,
    },
    {
      ...getDefaultTableColumnDef(PRODUCT_ASSET_TYPES.FILE, PRODUCT_ASSET_LABELS.FILE),
      formatter: (_cell, row) => row[PRODUCT_ASSET_TYPES.FILE] ?? 0,
      sort: false,
    },
  ],
};

export const WeeklyCompanyTableDef = ({ totals = [], startDate, endDate }) => {
  const periods = [
    getPeriodDateRange(startDate, endDate, 3),
    getPeriodDateRange(startDate, endDate, 2),
    getPeriodDateRange(startDate, endDate, 1),
    getPeriodDateRange(startDate, endDate, 0),
  ];

  return {
    columns: [
      {
        ...getDefaultTableColumnDef('metric', 'Metric'),
        formatter: (_cell, row) => row.metric || 'N/A',
        footer: 'Totals',
        onSort: (_field, order) => {
          const params = queryString.parse(window.location.search);
          const query = params;
          query.sortDir = order;
          window.history.replaceState({}, '', `/weekly-metrics?${queryString.stringify(query)}`);
        },
      },
      {
        ...getDefaultTableColumnDef('metrics-table-period', getPeriodDateRangeLabel(periods, 0)),
        formatter: (_cell, row) => row.metrics[0],
        sort: false,
        dataField: 'metrics-table-period-0',
        footer: '',
        footerFormatter: (_columnData, columnIndex) => totals[columnIndex - 1] ?? 0,
      },
      {
        ...getDefaultTableColumnDef('metrics-table-period', getPeriodDateRangeLabel(periods, 1)),
        formatter: (_cell, row) => row.metrics[1],
        sort: false,
        dataField: 'metrics-table-period-1',
        footer: '',
        footerFormatter: (_columnData, columnIndex) => totals[columnIndex - 1] ?? 0,
      },
      {
        ...getDefaultTableColumnDef('metrics-table-period', getPeriodDateRangeLabel(periods, 2)),
        formatter: (_cell, row) => row.metrics[2],
        sort: false,
        dataField: 'metrics-table-period-2',
        footer: '',
        footerFormatter: (_columnData, columnIndex) => totals[columnIndex - 1] ?? 0,
      },
      {
        ...getDefaultTableColumnDef('metrics-table-period', getPeriodDateRangeLabel(periods, 3)),
        dataField: 'metrics-table-period-3',
        formatter: (_cell, row) => {
          return (
            <span
              className={classNames('metrics-table-period', {
                'metrics-table-period--positive': row.metrics[3] > row.metrics[2],
                'metrics-table-period--negative': row.metrics[3] < row.metrics[2],
              })}
            >
              {row.metrics[3]}
            </span>
          );
        },
        footer: '',
        footerFormatter: (_columnData, columnIndex) => totals[columnIndex - 1] ?? 0,
        sort: false,
      },
    ],
  };
};

export const WeeklyCustomerMetricTableDef = (metricPeriods) => {
  const periods = metricPeriods.map((period, i) => {
    return {
      ...getDefaultTableColumnDef(
        'metrics-table-period',
        getHistoryMetricsDateRangeLabel(period.start_date, period.end_date)
      ),
      dataField: `metrics-table-period-${i}`,
      sort: false,
      formatter: (_cell, row) => {
        return row[`total_${i}`] ?? 0;
      },
    };
  });

  return {
    columns: periods,
  };
};
