import React from 'react';

import classNames from 'classnames';

import './ProgressBar.scss';
import { statusValuesEnum } from 'constants/DataJobDetailsConstants';

const ProgressBar = ({ data = null, itemRenderer = null }) => {
  const renderItemIcon = (item) => {
    let output = null;

    if (item && item.icon) {
      output = item.icon;
    }

    return output;
  };

  const renderItemLabel = (item) => {
    let output = null;

    if (item && item.label) {
      output = <div>{item.label}</div>;
    }

    return output;
  };

  const renderItemContent = (item) => {
    let output = null;

    if (item) {
      if (typeof itemRenderer === 'function') {
        output = itemRenderer(item);
      } else {
        output = (
          <div className="progress-steps-item-content">
            {renderItemIcon(item)}
            {renderItemLabel(item)}
          </div>
        );
      }
    }

    return output;
  };

  return (
    <div className={classNames('progress-wrapper', { empty: !(data && data.length) })}>
      {data && data.length ? (
        <>
          {data.map(
            (item, i) =>
              // Only show the rejected bar if it is the active status
              ((item?.label === statusValuesEnum.rejected && item.isActive === true) ||
                item?.label !== statusValuesEnum.rejected) && (
                <div
                  className={`progress-step ${classNames(item.className || null, {
                    'progress-steps-item': true,
                    done: item.isDone,
                    active: item.isActive,
                  })}`}
                  key={i}
                >
                  <div className="progress-steps-item-content-wrap">{renderItemContent(item)}</div>
                </div>
              )
          )}
        </>
      ) : null}
    </div>
  );
};

export { ProgressBar };
