import React, { useCallback, useEffect, useState } from 'react';

import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { useIsMounted } from 'helpers/useIsMounted';

import './DataSources.scss';
import { SourceFilesTableDef } from './ViewSourceFilesTableDef';

const TYPES = {
  SOURCE_FILES: 'source_files',
  TRANSFORMED_FILES: 'transformed_files',
};

const URLS = {
  [TYPES.SOURCE_FILES]: '/data-requests/source-files/',
  [TYPES.TRANSFORMED_FILES]: '/data-requests/transformation-files/',
};

/**
 * // TODO: Use this table instead of the sourcedata/sourcedata / transformeddata/transformeddata
 *
 * @return render
 */
const DatabotFilesTable = ({
  type,
  mfrId,
  pageSize,
  hasPagination,
  onDataStatusChange,
  emptyNotice,
}) => {
  const isMounted = useIsMounted();

  // Holds exports status data
  const [filesData, setFilesData] = useState([]);
  const [filesDataStatus, setFilesDataStatus] = useState(useState(ActionStatusConstants.INITIAL));

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const doGetData = useCallback((_isMounted, _offset, _pageSize, _type, _mfrId) => {
    if (_isMounted.current) {
      setFilesDataStatus(ActionStatusConstants.ISBUSY);
      onDataStatusChange && onDataStatusChange(ActionStatusConstants.ISBUSY);

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: URLS[_type],
        params: {
          offset: _offset,
          limit: _pageSize,
          manufacturer_id: _mfrId || null,
        },
        onSuccess: (res) => {
          if (_isMounted.current) {
            setFilesDataStatus(ActionStatusConstants.SUCCESS);
            onDataStatusChange && onDataStatusChange(ActionStatusConstants.SUCCESS);

            const data = _get(res, 'data') || null;
            const files =
              data?.files.map((file) => {
                return {
                  ...file,
                  manufacturerId: _mfrId,
                };
              }) ?? [];

            setFilesData(files || []);
            setTotal(data?.total || 0);
          }
        },
        onError: () => {
          if (_isMounted.current) {
            setFilesDataStatus(ActionStatusConstants.FAILURE);
            onDataStatusChange && onDataStatusChange(ActionStatusConstants.FAILURE);
          }
        },
      });
    }
  }, []);

  useEffect(() => {
    doGetData(isMounted, offset, pageSize, type, mfrId);
  }, [doGetData, isMounted, offset, pageSize, type, mfrId]);

  const handlePageChange = (page, sizePerPage) => {
    if (currentPage !== page && offset !== sizePerPage) {
      setCurrentPage(page);
      setOffset(sizePerPage);
      doGetData(sizePerPage);
    }
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (currentPage === page) {
      return;
    }
    handlePageChange(page, currentIndex);
  };

  const renderEmptyNotice = () => {
    if (emptyNotice && filesDataStatus === ActionStatusConstants.SUCCESS) {
      let typeLabel = '';

      if (type === TYPES.SOURCE_FILES) {
        typeLabel = ' source';
      } else if (type === TYPES.TRANSFORMED_FILES) {
        typeLabel = ' transformed';
      }

      return (
        <div className="empty-notice">
          <Alert variant="primary">{`No${typeLabel} files found.`}</Alert>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="export-functionality-panel">
      {filesData?.length ? (
        <div className="exports-table">
          <div className="bootstrap-table-wrap">
            <BootstrapTable
              keyField="id"
              data={filesData}
              columns={SourceFilesTableDef.columns}
              remote={{ pagination: hasPagination }}
              onTableChange={handleTableChange}
              pagination={
                hasPagination
                  ? paginationFactory({
                      page: currentPage,
                      sizePerPage: pageSize,
                      totalSize: total,
                      showTotal: true,
                    })
                  : null
              }
            />
          </div>
        </div>
      ) : (
        renderEmptyNotice()
      )}
    </div>
  );
};

DatabotFilesTable.TYPES = TYPES;

DatabotFilesTable.defaultProps = {
  mfrId: null,
  pageSize: null,
  hasPagination: false,
  onDataStatusChange: null,
  emptyNotice: false,
};

DatabotFilesTable.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  mfrId: PropTypes.number,
  pageSize: PropTypes.number,
  hasPagination: PropTypes.bool,
  onDataStatusChange: PropTypes.func,
  emptyNotice: PropTypes.bool,
};

export { DatabotFilesTable };
