import axios from 'axios';
import axiosRetry from 'axios-retry';
import isRetryAllowed from 'is-retry-allowed';

import ActionTypeConstants from 'constants/ActionTypeConstants';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { toast } from 'helpers/ToastUtils';
import { store } from 'store/store';

export const BASE_API_URL = process.env.REACT_APP_BE_API_BASE;

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  PUT: 'PUT',
  HEAD: 'HEAD',
};

export const isNetworkError = (err) => {
  return (
    !err.response?.data &&
    Boolean(err.code) && // Prevents retrying cancelled requests
    err.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
    isRetryAllowed(err)
  );
};

const EXPIRED_DETAILS = ['The Token is expired', 'Invalid Token'];
axiosRetry(axios, {
  retries: 5,
  shouldResetTimeout: true,
  retryCondition: (err) => isNetworkError(err), // || axiosRetry.isIdempotentRequestError(err),
  retryDelay: axiosRetry.exponentialDelay,
});
/**
 * All-in-one API call wrapper.
 *
 * authToken: can provide a custom token different from login
 * method: One of HTTP_METHODS items
 * urlRoot: can provide a custom API root
 * urlPath: path to API endpoint
 * params: URL params
 * data: req data
 * asFormData: transform data to FormData object and send that way
 * onProgress, onCancel, onError, onEnd Axios event handlers
 * cancelTokenFunc: Provider function for cancel event
 * mockupDataId: can provide key for mockup data generation. See ApiMockupData
 * errorMesage: explicit false disables toast, null(default) shows server msg, object is showing its content
 * successMesage: optional object is showing its content on success
 */
export const doCall = (args) => {
  const {
    authToken = getAuthToken(),
    method,
    urlRoot = BASE_API_URL,
    urlPath,
    params = null,
    data = null,
    asFormData = false,
    onProgress = null,
    onSuccess = null,
    onCancel = null,
    onError = null,
    onEnd = null,
    errorMessage = null,
    successMessage = null,
    cancelTokenFunc = null,
    mockupDataId = null,
    responseType = 'json',
    headers = {},
    isFileFormData = false,
  } = args;
  // FormData transform decision
  const reqData = asFormData && !isFileFormData ? new FormData() : data;

  if (asFormData && !isFileFormData && data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // IE11 doesn't support FormData.set(). Fall back to FormData.append()
        if (window.navigator.userAgent.match(/(Trident)/)) {
          reqData.append(key, data[key]);
        } else {
          reqData.set(key, data[key]);
        }
      }
    }
  }

  // Axios config assembly
  const reqConfig = {
    method,
    url: `${urlRoot}${urlPath}`,
    responseType,
  };

  if (reqData) {
    reqConfig.data = asFormData || isFileFormData ? reqData : { ...reqData };
  }

  if (params) {
    reqConfig.params = { ...params };
  }

  reqConfig.headers = { ...(headers || {}) };

  if (authToken) {
    reqConfig.headers = { Authorization: `Token ${authToken}`, ...reqConfig.headers };
  }

  if (typeof onProgress === 'function') {
    reqConfig.onUploadProgress = onProgress;
  }

  // Generate cancel token
  // Calls reqConfig.cancelToken with callback param
  // passing a cancel function c(reason)
  if (typeof cancelTokenFunc === 'function') {
    const { CancelToken } = axios;
    reqConfig.cancelToken = new CancelToken(cancelTokenFunc);
  }

  // IE11 Cache busting
  if (reqConfig.method === HTTP_METHODS.GET) {
    if (window.navigator.userAgent.match(/(Trident)/)) {
      reqConfig.params = { ...reqConfig.params, _ts: Date.now() };
    }
  }

  // If mockupDataId param exists, execute mockup data generation
  // and skip Axios call.
  if (mockupDataId) {
    if (process.env.REACT_APP_INCLUDE_MOCKUP_DATA === 'true') {
      console.warn('Using Mockup Mode for API request', args);
      const mockupData = require('api/mockupData/ApiMockupData.js');
      mockupData.generateData(args);
      return;
    }
    console.error('Trying to request mockup data in Production build', mockupDataId);
  }

  axios(reqConfig)
    .then((res) => {
      if ((res.detail && EXPIRED_DETAILS.includes(res.data.detail)) || res.status === 401) {
        if (typeof onError === 'function') {
          onError(res);
        }
        store.dispatch({ type: ActionTypeConstants.SUBMIT_USER_LOGOUT });
        return;
      }

      // If successMessage is set, display it
      if (successMessage) {
        toast.success(successMessage);
      }

      if (typeof onSuccess === 'function') {
        onSuccess(res);
      }
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        if (typeof onCancel === 'function') {
          onCancel(err);
        }
      } else {
        // if the user session has expired, cleanup the client side and send them to login screen
        if (
          err?.response?.status === 401 ||
          (err?.response?.data && EXPIRED_DETAILS.includes(err?.response?.data?.detail))
        ) {
          if (typeof onError === 'function') {
            onError(err);
          }
          store.dispatch({ type: ActionTypeConstants.SUBMIT_USER_LOGOUT });
          return;
        }

        // If errorMessage is not explicitly false, check if it is a custom message
        // or simply null and try to display a server message
        if (errorMessage !== false) {
          if (errorMessage === null) {
            if (err?.response?.data?.message) {
              toast.error(err.response.data.message);
            }
          } else if (errorMessage) {
            toast.error(errorMessage);
          }
        }

        if (err?.response?.status === 403) {
          toast.error("You don't have permission to perform this action");
        }

        // if the err message contains error message details, show those instead of generic error
        if (err?.response?.data?.details && err?.response?.data?.message) {
          console.error(err?.response?.data?.message, err.response.data.details);
        } else if (err?.response?.data?.details) {
          console.error(err.response.data.details);
        } else {
          console.error(err);
        }
        if (typeof onError === 'function') {
          onError(err);
        }
      }
    })
    .then(() => {
      if (typeof onEnd === 'function') {
        onEnd();
      }
    });
};

// Legacy call to replace old axios wrappers
/** @deprecated */
export const legacySyncDoCall = async (reqCfg) =>
  new Promise((resolve) => {
    doCall({
      ...reqCfg,
      onSuccess: (res) => {
        if (res?.data) {
          resolve({
            data: res.data,
          });
        } else {
          resolve({
            isError: true,
          });
        }
      },
      onError: (res) => {
        resolve({
          isError: true,
          isUnauthorized: !![401, 403].includes(res?.response?.status),
          status: res?.response?.status,
        });
      },
    });
  });

export const getProgressPercent = (progressEvent) => {
  if (progressEvent) {
    const { total } = progressEvent;
    const { loaded } = progressEvent;
    if (total > 0 && loaded > 0) {
      return (loaded / total) * 100;
    }
    return 0;
  }
  return null;
};
