import React, { useState } from 'react';

import { BotConstants } from 'constants/BotConstants';

import { getIconWithPopover, isFileValid } from '../../../helpers/Utils';
import { CdsModels } from './CdsModels';
import { NewJobFileUpload } from './NewJobFileUpload';
import './NewJobSection.scss';

/**
 * Upload panel for creating a new Content job
 * @param {function} onJobCreated Callback handler for successful start.
 * @param {function} setIsNewJobInProgress setter for flag
 * // TODO: This connection should be refactored in a more elegant way
 *
 * @return render
 */
const NewCdsJobSection = ({ onJobCreated, setIsNewJobInProgress }) => {
  return (
    <div className="new-job-section">
      <>
        <div className="uploader-panel">
          <NewJobFileUpload
            botType={BotConstants.BOT_TYPES.CDS}
            onJobCreated={onJobCreated}
            isFileValid={(files) =>
              isFileValid({
                files,
                validExtensions: CdsModels.CDS_ACCEPTED_FILE_TYPES.map((item) =>
                  item.name.replace('.', '')
                ),
              })
            }
            setIsNewJobInProgress={setIsNewJobInProgress}
            infoText={
              <>
                <div className="supported-formats-wrapper">
                  <div className="formats-row-item">
                    <div className="af-title">
                      File Formats{' '}
                      {getIconWithPopover({
                        iconProp: ['far', 'question-circle'],
                        title: 'Upload Files Formats',
                        content: (
                          <>
                            The following file formats are supported for <strong>upload</strong>. If
                            you require support for a different format, please contact us.
                          </>
                        ),
                      })}
                    </div>
                    <div className="file-upload-allowed-files">
                      {CdsModels.CDS_ACCEPTED_FILE_TYPES.map((item, index) => (
                        <div key={index} className="file-item">
                          <img src={item.icon} alt={item.name} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <u>Maximum:</u> <strong>500 SKUs</strong> or <strong>2GB</strong>.
                </div>
              </>
            }
          />
        </div>
      </>
    </div>
  );
};

export { NewCdsJobSection };
