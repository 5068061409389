import React from 'react';

import Moment from 'react-moment';

import { MIN_DATE, TIME } from 'constants/DateTimeConstants';
import * as Utils from 'helpers/Utils';

export const ReclassifyAttributesLogTableDef = {
  columns: [
    {
      ...Utils.getDefaultTableColumnDef('date', 'Date'),
      formatter: (_cell, row) => {
        if (row.date) {
          return <Moment format={`${MIN_DATE} ${TIME}`} date={row.date} />;
        }
        return 'N/A';
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('user', 'User'),
    },
    {
      ...Utils.getDefaultTableColumnDef('from', 'Attribute Moved'),
    },
    {
      ...Utils.getDefaultTableColumnDef('to', 'Destination'),
    },
  ],
};
