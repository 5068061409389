import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ImgBotEngineDashboard } from 'components/img-bot-engine/ImgBotEngineDashboard';

const PAGE_SIZE = 20;

const ViewImgBotEngineDashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Helmet>
        <title>Image Databot Suite - Dashboard</title>
      </Helmet>
      <div className="content content-fluid view-img-bot-engine-dashboard">
        <div
          className="title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '1.7em',
          }}
        >
          <div>
            <h3>
              <span>Image Databot Suite</span>
              {isLoading && (
                <LoadingSpinner fast style={{ marginLeft: '0.5em', fontSize: '0.75em' }} />
              )}
            </h3>
          </div>
          <button
            type="button"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            className="btn btn-secondary btn-sm"
            onClick={() => history.push(`/image-databot-suite/configs`, { from: location })}
            disabled={isLoading}
            title="Run Assessment"
          >
            {isLoading ? <LoadingSpinner fast /> : <FontAwesomeIcon icon={['far', 'cogs']} />}{' '}
            Configuration
          </button>
        </div>
        <ImgBotEngineDashboard
          enableUploader
          onSetIsLoading={setIsLoading}
          pageSize={PAGE_SIZE}
          enableFilters
        />
      </div>
    </>
  );
};

export { ViewImgBotEngineDashboard };
