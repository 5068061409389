import './CompanyOverview.scss';

import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import BackboneLogo from 'assets/img/backbone-logo.svg';
import { downloadFile } from 'helpers/Utils';

const CompanyOverview = ({
  overview,
  showInline = true,
  showViewDataJobs = false,
  showSiteUrl = true,
  children,
  headerChildren = null,
}) => {
  // The short name property is only present on manufacturers/suppliers (as of now), so it's an easy check.
  const companyType = overview.short_name ? 'supplier' : 'distributor';

  return (
    <section className="metrics-overview">
      <div className="metrics-overview-row">
        <div className="metrics-overview-company metrics-overview-company--customer">
          <div className="metrics-overview-company-header">
            <h1>
              {overview.name}
              <br />
              Dashboard
            </h1>
            {headerChildren}
          </div>
          <div className="metrics-overview-company-card">
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => downloadFile(overview.logo)}
              onClick={() => downloadFile(overview.logo)}
              className={classNames('metrics-overview-company-card-logo', {
                'metrics-overview-company-card-logo--disabled': !overview.logo,
              })}
            >
              <img src={overview.logo ?? BackboneLogo} alt={`Logo for ${overview.name}`} />
            </div>
            <div className="metrics-overview-company-card-details">
              <div>
                Backbone ID: <span>{overview.backbone_id}</span>
              </div>
              {showSiteUrl && (
                <div className="metrics-overview-company-card-details-link">
                  {overview.url ? (
                    <a href={overview.url} alt={`Site URL for ${overview.name}`} className="link">
                      {overview.url}
                    </a>
                  ) : (
                    <span>No site URL available.</span>
                  )}
                </div>
              )}
              {showViewDataJobs && (
                <Link to={`/data-jobs?${companyType}[]=${overview.backbone_id}`}>
                  <button className="metrics-overview-company-card-btn" type="button">
                    View Data Jobs
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
        {showInline && children}
      </div>
      {!showInline && children}
    </section>
  );
};

export { CompanyOverview };
