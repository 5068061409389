import React, { useReducer, useEffect } from 'react';

import { BotConstants } from '../../../../../constants/BotConstants';
import { StyledMultiselect } from '../../../../common/StyledMultiselect/StyledMultiselect';
import { BotFormLabel } from '../../_components/BotFormLabel';
import { CdsModels } from '../../CdsModels';
import { CdsGptContentForm } from './CdsGptContentForm';

const COUNT_OPTIONS = Array.from({ length: 7 }, (x, i) => i + 3).map((item) => ({
  label: item,
  value: item,
}));

const handleFormStateUpdate = (state, action) => {
  const { field, payload, isError = false } = action;
  let newState = { ...state };
  if (field === 'config') {
    if (isError) {
      newState = {
        ...newState,
        errors: {
          ...newState.errors,
          [field]: payload,
        },
      };
    } else if (payload) {
      ['column_name', 'length', 'tone', 'goal', 'additional_languages'].forEach((key) => {
        newState.config[key] = payload[key];
      });
    }
  } else {
    newState = {
      ...newState,
      config: {
        ...newState.config,
        [field]: payload,
      },
    };
  }

  if (JSON.stringify(state) === JSON.stringify(newState)) {
    return state;
  }
  return newState;
};

const initializeFormState = ({ state, headers: columnNames }) => {
  const config = {
    ...state,
    format: BotConstants.GPT_FORMATTING_OPTIONS.find((option) => option.value === state.format),
    count: COUNT_OPTIONS.find((option) => option.value === state.count),
  };
  return {
    config,
    columnNames,
    errors: {},
  };
};

/**
 * Form Component that allows the user to define configure the Content for the CDS tool
 * @param {Object} state - The current state
 * @param {function} setState - Callback to set the state
 * @param {string[]} columnNames - The list of column names
 * @param {function} setInvalid - Callback to set if the form is invalid
 * @param {boolean} isLoading - If true, the component is disabled
 *
 * @returns {JSX.Element} Component
 */
export const CdsFeatureBenefitsContentForm = ({
  state,
  setState,
  disabled = false,
  columnNames,
  setInvalid = undefined,
  isLoading = false,
}) => {
  const [formState, onFormStateUpdated] = useReducer(
    handleFormStateUpdate,
    { state, headers: columnNames },
    initializeFormState
  );

  useEffect(() => {
    if (setInvalid) {
      const { config } = formState;
      setInvalid(
        Object.values(formState.errors).some((v) => v === true) || !config.count || !config.format
      );
    }

    setState({
      column_name: formState.config.column_name,
      length: formState.config.length,
      tone: formState.config.tone,
      goal: formState.config.goal,
      additional_languages: formState.config.additional_languages,
      format: formState.config.format?.value,
      count: formState.config.count?.value,
    });
  }, [formState, setState, setInvalid]);

  return (
    <>
      <section className="new-description">
        <CdsGptContentForm
          columnNames={columnNames}
          state={formState.config}
          disabled={isLoading || disabled}
          setInvalid={(e) =>
            onFormStateUpdated({
              field: 'config',
              payload: e,
              isError: true,
            })
          }
          setState={(v) =>
            onFormStateUpdated({
              field: 'config',
              payload: v,
            })
          }
          isLoading={isLoading}
          toneConfig={{
            options: BotConstants.GPT_FNB_GENERATOR_TONES,
            tooltip: CdsModels.CDS_TOOL_TIP.tone_fnb,
          }}
          goalConfig={{
            options: BotConstants.GPT_FNB_GENERATOR_GOALS,
            tooltip: CdsModels.CDS_TOOL_TIP.goal,
          }}
        />
      </section>

      <section className="formatting">
        <BotFormLabel
          label="Formatting"
          tooltip={CdsModels.CDS_TOOL_TIP.formattingOptions}
          type="subsection"
        />

        <div className="content-formatting">
          <section>
            <BotFormLabel label="Style" tooltip={CdsModels.CDS_TOOL_TIP.formattingStyle} required />
            <StyledMultiselect
              values={formState.config.format}
              options={BotConstants.GPT_FORMATTING_OPTIONS}
              setOnChange={(e) =>
                onFormStateUpdated({
                  field: 'format',
                  payload: e,
                })
              }
              getOptionValue={(option) => option.value}
              closeMenuOnSelect
              canReset
              isClearable
              isMulti={false}
              isDisabled={isLoading || disabled}
              isInvalid={!isLoading && !formState.config.format}
            />
          </section>

          <section>
            <BotFormLabel label="Count" tooltip={CdsModels.CDS_TOOL_TIP.count} required />
            <StyledMultiselect
              defaultValue={[3]}
              values={formState.config.count}
              options={COUNT_OPTIONS}
              setOnChange={(e) =>
                onFormStateUpdated({
                  field: 'count',
                  payload: e,
                })
              }
              getOptionValue={(option) => option.value}
              closeMenuOnSelect
              canReset
              isClearable
              isMulti={false}
              isDisabled={isLoading || disabled}
              isInvalid={!isLoading && !formState.config.count}
            />
          </section>
        </div>
      </section>
    </>
  );
};
