import React, { useEffect, useCallback, useState } from 'react';

import _isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import noNotificationImage from 'assets/img/no-notifications.svg';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { DataIngestionHeader } from 'components/data-ingestion/DataIngestionHeader/DataIngestionHeader';
import { SheetSelect } from 'components/data-ingestion/DataIngestionSheetSelect/SheetSelect';
import { TUTORIALSTEPS } from 'constants/DataIngestionConstants';

const ViewDataIngestion = (props) => {
  const [attachmentData, setAttachmentData] = useState({});
  const [jobData, setJobData] = useState({});
  const [isViewLoading, setIsViewLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isChangingStages, setIsChangingStages] = useState(false);
  const [sheetLoading, setSheetLoading] = useState(false);
  // steps to be used within tutorials to tell what to do and when
  const [step, setStep] = useState(TUTORIALSTEPS.HEADER);
  const [rowTutorialDone, setRowTutorialDone] = useState(false);
  const [columnTutorialDone, setColumnTutorialDone] = useState(false);
  const location = useLocation();
  const [userIsBacktracking, setUserIsBacktracking] = useState(false);
  const [backtrackingChanges, setBacktrackingChanges] = useState(0);
  const [rowStep, setRowStep] = useState(0);
  const [isColumnPhaseAccessible, setIsColumnPhaseAccessible] = useState(true);
  const [isAssessmentAccessible, setIsAssessmentAccessible] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const [errorArray, setErrorArray] = useState([]);

  useEffect(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/users/status',
      onSuccess: (res) => {
        if (location.pathname.includes('rows') && !res.data.row_tutorial_done) {
          setStep(TUTORIALSTEPS.HEADER);
          setRowTutorialDone(false);
        } else if (location.pathname.includes('columns') && !res.data.col_tutorial_done) {
          setColumnTutorialDone(false);
          setStep(TUTORIALSTEPS.LABELS);
        } else {
          setRowTutorialDone(true);
          setColumnTutorialDone(true);
          setStep(TUTORIALSTEPS.COLUMNS_DONE);
        }
      },
    });
  }, [location.pathname]);

  const { attachmentId } = props;

  const getAttachmentData = useCallback(() => {
    const getJobData = (jobId) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/data-requests/${jobId}`,
        onSuccess: (res) => {
          setJobData(res.data);
        },
        onError: (e) => {
          console.error(e);
        },
        onEnd: () => {
          setIsViewLoading(false);
        },
        errorMessage: false,
      });
    };

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/attachments/${attachmentId}`,
      onSuccess: (res) => {
        setAttachmentData(res.data);
        getJobData(res.data.data_request);
      },
    });

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/users/status`,
      onSuccess: (res) => {
        if (res.data.col_tutorial_done === true) {
          setColumnTutorialDone(true);
        } else if (res.data.row_tutorial_done === true) {
          setRowTutorialDone(true);
        } else {
          setRowTutorialDone(false);
          setColumnTutorialDone(false);
        }
      },
    });
  }, [attachmentId]);

  useEffect(() => {
    getAttachmentData();
  }, [getAttachmentData]);

  return (
    <DataIngestionContext.Provider
      value={{
        attachmentData,
        jobData,
        tutorial: {
          step,
          setStep,
          rowTutorialDone,
          setRowTutorialDone,
          columnTutorialDone,
          setColumnTutorialDone,
        },
        userIsBacktracking,
        setUserIsBacktracking,
        backtrackingChanges,
        setBacktrackingChanges,
        errors: { errorArray, setErrorArray },
        isViewLoading,
        setIsViewLoading,
        rowStep,
        setRowStep,
        isColumnPhaseAccessible,
        setIsColumnPhaseAccessible,
        isAssessmentAccessible,
        setIsAssessmentAccessible,
        isLoading,
        setIsLoading,
        isChangingStages,
        setIsChangingStages,
        firstLoad,
        setFirstLoad,
        sheetLoading,
        setSheetLoading,
        isNextDisabled,
        setIsNextDisabled,
      }}
    >
      <div className="content content-fluid view-data-ingestion">
        <Helmet bodyAttributes={{ 'data-page': 'data-jobs' }}>
          <title>Data Ingestion</title>
        </Helmet>
        {!isViewLoading ? (
          <>
            {_isEmpty(jobData) ? (
              <div className="content-fluid ingestion-header ">
                <h1>Data Onboarding</h1>
                <div className="not-found">
                  <img className="not-found-image" src={noNotificationImage} alt="Backbone" />
                  <h3 className="not-found-label">
                    The requested Data Onboarding Job does not exist or you don't have permission to
                    view it.
                  </h3>
                </div>
              </div>
            ) : (
              <>
                <DataIngestionHeader props={props} />
                <SheetSelect attachmentId={attachmentId} />
              </>
            )}
          </>
        ) : (
          <div className="loading-placeholder">
            <LoadingView />
          </div>
        )}
      </div>
    </DataIngestionContext.Provider>
  );
};

export { ViewDataIngestion };
