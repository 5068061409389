import React, { useCallback, useEffect, useState } from 'react';

import './DatabotApprovalPanel.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';

import * as ApiCalls from 'api/ApiCalls';
import { FeedbackModalPanel } from 'components/common/FeedbackModalPanel/FeedbackModalPanel';
import { DatabotConfigModal } from 'components/databots/DatabotConfigModal/DatabotConfigModal';
import { EVENTS, FSEvent } from 'helpers/logging';
import { RootHooks } from 'helpers/RootHooks';
import { ApprovalPanelTableDef } from 'helpers/table-defs/ViewBotsTableDef';
import { toast } from 'helpers/ToastUtils';
import { downloadExcelFile } from 'helpers/Utils';

const DatabotApprovalPanel = ({
  botStatusId,
  hasDownloadedReport,
  reportName,
  onApproveChanges,
  onRejectChanges,
  confirmDialogTitle,
  confirmDialogBody,
  confirmDialogClassNameSuffix,
  enableIncorrectDataReport,
}) => {
  const [hasDownloaded, setHasDownloaded] = useState(hasDownloadedReport);
  const [approvalColumns, setApprovalColumns] = useState(null);

  const { activeUser } = RootHooks.useActiveUser();

  const downloadReport = useCallback(() => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/status/${botStatusId}/report`,
      responseType: 'arraybuffer',
      onSuccess: (res) => {
        downloadExcelFile(res.data, res.headers);
        setHasDownloaded(true);
        FSEvent(EVENTS.DATABOT_REPORT_DOWNLOADED, {
          botStatusId,
          downloaderId: activeUser?.id,
          downloaderEmail: activeUser?.email,
        });
      },
    };
    ApiCalls.doCall(reqCfg);
  }, [botStatusId, activeUser?.email, activeUser?.id]);

  const onDownload = useCallback(() => {
    downloadReport();
  }, [downloadReport]);

  const doFeedbackSubmit = ({ feedbackData, onSuccess, setIsVisible }) => {
    onRejectChanges({ feedback: feedbackData });
    onSuccess();
    setIsVisible(false);
    toast.success('Thanks for your feedback. The BackboneAI Team will review it shortly.');
  };

  useEffect(() => {
    setApprovalColumns(ApprovalPanelTableDef({ onDownload }));
  }, [onDownload]);

  return (
    <div className="databot-approval-panel">
      <p>
        This databot requires confirmation of the changes being made to your data. Please download
        the file below and review changes.
      </p>
      <div className="databot-approval-panel-table">
        {approvalColumns && (
          <BootstrapTable
            keyField="file_name"
            bootstrap4
            bordered={false}
            columns={approvalColumns.columns}
            data={[{ file_name: reportName }]}
          />
        )}
      </div>
      {hasDownloaded && (
        <>
          <p>Thank you for downloading and reviewing this file. Tell us how to proceed.</p>
          <div className="databot-approval-panel-actions">
            <button
              onClick={onRejectChanges}
              className="databot-approval-panel-actions__action databot-approval-panel-actions__action--cancel"
              type="button"
            >
              Cancel
            </button>
            {enableIncorrectDataReport && (
              <FeedbackModalPanel
                subtitle={
                  <>
                    Thank you for letting us know there was an error in the data. Please provide the
                    details in the box below. This will cancel your databot run and you will be
                    notified when the changes have been made.
                  </>
                }
                submitBtnLabel="Submit and Cancel"
                onFeedbackSubmit={doFeedbackSubmit}
              >
                {({ setIsVisible }) => (
                  <button
                    type="button"
                    onClick={() => setIsVisible(true)}
                    className="databot-approval-panel-actions__action databot-approval-panel-actions__action--report"
                  >
                    <span className="icon">
                      <FontAwesomeIcon size="xs" icon={['fas', 'bullhorn']} />
                    </span>
                    <span>Report Incorrect Data</span>
                  </button>
                )}
              </FeedbackModalPanel>
            )}
            <DatabotConfigModal
              className={`databot-config-modal-panel-${confirmDialogClassNameSuffix}`}
              header={confirmDialogTitle}
              onSubmit={onApproveChanges}
              body={confirmDialogBody}
            >
              {({ setIsVisible }) => (
                <button
                  onClick={() => {
                    setIsVisible(true);
                  }}
                  className="databot-approval-panel-actions__action databot-approval-panel-actions__action--submit"
                  type="button"
                >
                  Confirm Changes & Run
                </button>
              )}
            </DatabotConfigModal>
          </div>
        </>
      )}
    </div>
  );
};

export { DatabotApprovalPanel };
