export const setAuthToken = (authToken) => {
  window.localStorage.setItem("auth_token", authToken);
};

export const getAuthToken = () => {
  return window.localStorage.getItem("auth_token");
};

export const removeAuthToken = () => {
  window.localStorage.removeItem("auth_token");
};
