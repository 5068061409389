import React from 'react';

import PropTypes from 'prop-types';

import { CircularProgressBar } from 'components/common/CircularProgressBar/CircularProgressBar';
import { ValueBlock } from 'components/common/ValueBlock/ValueBlock';
import * as Utils from 'helpers/Utils';

// Color class for circular progressbar per fill rate percentage
const getFillRateStatusClass = (fillRate) => {
  if (Number.isFinite(fillRate)) {
    if (fillRate >= 95) {
      return CircularProgressBar.STATUSES.GOOD;
    }

    if (fillRate >= 75) {
      return CircularProgressBar.STATUSES.ACCEPTABLE;
    }
    return CircularProgressBar.STATUSES.POOR;
  }
  return null;
};

/**
 * Summary data section
 *
 * @param {object} summaryData Summary data
 * @param {object} manufacturerData Selected mfr data
 * @return render
 */
const SectionSummaryAdmin = ({ summaryData, manufacturerData }) => {
  const fillRatePercentage = Number.isFinite(summaryData?.fill_rates?.fill_rate)
    ? summaryData.fill_rates.fill_rate * 100
    : null;
  return (
    <section className="data-lakehouse-dashboard-summary">
      <div className="fill-rate-summary">
        <section>
          <div className="title">{manufacturerData?.name ?? 'N/A'} Dashboard</div>
          <div className="summary-data">
            {Number.isFinite(summaryData?.total_products) && (
              <div className="kv-pair total-skus">
                <div className="label">Total SKUs</div>
                <div className="value">
                  {Utils.getFormattedIntOrString(summaryData.total_products)}
                </div>
              </div>
            )}
          </div>
        </section>
        <section>
          <CircularProgressBar
            percentage={fillRatePercentage}
            label="Best Practice Fill Rate"
            status={getFillRateStatusClass(fillRatePercentage)}
            isSemiCircle
            isShowPercentage
            isShowStatus
          />
        </section>
      </div>
      <div className="databots-summary">
        <ValueBlock
          title="Databots"
          subtitle="performed on your files"
          value={summaryData.total_databots}
          className="databots"
        />

        <ValueBlock
          title="General Attributes"
          subtitle="attributes per SKU"
          value={summaryData?.fill_rates?.ga_avg}
          decimalPlaces={1}
          tooltip={
            'BackboneAI captures the industry standard attributes required for storing, shipping, and receiving products, and calls them "General Attributes"'
          }
          additionalData={[
            {
              label: `attribute${
                Math.round(summaryData?.fill_rates?.ga_found) > 1 ||
                summaryData?.fill_rates?.ga_found === 0
                  ? 's'
                  : ''
              } detected`,
              value: summaryData?.fill_rates?.ga_found,
            },
            {
              label: 'attribute values',
              value: summaryData?.fill_rates?.ga_values,
            },
          ]}
          className="general-attributes"
        />

        <ValueBlock
          title="Detailed Attributes"
          subtitle="attributes per SKU"
          value={summaryData?.fill_rates?.da_avg}
          decimalPlaces={1}
          tooltip={
            'BackboneAI captures the industry standard attributes used to specifically describe the products themselves, and calls them "Detailed Attributes"'
          }
          additionalData={[
            {
              label: `attribute${
                Math.round(summaryData?.fill_rates?.da_found) > 1 ||
                summaryData?.fill_rates?.da_found === 0
                  ? 's'
                  : ''
              } detected`,
              value: summaryData?.fill_rates?.da_found,
            },
            {
              label: 'attribute values',
              value: summaryData?.fill_rates?.da_values,
            },
          ]}
          className="detailed-attributes"
        />

        <ValueBlock
          title="Primary Images"
          subtitle="primary images"
          value={summaryData.total_images}
          className="primary-images"
        />
      </div>
    </section>
  );
};

SectionSummaryAdmin.defaultProps = {
  summaryData: null,
  manufacturerData: null,
};

SectionSummaryAdmin.propTypes = {
  summaryData: PropTypes.object,
  manufacturerData: PropTypes.object,
};

export { SectionSummaryAdmin };
