import React, { useEffect, useState } from 'react';

import _get from 'lodash/get';
import { Button, Form } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

import './DistributorItemsExportPanel.scss';

/**
 * Distributor export panel main view
 *
 * @return render
 */
const DistributorItemsExportPanel = () => {
  const isMounted = useIsMounted();

  const [distributorList, setDistributorList] = useState(null);
  const [distributorListStatus, setDistributorListStatus] = useState(ActionStatusConstants.INITIAL);
  const [distributorSelection, setDistributorSelection] = useState(null);
  const [manufacturerSelection, setManufacturerSelection] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(ActionStatusConstants.INITIAL);

  useEffect(() => {
    const doGetDistributors = () => {
      if (isMounted.current) {
        setDistributorListStatus(ActionStatusConstants.ISBUSY);

        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: '/distributors/',
          onSuccess: (res) => {
            if (isMounted.current) {
              setDistributorListStatus(ActionStatusConstants.SUCCESS);
              const _data = _get(res, 'data') || null;
              setDistributorList(sortByKey(_data, 'name'));
            }
          },
          onError: () => {
            setDistributorListStatus(ActionStatusConstants.FAILURE);
          },
        });
      }
    };

    doGetDistributors();
  }, [isMounted]);

  // Handle dropdown change for distributor
  const doDistributorChange = (distributor) => {
    setDistributorSelection(distributor);
  };

  // Submit form and handle response data
  const doSubmitForm = () => {
    setSubmitStatus(ActionStatusConstants.ISBUSY);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/products/client/${distributorSelection}/manufacturer/`,
      data: {
        manufacturer_name: manufacturerSelection,
      },
      responseType: 'text/csv',
      onSuccess: (res) => {
        setSubmitStatus(ActionStatusConstants.SUCCESS);
        const data = _get(res, 'data') || null;
        if (data.message) {
          toast.success(data.message);
        } else {
          toast.success(
            'Generating csv process started. You will receive an email with the export attached.'
          );
        }
      },
      onError: () => {
        setSubmitStatus(ActionStatusConstants.FAILURE);
      },
    });
  };

  return (
    <>
      <div className="distributor-items-export-panel">
        <>
          {distributorListStatus === ActionStatusConstants.ISBUSY ? (
            <div className="top-status">
              <LoadingSpinner style={{ fontSize: '1.5em', marginRight: '0.5em' }} /> Loading data...
            </div>
          ) : null}
        </>
        <p className="distributor-titlebar-subtitle">
          Select a Distributor and provide the Supplier name. After clicking on “Create CSV”, the
          system will create a file and send it as an attachment to your email.
        </p>
        <Form inline className="distributor-items-form">
          <div className="distributor-form-group">
            <Form.Label htmlFor="inlineFormCustomSelectPref">Distributor</Form.Label>
            <Form.Control
              as="select"
              id="inlineFormCustomSelectPref"
              custom
              onChange={(e) => doDistributorChange(e.target.value)}
            >
              <option value="-1">Choose...</option>
              {distributorList?.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              }) || []}
            </Form.Control>
          </div>
          <div className="distributor-form-group">
            <Form.Label className="form-label" htmlFor="inlineFormCustomSelectPref">
              Supplier
            </Form.Label>
            <Form.Control
              as="input"
              id="inlineFormCustomSelectPref2"
              placeholder="Manufacturer Name"
              custom
              onChange={(e) => setManufacturerSelection(e.target.value)}
            />
          </div>
          <div className="distributor-form-group">
            <Button
              type="submit"
              className="my-1"
              onClick={(e) => {
                e.preventDefault();
                doSubmitForm(e);
              }}
            >
              Create CSV
            </Button>
          </div>
        </Form>
        <>
          {submitStatus === ActionStatusConstants.ISBUSY ? (
            <div className="">
              <LoadingSpinner style={{ fontSize: '1.5em', marginRight: '0.5em' }} />
              Generating CSV ...
            </div>
          ) : null}
        </>
      </div>
    </>
  );
};

export { DistributorItemsExportPanel };
