import './CADImageRenderFifBot.scss';

import React, { useEffect, useState } from 'react';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { FileSelector } from 'components/databots/bots/common/FileSelector/FileSelector';
import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { UploadBotFile } from 'components/databots/UploadBotFile/UploadBotFile';
import { cadRegex } from 'constants/FileConstants';
import { getRootMenuPortalTarget } from 'helpers/Utils';

const CADImageRenderFifBot = ({
  botStatusId,
  slug,
  status,
  handleRunBot,
  handleCancelBot,
  additionalData,
  setConfigData,
}) => {
  const menuPortalTarget = getRootMenuPortalTarget();
  const location = useLocation();
  const dataJobId = queryString.parse(location?.search).data_request_id;

  const [file, setFile] = useState(null);
  const [filesList, setFilesList] = useState(additionalData?.attachments ?? []);
  const [fileFormat, setFileFormat] = useState(null);

  const isDisabled = () => file === null;

  const options = [
    { value: '.png', label: 'PNG' },
    { value: '.jpg', label: 'JPG' },
  ];

  const onFileUploaded = (uploadedFile) => {
    if (!uploadedFile?.length) return;

    const file = uploadedFile[0];
    setFile(file);
    setFilesList([...filesList, file]);
  };

  const onRunBot = () => {
    handleRunBot(false, `/data-request/${dataJobId}`);
  };

  useEffect(() => {
    setConfigData({
      file_name: file?.name,
      convert_to: fileFormat?.value,
    });
  }, [file, fileFormat, setConfigData]);

  const renderBody = () => {
    return (
      <>
        <section className="file-selection">
          <div className="title">Step 1: Upload New File</div>
          <div className="content">
            <FileSelector selectedFile={file} filesList={filesList} setSelectedFile={setFile} />
            <UploadBotFile
              typeValidation={cadRegex}
              onUploadedFiles={(uploadedFile) => onFileUploaded(uploadedFile)}
              dataJobId={dataJobId}
            />
          </div>
        </section>
        <section className="format-selection">
          <div className="title">Step 2: Select File Format</div>
          <StyledMultiselect
            values={fileFormat}
            options={options}
            setOnChange={(v) => setFileFormat(v)}
            closeMenuOnSelect
            isClearable
            isMulti={false}
            canReset
            isDisabled={isDisabled()}
            menuPortalTarget={menuPortalTarget}
            placeholder="Select a format to convert to."
          />
        </section>
      </>
    );
  };

  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
        {
          label: 'Total Products',
          value: additionalData?.total_products ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      title="CAD Image Render"
      subtitle="Start your data automation journey by converting CAD files."
      onRun={() => onRunBot()}
      bodyContent={renderBody()}
      disableActions={{
        run: !(file && fileFormat),
      }}
      onCancel={() => handleCancelBot(`/data-request/${dataJobId}`)}
    />
  );
};

export { CADImageRenderFifBot };
