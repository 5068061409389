import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotFailed = ({ runBot }) => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="failed"
      middle={<div className="description">This process has failed. Please try again.</div>}
      bottom={
        <div className="databot-actions__recently-finished">
          <button
            onClick={runBot}
            className="databot-action databot-action--alt databot-action--failed"
            type="button"
          >
            Re-run
            <FontAwesomeIcon icon={['fas', 'redo-alt']} />
          </button>
        </div>
      }
    />
  );
};

export { DatabotFailed };
