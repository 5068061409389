import "./UserDisplay.scss";

import React from "react";

import { UserProfileCircle } from "components/common/UserProfileCircle/UserProfileCircle.js";
import PropTypes from "prop-types";

/**
 *  Component expects a name along with either a picture or singleInitial=true.
 *  size defaults to sm, only sm and md have been defined at this time
 **/

const UserDisplay = ({
  name = null,
  picture = null,
  singleInitial = false,
  size = 'sm',
  showBorder = true,
  userCompanyName = null,
}) => {
  return (
    <div className="user-display">
      <div className="user-avatar">
        <UserProfileCircle
          name={name}
          imageSrc={picture}
          size={size}
          singleInitial={singleInitial}
          showBorder={showBorder}
        />
      </div>
      {userCompanyName ? (
        <div className="user-name-and-company">
          <div className="user-name">{name}</div>
          <div className="user-company">{userCompanyName}</div>
        </div>
      ) : (
        <div className="user-name">{name}</div>
      )}
    </div>
  );
};

UserDisplay.propTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.string,
  singleInitial: PropTypes.bool,
  size: PropTypes.string,
  showBorder: PropTypes.bool,
};

export { UserDisplay };
