import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './DatabotConfigPanel.scss';
import { DatabotApprovalPanel } from 'components/databots/bots/DatabotApprovalPanel/DatabotApprovalPanel';
import { BOT_STATUSES } from 'constants/BotConstants';

import { TemplateActions } from './private/TemplateActions';
import { TemplateBody } from './private/TemplateBody';
import { TemplateBodyContent } from './private/TemplateBodyContent';
import { TemplateHeadingStats } from './private/TemplateHeadingStats';
import { TemplateSubtitle } from './private/TemplateSubtitle';
import { TemplateTitle } from './private/TemplateTitle';
import { TemplateRunningMessage } from './private/TemplateRunningMessage';

/**
 * Template wrapper for constructing databot panel. If no children are provided, the default
 * template below is rendered.
 * If children elements are provided, they are rendered instead wrapped in the template wrapper.
 * The latter option is if you want to completely customize panel UI.
 *
 * You can compose custom template variants with this and the inner template components
 * and customize them and the styles for each specific databot.
 *
 * Main template wrapper has a suffixed className with the bot slug for specific styles.
 *
 * See ShortDescriptionCreatorBot for an example of default template use.
 *
 * @param {array} headingStatsData Array of { label, value, tooltip? } objects for the top summary
 * @param {string} botStatusId Databot status (run) ID
 * @param {string} slug Databot slug
 * @param {string} status Databot run status
 * @param {bool} requiresApproval Whether or not the databot requires approval for changes
 * @param {bool} hasDownloadedReport Whether or not the active user has downloaded the changes report
 * @param {bool} reportName The report's file name
 * @param {element} title Contents of title block. Can be string or any other elements
 * @param {element} subtitle Contents of subtitle block. Can be string or any other elements
 * @param {element} bodyContent Contents of the body block
 * @param {boolean} hideBody Hides body container
 * @param {boolean} hideBodyActions Hides default actions
 * @param {any} disableActions Boolean to hide both default actions, {run?, cancel?} object of booleans to hide run/cancel actions selectively
 * @param {element} confirmDialogTitle Confirm dialog title contents when running an action
 * @param {element} confirmDialogBody Confirm dialog body contents when running an action
 * @param {function} onRun Event handler
 * @param {function} onCancel Event handler
 * @param {function} onApproveChanges Event handler for product auxiliary process
 * @param {function} onRejectChanges Event handler for product auxiliary process
 * @param {element} children For completely custom template, keeps only the template wrapper and renders whatever is passed here
 * @return render
 */
const DatabotConfigPanel = ({
  headingStatsData,
  botStatusId,
  slug,
  className,
  title,
  subtitle,
  status,
  requiresApproval,
  hasDownloadedReport,
  reportName,
  bodyContent,
  hideBody,
  hideBodyActions,
  disableActions,
  confirmCallback,
  confirmDialogTitle,
  confirmDialogBody,
  preRunValidate,
  onRun,
  onCancel,
  onApproveChanges,
  onRejectChanges,
  enableIncorrectDataReport,
  children,
}) => {
  return (
    <div
      className={classNames('databot-config-panel', className ?? null, {
        [`databot-config-panel-${slug}`]: slug?.length,
      })}
    >
      {children ?? (
        <>
          <TemplateHeadingStats data={headingStatsData} />
          <TemplateTitle>{title}</TemplateTitle>
          <TemplateSubtitle>{subtitle}</TemplateSubtitle>
          {hideBody !== true && (
            <TemplateBody>
              {bodyContent && <TemplateBodyContent>{bodyContent}</TemplateBodyContent>}
              {!hideBodyActions && (
                <TemplateActions
                  disableRun={disableActions === true || disableActions?.run === true}
                  disableCancel={disableActions === true || disableActions?.cancel === true}
                  preRunValidate={preRunValidate}
                  onRun={onRun}
                  onCancel={onCancel}
                  showModal={requiresApproval}
                  confirmCallback={confirmCallback}
                  confirmDialogClassNameSuffix={slug}
                  confirmDialogTitle={confirmDialogTitle}
                  confirmDialogBody={confirmDialogBody}
                />
              )}
            </TemplateBody>
          )}
          {status === BOT_STATUSES.AWAITING_APPROVAL && (
            <DatabotApprovalPanel
              botStatusId={botStatusId}
              hasDownloadedReport={hasDownloadedReport}
              reportName={reportName}
              confirmCallback={confirmCallback}
              confirmDialogClassNameSuffix={slug}
              confirmDialogTitle={confirmDialogTitle}
              confirmDialogBody={confirmDialogBody}
              onApproveChanges={onApproveChanges}
              onRejectChanges={onRejectChanges}
              enableIncorrectDataReport={enableIncorrectDataReport}
            />
          )}
          {[BOT_STATUSES.PENDING, BOT_STATUSES.RUNNING].includes(status) && (
            <TemplateRunningMessage onCancel={onCancel} />
          )}
        </>
      )}
    </div>
  );
};

/**
 * When customizing a template and using template components, import them through the
 * DatabotConfigPanel component and use them this way, don't import directly:
 * <DatabotConfigPanel.TemplateBody /> instead of <TemplateBody />
 */
DatabotConfigPanel.TemplateActions = TemplateActions;
DatabotConfigPanel.TemplateBody = TemplateBody;
DatabotConfigPanel.TemplateBodyContent = TemplateBodyContent;
DatabotConfigPanel.TemplateHeadingStats = TemplateHeadingStats;
DatabotConfigPanel.TemplateTitle = TemplateTitle;
DatabotConfigPanel.TemplateSubtitle = TemplateSubtitle;

DatabotConfigPanel.defaultProps = {
  headingStatsData: null,
  title: null,
  subtitle: null,
  bodyContent: null,
  hideBody: false,
  hideBodyActions: false,
  botStatusId: null,
  status: null,
  requiresApproval: false,
  hasDownloadedReport: false,
  reportName: false,
  disableActions: false,
  confirmCallback: null,
  confirmDialogTitle: null,
  confirmDialogBody: null,
  onRun: null,
  onCancel: null,
  enableIncorrectDataReport: false,
  children: null,
};

DatabotConfigPanel.propTypes = {
  headingStatsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      tooltip: PropTypes.string,
    })
  ),
  botStatusId: PropTypes.number,
  slug: PropTypes.string.isRequired,
  status: PropTypes.string,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  bodyContent: PropTypes.any,
  requiresApproval: PropTypes.bool,
  hasDownloadedReport: PropTypes.bool,
  reportName: PropTypes.string,
  hideBody: PropTypes.bool,
  hideBodyActions: PropTypes.bool,
  disableActions: PropTypes.oneOf([
    PropTypes.bool,
    PropTypes.shape({ run: PropTypes.bool, cancel: PropTypes.bool }),
  ]),
  confirmCallback: PropTypes.func,
  confirmDialogTitle: PropTypes.any,
  confirmDialogBody: PropTypes.any,
  onRun: PropTypes.func,
  onCancel: PropTypes.func,
  enableIncorrectDataReport: PropTypes.bool,
  children: PropTypes.node,
};

export { DatabotConfigPanel };
