import React from 'react';

import classNames from 'classnames';
import propTypes from 'prop-types';

const ToggleSwitch = ({ on = false, className }) => {
  const renderOn = () => (
    <svg
      className={classNames('control-dg', className)}
      width="40"
      height="20"
      viewBox="0 0 40 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 0H10C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20H30C35.5228 20 40 15.5228 40 10C40 4.47715 35.5228 0 30 0Z"
        fill="#20c094"
      />
      <path
        d="M30 2H28C23.5817 2 20 5.58172 20 10C20 14.4183 23.5817 18 28 18H30C34.4183 18 38 14.4183 38 10C38 5.58172 34.4183 2 30 2Z"
        fill="white"
      />
    </svg>
  );

  const renderOff = () => (
    <svg
      className={classNames('control-dg', className)}
      width="40"
      height="20"
      viewBox="0 0 40 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 0H10C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20H30C35.5228 20 40 15.5228 40 10C40 4.47715 35.5228 0 30 0Z"
        fill="#DFE1E4"
      />
      <path
        d="M12 2H10C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18H12C16.4183 18 20 14.4183 20 10C20 5.58172 16.4183 2 12 2Z"
        fill="white"
      />
    </svg>
  );

  const renderToggle = () => {
    return on ? renderOn() : renderOff();
  };

  return renderToggle();
};

ToggleSwitch.propTypes = {
  on: propTypes.bool,
  className: propTypes.string,
};

export { ToggleSwitch };
