import { BOT_CATEGORIES } from 'constants/BotConstants';

export const mapBotsToCategories = (categories, bots) => {
  bots.forEach((bot) => {
    const linkedCategoryIdx = categories.findIndex((category) =>
      [category.key.toLowerCase(), category.name.toLowerCase()].includes(
        bot.category?.toLowerCase()
      )
    );
    if (linkedCategoryIdx === -1) return;
    categories[linkedCategoryIdx].bots.push(bot);
  });
  return categories;
};

export const getDatabotLibrary = (bots) => {
  let categories = [];
  BOT_CATEGORIES.forEach((category) => {
    const categoryObj = {
      ...category,
      bots: [],
    };
    categories.push(categoryObj);
  });

  categories = mapBotsToCategories(categories, bots);
  return categories;
};

export const parseDatabotData = (bot) => {
  const {
    id: botId,
    name,
    description,
    reportable,
    slug,
    configurable,
    requires_approval: requiresApproval,
    has_details: hasDetails,
    description_completed: descriptionCompleted,
    description_running: descriptionRunning,
    avg_runtime: avgRuntime,
  } = bot;

  return {
    ...bot.bot_status,
    botId,
    name,
    description,
    descriptionCompleted,
    descriptionRunning,
    avgRuntime,
    configurable,
    reportable,
    requiresApproval,
    hasDetails,
    slug,
  };
};
