import React from 'react';

import { ProgressBar } from 'react-bootstrap';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotRunning = ({ description, hasExceededTimeRemaining, timeElapsed, timeRemaining }) => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="running"
      middle={<div className="description">{description}</div>}
      bottom={
        hasExceededTimeRemaining !== null && (
          <div className="running-message-wrap">
            <div className="running-message">Working on your data... </div>
            <div className="running-icon">
              <LoadingSpinner fast />
            </div>
          </div>
        )
      }
    />
  );
};

export { DatabotRunning };
