import './SheetTabColumns.scss';
import React, { useState, useEffect, useCallback, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger, Form } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import Logo from 'assets/img/BackboneAI-Short-logo.svg';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { TableScroller } from 'components/common/TableScroller/TableScroller';
import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { TutorialSheetColumns } from 'components/data-ingestion/Tutorial/TutorialSheetColumns';
import { TUTORIALSTEPS, COLUMNSTATUS, MODALTYPES } from 'constants/DataIngestionConstants';
import { toast } from 'helpers/ToastUtils';
import { excelHeaders, debounce } from 'helpers/Utils';

import { EditModal } from './EditModal/EditModal';

// TODO: Huge component, to be split to managable pieces

/**
 * Creates a table for displaying and interacting with sheet data for the second step in data ingestion
 *
 * @param {*} activeSheetData json with sheet data from /attachments/sheets
 * @param {*} goToNextSheet function to execute after the current sheet is finished
 * @returns object
 */

const SheetTabColumns = ({
  activeTab,
  activeSheetData,
  onSheetComplete,
  updateColumns,
  showModal,
  isLastSheet,
  sheets,
  doUpdateSheet,
  columnsChanged,
  scrollPosition,
  setScrollPosition,
  hideOtherColumns,
  setHideOtherColumns,
  hideNonErrorColumns,
  setHideNonErrorColumns,
}) => {
  const [scroll, setScroll] = useState(0);
  const [scrollJumpPoints, setScrollJumpPoints] = useState([]);
  const [previousScrollJumpPoints, setPreviousScrollJumpPoints] = useState([]);
  const [currentScrollJumpPoints, setCurrentScrollJumpPoints] = useState([]);
  const [doJump, setDoJump] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [validatedColumns, setValidatedColumns] = useState([]);
  const [finalTableData, setFinalTableData] = useState([]);
  const [errorColumns, setErrorColumns] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalBody] = useState();
  const [modalTitle] = useState();

  const [editVisible, setEditVisible] = useState(false);
  const [editModal, setEditModal] = useState();
  const [columnsLoading, setColumnsLoading] = useState(true);
  const [instructions, setInstructions] = useState(
    'Scroll right to confirm the suggested headers.'
  );
  const {
    errors: { setErrorArray },
    tutorial,
    isLoading,
    setIsLoading,
    setIsNextDisabled,
  } = useContext(DataIngestionContext);
  // ---------------------------------- CHECK ERRORS ---------------------------------- //
  const checkErrors = useCallback(() => {
    if (!setErrorArray) return;
    const checkColumns = [];
    for (let i = 0; i < columnData.length; i++) {
      if (columnData[i].status !== COLUMNSTATUS.DISABLED) {
        checkColumns.push(columnData[i].bb_header);
      }
    }
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/data-requests/attachments/verify/constraints`,
      data: { columns: checkColumns },
      onSuccess: (e) => {
        if (_get(e, 'data.detail') === 'ok') {
          setErrorColumns([]);
          setErrorArray([]);
        } else {
          const errorArray = [];
          const errorMessageArray = [];
          Object.keys(e.data).forEach((key) => {
            errorArray.push(key);
            errorMessageArray.push(`${key}: ${e.data[key]}`);
          });
          setErrorColumns(errorArray);
          setErrorArray(errorMessageArray);
          setErrorMessage(e.data);
          if (tutorial.columnTutorialDone === true && errorArray?.length === 0) {
            toast.error('Some columns have errors.');
          }
        }
      },
    });
    // how to stop infinite loop of it wants a dependency for something its changing a subset of?
  }, [tutorial.columnTutorialDone, columnData, setErrorArray]);

  // check errors on data load
  useEffect(() => {
    if (!isLoading && columnData.length > 0) {
      checkErrors();
    }
  }, [checkErrors, tutorial.columnTutorialDone, columnData, isLoading]);

  // ---------------------------------------------------------------------------------- //

  useEffect(() => {
    if (activeSheetData) {
      const reqCfg = {
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/attachments/sheets/${activeSheetData.id}/columns`,
        onSuccess: (res) => {
          setColumnData(
            res.data.filter((column) => {
              return [COLUMNSTATUS.PENDING, COLUMNSTATUS.VALIDATED, COLUMNSTATUS.DISABLED].includes(
                column.status
              );
            })
          );
          if (columnsChanged === 0) {
            const valid = res.data.filter((column) => {
              return [COLUMNSTATUS.VALIDATED].includes(column.status);
            });
            const validArr = [];
            valid.forEach((e) => validArr.push(e.id));
            setValidatedColumns(validArr);
          }
          setIsLoading(false);
          setColumnsLoading(false);
        },
      };
      setColumnsLoading(true);
      ApiCalls.doCall(reqCfg);
    }
  }, [activeSheetData, columnsChanged, setColumnData, setIsLoading]);

  // ---------------------------- DISABLE / ENABLE COLUMN ------------------------------- //
  // handleDisable handles either ignoring or enabling a column, depending on 'direction' arg
  const handleDisable = (id, direction) => {
    // bypass during the tutorial
    if ([TUTORIALSTEPS.DISABLE, TUTORIALSTEPS.LABELS].includes(tutorial.step)) {
      return;
    }
    showModal(MODALTYPES.COLUMN_DISABLE, direction, id, direction, columnData);
  };
  // ---------------------------------------------------------------------------------- //

  // ------------------------------------- EDITS ------------------------------------- //
  const handleDataChanged = (id) => {
    setValidatedColumns([...validatedColumns, id]);
  };

  const handleEdit = (id, value, originalValue, options) => {
    // bypass during the tutorial
    if ([TUTORIALSTEPS.DISABLE, TUTORIALSTEPS.LABELS].includes(tutorial.step)) {
      return;
    }
    setEditVisible(true);
    setEditModal(
      <EditModal
        id={id}
        value={value}
        originalValue={originalValue}
        options={options}
        handleDataChanged={() => {
          handleDataChanged(id);
        }}
        editParentVisible={(e) => setEditVisible(e)}
        sheetName={activeSheetData.name}
        errorMessage={errorColumns.includes(value) ? errorMessage : null}
        showModal={(modal, type, value, direction, columnData) =>
          showModal(modal, type, value, direction, columnData)
        }
        activeSheetData={activeSheetData}
        doUpdateSheet={(data, sheetId, detail) => doUpdateSheet(data, sheetId, detail)}
        setIsLoading={(e) => setIsLoading(e)}
      />
    );
  };
  // ---------------------------------------------------------------------------------- //

  // ----------------------------------- SCROLLING ------------------------------------ //

  const getCurrentJumpPoint = useCallback(() => {
    // start at 1 because we'll just reutrn 0 if it doesn't
    if (currentScrollJumpPoints.length === 0) {
      for (let i = 1; i < scrollJumpPoints.length; i++) {
        if (scrollJumpPoints[i].value > scroll) return scrollJumpPoints[i - 1];
      }
      return scrollJumpPoints[0];
    }
    for (let i = 1; i < currentScrollJumpPoints.length; i++) {
      if (currentScrollJumpPoints[i].value > scroll) return currentScrollJumpPoints[i - 1];
    }
    return currentScrollJumpPoints[0];
  }, [scroll, scrollJumpPoints, currentScrollJumpPoints]);

  const doGoToJumpPoint = useCallback(
    (jumpPoint) => {
      let location =
        currentScrollJumpPoints.length > 0
          ? currentScrollJumpPoints.filter((point) => point.key === jumpPoint.key)[0].value
          : jumpPoint.value;
      if (location === 0) {
        for (let i = currentScrollJumpPoints.length - 1; i >= 0; i--) {
          if (currentScrollJumpPoints[i].value > 0) {
            location = currentScrollJumpPoints[i].value;
            break;
          }
        }
      }
      document.getElementById('table-wrapper').scrollLeft = location;
    },
    [currentScrollJumpPoints]
  );

  const handleScroll = useCallback(() => {
    if ([undefined, null].includes(document.getElementById('table-wrapper'))) {
      return;
    }
    setScroll(document.getElementById('table-wrapper').scrollLeft);
    setScrollPosition(getCurrentJumpPoint());
    const canScroll =
      document.getElementById('table-wrapper').clientWidth <
      document.getElementById('table-wrapper').scrollWidth;
    const scrollRemaining =
      document.getElementById('table-wrapper').scrollWidth -
      scroll -
      document.getElementById('table-wrapper').offsetWidth;
    if (canScroll && scrollRemaining > 10) {
      document.getElementById('table-shadow-right').style.display = 'block';
    } else {
      document.getElementById('table-shadow-right').style.display = 'None';
    }

    // check which cells aren't visible and validate those which have been scrolled past.
    if (columnData.length > 0 && document.getElementById(`bb-${columnData[0].id}`) !== null) {
      for (let i = 0; i < columnData.length; i++) {
        // skip if column is already valid or is an error
        if (
          validatedColumns.includes(columnData[i].id) ||
          errorColumns.includes(columnData[i].bb_header)
        ) {
          continue;
        }
        const elem = document.getElementById(`bb-${columnData[i].id}`).offsetLeft;
        // if all the way to the right, validate all
        if (scrollRemaining < 10) {
          setValidatedColumns([...validatedColumns, columnData[i].id]);
        } else if (elem < scroll - 200) {
          setValidatedColumns([...validatedColumns, columnData[i].id]);
        } else if (elem > scroll - 200) {
          break;
        }
      }
    }
  }, [errorColumns, columnData, scroll, validatedColumns]);

  const DoubleScroll = (element) => {
    if (document.getElementById('double-scroll') === null) {
      const scrollbar = document.createElement('div');
      scrollbar.appendChild(document.createElement('div'));
      scrollbar.id = 'double-scroll';
      scrollbar.style.overflow = 'scroll';
      scrollbar.style.overflowY = 'hidden';
      scrollbar.style.width = '100%';
      scrollbar.firstChild.style.width = `${element.firstChild.scrollWidth}px`;
      scrollbar.firstChild.style.height = '0em';
      scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
      scrollbar.onscroll = function () {
        element.scrollLeft = scrollbar.scrollLeft;
      };
      element.onscroll = function () {
        scrollbar.scrollLeft = element.scrollLeft;
      };
      element.parentNode.insertBefore(scrollbar, element);
    } else {
      document.getElementById(
        'double-scroll'
      ).firstChild.style.width = `${element.firstChild.scrollWidth}px`;
    }
  };

  // set height for the table blur on right
  // TODO combine this use effect with the one above
  useEffect(() => {
    if (!isLoading) {
      document.getElementById('table-shadow-right').style.height = `${
        document.getElementById('table-wrapper').clientHeight
      }px`;
    }
  }, [isLoading, scroll]);

  useEffect(() => {
    if (!isLoading && !columnsLoading) {
      DoubleScroll(document.getElementById('table-wrapper'));
    }
  }, [isLoading, columnsLoading, validatedColumns, hideOtherColumns, hideNonErrorColumns]);

  const getScrollJumpPoints = useCallback(() => {
    const arr = [];
    for (let i = 0; i < columnData.length; i++) {
      arr.push({
        key: excelHeaders(i),
        value: document.getElementById(`bb-${columnData[i].id}`).offsetLeft,
      });
    }
    return arr;
  }, [columnData]);

  // make scrolling work when loading is done
  useEffect(() => {
    if (!isLoading && !columnsLoading) {
      handleScroll();
    }
  }, [handleScroll, isLoading, columnsLoading]);

  // reset scroll on tab change
  useEffect(() => {
    if (!columnsLoading) {
      if (columnsChanged === 0) {
        setScroll(0);
        document.getElementById('table-wrapper').scrollLeft = 0;
      } else {
        doGoToJumpPoint(scrollPosition);
      }
    }
  }, [activeTab, columnsChanged, columnsLoading]);

  // rebuild jump points when columns shown change and keep rough position
  useEffect(() => {
    if (!isLoading && !columnsLoading && checkRenderTable()) {
      setPreviousScrollJumpPoints(scrollJumpPoints);
      if (scrollJumpPoints.length === 0) {
        setScrollJumpPoints(getScrollJumpPoints);
      }
      setCurrentScrollJumpPoints(getScrollJumpPoints);
    }
  }, [
    getScrollJumpPoints,
    hideNonErrorColumns,
    hideOtherColumns,
    columnData,
    isLoading,
    columnsLoading,
    checkRenderTable,
  ]);

  // jump to point when scroll jump points updates and user has scrolled
  useEffect(() => {
    if (scroll > 0) {
      doGoToJumpPoint(scrollPosition);
    }
  }, [currentScrollJumpPoints]);

  // ------------------------------------------------------------------------------------- //

  // ------------------------------------- VALIDATION ------------------------------------ //
  // validate sheet when all constraints are met
  useEffect(() => {
    if (
      validatedColumns.length === columnData.length &&
      validatedColumns.length > 0 &&
      errorColumns.length === 0 &&
      !isLoading
    ) {
      updateColumns(null, null, true, 'Sheet Verified');
      onSheetComplete();
      if (isLastSheet) {
        setInstructions('All sheets were verified. Click "Start Assessment" below to finish.');
        setIsNextDisabled(false);
      } else {
        setInstructions('This sheet is verified. Click the next tab to continue');
        setIsNextDisabled(false);
      }
    } else {
      setInstructions('Scroll right to confirm the suggested headers.');
      setIsNextDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatedColumns, columnData, errorColumns.length]);

  // convert the activeSheetData into an array of arrays we can use to build a table
  useEffect(() => {
    if (activeSheetData && columnData) {
      const originalObject = _get(activeSheetData, 'smart_sample.smart_col');
      const tempData = [];
      for (let i = 0; i < originalObject.length; i++) {
        const temp = [];
        for (let j = 0; j < columnData.length; j++) {
          temp[j] = `${originalObject[i][j]}`;
        }
        tempData.push(temp);
      }
      setFinalTableData(tempData);
    }
  }, [activeSheetData, columnData]);

  useEffect(() => {
    if (hideNonErrorColumns && errorColumns.length === 0) {
      setHideNonErrorColumns(false);
    }
  }, [hideNonErrorColumns, errorColumns]);
  // ---------------------------------------------------------------------------------- //

  // ------------------------------------- TUTORIAL ----------------------------------- //

  // function to determine all of the classes applied to a cell to make the tutorial function
  const cellClass = (cellValue, type, index) => {
    let classValue = 'cell-wrapper';
    if (tutorial.step === TUTORIALSTEPS.LABELS) {
      classValue += ' cell-highlight';
    }
    if (tutorial.step === TUTORIALSTEPS.DISABLE && index === 1) {
      classValue += ' tutorial-disable-highlight';
    }
    if (
      errorColumns.includes(cellValue) &&
      type === 'header' &&
      columnData[index].status !== COLUMNSTATUS.DISABLED
    ) {
      classValue += ' cell-error';
    }
    if (columnData[index].status === COLUMNSTATUS.DISABLED) {
      classValue += ' cell-disabled';
    }
    return classValue;
  };

  // ---------------------------------------------------------------------------------- //

  // set height and position of the labels
  useEffect(() => {
    const labelBB = document.getElementById('label-backbone');
    const labelOriginal = document.getElementById('label-original');
    const table = document.getElementById('table-ingestion-columns');
    const originalRow = document.getElementById(`row-original`);
    const bbRow = document.getElementById(`row-backbone`);
    const doubleScroll = document.getElementById('double-scroll');

    if (bbRow && doubleScroll) {
      const originalLocation =
        originalRow.getBoundingClientRect().top -
        table.getBoundingClientRect().top +
        doubleScroll.getBoundingClientRect().height;
      labelOriginal.style.top = `${originalLocation}px`;
      labelOriginal.style.height = `${Math.max(
        originalRow.getBoundingClientRect().height,
        labelOriginal.style.height,
        labelOriginal.children[0].getBoundingClientRect().height
      )}px`;
      originalRow.style.height = labelOriginal.style.height;
      labelOriginal.style.right = `${labelBB.getBoundingClientRect().width}px`;

      const bbLocation =
        bbRow.getBoundingClientRect().top -
        table.getBoundingClientRect().top -
        `${originalRow.getBoundingClientRect().height}` +
        doubleScroll.getBoundingClientRect().height;
      labelBB.style.top = `${bbLocation}px`;
      labelBB.style.height = `${bbRow.getBoundingClientRect().height}px`;
      labelBB.style.right = `${labelBB.getBoundingClientRect().width}px`;
    }
  }, [activeSheetData, columnData, columnsLoading]);

  const wrapperScroll = debounce(() => handleScroll());

  // ------------------------------------- TOOLTIPS ----------------------------------- //

  const renderDisableTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Disable column
    </Tooltip>
  );

  const renderEnableTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Enable column
    </Tooltip>
  );

  // Logical checks  //

  const checkRenderTable = () => {
    if (columnData.length === 0 || columnData === undefined || finalTableData.length === 0) {
      return false;
    }
    if (columnData[0].sheet_id === activeSheetData.id) {
      return true;
    }
    return false;
  };

  const columnDisplay = (index) => {
    if (
      (hideOtherColumns && columnData[index].bb_header === 'OTHER') ||
      (hideNonErrorColumns && !errorColumns.includes(columnData[index].bb_header))
    ) {
      return { display: 'None' };
    }
    return null;
  };

  return (
    <>
      <div className="sheet-table-actions-columns">
        <div className="actions-header">
          <div className="actions-icon fa-layers fa-fw">
            <FontAwesomeIcon
              className="highlight-action"
              icon={['fas', 'check-circle']}
              size="lg"
            />
          </div>
          {instructions}
        </div>
        <div className="actions-button">
          <div className="show-type-input show-type-input-partners">
            <Form.Check
              type="checkbox"
              readOnly
              checked={hideOtherColumns}
              onClick={() => {
                setHideOtherColumns(!hideOtherColumns);
                setDoJump(true);
              }}
            />
            <span className="label">Hide OTHER columns</span>
          </div>
          <div className="show-type-input show-type-input-partners">
            <Form.Check
              type="checkbox"
              readOnly
              checked={hideNonErrorColumns}
              onClick={() => setHideNonErrorColumns(!hideNonErrorColumns)}
              disabled={errorColumns.length === 0 && !hideNonErrorColumns}
            />
            <span
              className={
                errorColumns.length === 0 && !hideNonErrorColumns ? 'label-disabled' : 'label'
              }
            >
              Show Only Errors
            </span>
          </div>
        </div>
        <div className="actions-scroll">
          {checkRenderTable() ? (
            <TableScroller
              wrapper="table-wrapper"
              table=""
              row="row-0"
              tutorialMode={tutorial.step === TUTORIALSTEPS.SCROLL}
              scroll={scroll}
              setScroll={(e) => setScroll(e)}
            />
          ) : null}
        </div>
      </div>
      {modalVisible === true ? (
        <div id="modal-container">
          <ModalPanel
            isVisible={modalVisible}
            setIsVisible={setModalVisible}
            showCancel
            header={modalTitle}
            body={modalBody}
            centered={false}
          />
        </div>
      ) : null}
      {editVisible === true ? <div id="modal-container">{editModal}</div> : null}

      {/*  INSTRUCTIONS LABEL HEADER  */}
      <div style={{ display: 'flex' }} className="ingestion-columns">
        {checkRenderTable() ? (
          <div className="label-wrapper">
            <div className="label-original" id="label-original">
              <div>Original</div>
            </div>
            <div className="label-backbone" id="label-backbone">
              <img className="logo-tmp" src={Logo} alt="Logo" height="20" width="20" />
            </div>
          </div>
        ) : null}
        {/* TABLE */}
        <div className="table-wrapper" id="table-wrapper" onScroll={() => wrapperScroll()}>
          {checkRenderTable() ? (
            <>
              {tutorial.step !== TUTORIALSTEPS.COLUMNS_DONE ? (
                <div id="modal-container">
                  <TutorialSheetColumns target="main-table" />
                </div>
              ) : null}
              <table id="table-ingestion-columns">
                <thead>
                  <tr className="row-letter">
                    {columnData.map((cell, index) => (
                      <th
                        key={`excelheader-${excelHeaders(index)}`}
                        style={columnDisplay(index)}
                        className={
                          tutorial.step === TUTORIALSTEPS.DISABLE && index === 1
                            ? 'tutorial-disable-highlight letter-header'
                            : cell.status === COLUMNSTATUS.DISABLED
                            ? 'cell-disabled'
                            : null
                        }
                      >
                        <div className="cell-letter">
                          <div className="header-secondary" style={{ marginLeft: 'auto' }}>
                            {excelHeaders(index)}
                          </div>
                          {cell.status !== COLUMNSTATUS.DISABLED ? (
                            <OverlayTrigger placement="top" overlay={renderDisableTooltip}>
                              <button
                                type="button"
                                alt={`Disable column${excelHeaders(index)}`}
                                className="button-inherit"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => {
                                  if (cell.status !== COLUMNSTATUS.DISABLED) {
                                    handleDisable(cell.id, 'disable_column');
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger placement="top" overlay={renderEnableTooltip}>
                              <button
                                type="button"
                                alt={`Enable column${excelHeaders(index)}`}
                                className="button-inherit disabled-trash"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => {
                                  handleDisable(cell.id, 'enable_column');
                                }}
                              >
                                <FontAwesomeIcon icon={['far', 'undo']} size="sm" />
                              </button>
                            </OverlayTrigger>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                  <tr className="header-original" id="row-original">
                    {columnData.map((cell, index) => (
                      <th
                        className={
                          tutorial.step === TUTORIALSTEPS.DISABLE && index === 1
                            ? 'cell-wrapper tutorial-disable-highlight'
                            : cell.status === COLUMNSTATUS.DISABLED
                            ? 'cell-disabled cell-wrapper'
                            : 'cell-wrapper'
                        }
                        style={columnDisplay(index)}
                        key={`original-${excelHeaders(index)}`}
                      >
                        <div className="cell-cutoff">{cell.original_header}</div>
                      </th>
                    ))}
                  </tr>
                  <tr
                    id="row-backbone"
                    className={
                      tutorial.step === TUTORIALSTEPS.LABELS
                        ? 'row-backbone tutorial-row-highlight'
                        : 'row-backbone'
                    }
                  >
                    {columnData.map((cell, index) => (
                      <th
                        className={cellClass(cell.bb_header, 'header', index)}
                        key={`backbone-${excelHeaders(index)}`}
                        style={columnDisplay(index)}
                        id={`bb-${cell.id}`}
                      >
                        <button
                          disabled={cell.status === COLUMNSTATUS.DISABLED}
                          type="button"
                          className="button-inherit cell-backbone"
                          onClick={() => {
                            if (cell.status !== COLUMNSTATUS.DISABLED) {
                              handleEdit(
                                cell.id,
                                cell.bb_header,
                                cell.original_header,
                                cell.bb_alternate_headers
                              );
                            }
                          }}
                        >
                          <div className="cell-text">{cell.bb_header}</div>
                          {cell.status !== COLUMNSTATUS.DISABLED ? (
                            <div className="cell-check">
                              {errorColumns.includes(cell.bb_header) ? (
                                <FontAwesomeIcon icon={['fas', 'ban']} size="sm" />
                              ) : validatedColumns.includes(cell.id) ? (
                                <FontAwesomeIcon icon={['fas', 'check-circle']} size="sm" />
                              ) : null}
                            </div>
                          ) : null}

                          <div className="cell-action">
                            <FontAwesomeIcon icon={['fas', 'pencil']} size="sm" />
                          </div>
                        </button>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {finalTableData.map((row, index) => (
                    <tr id={`row-${index}`} key={`row-${index}`}>
                      {row.map((cell, index) => (
                        <td
                          key={`${row}-${cell}-${excelHeaders(index)}`}
                          style={columnDisplay(index)}
                          className={
                            tutorial.step === TUTORIALSTEPS.DISABLE && index === 1
                              ? 'tutorial-disable-highlight'
                              : _get(columnData[index], 'status') === COLUMNSTATUS.DISABLED
                              ? 'cell-disabled cell-wrapper'
                              : 'cell-wrapper'
                          }
                        >
                          <div className="cell-wrapper">
                            <div className="cell-cutoff">{cell}</div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="loading-placeholder">
              <LoadingSpinner style={{ fontSize: '5em', marginLeft: '5em' }} />
            </div>
          )}
        </div>
        <div className="table-shadow-right" id="table-shadow-right" />
      </div>
    </>
  );
};

SheetTabColumns.propTypes = {
  activeSheetData: PropTypes.object.isRequired,
};

export { SheetTabColumns };
