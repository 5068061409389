import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';

import { BotConstants } from 'constants/BotConstants';

import { getIconWithPopover, isFileValid } from '../../../helpers/Utils';
import { IdsModels } from './IdsModels';
import { ImgBotEngineModalTrigger } from './ImgBotEngineModalTrigger';
import { NewJobFileUpload } from './NewJobFileUpload';
import './NewJobSection.scss';

/**
 * Upload panel for creating a new IDS job
 * @param {function} onRunAssessment Handler for starting an initial assessment
 * @param {function} onJobCreated Callback handler for successful start.
 * @param {function} setIsNewJobInProgress setter for flag
 * // TODO: This connection should be refactored in a more elegant way
 *
 * @return render
 */
const NewIdsJobSection = ({ onRunAssessment, onJobCreated, setIsNewJobInProgress }) => {
  // Newly created IDS job - process_job
  const [imgJob, setImgJob] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (imgJob) {
      setIsVisible(true);
    }
  }, [imgJob, setIsVisible]);

  const onLocalJobCreated = (jobData) => {
    setImgJob({ ...jobData });
    onJobCreated();
  };

  const handleOnHide = () => {
    setIsVisible(false);
    setImgJob(null);
  };

  return (
    <div className="new-job-section">
      <>
        <div className="uploader-panel">
          <NewJobFileUpload
            botType={BotConstants.BOT_TYPES.IDS}
            onJobCreated={onLocalJobCreated}
            isFileValid={(files) =>
              isFileValid({
                files,
                validExtensions: IdsModels.IDS_ACCEPTED_FILE_TYPES.map((item) =>
                  item.name.replace('.', '')
                ),
              })
            }
            setIsNewJobInProgress={setIsNewJobInProgress}
            infoText={
              <>
                <div className="supported-formats-wrapper">
                  <div className="formats-row-item">
                    <div className="af-title">
                      File Formats{' '}
                      {getIconWithPopover({
                        iconProp: ['far', 'question-circle'],
                        title: 'Upload Files Formats',
                        content: (
                          <>
                            The following file formats are supported for <strong>upload</strong>. If
                            you require support for a different format, please contact us.
                          </>
                        ),
                      })}
                    </div>
                    <div className="file-upload-allowed-files">
                      {IdsModels.IDS_ACCEPTED_FILE_TYPES.map((item, index) => (
                        <div key={index} className="file-item">
                          <img src={item.icon} alt={item.name} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <span className="separator" />
                  <div className="formats-row-item">
                    <div className="af-title">
                      Image formats{' '}
                      {getIconWithPopover({
                        iconProp: ['far', 'question-circle'],
                        title: 'Processing Image formats',
                        content: (
                          <>
                            The following image formats are supported for{' '}
                            <strong>processing</strong>. If you require support for a different
                            format, please contact us.
                          </>
                        ),
                      })}
                    </div>
                    <div className="file-upload-allowed-files">
                      {IdsModels.IDS_SUPPORTED_IMAGE_TYPES.map((item, index) => (
                        <div key={index} className="file-item">
                          <img src={item.icon} alt={item.name} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <u>Maximum</u>: <strong>10k images</strong> or <strong>2GB</strong>.
                </div>
              </>
            }
          />
        </div>
      </>
      {imgJob && (
        <Modal
          backdrop="static"
          show={isVisible}
          onHide={() => handleOnHide()}
          className="modal-panel img-bot-modal-panel"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="ids-job-action-btns__header">Begin Initial Assessment</div>
              <div className="ids-job-action-btns__subheader">
                Run# <strong>{imgJob.id}</strong>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-panel-body-text">
            <ImgBotEngineModalTrigger
              imgJob={{
                process_job: imgJob,
                onRunAssessment,
              }}
              setIsVisible={setIsVisible}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export { NewIdsJobSection };
