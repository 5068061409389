import './Breadcrumbs.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

// TODO: Add support for non-linear taxonomy when this is possible in DB
const Breadcrumbs = ({ taxonomy, showDefault = true }) => {
  const [taxonomyItems, setTaxonomyItems] = useState([]);

  useEffect(() => {
    const taxonomyItems = taxonomy.value;
    if (typeof taxonomyItems === 'undefined' || taxonomyItems === null) return;

    typeof taxonomyItems === 'string'
      ? setTaxonomyItems([taxonomyItems])
      : setTaxonomyItems(taxonomyItems);
  }, [taxonomy.value]);

  const renderBreadcrumbIcon = (i) => {
    const icon = <span className="oi oi-chevron-right" title="chevron right" aria-hidden="true" />;

    if (!showDefault) {
      if (i > 0) return icon;
    }

    return icon;
  };

  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs-list">
        {showDefault && (
          <li className="breadcrumbs-list-item breadcrumbs-list-item--active">
            <a className="link" href="/products">
              All Products
            </a>
          </li>
        )}
        {/* TODO: replace span with href for item when links are supported */}
        {taxonomyItems.map((item, i) =>
          item?.length ? (
            <li
              key={item}
              className={classNames('breadcrumbs-list-item', {
                'breadcrumbs-list-item--active': i < taxonomyItems.length - 1,
              })}
            >
              {renderBreadcrumbIcon(i)}
              <span>{item}</span>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};

Breadcrumbs.propTypes = {
  taxonomy: PropTypes.object.isRequired,
  showDefault: PropTypes.bool,
};

export { Breadcrumbs };
