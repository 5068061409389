import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { getTransformedData } from 'components/header-notifications/HeaderNotificationUtils';
import ImgWithPlaceholder from 'components/ImgWithPlaceholder';
import { useIsMounted } from 'helpers/useIsMounted';

const ViewNotifications = () => {
  const [hasMore, setHasMore] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const history = useHistory();

  const doLoadMore = useCallback(
    (cursor, existingNotifications = []) => {
      setIsLoading(true);
      const query = {};
      query.cursor = cursor;

      const url = `/notifications/views?${queryString.stringify(query)}`;

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: url,
        onSuccess: (res) => {
          const { notifications, cursor, has_more: hasMore } = res.data;
          let transformedNotifications = null;
          if (notifications && Array.isArray(notifications) && notifications.length) {
            transformedNotifications = notifications
              .map((item) => getTransformedData(item))
              .filter((item) => !!item);
          }

          if (isMounted.current) {
            setNotifications([...existingNotifications, ...transformedNotifications]);
            setHasMore(hasMore);
            setCursor(cursor);
          }
        },
        onEnd: () => {
          setIsLoading(false);
        },
      });
    },
    [isMounted]
  );

  const markAllAsViewed = () => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PATCH,
      data: { all: true },
      urlPath: `/notifications/views`,
    });

    const notifs = cloneDeep(notifications);
    notifs.forEach((notification) => (notification.viewed = true));
    setNotifications(notifs);
  };

  useEffect(() => {
    doLoadMore(0, []);
  }, [doLoadMore]);

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <div className="content content-fluid view-notifications">
        <div className="notifications-wrapper">
          <div className="notifications-header">
            <h1>Notifications</h1>
            <button
              hidden={notifications.length === 0}
              type="button"
              onClick={() => markAllAsViewed()}
              className="notifications-header-mark-read"
            >
              <FontAwesomeIcon size="sm" icon={['fas', 'check']} />
              Mark all as read
            </button>
          </div>
          {isLoading ? (
            <LoadingView />
          ) : (
            <>
              <ul className="notifications-list">
                {notifications.map((notification) => (
                  <div
                    key={`div${notification.url}${notification.id}`}
                    className="notifications-list-item-wrapper"
                  >
                    <div className="notif-image-wrapper">
                      <ImgWithPlaceholder
                        className="notif-image"
                        src={notification.image}
                        placeholder={
                          <FontAwesomeIcon
                            className="notif-image placeholder"
                            icon={['far', 'times']}
                          />
                        }
                      />
                    </div>
                    <li
                      key={`${notification.url}${notification.id}`}
                      onClick={() => history.push(notification.url)}
                      className="notifications-list-item"
                    >
                      {!notification.viewed && (
                        <span className="notifications-list-item-viewed-indicator" />
                      )}
                      <div className="notifications-list-item__title">
                        {notification.shortMessage}
                      </div>
                      <div className="notifications-list-item__details">{notification.message}</div>
                      <span className="notifications-list-item__date">
                        {notification.created_at}
                      </span>
                    </li>
                  </div>
                ))}
              </ul>
              {notifications.length === 0 && <p>You have no notifications.</p>}
              <button
                className="notifications-load-more"
                hidden={!hasMore}
                onClick={() => doLoadMore(cursor, notifications)}
                type="button"
              >
                Load More
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { ViewNotifications };
