import React, { useCallback, useEffect, useState } from 'react';
import '../DataSources.scss';

import _get from 'lodash/get';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { SourceFilesTableDef } from 'components/data-sources/ViewSourceFilesTableDef';
import { useIsMounted } from 'helpers/useIsMounted';

// TODO: Unify SourceData and TransformedData into 1 component
const SourceData = ({ pageSize, hasPagination, manufacturer }) => {
  const isMounted = useIsMounted();
  // Holds exports status data
  const [transformedData, setTransformedData] = useState([]);
  const [showTableSpinner, setShowTableSpinner] = useState(false);
  const [showInitTableSpinner, setInitShowTableSpinner] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [sortField, setSortField] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField: _sortField, sortOrder: _sortOrder }
  ) => {
    if (['sort'].includes(type)) {
      setSortField(_sortField);
      setSortOrder(_sortOrder);
    }
    const currentIndex = (page - 1) * sizePerPage;
    if (currentPageSize !== sizePerPage) {
      setCurrentPageSize(sizePerPage);
      setShowTableSpinner(true);
    }
    if (currentPage === page) {
      return;
    }
    handlePageChange(page, currentIndex);
  };

  const handlePageChange = (page, sizePerPage) => {
    if (currentPage !== page && offset !== sizePerPage) {
      setCurrentPage(page);
      setOffset(sizePerPage);
      setShowTableSpinner(true);

      doGetSourceData(sizePerPage, currentPageSize);
    }
  };
  const doGetSourceData = useCallback(
    (cacloffset, limit) => {
      if (isMounted.current) {
        setInitShowTableSpinner(true);
        let url = `/data-requests/source-files/?offset=${cacloffset}&limit=${limit}&sort_dir=${sortOrder}&sort_field=${sortField}`;
        if (manufacturer) {
          url = `/data-requests/source-files/?offset=${cacloffset}&limit=${limit}&sort_dir=${sortOrder}&sort_field=${sortField}&manufacturer_id=${manufacturer}`;
        }
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: url,
          onSuccess: (res) => {
            const data = _get(res, 'data') || [];
            setTransformedData(data.files);
            setTotal(res.data.total);
            setShowTableSpinner(false);
            setInitShowTableSpinner(false);
          },
          onError: () => {},
        });
      }
    },
    [isMounted, manufacturer, sortField, sortOrder]
  );

  useEffect(() => {
    doGetSourceData(offset, currentPageSize);
  }, [doGetSourceData, currentPageSize]);

  return (
    <div className="export-functionality-panel">
      <div className="exports-table">
        <div className="bootstrap-table-wrap">
          <BootstrapTable
            keyField="id"
            data={transformedData}
            columns={SourceFilesTableDef.columns}
            remote={{ pagination: hasPagination }}
            onTableChange={handleTableChange}
            noDataIndication={() =>
              total === 0 && !showInitTableSpinner ? (
                <p> No source data available</p>
              ) : (
                <LoadingSpinner style={{ fontSize: '1.5em' }} />
              )
            }
            loading={showTableSpinner}
            overlay={overlayFactory({
              spinner: true,
              styles: { overlay: (base) => ({ ...base, background: 'rgba(221,219,219,0.5)' }) },
            })}
            pagination={
              hasPagination
                ? paginationFactory({
                    sizePerPageList: [
                      {
                        text: '10 items',
                        value: 10,
                      },
                      {
                        text: '25 items',
                        value: 25,
                      },
                      {
                        text: '50 items',
                        value: 50,
                      },
                      {
                        text: 'All',
                        value: total,
                      },
                    ],
                    page: currentPage,
                    sizePerPage: currentPageSize,
                    totalSize: total,
                    showTotal: true,
                  })
                : null
            }
          />
        </div>
      </div>
    </div>
  );
};

export { SourceData };
