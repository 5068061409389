import './ModalPanelDetach.scss';

import React, { useCallback } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ModalPanel = ({
  onOpen,
  onClose,
  header,
  body,
  footer,
  className,
  dialogClassName,
  bsPrefix,
  centered = true,
  isVisible,
  setIsVisible,
  backdrop,
  keyboard,
}) => {
  const _setIsVisible = useCallback(
    (state) => {
      if (state) {
        typeof onOpen === 'function' && onOpen();
      } else {
        typeof onClose === 'function' && onClose();
      }
      setIsVisible(state);
    },
    [onClose, onOpen, setIsVisible]
  );

  const renderPart = (part) => {
    if (part) {
      if (typeof part === 'function') {
        return part({ setIsVisible: _setIsVisible });
      }
      return part;
    }

    return null;
  };

  return (
    <Modal
      show={isVisible}
      onHide={() => _setIsVisible(false)}
      onFocus={() => {
        typeof onOpen === 'function' && onOpen();
      }}
      backdrop={backdrop}
      keyboard={keyboard}
      className={classNames('modal-panel-detach', className)}
      dialogClassName={classNames(dialogClassName)}
      bsPrefix={classNames(bsPrefix)}
      centered={centered}
    >
      <Modal.Header closeButton>
        {header && <Modal.Title>{renderPart(header)}</Modal.Title>}
      </Modal.Header>
      <Modal.Body className="modal-panel-detach-body-text">{body && renderPart(body)}</Modal.Body>
      {footer && <Modal.Footer>{renderPart(footer)}</Modal.Footer>}
    </Modal>
  );
};

ModalPanel.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  header: PropTypes.any,
  centered: PropTypes.bool,
  body: PropTypes.any,
  footer: PropTypes.any,
  className: PropTypes.string,
};

export { ModalPanel };
