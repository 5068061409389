import React, { useCallback, useEffect, useState } from 'react';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import { isRoleAdmin, UserUtils } from 'helpers/UserUtils';
import * as Utils from 'helpers/Utils';

import { PlatformConstants } from '../../../constants/PlatformConstants';
import { FileResult } from './FileResult';
import { FileResultItem } from './FileResultItem';

import './ImgBotEngineDownloadModal.scss';

/**
 * Modal panel body for listing the available files uploaded and generated by the IDS job.
 *
 * @param {object} imgJob Image datajob, containing process_job
 *
 * @return render
 */
const ImgBotEngineDownloadModal = ({ imgJob }) => {
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();
  const isAdmin = isRoleAdmin(activeUser);

  const [filesData, setFilesData] = useState(null);
  const [isLoadingFilesData, setIsLoadingFilesData] = useState(false);

  // Get files data with fresh SAS tokens
  const doLoadFilesData = useCallback(() => {
    if (!(isMounted.current && imgJob?.process_job?.id)) {
      return;
    }

    setIsLoadingFilesData(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/process-job/get-result-urls/${imgJob.process_job.id}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setFilesData(res?.data ?? null);
        }
      },
      onEnd: () => {
        if (isMounted.current) {
          setIsLoadingFilesData(false);
        }
      },
    });
  }, [isMounted, imgJob?.process_job?.id]);

  useEffect(() => {
    doLoadFilesData(imgJob?.process_job?.id);
  }, [doLoadFilesData, imgJob?.process_job?.id]);

  const formatFileName = (fullText) => {
    const maxLength = 18;
    if (fullText.length > maxLength) {
      const [name, extension] = Utils.splitExt(fullText);
      const truncated = name.substring(0, maxLength - extension.length - 1);
      return `${truncated}...${extension}`;
    }
    return fullText;
  };

  const reports = (fileName) => {
    if (fileName.toLocaleLowerCase().includes('initial_assessment')) {
      return 'Initial Assessment';
    }

    if (fileName.toLocaleLowerCase().includes('final_assessment')) {
      return 'Final Assessment';
    }

    return formatFileName(fileName);
  };

  const debugFiles = (fileName) => {
    if (fileName.includes('debug_masks')) {
      return 'Debug Masks';
    }
    if (fileName.includes('debug_info')) {
      return 'Debug Info';
    }

    return formatFileName(fileName);
  };

  const imageArchives = (index, totalFiles) => {
    if (totalFiles > 1) {
      return `Output Images Part ${index + 1}`;
    }
    return 'Output Images';
  };

  const FILE_OPTIONS = {
    output_image_archives: {
      title: 'Enhanced Images',
      callback: imageArchives,
      hasUserFeatureAccess: true,
    },
    corrected_uploaded_files: {
      title: 'Corrected Files',
      callback: formatFileName,
      hasUserFeatureAccess: UserUtils.hasUserFeatureAccess(
        activeUser,
        PlatformConstants.PLATFORM_FEATURES.IDS_QUALITY_CHECKS
      ),
    },
    reports: {
      title: 'Reports',
      callback: reports,
      hasUserFeatureAccess: true,
    },
    debug_files: {
      title: 'Debug Files',
      callback: debugFiles,
      hasUserFeatureAccess: true,
    },
  };

  return (
    <>
      <div className="container-files">
        {isLoadingFilesData ? (
          <div className="loading">
            Loading...
            <LoadingSpinner fast style={{ marginLeft: '0.5em' }} />
          </div>
        ) : (
          <div>
            <div className="file-container-result">
              <div className="file-result-type">Initial Files</div>
              <div className="file-result-group">
                {filesData?.raw_uploaded_files && (
                  <FileResultItem
                    tip={filesData?.raw_uploaded_files[0]?.name}
                    fileName={formatFileName(filesData?.raw_uploaded_files[0]?.name)}
                    createdAt={filesData?.raw_uploaded_files[0]?.created_at}
                    link={isAdmin ? filesData?.raw_uploaded_files[0]?.url : null}
                  />
                )}
              </div>
            </div>

            {filesData &&
              Object.keys(filesData).map(
                (item, index) =>
                  filesData[item]?.length > 0 &&
                  FILE_OPTIONS[item]?.hasUserFeatureAccess && (
                    <FileResult
                      key={index}
                      downloadType={item}
                      files={filesData[item]}
                      totalFiles={filesData[item]?.length}
                      title={FILE_OPTIONS[item]?.title}
                      callback={FILE_OPTIONS[item]?.callback}
                    />
                  )
              )}
          </div>
        )}
      </div>
    </>
  );
};

export { ImgBotEngineDownloadModal };
