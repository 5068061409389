import React, { useCallback, useEffect, useState } from 'react';

import queryString from 'query-string';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router';

import * as ApiCalls from 'api/ApiCalls';
import { SmallSelectFileSection } from 'components/databots/bots/common/SmallSelectFileSection/SmallSelectFileSection';
import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import './DescriptionBuilderBot.scss';
import { HeaderSelector } from './private/HeaderSelector';

/**
 * Description Builder Bot
 *
 * @see {@link DescriptionBuilderBot}
 */
const DescriptionBuilderBot = ({
  botStatusId,
  slug,
  status,
  handleRunBot,
  handleCancelBot,
  additionalData,
  setConfigData,
}) => {
  // TODO: In a future iteration, maybe standardize all bot context objects as props
  // (context, config, additionalData, setters, runners, etc.)
  const location = useLocation();
  const dataJobId = queryString.parse(location?.search).data_request_id;

  const isMounted = useIsMounted();

  // Holds the values users are able to select from
  const [filesList, setFilesList] = useState(null);
  const [headersList, setHeadersList] = useState(null);

  // Information holding user selections
  const [selectedFile, setSelectedFile] = useState(null);
  const [newDescription, setNewDescription] = useState('');
  const [selectedHeaders, setSelectedHeaders] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [validSelections, setValidSelections] = useState(false);

  // Reset file lists on props change
  useEffect(() => {
    setFilesList(additionalData?.attachments ?? null);
  }, [additionalData]);

  // Load column headers per file.
  // - If file obj has an ID(original file), retrieve headers from BE
  // - If file obj has a header list(uploaded secondary), use that
  const doLoadHeaders = useCallback(
    (file) => {
      setSelectedHeaders([]);

      if (file?.id) {
        setIsLoading(true);

        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/data-requests/attachment/${file.id}/headers`,
          onSuccess: (res) => {
            if (isMounted.current && res?.data?.headers?.length) {
              setHeadersList(res.data.headers.map((item) => item));
            } else {
              setHeadersList(null);
            }
          },
          onEnd: () => setIsLoading(false),
        });
      } else if (file?.headers?.length) {
        setHeadersList(file?.headers);
      } else {
        setHeadersList(null);
      }
    },
    [isMounted]
  );

  // Load primary column headers for selected file
  useEffect(() => {
    doLoadHeaders(selectedFile);
  }, [selectedFile, doLoadHeaders]);

  // Update bot config on form changes
  useEffect(() => {
    const botConfigObj = {
      file_data: null,
    };

    if (selectedFile?.location && newDescription && selectedHeaders.length > 0) {
      // TODO update once we have BE needed definitions and keys finishes
      botConfigObj.file_data = {
        container_name: selectedFile?.location,
        file_name: selectedFile?.name,
        new_column_name: newDescription,
        columns_to_concat: selectedHeaders.map((header) => header.name),
      };
      // allow bot to run once we have a good config data
      setValidSelections(true);
    } else {
      setValidSelections(false);
    }

    setConfigData(botConfigObj);
  }, [selectedFile?.location, selectedFile?.name, newDescription, selectedHeaders, setConfigData]);

  // Set New Description
  const handleNewDescription = ({ target }) => {
    setNewDescription(target.value);
  };

  // Run handler assembles config object and triggers bot run
  const onRunBot = () => {
    handleRunBot(false, `/data-request/${dataJobId}`);
  };

  const renderBotBody = () => (
    <>
      <SmallSelectFileSection
        title="Step 1: Select Your File"
        dataJobId={dataJobId}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        filesList={filesList}
        setFilesList={setFilesList}
        isLoading={isLoading}
      />
      <section className="secondary-file-selection">
        <div className="title">Step 2: Name Your New Description</div>
        <div className="content">
          <Form.Control
            as="input"
            value={newDescription}
            placeholder="Name your new description (ex: SEO Description 1)"
            onChange={handleNewDescription}
            maxLength="100"
          />
        </div>
      </section>
      <section className="header-selection">
        <div className="title">Step 3: Add Columns and Arrange the Order</div>
        <div className="note">*Only the first sheet of the file will be updated.</div>
        <div className="content">
          <HeaderSelector
            selectedHeaders={selectedHeaders}
            setSelectedHeaders={setSelectedHeaders}
            headerChoices={headersList}
            disabled={isLoading || !selectedFile}
          />
        </div>
      </section>
    </>
  );

  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
        {
          label: 'Total Products',
          value: additionalData?.total_products ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      title="SEO Description Builder"
      subtitle="Start your data automation journey by creating custom SEO descriptions for your products. Select the attributes you would like to use, and arrange them in order to create a new report."
      bodyContent={renderBotBody()}
      requiresApproval
      preRunValidate={() => {
        if (!(selectedFile?.location && selectedHeaders.length > 0)) {
          toast.error('Please select a file and select which columns will be used');
          return false;
        }
        return true;
      }}
      disableActions={{ run: !validSelections }}
      onRun={onRunBot}
      onCancel={() => handleCancelBot(`/data-request/${dataJobId}`)}
      confirmDialogTitle="Run Description Builder Databot?"
      confirmDialogBody={
        <>
          <p>You are about to:</p>
          Run the Description Builder Databot
        </>
      }
    />
  );
};

export { DescriptionBuilderBot };
