import React, { useEffect, useState } from 'react';

import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import { CreateDetailedAttributesBotModal } from './CreateDetailedAttributesBotModal';
import * as DetailedAttributesBotAPICalls from './DetailedAttributesBotAPICalls';
import { DetailedAttributesTable } from './DetailedAttributesTable';
import * as DetailedAttributesUtils from './DetailedAttributesUtils';

import './DetailedAttributesTable.scss';

import { useHistory } from 'react-router-dom';

import { DALCLogTableTableDef } from './LogTableTableDef';

import { ValueBlock } from 'components/common/ValueBlock/ValueBlock';

import { Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const CHANGE_TYPE = {
  keys: { value: 'keys', label: 'Keys' },
  labels: { value: 'labels', label: 'Labels' },
};
const DetailedAttributesBot = ({
  handleRunBot,
  setConfigData,
  configData,
  details,
  manufacturerId,
  slug,
}) => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const [prodCountData, setProdCountData] = useState(null);
  const [attributeCount, setAttributesCount] = useState(null);
  const [manufacturerName, setManufacturerName] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [attributesData, setAttributesData] = useState(null);
  const [attributesDataStatus, setAttributesDataStatus] = useState(ActionStatusConstants.INITIAL);
  const [templateData, setTemplateData] = useState(null);
  const [templateDataStatus, setTemplateDataStatus] = useState(ActionStatusConstants.INITIAL);
  const [editableTemplateData, setEditableTemplateData] = useState(null);
  const [customTemplateData, setCustomTemplateData] = useState(null);
  const [processedData, setProcessedData] = useState(null);
  const [changeType, setChangeType] = useState(CHANGE_TYPE.keys.value);
  const [logData, setLogData] = useState([]);
  useEffect(() => {
    if (details.length > 0) {
      const newLogData = [];
      details.forEach((item) => {
        if (item.config_data.change_type && item.config_data.change_type === 'keys') {
          const orgKeys = item.config_data.output_data.map((i) => {
            return { current: i.current, corrected: i.corrected };
          });
          newLogData.push({
            id: item.id,
            user: item.created_by,
            date: item.queued_at,
            original_keys: orgKeys,
            corrected_keys: orgKeys,
            file_download_url: item.file_download_url,
            file_name: item.file_name,
          });
        }
      });

      setLogData(newLogData);
    }
  }, [details]);
  // Init calls
  useEffect(() => {
    const generateFullTemplateData = (attributesData) => {
      const output = {
        ...templateData,
      };

      if (attributesData?.length) {
        output.output_format = attributesData.map((item) => {
          let templateAttr = customTemplateData?.output_format?.find(
            (_item) => item.id === _item.id
          );

          if (templateAttr) {
            templateAttr = {
              ...templateAttr,
            };
            if (changeType === 'keys') {
              templateAttr.current = item.key;
            } else {
              templateAttr.current = item.name;
            }
          } else {
            templateAttr = {
              id: item.id,
              key: item.key,
              label: item.name,
              corrected: null,
              is_changed: false,
              is_invalid: false,
              errors: null,
            };
            if (changeType === 'keys') {
              templateAttr.current = item.key;
            } else {
              templateAttr.current = item.name;
            }
          }
          return templateAttr;
        });
      }
      return output;
    };
    DetailedAttributesBotAPICalls.doGetAttributes({
      mfrId: manufacturerId,
      isMounted,
      setAttributesData: (_attributesData) => {
        // Extract the actual list from the paginated data object
        let attrDataList = null;
        if (_attributesData?.attributes?.length) {
          attrDataList = _attributesData.attributes;
        }
        // Generate custom template from newly loaded attributes
        setCustomTemplateData(generateFullTemplateData(attrDataList));
        setEditableTemplateData(generateFullTemplateData(attrDataList));
        setAttributesData(attrDataList);
      },
      setAttributesDataStatus,
      orderBy: changeType
    });
    DetailedAttributesBotAPICalls.doGetStats({
      mfrId: manufacturerId,
      isMounted,
      setProdCountData: (productCount) => {
        setProdCountData(productCount);
      },
      setAttributesCount: (attributesCount) => {
        setAttributesCount(attributesCount);
      },
      setManufacturerName: (manufacturerName) => {
        setManufacturerName(manufacturerName);
      },
      orderBy:changeType,
    });
  }, [isMounted, processedData, changeType]);

  const goToDataLakeHouseDashboard = () => {
    history.push('/data-lakehouse/dashboard');
  };

  const handleSubmit = () => {
    handleRunBot();
  };

  const setConfiguredData = (data) => {
    setProcessedData(data);
    setConfigData(processedData);
  };

  const onSaveChanges = (editableTemplateData) => {
    setIsVisible(false);
    if (editableTemplateData) {
      const isSelected = DetailedAttributesUtils.checkIfSelectedAttribute(editableTemplateData);
      const valid = DetailedAttributesUtils.validateTable(editableTemplateData);
      const data = editableTemplateData.output_format.filter((item) => {
        if (item.is_changed === true) {
          return item;
        }
      });
      data.change_type = changeType;
      setConfigData({ output_data: data, change_type: changeType });
      if (isSelected === false) {
        toast.error('Please select at least one attribute and enter a valid Label for it.');
      } else if (valid && isSelected) {
        setIsVisible(true);
      } else {
        toast.error('Please fix the errors in the table.');
      }
    }
  };

  return (
    <div className="detailed-attributes-bot">
      <h1 className="databots-config-heading">Detailed Attributes Label Correction</h1>
      {attributesData?.length ? (
        <>
          <section className="block databots-summary">
            <ValueBlock title="Manufacturer" subtitle="" value={manufacturerName} />
            <ValueBlock title="Total Products" subtitle="" value={prodCountData} />
            <ValueBlock title="Detailed Attributes" subtitle="" value={attributeCount} />
          </section>
          <section className="block block-action-type">
            <div className="title">Change type</div>
            <Form.Check
              type="radio"
              label={CHANGE_TYPE.keys.label}
              checked={changeType === CHANGE_TYPE.keys.value}
              readOnly
              onClick={() => setChangeType(CHANGE_TYPE.keys.value)}
            />
            <Form.Check
              type="radio"
              label={CHANGE_TYPE.labels.label}
              checked={changeType === CHANGE_TYPE.labels.value}
              readOnly
              onClick={() => setChangeType(CHANGE_TYPE.labels.value)}
            />
          </section>
          <DetailedAttributesTable
            attributesData={attributesData}
            editableTemplateData={editableTemplateData}
            originalTemplateData={customTemplateData}
            onChange={setEditableTemplateData}
            isDisabled={[attributesDataStatus, templateDataStatus].includes(
              ActionStatusConstants.ISBUSY
            )}
            columnsType={changeType}
          />
          <section className="block block-actions text-right">
            <button
              type="button"
              title="Cancel"
              className="btn btn-primary"
              onClick={() => goToDataLakeHouseDashboard()}
            >
              Cancel
            </button>
            <button
              type="button"
              title="Save"
              className="btn btn-primary"
              onClick={() => onSaveChanges(editableTemplateData)}
            >
              Save
            </button>
            <div>
              <CreateDetailedAttributesBotModal
                templateData={editableTemplateData}
                botId={slug}
                columnsType={changeType}
                manufacturer={manufacturerId}
                onVisibilityChange={setIsVisible}
                visible={isVisible}
                onSuccess={handleSubmit}
                onSetConfig={setConfiguredData}
              />
            </div>
          </section>
        </>
      ) : null}
      <section className="block block-action-type block-log">
        {details.length > 0 && changeType === CHANGE_TYPE.keys.value ? (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a id="log">
              <h3>Attribute Key Correction Log</h3>
            </a>
            <BootstrapTable
              bordered={false}
              bootstrap4
              pagination={paginationFactory({
                showTotal: true,
                sizePerPageList: [25, 50, 100],
              })}
              keyField="id"
              data={logData}
              columns={DALCLogTableTableDef.columns}
            />
          </>
        ) : null}
      </section>
    </div>
  );
};

export { DetailedAttributesBot };
