import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { DropdownButton } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { HeaderNotificationItem } from 'components/header-notifications/HeaderNotificationItem';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { useIsMounted } from 'helpers/useIsMounted';
import { useOutsideClick } from 'helpers/useOutsideClick';
import './HeaderNotifications.scss';
import { useWebsocket } from 'helpers/useWebsocket';

import { getTransformedData } from './HeaderNotificationUtils';
import { WsConstants } from 'api/WsConstants';

/**
 * Header Bell Notifications
 *
 * @returns Render
 */
const HeaderNotifications = () => {
  const channel = WsConstants.WS_CHANNELS.NOTIFICATION;

  const history = useHistory();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const isMounted = useIsMounted();

  const dropdownRef = useRef();

  // Holds raw data between loads(previous value)
  const rawNotifsData = useRef(null);

  // The number of notifications that should be displayed in the menu
  const maxNotifLength = 5;

  // create websocket
  const ws = useWebsocket();
  const [wsLastUpdate, setWsLastUpdate] = useState(null);
  const { messages, messagesLastUpdate } = ws;

  // add websocket channel
  useEffect(() => {
    if (isMounted.current === true && ws?.isConnected && wsLastUpdate === null) {
      ws.addChannel(channel);
      setWsLastUpdate(Date.now());
    }
  }, [isMounted, ws?.isConnected, ws, wsLastUpdate]);

  // watch for new messages in that channel
  useEffect(() => {
    if (
      isMounted.current === true &&
      ws?.isConnected &&
      messages[channel]?.length > 0 &&
      wsLastUpdate < messagesLastUpdate[channel]
    ) {
      setWsLastUpdate(messagesLastUpdate[channel]);
      try {
        if (isMounted.current === true) {
          let _data = messages[channel];
          if (!_data || _data === undefined) {
            return;
          }

          // Transform data items to what we need to show
          if (_data && Array.isArray(_data) && _data.length) {
            _data = _data.map((item) => getTransformedData(item)).filter((item) => !!item);
          }
          if (_data && _data.length > maxNotifLength) {
            _data = _data.slice(0, maxNotifLength);
          }

          rawNotifsData.current = _data;
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [ws?.isConnected, isMounted, wsLastUpdate, ws, messages, messagesLastUpdate]);

  const markViewedItems = () => {
    // Immediately mark local list as viewed and send a request to the BE to mark
    // all items as viewed. We don't care about the success/failure of the call.
    if (rawNotifsData.current && rawNotifsData.current.length) {
      const notifIds = [];

      rawNotifsData.current = _cloneDeep(rawNotifsData.current);
      for (let i = 0; i < rawNotifsData.current.length; i++) {
        rawNotifsData.current[i].viewed = true;

        const notifId = _get(rawNotifsData.current[i], 'id') || null;

        if (notifId) {
          notifIds.push(notifId);
        } else {
          console.error('No notification ID', rawNotifsData.current[i]);
        }
      }

      if (notifIds && notifIds.length) {
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.PATCH,
          data: { tracked_notification_ids: notifIds },
          urlPath: `/notifications/views`,
          onError: (err) => {
            console.error(err);
          },
        });
      }
    }
  };

  useOutsideClick(dropdownRef, () => {
    if (isDropdownVisible) {
      setIsDropdownVisible(false);
      markViewedItems();
    }
  });

  const renderNotifsList = () => {
    let output = null;
    if (rawNotifsData?.current && rawNotifsData.current.length) {
      output = rawNotifsData.current.map((item, i) => (
        <HeaderNotificationItem
          key={i}
          as={<li />}
          isViewed={item.viewed}
          url={item.url}
          image={item.image}
          message={item.renderMessage()}
          createdAt={item.created_at}
          onClick={(url) => {
            setIsDropdownVisible(false);
            markViewedItems();
            if (url) {
              history.push(url);
              history.go();
            } else {
              console.error('Invalid URL', url);
            }
          }}
        />
      ));
    }

    return output;
  };

  const getNewItemsCount = () => {
    if (rawNotifsData.current && rawNotifsData.current.length) {
      let newItemsCount = 0;

      for (let i = 0; i < rawNotifsData.current.length; i++) {
        if (rawNotifsData.current[i] && !rawNotifsData.current[i].viewed) {
          newItemsCount += 1;
        }
      }

      return newItemsCount;
    }

    return null;
  };

  const isBusy = rawNotifsData.currentStatus === ActionStatusConstants.ISBUSY;
  const hasNotifs = rawNotifsData.current && rawNotifsData.current.length;
  const newItemsCount = getNewItemsCount();

  return (
    <div
      ref={dropdownRef}
      className={classNames('header-notifications', {
        'has-new-items': newItemsCount > 0,
        loading: isBusy,
        empty: !hasNotifs,
      })}
    >
      <DropdownButton
        variant="secondary"
        title={
          <>
            <LoadingSpinner fast />
            <span className="icon">
              <FontAwesomeIcon icon={['fas', 'bell']} />
            </span>
          </>
        }
        show={isDropdownVisible}
        onToggle={(isOpen, event) => {
          // Bootstrap bug workaround - onToggle is triggered twice
          // once with event and metadata populated, and once - with undefined.
          if (event) {
            setIsDropdownVisible(isOpen);
            if (!isOpen) {
              markViewedItems();
            }
          }
        }}
      >
        {isDropdownVisible ? (
          <>
            <div className="header">
              <div className="title">{hasNotifs ? 'Notifications' : 'No Notifications'}</div>
              <Link
                onClick={() => setIsDropdownVisible(false)}
                hidden={!hasNotifs}
                to="/notifications"
                className="new-count"
              >
                See All
              </Link>
            </div>
            {hasNotifs ? (
              <>
                <div className="body">
                  <ul className="notifications-list">{renderNotifsList()}</ul>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </DropdownButton>
    </div>
  );
};

HeaderNotifications.propTypes = {};

export { HeaderNotifications };
