import React from 'react';

import { Helmet } from 'react-helmet';

import { DataLakehouseDashboardAdmin } from 'components/data-lakehouse/DataLakehouseDashboard/DataLakehouseDashboardAdmin';
import { DataLakehouseDashboardSupplier } from 'components/data-lakehouse/DataLakehouseDashboard/DataLakehouseDashboardSupplier';
import { RootHooks } from 'helpers/RootHooks';
import * as UserUtils from 'helpers/UserUtils';

const ViewDataLakehouseDashboard = () => {
  const { activeUser } = RootHooks.useActiveUser();

  const isSupplier = UserUtils.isRoleSupplier(activeUser);

  return (
    <div className="content content-fluid view-data-lakehouse-dashboard">
      <Helmet bodyAttributes={{ 'data-page': 'view-data-lakehouse-dashboard' }}>
        <title>Dashboard</title>
      </Helmet>

      {isSupplier ? <DataLakehouseDashboardSupplier /> : <DataLakehouseDashboardAdmin />}
    </div>
  );
};

export { ViewDataLakehouseDashboard };
