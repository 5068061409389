import './AssetActions.scss';
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { DropdownButton } from 'react-bootstrap';
import 'mdn-polyfills/MouseEvent';

import * as ApiCalls from 'api/ApiCalls';
import BackboneLogo from 'assets/img/backbone-logo.svg';
import CAD from 'assets/img/document-types/CAD.svg';
import PDF from 'assets/img/document-types/PDF.svg';
import STP from 'assets/img/document-types/STP.svg';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { LoadingText } from 'components/common/LoadingText/LoadingText';
import { PRODUCT_ASSET_LABELS, PRODUCT_ASSET_TYPES } from 'constants/ProductConstants';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { toast } from 'helpers/ToastUtils';

const AssetActions = ({ assetDocuments, assetImages, setAssetDocuments, productId }) => {
  const [selectedDocuments, setSelectedDocuments] = useState(0);
  const [isDocumentationVisible, setIsDocumentationVisible] = useState(false);
  const [areImagesVisible, setAreImagesVisible] = useState(false);
  const [downloadSpinner, setDownloadSpinner] = useState(false);

  const onDocumentSelected = (file) => {
    const assets = _cloneDeep(assetDocuments);
    let selectedCount = 0;
    assets.forEach((asset) => {
      if (asset.hash === file.hash) asset.selected = !asset.selected;
      if (asset.selected) selectedCount++;
    });

    setAssetDocuments(assets);
    setSelectedDocuments(selectedCount);
  };

  const toggleDocumentationDropdown = (isOpen) => {
    setIsDocumentationVisible(isOpen);
  };

  const toggleImagesDropdown = (isOpen) => {
    setAreImagesVisible(isOpen);
  };

  const download = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = true;
    link.style.display = 'none';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    document.body.appendChild(link);
    link.dispatchEvent(event);
    document.body.removeChild(link);
  };

  const downloadSelectedDocuments = () => {
    assetDocuments
      .filter((asset) => asset.selected)
      .forEach((asset) => {
        download(asset.url);
      });
    setIsDocumentationVisible(false);
  };

  const downloadAllDocuments = () => {
    assetDocuments.forEach((asset) => {
      download(asset.url);
    });
    setIsDocumentationVisible(false);
  };
  const downloadAllImages = () => {
    setDownloadSpinner(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/product/${productId}/download-images?token=${getAuthToken()}`,
      onSuccess: (res) => {
        setAreImagesVisible(false);
        setDownloadSpinner(false);
        download(res.data.zip_url);
      },
      onError: () => {
        setDownloadSpinner(false);
        toast.error('We were unable to Zip these files. Please try downloading them individually.');
      },
    });
  };

  const renderDocuments = () => {
    const fileType = (type) => {
      if (type === PRODUCT_ASSET_TYPES.CAD_2D) return CAD;
      if (type === PRODUCT_ASSET_TYPES.CAD_3D) return STP;
      return PDF;
    };

    const fileLabel = (type) => {
      const key = Object.keys(PRODUCT_ASSET_TYPES).find((key) => PRODUCT_ASSET_TYPES[key] === type);
      return PRODUCT_ASSET_LABELS[key];
    };

    const fileNameWithoutExt = (fileName) => {
      const delimiterIndex = fileName.indexOf('.');
      return fileName.slice(0, delimiterIndex);
    };

    return assetDocuments.map((file, i) => (
      <div key={file.name} className="assets-actions-body-row">
        <input type="checkbox" defaultChecked={file.selected} hidden name={`file-${i}`} />
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => onDocumentSelected(file)}
          className="assets-actions-body-checkbox"
          onClick={() => onDocumentSelected(file)}
        >
          <Checkbox checked={file.selected} />
        </div>
        <img className="assets-actions-body-type" src={fileType(file.type)} alt="" />
        <label htmlFor={`file-${i}`}>
          {fileLabel(file.type)} - {fileNameWithoutExt(file.name)}
        </label>
        {file.url && (
          <a
            href={file.url}
            download
            target="_blank"
            rel="noopener noreferrer"
            className="assets-actions-body-download"
          >
            <span className="oi oi-data-transfer-download" />
          </a>
        )}
      </div>
    ));
  };

  const renderImages = () => {
    return assetImages.map((image) => (
      <div key={image.url} className="assets-actions-body-img">
        <img src={image.url ?? BackboneLogo} alt="" />
        {image.url && (
          <a
            href={image.url}
            download
            target="_blank"
            rel="noopener noreferrer"
            className="assets-actions-body-download"
          >
            <span className="oi oi-data-transfer-download">
              <FontAwesomeIcon
                className="attachments-table-row__action-icon"
                icon={['fas', 'download']}
              />
            </span>
          </a>
        )}
      </div>
    ));
  };

  return (
    <div className="general-info-assets-actions">
      <DropdownButton
        className="specific-documentation"
        show={isDocumentationVisible}
        onToggle={(isOpen) => toggleDocumentationDropdown(isOpen)}
        variant="secondary"
        title={<span>View Documentation</span>}
      >
        <div className="assets-dropdown-header">
          <div className="title">All Available Documents</div>
          <div
            tabIndex={0}
            role="button"
            onKeyDown={() => toggleDocumentationDropdown(!isDocumentationVisible)}
            onClick={() => toggleDocumentationDropdown(!isDocumentationVisible)}
            className="close-icon"
          >
            <span className="oi oi-x" />
          </div>
        </div>
        <div className="assets-actions-body">
          <div className="assets-actions-body-header">
            <div className="assets-actions-body-header__leading">
              Documents ({assetDocuments.length || 0})
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={downloadSelectedDocuments}
              onClick={downloadSelectedDocuments}
              className={classNames('assets-actions-body-header__select', {
                'assets-actions-body-header__select--disabled': selectedDocuments === 0,
              })}
            >
              <span className="oi oi-data-transfer-download" />
              Download Selected ({selectedDocuments})
            </div>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={downloadAllDocuments}
              onClick={downloadAllDocuments}
              className={classNames('assets-actions-body-header__select', {
                'assets-actions-body-header__select--disabled': !assetDocuments.length,
              })}
            >
              <span className="oi oi-data-transfer-download" />
              Download All
            </div>
          </div>
          {renderDocuments()}
        </div>
      </DropdownButton>
      <DropdownButton
        className="download-images"
        show={areImagesVisible}
        onToggle={(isOpen) => toggleImagesDropdown(isOpen)}
        variant="primary"
        title={
          <span className="general-info-assets-actions--download">
            <span className="oi oi-data-transfer-download" />
            Download Images
          </span>
        }
      >
        <div className="assets-dropdown-header">
          <div className="title">All Available Images</div>
          <div
            tabIndex={0}
            role="button"
            onKeyDown={() => toggleImagesDropdown(!areImagesVisible)}
            onClick={() => toggleImagesDropdown(!areImagesVisible)}
            className="close-icon"
          >
            <span className="oi oi-x" />
          </div>
        </div>
        <div className="assets-actions-body">
          <div className="assets-actions-body-header">
            <div className="assets-actions-body-header__leading">
              Images ({assetImages.length || 0})
            </div>
            {downloadSpinner ? (
              <LoadingText
                text="Zipping your Files. Please wait"
                style={{
                  fontSize: '1em',
                  width: '16em',
                  margin: 'auto',
                  verticalAlign: 'baseline',
                }}
              />
            ) : (
              <div
                tabIndex={0}
                role="button"
                onKeyDown={downloadAllImages}
                onClick={downloadAllImages}
                className={classNames('assets-actions-body-header__select', {
                  'assets-actions-body-header__select--disabled': !assetImages.length,
                })}
              >
                <span className="oi oi-data-transfer-download" />
                Download All
              </div>
            )}
          </div>
          <div className="assets-actions-body-images">{renderImages()}</div>
        </div>
      </DropdownButton>
    </div>
  );
};

AssetActions.propTypes = {
  assetDocuments: PropTypes.arrayOf(PropTypes.object),
  assetImages: PropTypes.arrayOf(PropTypes.object),
  setAssetDocuments: PropTypes.func.isRequired,
};

AssetActions.defaultProps = {
  assetDocuments: [],
  assetImages: [],
};

export { AssetActions };
