import React from 'react';

import csvIcon from 'assets/img/file-formats/csv.svg';
import xlsIcon from 'assets/img/file-formats/xls.svg';
import xlsxIcon from 'assets/img/file-formats/xlsx.svg';

import ChatGptLogo from '../../../assets/img/chatgpt-logo.svg';

const CDS_BOT_NAMES = {
  GPT_DESCRIPTION: 'gpt-description-generator',
  GPT_FB: 'gpt-fnb-generator',
  ATTRIBUTE_COMPRESSION: 'attribute-compression',
};

const CDS_BOT_ITEM = {
  gptDescription: {
    initialState: {
      isSourceValid: false,
      isContentValid: false,
      config_data: null,
      validSelections: false,
      isLoading: false,
      newDescription: '',
      newDescriptionError: '',
      headerMapping: {},
      headersList: [],
      headerMappingChoices: [],
      selectedHeaders: [],
      additionalLanguages: null,
      selectedLength: null,
      selectedTone: null,
      selectedGoal: '',
    },
    name: CDS_BOT_NAMES.GPT_DESCRIPTION,
    title: 'GPT Description Generator',
    description: 'Generates SEO-optimized product descriptions.',
    icon: (
      <div className="powered-by-wrapper">
        <span>
          Powered by <strong>ChatGPT</strong>
          <img src={ChatGptLogo} alt="" />
        </span>
      </div>
    ),
  },
  gptFB: {
    name: CDS_BOT_NAMES.GPT_FB,
    title: 'GPT Features and Benefits Generator',
    description: 'Generates content for the Features and Benefits section of your products.',
    icon: (
      <div className="powered-by-wrapper">
        <span>
          Powered by <strong>ChatGPT</strong>
          <img src={ChatGptLogo} alt="" />
        </span>
      </div>
    ),
  },
  attributeCompression: {
    name: CDS_BOT_NAMES.ATTRIBUTE_COMPRESSION,
    title: 'Attribute Compression',
    description: 'Reduces the character size of your product attributes to the desired lengths.',
  },
};

const CDS_ACCEPTED_FILE_TYPES = [
  { name: '.csv', icon: csvIcon },
  { name: '.xlsx', icon: xlsxIcon },
  { name: '.xls', icon: xlsIcon },
];

const CDS_TOOL_TIP = {
  columnMapping: {
    title: 'Column Mapping',
    content:
      'The bot requires a set of product attributes to be able to run successfully. Use this control to specify the corresponding columns for each of the requested attributes.',
    iconProp: ['far', 'question-circle'],
  },
  additionalColumns: {
    title: 'Additional Columns',
    content:
      'Select any other columns you consider relevant. They will be used to provide additional context to the bot. The order you specify the attributes represents the amount of importance the bot will assign to them.',
    iconProp: ['far', 'question-circle'],
  },
  name: {
    title: 'Column Name',
    content:
      'The newly generated content will be added as a new column in your file, using the name you specify in this field.',
    iconProp: ['far', 'question-circle'],
  },
  additionalLanguages: {
    title: 'Additional Languages',
    content:
      'This bot defaults to producing content in English. If you add more languages, the original English content will be translated into those languages and incorporated into the output file.',
    iconProp: ['far', 'question-circle'],
  },
  guidelines: {
    title: 'Guidelines',
    content: 'Use these settings to control specific aspects of the content you are generating.',
    iconProp: ['far', 'question-circle'],
  },
  length: {
    title: 'Length',
    content: 'Controls the length, in words of the generated content.',
    iconProp: ['far', 'question-circle'],
  },
  tone: {
    title: 'Tone',
    content: (
      <>
        <div>
          <div>Controls the tone of the generated content.</div>
          <div>
            Available options:
            <ul>
              <li>
                <strong>Technical</strong> - Focuses on precise technical details and specifications
                of the product.
              </li>
              <li>
                <strong>Conversational</strong> - Uses friendly and approachable language to
                describe the product.
              </li>
              <li>
                <strong>Professional</strong> - Uses language that appeals to emotions and aims to
                convince the reader to buy the product.
              </li>
              <li>
                <strong>Persuasive</strong> - Uses business language and terminology to convey
                authority and expertise.
              </li>
              <li>
                <strong>Descriptive</strong> - Uses vivid language and sensory appeal to describe
                the product and its benefits.
              </li>
            </ul>
          </div>
        </div>
      </>
    ),
    iconProp: ['far', 'question-circle'],
  },
  tone_fnb: {
    title: 'Tone',
    content: (
      <>
        <div>
          <div>Controls the tone of the generated content.</div>
          <div>
            Available options:
            <ul>
              <li>
                <strong>Technical</strong> - Focuses on precise technical details and specifications
                of the product.
              </li>
              <li>
                <strong>Conversational</strong> - Uses friendly and approachable language to
                describe the product.
              </li>
              <li>
                <strong>Professional</strong> - Uses language that appeals to emotions and aims to
                convince the reader to buy the product.
              </li>
              <li>
                <strong>Persuasive</strong> - Uses business language and terminology to convey
                authority and expertise.
              </li>
              <li>
                <strong>Descriptive</strong> - Uses vivid language and sensory appeal to describe
                the product and its benefits.
              </li>
              <li>
                <strong>Marketing & Sales</strong> - Uses engaging language to highlight key
                features and benefits, motivating the audience to purchase.
              </li>
            </ul>
          </div>
        </div>
      </>
    ),
    iconProp: ['far', 'question-circle'],
  },
  goal: {
    title: 'Goal',
    content:
      'Allows you to specify the intended use of the generated content. The bot will apply nuances to the content to ensure it is appropriate for the intended use.',
    iconProp: ['far', 'question-circle'],
  },
  extraKeywords: {
    title: 'Extra Keywords',
    content:
      'Use this option to specify additional keywords that you do not have in your attributes file. You can select from the already available keywords, or you can add your own, by typing in this field.',
    iconProp: ['far', 'question-circle'],
  },
  itemGrade: {
    title: 'Item Grade',
    content:
      'Use this column to specify the Grade of the item, if this information does not exist in the product attributes in the provided file.The value you select in this field will be used for all the SKUs in the file.',
    iconProp: ['far', 'question-circle'],
  },
  formattingOptions: {
    title: 'Formatting Options',
    content: 'These settings control the way your generated content is formatted.',
    iconProp: ['far', 'question-circle'],
  },
  formattingStyle: {
    title: 'Formatting Style',
    content: 'Controls the form in which the content is generated.',
    iconProp: ['far', 'question-circle'],
  },
  count: {
    title: 'Count',
    content:
      'Controls how many pieces of content will be generated, in any of the selected formatting styles.',
    iconProp: ['far', 'question-circle'],
  },
};

const CdsModels = {
  CDS_TOOL_TIP,
  CDS_BOT_ITEM,
  CDS_BOT_NAMES,
  CDS_ACCEPTED_FILE_TYPES,
};

export { CdsModels };
