import './CompanyMetricsCard.scss';

import React from 'react';

const CompanyMetricsCard = ({ total, header, metrics }) => {
  return (
    <article className="company-metrics-card">
      <div className="company-metrics-card-header">
        <span>{total ?? 0}</span>
        <h2>{header ?? 'N/A'}</h2>
      </div>
      <div className="company-metrics-card-body">
        <ul className="company-metrics-card-list">
          {metrics &&
            metrics.map((metric) => (
              <li key={metric.label} className="company-metrics-card-list-item">
                <p className="company-metrics-card-list-item-label">{metric.label ?? 'N/A'}</p>
                <p>{metric.total ?? 0}</p>
              </li>
            ))}
        </ul>
      </div>
    </article>
  );
};

export { CompanyMetricsCard };
