import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as ApiCalls from 'api/ApiCalls';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { formatDateStamp } from 'helpers/TimeUtils';
import { getDefaultTableColumnDef } from 'helpers/Utils';

import { ATTRIBUTE_TYPES, EXPORT_STATUSES } from './ExportFunctionalityAdvancedConstants';

const attrTypesToCsv = (typesArray) => {
  let output = null;

  if (typesArray?.length) {
    Object.keys(ATTRIBUTE_TYPES)
      .filter((item) => typesArray.includes(item))
      .map((item) => ATTRIBUTE_TYPES[item].label);

    if (output?.length) {
      output = output.join(', ');
    }
  }

  return output;
};

export const BlockExportStatusTableTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('file', 'File'),
      sort: true,
      formatter: (cell, row) => {
        return row.status === 'done' ? (
          <a
            className="btn btn-primary btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href={`${ApiCalls.BASE_API_URL}/product-export-history/${
              row.id
            }/download?token=${getAuthToken()}`}
          >
            <span className="icon" style={{ marginRight: '0.5em' }}>
              <FontAwesomeIcon icon={['far', 'file-download']} />
            </span>
            Download
          </a>
        ) : null;
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = rowA?.status === 'done' ? 1 : 0;
        const numB = rowB?.status === 'done' ? 1 : 0;
        return order === 'asc' ? numA - numB : numB - numA;
      },
    },
    {
      ...getDefaultTableColumnDef('manufacturer', 'Manufacturer'),
      sort: true,
      formatter: (cell, row) => row.manufacturer?.name || 'N/A',
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        return (
          (order === 'asc' ? 1 : -1) *
          `${rowA?.manufacturer?.name || ''}`.localeCompare(`${rowB?.manufacturer?.name || ''}`)
        );
      },
    },
    {
      ...getDefaultTableColumnDef('type', 'Export Type'),
      sort: true,
      formatter: (cell, row) => attrTypesToCsv(row?.type) || 'Advanced',
      sortFunc: (a, b, order) => {
        const strA = attrTypesToCsv(a?.type);
        const strB = attrTypesToCsv(b?.type);
        return (order === 'asc' ? 1 : -1) * `${strA || ''}`.localeCompare(`${strB || ''}`);
      },
    },
    {
      ...getDefaultTableColumnDef('created_at', 'Started At'),
      sort: true,
      formatter: (_cell, row) => (row.created_at ? formatDateStamp(row.created_at) : 'N/A'),
      sortFunc: (a, b, order) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      ...getDefaultTableColumnDef('status', 'Status'),
      sort: true,
      formatter: (cell, row) => {
        let output = 'N/A';

        if (Object.keys(EXPORT_STATUSES).includes(row.status)) {
          output = EXPORT_STATUSES[row.status].label;
        }
        return output;
      },
      sortFunc: (a, b, order) => {
        return (order === 'asc' ? 1 : -1) * `${a || ''}`.localeCompare(`${b || ''}`);
      },
    },
    {
      ...getDefaultTableColumnDef('created_by', 'Created By'),
      sort: true,
      formatter: (_cell, row) => row.user?.email || 'N/A',
      sortFunc: (a, b, order) => {
        return (order === 'asc' ? 1 : -1) * `${a || ''}`.localeCompare(`${b || ''}`);
      },
    },
  ],
};
