import React from 'react';

import PropTypes from 'prop-types';

/**
 * Default subtitle template for DatabotConfigPanel.
 *
 * This is just a wrapper template to provide consistent className.
 *
 * Do NOT import this directly. Use it through the main component - <DatabotConfigPanel.Template[name] />
 *
 * @param {element} children Content
 * @return render
 */
const TemplateSubtitle = ({ children }) => {
  return <section className="databot-config-panel-subtitle">{children}</section>;
};

TemplateSubtitle.defaultProps = {
  children: null,
};

TemplateSubtitle.propTypes = {
  children: PropTypes.node,
};

export { TemplateSubtitle };
