import './SlateRTE.scss';

import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import isHotkey from 'is-hotkey';
import isUrl from 'is-url';
import _debounce from 'lodash/debounce';
import { ButtonGroup } from 'react-bootstrap';
import { Editor, Node, Range, Element as SlateElement, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, ReactEditor, useSlate, withReact } from 'slate-react';

import { Button, Icon, Toolbar, Portal } from './components';
import { insertMention, Mention } from './Mentions';
import { deserialize, normalize, serialize } from './serial';

// TODO: Huge component, to be split to managable pieces

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const SlateRTE = ({
  value,
  setConvertedValue,
  resetSentinel,
  maxLength,
  disabled,
  taggableUsers,
  taggedUsers,
  setTaggedUsers,
}) => {
  const ref = useRef();
  const isMounted = React.useRef(true);
  const [count, setCount] = useState(0);
  const [localValue, setLocalValue] = useState(initialValue);
  const [isDoOnce, setIsDoOnce] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [isRed, setIsRed] = useState(false);
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');

  const renderElement = useCallback((props) => {
    return <Element {...props} />;
  }, []);
  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const setRedTimeout = () => {
    setIsRed(true);
    setTimeout(() => setIsRed(false), 2200);
  };

  const editor = useMemo(() => withMentions(withLinks(withHistory(withReact(createEditor())))), []);

  let tagList = [];
  if (taggableUsers !== undefined) {
    const users = taggableUsers.map((u) => ({
      display: `${u.first_name} ${u.last_name}`,
      id: u.id,
      email: u.email || 'no email',
    }));

    tagList = users
      ?.filter((c) => c.display.toLowerCase().startsWith(search.toLowerCase()))
      .slice(0, 20);
  }

  const editableKeyDown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
    if (
      maxLength &&
      parseInt(maxLength) &&
      count >= parseInt(maxLength) &&
      event.keyCode !== 46 && // keycode for delete
      event.keyCode !== 8 // keycode for backspa
    ) {
      setRedTimeout();
      event.preventDefault();
    }

    if (target) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          const prevIndex = index >= tagList.length - 1 ? 0 : index + 1;
          setIndex(prevIndex);
          break;
        case 'ArrowUp':
          event.preventDefault();
          const nextIndex = index <= 0 ? tagList.length - 1 : index - 1;
          setIndex(nextIndex);
          break;
        case 'Tab':
          break;
        case 'Enter':
          event.preventDefault();
          Transforms.select(editor, target);
          insertMention(editor, tagList[index], taggableUsers, taggedUsers, (v) =>
            setTaggedUsers(v)
          );
          setTarget(null);
          break;
        case 'Escape':
          event.preventDefault();
          setTarget(null);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (target && tagList.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [tagList.length, editor, index, search, target]);

  const deserial = useCallback((v) => {
    if (!v || v.trim().length === 0) {
      return [
        {
          type: 'paragraph',
          children: [
            {
              text: '',
            },
          ],
        },
      ];
    }

    const parser = new DOMParser().parseFromString(v, 'text/html');
    const nodes = deserialize(parser.body);
    // normalize nodes to Slate compatible format
    nodes.forEach((node) => normalize(node));
    return nodes;
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isClear && isMounted.current && resetSentinel) {
      setLocalValue(initialValue);
      editor.history = { redos: [], undos: [] };
      editor.selection = null;
      setIsClear(true);
    }
  }, [editor.history, editor.selection, isClear, resetSentinel]);

  useEffect(() => {
    if (value && !isDoOnce && isMounted.current) {
      setLocalValue(deserial(value));
      setIsDoOnce(true);
      setIsClear(false);
    } else if (value && isMounted.current) {
      setIsClear(false);
    }
  }, [deserial, value, isDoOnce]);

  const setParentVal = useCallback(
    _debounce((v) => {
      if (isMounted.current && setConvertedValue && typeof setConvertedValue === 'function') {
        // this will also set localValue on value update
        const serial = serialize({ children: v });
        setConvertedValue(serial);
      }
    }, 20),
    [setConvertedValue]
  );

  const setValues = (v) => {
    if (maxLength && parseInt(maxLength)) {
      let c = 0;
      v.forEach((node) => {
        c += Node.string(node).length;
      });
      setCount(c);
    }
    setLocalValue(v);
    setParentVal(v);
  };

  const slateOnChange = (value) => {
    setValues(value);
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: 'word' });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
    }

    setTarget(null);
  };

  return (
    <div className={`slate-rte-text-editor${disabled ? '--disabled' : ''}`} id="slate-wrapper">
      <Slate editor={editor} value={localValue} onChange={(value) => slateOnChange(value)}>
        <Toolbar>
          <ButtonGroup toggle>
            <MarkButton format="bold" icon="bold" />
            <MarkButton format="italic" icon="italic" />
            <MarkButton format="underline" icon="underline" />
            <MarkButton format="code" icon="code" />
            <BlockButton format="heading-one" icon="h1" />
            <BlockButton format="heading-two" icon="h2" />
            <BlockButton format="block-quote" icon="quote-right" />
            <BlockButton format="numbered-list" icon="list-ol" />
            <BlockButton format="bulleted-list" icon="list-ul" />
            <LinkButton icon="link" />
            <RemoveLinkButton icon="unlink" />
          </ButtonGroup>
        </Toolbar>
        <Editable
          className="slate-rte-text-editable"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some text…"
          spellCheck
          onPaste={(event) => {
            const text = event.clipboardData.getData('Text');
            if (maxLength && parseInt(maxLength) && count + text.length >= parseInt(maxLength)) {
              event.preventDefault();
              setRedTimeout();
            }
          }}
          onKeyDown={(event) => {
            editableKeyDown(event);
          }}
        />
        {target && tagList.length > 0 && (
          <Portal>
            <div
              ref={ref}
              style={{
                top: '-9999px',
                left: '-9999px',
                position: 'absolute',
                zIndex: 1,
                padding: '3px',
                background: 'white',
                borderRadius: '4px',
                boxShadow: '0 1px 5px rgba(0,0,0,.2)',
              }}
              data-cy="mentions-portal"
            >
              {tagList.map((user, i) => (
                <button
                  type="button"
                  className="button-inherit"
                  onClick={() => {
                    Transforms.select(editor, target);
                    insertMention(editor, user, taggableUsers, taggedUsers, (v) =>
                      setTaggedUsers(v)
                    );
                    setTarget(null);
                  }}
                  key={user.id}
                  style={{
                    display: 'block',
                    padding: '1px 3px',
                    borderRadius: '3px',
                    background: i === index ? '#B4D5FF' : 'transparent',
                  }}
                >
                  {`${user.display} (${user.email})`}
                </button>
              ))}
            </div>
          </Portal>
        )}
      </Slate>
      <span className={`slate-rte-text-editor-wordcount${isRed ? '-red' : ''}`}>
        {count}/{parseInt(maxLength)}
      </span>
    </div>
  );
};

const withMentions = (editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  return editor;
};

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  });
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === 'link' });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const mentionProps = { attributes, children, element };
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'mention':
      return <Mention {...mentionProps} />;
    case 'link':
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.mention) {
    children = (
      <span
        className="comment-mention"
        mention-id={leaf.tag?.id}
        mention-email={leaf.tag?.email}
        mention-display={leaf.tag?.display}
        contentEditable={false}
      >
        {children}
      </span>
    );
  }

  return <span {...attributes}>{children}</span>;
};

const LinkButton = ({ icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const RemoveLinkButton = ({ icon }) => {
  const editor = useSlate();

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export { SlateRTE };
