import './DataJobs.scss';
import React from 'react';

import classNames from 'classnames';

import { JOBTYPES } from 'constants/DataJobDetailsConstants';

const DataJobsTabs = ({ isAdmin, activeTab, tryChangeActiveTab }) => {
  const tabs = [
    { label: 'All Data Jobs', key: JOBTYPES.DATA_JOBS_ALL },
    { label: 'Sent Data Jobs', key: JOBTYPES.DATA_JOBS_SENT },
    { label: 'Received Data Jobs', key: JOBTYPES.DATA_JOBS_RECEIVED },
    { label: 'Draft Data Jobs', key: JOBTYPES.DATA_JOBS_INTERNAL },
    { label: 'Pass Through Files', key: JOBTYPES.PASS_THRU_FILES },
  ];

  const handleDataTabSelected = (key) => {
    tryChangeActiveTab(key);
  };

  return (
    <div className="data-jobs-tabs">
      {tabs.map((tab) => (
        <button
          type="button"
          key={tab.key}
          onClick={() => handleDataTabSelected(tab.key)}
          className={classNames('data-jobs-tabs-tab', {
            'data-jobs-tabs-tab--active': activeTab === tab.key,
          })}
          hidden={
            isAdmin && [JOBTYPES.DATA_JOBS_SENT, JOBTYPES.DATA_JOBS_RECEIVED].includes(tab.key)
          }
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export { DataJobsTabs };
