import React from 'react';

import { Prototype } from 'components/prototype/Prototype';

const ViewPrototype = () => {
  return (
    <div className="content content-fluid view-weekly-metrics">
      <Prototype />
    </div>
  );
};

export { ViewPrototype };
