import React, { useState } from 'react';

import './ImgLightbox.scss';
import PropTypes from 'prop-types';
import { Lightbox } from 'react-modal-image';

// TODO: Improvements:
// TODO: Portal to upper elements or fwd ref support
// TODO: Support for thumbnail tooltip when it becomes available on BE
// TODO: Improve UI of lightbox lib

/**
 * Allows to open up a lightbox with an image. Child node is a render function with a
 * doOpen function that triggers the lightbox.
 *
 * @param {Object} props the prop object
 * @param {String} props.src Image src
 * @param {String} props.srcSm Small image src
 * @param {String} props.title Title to display on top of lightbox
 * @param {function} props.children Function to render trigger nodes
 * @param {boolean} props.hideDownload Hide download button
 * @param {string} props.imageBackgroundColor Background color of image
 */
const ImgLightbox = ({
  src,
  srcSm,
  title,
  children,
  hideDownload = true,
  imageBackgroundColor = 'black',
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="modal_image__light_box_wrapper">
      {typeof children === 'function' && children({ doOpen: () => setIsVisible(true) })}
      {isVisible && src && (
        <Lightbox
          alt={title}
          small={srcSm}
          medium={src}
          hideDownload={hideDownload}
          imageBackgroundColor={imageBackgroundColor}
          onClose={() => setIsVisible(false)}
        />
      )}
    </div>
  );
};

ImgLightbox.defaultProps = {
  srcSm: null,
  title: null,
};

ImgLightbox.propTypes = {
  src: PropTypes.string.isRequired,
  srcSm: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export { ImgLightbox };
