import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";

import RootReducer from "store/reducers/RootReducer";
import RootSaga from "store/sagas/RootSaga";

// Setup store
const sagaMiddleware = createSagaMiddleware();
export const browserHistory = createBrowserHistory();

const middleware = [sagaMiddleware];
if (
  !process.env.REACT_APP_DISABLE_REDUX_LOG ||
  process.env.REACT_APP_DISABLE_REDUX_LOG === "false"
) {
  middleware.push(logger);
}

export const store = createStore(
  RootReducer(browserHistory),
  composeWithDevTools(applyMiddleware(routerMiddleware(browserHistory), ...middleware))
);

// Init our root saga
sagaMiddleware.run(RootSaga);
