import React from "react";
import { statusValuesEnum } from "constants/DataJobDetailsConstants";

export { statusValuesEnum };

export const priorityValuesEnum = {
  1: "Low",
  2: "Medium",
  3: "High",
};

export const renderPriorityOptions = () => {
  return [
    <option value="1" key={0}>
      Low
    </option>,
    <option value="2" key={1}>
      Medium
    </option>,
    <option value="3" key={2}>
      High
    </option>,
  ];
};

export const renderStatusOptions = () => {
  return [
    <option value="new" key={0}>
      New
    </option>,
    <option value="in_process" key={1}>
      In Process
    </option>,
    <option value="flash_assessment_pending" key={2}>
      Flash Assessment
    </option>,
    <option value="canceled" key={3}>
      Canceled
    </option>,
    <option value="clarification_needed" key={4}>
      Clarification Needed
    </option>,
    <option value="ready_to_share" key={5}>
      Ready To Share
    </option>,
    <option value="completed" key={6}>
      Completed
    </option>,
  ];
};
