import React from 'react';

import _get from 'lodash/get';
import Moment from 'moment';

import { statusValueConstants as STATUS_CONST } from 'constants/DataJobDetailsConstants';
import { MIN_DATE } from 'constants/DateTimeConstants';

/**
 * Gets status label from code
 *
 * @param String status Status code
 * @returns String Status label
 */
const getStatusLabel = (status) => {
  let output = null;

  const statusLabels = {
    new: 'New',
    in_process: 'In Process',
    ready_for_approval: 'Ready for Approval',
    complete: 'Data Accepted',
    canceled: 'Canceled',
    closed: 'Closed',
  };

  if (status && statusLabels.hasOwnProperty(status)) {
    output = statusLabels[status];
  }

  return output;
};

/**
 * Gets date or interval label since date
 *
 * @param String dateString Date
 * @returns String Date label
 */
const getDateString = (dateString) => {
  let output = null;

  const momentCreatedAt = Moment(dateString);

  if (momentCreatedAt.isValid()) {
    if (Moment().diff(momentCreatedAt, 'days') >= 1) {
      output = momentCreatedAt.format(MIN_DATE);
    } else {
      output = momentCreatedAt.fromNow();
    }
  }

  return output;
};

/**
 * Truncate string if needed, and add ellipsis.
 *
 * @param String str
 * @returns String Truncated string with ellipsis
 */
const getEllipsisString = (str) => {
  let output = str;

  if (output && output.length > 50) {
    output = output.slice(0, 50);
    output = (
      <>
        {output}
        <span className="ellipsis">{!output.endsWith(' ') ? ' ' : null}(&hellip;)</span>
      </>
    );
  }

  return output;
};

/**
 * Truncate file name if needed, remove path from file name, and add ellipsis if > 25 chars
 *
 * @param String str
 * @returns String Truncated string with ellipsis
 */
const getTruncatedFileName = (str) => {
  // remove path
  if (!str) {
    return;
  }
  str = str.slice(str.lastIndexOf('/') + 1, str.length);
  let output = str;
  // truncate with ellipses
  if (output && output.length > 25) {
    const firstPart = str.slice(0, 15);
    const secondPart = str.slice(-10);

    output = (
      <>
        {firstPart}
        <span>(&hellip;)</span>
        {secondPart}
      </>
    );
  }

  return output;
};

// TODO: When BE endpoints are done, this function has to be revised:
// 1. We don't use type/subtype separately so if they are united in the BE, just remove the fullType concatenation.
/**
 * Transforms Notification items to renderable structures. Depending on notification type/subtype,
 * append various properties.
 *
 * @param {*} data Notification item
 * @param {integer} index Index
 * @returns Transformed data
 */
export const getTransformedData = (data) => {
  let output = null;

  if (data && data.notification && data.notification.type && data.notification.subtype) {
    output = {
      id: data.id,
      viewed: data.viewed,
      created_at: getDateString(data.notification.created_at),
      image: data.notification.image,
    };

    const fullType = `${data.notification.type}_${data.notification.subtype}`;
    const notifDetails = _get(data, 'notification.details') || null;

    const getArr = (obj) => (obj?.length && obj[0] ? obj[0] : null);

    if (fullType === 'data_job_data_job_created') {
      output.shortMessage = 'Data job created';
      output.message = <>A new Data Job was created. Click here to view it.</>;
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'flash_assessment_flash_assessment_published') {
      output.shortMessage = 'Flash assessment published';
      output.message = (
        <>
          A Flash Assessment was published for Data Job:{' '}
          <span className="data-job-title">
            {getEllipsisString(_get(notifDetails, 'data_job.name') || null)}
          </span>
          . Click here to view it.
        </>
      );
      output.url = `/flash-assessment/${_get(notifDetails, 'flash_assessment.id') || null}`;
    } else if (fullType === 'data_job_data_job_transformation_required_yes') {
      output.shortMessage = 'File uploaded (processing required)';
      output.message = (
        <>
          A File that requires processing has been uploaded to{' '}
          <span className="data-job-title">
            {getEllipsisString(_get(notifDetails, 'data_job.name') || null)}
          </span>
          . Click here to view it.
        </>
      );
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'data_job_data_job_transformation_required_no') {
      output.shortMessage = 'File uploaded';
      output.message = (
        <>
          A new File has been uploaded to{' '}
          <span className="data-job-title">
            {getEllipsisString(_get(notifDetails, 'data_job.name') || null)}
          </span>
          . Click here to view it.
        </>
      );
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'data_job_data_job_attachment_comment_added') {
      output.shortMessage = 'Comment added to attachment';
      output.message = (
        <>
          A new comment has been added to an attachment in{' '}
          <span className="data-job-title">
            {getEllipsisString(_get(notifDetails, 'data_job.name') || null)}
          </span>
          . Click here to view it.
        </>
      );
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'data_job_data_job_transformed_file_accepted') {
      output.shortMessage = 'Processed file accepted';
      output.message = (
        <>
          A processed file has been Accepted in{' '}
          <span className="data-job-title">
            {getEllipsisString(_get(notifDetails, 'data_job.name') || null)}
          </span>
          . Click here to view it.
        </>
      );
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'data_job_data_job_transformed') {
      output.shortMessage = 'Transformed file uploaded';
      output.message = (
        <>
          Transformed file uploaded in{' '}
          <span className="data-job-title">
            {getEllipsisString(_get(notifDetails, 'data_job.name') || null)}
          </span>
          . Click here to view it.
        </>
      );
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'data_job_data_job_comment_created') {
      output.shortMessage = 'Comment added to data job';
      output.message = (
        <>
          A new comment has been added to{' '}
          <span className="data-job-title">
            {getEllipsisString(_get(notifDetails, 'data_job.name') || null)}
          </span>
          . Click here to view it.
        </>
      );
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'data_job_data_job_type_subtype_change') {
      output.shortMessage = 'Data job type/subtype changed';
      output.message = (
        <>
          Data job type/subtype changed for{' '}
          <span className="data-job-title">
            {getEllipsisString(_get(notifDetails, 'data_job.name') || null)}
          </span>
          . Click here to view it.
        </>
      );
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'data_job_data_job_status_update') {
      const status = _get(notifDetails, 'data_job.status');
      if (status === 'accepted') {
        output.shortMessage = 'Data job complete';
        output.message = <>Your data job is COMPLETE! Click here to view it.</>;
      } else if (
        [STATUS_CONST.CLOSED, STATUS_CONST.CANCELED, STATUS_CONST.REJECTED].includes(
          notifDetails?.old_status
        ) &&
        ![STATUS_CONST.CLOSED, STATUS_CONST.CANCELED, STATUS_CONST.REJECTED].includes(
          notifDetails?.data_job?.status
        )
      ) {
        output.shortMessage = 'Data job reopened';
        output.message = (
          <>
            Data job{' '}
            <span className="data-job-title">{_get(notifDetails, 'data_job.name') || null}</span>{' '}
            has been reopened and set to status{' '}
            <span className="data-job-status">
              {getStatusLabel(_get(notifDetails, 'data_job.status') || null)}
            </span>
            . Click here to view it.
          </>
        );
      } else {
        output.shortMessage = 'Data job status changed';
        output.message = (
          <>
            Status of{' '}
            <span className="data-job-title">{_get(notifDetails, 'data_job.name') || null}</span>{' '}
            was changed to{' '}
            <span className="data-job-status">
              {getStatusLabel(_get(notifDetails, 'data_job.status') || null)}
            </span>
            . Click here to view it.
          </>
        );
      }
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (fullType === 'data_job_data_job_rejected') {
      output.shortMessage = 'Data job rejected';
      output.message = (
        <>
          Data job{' '}
          <span className="data-job-title">{_get(notifDetails, 'data_job.name') || null}</span>{' '}
          has been rejected by{' '}
          <span className="data-job-status">{_get(notifDetails, 'triggered_by')}</span>. Click here
          to view it.
        </>
      );
      output.url = `/data-request/${_get(notifDetails, 'data_job.id') || null}`;
    } else if (data.notification.type === 'ingestion') {
      // -------------------------------------------------- INGESTION NOTIFICTAIONS -------------------------------------------------- //

      if (data.notification.subtype === 'ready_for_validation') {
        output.shortMessage = 'File finished processing';
        output.message = (
          <>
            Your file for{' '}
            <span className="data-job-title">
              {getEllipsisString(getArr(notifDetails)?.data_job_name || null)}
            </span>{' '}
            has finished processing. Click here to verify the ingestion data.
          </>
        );
      } else if (data.notification.subtype === 'column_validation') {
        output.shortMessage = 'File finished processing';
        output.message = (
          <>
            Your file for{' '}
            <span className="data-job-title">
              {getEllipsisString(getArr(notifDetails)?.data_job_name || null)}
            </span>{' '}
            has finished processing. Click here to continue verifying ingestion data.
          </>
        );
      } else if (data.notification.subtype === 'complete') {
        output.shortMessage = 'Assessment finished processing';
        output.message = (
          <>
            The assessment of{' '}
            <span className="data-job-title">
              {getTruncatedFileName(getArr(notifDetails)?.attachment_name || null)}
            </span>{' '}
            for the data job{' '}
            <span className="data-job-title">
              {' '}
              {getEllipsisString(getArr(notifDetails)?.data_job_name || null)}
            </span>{' '}
            has finished processing. Click here to see your data visualization.
          </>
        );
      } else if (data.notification.subtype === 'failed') {
        output.shortMessage = 'Unable to perform assessment';
        output.message = (
          <>
            Unable to perform the Assessment for{' '}
            <span className="data-job-title">
              {getTruncatedFileName(getArr(notifDetails)?.attachment_name || null)}
            </span>{' '}
            Click here to review.
          </>
        );
      }
      output.url = getArr(notifDetails)?.url;
    } else {
      return null;
    }
  } else {
    console.error('Invalid data', data);
    return null;
  }

  // TODO: Workaround for a very weird bug causing "missing key" warning only once when closing
  // the dropdown menu.
  output.renderMessage = () => output.message;

  return output;
};
