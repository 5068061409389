import React, { useEffect, useState } from 'react';

import _isEqual from 'lodash/isEqual';
import { Form } from 'react-bootstrap';
import { components } from 'react-select';

import * as ApiCalls from 'api/ApiCalls';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { useIsMounted } from 'helpers/useIsMounted';
import styleVars from 'scss/vars.scss';
import './DetailsBlock.scss';

const getSelectedTypeOption = (_dataJobTypes) => {
  let output = null;

  const parentType = _dataJobTypes?.find((item) => item?.parent?.id)?.parent ?? null;

  if (parentType) {
    output = {
      value: parentType.id,
      label: parentType.name,
    };
  }

  return output;
};

const getSelectedSubTypeOptions = (_dataJobTypes) => {
  let output = _dataJobTypes
    // TODO: Backend sometimes returns the type separately along with the subtypes. Probable BE bug
    ?.filter((item) => !!item?.parent?.id)
    ?.map((item) => {
      return { value: item.id, label: item.name };
    });

  if (!output?.length) {
    output = [];
  }

  return output;
};

const isEqProps = (prevProp, nextProp) => {
  return _isEqual(prevProp, nextProp);
};

const TypeSubTypeSelector = React.memo(({ value, onChange, isEditMode, validationMsg }) => {
  const isMounted = useIsMounted();

  const [djTypesData, setDjTypesData] = useState(null);
  const [djTypesDataStatus, setDjTypesDataStatus] = useState(ActionStatusConstants.INITIAL);

  const [typeOptions, setTypeOptions] = useState(null);
  const [subTypeOptions, setSubTypeOptions] = useState(null);

  const [selectedTypeLocal, setSelectedTypeLocal] = useState(null);

  useEffect(() => {
    setDjTypesDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/data-request-types/',
      params: { mode: 'nested' },
      onSuccess: (res) => {
        if (isMounted.current && res?.data) {
          setDjTypesDataStatus(ActionStatusConstants.SUCCESS);
          setDjTypesData(res.data);
        } else {
          setDjTypesDataStatus(ActionStatusConstants.FAILURE);
        }
      },
      onError: () => {
        setDjTypesDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }, [isMounted]);

  // Init dropdown options
  useEffect(() => {
    // Only update local Selected Type state if we have a value coming from DJ
    if (value?.length) {
      const selectedTypeOption = getSelectedTypeOption(value);
      setSelectedTypeLocal(selectedTypeOption);
    }
  }, [djTypesData, value]);

  // Init type options as soon as we have the list
  useEffect(() => {
    let _typeOptions = [];

    if (djTypesData?.length) {
      _typeOptions = djTypesData.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    }

    setTypeOptions(_typeOptions);
  }, [djTypesData]);

  // Get a list of sub-types depending on the local selected type state
  useEffect(() => {
    let _subTypeOptions = [];
    // Assuming all selected have the same parent, get from first selected item's parent

    if (djTypesData?.length && selectedTypeLocal?.value) {
      const parentId = selectedTypeLocal.value;
      const parentObj = djTypesData.find((item) => parentId === item.id);

      if (parentObj?.children?.length) {
        _subTypeOptions = parentObj.children.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      }
    }

    setSubTypeOptions(_subTypeOptions);
  }, [djTypesData, selectedTypeLocal]);

  const onChangeSubType = (v) => {
    let newValue = [];

    if (v?.length && selectedTypeLocal?.value) {
      const parentValue = {
        id: selectedTypeLocal.value,
        name: selectedTypeLocal.label,
        parent: null,
      };
      newValue = v.map((item) => {
        return {
          id: item.value,
          name: item.label,
          parent: parentValue,
        };
      });
    }
    onChange(newValue);
  };

  const onChangeType = (v) => {
    onChangeSubType([]);
    setSelectedTypeLocal(v);
  };

  const selectedSubTypeOptions = getSelectedSubTypeOptions(value);

  const ValueContainer = ({ children, ...props }) => {
    const selectedCount = props.selectProps.value.length || 0;
    const selectInput = React.Children.map(children, (child) =>
      child && [components.MultiValue].indexOf(child.type) === -1 ? child : null
    );

    return (
      <components.ValueContainer {...props}>
        {selectedCount} selected{selectInput}
      </components.ValueContainer>
    );
  };

  const isBusy = djTypesDataStatus === ActionStatusConstants.ISBUSY;

  const isInvalidType = !!(validationMsg && !selectedTypeLocal?.value);
  const isInvalidSubType = !!(validationMsg && !selectedSubTypeOptions?.length);

  const SelectType = () => {
    return (
      <StyledMultiselect
        values={selectedTypeLocal}
        options={typeOptions}
        setOnChange={onChangeType}
        isSearchable
        isDisabled={isBusy}
        isInvalid={isInvalidType}
        isMulti={false}
      />
    );
  };

  const SelectSubType = () => {
    return (
      <StyledMultiselect
        defaultValue={selectedSubTypeOptions}
        selectStyle={{
          option: (provided, state) => {
            return {
              color: state.isSelected && `${styleVars.colors_designBlack}`,
              display: 'inline-flex',
              backgroundColor: state.isFocused && `${styleVars.colors_designOrangeLight}`,
              ':before': {
                backgroundColor: state.isSelected
                  ? `${styleVars.colors_designGreen}`
                  : `${styleVars.colors_designWhite}`,
                borderRadius: 10,
                borderWidth: 1,
                display: 'inline-flex',
                content: '" "',
                marginRight: 8,
                marginTop: 'auto',
                marginBottom: 'auto',
                height: 10,
                width: 10,
              },
            };
          },
        }}
        options={subTypeOptions}
        setOnChange={onChangeSubType}
        backspaceRemovesValue={false}
        useOnBlur={false}
        isSearchable={false}
        closeMenuOnSelect={false}
        isMulti
        hideSelectedOptions={false}
        isDisabled={isBusy}
        isInvalid={isInvalidSubType}
        placeholder=""
        getOptionValue={(option) => option.value}
        customComponents={{
          ValueContainer,
        }}
      />
    );
  };

  return (
    <>
      <section className="type-block">
        <Form.Group className="input-field input-field-type" controlId="dataJob.type">
          <Form.Label>Type:</Form.Label>
          {isEditMode ? (
            <>
              <Form.Control as={SelectType} />
              <Form.Control.Feedback type="invalid">{validationMsg?.message}</Form.Control.Feedback>
            </>
          ) : (
            <Form.Control readOnly plaintext as="input" value={selectedTypeLocal?.label ?? ''} />
          )}
        </Form.Group>
      </section>
      <section className="subtype-block">
        <Form.Group className="input-field input-field-subtype" controlId="dataJob.subtype">
          <Form.Label>Sub-Type:</Form.Label>
          {isEditMode ? (
            <>
              <Form.Control as={SelectSubType} />
              <Form.Control.Feedback type="invalid">{validationMsg?.message}</Form.Control.Feedback>
            </>
          ) : (
            <Form.Control
              className="subtype-textarea"
              readOnly
              plaintext
              as="textarea"
              value={selectedSubTypeOptions?.map((item) => item.label)?.join(', ') ?? ''}
            />
          )}
        </Form.Group>
      </section>
    </>
  );
}, isEqProps);

export { TypeSubTypeSelector };
