import React from 'react';

import PropTypes from 'prop-types';

/**
 * Default body content template for DatabotConfigPanel.
 *
 * This is just a wrapper template to provide consistent className.
 *
 * Do NOT import this directly. Use it through the main component - <DatabotConfigPanel.Template[name] />
 *
 * @param {element} children Content
 * @return render
 */
const TemplateBodyContent = ({ children }) => {
  return <section className="databot-config-panel-body-content">{children}</section>;
};

TemplateBodyContent.defaultProps = {
  children: null,
};

TemplateBodyContent.propTypes = {
  children: PropTypes.node,
};

export { TemplateBodyContent };
