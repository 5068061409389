import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import ImgWithPlaceholder from 'components/ImgWithPlaceholder';
import { MIN_DATE } from 'constants/DateTimeConstants';
import * as Utils from 'helpers/Utils';

export default {
  columns: [
    {
      ...Utils.getDefaultTableColumnDef('name', 'Manufacturer'),
      formatter: (cell, row, rowIndex) => {
        console.debug('Table def, formatter', cell, row, rowIndex);
        return (
          <div className="supplier-name-block">
            <div className="supplier-logo">
              <ImgWithPlaceholder
                src={row && row.logo ? row.logo : null}
                placeholder={<FontAwesomeIcon icon={['far', 'box-full']} />}
              />
            </div>
            <div className="supplier-name">{row.name}</div>
          </div>
        );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('product_count', 'Product Count'),
    },
    {
      ...Utils.getDefaultTableColumnDef('sds_count', 'SDS Count'),
    },
    {
      ...Utils.getDefaultTableColumnDef('last_updated', 'Last Crawl'),
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <Moment format={MIN_DATE} date={row.last_updated} />
          </>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const dateA = new Date(a);
        const dateB = new Date(b);

        return order === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('last_crawled', 'New Products'),
    },
  ],
};
