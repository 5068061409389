import React from 'react';

import { Helmet } from 'react-helmet';

import { ImgBotEngineDetails } from 'components/img-bot-engine/ImgBotEngineDetails';

const ViewImgBotEngineDetails = ({ id }) => {
  return (
    <>
      <Helmet>
        <title>Image Databot Engine - Details</title>
      </Helmet>
      <div className="content content-fluid img-bot-engine-details">
        <ImgBotEngineDetails id={id} />
      </div>
    </>
  );
};

export { ViewImgBotEngineDetails };
