import './TutorialSheetRows.scss';
import React, { useState, useContext, useEffect, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as ApiCalls from 'api/ApiCalls';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { ProgressDots } from 'components/common/ProgressDots/ProgressDots';
import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { TUTORIALSTEPS } from 'constants/DataIngestionConstants';
import { useIsMounted } from 'helpers/useIsMounted';

/**
 * Runs the ingestion rows tutorial
 * @doVerify advances steps through tutorial
 * @returns object
 */

const TutorialSheetRows = ({ doVerify, restartTutorial, doStartOnboarding, setRowStep }) => {
  const { tutorial } = useContext(DataIngestionContext);
  const [visible, setVisible] = useState(true);
  const isMounted = useIsMounted();

  const calculateLocation = useCallback(async () => {
    // TODO find a new modal library built for what designs we're receiving(arrows and ability to set position)
    const modal = document.getElementsByClassName('modal-dialog')[0];
    if (modal !== undefined && tutorial.step === TUTORIALSTEPS.HEADER) {
      const target = await document.getElementsByClassName('selected-header');
      const location = target[0].getBoundingClientRect();
      const top = location.bottom - 25;
      const left = location.left + 450;
      modal.style.position = 'absolute';
      modal.style.top = `${top}px`;
      modal.style.left = `${left}px`;
      modal.className += ' arrow-top';
      modal.classList.remove('arrow-bottom');
    } else if (modal !== undefined && tutorial.step === TUTORIALSTEPS.FIRST_DATA_ROW) {
      const target = await document.getElementsByClassName('selected-first-row');
      const location = target[0].getBoundingClientRect();
      const top = location.bottom - 25;
      const left = location.left + 450;
      modal.style.position = 'absolute';
      modal.style.top = `${top}px`;
      modal.style.left = `${left}px`;
      modal.className += ' arrow-top';
    } else if (modal !== undefined && tutorial.step === TUTORIALSTEPS.SHEET) {
      const target = await document.getElementsByClassName('sheet-tab')[0];
      const location = target.getBoundingClientRect();
      const top = location.top - 240;
      const left = location.left + 200;
      modal.style.position = 'absolute';
      modal.style.top = `${top}px`;
      modal.style.left = `${left}px`;
      modal.className += ' arrow-bottom';
      modal.classList.remove('arrow-top');
    } else if (modal !== undefined && tutorial.step === TUTORIALSTEPS.ROWS_DONE) {
      modal.classList.remove('arrow-bottom');
    }
  }, [tutorial.step]);

  useEffect(() => {
    if (isMounted && tutorial.step) calculateLocation();
  }, [calculateLocation, tutorial, isMounted]);

  const stepHeader = () => {
    doVerify();
    tutorial.setStep(TUTORIALSTEPS.FIRST_DATA_ROW);
  };

  const stepData = () => {
    tutorial.setStep(TUTORIALSTEPS.SHEET);
  };

  const stepSheet = () => {
    tutorial.setStep(TUTORIALSTEPS.ROWS_DONE);
  };

  const finishTutorial = () => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/users/status`,
      data: { row_tutorial_done: true },
      onSuccess: () => {
        tutorial.setRowTutorialDone(true);
        setRowStep(0);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const startOnboarding = () => {
    finishTutorial();
    doStartOnboarding();
  };

  return (
    <>
      {tutorial.step === TUTORIALSTEPS.HEADER ? (
        <ModalPanel
          className="column-edit-panel ingestion-tutorial-modal"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          backdrop="static"
          keyboard={false}
          header="Confirm The Header Row"
          body={
            <div className="body-tutorial">
              Click the row with the header data. (We've predicted this row to be the header of this
              sheet)
            </div>
          }
          footer={
            <>
              <div className="progress-wrapper">
                <ProgressDots values={['header', 'first_data_row', 'sheet']} current="header" />
              </div>
              <div className="edit-footer">
                <button type="button" onClick={() => stepHeader()}>
                  <span className="oi oi-arrow-right" aria-hidden />
                  Next
                </button>
              </div>
            </>
          }
          centered={false}
        />
      ) : tutorial.step === TUTORIALSTEPS.FIRST_DATA_ROW ? (
        <ModalPanel
          className="column-edit-panel ingestion-tutorial-modal"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          backdrop="static"
          keyboard={false}
          header="Confirm The First Data Row"
          body={
            <div className="body-tutorial">
              Next, click the first row of data. Ignore blank rows. (We will predict which row is
              correct).
            </div>
          }
          footer={
            <>
              <div className="progress-wrapper">
                <ProgressDots
                  values={['header', 'first_data_row', 'sheet']}
                  current="first_data_row"
                />
              </div>
              <div className="edit-footer">
                <button
                  type="button"
                  onClick={() => {
                    stepData();
                  }}
                >
                  <span className="oi oi-arrow-right" aria-hidden />
                  Next
                </button>
              </div>
            </>
          }
          centered={false}
        />
      ) : tutorial.step === TUTORIALSTEPS.SHEET ? (
        <ModalPanel
          className="column-edit-panel ingestion-tutorial-modal"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          backdrop="static"
          keyboard={false}
          header="Review or Remove Each Sheet"
          body={
            <div className="body-tutorial">
              Use the <FontAwesomeIcon icon={['fas', 'times-circle']} size="sm" /> button to remove
              a sheet, or cycle through them by clicking on these tabs.
            </div>
          }
          footer={
            <>
              <div className="progress-wrapper">
                <ProgressDots values={['header', 'first_data_row', 'sheet']} current="sheet" />
              </div>
              <div className="edit-footer">
                <button
                  type="button"
                  onClick={() => {
                    stepSheet();
                  }}
                >
                  <span className="oi oi-check" aria-hidden />
                  Okay
                </button>
              </div>
            </>
          }
          centered={false}
        />
      ) : tutorial.step === TUTORIALSTEPS.ROWS_DONE ? (
        <ModalPanel
          className="column-edit-panel ingestion-tutorial-modal"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          backdrop="static"
          keyboard={false}
          header="Tutorial Complete!"
          body={
            <div className="body-tutorial">
              That's it for step 1 of onobarding! Click "Restart" to go through this tutorial again,
              or "Start Onboarding" to confirm data for your file.
            </div>
          }
          footer={
            <>
              <div className="edit-footer">
                <button
                  type="button"
                  className="tutorial-reload"
                  onClick={() => {
                    restartTutorial();
                  }}
                >
                  <span className="oi oi-reload" aria-hidden />
                  Restart
                </button>
                <button
                  type="button"
                  onClick={() => {
                    startOnboarding();
                  }}
                >
                  <span className="oi oi-check" aria-hidden />
                  Start Ingestion
                </button>
              </div>
            </>
          }
          centered={false}
        />
      ) : null}
    </>
  );
};

export { TutorialSheetRows };
