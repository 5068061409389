import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Overlay } from 'react-bootstrap';
import './AnalyticsBar.scss';

const AnalyticsBar = React.forwardRef((props, ref) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const { label, tooltip } = props;
  return (
    <div className="analytics-detail">
      <div className="detail-label">{label}</div>
      <div className="detail-tooltip">
        <button
          className="button-inherit"
          type="button"
          ref={ref}
          onClick={() => setShowTooltip(!showTooltip)}
        >
          <FontAwesomeIcon className="icon-circle" icon={['fas', 'info']} size="sm" />
        </button>
        <Overlay target={ref} show={showTooltip} placement="bottom">
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                backgroundColor: '#fff',
                padding: '2px 10px',
                borderRadius: 5,
                border: '1px solid #555',
                maxWidth: '33vw',
                ...props.style,
              }}
            >
              <p>{tooltip}</p>
            </div>
          )}
        </Overlay>
      </div>
      <div className="detail-values">
        <div className="detail-chart">
          <div className="chart-bar-valid" style={{ width: `${props.valid * 100}%` }} />
          <div
            className="chart-bar-fill"
            style={{ width: `${(props.fillRate - props.valid) * 100}%` }}
          />
        </div>
        <div className="detail-numbers">
          {`${(props.valid * 100).toFixed(2)}% Valid Values | ${(props.fillRate * 100).toFixed(
            2
          )}% Fill Rate`}
        </div>
      </div>
    </div>
  );
});

AnalyticsBar.propTypes = {
  props: PropTypes.shape({
    label: PropTypes.string,
    valid: PropTypes.float,
    fillRate: PropTypes.fillRate,
  }).isRequired,
  ref: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export { AnalyticsBar };
