/**
 * "label" key is the name of the section in the processed attributes table;
 * "columns" key are the rows of the section in the table. Anything in this will be displayed.
 */
export const generalAttributeSections = {
  PRODUCT_ID: {
    label: 'Production Identification',
    columns: [
      { key: 'description_new', name: 'Alternative Description', _column: true },
      { key: 'description_input_1', name: 'Alternate Description' },
      { key: 'description_input_2', name: 'Description 2' },
      { key: 'description_1', name: 'Alternative Description 1' },
      { key: 'description_2', name: 'Alternative Description 2' },
      { key: 'brand_name', name: 'Brand Name', _column: true },
    ],
  },
  CATEGORIZATION: {
    label: 'Categorization',
    columns: [{ key: 'taxonomy', name: 'Taxonomy', _column: true }],
  },
  ALT_DESCS: {
    label: 'Alternate Descriptions',
    columns: [
      { key: 'description_input_1', name: 'Alternate Description 1' },
      { key: 'description_input_2', name: 'Alternate Description 2' },
      { key: 'long_description', name: 'Long Description' },
    ],
  },
  MARKETING: {
    label: 'Marketing & eCommerce',
    columns: [{ key: 'features_and_benefits', name: 'Features and Benefits' }],
  },
  PRICE_INFO: {
    label: 'Price Information',
    columns: [
      { key: 'effective_date', name: 'Effective Date', _column: true },
      { key: 'expiration_date', name: 'Expiration Date', _column: true },
      { key: 'published_list_price', name: 'Published List Price' },
      { key: 'price_disc_grp_1', name: 'Price Discount Group 1' },
      { key: 'price_disc_grp_2', name: 'Price Discount Group 2' },
      { key: 'special_invoice', name: 'Special Invoice' },
      { key: 'spec_inv_multp', name: 'Special Invoice Multiplier' },
      { key: 'spec_inv_net_price', name: 'Special Invoice Net Price' },
      { key: 'specs_price_flag', name: 'Specs Price Flag' },
      { key: 'pub_oem_inv_price', name: 'Published OEM Invoice Price' },
    ],
  },
  PPS_OP_DATA: {
    label: 'Pick/Pack/Ship Operational Data',
    columns: [
      { key: 'sell_uom', name: 'Selling Unit of Measure' },
      { key: 'min_order_qty', name: 'Minimum Order Quantity' },
      { key: 'order_incr_qty', name: 'Order Increment Quantity' },
      { key: 'stock_flag', name: 'Stock Flag' },
      { key: 'return_flag', name: 'Return Flag' },
      { key: 'obsolete_flag', name: 'Obsolete Flag' },
      { key: 'lead_time', name: 'Estimated Lead Time', _column: true },
      { key: 'country_1', name: 'Country Of Origin' },
      { key: 'country_2', name: 'Country Of Origin 2' },
      { key: 'country_3', name: 'Country Of Origin 3' },
      { key: 'country_4', name: 'Country Of Origin 4' },
      { key: 'country_5', name: 'Country Of Origin 5' },
      { key: 'unspc', name: 'UNSPC', _column: true },
      { key: 'harmonized_tariff', name: 'Harmonized Tariff', _column: true },
      { key: 'eccn', name: 'ECCN (Export Control Classification Number)', _column: true },
    ],
  },
  PKG: {
    label: 'Packaging',
    columns: [
      { key: 'item_length', name: 'Item Length' },
      { key: 'item_width', name: 'Item Width' },
      { key: 'item_height', name: 'Item Height' },
      { key: 'dim_uom', name: 'Dimensions UOM' },
      { key: 'total_weight', name: 'Total Weight' },
      { key: 'unit_weight_uom', name: 'Unit Weight UOM' },
      { key: 'case_qty', name: 'Case Quantity' },
      { key: 'case_uom', name: 'Case UOM' },
      { key: 'case_weight', name: 'Case Weight' },
      { key: 'case_weight_uom', name: 'Case Weight UOM' },
      { key: 'case_dim_uom', name: 'Case Dimensions UOM' },
      { key: 'case_length', name: 'Case Length' },
      { key: 'case_width', name: 'Case Width' },
      { key: 'case_height', name: 'Case Height' },
      { key: 'carton_qty', name: 'Carton Quantity' },
      { key: 'carton_uom', name: 'Carton UOM' },
      { key: 'carton_weight', name: 'Carton Weight' },
      { key: 'carton_weight_uom', name: 'Carton Weight UOM' },
      { key: 'carton_dim_uom', name: 'Carton Dimensions UOM' },
      { key: 'carton_length', name: 'Carton Length' },
      { key: 'carton_width', name: 'Carton Width' },
      { key: 'carton_height', name: 'Carton Height' },
    ],
  },
  PRODUCT_LIFECYCLE: {
    label: 'Product Lifecycle',
    columns: [
      { key: 'super_item', name: 'Superseded Item' },
      { key: 'super_upc', name: 'Superseded UPC' },
      { key: 'subs_item', name: 'Substitute Item' },
      { key: 'subs_upc', name: 'Substitute UPC' },
    ],
  },
  HAZMAT: {
    label: 'Hazmat/Compliance/Regulatory',
    columns: [
      { key: 'msds_id', name: 'MSDS (Material Safety Data Sheet) ID', _column: true },
      { key: 'un_number', name: 'UN Code' },
      { key: 'un_proper_shipping_name', name: 'Proper Shipping Name' },
      { key: 'hazard_statements', name: 'Hazard Statement' },
      { key: 'signal_word', name: 'Signal Word' },
      { key: 'prop65', name: 'California Proposition 65 Information' },
      { key: 'disposal', name: 'Disposal Information' },
      { key: 'storage', name: 'Storage' },
      { key: 'handling', name: 'Handling' },
    ],
  },
  ADDITIONAL_DATA: {
    label: 'Additional Data',
    columns: [],
  },
};

/**
 * Similar to the comment above, the columns found here will still be evaluated when morphing data, but will _not_ be displayed in the table.
 */
export const generalAttributeColumns = [
  { key: 'flags', name: 'Flags', _column: true },
  { key: 'order', name: 'Order', _column: true },
  { key: 'sell_qty', name: 'Selling Quantity' },
  { key: 'price', name: 'Price' },
  { key: 'country_of_origin', name: 'Country Of Origin', _column: true },
  { key: 'marketing', name: 'Marketing' },
  { key: 'price_info', name: 'Price Information', _column: true },
  { key: 'other', name: 'Other' },
  { key: 'new_item', name: 'New Item' },
  { key: 'currency', name: 'Currency' },
  { key: 'substitute', name: 'Substitute', _column: true },
  { key: 'item_specs', name: 'Item Specifications' },
  { key: 'superseded', name: 'Superseded', _column: true },
  { key: 'price_discount', name: 'Price Discount' },
  { key: 'case_specs', name: 'Case Specifications' },
  { key: 'carton_specs', name: 'Carton Specifications' },
  { key: 'specs', name: 'Specifications', _column: true },
  { key: 'regulatory_data', name: 'Regulatory Data', _column: true },
  { key: 'sds_data', name: 'SDS Data' },
  { key: 'transport_information', name: 'Transportation' },
  { key: 'dot', name: 'DOT' },
  { key: 'transport_hazard_classes', name: 'Transport Hazard Classes' },
  { key: 'subsidiary_risk', name: 'Subsidiary Risk' },
  { key: 'attributes', name: 'Attributes', _column: true },
];

export const PRODUCT_ASSET_TYPES = {
  SDS_TRANSPORT: 'sds_transport',
  FILE: 'file',
  PRIMARY_IMAGE: 'primary_image',
  CAD_2D: '2d_cad',
  IMAGE: 'image',
  SDS: 'sds',
  CAD_3D: '3d_cad',
  IMAGE_360: '360_image',
  PRODUCT_PAGE: 'product_page',
  VIDEO: 'video',
};

export const PRODUCT_ASSET_LABELS = {
  SDS_TRANSPORT: 'SDS Transport',
  FILE: 'File',
  PRIMARY_IMAGE: 'Primary Image',
  CAD_2D: 'CAD (2D)',
  IMAGE: 'Image',
  SDS: 'SDS',
  CAD_3D: 'CAD (3D)',
  IMAGE_360: '360 Image',
  PRODUCT_PAGE: 'Product Page',
  VIDEO: 'Video',
};
