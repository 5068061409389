import React from 'react';

import { Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { DistributorItemsExportPanel } from 'components/products-export/DistributorItemsExportPanel';
import { ExportFunctionalityAdvanced } from 'components/products-export/export-functionality-advanced/ExportFunctionalityAdvanced';
import { ExportFunctionalityPanel } from 'components/products-export/ExportFunctionalityPanel';
import { ImportFunctionalityPanel } from 'components/products-import/ImportFunctionalityPanel';
import { RootHooks } from 'helpers/RootHooks';
import * as UserUtils from 'helpers/UserUtils';

import './ViewProductsExport.scss';

function ViewProductsExport() {
  const { activeUser } = RootHooks.useActiveUser();
  const { featureFlags } = RootHooks.useFeatureFlags();
  const isAdvancedExportEnabled = featureFlags.ENABLE_ADVANCED_PRODUCT_EXPORT;
  return (
    <div className="content content-fluid view-products-export">
      <Helmet bodyAttributes={{ 'data-page': 'view-products-export' }}>
        <title>Import Export Functionality</title>
      </Helmet>
      <Tabs defaultActiveKey="1" mountOnEnter unmountOnExit>
        <Tab eventKey="1" title="Export Functionality">
          <ExportFunctionalityPanel />
        </Tab>
        <Tab eventKey="2" title="Distributor Item Profile">
          <DistributorItemsExportPanel />
        </Tab>
        {isAdvancedExportEnabled && (
          <Tab eventKey="3" title="Export Functionality - Advanced">
            <ExportFunctionalityAdvanced />
          </Tab>
        )}

        {UserUtils.isRoleAdmin(activeUser) && (
          <Tab eventKey="4" title="Import Functionality">
            <ImportFunctionalityPanel />
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export { ViewProductsExport };
