import './MetricsTable.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const MetricsTable = ({
  metrics,
  columns,
  handleRowSelected,
  keyField = 'backbone_id',
  header,
  baseProps = {},
  usePagination = false,
  showViewAll = true,
  className,
  defaultSorted,
}) => {
  /**
   * Specifies how many metrics should be shown by default/collapsed
   */
  const defaultMetricsShown = 5;
  const defaultPage = 1;
  const defaultPageSize = 10;
  const [shownMetrics, setShownMetrics] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [viewingAll, setViewingAll] = useState(false);
  const [tableRef, setTableRef] = useState({
    paginationContext: {
      currPage: defaultPage,
      currSizePerPage: defaultPageSize,
    },
  });

  useEffect(() => {
    if (!showPagination) return;
    tableRef.paginationContext.currPage = defaultPage;
    tableRef.paginationContext.currSizePerPage = defaultPageSize;
  }, [columns, tableRef, showPagination]);

  useEffect(() => {
    setShowPagination(usePagination);
    setShownMetrics(
      metrics.length && showViewAll && !usePagination
        ? metrics.slice(0, defaultMetricsShown)
        : metrics
    );
    if (metrics?.length === 0) setShowPagination(false);
  }, [metrics, showViewAll, usePagination]);

  const handleViewAll = () => {
    setViewingAll(true);
    setShownMetrics(metrics);
  };

  return (
    <div
      className={classNames('metrics-table', className, {
        'metrics-table--selectable': !!handleRowSelected,
      })}
    >
      {header && <div className="metrics-table-header">{header}</div>}
      <BootstrapTable
        {...baseProps}
        pagination={
          showPagination
            ? paginationFactory({
                showTotal: true,
              })
            : null
        }
        rowEvents={handleRowSelected ? { onClick: (_e, row) => handleRowSelected(row) } : null}
        bordered={false}
        defaultSorted={defaultSorted}
        ref={(n) => setTableRef(n)}
        bootstrap4
        keyField={keyField}
        data={shownMetrics}
        columns={columns.columns}
      />
      {!metrics?.length && !showPagination && <div className="metrics-table-footer">No data.</div>}
      {showViewAll && !showPagination && metrics.length > defaultMetricsShown && !viewingAll && (
        <button className="metrics-table-expand" type="button" onClick={handleViewAll}>
          View All
        </button>
      )}
    </div>
  );
};

export { MetricsTable };
