import './AdditionalInformation.scss';

import React, { useCallback, useEffect, useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { BAIDueDatePicker } from 'components/common/DueDate/DueDate';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { DataJobLinkButton } from 'components/data-job/data-jobs/DataJobLinkButton';
import { DistributorJobLinks } from 'components/distributor-job/DistributorJobLinks/DistributorJobLinks';
import { DistributorJobLinkSearch as JobLinkSearch } from 'components/distributor-job/DistributorJobLinkSearch/DistributorJobLinkSearch';
import DataJobCreateConstants from 'constants/DataJobCreateConstants';
import * as DataJobDetailsConstants from 'constants/DataJobDetailsConstants';
import { formatDate8601 } from 'helpers/TimeUtils';

const AdditionalInformation = ({ Footer, createFormErrors, dispatch, createForm }) => {
  const [isLoading] = useState(false);
  const [reqTypes, setReqTypes] = useState([]);
  const [deliveryFormats, setDeliveryFormats] = useState([]);
  const [selectedType, setSelectedType] = useState([createForm.dataRequestTypeId]);
  const [selectedSubtypes, setSelectedSubtypes] = useState(createForm.dataRequestSubtypeIds);
  const [selectedFormats, setSelectedFormats] = useState(
    createForm.expectedDeliveryFormatIds || null
  );
  const [selectedPriority, setSelectedPriority] = useState(createForm.priority || null);
  const [showJobLinks, setShowJobLinks] = useState(false);

  // Delivery Format Dropdown
  const getDeliveryFormats = () => {
    let output = null;

    if (deliveryFormats) {
      output = deliveryFormats.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }
    return output;
  };

  // Job Type Dropdown
  const getReqTypeOptions = useCallback(
    () =>
      (reqTypes && reqTypes.map((item) => ({ value: item.id, label: item.name, obj: item }))) || [],
    [reqTypes]
  );

  const onReqTypeSelected = (value) => {
    setSelectedType([value]);
    setSelectedSubtypes();
    dispatch({
      type: DataJobCreateConstants.JOBTYPE,
      payload: value,
    });
  };

  // Job Subtype Dropdown
  const getReqSubtypeOptions = useCallback(
    () =>
      (selectedType[0] &&
        selectedType[0].obj.children.map((item) => ({
          value: item.id,
          label: item.name,
          obj: item,
        }))) ||
      [],
    [selectedType]
  );

  const onReqSubtypeSelected = (value) => {
    setSelectedSubtypes(value);
    dispatch({
      type: DataJobCreateConstants.SUBTYPE,
      payload: value,
    });
  };

  const onFormatSelected = (value) => {
    setSelectedFormats(value);
    dispatch({
      type: DataJobCreateConstants.DELIVERYFORM,
      payload: value,
    });
  };

  const onPrioritySelected = (value) => {
    setSelectedPriority(value);
    dispatch({
      type: DataJobCreateConstants.PRIORITY,
      payload: value,
    });
  };

  const reqTypeOptions = getReqTypeOptions();
  const reqSubtypeOptions = getReqSubtypeOptions();
  const deliveryFormatsOptions = getDeliveryFormats();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // get Data request Types
    const apiDataType = {
      urlPath: `/data-request-types/?mode=nested`,
      method: ApiCalls.HTTP_METHODS.GET,
      onSuccess: (res) => {
        setReqTypes(res.data);
      },
    };
    ApiCalls.doCall(apiDataType);
  }, []);

  useEffect(() => {
    // get Delivery Format Types
    const apiFormat = {
      urlPath: `/data-requests/delivery-formats`,
      method: ApiCalls.HTTP_METHODS.GET,
      onSuccess: (res) => {
        setDeliveryFormats(res.data);
      },
    };
    ApiCalls.doCall(apiFormat);
  }, []);

  const linkDataJobClassName = () => {
    if (createForm.linkedDataJob.length) {
      return 'data-job-create-job-details-box-link-data-box';
    }
    return 'mx-1';
  };

  // Build dropdown selects
  // maxHeight set here so that they single value component containers don't expand to fill when multivalue dropdowns have more than one line of values.<F9>
  const SelectJobType = () => {
    return (
      <StyledMultiselect
        id="inpType"
        values={selectedType || []}
        options={getReqTypeOptions()}
        setOnChange={onReqTypeSelected}
        isSearchable
        menuPlacement="auto"
        menuPortalTarget={document.body}
        isMulti={false}
        isDisabled={!(reqTypeOptions && reqTypeOptions.length) || isLoading}
        isInvalid={!!createFormErrors.dataRequestTypeId}
        selectWrapperStyle={{ maxHeight: '3rem' }}
      />
    );
  };

  const SelectJobSubtype = () => {
    return (
      <StyledMultiselect
        id="inpSubtype"
        defaultValue={selectedSubtypes}
        options={getReqSubtypeOptions()}
        setOnChange={onReqSubtypeSelected}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        isDisabled={!(reqSubtypeOptions && reqSubtypeOptions.length) || isLoading}
        isInvalid={!!createFormErrors.dataRequestSubtypeIds}
        isMulti
        closeMenuOnSelect={false}
        isSearchable
        placeholder={selectedType[0] === null ? `Select a job type first...` : `Select...`}
      />
    );
  };

  const SelectPriority = () => (
    <StyledMultiselect
      id="inpPriority"
      values={selectedPriority}
      options={[
        { value: '1', label: 'Low' },
        { value: '2', label: 'Medium' },
        { value: '3', label: 'High' },
      ]}
      setOnChange={onPrioritySelected}
      isDisabled={isLoading}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      isMulti={false}
      isSearchable
      selectWrapperStyle={{ maxHeight: '3rem' }}
    />
  );

  const SelectFormat = () => (
    <StyledMultiselect
      id="inpFormat"
      defaultValue={selectedFormats}
      options={deliveryFormatsOptions}
      setOnChange={onFormatSelected}
      isDisabled={!(deliveryFormatsOptions && deliveryFormatsOptions.length) || isLoading}
      isMulti
      menuPlacement="auto"
      menuPortalTarget={document.body}
      closeMenuOnSelect={false}
      isSearchable
      isClearable
    />
  );

  return (
    <div className="data-job-additional-items-details-box">
      <Row>
        <div className="data-job-additional-items-title">
          <h2>Additional Information</h2>
          <h3>* Required fields</h3>
        </div>
      </Row>
      <Row>
        <Col>
          <Form className="flex-form-vertical-labels data-job-additional-details-right">
            <div className="details-top">
              <Col sm={{ order: 1 }}>
                <Row>
                  <div className="flex-group" style={{ order: 1 }}>
                    <Form.Label htmlFor="inpType">Job Type *</Form.Label>
                    <Form.Control as={SelectJobType} />
                    <Form.Control.Feedback type="invalid">
                      Please select a job type!
                    </Form.Control.Feedback>
                  </div>
                  <div className="flex-group" style={{ order: 2 }}>
                    <Form.Label htmlFor="inpSubtype">Job Subtype *</Form.Label>
                    <Form.Control as={SelectJobSubtype} />
                    <Form.Control.Feedback type="invalid">
                      Please select a job subtype!
                    </Form.Control.Feedback>
                  </div>
                </Row>
                <Row hidden={createForm.dataJobDirection === 2} className="nowrap">
                  <div className="flex-group" style={{ order: 1 }}>
                    <Form.Label htmlFor="inpPriority">Requested Priority</Form.Label>
                    <Form.Control as={SelectPriority} />
                  </div>
                  <div className="flex-group" style={{ order: 2 }}>
                    <Form.Label htmlFor="inpFormat">Delivery Format</Form.Label>
                    <Form.Control as={SelectFormat} />
                  </div>
                </Row>
                <Row>
                  <div style={{ width: createForm.dataJobDirection === 2 ? '50%' : '100%' }}>
                    <div className={linkDataJobClassName()}>
                      <Form.Label
                        className="data-job-create-link-search-title"
                        htmlFor="inpDataLink"
                      >
                        Link Data Job
                      </Form.Label>
                      <DataJobLinkButton
                        onClick={() => setShowJobLinks(true)}
                        hidden={showJobLinks}
                      />
                      <div hidden={!showJobLinks}>
                        <JobLinkSearch
                          id="inpDataLink"
                          onEdit={(v) =>
                            dispatch({ type: DataJobCreateConstants.LINKEDJOB, payload: v })
                          }
                          data={createForm.linkedDataJob}
                          viewingId=""
                          className="data-job-create-job-link-modal-search-input"
                        />
                        <DistributorJobLinks
                          data={createForm.linkedDataJob}
                          onUpdate={(v) =>
                            dispatch({ type: DataJobCreateConstants.LINKEDJOB, payload: v })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
            </div>
          </Form>
        </Col>
        <Col hidden={createForm.dataJobDirection === 2} sm={{ order: 2 }}>
          <Form className="flex-form-vertical-labels">
            <div className="details-top">
              <Row>
                <div className="flex-group" style={{ order: 1 }}>
                  <Form.Label htmlFor="inpDueDate">Requested Due Date</Form.Label>
                  <Form.Control
                    id="inpDueDatePicker"
                    as={BAIDueDatePicker}
                    placeholderText="Select a date..."
                    style={{ height: '38px' }}
                    readOnly={false}
                    isClearable
                    dueDate={createForm.dueDate}
                    minDate={moment().toDate()}
                    editable
                    onEdit={(e) =>
                      dispatch({
                        type: DataJobCreateConstants.DUEDATE,
                        payload: !Number.isNaN(Date.parse(e)) ? formatDate8601(e) : null,
                      })
                    }
                    offsetWithWeekends={false}
                    minDaysOffset={14}
                    showMinDateHighlight
                  >
                    <div className="distributor-job-details-box-value-input__flavor-text">
                      The average data job takes{' '}
                      {DataJobDetailsConstants.STANDARD_PROCESSING_TIME_DAYS_NOTE} business days. If
                      you would like your data job processed more quickly, please reach out to us at{' '}
                      <a
                        href={`mailto:data@backbone.ai?subject=Assistance Required - Data Job Due Date&body=Assistance is requested from backbone.ai team in processing this Data Job more quickly than ${DataJobDetailsConstants.STANDARD_PROCESSING_TIME_DAYS_NOTE} days.`}
                      >
                        data@backbone.ai
                      </a>
                    </div>
                  </Form.Control>
                </div>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
      <div className="data-job-create-details-footer">
        <Footer />
      </div>
    </div>
  );
};

AdditionalInformation.propTypes = {
  createForm: PropTypes.object.isRequired,
  createFormErrors: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  Footer: PropTypes.func.isRequired,
};

export { AdditionalInformation };
