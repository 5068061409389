import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';

import * as Utils from 'helpers/Utils';

const reqPricingData = [
  {
    attr: 'Currency',
    descr: 'ISO Standard record of currency',
    transf: 'If no Currency is found, USD will be applied',
  },
  {
    attr: 'Published List Price (MSRP)',
    descr: 'Published manufacturer suggested retail price for a product',
    transf: 'If no Published List Price is found, NULL will be applied',
  },
  {
    attr: 'Published OEM Invoice Price',
    descr: 'Published original equipment manufacturer invoice price',
    transf: 'If no Published OEM Invoice Price is found, NULL will be applied',
  },
  {
    attr: 'Resale Net Cost Price',
    descr: 'Distributor cost price for item unit',
    transf: 'If no Resale Net Cost Price is found, NULL will be applied',
  },
  {
    attr: 'Publish Effective Date',
    descr: 'Date the price goes into effect',
    transf: 'If no Publish Effective Date is found, NULL will be applied',
  },
  {
    attr: 'Expiration Date',
    descr: 'Date the price expires',
    transf: 'If no Expiration Date is found, NULL will be applied',
  },
  {
    attr: 'Selling Unit of Measure',
    descr: 'How many products are being sold at the published list price',
    transf: 'If no Selling Unit of Measure is found, EA will be applied',
  },
  {
    attr: 'Selling Quantity',
    descr: 'How many products the manufacturer is selling',
    transf: 'If no Selling Quantity is found, 1 will be applied',
  },
  {
    attr: 'Minimum Order Quantity',
    descr: 'The minimum amount of products for an order',
    transf: 'If no Minimum Order Quantity is found, 1 will be applied',
  },
  {
    attr: 'Order Increment Quantity',
    descr: 'The number a distributor is allowed to order',
    transf: 'If no Order Increment Quantity is found, 1 will be applied',
  },
];

const optPricingData = [
  {
    attr: 'Carton Unit of Measure',
    descr: 'How many products are in a carton',
    transf: 'If no Carton Unit of Measure is found, NULL will be applied',
  },
  {
    attr: 'Carton Quantity',
    descr: 'Number of cartons',
    transf: 'If no Carton Quantity is found, NULL will be applied',
  },
  {
    attr: 'Case Unit of Measure',
    descr: 'How many products are in a case',
    transf: 'If no Case Unit of Measure is found, NULL will be applied',
  },
  {
    attr: 'Case Quantity',
    descr: 'Number of cases',
    transf: 'If no Case Quantity is found, NULL will be applied',
  },
];

const tableDef = [
  {
    ...Utils.getDefaultTableColumnDef('attr', 'Attribute'),
    sort: false,
  },
  {
    ...Utils.getDefaultTableColumnDef('descr', 'Description'),
    sort: false,
  },
  {
    ...Utils.getDefaultTableColumnDef('transf', 'Transformation'),
    sort: false,
  },
];

/**
 * Static pricing info for Pricing Validation bot
 *
 * @return render
 */
const PricingDataInfoSection = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="pricing-data-info-section">
      <div className="heading">
        <div className="title">What is Pricing Data?</div>

        <div className="actions">
          <button
            type="button"
            title={isExpanded ? 'Collapse' : 'Expand'}
            className="btn btn-secondary btn-sm action action-expand"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Collapse' : 'Expand'}
            <span className="icon">
              <FontAwesomeIcon icon={['fas', `chevron-double-${isExpanded ? 'up' : 'down'}`]} />
            </span>
          </button>
        </div>
      </div>
      <p>
        BackboneAI considers the following attributes &quot;Pricing Data&quot;. Required pricing
        data is industry standard attributes that we normalize on your BackboneAI database to
        quickly send pricing data to your distributors. Optional Pricing Data is inventory data that
        can be associated with the Published List Price (MSRP).
      </p>
      <div
        className={classNames('pricing-data-tables', {
          expanded: isExpanded,
        })}
      >
        <div className="pricing-data-table">
          <div className="title">Required Pricing Data</div>
          <BootstrapTable bootstrap4 keyField="attr" data={reqPricingData} columns={tableDef} />
        </div>
        <div className="pricing-data-table">
          <div className="title">Optional Pricing Data</div>
          <BootstrapTable bootstrap4 keyField="attr" data={optPricingData} columns={tableDef} />
        </div>
      </div>
    </div>
  );
};

export { PricingDataInfoSection };
