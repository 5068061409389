import './Bots.scss';

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const BotRanIcon = ({
  bot,
  withTooltip = false,
  tooltipText = '',
  tooltipPlacement = 'top',
  icon,
}) => {
  const baseComponent = () => (
    <div className="bot-ran-icon">{icon || <FontAwesomeIcon icon={['far', 'cubes']} />
  }</div>
  );

  return withTooltip ? (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={<Tooltip bsPrefix="bai-tooltip tooltip">{tooltipText}</Tooltip>}
    >
      {baseComponent()}
    </OverlayTrigger>
  ) : (
    baseComponent()
  );
};

export { BotRanIcon };
