import React from 'react';

import PropTypes from 'prop-types';

import { ValueBlock } from 'components/common/ValueBlock/ValueBlock';

/**
 * Default heading stats template for DatabotConfigPanel.
 *
 * Passing children to this component overrides default UI and just keeps the wrapper.
 *
 * Do NOT import this directly. Use it through the main component - <DatabotConfigPanel.Template[name] />
 *
 * @param {array} onRun Array of { label, value, tooltip? } objects for the top summary
 * @param {element} children For completely custom template, keeps only the template wrapper and renders whatever is passed here
 * @return render
 */
const TemplateHeadingStats = ({ data, children }) => {
  const renderStats = () => {
    let output = null;
    if (data?.length) {
      const dataItems = data.map((item, index) => (
        <ValueBlock
          as={<li />}
          className="data-item"
          key={index}
          title={item.label}
          value={item.value}
          tooltip={item.tooltip}
        />
      ));

      output = <ul className="data-items">{dataItems}</ul>;
    }

    return output;
  };

  return (
    <section className="databot-config-panel-heading-stats">{children ?? renderStats()}</section>
  );
};

TemplateHeadingStats.defaultProps = {
  data: null,
  children: null,
};

TemplateHeadingStats.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      tooltip: PropTypes.string,
    })
  ),
  children: PropTypes.node,
};

export { TemplateHeadingStats };
