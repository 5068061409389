import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { ImageQualityAssessmentsFaqFeedback } from './ImageQualityAssessmentsFaqFeedback';

const ImageQualityAssessmentsFaqItem = ({ data, companyId, type }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <div
      className={classNames('image-quality-assessments-faq-item', {
        collapsed: !isExpanded,
      })}
    >
      <div className="heading">
        <div className="title">{data.title}</div>
        <div className="actions">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="btn btn-secondary btn-expand"
            type="button"
          >
            {isExpanded ? 'Collapse' : 'Expand'}
            <FontAwesomeIcon
              style={{ marginLeft: '1em' }}
              icon={['fas', isExpanded ? 'chevron-double-up' : 'chevron-double-down']}
            />
          </button>
        </div>
      </div>
      <div className="content">
        <div className="image">
          <img src={data.image} alt={data.title} />
          <div className="image-caption">{data.imageCaption}</div>
        </div>
        <div className="description">
          <div className="descr-text">{data.description}</div>
          <div className="actions">
            <ImageQualityAssessmentsFaqFeedback
              additionalData={{ title: data.title, manufacturer_id: companyId, type }}
            >
              {({ setIsVisible }) => (
                <button
                  type="button"
                  className="btn btn-secondary btn-give-feedback"
                  onClick={() => setIsVisible(true)}
                >
                  <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={['fas', 'bullhorn']} />
                  Give Feedback
                </button>
              )}
            </ImageQualityAssessmentsFaqFeedback>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ImageQualityAssessmentsFaqItem };
