import React from 'react';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotApplyingChanges = () => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="applying-changes"
      middle={
        <div className="description">
          This databot is applying your changes. Please check back later.
        </div>
      }
    />
  );
};

export { DatabotApplyingChanges };
