import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { DropdownButton } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { FeedbackModalPanel } from 'components/common/FeedbackModalPanel/FeedbackModalPanel';
import { ToggleSwitch } from 'components/common/ToggleSwitch/ToggleSwitch';
import { sortAttributeSections } from 'helpers/ProductUtils';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { UserUtils } from 'helpers/UserUtils';

// TODO: Potentially clean-up lots of this logic by placing into a full-blown utility class/object
const AttributesHeader = ({
  sections,
  generalAttributes,
  setRows,
  setSections,
  setCategorySections,
  categorySections,
  productId,
  setIsFilterVisible,
  isFilterVisible,
}) => {
  const { activeUser } = RootHooks.useActiveUser();

  const [dirtyCategories, setDirtyCategories] = useState([]);
  const [showAllValues, setshowAllValues] = useState(true);

  const applyFilterChange = () => {
    const labels = dirtyCategories;
    let newSections = _cloneDeep(sections);
    const rows = [];

    newSections.forEach((section) => {
      if (labels.includes(section.label)) section.filteredOut = !section.filteredOut;
      if (!section.filteredOut) section.columns.forEach((column) => rows.push(column.name));
    });

    setRows(rows);
    setSections(newSections);
    newSections = sortAttributeSections(newSections, 'default');
    setCategorySections(newSections);
    setDirtyCategories([]);
    setIsFilterVisible(!isFilterVisible);
  };

  const getCategoryFilteredCount = () =>
    categorySections.reduce((acc, curr) => {
      return !curr.filteredOut ? acc + 1 : acc;
    }, 0);

  const onFilterChange = (label) => {
    let categorySections = _cloneDeep(sections);
    const labels = dirtyCategories;
    categorySections = sortAttributeSections(categorySections, 'default');

    labels.includes(label) ? labels.splice(labels.indexOf(label, 1)) : labels.push(label);
    categorySections.forEach(
      (section) => labels.includes(section.label) && (section.filteredOut = !section.filteredOut)
    );

    setDirtyCategories(labels);
    setCategorySections(categorySections);
  };

  //   TODO/FIXME: Don't automatically submit, allow for pending state & being able to change individually after making this change (omitted for now)
  const toggleAllCategories = () => {
    let newSections = _cloneDeep(sections);
    const rows = [];
    const filteredCount = getCategoryFilteredCount();

    newSections.forEach((section) => {
      section.filteredOut = filteredCount === sections.length;
      if (!section.filteredOut) section.columns.forEach((column) => rows.push(column.name));
    });

    setRows(rows);
    setSections(newSections);
    newSections = sortAttributeSections(newSections, 'default');
    setCategorySections(newSections);
    setDirtyCategories([]);
  };

  const toggleFilterDropdown = (isOpen) => {
    let newSections = _cloneDeep(sections);
    newSections = sortAttributeSections(newSections, 'default');

    setIsFilterVisible(isOpen);
    setDirtyCategories([]);
    setCategorySections(newSections);
  };

  const toggleShowAllValues = () => {
    const attributes = _cloneDeep(generalAttributes);
    let newSections = attributes.filter((section) => !section._hide);
    const rows = [];

    // Set up map to check section label against to get filtered state (1)
    const filterStates = {};
    sections.forEach((section) => (filterStates[section.label] = section.filteredOut));

    if (showAllValues) {
      // Filter out all null values
      newSections.forEach(
        (section) => (section.columns = section.columns.filter((column) => column.value))
      );
    }

    newSections = sortAttributeSections(newSections, 'default');

    // Assign filtered states to new sections object (2)
    newSections.forEach((section) => {
      section.filteredOut = filterStates[section.label];
      if (!section.filteredOut) section.columns.forEach((column) => rows.push(column.name));
    });

    setRows(rows);
    setSections(newSections);
    setshowAllValues(!showAllValues);
  };

  const doFeedbackSubmit = ({ feedbackData, onSuccess, onFailure, setIsVisible }) => {
    const data = { ...feedbackData, product_id: productId, user_id: activeUser?.id };

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/product/${productId}/feedback/`,
      data,
      onSuccess: () => {
        onSuccess();
        setIsVisible(false);
        toast.success('Thanks for your feedback. The BackboneAI Team will review it shortly.');
      },
      onError: (err) => {
        onFailure(err);
      },
    });
  };

  const renderCategoryHeader = () => {
    const filteredCount = getCategoryFilteredCount();
    const sectionsLength = sections.length;

    return (
      <div className="filter-categories-header">
        <div>
          Categories ({filteredCount || 0}/{sectionsLength || 0})
        </div>
        <div onClick={toggleAllCategories} className="filter-categories-header__select">
          {filteredCount === sectionsLength ? 'Unselect All' : 'Select All'}
        </div>
      </div>
    );
  };

  const renderCategoryFilters = () => {
    let newSections = categorySections.slice();
    newSections = sortAttributeSections(newSections, 'default');

    return newSections.map((section) => (
      <div key={section.label} className="attributes-filter-row">
        <input
          type="checkbox"
          defaultChecked={!section.filteredOut}
          hidden
          name={`filter-${section.label}`}
        />
        <div className="attributes-filter-checkbox" onClick={() => onFilterChange(section.label)}>
          <Checkbox checked={!section.filteredOut} />
        </div>
        <label htmlFor={`filter-${section.label}`}>{section.label}</label>
      </div>
    ));
  };
  return (
    <div className="attributes-options">
      {!UserUtils.isReadOnly(activeUser) && (
        <FeedbackModalPanel onFeedbackSubmit={doFeedbackSubmit}>
          {({ setIsVisible }) => (
            <button type="button" onClick={() => setIsVisible(true)} className="feedback-info">
              <FontAwesomeIcon size="xs" icon={['fas', 'bullhorn']} />
              Report Incorrect Data
            </button>
          )}
        </FeedbackModalPanel>
      )}
      <div className="attributes-filter">
        <div className="attributes-filter-toggle">
          <input
            hidden
            name="attributes-filter-toggle"
            type="checkbox"
            defaultChecked={!showAllValues}
          />
          <div onClick={toggleShowAllValues}>
            <ToggleSwitch on={!showAllValues} />
          </div>
          <label htmlFor="attributes-filter-toggle">Only show attributes with values</label>
        </div>
        <DropdownButton
          alignRight
          variant="secondary"
          title={<span className="label">Filter By</span>}
          show={isFilterVisible}
          onToggle={(isOpen) => toggleFilterDropdown(isOpen)}
        >
          <div className="filter-header">
            <div className="title">Filter By</div>
            <div onClick={() => toggleFilterDropdown(!isFilterVisible)} className="close-icon">
              <FontAwesomeIcon icon={['fas', 'times']} />
            </div>
          </div>
          <div className="filter-categories">
            {renderCategoryHeader()}
            {renderCategoryFilters()}
          </div>
          <div className="filter-footer">
            <span
              onClick={() => toggleFilterDropdown(!isFilterVisible)}
              className="filter-footer-btn filter-footer-btn--secondary"
            >
              Cancel
            </span>
            <span
              onClick={applyFilterChange}
              className="filter-footer-btn filter-footer-btn--primary"
            >
              Submit
            </span>
          </div>
        </DropdownButton>
      </div>
    </div>
  );
};

AttributesHeader.propTypes = {
  sections: PropTypes.array,
  generalAttributes: PropTypes.array,
  setRows: PropTypes.func,
  setCategorySections: PropTypes.func,
  setSections: PropTypes.func,
  categorySections: PropTypes.array,
  productId: PropTypes.number,
};

export { AttributesHeader };
