import React from 'react';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { UserDisplay } from 'components/common/UserProfileDisplay/UserDisplay';
import { ValueBlock } from 'components/common/ValueBlock/ValueBlock';
import * as TimeUtils from 'helpers/TimeUtils';
import * as Utils from 'helpers/Utils';
import { getIconWithPopover, getIconWithTooltip } from 'helpers/Utils';

import { IdsUtils } from './IdsUtils';
import './DetailsHeader.scss';

const buildUserNameDisplay = (user) => {
  const fullName = Utils.joinNameToStr(user?.first_name, user?.last_name);
  const jobTitle = user?.profile?.job_title ?? '';
  return `${fullName?.length ? fullName : user?.email}${jobTitle && `, ${jobTitle}`}`;
};

/**
 * Top header values for Details screen
 *
 * @param {object} djData datajob object we get separately in Details
 * @param {object} idsData process_job object
 * @param {boolean} isLoadingDjData Loading state
 * @param {boolean} isLoadingIdsData Loading state
 *
 * @return render
 */
const DetailsHeader = ({ djData, idsData, isLoadingDjData, isLoadingIdsData }) => {
  const getValueOrLoading = (v, isLoading) => (isLoading ? <LoadingSpinner fast /> : v);

  const renderStatusBlock = (_idsData) => {
    const phase = IdsUtils.getPhaseLabel(_idsData);
    const status = IdsUtils.getStatusLabel(_idsData);
    const tooltipInfo = IdsUtils.getTooltipInfo(_idsData);

    return (
      <div className="status-block">
        <div className="status-block-phase">{phase}</div>
        <div className="status-block-status">
          {IdsUtils.getStatusIcon(_idsData)}
          <div className="status-block-status-info">
            <span className="status-block-status-info-step">{status.step} </span>
            <span className="status-block-status-info-status">
              <span className="status-block-status-info-status">{status.status}</span>
              {status?.progress ? (
                <>
                  <span className="status-block-status-info-progress">- {status.progress}%</span>
                </>
              ) : undefined}
              {tooltipInfo &&
                getIconWithPopover({
                  iconProp: ['far', tooltipInfo.icon],
                  content: tooltipInfo.msg,
                })}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="details-header">
      <ValueBlock
        title="Job #"
        className="job-id"
        value={getValueOrLoading(idsData ? idsData?.id : null, isLoadingDjData)}
      />
      <ValueBlock
        title="Status"
        className="status"
        value={getValueOrLoading(idsData ? renderStatusBlock(idsData) : null, isLoadingDjData)}
      />
      <ValueBlock
        title="Input File"
        className="input-file"
        value={getValueOrLoading(
          idsData ? idsData.additional_data[0].original_file_name : null,
          isLoadingDjData
        )}
        additionalData={[
          { label: 'Total Files', value: idsData?.statistics?.total_extracted },
          { label: 'Invalid Files', value: idsData?.statistics?.invalid_extracted },
        ]}
      />
      <ValueBlock
        title="Owner"
        className="owner"
        value={getValueOrLoading(
          djData ? (
            <UserDisplay
              name={buildUserNameDisplay(djData?.requestor_user)}
              picture={djData?.requestor_user?.profile?.picture}
            />
          ) : null,
          isLoadingDjData
        )}
      />
      <ValueBlock
        title="Execution Summary"
        className="created-at"
        value={getValueOrLoading(
          idsData ? (
            <>
              <div>
                {idsData?.created_at && (
                  <div>
                    <span className="light">
                      {getIconWithTooltip({
                        iconProp: ['far', 'calendar-plus'],
                        content: <p>Created At</p>,
                        placement: 'top',
                      })}{' '}
                      {TimeUtils.formatMinDateStamp(idsData?.created_at)}{' '}
                      {TimeUtils.formatTimeStamp(idsData?.created_at)}
                    </span>
                  </div>
                )}

                {idsData?.updated_at && (
                  <div>
                    <span className="light">
                      {getIconWithTooltip({
                        iconProp: ['far', 'calendar-check'],
                        content: <p>Last Update</p>,
                        placement: 'top',
                      })}{' '}
                      {TimeUtils.formatMinDateStamp(idsData?.updated_at)}{' '}
                      {TimeUtils.formatTimeStamp(idsData?.updated_at)}
                    </span>
                  </div>
                )}

                <div>
                  <span className="light">
                    {getIconWithTooltip({
                      iconProp: ['fad', 'stopwatch'],
                      content: <p>Total Runtime</p>,
                      placement: 'top',
                    })}{' '}
                    {TimeUtils.formattedRuntime(idsData?.runtime)}
                  </span>
                </div>
              </div>
            </>
          ) : null,
          isLoadingIdsData
        )}
      />
    </section>
  );
};

export { DetailsHeader };
