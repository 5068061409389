import React from 'react';

import PropTypes from 'prop-types';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';

/**
 * Manufacturer and Child mfr selection widget
 *
 * @param {array} mfrsData List of manufacturers
 * @param {array} childMfrsData List of child manufacturers
 * @param {object} value Current selected item
 * @param {function} onChange Change handler
 * @param {boolean} isDisabled Disabled state
 * @param {boolean} isLoadingProdCountData Load state for prod count data
 * @return render
 */
const BlockManufacturerSelect = ({
  mfrsData,
  childMfrsData,
  value,
  onChange,
  prodCountData,
  isDisabled,
}) => {
  const getSelectedMfrOption = () => {
    const selectedMfr = value?.manufacturer;
    return selectedMfr ? [{ value: selectedMfr.id, label: selectedMfr.name }] : [];
  };

  const getSelectedChildMfrOption = () => {
    const selectedChildMfr = value?.manufacturerChild;
    return selectedChildMfr ? [{ value: selectedChildMfr.id, label: selectedChildMfr.name }] : [];
  };

  const doSelectMfr = (id) => {
    const mfrObj = id && mfrsData?.find((item) => item.id === id);

    onChange({
      ...value,
      manufacturer: mfrObj || null,
    });
  };

  const doSelectChildMfr = (id) => {
    const childMfrObj = id && childMfrsData?.find((item) => item.id === id);

    onChange({
      ...value,
      manufacturerChild: childMfrObj || null,
    });
  };

  return (
    <section className="block block-manufacturer-select">
      <div className="manufacturer">
        <div className="title">Manufacturer</div>
        <StyledMultiselect
          values={getSelectedMfrOption()}
          options={
            mfrsData
              ? mfrsData.map((item) => {
                  return { value: item.id, label: item.name };
                })
              : []
          }
          setOnChange={(v) => {
            doSelectMfr(v?.value || null);
          }}
          getOptionValue={(option) => option.value}
          closeMenuOnSelect
          isSearchable
          isDisabled={isDisabled}
          isMulti={false}
          isClearable
          canReset
        />
      </div>
      {value?.manufacturer && childMfrsData?.length ? (
        <div className="child-company">
          <div className="title">Level 1 Brand</div>
          <StyledMultiselect
            values={getSelectedChildMfrOption() || []}
            options={
              childMfrsData
                ? childMfrsData.map((item) => {
                    return { value: item.id, label: item.name };
                  })
                : []
            }
            setOnChange={(v) => doSelectChildMfr(v?.value || null)}
            getOptionValue={(option) => option.value}
            closeMenuOnSelect
            isSearchable
            isDisabled={isDisabled}
            isMulti={false}
            isClearable
            canReset
          />
        </div>
      ) : null}
      {parseInt(prodCountData?.product_count) >= 0 && (
        <div className="product-count">
          <div className="title">Products</div>
          <div className="product-count-value">
            {prodCountData.product_count.toLocaleString('en-US')}
          </div>
        </div>
      )}
    </section>
  );
};

BlockManufacturerSelect.defaultProps = {
  mfrsData: null,
  childMfrsData: null,
  value: null,
  prodCountData: null,
  isDisabled: false,
};

BlockManufacturerSelect.propTypes = {
  mfrsData: PropTypes.array,
  childMfrsData: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  prodCountData: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export { BlockManufacturerSelect };
