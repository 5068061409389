import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotNotRun = ({ description, avgRuntime, runBot }) => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="not-run"
      middle={<div className="description">{description}</div>}
      bottom={
        <div className="databot-actions">
          <span className="databot-details__est-time">
            Est. time: {(Math.round(avgRuntime) / 60).toFixed(0) ?? 0} mins
          </span>
          <button onClick={runBot} className="databot-action" type="button">
            Run <FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} />
          </button>
        </div>
      }
    />
  );
};

export { DatabotNotRun };
