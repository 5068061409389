import './TooltipIcon.scss';

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import propTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipIcon = ({ tooltip }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <p>{tooltip}</p>
        </Tooltip>
      }
    >
      <div className="title-tooltip">
        <span className="icon">
          <FontAwesomeIcon className="icon-circle" icon={['fas', 'info']} />
        </span>
      </div>
    </OverlayTrigger>
  );
};

TooltipIcon.propTypes = {
  tooltip: propTypes.string.isRequired,
};

export { TooltipIcon };
