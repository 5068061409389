import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatMinDateStamp } from 'helpers/TimeUtils';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotCompleted = ({
  queuedAt,
  description,
  hasDetails,
  reportable,
  downloadReport,
  showInProgress = false,
  viewDetails,
  runBot,
  inProgress = 0,
  downloads = 0,
  completed = 0,
}) => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="completed"
      top={
        <div className="databot-details__last-ran">
          Last Ran: {queuedAt ? formatMinDateStamp(queuedAt) : 'N/A'}
        </div>
      }
      middle={<div className="description">{description}</div>}
      bottom={
        <div>
          {/* TODO: Use props here instead of checking bot model, due to too many combinations */}
          <div className="databot-actions">
            {hasDetails && !reportable && queuedAt && (
              <button
                onClick={viewDetails}
                className="databot-action databot-action--alt"
                type="button"
              >
                See Details <FontAwesomeIcon icon={['fas', 'forward']} />
              </button>
            )}
            {reportable && !hasDetails && (
              <button
                onClick={downloadReport}
                className="databot-action databot-action--alt"
                type="button"
              >
                Download <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} />
              </button>
            )}
            <button onClick={runBot} className="databot-action" type="button">
              Run <FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} />
            </button>
          </div>
          {queuedAt && (
            <>
              {showInProgress && (
                <div className="databot-details__primary">{inProgress ?? 0} In Progress</div>
              )}
              {!showInProgress && (
                <div className="databot-details__tertiary">{completed ?? 0} Completed</div>
              )}
              <div className="databot-details__primary">
                {downloads ?? 0} Download{downloads === 1 ? '' : 's'}
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export { DatabotCompleted };
