import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import './CircularProgressBar.scss';

/**
 * Statuses list defines color accents and status pill at the bottom.
 * If you need to extend with more statuses for a specific use, you can pass down
 * your own class-label pair, and take a look at the SCSS how to style it.
 */
const STATUSES = {
  POOR: { class: 'poor', label: 'Poor' },
  ACCEPTABLE: { class: 'acceptable', label: 'Acceptable' },
  GOOD: { class: 'good', label: 'Good' },
};

/**
 * Circular or semi-circle progress widget with various labeling options
 *
 * @param {Number} percentage Percentage value. Will be clamped to 0-100, or null for N/A state.
 * @param {String} label Text label
 * @param {Node} children Custom central content. Will override all default content.
 * @param {Object} status CircularProgressBar.STATUSES item or custom class-label pair
 * @param {String} className Custom class
 * @param {boolean} isSemiCircle Turns the component into a semi-circle variant with 2:1 display ratio
 * @param {boolean} isShowPercentage Show or hide percentage value in label
 * @param {boolean} isShowStatus Show or hide status pill in label
 * @param {Number} percentageDecimalPlaces Number of decimal places to show on percentage label
 *
 * @return Render
 */
const CircularProgressBar = ({
  percentage,
  label,
  children,
  status,
  className,
  isSemiCircle,
  isShowPercentage,
  isShowStatus,
  percentageDecimalPlaces,
}) => {
  const isEmpty = !Number.isFinite(percentage);
  const _percentage = !isEmpty ? Math.min(Math.max(percentage, 0), 100) : null;

  const renderPercentageLabel = () => {
    let output = 'N/A';

    if (!isEmpty) {
      const _label = _percentage.toLocaleString('en-US', {
        minimumFractionDigits: percentageDecimalPlaces > 0 ? percentageDecimalPlaces : 0,
        maximumFractionDigits: percentageDecimalPlaces > 0 ? percentageDecimalPlaces : 0,
      });

      output = (
        <>
          {_label}
          <span className="unit">%</span>
        </>
      );
    }

    return output;
  };

  return (
    <div
      className={classNames('bai-circular-progressbar', className, {
        empty: isEmpty,
        'semi-circle': isSemiCircle,
        [`status-${status?.class}`]: !!status?.class,
      })}
    >
      <div className="bai-circular-progressbar-inner-wrap">
        <CircularProgressbarWithChildren
          value={!isEmpty && _percentage < 1 ? 1 : _percentage < 100 ? _percentage * 0.99 : 100}
          circleRatio={isSemiCircle ? 0.5 : 1}
          styles={buildStyles({
            rotation: isSemiCircle ? 0.75 : 0,
          })}
        >
          <div className="bai-circular-progressbar-label">
            {children ?? (
              <>
                {isShowPercentage && <div className="value">{renderPercentageLabel()}</div>}
                {label && <div className="label">{label}</div>}
                {isShowStatus && !!status?.label && (
                  <div className="status-label-wrap">
                    <div className="status-label">{status.label}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
};

CircularProgressBar.STATUSES = STATUSES;

CircularProgressBar.defaultProps = {
  percentage: null,
  label: null,
  children: null,
  status: null,
  className: null,
  isSemiCircle: false,
  isShowPercentage: false,
  isShowStatus: false,
  percentageDecimalPlaces: 0,
};

CircularProgressBar.propTypes = {
  percentage: PropTypes.number,
  label: PropTypes.string,
  children: PropTypes.node,
  status: PropTypes.shape({
    class: PropTypes.string,
    label: PropTypes.string,
  }),
  className: PropTypes.string,
  isSemiCircle: PropTypes.bool,
  isShowPercentage: PropTypes.bool,
  isShowStatus: PropTypes.bool,
  percentageDecimalPlaces: PropTypes.number,
};

export { CircularProgressBar };
