import './PassThruModal.scss';

import React, { useEffect, useReducer, useState, useCallback } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { OKModal } from 'components/common/ModalPanel/OKModal';
import { PassThruFileDetailsSection } from 'components/data-job/data-job-create/PassThruFileDetailsSection/PassThruFileDetailsSection';
import PassThruFileCreateConstants from 'constants/PassThruFileCreateConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';

import { ModalPanel } from './ModalPanelDetach';

/**
 * PassThruModal.
 *
 * @param {Object} - props
 * @param {function} - props.onOpen - function called on modal open
 * @param {function} - props.onClose - function called on modal close regardless of button click
 * @param {Object} - props.header - header string or react node
 * @param {Object} - props.body - header string or react node
 * @param {Object} - props.footer - header string or react node - this is an override for existings Ok and opt cancel button
 * @param {String} - props.className - for underlying body component
 * @param {Boolean} - props.vertCentered - boolean whether to center modal vertical, default true
 * @param {String} -  props.buttonText - text to show in ok button
 * @param {Boolean} - props.isDanger - show danger button variant
 * @param {Boolean} - props.isWarning - show warning button variant
 * @param {function} - props.setIsVisible - function to set visibility of modal
 * @param {Boolean} - props.isVisible - prop to control visibility of modal
 * @param {Boolean} - props.showCancel - prop to control visibiility of cancel button
 *
 */

const passThruReducer = (state, action) => {
  switch (action.type) {
    case PassThruFileCreateConstants.FILE:
      return { ...state, files: [...state.files, action.payload] };
    case PassThruFileCreateConstants.DELETE_FILE:
      return { ...state, files: state.files.filter((f) => f.id !== action.payload) };
    case PassThruFileCreateConstants.LINK:
      return { ...state, links: [...state.links, action.payload] };
    case PassThruFileCreateConstants.RECIPIENT:
      return { ...state, recipient: action.payload };
    case PassThruFileCreateConstants.DISTRIBUTOR:
      return { ...state, distributor: action.payload };
    case PassThruFileCreateConstants.MANUFACTURER:
      return { ...state, manufacturer: action.payload };
    case PassThruFileCreateConstants.SENDER:
      return { ...state, sender: action.payload };
    case PassThruFileCreateConstants.SENDER_TYPE:
      return { ...state, sender_type: action.payload };
    case PassThruFileCreateConstants.RESET:
      return action.payload;
    default:
      return state;
  }
};

const invalidReducer = (state, action) => {
  return { ...state, ...action.payload };
};

const PassThruModal = ({
  onOpen,
  onClose,
  body,
  className,
  vertCentered = true,
  setIsVisible,
  isVisible,
}) => {
  const { activeUser } = RootHooks.useActiveUser();

  const [isFileUploading, setIsFileUploading] = useState(null);
  const [invalidCheckEffect, setInvalidCheckEffect] = useState(false);
  const [passThruFilesSent, setPassThruFilesSent] = useState(false);

  // ----------------------------------- Pass Thru Job Reducer -----------------------------------  //

  const initialPassThruState = {
    dataJobDirection: null,
    files: [],
    links: [],
    recipient: null,
    distributor: null,
    manufacturer: null,
    sender: null,
    sender_type: activeUser?.role === UserRoleConstants.CLIENT ? 'client' : 'manufacturer',
    jobName: '',
    desc: '',
    linkedDataJob: [],
    dueDate: '',
    priority: '',
    dataRequestTypeId: null,
    dataRequestSubtypeIds: [],
    expectedDeliveryFormatIds: [],
  };

  const [passThruState, passThruDispatch] = useReducer(
    passThruReducer,
    _cloneDeep(initialPassThruState)
  );

  const [invalidCheck, dispatchInvalidCheck] = useReducer(invalidReducer, {
    distributor: false,
    manufacturer: false,
    sender: false,
    files: false,
    recipient: false,
  });

  const dispatchChangePassThru = useCallback((data) => {
    passThruDispatch(data);
  }, []);

  const doResetState = useCallback(() => {
    dispatchChangePassThru({
      type: PassThruFileCreateConstants.RESET,
      payload: initialPassThruState,
    });
    dispatchInvalidCheck({
      payload: {
        distributor: false,
        manufacturer: false,
        sender: false,
        files: false,
        recipient: false,
      },
    });
    setInvalidCheckEffect(false);
  }, [dispatchInvalidCheck, dispatchChangePassThru, setInvalidCheckEffect]);

  // -------------------------------- Page Completion Logic -------------------------------- //
  const handleSubmit = () => {
    const createForm = {
      manufacturer_id: passThruState.recipient
        ? activeUser?.role === UserRoleConstants.CLIENT && passThruState.recipient.value
        : passThruState.manufacturer?.value,
      client_id: passThruState.recipient
        ? activeUser?.role === UserRoleConstants.SUPPLIER && passThruState.recipient.value
        : passThruState.distributor?.value,
      sender_id: passThruState.sender
        ? passThruState.sender.value || passThruState.sender.id
        : null,
      sender_type: passThruState.sender_type,
      recipient: passThruState.recipient,
      attachments: passThruState.files
        .map((v) => v.id)
        .concat(passThruState.links.map((v) => v.id)),
    };

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/pass-thru-files/send/`,
      data: createForm,
      onSuccess: () => {
        setPassThruFilesSent(true);
        setIsVisible(false);
        doResetState();
      },
      onError: () => {
        toast.error('There was an error while submitting data');
      },
    });
  };
  useEffect(() => {
    if (invalidCheckEffect) {
      dispatchInvalidCheck({
        payload: {
          distributor: !passThruState.distributor,
          manufacturer: !passThruState.manufacturer,
          sender: !passThruState.sender,
          recipient: !passThruState.recipient,
          files: !passThruState.files.length > 0,
        },
      });
    }
  }, [activeUser?.role, invalidCheckEffect, passThruState]);

  const isFormComplete = () => {
    const test = ![
      passThruState.distributor,
      passThruState.manufacturer,
      passThruState.sender,
      passThruState.recipient,
    ].includes(null);
    return test && passThruState.files.length > 0;
  };

  const checkAndSubmit = (e) => {
    setInvalidCheckEffect(true);
    if (!isFormComplete() || Object.values(invalidCheck).includes(true)) {
      return;
    }
    handleSubmit(e);
  };

  const setIsVisibleLocal = (val) => {
    doResetState();
    setIsVisible(val);
  };

  const Footer = () => (
    <>
      <Button
        style={{ order: 1 }}
        type="back"
        className="my-1 btn-secondary footer-button"
        onClick={() => setIsVisibleLocal(false)}
        disabled={isFileUploading}
      >
        Cancel
      </Button>
      <Button
        style={{ order: 2 }}
        type="submit"
        className="my-1 footer-button"
        onClick={(e) => checkAndSubmit(e)}
        disabled={isFileUploading}
      >
        Send
      </Button>
    </>
  );

  const localBody = () => {
    if (body) return body;
    return (
      <PassThruFileDetailsSection
        isUploading={isFileUploading}
        setIsUploading={(v) => setIsFileUploading(v)}
        dispatchChange={dispatchChangePassThru}
        state={passThruState}
        invalidCheck={invalidCheck}
      />
    );
  };

  if (passThruFilesSent) {
    return (
      <OKModal
        header={`File${passThruState.files.length > 1 ? 's' : ''} Sent!`}
        body={
          <span className="attachments-table-row_item_item-deletebutton-confirmbody">
            {`Thanks, your file${
              passThruState.files.length > 1 ? 's were' : ' was'
            } sent successfuly.`}
          </span>
        }
        isVisible={passThruFilesSent}
        setIsVisible={setPassThruFilesSent}
        onClose={() => doResetState()}
        onOk={() => doResetState()}
      />
    );
  }
  return (
    <ModalPanel
      dialogClassName="pass-thru-modal-dialog"
      onOpen={onOpen}
      header="Send Pass Through File"
      body={localBody}
      className={`${className} pass-thru-modal`}
      centered={vertCentered}
      setIsVisible={setIsVisibleLocal}
      isVisible={isVisible}
      backdrop="static"
      onClose={() => {
        typeof onClose === 'function' && onClose();
      }}
      footer={Footer}
    />
  );
};

PassThruModal.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  vertCentered: PropTypes.bool,
  body: PropTypes.any,
  footer: PropTypes.any,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  isDanger: PropTypes.bool,
  isWarning: PropTypes.bool,
  setIsVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  showCancel: PropTypes.bool,
};

export { PassThruModal };
