import React, { useEffect, useState, useCallback } from 'react';

import 'components/products-export/ExportFunctionalityPanel.scss';

import { Button } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { BOT_STATUSES } from 'constants/BotConstants';
import { useIsMounted } from 'helpers/useIsMounted';
import { equalUnsortedArrays } from 'helpers/Utils';

import { DataFillDefinitions } from './_DataFillDefinitions';
import { BaseDataFillExportTable } from './BaseDataFillExportTable';
import { BaseDataFillFileUpload } from './BaseDataFillFileUpload';

/**
 * Export functionality panel main view
 *
 * @return render
 */
const BaseDataFillBotOverview = ({
  manufacturerId,
  validUpload,
  setValidUpload,
  botStatusId,
  exportHeaders,
  setExportHeaders,
  startExportStatus,
  setStartExportStatus,
  setConfigData,
  setBotStatusData,
  status,
  botData,
  exportData,
  exportLoading,
  slug,
}) => {
  // Holds selected mfr id and export type selected
  const isMounted = useIsMounted();

  const [uploadHeaders, setUploadHeaders] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [areHeadersUpdating, setAreHeadersUpdating] = useState(false);
  const [files, setFiles] = useState([]);
  const [invalidMessage, setInvalidMessage] = useState();

  const { longName, type } = DataFillDefinitions[slug];

  useEffect(() => {
    if (files.length > 0 && !isFileUploading && !areHeadersUpdating) {
      if (files.length > 1) {
        setValidUpload(false);
        setInvalidMessage(`Error: Only one file may be uploaded.`);
      } else if (
        uploadHeaders.length === 0 ||
        exportHeaders.length === 0 ||
        uploadHeaders.length !== exportHeaders.length
      ) {
        setValidUpload(false);
        setInvalidMessage(
          `Error: The file you uploaded contains a different number of columns or the column names have changed.`
        );
      } else {
        setValidUpload(equalUnsortedArrays(exportHeaders, uploadHeaders));
      }
    } else {
      setValidUpload(false);
      setInvalidMessage(null);
    }
  }, [files, isFileUploading, uploadHeaders, setValidUpload, exportHeaders, areHeadersUpdating]);

  const startExport = useCallback(
    (botStatusId) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/product-export-history/general/export',
        data: {
          manufacturer_id: manufacturerId,
          type,
          file_type: 'xlsx',
          bot_status_id: botStatusId,
        },
        successMessage: 'Export started.',
        onSuccess: () => {
          if (isMounted.current) setStartExportStatus(ActionStatusConstants.SUCCESS);
        },
        onError: () => {
          if (isMounted.current) setStartExportStatus(ActionStatusConstants.FAILURE);
        },
      });
    },
    [isMounted, manufacturerId, setStartExportStatus, type]
  );

  const doStartExport = useCallback(() => {
    setStartExportStatus(ActionStatusConstants.ISBUSY);
    let localBotStatusId = botStatusId;

    // If a valid databot status doesn't exist prior to running export, we want to create it as a fallback here to keep state predictable
    if (!localBotStatusId || [BOT_STATUSES.FAILED, BOT_STATUSES.CANCELED].includes(status)) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/bots/status',
        data: {
          ...(manufacturerId && {
            manufacturer_id: manufacturerId,
          }),
          bot_id: botData.id,
        },
        onSuccess: (res) => {
          if (isMounted.current) {
            setBotStatusData(res.data);
            localBotStatusId = res.data.id;
          }
        },
        onEnd: () => {
          if (isMounted.current) startExport(localBotStatusId);
        },
      });
    } else {
      startExport(localBotStatusId);
    }
  }, [
    isMounted,
    manufacturerId,
    botStatusId,
    setStartExportStatus,
    status,
    setBotStatusData,
    botData,
    startExport,
  ]);

  return (
    <div className="data-fill">
      <div className="bot-main">
        <div className="bot-form">
          <section className="bot-select">
            <h3>Step 1: Download {longName} Export</h3>
            {/* code stored for changing the export time when the bot is expanded
            <div className="title">Export Type</div>
            <Form.Check
              type="radio"
              label={EXPORT_TYPES.general.label}
              checked={selectedExportType === EXPORT_TYPES.general.value}
              readOnly
              disabled={startExportStatus === ActionStatusConstants.ISBUSY}
              onClick={() => setSelectedExportType(EXPORT_TYPES.general.value)}
            />
            <Form.Check
              type="radio"
              label={EXPORT_TYPES.detailed.label}
              checked={selectedExportType === EXPORT_TYPES.detailed.value}
              readOnly
              disabled
              onClick={() => setSelectedExportType(EXPORT_TYPES.detailed.value)}
            />
            <Form.Check
              type="radio"
              label={EXPORT_TYPES.asset.label}
              checked={selectedExportType === EXPORT_TYPES.asset.value}
              readOnly
              disabled
              onClick={() => setSelectedExportType(EXPORT_TYPES.asset.value)}
            />
            <Form.Check
              type="radio"
              label={EXPORT_TYPES.taxonomy.label}
              checked={selectedExportType === EXPORT_TYPES.taxonomy.value}
          
              onClick={() => setSelectedExportType(EXPORT_TYPES.taxonomy.value)}
            />
            */}
            <div className="actions">
              <Button className="btn btn-secondary btn-lg" onClick={() => doStartExport()}>
                Start {longName} Export
              </Button>
            </div>
            {exportData?.length > 0 && <BaseDataFillExportTable exportData={exportData} />}
          </section>
          {exportData?.length > 0 ? (
            <section className="bot-upload">
              <h3>Step 2: Upload Corrected File</h3>
              <div className="upload-section">
                <BaseDataFillFileUpload
                  manufacturerId={manufacturerId}
                  setIsUploading={setIsFileUploading}
                  isUploading={isFileUploading}
                  files={files}
                  setFiles={setFiles}
                  setUploadHeaders={setUploadHeaders}
                  setExportHeaders={setExportHeaders}
                  setAreHeadersUpdating={setAreHeadersUpdating}
                  botStatusId={botStatusId}
                  setConfigData={setConfigData}
                  slug={slug}
                />
              </div>
            </section>
          ) : null}
        </div>
        {files.length > 0 && !validUpload ? (
          <div className="bot-invalid">{invalidMessage}</div>
        ) : null}
      </div>
    </div>
  );
};

export { BaseDataFillBotOverview };
