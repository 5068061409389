import './DataQualityFooter.scss';
import React, { useContext, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { DataIngestionDataQualityContext } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQualityContext';
import { DEFAULT_NUM_ROWS } from 'constants/DataIngestionConstants';
import { generateProcessedDownloadUrl } from 'helpers/AttachmentUtils';

const DataQualityFooter = ({ fileId, doStartIngestion, doDownload, userRole }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const context = useContext(DataIngestionDataQualityContext);

  const {
    showGoodRows,
    setShowGoodRows,
    allRowsHaveErrors,
    maxNumRowsToShow,
    setMaxNumRowsToShow,
    numRowsInSheet,
    inconsistentRows,
  } = context;

  function startIngestion() {
    doStartIngestion();
  }

  function download() {
    const downloadUrl = generateProcessedDownloadUrl(fileId) || null;

    if (downloadUrl !== null) window.open(downloadUrl, '_blank');
    doDownload();
  }

  const PaginationButton = () => {
    const changeMaxNumRowsToShow = () => {
      if (maxNumRowsToShow > DEFAULT_NUM_ROWS) {
        // Show fewer rows
        // Adjust pagination if all rows shown have errors
        if (showGoodRows && maxNumRowsToShow <= inconsistentRows.length) {
          setShowGoodRows(false);
        }
        setMaxNumRowsToShow(DEFAULT_NUM_ROWS);
      } else {
        // Show more rows
        setMaxNumRowsToShow(maxNumRowsToShow + 1000);
        if (inconsistentRows.length <= DEFAULT_NUM_ROWS) {
          setShowGoodRows(true);
        }
      }
    };

    return (
      <span className="pagination-button-wrapper">
        <button type="button" className="button-paginate" onClick={() => changeMaxNumRowsToShow()}>
          {maxNumRowsToShow > DEFAULT_NUM_ROWS ? (
            <>
              <FontAwesomeIcon
                icon="caret-up"
                style={{ marginLeft: '8px', marginRight: '8px', height: '1em' }}
              />
              {' Show Less '}
              <FontAwesomeIcon
                icon="caret-up"
                style={{ marginLeft: '8px', marginRight: '8px', height: '1em' }}
              />
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon="caret-down"
                style={{ marginLeft: '8px', marginRight: '8px', height: '1em' }}
              />
              {' Show More '}
              <FontAwesomeIcon
                icon="caret-down"
                style={{ marginLeft: '8px', marginRight: '8px', height: '1em' }}
              />
            </>
          )}
        </button>
      </span>
    );
  };

  const showHideGoodRows = () => {
    setMaxNumRowsToShow(DEFAULT_NUM_ROWS);
    setShowGoodRows(!showGoodRows);
  };

  return (
    <>
      {numRowsInSheet > DEFAULT_NUM_ROWS && <PaginationButton />}
      <footer className=" content-fluid ingestion-footer ">
        <div className="footer-content">
          {/* TODO add pagination controls once we have a table */}
          <button
            type="button"
            className="button-filter"
            onClick={() => showHideGoodRows()}
            style={{ display: allRowsHaveErrors && 'none' }}
          >
            <span className="icon">
              <FontAwesomeIcon icon={['far', 'filter']} />
            </span>
            {showGoodRows ? ' Hide rows without errors' : ' Show rows without errors'}
          </button>
          {/* TODO V2 reimplement start ingestion when we have definitions
          userRole === UserRoleConstants.ADMIN ? (
            <button
              type="button"
              className="button-ingestion"
              onClick={() => setModalVisible(true)}
              disabled={!canDoIngestion()}
            >
              <span className="oi oi-cloud-upload" style={{ marginRight: '.5em' }} aria-hidden />
              Start Ingestion
            </button>
          ) : null */}
          <button
            type="button"
            className="button-download btn btn-outline-dark"
            onClick={() => download()}
          >
            <span
              className="oi oi-data-transfer-download"
              style={{ marginRight: '.5em' }}
              aria-hidden
            />
            Download
          </button>
        </div>
        <div id="modal-container">
          <ModalPanel
            isVisible={modalVisible}
            setIsVisible={setModalVisible}
            showCancel
            body={<div>This will trigger the automatic data ingestion. Proceed?</div>}
            footer={
              <div className="row">
                <Button variant="outline-dark mr-2" onClick={() => setModalVisible(false)}>
                  Cancel
                </Button>
                <Button className="secondary-btn" onClick={() => startIngestion()}>
                  Continue
                </Button>
              </div>
            }
          />
        </div>
      </footer>
    </>
  );
};

DataQualityFooter.propTypes = {
  fileId: PropTypes.number.isRequired,
  doStartIngestion: PropTypes.func.isRequired,
  doDownload: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

export { DataQualityFooter };
