import React from 'react';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ReportsTable } from 'components/data-lakehouse/ReportsTable';

const DatabotsReports = ({ manufacturerId, reports, isLoading }) => {
  if (!manufacturerId) return null;

  return (
    reports.length > 0 && (
      <section className="databots-section">
        <div className="databots-section-header">
          <h2 className="databots-section-heading">Reports</h2>
          {isLoading && <LoadingSpinner fast />}
        </div>
        <ReportsTable reports={reports} />
      </section>
    )
  );
};

export { DatabotsReports };
