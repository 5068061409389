import './WeeklyMetricsCard.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { Card } from 'components/common/Card/Card';

const WeeklyMetricsCard = ({ header, currentPeriodTotal = 0, previousPeriodTotal = 0 }) => {
  const POSITIVE_SIGN = '+';
  const NEGATIVE_SIGN = '-';
  const [percentSign, setPercentSign] = useState('');
  const [percentChange, setPercentChange] = useState(0);

  const calculatePercentageChange = (currentPeriod, previousPeriod) => {
    const increase = currentPeriod - previousPeriod;
    return (increase / previousPeriod) * 100;
  };

  const renderSign = (percentChange) => {
    let sign = '';
    let signValue = '';
    if (Number.isFinite(percentChange)) signValue = Math.sign(percentChange);

    switch (signValue) {
      case 1:
        sign = POSITIVE_SIGN;
        break;
      case -1:
        sign = NEGATIVE_SIGN;
        break;
      default:
        break;
    }

    return sign;
  };

  const renderPercentage = () => {
    let value = 'N/A';
    if (Number.isNaN(percentChange) === false && Number.isFinite(percentChange))
      value = `${percentSign}${Math.abs(percentChange).toFixed(0)}%`;

    return (
      <p
        className={classNames({
          'weekly-metrics-card-list-item-percent--positive': percentSign === POSITIVE_SIGN,
          'weekly-metrics-card-list-item-percent--negative': percentSign === NEGATIVE_SIGN,
        })}
      >
        {value}
      </p>
    );
  };

  useEffect(() => {
    const percentChange = calculatePercentageChange(currentPeriodTotal, previousPeriodTotal);
    setPercentChange(percentChange);
    if (Number.isNaN(percentChange) === false && Number.isFinite(percentChange))
      setPercentSign(renderSign(percentChange));
    else setPercentSign('');
  }, [currentPeriodTotal, previousPeriodTotal]);

  return (
    <Card className="weekly-metrics-card">
      <div className="weekly-metrics-card-header">
        <h2>{header ?? 'N/A'}</h2>
      </div>
      <div className="weekly-metrics-card-body">
        <ul className="weekly-metrics-card-list">
          <li className="weekly-metrics-card-list-item">
            <p className="weekly-metrics-card-list-item-label">Current Period</p>
            <p>{currentPeriodTotal ?? 0}</p>
          </li>
          <li className="weekly-metrics-card-list-item">
            <p className="weekly-metrics-card-list-item-label">Previous Period</p>
            <p>{previousPeriodTotal ?? 0}</p>
          </li>
          <li className="weekly-metrics-card-list-item">
            <p className="weekly-metrics-card-list-item-label">% Change</p>
            {renderPercentage()}
          </li>
        </ul>
      </div>
    </Card>
  );
};

export { WeeklyMetricsCard };
