import './PricingUpdateHeader.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * LoadingText.
 * @param manufacturer the manufacturer name
 * @param productTotal an int counting the total projects for the manufacturer
 */
const PricingUpdateHeader = ({ manufacturer, productTotal }) => {
  return (
    <section className={classNames('pricing-update-section')}>
      <div className="header-card card-manufacturer">
        <div className="title">Manufacturer</div>
        <div className="content">{manufacturer}</div>
      </div>
      <div className="header-card card-total">
        <div className="title">Total Products</div>
        <div className="content">{productTotal?.toLocaleString()}</div>
      </div>
    </section>
  );
};

PricingUpdateHeader.propTypes = {
  manufacturer: PropTypes.string,
  productTotal: PropTypes.number,
};

export { PricingUpdateHeader };
