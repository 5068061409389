import React, { useEffect, useRef, useState } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import * as ApiCalls from 'api/ApiCalls';
import { WsConstants } from 'api/WsConstants';
import { Databot } from 'components/databots/Databot/Databot';
import { BotConstants } from 'constants/BotConstants';
import { parseDatabotData } from 'helpers/BotUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { useWebsocket } from 'helpers/useWebsocket';

import 'mdn-polyfills/MouseEvent';
import './FilesBlock.scss';

/**
 * Renders the list of bots related to the current Data Job
 * @param {integer} djId Data Request ID
 * @param {integer} mfrId Manufacturer ID
 * @returns Render
 */
const DatabotsBlock = React.memo(
  ({ djId, mfrId }) => {
    // WS params
    const channel = WsConstants.WS_CHANNELS.BOTS;
    const ws = useWebsocket();
    const wsLastUpdate = useRef(null);
    const { messages, messagesLastUpdate } = ws;

    const isMounted = useIsMounted();

    // Holds bots definitions
    const [fifBots, setFifBots] = useState([]);

    // Load data bots
    useEffect(() => {
      if (!(isMounted.current && mfrId && djId)) return;

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/available-bots?manufacturer_id=${mfrId}&data_request_id=${djId}&type=${BotConstants.BOT_TYPES.FILE_IN_FLIGHT}`,
        onSuccess: (res) => {
          if (isMounted.current) {
            setFifBots(res.data);
          }
        },
      });
    }, [isMounted, djId, mfrId]);

    // WS subscribe/unsubscribe
    useEffect(() => {
      // extra config
      const wsExtra = {
        type: BotConstants.BOT_WS_CHANNEL_TYPES.DJ,
        id: djId,
      };

      // Unsubscribe func on unmount
      const unmountFunc = () => {
        if (djId && !isMounted.current) {
          ws.disconnectChannel(channel, wsExtra);
        }
      };

      // Subscribe logic
      if (
        djId &&
        isMounted.current &&
        fifBots?.length &&
        ws?.isConnected &&
        !wsLastUpdate.current
      ) {
        ws.addChannel(channel, wsExtra);
        wsLastUpdate.current = Date.now();
      }
      return () => unmountFunc();
    }, [isMounted, channel, ws, djId, wsLastUpdate, fifBots?.length]);

    // WS messages handler
    useEffect(() => {
      if (
        !(
          fifBots?.length &&
          isMounted.current &&
          wsLastUpdate.current < messagesLastUpdate[channel]
        )
      ) {
        return;
      }

      wsLastUpdate.current = messagesLastUpdate[channel];
      const _data = messages[channel] ?? null;

      if (_data?.id && _data?.bot_id) {
        const newFifBots = _cloneDeep(fifBots);

        const foundBot = newFifBots.find((item) => item?.id === _data.bot_id);

        if (foundBot) {
          foundBot.bot_status = _cloneDeep(_data);
        }

        setFifBots(newFifBots);
      } else {
        console.debug('Unsupported msg', _data);
      }
    }, [isMounted, channel, ws?.isConnected, messages, wsLastUpdate, messagesLastUpdate, fifBots]);

    if (!fifBots?.length) return null;

    return (
      <div className="fif-databots">
        <div className="title">Premium Databots</div>
        <div className="subtitle">
          Select one file from above to run a premium databot. Premium databots do not save data
          into your BackboneAI portal, but allow you to transform your data to meet requirements
          before submitting for approval.
        </div>
        <div className="fif-databots-list">
          {fifBots?.map((bot) => (
            <Databot
              key={bot.id}
              manufacturerId={mfrId}
              type="file_in_flight"
              dataJobId={djId}
              bot={parseDatabotData(bot)}
            />
          ))}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => _isEqual(prevProps, nextProps)
);

export { DatabotsBlock };
