/**
 * Generic Action statuses
 */
export default {
  INITIAL: "INITIAL", // Initial state(before being dispatched or after being reset)
  ISBUSY: "ISBUSY", // Action is in progress
  SUCCESS: "SUCCESS", // Action success
  FAILURE: "FAILURE", // Action failure
  SERVER_ERROR: "SERVER_UNRESPONSIVE_OR_FAILED",
};
