import React, { useEffect, useState, useCallback } from 'react';

import queryString from 'query-string';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import * as ApiCalls from '../../../../api/ApiCalls';
import { BOT_SLUGS, BotConstants } from '../../../../constants/BotConstants';
import { toast } from '../../../../helpers/ToastUtils';
import { useIsMounted } from '../../../../helpers/useIsMounted';
import { getRootMenuPortalTarget } from '../../../../helpers/Utils';
import { StyledMultiselect } from '../../../common/StyledMultiselect/StyledMultiselect';
import { DatabotConfigPanel } from '../../DatabotConfigPanel/DatabotConfigPanel';
import { SmallSelectFileSection } from '../common/SmallSelectFileSection/SmallSelectFileSection';
import './AttributeCompression.scss';

const AttributeCompression = ({
  botStatusId,
  slug,
  status,
  handleRunBot,
  handleCancelBot,
  additionalData,
  setConfigData,
}) => {
  const location = useLocation();

  // TODO: this is an prototype version. Must review and rework.
  const menuPortalTarget = getRootMenuPortalTarget();
  const dataJobId = queryString.parse(location?.search).data_request_id;
  const isMounted = useIsMounted();

  // Holds the values users are able to select from
  const [filesList, setFilesList] = useState(null);
  const [originalHeadersList, setOriginalHeadersList] = useState(null);
  const [selectedFileHasError, setSelectedFileHasError] = useState(false);
  const [uploadedFilesMessageError, setUploadedFilesMessageError] = useState('');

  // Information holding user selections
  const [selectedFile, setSelectedFile] = useState(null);
  const [headerMappingChoices, setHeaderMappingChoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validSelections, setValidSelections] = useState(false);

  const [characterLength, setCharacterLength] = useState(null);
  const [selectedHeadersColumns, setSelectedHeadersColumns] = useState(null);

  const [formCustomValueVisible, setFormCustomValueVisible] = useState(false);
  const [inputCustomValueError, setInputCustomValueError] = useState('');
  const [selectedCustomValue, setSelectedCustomValue] = useState('');

  // Reset file lists on props change
  useEffect(() => {
    setFilesList(additionalData?.attachments ?? null);
  }, [additionalData]);

  const doLoadHeaders = useCallback(
    (file) => {
      if (file?.id) {
        setIsLoading(true);

        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/data-requests/attachment/${file.id}/headers`, // if not provided, will not validate row count
          params: {
            bot_slug: BOT_SLUGS.ATTRIBUTE_COMPRESSION,
          },
          onSuccess: (res) => {
            if (isMounted.current && res?.data?.headers?.length) {
              setOriginalHeadersList(res?.data?.headers?.map((item) => item));
            } else {
              setOriginalHeadersList(null);
            }
          },
          onError: (res) => {
            if (res?.response?.data?.code === 'err_max_rows_exceeded') {
              setSelectedFileHasError(true);
              const errorMessage = (
                <>
                  The selected file contains <strong>{res.response.data.rows_number}</strong> rows,
                  which exceeds the maximum number of rows allowed.
                  <br />
                  Please select a file with{' '}
                  <u>
                    up to <strong>{res.response.data.max_allowed}</strong> rows
                  </u>{' '}
                  to proceed.
                </>
              );
              setUploadedFilesMessageError(errorMessage);
            }
          },
          onEnd: () => setIsLoading(false),
        });
      } else if (file?.headers?.length) {
        setOriginalHeadersList(file?.headers);
      } else {
        setOriginalHeadersList(null);
      }
    },
    [isMounted]
  );

  // Update bot config on form changes
  useEffect(() => {
    // is valid header mapping

    const validCharacterLength =
      characterLength?.value === 'custom' ? selectedCustomValue : characterLength?.value;

    if (selectedFile?.location && selectedHeadersColumns?.length > 0 && validCharacterLength > 0) {
      const botConfigObj = {
        container_name: selectedFile?.location,
        file_name: selectedFile?.name,
        character_length: parseInt(validCharacterLength),
        header_mapping: selectedHeadersColumns
          ? selectedHeadersColumns.map((columns) => columns.value)
          : [],
      };
      setValidSelections(true);
      setConfigData(botConfigObj);
    } else {
      setValidSelections(false);
    }
  }, [
    setConfigData,
    characterLength,
    selectedFile?.name,
    selectedCustomValue,
    selectedFile?.location,
    selectedHeadersColumns,
  ]);

  // Load primary column headers for selected file
  useEffect(() => {
    setOriginalHeadersList([]);
    setHeaderMappingChoices(undefined);
    setSelectedHeadersColumns(null);
    setSelectedFileHasError(false);
    setUploadedFilesMessageError('');
    setCharacterLength(null);
    setSelectedCustomValue('');
    doLoadHeaders(selectedFile);
  }, [selectedFile, doLoadHeaders]);

  useEffect(() => {
    const filteredHeaders = [...(originalHeadersList || [])];
    setHeaderMappingChoices(
      [...filteredHeaders].map((header) => ({ label: header, value: header }))
    );
  }, [originalHeadersList]);

  // Run handler assembles config object and triggers bot run
  const onRunBot = () => {
    handleRunBot(false, `/data-request/${dataJobId}`);
  };

  useEffect(() => {
    if (characterLength?.value === 'custom') {
      setFormCustomValueVisible(true);
    } else {
      setFormCustomValueVisible(false);
    }
  }, [characterLength]);

  useEffect(() => {
    if (!formCustomValueVisible) {
      setSelectedCustomValue('');
      setInputCustomValueError('');
    }
  }, [formCustomValueVisible]);

  useEffect(() => {
    if (!/^\d+$/.test(selectedCustomValue) && selectedCustomValue !== '') {
      setInputCustomValueError('Only positive numbers are allowed.');
    } else {
      setInputCustomValueError('');
    }
  }, [selectedCustomValue]);

  const handleSelectedCustomValueChange = (event) => {
    setSelectedCustomValue(event.target.value);
  };

  const renderBotBody = () => (
    <>
      <div className="config-panel">
        <div className="column">
          <SmallSelectFileSection
            title="Step 1: Select Your File"
            note="*Only the first sheet of the file will be updated"
            dataJobId={dataJobId}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            filesList={filesList}
            setFilesList={setFilesList}
            isLoading={isLoading}
            errorMessage={uploadedFilesMessageError}
          />

          <section>
            <div className="title">Step 2: Select the columns</div>
            <div className="note">
              *Select the product attributes you want to compress. Compression will be applied only
              to these column
            </div>
            <div className="content">
              <StyledMultiselect
                closeMenuOnSelect
                canReset
                isClearable
                isMulti
                defaultValue={selectedHeadersColumns}
                values={selectedHeadersColumns}
                options={headerMappingChoices}
                setOnChange={setSelectedHeadersColumns}
                getOptionValue={(option) => option.value}
                isDisabled={isLoading || !selectedFile || selectedFileHasError}
                menuPortalTarget={menuPortalTarget}
                isInvalid={
                  !isLoading &&
                  !uploadedFilesMessageError &&
                  selectedFile?.location &&
                  !selectedHeadersColumns
                }
              />
            </div>
          </section>
          <section className="header-selection">
            <div className="title">Step 3: Configure your bot</div>
            <div className="note">
              *The maximum number of characters you want your content to be compressed to.
            </div>
            <div className="content">
              <StyledMultiselect
                closeMenuOnSelect
                canReset
                isClearable
                isMulti={false}
                values={characterLength}
                options={BotConstants.ATTRIBUTES_COMPRESSION_CHARACTERS_LENGTH}
                setOnChange={setCharacterLength}
                getOptionValue={(option) => option.value}
                errorMessage="This field is required"
                menuPortalTarget={menuPortalTarget}
                isInvalid={
                  !isLoading &&
                  !uploadedFilesMessageError &&
                  selectedFile?.location &&
                  !characterLength
                }
              />

              {formCustomValueVisible && (
                <div className="form-control-custom-value">
                  <div className="note">*Custom Length must be a positive integer</div>
                  <Form.Control
                    type="text"
                    autoFocus
                    placeholder="Enter a positive integer"
                    value={selectedCustomValue}
                    onChange={handleSelectedCustomValueChange}
                    className={inputCustomValueError ? 'is-invalid' : ''}
                  />
                  {inputCustomValueError && (
                    <div className="invalid-feedback">{inputCustomValueError}</div>
                  )}
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
        {
          label: 'Total Products',
          value: additionalData?.total_products ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      title="Attribute Compression Databot"
      subtitle={
        <span>
          Use automation to reduce the character size of your attributes to the desired lengths.
          Select the attributes you want to compress and specify the limit, and you’re all set!
        </span>
      }
      bodyContent={renderBotBody()}
      requiresApproval
      preRunValidate={() => {
        if (
          !(
            selectedFile?.location &&
            selectedHeadersColumns.length > 0 &&
            (characterLength?.value > 0 || selectedCustomValue > 0)
          )
        ) {
          toast.error('Please select a file and select which columns will be used');
          return false;
        }
        return true;
      }}
      disableActions={{ run: !validSelections }}
      onRun={onRunBot}
      onCancel={() => handleCancelBot(`/data-request/${dataJobId}`)}
      confirmDialogTitle="Run Attribute Compression Databot?"
      confirmDialogBody={
        <>
          <p>You are about to:</p>
          Run the Attribute Compression Databot
        </>
      }
    />
  );
};
export { AttributeCompression };
