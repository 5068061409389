import React, { useState, useEffect } from 'react';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { getRootMenuPortalTarget } from 'helpers/Utils';

const HeaderSelector = ({
  selectedHeader,
  setSelectedHeader,
  headersList,
  placeholder,
  disabled,
  isMulti = true,
  selectStyle,
  menuPortalTarget,
}) => {
  const _menuPortalTarget = menuPortalTarget || getRootMenuPortalTarget();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (headersList?.length) {
      setOptions(
        headersList.map((item) => {
          return { value: item, label: item };
        })
      );
    } else {
      setOptions([]);
    }
  }, [headersList]);

  return (
    <div className="header-selector">
      <StyledMultiselect
        values={selectedHeader}
        options={options}
        setOnChange={(v) => {
          setSelectedHeader(v);
        }}
        getOptionValue={(option) => option.value}
        closeMenuOnSelect
        isMulti={isMulti}
        isClearable
        isSearchable
        optionsSort
        canReset
        isDisabled={disabled}
        menuPortalTarget={_menuPortalTarget}
        placeholder={placeholder ?? 'Select a column to add and arrange.'}
        selectStyle={selectStyle}
      />
    </div>
  );
};

export { HeaderSelector };
