import './DataJobLinkButton.scss';

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const DataJobLinkButton = ({ className, ...props }) => {
  return (
    <button type="button" className={classNames('data-job-link-btn', className)} {...props}>
      <FontAwesomeIcon icon={['far', 'link']} />
      Link
    </button>
  );
};

export { DataJobLinkButton };
