import { EXPORT_TYPES } from 'components/products-export/ExportFunctionalityPanelTableDef';
import { BotConstants } from 'constants/BotConstants';

const DataFillDefinitions = {
  [BotConstants.BOT_SLUGS.ATTRIBUTES_FILL]: {
    longName: 'General Attributes',
    name: 'Attributes',
    type: EXPORT_TYPES.general.value,
    displayLog: true,
  },
  [BotConstants.BOT_SLUGS.TAXONOMY_FILL]: {
    longName: 'Taxonomy',
    name: 'Taxonomy',
    type: EXPORT_TYPES.taxonomy.value,
    displayLog: false,
  },
};

export { DataFillDefinitions };
