import React, { useState, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { push } from 'connected-react-router';
import _get from 'lodash/get';
import { Dropdown } from 'react-bootstrap';
import ReactDOM from 'react-dom';
// import { useDispatch } from 'react-redux';

import * as ApiCalls from 'api/ApiCalls';
import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { DATA_JOB_DIRECTION_CONSTANTS } from 'constants/DataJobCreateConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useOutsideClickWithScroll } from 'helpers/useOutsideClick';
import { UserUtils } from 'helpers/UserUtils';
import { getRootMenuPortalTarget } from 'helpers/Utils';

/**
 * Renders action dropdown for Data Jobs row
 *
 * @param {Object} param Props
 * @param {Object} param.row Row data
 * @returns Render
 */
const DataJobsActionsDropdown = ({ row, setShowPublishModal }) => {
  const { activeUser } = RootHooks.useActiveUser();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef();
  const rootTarget = getRootMenuPortalTarget();
  // TODO: Re-enable this pending FEAT/BC-3398 changes
  // const dispatch = useDispatch();

  // const doShareDataJob = ({ shareData, onSuccess, onFailure, setIsVisible }) => {
  //   ApiCalls.doCall({
  //     method: ApiCalls.HTTP_METHODS.POST,
  //     urlPath: `/organisation/users/send-emails/`,
  //     data: shareData,
  //     onSuccess: () => {
  //       onSuccess();
  //       setIsVisible(false);
  //       toast.success('Job sent.');
  //     },
  //     onError: (err) => {
  //       onFailure(err);
  //     },
  //   });
  // };

  // const handleGoToFlashAssessmentReport = () => {
  //   if (row && row.flash_assessment && row.flash_assessment.id) {
  //     dispatch(push(`/flash-assessment/${row.flash_assessment.id}`));
  //   }
  // };

  const internalJob = DATA_JOB_DIRECTION_CONSTANTS.INTERNAL_DATA_JOB;

  const canPublishJob = () => {
    if (row.data_request_action_type === internalJob && row.assignee_user)
      return row.data_request_action_type === internalJob;
    return false;
  };

  useOutsideClickWithScroll(dropdownRef, () => {
    if (isDropdownVisible) {
      setIsDropdownVisible(false);
    }
  });

  const doDeleteDataJob = () => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.DELETE,
      urlPath: `/data-requests/${row.id}`,
      onSuccess: () => {
        toast.success('Data job deleted.');
        if (typeof row.onDeleteDataJob === 'function') {
          row.onDeleteDataJob(row.id);
        }
      },
    });
  };

  const canTryDeleteDataJob = () =>
    !UserUtils.isReadOnly(activeUser) && !UserUtils.isRoleAdmin(activeUser);

  return (
    <div
      className="action-dropdown"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown
        show={isDropdownVisible}
        onToggle={(isOpen) => {
          setIsDropdownVisible(isOpen);
        }}
      >
        <Dropdown.Toggle variant="light">
          <FontAwesomeIcon icon={['far', 'ellipsis-h']} />
        </Dropdown.Toggle>
        {ReactDOM.createPortal(
          <Dropdown.Menu
            ref={dropdownRef}
            bsPrefix="djl-filter-dropdown-menu dropdown-menu"
            popperConfig={{
              modifiers: [
                {
                  name: 'flip',
                  options: {
                    rootBoundary: rootTarget,
                  },
                },
              ],
            }}
          >
            <Dropdown.Item
              href={`/data-request/${row.id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="icon">
                <FontAwesomeIcon fixedWidth icon={['far', 'address-card']} />
              </span>
              View
            </Dropdown.Item>
            {/* TODO: Re-enable this pending FEAT/BC-3398 changes */}
            {/* <ShareModalPanel
          shareUrl={`${window.location.protocol}//${window.location.host}/data-request/${row.id}`}
          onShare={doShareDataJob}
        >
          {({ setIsVisible }) => (
            <Dropdown.Item onClick={() => setIsVisible(true)}>
              <span className="icon">
                <FontAwesomeIcon fixedWidth icon={['far', 'paper-plane']} />
              </span>
              Share
            </Dropdown.Item>
          )}
        </ShareModalPanel> */}
            {canTryDeleteDataJob() ? (
              <ConfirmDialog
                onConfirm={() => doDeleteDataJob()}
                headerContent="Delete Data Job?"
                bodyContent={`Are you sure you want to delete data job "${row.name}"?`}
              >
                {({ onClick }) => (
                  <Dropdown.Item
                    onClick={() => {
                      if (['new', 'canceled'].includes(row.status)) {
                        onClick();
                      } else {
                        toast.error(
                          'This Data Job is being processed and cannot be deleted. Please contact the BackboneAI Admin to delete.'
                        );
                      }
                    }}
                  >
                    <span className="icon">
                      <FontAwesomeIcon fixedWidth icon={['far', 'trash-alt']} />
                    </span>
                    Delete Job
                  </Dropdown.Item>
                )}
              </ConfirmDialog>
            ) : null}
            {canPublishJob() ? (
              <Dropdown.Item onClick={() => setShowPublishModal(row.id)}>
                <span className="icon dropdown-icon">
                  <FontAwesomeIcon fixedWidth icon={['far', 'plus']} />
                </span>
                Publish Draft Job
              </Dropdown.Item>
            ) : null}
          </Dropdown.Menu>,
          document.body
        )}
      </Dropdown>
    </div>
  );
};

export { DataJobsActionsDropdown };
