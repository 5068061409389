import React from 'react';

import Moment from 'react-moment';

import { MIN_DATE } from 'constants/DateTimeConstants';
import * as Utils from 'helpers/Utils';

const formatDateCell = (v) => (v ? <Moment format={MIN_DATE} date={v} /> : 'N/A');
const dateSortFunc = (a, b, order) => {
  const dateA = new Date(a);
  const dateB = new Date(b);
  return order === 'asc' ? dateA - dateB : dateB - dateA;
};

const DatabotsUsageMetricsTableDef = {
  botDownloadsColumns: [
    {
      ...Utils.getDefaultTableColumnDef('downloaded_at', 'Downloaded At'),
      formatter: (_cell, row) => formatDateCell(row.downloaded_at),
      sortFunc: dateSortFunc,
    },
    {
      ...Utils.getDefaultTableColumnDef('email', 'Email'),
    },
    {
      ...Utils.getDefaultTableColumnDef('first_name', 'First Name'),
    },
    {
      ...Utils.getDefaultTableColumnDef('last_name', 'Last Name'),
    },
    {
      ...Utils.getDefaultTableColumnDef('name', 'Name'),
    },
    {
      ...Utils.getDefaultTableColumnDef('description', 'Description'),
    },
  ],

  botStartsColumns: [
    {
      ...Utils.getDefaultTableColumnDef('created_at', 'Created At'),
      formatter: (_cell, row) => formatDateCell(row.created_at),
      sortFunc: dateSortFunc,
    },
    {
      ...Utils.getDefaultTableColumnDef('bot_id', 'Bot ID'),
    },
    {
      ...Utils.getDefaultTableColumnDef('updated_at', 'Updated At'),
      formatter: (_cell, row) => formatDateCell(row.updated_at),
      sortFunc: dateSortFunc,
    },
    {
      ...Utils.getDefaultTableColumnDef('status', 'Status'),
    },
    {
      ...Utils.getDefaultTableColumnDef('started_at', 'Started At'),
      formatter: (_cell, row) => formatDateCell(row.started_at),
      sortFunc: dateSortFunc,
    },
    {
      ...Utils.getDefaultTableColumnDef('finished_at', 'Finished At'),
      formatter: (_cell, row) => formatDateCell(row.finished_at),
      sortFunc: dateSortFunc,
    },
    {
      ...Utils.getDefaultTableColumnDef('approved_at', 'Approved At'),
      formatter: (_cell, row) => formatDateCell(row.approved_at),
      sortFunc: dateSortFunc,
    },
    {
      ...Utils.getDefaultTableColumnDef('approved_finished_at', 'Approved Finished At'),
      formatter: (_cell, row) => formatDateCell(row.approved_finished_at),
      sortFunc: dateSortFunc,
    },
    {
      ...Utils.getDefaultTableColumnDef('rejected_at', 'Rejected At'),
      formatter: (_cell, row) => formatDateCell(row.rejected_at),
      sortFunc: dateSortFunc,
    },
    {
      ...Utils.getDefaultTableColumnDef('data_request_id', 'Data Job ID'),
    },
    {
      ...Utils.getDefaultTableColumnDef('email', 'Email'),
    },
    {
      ...Utils.getDefaultTableColumnDef('first_name', 'First Name'),
    },
    {
      ...Utils.getDefaultTableColumnDef('last_name', 'Last Name'),
    },
    {
      ...Utils.getDefaultTableColumnDef('name', 'Name'),
    },
    {
      ...Utils.getDefaultTableColumnDef('description', 'Description'),
    },
  ],
};

export { DatabotsUsageMetricsTableDef };
