import React, { useCallback, useState } from 'react';

import { PropTypes } from 'prop-types';

import * as ApiCalls from 'api/ApiCalls';
import { AddLinkButton } from 'components/common/AddLinkButton/AddLinkButton';
import { FileUpload } from 'components/common/FileUpload/FileUpload';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { checkFileExtAndSize } from 'helpers/Utils';

const DataJobUploadFile = ({
  dataRequestId,
  isUploading,
  setIsUploading,
  setFileOrLink,
  // setFileTransformation,
  setUploadId,
  uploadId,
  // isTransformationRequired,
}) => {
  const isMounted = useIsMounted();
  // only setIsUploadComplete to true in this component, child will turn itself off on a timer when uploadcomplete is set to true
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('0');

  const handleUpload = useCallback(
    async (type, data) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/data-requests/attachments/`,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
        isFileFormData: true,
        onProgress: (progressEvent) => {
          const percentage = ApiCalls.getProgressPercent(progressEvent);
          if (percentage >= 0) setUploadProgress(percentage.toFixed(0));
        },
        onSuccess: (response) => {
          if (isMounted.current) {
            setIsUploadComplete(true);
            setIsUploading(false);
            response.data.forEach((f) => {
              setUploadId(f.id);
              setFileOrLink({ type, payload:f });
            });
          }
        },
        onError: () => {
          if (isMounted.current) {
            setIsUploading(false);
          }
        },
      });
    },
    [isMounted, setFileOrLink, setIsUploading, setUploadId]
  );

  const onAddLink = useCallback(
    async (link) => {
      setIsUploading(true);
      const dataRequestAttachment = {
        data_request_id: dataRequestId,
        name: link,
        file_type: 'externalLink',
        size: 0,
        original_name: link,
      };
      const formData = new FormData();
      Object.keys(dataRequestAttachment).forEach((k) => {
        if (dataRequestAttachment[k] !== undefined) formData.append(k, dataRequestAttachment[k]);
      });
      handleUpload('link', formData);
    },
    [dataRequestId, handleUpload, setIsUploading]
  );

  const onFileClicked = useCallback(
    async (e) => {
      const typeCallback = (name) => toast.error(`File type of "${name}" not allowed.`);
      const sizeCallback = () =>
        toast.error(
          'The file you are trying to upload exceeds the 2GB attachment limit. Try putting in a shared location and adding a link instead.'
        );

      const file = checkFileExtAndSize(e, typeCallback, sizeCallback);
      if (!file) {
        return;
      }
      setIsUploading(true);
      const files = [];
      e.forEach((f) => {
        const file = checkFileExtAndSize([f], typeCallback, sizeCallback);
        if (!file) {
          return;
        }
        files.push(file);
      });
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(`file[${i}]`, file)
      });
      formData.append('is_pass_thru_file', 0);
      formData.append('transformation_required', 1);
      handleUpload('file', formData);
    },
    [handleUpload, setIsUploading]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // filter to only selected amount of files
      if (acceptedFiles.length > 0) {
        onFileClicked(acceptedFiles);
      }
    },
    [onFileClicked]
  );

  return (
    <section>
      <FileUpload
        onDrop={onDrop}
        isFileLoading={isUploading}
        maxFileCount={10}
        uploadProgress={uploadProgress}
        isUploadComplete={isUploadComplete}
        uploadId={uploadId}
        setIsUploadComplete={setIsUploadComplete}
        onSuccessMessage="File Successfully Added!"
      >
        <AddLinkButton handleAddLink={onAddLink} />
      </FileUpload>
    </section>
  );
};

DataJobUploadFile.propTypes = {
  dataRequestId: PropTypes.number,
  setIsUploading: PropTypes.func,
  isUploading: PropTypes.bool,
  setFileOrLink: PropTypes.func,
  uploadId: PropTypes.number,
  setUploadId: PropTypes.func,
};

export { DataJobUploadFile };
