import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getDefaultTableColumnDef } from 'helpers/Utils';

import { ImgLightbox } from '../../common/ImgLightbox/ImgLightbox';

const imgNameFormatter = (v) => {
  let fName = v ?? null;
  let fExt = null;

  const fNameMatches = v?.match(/(.*)\.([a-z0-9_]{2,4})$/i) ?? null;

  if (fNameMatches?.length === 3 && fNameMatches[1]?.length && fNameMatches[2]?.length) {
    fName = fNameMatches[1];
    fExt = `.${fNameMatches[2]}`;
  }

  return (
    <div className="file-block">
      <div className="file-block-name">
        {!!fName?.length && <span className="fname">{fName}</span>}
        {!!fExt?.length && <span className="fext">{fExt}</span>}
      </div>
    </div>
  );
};

const imgLinkFormatter = (title) => {
  return (v) => {
    return v ? (
      <ImgLightbox src={v} title={title} imageBackgroundColor="transparent">
        {({ doOpen }) => (
          <button type="button" className="link" onClick={() => doOpen()}>
            <FontAwesomeIcon icon={['far', 'file-search']} />
          </button>
        )}
      </ImgLightbox>
    ) : (
      'N/A'
    );
  };
};

const tableTemplate = {
  original_name: { key: 'original_name', label: 'Filename', formatter: imgNameFormatter },
  original_image: {
    key: 'original_image',
    label: 'Original Image',
    formatter: imgLinkFormatter('Original Image'),
  },
  cropped: { key: 'cropped', label: 'Cropping', formatter: imgLinkFormatter('Cropping') },
  enlarged: { key: 'enlarged', label: 'Enlargement', formatter: imgLinkFormatter('Enlargement') },
  bgremoved: {
    key: 'bgremoved',
    label: 'Background Removal',
    formatter: imgLinkFormatter('Background Removal'),
  },
  completed: {
    key: 'completed',
    label: 'Enhanced Image',
    formatter: imgLinkFormatter('Enhanced Image'),
  },
};

const BeforeAfterImgTableDef = {
  columns: Object.keys(tableTemplate).map((item) => ({
    ...getDefaultTableColumnDef(item, tableTemplate[item].label),
    sort: false,
    formatter: (_cell, row) => tableTemplate[item].formatter(row[item]),
  })),
};

export { BeforeAfterImgTableDef };
