import ActionStatusConstants from "constants/ActionStatusConstants";
import ActionTypeConstants from "constants/ActionTypeConstants";

const initialState = {
  status: ActionStatusConstants.INITIAL,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.SUBMIT_USER_LOGOUT:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.SUBMIT_USER_LOGOUT_SUCCESS:
      return {
        ...state,
        status: ActionStatusConstants.SUCCESS,
        payload,
      };
    case ActionTypeConstants.SUBMIT_USER_LOGOUT_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        payload,
      };
    default:
  }
  return state;
};
