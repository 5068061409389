/**
 * Case-insensitive regex for one of (xlsx, xlsx, csv)
 */
export const sheetRegex = /\.(xls|xlsx|csv)$/i;

/**
 * Case-insensiive regex for one of (dxf, zip)
 */
export const cadRegex = /\.(dxf|zip)$/i;

const FileConstants = {
  sheetRegex,
  cadRegex,
};

export { FileConstants };
