import React, { useContext } from 'react';

import { DataIngestionDataQualityContext } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQualityContext';
import { DataIngestionDataQualityOverview } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQualityOverview';
import { DataIngestionDataQualitySheetSelect } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQualitySheetSelect';
import { DataQualityFooter } from 'components/data-quality/DataQualityFooter/DataQualityFooter';
import { DataQualityTable } from 'components/data-quality/DataQualityTable/DataQualityTable';
import './DataIngestionDataQuality.scss';
import { RootHooks } from 'helpers/RootHooks';

const DataIngestionDataQuality = ({ sheetsMessage }) => {
  const { activeUser } = RootHooks.useActiveUser();

  const userRole = activeUser?.role ?? null;

  const ctx = useContext(DataIngestionDataQualityContext);
  const {
    attachmentData,
    attachmentSheetsData,
    activeSheet,
    reloadAttachmentSheetsData,
    downloadFile,
    startIngestion,
  } = ctx;
  return (
    <div className="data-ingestion-data-quality">
      <DataIngestionDataQualityOverview />
      {attachmentSheetsData && attachmentSheetsData.length ? (
        <>
          <DataIngestionDataQualitySheetSelect />
          {activeSheet ? (
            <div className="data-quality-table-wrap">
              <DataQualityTable
                activeSheetData={activeSheet}
                updateSheet={() =>
                  typeof reloadAttachmentSheetsData === 'function' && reloadAttachmentSheetsData()
                }
                doDownload={() => typeof downloadFile === 'function' && downloadFile()}
                sheetCount={attachmentSheetsData?.length}
              />
            </div>
          ) : null}
          <DataQualityFooter
            fileId={attachmentData?.id}
            doDownload={() => typeof downloadFile === 'function' && downloadFile()}
            doStartIngestion={() => typeof startIngestion === 'function' && startIngestion()}
            userRole={userRole}
          />
        </>
      ) : sheetsMessage !== null ? (
        <div className="data-ingestion-data-quality-error-message">{sheetsMessage}</div>
      ) : null}
    </div>
  );
};

export { DataIngestionDataQuality };
