import './Bots.scss';

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const BotFileChip = ({ bot, withTooltip = false, tooltipText = '', tooltipPlacement = 'top' }) => {
  const baseChip = (
    <div key={bot.id} className="bot-file-chip">
      <FontAwesomeIcon className="cubes-icon" icon={['far', 'cubes']} />
    </div>
  );

  return withTooltip ? (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={<Tooltip bsPrefix="bai-tooltip tooltip">{tooltipText}</Tooltip>}
    >
      {baseChip}
    </OverlayTrigger>
  ) : (
    baseChip
  );
};

export { BotFileChip };
