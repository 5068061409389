import * as ApiCalls from 'api/ApiCalls';
import ActionStatusConstants from 'constants/ActionStatusConstants';

const doGetStats = ({
  mfrId,
  isMounted,
  setProdCountData,
  setAttributesCount,
  setManufacturerName,
  orderBy,
}) => {
  if (isMounted.current) {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/products/export/attributes/detailed/stats?manufacturer_id=${mfrId}&exclude=true&order_by=${orderBy}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          const _data = res?.data || null;
          setProdCountData(_data.total_products);
          setAttributesCount(_data.attributes_count);
          setManufacturerName(_data.manufacturer_name);
        }
      },
      onError: () => {},
    });
  }
};

/**
 * Get attributes list by manufacturer id
 *
 * @param {int} mfrId
 * @param {object} isMounted ref
 * @param {function} setAttributesData Result callback
 * @param {function} setAttributesDataStatus Status callback
 */
const doGetAttributes = ({ mfrId, isMounted, setAttributesData, setAttributesDataStatus, orderBy }) => {
  if (isMounted.current) {
    setAttributesDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/products/export/attributes/',
      params: { manufacturer_id: mfrId, offset: 0, limit: 5000, type:'detailed', exclude:true, order_by: orderBy},
      onSuccess: (res) => {
        if (isMounted.current) {
          setAttributesDataStatus(ActionStatusConstants.SUCCESS);
          const _data = res?.data || null;
          setAttributesData(_data);
        }
      },
      onError: () => {
        setAttributesDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }
};

export { doGetStats, doGetAttributes };
