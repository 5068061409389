import React, { useEffect, useRef, useState } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { Alert, Form, Modal } from 'react-bootstrap';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import * as ExportFunctionalityAdvancedApiCalls from './ExportFunctionalityAdvancedApiCalls';
import * as ExportFunctionalityAdvancedUtils from './ExportFunctionalityAdvancedUtils';

/**
 * Modal form for creating new templates
 *
 * @param {object} templateData Current template object state
 * @param {object} initMfrSelectValue Initial mfr/child selection to populate the form with
 * @param {function} onSaveTemplate Post-save callback
 * @param {object} children Child node
 * @return render
 */
const CreateTemplateModalPanel = ({
  templateData,
  initMfrSelectValue,
  onSaveTemplate,
  children,
}) => {
  const isMounted = useIsMounted();

  // Modal visibilty state
  const [isVisible, setIsVisible] = useState(false);

  // Holds list of manufacturers
  const [mfrsData, setMfrsData] = useState(null);
  const [mfrsDataStatus, setMfrsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds list of child mfrs per selected mfr
  const [childMfrsData, setChildMfrsData] = useState(null);
  const [childMfrsDataStatus, setChildMfrsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds list of distributors
  const [distributorsData, setDistributorsData] = useState(null);
  const [distributorsDataStatus, setDistributorsDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  // Holds list of templates per selected mfr
  const [templatesData, setTemplatesData] = useState(null);
  const [templatesDataStatus, setTemplatesDataStatus] = useState(ActionStatusConstants.INITIAL);

  const [saveTemplateStatus, setSaveTemplateStatus] = useState(ActionStatusConstants.INITIAL);

  const [mfrSelectValue, setMfrSelectValue] = useState(null);
  // Holds previous mfr selection
  const prevMfrSelectValue = useRef(null);

  const [distributorSelectValue, setDistributorSelectValue] = useState(null);

  const [templateNameValue, setTemplateNameValue] = useState(null);

  // Init calls
  useEffect(() => {
    if (!isVisible) {
      return;
    }

    ExportFunctionalityAdvancedApiCalls.doGetMfrs({
      isMounted,
      setMfrsData,
      setMfrsDataStatus,
    });
  }, [isMounted, isVisible]);

  // Init mfr select value
  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (mfrSelectValue === null) {
      setMfrSelectValue(_cloneDeep(initMfrSelectValue));
    }
  }, [isMounted, initMfrSelectValue, mfrSelectValue, isVisible]);

  // Handles mfr selection changes
  useEffect(() => {
    if (!isVisible) {
      return;
    }

    // Calculate changes
    const isMfrChanged =
      prevMfrSelectValue.current?.manufacturer?.id !== mfrSelectValue?.manufacturer?.id;
    const isChildMfrChanged =
      prevMfrSelectValue.current?.manufacturerChild?.id !== mfrSelectValue?.manufacturerChild?.id;

    prevMfrSelectValue.current = mfrSelectValue;

    if (isMfrChanged || isChildMfrChanged) {
      // Reset dependent state
      setDistributorSelectValue(null);
      setTemplateNameValue(null);

      const exportMfrId = ExportFunctionalityAdvancedUtils.getExportMfrId(mfrSelectValue);

      // If we have a selected mfr or child mfr, retrieve relevant
      // data - templates, distributor
      if (exportMfrId) {
        ExportFunctionalityAdvancedApiCalls.doGetTemplates({
          mfrId: exportMfrId,
          isMounted,
          setTemplatesData,
          setTemplatesDataStatus,
        });

        ExportFunctionalityAdvancedApiCalls.doGetDistributors({
          mfrId: exportMfrId,
          isMounted,
          setDistributorsData,
          setDistributorsDataStatus,
        });
      }

      // If parent mfr has changed, reset child mfrs data and selection,
      // and get relevant child mfr list for that mfr
      if (isMfrChanged) {
        // Reset child when mfr is changed
        setChildMfrsData(null);
        setMfrSelectValue({ ...mfrSelectValue, manufacturerChild: null });

        if (mfrSelectValue?.manufacturer?.id) {
          // Load children of newly selected mfr
          ExportFunctionalityAdvancedApiCalls.doGetChildMfrs({
            mfrId: mfrSelectValue.manufacturer.id,
            isMounted,
            setChildMfrsData,
            setChildMfrsDataStatus,
          });
        }
      }
    }
  }, [isMounted, mfrSelectValue, isVisible]);

  const getSelectedMfrOption = () => {
    const selectedMfr = mfrSelectValue?.manufacturer;
    return selectedMfr ? [{ value: selectedMfr.id, label: selectedMfr.name }] : [];
  };

  const getSelectedChildMfrOption = () => {
    const selectedChildMfr = mfrSelectValue?.manufacturerChild;
    return selectedChildMfr ? [{ value: selectedChildMfr.id, label: selectedChildMfr.name }] : [];
  };

  const getSelectedDistributorOption = () => {
    return distributorSelectValue
      ? [{ value: distributorSelectValue.id, label: distributorSelectValue.name }]
      : [];
  };

  const doSelectMfr = (id) => {
    const mfrObj = id && mfrsData?.find((item) => item.id === id);

    setMfrSelectValue({
      ...mfrSelectValue,
      manufacturer: mfrObj || null,
    });
  };

  const doSelectChildMfr = (id) => {
    const childMfrObj = id && childMfrsData?.find((item) => item.id === id);

    setMfrSelectValue({
      ...mfrSelectValue,
      manufacturerChild: childMfrObj || null,
    });
  };

  const doSelectDistributor = (id) => {
    const distributorObj = id && distributorsData?.find((item) => item.id === id);

    setDistributorSelectValue(distributorObj);
  };

  const doSaveTemplate = () => {
    const templateName = templateNameValue?.length ? templateNameValue : null;
    const templateMfrId = ExportFunctionalityAdvancedUtils.getExportMfrId(mfrSelectValue) || null;
    const templateDistrId = distributorSelectValue?.id || null;

    if (!(templateName && templateMfrId)) {
      toast.error('Please input a Manufacturer and Template Name.');
      return;
    }

    const newTemplateData = _cloneDeep(templateData);

    // Try find a template with the same name to overwrite
    const existingTemplate = templatesData?.find(
      (item) =>
        item?.template_name?.length &&
        templateName?.length &&
        item?.template_name?.toUpperCase() === templateName.toUpperCase()
    );

    newTemplateData.template_name = templateName;
    newTemplateData.manufacturer_id = templateMfrId;
    newTemplateData.distributor_id = templateDistrId || null;
    newTemplateData.id = existingTemplate?.id || null;

    console.debug('Saving template', templateData, newTemplateData, existingTemplate);

    ExportFunctionalityAdvancedApiCalls.doSaveTemplate({
      template: newTemplateData,
      isMounted,
      onSaveTemplate: (data) => {
        setIsVisible(false);
        onSaveTemplate(data);
      },
      setSaveTemplateStatus,
    });
  };

  const getTemplateNameErrors = () => {
    let output = null;
    if (/custom template/i.test(templateNameValue)) {
      output = { allowSave: false, msg: 'Invalid template name' };
    } else if (
      templatesData?.some(
        (item) =>
          item?.template_name?.length &&
          templateNameValue?.length &&
          item.template_name.toUpperCase() === templateNameValue.toUpperCase()
      )
    ) {
      output = {
        allowSave: true,
        msg: 'There is already a template by that name. You would overwrite it if you save.',
      };
    }

    return output;
  };

  const renderChildren = () => {
    return typeof children === 'function' ? children({ setIsVisible }) : null;
  };

  const renderTemplateNameErrors = () => {
    let output = null;

    const nameErrs = getTemplateNameErrors();

    if (nameErrs) {
      output = <Alert variant={nameErrs.allowSave ? 'warning' : 'danger'}>{nameErrs.msg}</Alert>;
    }

    return output;
  };

  const isBusy = [
    mfrsDataStatus,
    childMfrsDataStatus,
    distributorsDataStatus,
    templatesDataStatus,
    saveTemplateStatus,
  ].includes(ActionStatusConstants.ISBUSY);

  return (
    <>
      {renderChildren()}

      {isVisible && (
        <Modal
          show={isVisible}
          onHide={() => setIsVisible(false)}
          className="modal-export-functionality-advanced-create-template"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="block-manufacturer-select">
                <div className="manufacturer">
                  <div className="title">Manufacturer</div>
                  <StyledMultiselect
                    values={getSelectedMfrOption()}
                    options={
                      mfrsData
                        ? mfrsData.map((item) => {
                            return { value: item.id, label: item.name };
                          })
                        : []
                    }
                    setOnChange={(v) => {
                      doSelectMfr(v?.value || null);
                    }}
                    getOptionValue={(option) => option.value}
                    closeMenuOnSelect
                    isSearchable
                    isDisabled={isBusy}
                    isMulti={false}
                    isClearable
                    canReset
                  />
                </div>
                {mfrSelectValue?.manufacturer && childMfrsData?.length ? (
                  <div className="child-company">
                    <div className="title">Level 1 Brand</div>
                    <StyledMultiselect
                      values={getSelectedChildMfrOption() || []}
                      options={
                        childMfrsData
                          ? childMfrsData.map((item) => {
                              return { value: item.id, label: item.name };
                            })
                          : []
                      }
                      setOnChange={(v) => doSelectChildMfr(v?.value || null)}
                      getOptionValue={(option) => option.value}
                      closeMenuOnSelect
                      isSearchable
                      isDisabled={isBusy}
                      isMulti={false}
                      isClearable
                      canReset
                    />
                  </div>
                ) : null}
              </div>
              {!!distributorsData?.length && (
                <div className="distributor">
                  <div className="title">Distributor</div>
                  <StyledMultiselect
                    values={getSelectedDistributorOption() || []}
                    options={
                      distributorsData
                        ? distributorsData.map((item) => {
                            return { value: item.id, label: item.name };
                          })
                        : []
                    }
                    setOnChange={(v) => doSelectDistributor(v?.value || null)}
                    getOptionValue={(option) => option.value}
                    closeMenuOnSelect
                    isSearchable
                    isDisabled={isBusy}
                    isMulti={false}
                    isClearable
                    canReset
                  />
                </div>
              )}
              <div className="template-name">
                <div className="title">Template Name</div>
                <Form.Control
                  type="text"
                  value={templateNameValue || ''}
                  onChange={(e) => setTemplateNameValue(e.target.value)}
                  disabled={isBusy}
                />
                {renderTemplateNameErrors()}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              title="Cancel"
              className="btn btn-secondary btn-cancel"
              disabled={isBusy}
              onClick={() => setIsVisible(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              title="Save Template"
              className="btn btn-primary btn-save-template"
              disabled={isBusy}
              onClick={doSaveTemplate}
            >
              {saveTemplateStatus === ActionStatusConstants.ISBUSY ? (
                <span className="icon">
                  <LoadingSpinner fast />
                </span>
              ) : null}
              Save Template
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

CreateTemplateModalPanel.defaultProps = {
  templateData: null,
  initMfrSelectValue: null,
};

CreateTemplateModalPanel.propTypes = {
  templateData: PropTypes.object,
  initMfrSelectValue: PropTypes.object,
  onSaveTemplate: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export { CreateTemplateModalPanel };
