import { cloneDeep } from 'lodash';

import * as ApiCalls from '../../../api/ApiCalls';
import { IdsConstants } from './IdsConstants';

const validateNotEmpty = (v) => {
  if (v !== undefined && v !== '') {
    return { value: v, err: undefined };
  }
  return { value: v, err: 'Please input a value.' };
};

const validateNumber = (v, type = 'any', positiveOnly = false) => {
  const vr = validateNotEmpty(v);
  if (!vr.err) {
    vr.value = Number(v);

    if (Number.isNaN(vr.value)) {
      return { value: v, err: 'Please input a valid number.' };
    }
    if (type === 'integer') {
      if (!Number.isInteger(vr.value)) {
        return { value: vr.value, err: 'Please input a valid integer.' };
      }
      if (positiveOnly && vr.value < 0) {
        return { value: vr.value, err: 'Please provide a positive value.' };
      }
    }
    if (type === 'percentage') {
      vr.value /= 100;
      if (vr.value < 0 || vr.value > 1.0) {
        return {
          value: Math.round(vr.value),
          err: 'Please input a valid percentage.',
        };
      }
    }
  }
  return vr;
};

const update = ({ o, v, attrKey, valueKey }) => {
  const no = cloneDeep(o);
  no[attrKey][valueKey] = v;
  return no;
};

const validateAndUpdate = ({
  o,
  v,
  attrKey,
  valueKey,
  type = 'any',
  positiveOnly = false,
  validationFn,
}) => {
  let vr;
  if (type === 'any') {
    vr = validateNotEmpty(v);
  } else if (type === 'custom') {
    vr = validationFn ? validationFn(v) : v;
  } else {
    vr = validateNumber(v, type, positiveOnly);
  }
  const no = update({ o, v: vr.value, attrKey, valueKey });
  no[attrKey][`err_${valueKey}`] = vr.err;
  return no;
};

const formatPercentage = (v) => {
  return v === '' || Number.isNaN(v) ? v : (v * 100).toFixed(0);
};

const containsError = (cfg) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in cfg) {
    if (cfg.hasOwnProperty(key)) {
      const value = cfg[key];
      if (typeof value === 'object') {
        if (containsError(value)) {
          return true;
        }
      } else if (typeof value === 'string' && key.startsWith('err_') && value.length > 0) {
        return true;
      }
    }
  }
  return false;
};

const validateHostName = (v) => {
  const hostRegex =
    /^(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?!:\/\/)(?:(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+(?:[A-Za-z]{2,7}|xn--[A-Za-z0-9]{2,30}))$)/;
  let message;

  if (v === '' || !hostRegex.test(v)) message = IdsConstants.FORM_ERROR_MESSAGES.valid_host;

  return { value: v, err: message };
};

const validateLocation = (v) => {
  const folderNameRegex = /^[^#%&{}<>*?$!'":@+`=]*$/;
  let message;

  if (v !== '') {
    if (!folderNameRegex.test(v)) {
      message = IdsConstants.FORM_ERROR_MESSAGES.valid_path;
    }
  }

  return { value: v, err: message };
};

const getConfigTypeConst = (cfg) => {
  return IdsConstants.IDS_CONFIG_TYPES[cfg?.bot?.slug?.replaceAll('-', '_')];
};

const loadDefaultConfig = ({ onSuccess, onError, onEnd }) => {
  ApiCalls.doCall({
    method: ApiCalls.HTTP_METHODS.GET,
    urlPath: `/bots/score-config/default/ids`,
    onSuccess: (res) => onSuccess?.(res),
    onError: (err) => onError?.(err),
    onEnd: () => onEnd?.(),
  });
};

const loadIdsConfigs = ({
  onSuccess,
  onError,
  onEnd,
  slugs = undefined,
  limit = undefined,
  offset = undefined,
}) => {
  let slugParam = slugs;
  if (!slugParam) {
    slugParam = [
      IdsConstants.IDS_CONFIG_TYPES.ids_enhancement.slug,
      IdsConstants.IDS_CONFIG_TYPES.ids_initial_assessment.slug,
    ];
  }
  const reqParams = {
    slugs: slugParam?.join(','),
    limit,
    offset,
  };
  ApiCalls.doCall({
    method: ApiCalls.HTTP_METHODS.GET,
    urlPath: '/bots/score-config',
    params: reqParams,
    onSuccess: (res) => onSuccess(res),
    onEnd: () => onEnd(),
    onError: (err) => onError(err),
  });
};
const CustomBotConfigUtils = {
  containsError,
  validateNumber,
  validateNotEmpty,
  formatPercentage,
  validateAndUpdate,
  update,
  validateHostName,
  validateLocation,
  getConfigTypeConst,
  loadDefaultConfig,
  loadIdsConfigs,
};
export { CustomBotConfigUtils };
