import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { FileConstants } from 'constants/FileConstants';
import { toast } from 'helpers/ToastUtils';
import { useDefaultConfigData } from 'helpers/useDefaultConfigData';

import './ImgBotEngineModal.scss';
import { RootHooks } from '../../../helpers/RootHooks';
import { UserUtils } from '../../../helpers/UserUtils';
import { getIconWithPopover } from '../../../helpers/Utils';
import { IdsHeaderSelection } from './_ids_configs/IdsHeaderSelection';
import { IdsConfigSelector } from './IdsConfigSelector';
import './ImgBotEngineModalTrigger.scss';

/**
 * Modal panel body for running the IDS. Allows review of what is
 * to be assessed and set up additional parameters - a required column header for
 * table-based uploads, and optional custom bot config parameters
 *
 * @param {object} imgJob Image datajob, containing process_job
 * @param {function} setIsVisible Handler for closing the parent modal panel component
 * @param {boolean} isLoading Loading state
 * @param {function} onSuccess Callback handler for run success used in NewJobSection
 *
 * @param {boolean} isEnhancementOnly set to true when the modal is used to trigger an Enhancement
 * @return render
 */
const ImgBotEngineModalTrigger = ({
  imgJob,
  setIsVisible,
  isLoading,
  onSuccess,
  isEnhancementOnly = false,
}) => {
  // Selected column headers for xls/csv uploads
  const { activeUser } = RootHooks.useActiveUser();
  const defaultConfigData = useDefaultConfigData({ defaultConfigType: 'ids' });

  const [selectedUrlHeader, setSelectedUrlHeader] = useState(null);
  const [selectedSkuHeader, setSelectedSkuHeader] = useState(null);
  const [selectedImgJob, setSelectedImgJob] = useState({ ...imgJob.process_job });

  const [assessmentConfig, setAssessmentConfig] = useState(null);
  const [enhancementConfig, setEnhancementConfig] = useState(null);
  const [externalDeliveryConfig, setExternalDeliveryConfig] = useState();

  const [requiresHeaders] = useState(
    FileConstants.sheetRegex.test(imgJob?.process_job.additional_data[0]?.file_name)
  );
  const [tabActiveKey, setTabActiveKey] = useState(
    isEnhancementOnly
      ? 'enhancement'
      : FileConstants.sheetRegex.test(imgJob?.process_job?.additional_data[0]?.file_name)
      ? 'headers'
      : 'assessment'
  );

  const [isE2ERunEnabled, setIsE2ERunEnabled] = useState(false);
  const [isExtDeliverEnabled, setIsExtDeliverEnabled] = useState(false);
  const [assessmentFormValidationRes, setAssessmentFormValidationRes] = useState(null);
  const [enhancementFormValidationRes, setEnhancementFormValidationRes] = useState(null);
  const [externalDeliveryFormValidationRes, setExternalDeliveryFormValidationRes] = useState(null);

  useEffect(() => {
    setSelectedImgJob((prev) => ({ ...prev, config_data: defaultConfigData }));
  }, [defaultConfigData]);

  useEffect(() => {
    if (selectedImgJob?.config_data) {
      setAssessmentConfig((prev) => ({
        configuration: prev?.configuration || cloneDeep(selectedImgJob?.config_data?.assessment),
      }));
      setEnhancementConfig((prev) => ({
        configuration: prev?.configuration || cloneDeep(selectedImgJob?.config_data?.enhancement),
      }));
      setExternalDeliveryConfig((prev) => ({
        configuration:
          prev?.configuration || cloneDeep(selectedImgJob?.config_data?.enhancement ?? {}),
        is_enabled: !!selectedImgJob?.config_data?.external_delivery?.is_enabled || false,
      }));
    }
  }, [selectedImgJob]);

  useEffect(() => {
    if (!isE2ERunEnabled) {
      if (!isEnhancementOnly && tabActiveKey === 'enhancement') {
        setTabActiveKey('assessment');
      }
      setEnhancementConfig((prev) => ({
        configuration: prev?.configuration || cloneDeep(selectedImgJob?.config_data?.enhancement),
      }));
      setEnhancementFormValidationRes(null);
    }
    if (!isExtDeliverEnabled) {
      if (tabActiveKey === 'external_delivery') {
        setTabActiveKey(isEnhancementOnly ? 'enhancement' : 'assessment');
      }
      setExternalDeliveryConfig((prev) => ({
        configuration:
          prev?.configuration || cloneDeep(selectedImgJob?.config_data?.enhancement ?? {}),
        is_enabled: !!selectedImgJob?.config_data?.external_delivery?.is_enabled || false,
      }));
      setExternalDeliveryFormValidationRes(null);
    }
  }, [
    isEnhancementOnly,
    selectedImgJob?.config_data?.enhancement,
    tabActiveKey,
    isE2ERunEnabled,
    isExtDeliverEnabled,
    selectedImgJob?.config_data?.external_delivery,
  ]);

  const onRunProcessing = () => {
    let _selectedUrlHeader = null;
    let _selectedSkuHeader = null;

    const _cfg = {
      assessment: assessmentConfig.configuration,
      enhancement:
        isE2ERunEnabled || isEnhancementOnly
          ? enhancementConfig.configuration
          : selectedImgJob?.config_data?.enhancement,
      miscellaneous: { e2e_enabled: isE2ERunEnabled },
      external_delivery: isExtDeliverEnabled
        ? {
            is_enabled: isExtDeliverEnabled,
            ...externalDeliveryConfig.configuration,
          }
        : undefined,
    };

    // Stop if no header is selected when required
    if (requiresHeaders && !isEnhancementOnly) {
      if (selectedUrlHeader?.value?.length) {
        _selectedUrlHeader = selectedUrlHeader.value;
      } else {
        toast.error('Please select a column header first.');
        return;
      }

      if (selectedSkuHeader?.value?.length) {
        _selectedSkuHeader = selectedSkuHeader.value;
      }
    }

    if (isEnhancementOnly) {
      imgJob.onRunEnhancement({
        jobId: selectedImgJob?.id,
        customConfig: { enhancement: _cfg.enhancement, external_delivery: _cfg.external_delivery },
        enhancementConfigId: enhancementConfig?.id,
        onSuccess: () => {
          setIsVisible(false);
        },
      });
    } else {
      imgJob.onRunAssessment({
        jobId: selectedImgJob?.id,
        file: selectedImgJob?.additional_data[0]?.file_name,
        selectedUrlHeader: _selectedUrlHeader,
        selectedSkuHeader: _selectedSkuHeader,
        customConfig: _cfg,
        assessmentConfigId: assessmentConfig?.id,
        enhancementConfigId: isE2ERunEnabled ? enhancementConfig?.id : undefined,
        onSuccess: () => {
          // TODO: Rework in a more elegant way
          // We need to reset the form on the New Job panel on successful start.
          if (typeof onSuccess === 'function') onSuccess();
          setIsVisible(false);
        },
      });
    }
  };

  return (
    <div className="img-bot-modal-panel-body">
      {!isEnhancementOnly ? (
        <div className="helper-text">
          <p>
            You are about to begin the <strong>Initial Assessment</strong> phase of our{' '}
            <strong>Image Databot Suite</strong>.
          </p>
          <p>
            In this initial step we will <u>analyze your image files</u> to spot common issues that
            our <strong>Enhancement</strong> process can fix.
          </p>
          <p>
            Once the <strong>Initial Assessment</strong> is complete you will find a report
            available that summarizes our findings.
          </p>
        </div>
      ) : (
        <div className="helper-text">
          <p>
            You are about to begin the <strong>Enhancement</strong> phase of our{' '}
            <strong>Image Databot Suite</strong>.
          </p>
          <p>
            During this step, we will <u>improve your images according</u> to the selected
            configuration.
          </p>
          <p>
            Once this process is complete, the <strong>Final Assessment</strong> will commence. Upon
            its completion, you will be able to download the enhanced files along with the final
            report.
          </p>
        </div>
      )}

      {!!selectedImgJob?.additional_data?.length && (
        <section className="download-list download-list-input">
          <div className="title">Initial File:</div>
          <ul className="ids-config-initial-file">
            {selectedImgJob?.additional_data.map((item, index) => (
              <li key={index}>{item?.original_file_name}</li>
            ))}
          </ul>
        </section>
      )}

      <section className="custom-bot-config">
        <div className="title">Configuration</div>
        {!isEnhancementOnly ? (
          <div className="e2e-run-enable">
            <label htmlFor="ids-e2e-run-enable">
              <input
                onClick={() => setIsE2ERunEnabled(!isE2ERunEnabled)}
                checked={!!isE2ERunEnabled}
                readOnly
                type="checkbox"
                name="ids-e2e-run-enable"
                id="ids-e2e-run-enable"
                disabled={isLoading}
              />
              <span className="label">Run all steps</span>
              {getIconWithPopover({
                iconProp: ['far', 'question-circle'],
                content: (
                  <p>
                    If enabled, the IDS will seamlessly run all the phases (
                    <strong>Initial Assessment</strong>, <strong>Enhancement</strong>,{' '}
                    <strong>Final Assessment</strong>), without the need for any user interaction.
                  </p>
                ),
                title: 'Enable End-to-End Run',
              })}
            </label>
          </div>
        ) : undefined}
        {!isEnhancementOnly && !UserUtils.isRoleSelfService(activeUser) ? (
          <div className="ext-deliver-enable">
            <label htmlFor="ids-ext-deliver-enable">
              <input
                onClick={() => setIsExtDeliverEnabled(!isExtDeliverEnabled)}
                checked={!!isExtDeliverEnabled}
                readOnly
                type="checkbox"
                name="ids-ext-deliver-enable"
                id="ids-ext-deliver-enable"
                disabled={isLoading}
              />
              <span className="label">Deliver Externally</span>
              {getIconWithPopover({
                iconProp: ['far', 'question-circle'],
                content: (
                  <p>
                    Select the delivery destination from the options below. Once selected, you will
                    be required to provide additional information that will allow us to successfully
                    connect and deliver the assets.
                  </p>
                ),
                title: 'Enable External Delivery',
              })}
            </label>
          </div>
        ) : undefined}

        <Tabs
          activeKey={tabActiveKey}
          id="uncontrolled-tab-example"
          className={classNames({
            'mb-3': true,
            'ids-config-tabs': true,
            'hide-nav':
              (isEnhancementOnly || (!isE2ERunEnabled && !requiresHeaders)) && !isExtDeliverEnabled,
          })}
          onSelect={(ak) => setTabActiveKey(ak)}
        >
          <Tab
            eventKey="headers"
            title="Headers"
            tabClassName={!requiresHeaders || isEnhancementOnly ? 'd-none' : undefined}
          >
            <IdsHeaderSelection
              isLoading={isLoading}
              selectedUrlHeader={selectedUrlHeader}
              setSelectedUrlHeader={setSelectedUrlHeader}
              selectedSkuHeader={selectedSkuHeader}
              setSelectedSkuHeader={setSelectedSkuHeader}
              headersList={imgJob?.process_job?.additional_data[0]?.headers ?? null}
            />
          </Tab>
          <Tab
            eventKey="assessment"
            title={
              <>
                Assessment
                {assessmentFormValidationRes?.isValid ? undefined : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="assessment-error-tooltip">
                        <p>This form has validation errors.</p>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon className="err-icon" icon={['far', 'exclamation-circle']} />
                  </OverlayTrigger>
                )}
              </>
            }
            tabClassName={classNames(
              assessmentFormValidationRes?.isValid ? undefined : 'invalid',
              isEnhancementOnly ? 'd-none' : undefined
            )}
          >
            {!isEnhancementOnly && assessmentConfig ? (
              <IdsConfigSelector
                configuration={assessmentConfig}
                setConfiguration={setAssessmentConfig}
                isLoading={isLoading}
                type="assessment"
                setValidationResult={setAssessmentFormValidationRes}
              />
            ) : undefined}
          </Tab>
          <Tab
            eventKey="enhancement"
            title={
              <>
                Enhancement
                {enhancementFormValidationRes?.isValid ? undefined : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="enhancement-error-tooltip">
                        <p>This form has validation errors.</p>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon className="err-icon" icon={['far', 'exclamation-circle']} />
                  </OverlayTrigger>
                )}
              </>
            }
            tabClassName={classNames(
              enhancementFormValidationRes?.isValid ? undefined : 'invalid',
              !isE2ERunEnabled && !isEnhancementOnly ? 'd-none' : undefined
            )}
          >
            {(isE2ERunEnabled || isEnhancementOnly) && enhancementConfig ? (
              <IdsConfigSelector
                configuration={enhancementConfig}
                setConfiguration={setEnhancementConfig}
                isLoading={isLoading}
                type="enhancement"
                setValidationResult={setEnhancementFormValidationRes}
              />
            ) : undefined}
          </Tab>
          <Tab
            eventKey="external_delivery"
            title={
              <>
                External Delivery
                {externalDeliveryFormValidationRes?.isValid ? undefined : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="enhancement-error-tooltip">
                        <p>This form has validation errors.</p>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon className="err-icon" icon={['far', 'exclamation-circle']} />
                  </OverlayTrigger>
                )}
              </>
            }
            tabClassName={classNames(
              externalDeliveryFormValidationRes?.isValid ? undefined : 'invalid',
              !isExtDeliverEnabled ? 'd-none' : undefined
            )}
          >
            {isExtDeliverEnabled && externalDeliveryConfig ? (
              <IdsConfigSelector
                configuration={externalDeliveryConfig}
                setConfiguration={setExternalDeliveryConfig}
                isLoading={isLoading}
                type="external_delivery"
                setValidationResult={setExternalDeliveryFormValidationRes}
              />
            ) : undefined}
          </Tab>
        </Tabs>
      </section>
      <section className="actions">
        <button
          type="button"
          className="btn btn-link btn-cancel"
          onClick={() => setIsVisible(false)}
          disabled={isLoading}
          title="Cancel"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary btn-assess"
          onClick={onRunProcessing}
          disabled={
            isLoading ||
            assessmentFormValidationRes?.isValid === false ||
            enhancementFormValidationRes?.isValid === false ||
            externalDeliveryFormValidationRes?.isValid === false
          }
          title={isEnhancementOnly ? 'Run Enhancement' : 'Run Assessment'}
        >
          {isLoading ? <LoadingSpinner fast /> : <FontAwesomeIcon icon={['far', 'analytics']} />}{' '}
          {isEnhancementOnly ? 'Run Enhancement' : 'Run Assessment'}
        </button>
        {assessmentFormValidationRes?.message ||
        enhancementFormValidationRes?.message ||
        externalDeliveryFormValidationRes?.message ? (
          <p>
            {assessmentFormValidationRes?.message ||
              enhancementFormValidationRes?.message ||
              externalDeliveryFormValidationRes?.message}
          </p>
        ) : undefined}
      </section>
    </div>
  );
};

export { ImgBotEngineModalTrigger };
