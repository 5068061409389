import React, { useEffect, useState } from 'react';

import { DropdownButton } from 'react-bootstrap';

import { Checkbox } from 'components/common/Checkbox/Checkbox';

const ProductCompaniesFilter = ({ companies, onCompanySelect, selectedCompanies }) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [dirtyCompanies, setDirtyCompanies] = useState([]);

  const toggleFilterDropdown = (isOpen) => {
    setIsFilterVisible(isOpen);
  };

  const selectCompany = (company) => {
    const companies = dirtyCompanies.slice();
    companies.includes(company.id)
      ? companies.splice(companies.indexOf(company.id), 1)
      : companies.push(company.id);

    setDirtyCompanies(companies);
  };

  const cancelCompanySelect = () => {
    setDirtyCompanies(selectedCompanies);
    setIsFilterVisible(!isFilterVisible);
  };

  const isCompanyChecked = (company) =>
    !dirtyCompanies.some((selectedCompany) => selectedCompany === company.id);

  useEffect(() => {
    setDirtyCompanies(selectedCompanies);
  }, [selectedCompanies]);

  return (
    <DropdownButton
      alignRight
      className="prod-filter-companies"
      variant="secondary"
      title={<span className="label">Filter Companies</span>}
      show={isFilterVisible}
      onToggle={(isOpen) => toggleFilterDropdown(isOpen)}
    >
      <ul className="prod-filter-companies-body">
        {companies.map((company) => (
          <li key={company.name} className="prod-filter-companies-item">
            <button
              className="prod-filter-companies-input"
              type="button"
              onClick={() => selectCompany(company)}
            >
              <Checkbox checked={isCompanyChecked(company)} />
            </button>
            {company.name}
          </li>
        ))}
      </ul>
      <div className="prod-filter-companies-footer">
        <span
          onClick={cancelCompanySelect}
          className="prod-filter-companies-footer-btn prod-filter-companies-footer-btn--secondary"
        >
          Cancel
        </span>
        <span
          onClick={() => onCompanySelect(dirtyCompanies)}
          className="prod-filter-companies-footer-btn prod-filter-companies-footer-btn--primary"
        >
          Apply
        </span>
      </div>
    </DropdownButton>
  );
};

export { ProductCompaniesFilter };
