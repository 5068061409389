import moment from 'moment';

import { WEEK_DATE } from 'constants/DateTimeConstants';

/**
 * Returns an array of the summation of values in each column by period
 *
 * @param {object} metricsData - Object with keys mapping to each kind of metric, containing an array of data per period
 * @returns {array} - Flat array with values with indices inherently mapping to each period
 */
export const getTotalMetricsByPeriods = (metricsData) => {
  const metrics = [];
  const metricsTotals = [];

  Object.keys(metricsData).forEach((key) => {
    const metricsByPeriods = {};
    const metric = metricsData[key];

    metric.forEach((val, i) => {
      const keyName = `metric_${key}_${i}`;
      metricsByPeriods[keyName] = val;
    });

    metrics.push(metricsByPeriods);
  });

  metrics.forEach((metric) => {
    Object.keys(metric).forEach((m) => {
      const period = m[m.length - 1];
      if (!metricsTotals[period]) metricsTotals[period] = 0;
      metricsTotals[period] += metric[m];
    });
  });

  return metricsTotals;
};

/**
 * Returns a date range object that subtracts a specified number of weeks from the reference dates
 *
 * @param {string} startDate - Lower bound of date range
 * @param {string} endDate - Upper bound of date range
 * @param {int} weeks - Number of weeks to subtract from upper/lower range bounds
 * @returns {object} - Date range object
 */
export const getPeriodDateRange = (startDate, endDate, weeks) => {
  return {
    startDate: moment(startDate).subtract(weeks, 'week').format(WEEK_DATE),
    endDate: moment(endDate).subtract(weeks, 'week').format(WEEK_DATE),
  };
};

/**
 * Returns a string that formats the bounds of a date range.
 * Example: 06/22 and 06/28 -> 06/22-06/28
 *
 * @param {array} periods - Array of date ranges (@see getPeriodDateRange)
 * @param {int} period - The index representing a period/date range
 * @returns {string} - Formatted string containing a period date range
 */
export const getPeriodDateRangeLabel = (periods, period) => {
  return `${periods[period].startDate}-${periods[period].endDate}`;
};

/**
 * Returns a string that formats the bounds of a date range.
 * Example: 06/22 and 06/28 -> 06/22-06/28
 *
 * @param {string} startDate - Lower bound of date range
 * @param {string} endDate - Upper bound of date range
 * @returns {string} - Formatted string containing date range for historical metrics headers
 */
export const getHistoryMetricsDateRangeLabel = (startDate, endDate) => {
  startDate = moment(startDate).format(WEEK_DATE);
  endDate = moment(endDate).format(WEEK_DATE);
  return `${startDate}-${endDate}`;
};
