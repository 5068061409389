const validateData = (newTemplateData, columnsType) => {
  // eslint-disable-next-line array-callback-return
  newTemplateData.output_format.map((item) => {
    if (columnsType === 'keys' && item.corrected !== null){
      const changed_corrected = item.corrected.toLowerCase();
      item.corrected = changed_corrected.replace(/ /g,"_");
    }
    const exists = newTemplateData.output_format.find(
      (i) => i.corrected === item.corrected && item.corrected !== null
    );
    const originalLabel = newTemplateData.output_format.find(
      (i) => i.current === item.corrected
    );

    if (item.corrected === null && item.is_changed && columnsType === 'labels') {
      item.is_invalid = true;
      item.errors = 'Please enter a valid label for the selected attribute';
    }
    else if (item.corrected === null && item.is_changed && columnsType === 'keys'){
      item.is_invalid = true;
      item.errors = 'Please enter a valid key for the selected attribute';
    }
    else if (item.corrected && item.corrected.length > 100 && columnsType === 'labels') {
      item.is_invalid = true;
      item.errors =
        'The label can contain max 100 characters';
    }
    else if (item.corrected && item.corrected.length > 100 && columnsType === 'keys') {
      item.is_invalid = true;
      item.errors =
        'The key can contain max 100 characters';
    }
    else if (item.corrected !==null &&  !item.corrected.trim().length && columnsType === 'labels') {
      item.is_invalid = true;
      item.errors = 'Please enter a key containing alphanumeric characters.';
    }
    else if (item.corrected !==null &&  !item.corrected.trim().length && columnsType === 'keys') {
      item.is_invalid = true;
      item.errors = 'Please enter a label containing alphanumeric characters.';
    }
    else if (exists && item.is_changed && exists.key !== item.key && columnsType === 'labels') {
      item.is_invalid = true;
      item.errors =
        'This label is duplicated. Please make sure to enter different labels for the highlighted attributes';
    }
    else if (exists && item.is_changed && exists.key !== item.key && columnsType === 'keys') {
      item.is_invalid = true;
      item.errors =
        'This key is duplicated. Please make sure to enter different keys for the highlighted attributes';
    }
    else if (originalLabel && item.is_changed && columnsType === 'labels') {
      item.is_invalid = true;
      item.errors =
        'This label is duplicated. Please make sure to enter different labels';
    }
    else if (originalLabel && item.is_changed && columnsType === 'keys') {
      item.is_invalid = true;
      item.errors =
        'This key is duplicated. Please make sure to enter different keys';
    }
    else {
      item.is_invalid = false;
      item.errors = null;
    }
  });
};

const validateTable = (newTemplateData) => {
  const exists = newTemplateData.output_format.find(
    (i) => i.is_invalid === true && i.is_changed === true
  );
  return !exists;
};

const checkIfSelectedAttribute = (newTemplateData)=> {
  const exists = newTemplateData.output_format.find(
    (i) => i.is_changed === true
  );
  if (exists){
    return true;
  }
  return false;
};

export { validateData, validateTable, checkIfSelectedAttribute };
