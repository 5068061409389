import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { isIE } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router';

import { ResetPassword } from 'components/login/ResetPassword';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { RootHooks } from 'helpers/RootHooks';

const ViewResetPassword = () => {
  const { activeUser } = RootHooks.useActiveUser();

  const [isAlreadyLoggedIn, setIsAlreadyLoggedIn] = useState(false);

  useEffect(() => {
    if (activeUser?.email && getAuthToken()) {
      setIsAlreadyLoggedIn(true);
    } else {
      setIsAlreadyLoggedIn(false);
    }
  }, [JSON.stringify(activeUser)]);

  const redirectIfLoggedIn = () => {
    console.debug('Already logged in');
    return <Redirect to="/" />;
  };

  const renderHelmet = () => (
    <Helmet bodyAttributes={{ 'reset-password': 'reset-password' }}>
      <title>Reset Password</title>
    </Helmet>
  );

  return isAlreadyLoggedIn ? (
    redirectIfLoggedIn()
  ) : (
    <div className="view-login">
      {renderHelmet()}
      <div className="view-login-wrapper">
        <div className={classNames({ 'view-login-wrapper-isIE': isIE })}>
          <div className={classNames('view-login-card', { 'view-login-card-isIE': isIE })}>
            <ResetPassword />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewResetPassword };
