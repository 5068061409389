import './UserCommentsPanelIE.scss';

import React, { useState, useRef, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { UserComment } from 'components/common/UserComment/UserComment';
import { UserProfileCircle } from 'components/common/UserProfileCircle/UserProfileCircle';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { checkCommentStringForEmptyTags } from 'helpers/CommentUtils';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import { UserUtils } from 'helpers/UserUtils';

// TODO: IE-specific component almost same with the original one. Better to unite them.

const UserCommentsPanelIE = ({
  data,
  emptyDataMsg = 'No comments to show.',
  newCommentOnAdd,
  authorName,
  authorImageSrc,
  className,
  textBold = false,
  deleteComment,
  editComment,
  jobId,
  attachmentId = null,
  canEditExistingComments = true,
}) => {
  const isMounted = useIsMounted();

  const { activeUser } = RootHooks.useActiveUser();

  const inputRef = useRef();

  const [addCommentFieldValue, setAddCommentFieldValue] = useState('');
  const [commentEditNumber, setCommentEditNumber] = useState(null);
  const [commentEditFieldValue, setCommentEditFieldValue] = useState('');
  const [addCommentStatus, setAddCommentStatus] = useState(ActionStatusConstants.INITIAL);

  const onAddCommentSuccess = () => {
    setAddCommentStatus(ActionStatusConstants.SUCCESS);
    if (isMounted.current) {
      setAddCommentFieldValue('');
      setCommentEditNumber(null);
      setCommentEditFieldValue(null);
    }
  };

  const onAddCommentFailure = () => {
    setAddCommentStatus(ActionStatusConstants.FAILURE);
  };

  const onClickAddComment = () => {
    const { value } = inputRef.current;
    if (!value) {
      return;
    }

    const tempBody = checkCommentStringForEmptyTags(value);
    if (tempBody === null || tempBody === '') {
      setAddCommentStatus(ActionStatusConstants.FAILURE);
      inputRef.current.value = '';
      return;
    }

    setAddCommentStatus(ActionStatusConstants.ISBUSY);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/data-requests/${jobId}/comments/`,
      data: {
        content: tempBody,
        attachment_id: attachmentId,
      },
      onSuccess: () => {
        setAddCommentStatus(ActionStatusConstants.SUCCESS);
      },
      onEnd: () => {
        setAddCommentStatus(ActionStatusConstants.FAILURE);
        inputRef.current.value = '';
      },
    });
  };

  const onClickEditComment = (id) => {
    if (typeof editComment === 'function') {
      setAddCommentStatus(ActionStatusConstants.ISBUSY);
      editComment({
        commentId: id,
        commentBody: commentEditFieldValue,
        onSuccess: onAddCommentSuccess,
        onFailure: onAddCommentFailure,
      });
    } else {
      console.error('No edit comment function');
    }
  };

  const doDeleteComment = (id) => {
    // delete the comment with id
    if (typeof newCommentOnAdd === 'function') {
      setAddCommentStatus(ActionStatusConstants.ISBUSY);
      deleteComment({
        onSuccess: onAddCommentSuccess,
        onFailure: onAddCommentFailure,
        commentId: id,
      });
    } else {
      console.error('No commentDelete function');
    }
  };

  useEffect(() => {
    if (commentEditNumber && !commentEditFieldValue) {
      setCommentEditFieldValue(data.find((v) => v.id === commentEditNumber).commentBody);
    }
  }, [commentEditFieldValue, commentEditNumber, data]);

  return (
    <div className={classNames('user-comments-panel', className)}>
      {data && data.length ? (
        <ul className="user-comments-list">
          {data.map((item, i) => (
            <li
              key={i}
              className={classNames('user-comment-item', {
                'user-comment-item__editable': authorName === item.authorName,
              })}
            >
              {commentEditNumber === item.id ? (
                <div
                  className={classNames(
                    'user-comment-add',
                    {
                      focused: commentEditFieldValue,
                    },
                    'w-100'
                  )}
                >
                  <div className="add-comment-author">
                    <UserProfileCircle name={authorName} imageSrc={authorImageSrc} size="md" />
                  </div>
                  <div className="add-comment-field">
                    <textarea
                      ref={inputRef}
                      value={commentEditFieldValue}
                      className={`add-comment-textarea ${className}`}
                      onChange={(v) => {
                        setCommentEditFieldValue(v.target.value);
                      }}
                      maxLength={4000}
                      disabled={addCommentStatus === ActionStatusConstants.ISBUSY}
                    />
                  </div>
                  <div className="add-comment-actions">
                    <Button
                      style={{ width: '6rem', marginBottom: '1rem' }}
                      variant="secondary"
                      onClick={() => {
                        setCommentEditNumber(null);
                        setCommentEditFieldValue(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ width: '6rem' }}
                      variant="primary"
                      onClick={() => onClickEditComment(item.id)}
                      disabled={
                        !commentEditFieldValue ||
                        addCommentStatus === ActionStatusConstants.ISBUSY ||
                        commentEditFieldValue === '<p><span></span></p>'
                      }
                    >
                      <span className="icon" style={{ marginRight: '0.5em' }}>
                        {addCommentStatus === ActionStatusConstants.ISBUSY ? (
                          <LoadingSpinner />
                        ) : (
                          <FontAwesomeIcon fixedWidth icon={['fas', 'comments-alt']} />
                        )}
                      </span>
                      Save
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <UserComment
                    key={i}
                    as={<div />}
                    id={item.id}
                    className={classNames({
                      [`user-comment-${item.id}`]: !!item.id,
                    })}
                    authorName={item.authorName}
                    authorId={item.authorId}
                    authorImageSrc={item.authorImageSrc}
                    commentBody={item.commentBody}
                    createdAt={item.createdAt}
                    textBold={textBold}
                  />
                  {canEditExistingComments &&
                    authorName === item.authorName &&
                    !UserUtils.isReadOnly(activeUser) && (
                      <div className="user-comment-actions">
                        <button
                          type="button"
                          title="Save"
                          className="btn btn-secondary btn-sm action action-delete mr-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCommentEditNumber(item.id);
                          }}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={['fas', 'edit']} />
                          </span>
                        </button>
                        <ConfirmDialog
                          onConfirm={() => doDeleteComment(item.id)}
                          headerContent="Delete this comment?"
                          bodyContent="Once you delete, it’s gone for good"
                        >
                          {({ onClick }) => (
                            <button
                              type="button"
                              title="Delete"
                              className="btn btn-secondary btn-sm action action-delete mr-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick();
                              }}
                            >
                              <span className="icon">
                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                              </span>
                            </button>
                          )}
                        </ConfirmDialog>
                      </div>
                    )}
                </>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className="user-comments-list empty">{emptyDataMsg}</div>
      )}
      {!UserUtils.isReadOnly(activeUser) && (
        <div
          className={classNames('user-comment-add', {
            focused: addCommentFieldValue,
          })}
        >
          <div className="add-comment-author">
            <UserProfileCircle name={authorName} imageSrc={authorImageSrc} size="md" />
          </div>
          <div className="add-comment-field">
            <textarea
              ref={inputRef}
              className={`add-comment-textarea ${className}`}
              onChange={(v) => setAddCommentFieldValue(v)}
              maxLength={4000}
              disabled={addCommentStatus === ActionStatusConstants.ISBUSY}
            />
          </div>
          <div className="add-comment-actions">
            <Button
              variant="primary"
              onClick={() => onClickAddComment()}
              disabled={
                !addCommentFieldValue ||
                addCommentStatus === ActionStatusConstants.ISBUSY ||
                addCommentFieldValue === '<p><span></span></p>'
              }
            >
              <span className="icon" style={{ marginRight: '0.5em' }}>
                {addCommentStatus === ActionStatusConstants.ISBUSY ? (
                  <LoadingSpinner />
                ) : (
                  <FontAwesomeIcon icon={['fas', 'comments-alt']} />
                )}
              </span>
              Add
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

UserCommentsPanelIE.defaultProps = {
  data: null,
  emptyDataMsg: null,
  newCommentOnAdd: null,
  authorName: null,
  authorImageSrc: null,
  className: null,
  canEditExistingComments: true,
};

UserCommentsPanelIE.propTypes = {
  data: PropTypes.array,
  emptyDataMsg: PropTypes.string,
  newCommentOnAdd: PropTypes.func,
  authorName: PropTypes.string,
  authorImageSrc: PropTypes.string,
  className: PropTypes.string,
  canEditExistingComments: PropTypes.bool,
};

export { UserCommentsPanelIE };
