import './PricingUpdateFileList.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import { PropTypes } from 'prop-types';
import { Button, ListGroup } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import DataJobCreateConstants from 'constants/DataJobCreateConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { formatBytes } from 'helpers/Utils';

const PricingUpdateFileList = ({ fileList = [], setFiles }) => {
  const { activeUser } = RootHooks.useActiveUser();

  const [attachCount, setAttachCount] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(ActionStatusConstants.INITIAL);
  const [deleteProgFile, setDeleteProgFile] = useState(null);

  useEffect(() => {
    setAttachCount(fileList.length);
  }, [fileList, fileList.length]);

  const canDeleteFile = useCallback(
    (fileItem) => {
      if (!fileItem) {
        return false;
      }

      const ownerId = _get(fileItem, 'user.id') || null;
      const currUserId = activeUser?.id ?? null;

      return !!(ownerId && currUserId && ownerId === currUserId);
    },
    [activeUser?.id]
  );

  const doDeleteFile = useCallback(
    (delFile) => {
      if (!(delFile && delFile.id)) {
        return;
      }
      setDeleteProgFile({ id: delFile.id, type: delFile.type });

      setUploadStatus(ActionStatusConstants.ISBUSY);

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.DELETE,
        urlPath: `/data-requests/attachments/${delFile.id}`,
        onSuccess: () => {
          setUploadStatus(ActionStatusConstants.SUCCESS);
          setFiles(fileList.filter((file) => file !== delFile));
          toast.success(`File successfully removed.`);
        },
        onError: () => {
          setUploadStatus(ActionStatusConstants.FAILURE);
        },
        onEnd: () => {
          setDeleteProgFile(null);
        },
      });
    },
    [fileList, setFiles]
  );

  const deleteButtonRender = useCallback(
    (item) => {
      return canDeleteFile(item) ? (
        <ConfirmDialog
          onConfirm={() => doDeleteFile(item)}
          headerContent="Delete File?"
          bodyContent={
            <span className="item-deletebutton-confirmbody">
              {`Are you sure you want to delete file "${item.original_name ?? item.name}"?`}
            </span>
          }
        >
          {({ onClick }) => (
            <Button
              variant="outline-danger"
              title="Delete"
              className="item-deletebutton"
              disabled={uploadStatus === ActionStatusConstants.ISBUSY}
              onClick={() => {
                return uploadStatus !== ActionStatusConstants.ISBUSY && onClick();
              }}
            >
              {!deleteProgFile || (deleteProgFile && item.id !== deleteProgFile.id) ? (
                <FontAwesomeIcon icon={['far', 'trash-alt']} />
              ) : (
                <LoadingSpinner style={{ fontSize: '1em' }} />
              )}
            </Button>
          )}
        </ConfirmDialog>
      ) : null;
    },
    [canDeleteFile, deleteProgFile, doDeleteFile, uploadStatus]
  );

  // usecallback to memoize render of list items and only rerender when deps change
  const renderListItems = useCallback(() => {
    return [
      ...fileList.map((f) => {
        f.type = DataJobCreateConstants.FILE;
        return (
          <ListGroup.Item className="item-listgroup" key={f.id}>
            <span className="itemtext">{f.original_name ?? f.name}</span>
            <span className="itemdetail">{formatBytes(f.size)}</span>
            {deleteButtonRender(f)}
          </ListGroup.Item>
        );
      }),
    ];
  }, [deleteButtonRender, fileList]);

  return (
    <div className="pricing-update-upload-list">
      <div className="headertext">Files{` (${attachCount})`}</div>
      <ListGroup variant="flush">{renderListItems()}</ListGroup>
    </div>
  );
};

PricingUpdateFileList.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.object),
  setFiles: PropTypes.func.isRequired,
};

PricingUpdateFileList.defaultProps = {
  fileList: [],
};

export { PricingUpdateFileList };
