import React from 'react';

import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';

import './OrderDetailsBot.scss';
import { OrderDetailsBotBodyPanel } from './private/OrderDetailsBotBodyPanel';

/**
 * Order Details Bot ( Quantity Details Intelligent Fill )
 *
 * @see {@link ShortDescriptionCreatorBot}
 */
const OrderDetailsBot = ({
  botStatusId,
  slug,
  status,
  handleRunBot,
  handleCancelBot,
  additionalData,
}) => {
  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
        {
          label: 'Total Products',
          value: additionalData?.total_products ?? 'N/A',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      title="Quantity Details Intelligent Fill"
      bodyContent={
        <OrderDetailsBotBodyPanel
          additionalData={additionalData}
          onRun={() => handleRunBot()}
          onCancel={() => handleCancelBot()}
          slug={slug}
        />
      }
      hideBodyActions
    />
  );
};

export { OrderDetailsBot };
