import React, { useState, useEffect } from 'react';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';

const FileSelector = ({ selectedFile, filesList, setSelectedFile, disabled }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (filesList?.length) {
      setOptions(
        filesList.map((item) => {
          return { value: item.name, label: item.original_name, obj: item };
        })
      );
    } else {
      setOptions([]);
    }
  }, [filesList]);

  return (
    <StyledMultiselect
      values={
        selectedFile?.name
          ? { value: selectedFile.name, label: selectedFile.original_name, obj: selectedFile }
          : []
      }
      options={options}
      setOnChange={(v) => {
        setSelectedFile(v?.obj || null);
      }}
      getOptionValue={(option) => option.value}
      closeMenuOnSelect
      isMulti={false}
      isClearable
      canReset
      isDisabled={disabled}
    />
  );
};

export { FileSelector };
