import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { Customer } from 'components/metrics/Customer/Customer';
import { MetricsTable } from 'components/metrics/MetricsTable/MetricsTable';
import UserRoleConstants from 'constants/UserRoleConstants';
import {
  IndividualManufacturersTableDef,
  UsersTableDef,
} from 'helpers/table-defs/ViewMetricsTableDef';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

const ViewCustomerClientMetrics = () => {
  const [overviewMetrics, setOverviewMetrics] = useState({});
  const [userMetrics, setUserMetrics] = useState([]);
  const [mfrMetrics, setMfrMetrics] = useState([]);
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const [isOverviewLoading, setIsOverviewLoading] = useState(true);
  const { id } = useParams();
  const isMounted = useIsMounted();

  useEffect(() => {
    setIsOverviewLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/metrics/distributor/${id}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          const { overview, users, manufacturers } = res.data;

          setPageTitle(`${overview.name} Dashboard`);
          setOverviewMetrics(overview);

          setUserMetrics(sortByKey(users, 'last_name'));
          setMfrMetrics({
            total: manufacturers.total,
            manufacturers: sortByKey(manufacturers.manufacturers, 'name'),
          });
        }
      },
      onEnd: () => {
        setIsOverviewLoading(false);
      },
    });
  }, [id, isMounted]);

  return (
    <Customer
      pageTitle={pageTitle}
      type={UserRoleConstants.DISTRIBUTOR.toLowerCase()}
      isOverviewLoading={isOverviewLoading}
      overviewMetrics={overviewMetrics}
    >
      <MetricsTable
        usePagination
        header="User Overview"
        metrics={userMetrics}
        keyField="id"
        columns={{
          columns: UsersTableDef.columns.filter((column) => column.dataField !== 'is_active'),
        }}
      />
      <MetricsTable
        header="Manufacturer Overview"
        usePagination
        keyField="id"
        metrics={mfrMetrics.manufacturers ?? []}
        columns={IndividualManufacturersTableDef}
      />
    </Customer>
  );
};

export { ViewCustomerClientMetrics };
