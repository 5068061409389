import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import './IdsShortDescriptionBlock.scss';

const IdsShortDescriptionBlock = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <div className="ids-short-description">
        <div className="ids-short-description-content">
          <p>
            Discover the power of <strong>Image Databot Suite (IDS)</strong>, an{' '}
            <em>AI-powered solution</em> for superior image enhancement. IDS addresses a range of
            image issues, from <strong>size</strong> and <strong>resolution</strong> to{' '}
            <strong>positioning</strong>, ensuring your product visuals always hit the mark.
          </p>
          <p>
            With the unique capability to <u>optimize up to 10,000 images in a single run</u>, IDS
            is revolutionizing the way you manage your product images. Embark on your IDS journey to
            transform your images today!
          </p>
        </div>
        <div className="ids-short-description-action">
          <button
            type="button"
            className="btn btn-primary btn-request-data"
            onClick={() => history.push(`/image-databot-suite${location?.search}`)}
          >
            Get Started!
          </button>
        </div>
      </div>
    </>
  );
};

export { IdsShortDescriptionBlock };
