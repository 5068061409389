import { useEffect, useState, useRef, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { singletonHook } from 'react-singleton-hook';

import ActionTypeConstants from 'constants/ActionTypeConstants';
import { useIsMounted } from 'helpers/useIsMounted';
import { store } from 'store/store';

import { RootHooks } from './RootHooks';

const TIMEOUT = process.env.REACT_APP_WS_TIMEOUT;

const { ADD_WS_MESSAGE, REMOVE_WS_CHANNEL, CLEAR_ALL_WS_MESSAGES } = ActionTypeConstants;

const Websocket = (onOpen = () => {}, onClose = () => {}) => {
  let ws = null;
  let timeout = null;
  let reconnectTimer = TIMEOUT;
  let isConnected = false;
  // disable reconnect for onclose if var is set and if in dev environment
  let reconnectOnClose = !(
    process.env.REACT_APP_DISABLE_DEV_WS_RECONNECT === 'true' &&
    (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  );

  let messageListeners = [];
  let messageTimeListeners = [];
  let stateChangeListeners = [];

  const onStateChange = (fn) => {
    stateChangeListeners.push(fn);
    return () => (stateChangeListeners = stateChangeListeners.filter((l) => l !== fn));
  };

  const on = (fn) => (messageListeners = [fn]);
  const onTime = (fn) => (messageTimeListeners = [fn]);
  const off = (fn) => (messageListeners = messageListeners.filter((l) => l !== fn));
  const offTime = (fn) => (messageTimeListeners = messageTimeListeners.filter((l) => l !== fn));

  let wsProtocol = 'ws://';
  if (window.location.protocol === 'https:') {
    wsProtocol = 'wss://';
  }

  const start = () => {
    ws = new WebSocket(
      `${wsProtocol}${window.location.host}${process.env.REACT_APP_BE_WS_BASE}/messages/`
    );

    ws.onopen = () => {
      reconnectTimer = TIMEOUT;
      clearTimeout(timeout);
      timeout = null;
      stateChangeListeners.forEach((fn) => fn(true));
      isConnected = true;
      onOpen();
    };

    const { close } = ws;

    ws.close = () => {
      reconnectOnClose = false;
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      close.call(ws);
    };

    ws.onmessage = (e) => {
      const message = JSON.parse(e.data);
      if (message?.channel && message?.data) {
        store.dispatch({
          type: ADD_WS_MESSAGE,
          payload: { channel: message.channel, message: message.data },
        });
      }
      messageTimeListeners.forEach((fn) => fn(message));
    };

    ws.onclose = (e) => {
      isConnected = false;
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      stateChangeListeners.forEach((fn) => fn(false));
      onClose(e);
      if (!reconnectOnClose) return;
      timeout = setTimeout(() => {
        start();
      }, reconnectTimer);
      reconnectTimer *= 2;
    };
  };

  if (!ws) {
    start();
  }

  return {
    on,
    onTime,
    off,
    offTime,
    onStateChange,
    close: () => ws.close(),
    getClient: () => ws,
    messageListeners,
    messageTimeListeners,
    isConnected: () => isConnected,
  };
};

const init = {
  sendMessage: () => {},
  isConnected: false,
  addChannel: () => {},
  resetMessages: () => {},
  disconnectChannel: () => {},
  messages: {},
  messagesLastUpdate: {},
};

const useWebsocket = singletonHook(init, () => {
  const { activeUser } = RootHooks.useActiveUser();

  const userId = activeUser?.id ?? null;

  const { featureFlags } = RootHooks.useFeatureFlags();

  const isWsEnabled = !!featureFlags.ENABLE_WS || false;

  const messages = useSelector((state) => state?.WebsocketMessageReducer?.messages || {});
  const messagesLastUpdate = useSelector(
    (state) => state?.WebsocketMessageReducer?.messagesLastUpdate || {}
  );

  const ws = useRef(null);

  const isMounted = useIsMounted();

  const [isConnected, setIsConnected] = useState(false);

  const setMessages = useCallback(
    (channel, message) => store.dispatch({ type: ADD_WS_MESSAGE, payload: { channel, message } }),
    []
  );

  const getMessages = useCallback(
    (channel) => {
      return messages[channel];
    },
    [messages]
  );

  const resetMessages = useCallback((channel) => {
    store.dispatch({ type: REMOVE_WS_CHANNEL, payload: { channel } });
  }, []);

  const sendMessage = useCallback(
    (e) => {
      if (
        isConnected &&
        isMounted.current &&
        ws?.current?.getClient &&
        typeof ws?.current.getClient === 'function'
      ) {
        ws.current.getClient().send(JSON.stringify({ message: e }));
      }
    },
    [isConnected, isMounted]
  );

  const addChannel = useCallback(
    (channel, extra) => {
      setMessages(channel, []);
      sendMessage({ channel, data: 'add', extra });
    },
    [setMessages, sendMessage]
  );

  const disconnectChannel = useCallback(
    (channel, extra) => {
      resetMessages(channel);
      sendMessage({ channel, data: 'remove', extra });
    },
    [sendMessage, resetMessages]
  );

  // logic to connect/disconnect the websocket
  useEffect(() => {
    if (userId) {
      if (isWsEnabled && !ws.current) {
        ws.current = Websocket();
        ws.current.onStateChange((stateChangeBool) => setIsConnected(stateChangeBool));
      }
    } else {
      if (ws.current) {
        console.debug('Closing WS');
        ws.current.close();
        ws.current = null;
        store.dispatch({ type: CLEAR_ALL_WS_MESSAGES });
      }
    }
  }, [ws, isWsEnabled, userId]);

  // add isconnected listener to ws
  useEffect(() => {
    return () => ws?.current?.onStateChange(setIsConnected);
  }, [setIsConnected, ws]);

  return {
    setMessages: isWsEnabled ? setMessages : () => {},
    sendMessage: isWsEnabled ? sendMessage : () => {},
    isConnected: isWsEnabled && isConnected,
    addChannel: isWsEnabled ? addChannel : () => {},
    resetMessages: isWsEnabled ? resetMessages : () => {},
    disconnectChannel: isWsEnabled ? disconnectChannel : () => {},
    messages: isWsEnabled ? messages : {},
    messagesLastUpdate: isWsEnabled ? messagesLastUpdate : {},
  };
});

export { useWebsocket };
