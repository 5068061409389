import React, { useEffect, useRef, useState } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import { Alert } from 'react-bootstrap';

import ActionStatusConstants from 'constants/ActionStatusConstants';
import { RootHooks } from 'helpers/RootHooks';
import * as TimeUtils from 'helpers/TimeUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import { BlockActions } from './BlockActions';
import { BlockAttributesTable } from './BlockAttributesTable';
import { BlockAttributesTableFilter } from './BlockAttributesTableFilter';
import { BlockExportStatusTable } from './BlockExportStatusTable';
import { BlockManufacturerSelect } from './BlockManufacturerSelect';
import { BlockTemplateSelect } from './BlockTemplateSelect';
import './ExportFunctionalityAdvanced.scss';
import * as ExportFunctionalityAdvancedApiCalls from './ExportFunctionalityAdvancedApiCalls';
import { EXPORT_STATUSES } from './ExportFunctionalityAdvancedConstants';
import * as ExportFunctionalityAdvancedUtils from './ExportFunctionalityAdvancedUtils';

// TODO: confirm change mfr/template if template has changed
// TODO: Save As template dialog
// TODO: Integrate endpoints

/**
 * Advanced export functionality main view
 *
 * @return render
 */
const ExportFunctionalityAdvanced = () => {
  const isMounted = useIsMounted();

  const { activeUser } = RootHooks.useActiveUser();

  // Holds list of manufacturers
  const [mfrsData, setMfrsData] = useState(null);
  const [mfrsDataStatus, setMfrsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds list of child mfrs per selected mfr
  const [childMfrsData, setChildMfrsData] = useState(null);
  const [childMfrsDataStatus, setChildMfrsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Selected mfr/child mfr pair - { manufacturer: id, manufacturerChild: id }
  const [mfrSelectValue, setMfrSelectValue] = useState(null);
  // Holds previous mfr selection
  const prevMfrSelectValue = useRef(null);

  // Holds product count per selected mfr
  const [prodCountData, setProdCountData] = useState(null);
  const [prodCountDataStatus, setProdCountDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds attributes list per selected mfr
  const [attributesData, setAttributesData] = useState(null);
  const [attributesDataStatus, setAttributesDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds list of templates per selected mfr
  const [templatesData, setTemplatesData] = useState(null);
  const [templatesDataStatus, setTemplatesDataStatus] = useState(ActionStatusConstants.INITIAL);
  // Holds selected template object
  const [templateSelectValue, setTemplateSelectValue] = useState(null);
  // Template data retrieved for selected template
  const [templateData, setTemplateData] = useState(null);
  const [templateDataStatus, setTemplateDataStatus] = useState(ActionStatusConstants.INITIAL);
  // Editable copy of templateData
  const [editableTemplateData, setEditableTemplateData] = useState(null);
  // Custom template generated for current set of available attributes
  const [customTemplateData, setCustomTemplateData] = useState(null);

  // Holds status data for current exports
  const [exportsStatusData, setExportsStatusData] = useState(null);
  const [exportsStatusDataStatus, setExportsStatusDataStatus] = useState(null);
  const exportsStatusPollInterval = useRef(null);

  const templateSelectValueId = templateSelectValue?.id;
  // Init calls
  useEffect(() => {
    ExportFunctionalityAdvancedApiCalls.doGetMfrs({
      isMounted,
      setMfrsData,
      setMfrsDataStatus,
    });

    ExportFunctionalityAdvancedApiCalls.doGetExportsStatus({
      isMounted,
      setExportsStatusData,
      setExportsStatusDataStatus,
    });
  }, [isMounted]);

  // Handles mfr selection changes
  useEffect(() => {
    // Calculate changes
    const isMfrChanged =
      prevMfrSelectValue.current?.manufacturer?.id !== mfrSelectValue?.manufacturer?.id;
    const isChildMfrChanged =
      prevMfrSelectValue.current?.manufacturerChild?.id !== mfrSelectValue?.manufacturerChild?.id;

    prevMfrSelectValue.current = mfrSelectValue;

    if (isMfrChanged || isChildMfrChanged) {
      // Reset dependent state
      setProdCountData(null);
      setTemplatesData(null);
      setAttributesData(null);
      setTemplateSelectValue(null);
      setTemplateData(null);
      setEditableTemplateData(null);
      setCustomTemplateData(null);

      const exportMfrId = ExportFunctionalityAdvancedUtils.getExportMfrId(mfrSelectValue);

      // If we have a selected mfr or child mfr, retrieve relevant
      // data - product count, attributes, templates
      if (exportMfrId) {
        ExportFunctionalityAdvancedApiCalls.doGetProdCount({
          mfrId: exportMfrId,
          isMounted,
          setProdCountData,
          setProdCountDataStatus,
        });

        ExportFunctionalityAdvancedApiCalls.doGetAttributes({
          mfrId: exportMfrId,
          isMounted,
          setAttributesData: (_attributesData) => {
            // Extract the actual list from the paginated data object
            // TODO: Wrong endpoint attr name workaround
            let attrDataList = null;
            if (_attributesData?.attributes?.length) {
              attrDataList = _attributesData.attributes;
            } else if (_attributesData?.data_requests?.length) {
              attrDataList = _attributesData.data_requests;
            }
            // Generate custom template from newly loaded attributes
            setCustomTemplateData(
              ExportFunctionalityAdvancedUtils.generateFullTemplateData(attrDataList)
            );
            setAttributesData(attrDataList); // The category of each attribute changes for each manufacturer.
          },
          setAttributesDataStatus,
        });

        ExportFunctionalityAdvancedApiCalls.doGetTemplates({
          mfrId: exportMfrId,
          isMounted,
          setTemplatesData,
          setTemplatesDataStatus,
        });
      }

      // If parent mfr has changed, reset child mfrs data and selection,
      // and get relevant child mfr list for that mfr
      if (isMfrChanged) {
        // Reset child when mfr is changed
        setChildMfrsData(null);
        setMfrSelectValue({ ...mfrSelectValue, manufacturerChild: null });

        if (mfrSelectValue?.manufacturer?.id) {
          // Load children of newly selected mfr
          ExportFunctionalityAdvancedApiCalls.doGetChildMfrs({
            mfrId: mfrSelectValue.manufacturer.id,
            isMounted,
            setChildMfrsData,
            setChildMfrsDataStatus,
          });
        }
      }
    }
  }, [isMounted, mfrSelectValue]);

  // Handles template selection change
  useEffect(() => {
    // If an existing template is selected, get template data and apply it.
    // If a null(custom) is selected, apply Custom template.
    if (templateSelectValueId) {
      ExportFunctionalityAdvancedApiCalls.doGetTemplate({
        templateId: templateSelectValueId,
        isMounted,
        setTemplateData: (_templateData) => {
          const fullTemplateData = ExportFunctionalityAdvancedUtils.generateFullTemplateData(
            attributesData,
            _templateData
          );
          setTemplateData(fullTemplateData);
          setEditableTemplateData(_cloneDeep(fullTemplateData));
        },
        setTemplateDataStatus,
      });
    } else {
      setTemplateData(customTemplateData || null);
      setEditableTemplateData(customTemplateData ? _cloneDeep(customTemplateData) : null);
    }
  }, [isMounted, attributesData, templateSelectValueId, customTemplateData]);

  // Exports status poll
  useEffect(() => {
    if (isMounted.current === true) {
      if (!exportsStatusPollInterval.current) {
        ExportFunctionalityAdvancedApiCalls.doGetExportsStatus({
          isMounted,
          setExportsStatusData,
          setExportsStatusDataStatus,
        });
        exportsStatusPollInterval.current = setInterval(() => {
          if (isMounted.current === true) {
            ExportFunctionalityAdvancedApiCalls.doGetExportsStatus({
              isMounted,
              setExportsStatusData,
              setExportsStatusDataStatus,
            });
          } else {
            clearInterval(exportsStatusPollInterval.current);
            exportsStatusPollInterval.current = null;
          }
        }, 7000);
      }
    }
  }, [isMounted, exportsStatusPollInterval]);

  // Is state acceptable to start export
  const canStartExport = () => {
    // Is mfr/child mfr selected
    if (!ExportFunctionalityAdvancedUtils.getExportMfrId(mfrSelectValue)) {
      return false;
    }

    // Is there valid template data
    if (!editableTemplateData?.output_format?.length) {
      return false;
    }

    // Is there at least one Attribute Type selected
    if (!editableTemplateData?.attribute_types?.length) {
      return false;
    }

    // Is selected mfr already being exported at the moment
    if (
      ExportFunctionalityAdvancedUtils.checkIsSelectedMfrAlreadyInProgress(
        mfrSelectValue,
        exportsStatusData
      )
    ) {
      return false;
    }

    // Is any data loading in progress
    if (
      [
        mfrsDataStatus,
        childMfrsDataStatus,
        prodCountDataStatus,
        attributesDataStatus,
        templatesDataStatus,
        templateDataStatus,
      ].includes(ActionStatusConstants.ISBUSY)
    ) {
      return false;
    }

    return true;
  };

  // Resets editable template to currently loaded one
  const doResetTemplate = () => {
    if (templateData) {
      setEditableTemplateData(_cloneDeep(templateData));
    }
  };

  // Handling template post-save action
  const onSaveTemplate = (savedTemplateData) => {
    let shouldReloadTemplates = false;

    if (savedTemplateData?.id === editableTemplateData.id) {
      // If we're saving the current template, replace the template data with the modified one.
      setTemplateData(_cloneDeep(editableTemplateData));

      // Saving a new template as the current template name but with changed letter case
      shouldReloadTemplates =
        savedTemplateData.template_name !== editableTemplateData.template_name;

      // In case saving the current template, also change selected template value
      // label to reflect it on the dropdown
      if (shouldReloadTemplates) {
        setTemplateSelectValue({
          ..._cloneDeep(templateSelectValue),
          template_name: savedTemplateData.template_name,
        });
      }

      console.debug('Saved current template', savedTemplateData, shouldReloadTemplates);
    } else {
      // If we're saving a new template under the same mfr, reload the dropdown list so it appears.
      const exportMfrId = ExportFunctionalityAdvancedUtils.getExportMfrId(mfrSelectValue);

      if (exportMfrId === savedTemplateData?.manufacturer?.id) {
        shouldReloadTemplates = true;
      }

      console.debug('Saved template', savedTemplateData, shouldReloadTemplates);
    }

    if (shouldReloadTemplates) {
      ExportFunctionalityAdvancedApiCalls.doGetTemplates({
        mfrId: ExportFunctionalityAdvancedUtils.getExportMfrId(mfrSelectValue),
        isMounted,
        setTemplatesData,
        setTemplatesDataStatus,
      });
    }

    // If the template is saved outside the selected mfr, we don't do anything.
  };

  // Handling post-start actions
  const onStartExport = () => {
    // Locally add a status for the newly started export until the poll runs again.
    const mfrObj = mfrSelectValue?.manufacturerChild || mfrSelectValue?.manufacturer || null;

    if (mfrObj) {
      const statusItem = {
        id: -1,
        manufacturer: mfrObj,
        type: editableTemplateData.attribute_types,
        status: EXPORT_STATUSES.in_progress.value,
        created_at: TimeUtils.formatDateTime8601(new Date()),
        created_by: activeUser,
      };

      const newStatusData = exportsStatusData?.length ? _cloneDeep(exportsStatusData) : [];

      newStatusData.push(statusItem);

      setExportsStatusData(newStatusData);
    }
  };

  return (
    <div className="export-functionality-advanced">
      <BlockManufacturerSelect
        mfrsData={mfrsData}
        childMfrsData={childMfrsData}
        value={mfrSelectValue}
        onChange={setMfrSelectValue}
        prodCountData={prodCountData}
        isDisabled={[mfrsDataStatus, childMfrsDataStatus].includes(ActionStatusConstants.ISBUSY)}
        isLoadingProdCountData={prodCountDataStatus === ActionStatusConstants.ISBUSY}
      />
      {ExportFunctionalityAdvancedUtils.checkIsSelectedMfrAlreadyInProgress(
        mfrSelectValue,
        exportsStatusData
      ) && (
        <section className="block block-manufacturer-error-message">
          <Alert variant="danger">
            There is already an export in progress for{' '}
            <b>
              &quot;{mfrSelectValue?.manufacturerChild?.name || mfrSelectValue?.manufacturer?.name}
              &quot;
            </b>
            .
          </Alert>
        </section>
      )}
      {ExportFunctionalityAdvancedUtils.getExportMfrId(mfrSelectValue) ? (
        <>
          <BlockTemplateSelect
            templatesData={templatesData}
            value={templateSelectValue}
            onChange={setTemplateSelectValue}
            // TODO: isDisabled only accounts for loading status, not mandatoryExportColumns. Pull up that logic
            isDisabled={templatesDataStatus === ActionStatusConstants.ISBUSY}
          />
          {attributesData?.length ? (
            <>
              <BlockAttributesTableFilter
                editableTemplateData={editableTemplateData}
                originalTemplateData={templateData}
                onChange={setEditableTemplateData}
                isDisabled={[attributesDataStatus, templateDataStatus].includes(
                  ActionStatusConstants.ISBUSY
                )}
                attributesData={attributesData}
              />
              <BlockAttributesTable
                attributesData={attributesData}
                editableTemplateData={editableTemplateData}
                originalTemplateData={templateData}
                onChange={setEditableTemplateData}
                isDisabled={[attributesDataStatus, templateDataStatus].includes(
                  ActionStatusConstants.ISBUSY
                )}
              />
            </>
          ) : null}
          <BlockActions
            templateData={editableTemplateData}
            mfrSelectValue={mfrSelectValue}
            canStartExport={canStartExport()}
            canResetTemplate={!_isEqual(editableTemplateData, templateData)}
            onResetTemplate={doResetTemplate}
            onSaveTemplate={onSaveTemplate}
            onStartExport={onStartExport}
          />
        </>
      ) : null}
      <BlockExportStatusTable
        exportsStatusData={exportsStatusData}
        isDisabled={exportsStatusDataStatus === ActionStatusConstants.ISBUSY}
      />
    </div>
  );
};

export { ExportFunctionalityAdvanced };
