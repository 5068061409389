import React, { useCallback, useEffect, useRef, useState } from 'react';

import _get from 'lodash/get';
import { Button, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as ApiCalls from 'api/ApiCalls';
import { FileUpload } from 'components/common/FileUpload/FileUpload';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

import './ImportFunctionalityPanel.scss';
import { ImportFunctionalityPanelTableDef, IMPORT_TYPES } from './ImportFunctionalityPanelTableDef';
/**
 * Export functionality panel main view
 *
 * @return render
 */
const ImportFunctionalityPanel = () => {
  const isMounted = useIsMounted();

  const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB
  const [startImportStatus, setStartImportStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds exports status data
  const [importsData, setImportsData] = useState(null);

  // Holds mfrs list
  const [manufacturersData, setManufacturersData] = useState(null);
  const [manufacturersDataStatus, setManufacturersDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  // Holds selected mfr id and export type selected
  const [selectedMfrId, setSelectedMfrId] = useState(null);
  const [selectedImportType, setSelectedImportType] = useState(IMPORT_TYPES.general.value);
  const pollInterval = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const doGetImports = useCallback(() => {
    if (isMounted.current) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/products-import/',
        onSuccess: (res) => {
          if (isMounted.current) {
            const _data = _get(res, 'data') || null;
            setImportsData(_data);
          }
        },
      });
    }
  }, [isMounted]);

  const doGetManufacturers = useCallback(() => {
    setManufacturersDataStatus(ActionStatusConstants.ISBUSY);
    if (isMounted.current) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/product-export-history/manufacturers/',
        onSuccess: (res) => {
          if (isMounted.current) {
            setManufacturersDataStatus(ActionStatusConstants.SUCCESS);
            const _data = _get(res, 'data') || null;
            setManufacturersData(sortByKey(_data, 'name'));
          }
        },
        onError: () => {
          setManufacturersDataStatus(ActionStatusConstants.FAILURE);
        },
      });
    }
  }, [isMounted]);

  const reset = () => {
    setSelectedFiles([]);
    setSelectedMfrId(null);
    setIsUploadComplete(true);
    setIsUploading(false);
  };

  const doStartImport = useCallback(() => {
    if (!selectedMfrId) {
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFiles[0]);
    formData.append('manufacturer_id', selectedMfrId);
    formData.append('type', selectedImportType);

    setStartImportStatus(ActionStatusConstants.ISBUSY);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: '/products-import/',
      data: formData,
      isFileFormData: true,
      successMessage: 'Import started. It will appear in the table below shortly.',
      onProgress: (progressEvent) => {
        const percentage = ApiCalls.getProgressPercent(progressEvent);
        if (percentage >= 0) setUploadProgress(percentage.toFixed(0));
      },
      onSuccess: () => {
        if (isMounted.current) {
          reset();
          setStartImportStatus(ActionStatusConstants.SUCCESS);
          doGetImports();
        }
      },
      onError: (err) => {
        setStartImportStatus(ActionStatusConstants.FAILURE);
        setIsUploading(false);
        err.response.data.forEach((message) => {
          toast.error(message);
        });
      },
    });
  }, [selectedMfrId, selectedFiles, selectedImportType, isMounted, doGetImports]);

  // Exports status poll
  useEffect(() => {
    doGetManufacturers();
    doGetImports();

    if (!pollInterval.current) {
      pollInterval.current = setInterval(() => {
        if (isMounted.current === true) {
          doGetImports();
        } else {
          clearInterval(pollInterval.current);
          pollInterval.current = null;
        }
      }, 7000);
    }
  }, [doGetImports, doGetManufacturers, isMounted, pollInterval]);

  // Convert mfr object to Select option
  const getSelectedManufacturerOption = () => {
    const vObj = manufacturersData?.find((item) => item.id === selectedMfrId);
    return vObj ? [{ value: vObj.id, label: vObj.name }] : [];
  };

  const isStartBtnDisabled =
    startImportStatus === ActionStatusConstants.ISBUSY ||
    !selectedMfrId ||
    !selectedImportType ||
    selectedFiles?.length !== 1 ||
    !selectedFiles[0]?.name;

  const onSelectedFile = (files) => {
    let isValidTypes = true;

    if (!(files?.length === 1 && files[0]?.name)) {
      toast.error('You have to select a single file');
      return;
    }
    const file = files[0];

    if (!/\.(xls|xlsx|csv)$/i.test(file.name)) {
      toast.error(`File "${file.name}" is of invalid type. Allowed types are XLS, XLSX, CSV.`);
      isValidTypes = false;
    }
    if (file.size > MAX_FILE_SIZE) {
      toast.error(`File "${file.name}" is too large. Maximum allowed size is 2GB.`);
      isValidTypes = false;
    }

    if (isValidTypes && files?.length) {
      setSelectedFiles(files);
    }
  };

  const removeFile = () => {
    setSelectedFiles([]);
  };

  return (
    <>
      <div className="main-container">
        <div className="left-content">
          <div className="filter-select">
            <div className="title">Manufacturer</div>
            <StyledMultiselect
              values={getSelectedManufacturerOption()}
              options={
                manufacturersData
                  ? manufacturersData.map((item) => {
                      return { value: item.id, label: item.name };
                    })
                  : []
              }
              setOnChange={(v) => {
                setSelectedMfrId(v.value);
              }}
              getOptionValue={(option) => option.value}
              closeMenuOnSelect
              isSearchable
              isDisabled={
                startImportStatus === ActionStatusConstants.ISBUSY ||
                manufacturersDataStatus === ActionStatusConstants.ISBUSY
              }
              isMulti={false}
              isClearable={false}
              canReset
            />
          </div>

          <div className="filter-select">
            <div className="title">Import Type</div>
            <Form.Check
              type="radio"
              label={IMPORT_TYPES.general.label}
              checked={selectedImportType === IMPORT_TYPES.general.value}
              readOnly
              disabled={startImportStatus === ActionStatusConstants.ISBUSY}
              onClick={() => setSelectedImportType(IMPORT_TYPES.general.value)}
            />
            <Form.Check
              type="radio"
              label={IMPORT_TYPES.detailed.label}
              checked={selectedImportType === IMPORT_TYPES.detailed.value}
              readOnly
              disabled={startImportStatus === ActionStatusConstants.ISBUSY}
              onClick={() => setSelectedImportType(IMPORT_TYPES.detailed.value)}
            />
          </div>
        </div>

        <div className="center-content button-container">
          <Button
            className="btn btn-primary btn-lg"
            onClick={() => doStartImport()}
            disabled={isStartBtnDisabled}
          >
            Start Import
          </Button>
        </div>

        <div className="right-content">
          <FileUpload
            onRemoveFile={removeFile}
            selectedFiles={selectedFiles}
            maxFileCount={3}
            onDrop={onSelectedFile}
            isDisabled={isUploading || startImportStatus === ActionStatusConstants.ISBUSY}
            isFileLoading={isUploading}
            uploadProgress={uploadProgress}
            isUploadComplete={isUploadComplete}
            setIsUploadComplete={setIsUploadComplete}
            onSuccessMessage="File successfully added!"
            infoText="Maximum file size: 2Gb - Supported formats: .csv, .xls, .xlsx."
          />
        </div>
      </div>
      <div className="row">
        {importsData?.length ? (
          <div className="imports-table">
            <div className="bootstrap-table-wrap">
              <BootstrapTable
                bordered={false}
                bootstrap4
                keyField="id"
                data={importsData}
                pagination={paginationFactory({
                  totalSize: importsData?.length,
                  sizePerPageList: [],
                  sizePerPage: 10,
                  hidePageListOnlyOnePage: true,
                })}
                columns={ImportFunctionalityPanelTableDef.columns}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export { ImportFunctionalityPanel, IMPORT_TYPES };
