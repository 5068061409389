import React, { useEffect } from 'react';

import { CustomBotConfigForm } from '../CustomBotConfigForm';
import { CustomBotConfigUtils } from '../CustomBotConfigUtils';
import { IdsConstants } from '../IdsConstants';
import { IdsModels } from '../IdsModels';

const IdsAssessmentConfigForm = ({
  config,
  setCustomConfig,
  isLoading,
  setValidationResult,
  isAnonymous = false,
}) => {
  useEffect(() => {
    const hasError = CustomBotConfigUtils.containsError(config);
    const message = hasError ? IdsConstants.FORM_ERROR_MESSAGES.invalid_config_form : undefined;
    setValidationResult?.({ isValid: !hasError, message });
  }, [config, setValidationResult]);

  return (
    <section className="custom-bot-config">
      <CustomBotConfigForm
        isAnonymous={isAnonymous}
        customConfig={config}
        setCustomConfig={setCustomConfig}
        isLoading={isLoading}
        configGroups={IdsModels.CUSTOM_BOT_CFG_GROUPS}
        configModel={IdsModels.CUSTOM_BOT_CFG_MODEL}
      />
    </section>
  );
};

export { IdsAssessmentConfigForm };
