import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './Button.scss';

const BAIButton = ({ onClick, className, icon, label, secondary }) => {
  return (
    <>
      {secondary ? (
        <div onClick={onClick} className={`${className || ''}`}>
          {icon ? (
            <span className="btn-icon">
              <FontAwesomeIcon icon={['far', icon]} size="xs" />
            </span>
          ) : null}
          <div className="btn-label">{label}</div>
        </div>
      ) : (
        <Button block onClick={onClick}>
          {icon ? (
            <span className="bai-btn-icon">
              <FontAwesomeIcon icon={['far', icon]} size="xs" />
            </span>
          ) : null}
          <span className="bai-btn-label">{label}</span>
        </Button>
      )}
    </>
  );
};
BAIButton.defaultProps = {
  onClick: () => {},
  className: null,
  icon: null,
  label: 'OK',
  secondary: false,
};

BAIButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  secondary: PropTypes.bool,
};

export { BAIButton };
