import './WeeklyCompanyCards.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import 'intersection-observer';
import queryString from 'query-string';
import LazyLoad from 'react-lazyload';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingText } from 'components/common/LoadingText/LoadingText';
import { WeeklyCompanyCard } from 'components/metrics/WeeklyCompanyCard/WeeklyCompanyCard';
import { formatDate8601 } from 'helpers/TimeUtils';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

const WeeklyCompanyCards = ({
  handleViewDashboard,
  startDate,
  endDate,
  search,
  tier = 1,
  sortDir,
}) => {
  const [hasMore, setHasMore] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [metrics, setMetrics] = useState([]);
  const isMounted = useIsMounted();
  const observerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const doLoadMore = useCallback(
    (cursor, metrics = [], search, startDate, endDate, tier) => {
      setIsLoading(true);
      const query = {};
      query.cursor = cursor;
      query.tier = tier;
      if (endDate) query.end_date = formatDate8601(endDate);
      if (startDate) query.start_date = formatDate8601(startDate);
      if (search)
        query.companies = search
          .split(',')
          .map((company) => company.trim())
          .join(',');

      const url = `/weekly-metrics/companies?${queryString.stringify(query)}`;

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: url,
        onSuccess: (res) => {
          const { companies, cursor, has_more: hasMore } = res.data;

          if (isMounted.current) {
            setMetrics([...metrics, ...companies]);
            setHasMore(hasMore);
            setCursor(cursor);
          }
        },
        onError: (e) => {
          // FIXME if a message is to be shown, put the message in response.data.message and let doCall wrapper handle it
          toast.error(e.response.data);
        },
        onEnd: () => {
          setIsLoading(false);
        },
      });
    },
    [isMounted]
  );

  const loadMoreCb = useCallback(
    (cursor, metrics, search, startDate, endDate, hasMore, tier) => {
      if (hasMore) doLoadMore(cursor, metrics, search, startDate, endDate, tier);
    },
    [doLoadMore]
  );

  useEffect(() => {
    let observerRefValue = null;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const callbackFunction = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && metrics.length > 0)
        loadMoreCb(cursor, metrics, search, startDate, endDate, hasMore, tier);
    };

    const observer = new IntersectionObserver(callbackFunction, options);
    if (observerRef.current) {
      observer.observe(observerRef.current);
      observerRefValue = observerRef.current;
    }

    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    };
  }, [observerRef, loadMoreCb, cursor, metrics, search, startDate, endDate, hasMore, tier]);

  useEffect(() => {
    setMetrics([]);
    doLoadMore(0, [], search, startDate, endDate, tier);
  }, [doLoadMore, search, startDate, endDate, tier]);

  return (
    <section className="weekly-metrics-details">
      {metrics.map((metric, i) => (
        <LazyLoad key={metric.name} offset={100}>
          <WeeklyCompanyCard
            handleViewDashboard={handleViewDashboard}
            startDate={startDate}
            endDate={endDate}
            sortDir={sortDir}
            expand={i === 0}
            data={metric}
          />
        </LazyLoad>
      ))}
      {isLoading && (
        <div className="weekly-metrics-row-loading-wrapper">
          <div className="weekly-metrics-row-loading">
            <LoadingText />
          </div>
        </div>
      )}
      {!metrics.length && !isLoading && <p>No companies found.</p>}
      <div ref={observerRef} />
    </section>
  );
};

export { WeeklyCompanyCards };
