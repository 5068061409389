import './Attributes.scss';
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { sortAttributeSections } from 'helpers/ProductUtils';

import { AttributesHeader } from './AttributesHeader';
import { AttributesTable } from './AttributesTable';
import { Taxonomy } from './Taxonomy';

const ProcessedAttributes = ({
  generalAttributes,
  taxonomy,
  productId,
  setIsFilterVisible,
  isFilterVisible,
}) => {
  const [sections, setSections] = useState([]);
  const [categorySections, setCategorySections] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeTab, setActiveTab] = useState('attributes');
  const [sortDirection, setSortDirection] = useState('default');

  useEffect(() => {
    const attributes = _cloneDeep(generalAttributes);
    const sections = attributes.filter((section) => !section._hide);
    const rows = [];
    sections.forEach((section) => {
      section.filteredOut = false;
      section.columns.forEach((column) => rows.push(column.name));
    });

    setCategorySections(sections);
    setSections(sections);
    setRows(rows);
  }, [generalAttributes]);

  const sortAttributes = () => {
    const sortDirections = ['default', 'asc', 'desc'];

    let sortedSections = _cloneDeep(sections);
    const sortedRows = [];

    const newSortDirection =
      sortDirections[sortDirections.indexOf(sortDirection) + 1] ?? sortDirections[0];

    sortedSections = sortAttributeSections(sortedSections, newSortDirection);

    sortedSections.forEach((section) => {
      if (!section.filteredOut) section.columns.forEach((column) => sortedRows.push(column.name));
    });

    setRows(sortedRows);
    setSections(sortedSections);
    setSortDirection(newSortDirection);
  };

  const renderHeaderTabs = () => {
    const tabs = [
      {
        label: 'Processed Attributes',
        key: 'attributes',
      },
      { label: 'Taxonomy', key: 'taxonomy' },
    ];

    return tabs.map((tab) => (
      <div
        key={tab.key}
        onClick={() => setActiveTab(tab.key)}
        className={classNames('attributes-title', {
          'attributes-title--active': activeTab === tab.key,
        })}
      >
        {tab.label}
      </div>
    ));
  };

  const renderAttributesBody = () =>
    activeTab === 'attributes' ? (
      <>
        <AttributesHeader
          productId={productId}
          sections={sections}
          generalAttributes={generalAttributes}
          setRows={setRows}
          categorySections={categorySections}
          setCategorySections={setCategorySections}
          setSections={setSections}
          isFilterVisible={isFilterVisible}
          setIsFilterVisible={setIsFilterVisible}
        />
        <AttributesTable
          sections={sections}
          sortDirection={sortDirection}
          rows={rows}
          sortAttributes={sortAttributes}
        />
      </>
    ) : (
      <Taxonomy taxonomy={taxonomy.value} />
    );

  return (
    <div className="attributes">
      <div className="attributes-header">{renderHeaderTabs()}</div>
      <div className="attributes-body">{renderAttributesBody()}</div>
    </div>
  );
};

ProcessedAttributes.propTypes = {
  generalAttributes: PropTypes.array,
  taxonomy: PropTypes.object,
  productId: PropTypes.number,
};

export { ProcessedAttributes };
