import React from 'react';

import classNames from 'classnames';
import { ProgressBar } from 'react-bootstrap';

const ScoreScaleBar = ({
  value = null,
  className = null,
  style = null,
  nullLabel = null,
  minProgressVisible = 0,
  reversed = false,
}) => {
  const ranges = [
    [0, 35, '#ffb658'],
    [35, 70, '#6abd80'],
    [70, 100, '#20c094'],
  ];

  const getAbsValue = (v) => {
    let output = null;

    if (typeof v === 'number') {
      output = Math.max(Math.min(v, 100), 0);
    }

    return output;
  };

  const getBarColor = (v, ranges, reversed = false) => {
    let tempValue = v;
    if (reversed) {
      tempValue = 100 - v;
    }

    const matchingRange = ranges.find(([min, max]) => tempValue >= min && tempValue <= max);

    if (matchingRange) {
      return matchingRange[2];
    }
    return ranges[0][2];
  };

  const absValue = getAbsValue(value);
  const barValue = absValue !== null ? Math.max(absValue, minProgressVisible) : 0;
  const barColor = getBarColor(barValue, ranges, reversed);

  return (
    <div className={classNames('score-scale-bar-wrap', className)} style={style}>
      <ProgressBar now={barValue} className="score-scale-progress-bar">
        <div
          className="score-scale-progress-fill"
          style={{
            width: `${barValue}%`,
            background: barColor,
          }}
        />
      </ProgressBar>

      <div className="score-scale-value">
        {typeof absValue === 'number' && absValue >= 0
          ? `${Math.round(absValue)}%`
          : nullLabel || ''}
      </div>
    </div>
  );
};

export default ScoreScaleBar;
