import React from 'react';

import classNames from 'classnames';
import propTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const Checkbox = ({ checked = false, disabled = false, className }) => {
  const prefix = uuidv4();

  const renderDisabled = () => (
    <svg
      className={classNames('control-dg', className)}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Checkbox (Disable)</title>
      <defs>
        <filter colorInterpolationFilters="auto" id={`${prefix}-filter-1`}>
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0.874510 0 0 0 0 0.882353 0 0 0 0 0.894118 0 0 0 1.000000 0"
          />
        </filter>
        <polygon
          id={`${prefix}-path-2`}
          points="6.53196055 0.75 5.8289212 1.48700787 2.99638586 4.33267717 2.1710788 3.53425197 1.43747252 2.79724409 0.000826901338 4.24055118 0.734433177 4.97755906 2.26277958 6.51299213 2.96581893 7.25 3.69942521 6.51299213 7.26556682 2.93031496 7.9991731 2.19330709"
        />
      </defs>
      <g id={`${prefix}-Style-Guide`} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id={`${prefix}-10--Checkbox`} transform="translate(-299.000000, -242.000000)">
          <g id={`${prefix}-Group-Checkbox`} transform="translate(300.000000, 150.000000)">
            <g id={`${prefix}-Group-Copy-2`} transform="translate(0.000000, 90.000000)">
              <g id={`${prefix}-Group`} transform="translate(0.000000, 3.000000)">
                <rect
                  id={`${prefix}-Rectangle`}
                  stroke="#DFE1E4"
                  fill="#F9F9F9"
                  x="0"
                  y="0"
                  width="14"
                  height="14"
                  rx="4"
                />
                <g
                  transform="translate(3.000000, 3.000000)"
                  id={`${prefix}-icon/check`}
                  filter={`url(#${prefix}-filter-1)`}
                >
                  <g>
                    <mask id={`${prefix}-mask-3`} fill="white">
                      <use xlinkHref="#path-2" />
                    </mask>
                    <use
                      id={`${prefix}-Shape`}
                      fill="#000000"
                      fillRule="nonzero"
                      xlinkHref="#path-2"
                    />
                    <g id={`${prefix}-Group`} mask={`url(#${prefix}-mask-3)`} fill="#FFFFFF">
                      <g id={`${prefix}-Color`}>
                        <rect id={`${prefix}-Rectangle`} x="0" y="0" width="8" height="8" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  const renderUnchecked = () => (
    <svg
      className={classNames('control-dg', className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1H5C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11C13.2091 15 15 13.2091 15 11V5C15 2.79086 13.2091 1 11 1Z"
        fill="white"
      />
      <path
        d="M11 1H5C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11C13.2091 15 15 13.2091 15 11V5C15 2.79086 13.2091 1 11 1Z"
        stroke="#DFE1E4"
      />
    </svg>
  );

  const renderChecked = () => (
    <svg
      className={classNames('control-dg', className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1H5C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11C13.2091 15 15 13.2091 15 11V5C15 2.79086 13.2091 1 11 1Z"
        fill="black"
      />
      <path
        d="M11 1H5C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11C13.2091 15 15 13.2091 15 11V5C15 2.79086 13.2091 1 11 1Z"
        fill="#20c094"
        stroke="#16ad83"
      />
      <mask
        id={`${prefix}-mask0`}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="8"
        height="8"
      >
        <path
          d="M10.532 4.75L9.82892 5.48701L6.99639 8.33268L6.17108 7.53425L5.43747 6.79724L4.00083 8.24055L4.73443 8.97756L6.26278 10.513L6.96582 11.25L7.69943 10.513L11.2656 6.93032L11.9992 6.19331L10.532 4.75Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${prefix}-mask0)`}>
        <path
          d="M10.532 4.75L9.82892 5.48701L6.99639 8.33268L6.17108 7.53425L5.43747 6.79724L4.00083 8.24055L4.73443 8.97756L6.26278 10.513L6.96582 11.25L7.69943 10.513L11.2656 6.93032L11.9992 6.19331L10.532 4.75Z"
          fill="black"
        />
        <path d="M12 4H4V12H12V4Z" fill="white" />
      </g>
    </svg>
  );

  const renderCheckbox = () => {
    if (disabled) return renderDisabled();
    return checked ? renderChecked() : renderUnchecked();
  };

  return renderCheckbox();
};

Checkbox.propTypes = {
  checked: propTypes.bool,
  disabled: propTypes.bool,
  className: propTypes.string,
};

export { Checkbox };
