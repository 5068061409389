import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as ApiCalls from 'api/ApiCalls';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { formatDateStamp } from 'helpers/TimeUtils';
import { getDefaultTableColumnDef, splitExt } from 'helpers/Utils';

const EXPORT_TYPES = {
  general: { value: 'general', label: 'General Attributes' },
  // TODO: remove detailed when it will be deprecated and rename detailed_new to detailed.
  detailed: { value: 'detailed', label: 'Detailed Attributes' },
  asset: { value: 'asset', label: 'Digital Assets' },
  taxonomy: { value: 'taxonomy', label: 'Taxonomy Information' },
  detailed_new: { value: 'detailed_new', label: 'Detailed Attributes 3.0' },
  price_info: { value: 'price_info', label: 'Price Information' },
};

const ExportFunctionalityPanelTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('file', 'File'),
      sort: false,
      formatter: (cell, row) => {
        return row.status === 'done' ? (
          <a
            className="btn btn-primary btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href={`${ApiCalls.BASE_API_URL}/product-export-history/${
              row.id
            }/download?token=${getAuthToken()}`}
          >
            <span className="icon" style={{ marginRight: '0.5em' }}>
              <FontAwesomeIcon icon={['far', 'file-download']} />
            </span>
            Download
          </a>
        ) : null;
      },
    },
    {
      ...getDefaultTableColumnDef('manufacturer', 'Manufacturer'),
      sort: false,
      formatter: (cell, row) => row.manufacturer?.name || 'N/A',
    },
    {
      ...getDefaultTableColumnDef('type', 'Export Type'),
      sort: false,
      formatter: (cell, row) => {
        let output = 'N/A';
        if (Object.keys(EXPORT_TYPES).includes(row.type)) {
          output = EXPORT_TYPES[row.type].label;
        }
        return output;
      },
    },

    {
      ...getDefaultTableColumnDef('filename', 'File Type'),
      sort: false,
      formatter: (_cell, row) => {
        if (row?.file_name) {
          const [, extensions] = splitExt(row.file_name);
          return extensions.toUpperCase();
        }
        return 'N/A';
      },
    },

    {
      ...getDefaultTableColumnDef('started', 'Started At'),
      sort: false,
      formatter: (_cell, row) => (row.created_at ? formatDateStamp(row.created_at) : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('status', 'Status'),
      sort: false,
      formatter: (cell, row) => {
        let output = 'N/A';

        if (['in_progress', 'failed', 'done'].includes(row.status)) {
          output = { in_progress: 'In Progress', failed: 'Failed', done: 'Done' }[row.status];
        }
        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('created_by', 'Created By'),
      sort: false,
      formatter: (_cell, row) => row.user?.email || 'N/A',
    },
  ],
};

export { ExportFunctionalityPanelTableDef, EXPORT_TYPES };
