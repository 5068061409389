import React from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { IdsAssessmentConfigForm } from './_ids_configs/IdsAssessmentConfigForm';
import { IdsEnhancementConfigForm } from './_ids_configs/IdsEnhancementConfigForm';
import './ImgBotEngineModal.scss';

const ImgBotEngineModalConfigDetails = ({ processJob, isAssessmentOnly }) => {
  return (
    <div className="img-bot-modal-panel-body">
      <section className="custom-bot-config">
        <Tabs
          defaultActiveKey="assessment"
          id="uncontrolled-tab-example"
          className="mb-3 ids-config-tabs"
        >
          <Tab eventKey="assessment" title="Assessment">
            <>
              <section className="custom-config-wrap">
                {processJob?.assessment_config ? (
                  <div className="custom-config-block img-bot-config-obj-info">
                    <div className="title">Predefined Config</div>
                    <span className="config-name">
                      {processJob?.assessment_config?.name} (ID:{' '}
                      {`${processJob?.assessment_config?.id}`})
                    </span>
                  </div>
                ) : undefined}
              </section>
              <IdsAssessmentConfigForm
                config={processJob?.config_data?.assessment}
                setCustomConfig={() => {}}
                isLoading
              />
            </>
          </Tab>
          <Tab
            eventKey="enhancement"
            title="Enhancement"
            tabClassName={isAssessmentOnly ? 'd-none' : undefined}
          >
            <>
              <section className="custom-config-wrap">
                {processJob?.enhancement_config ? (
                  <div className="custom-config-block img-bot-config-obj-info">
                    <div className="title">Predefined Config</div>
                    <span className="config-name">
                      {processJob?.enhancement_config?.name} (ID:{' '}
                      {`${processJob?.enhancement_config?.id}`})
                    </span>
                  </div>
                ) : undefined}
              </section>
              {isAssessmentOnly ? undefined : (
                <IdsEnhancementConfigForm
                  config={processJob?.config_data?.enhancement}
                  setConfiguration={() => {}}
                  isLoading
                />
              )}
            </>
          </Tab>
        </Tabs>
      </section>
    </div>
  );
};

export { ImgBotEngineModalConfigDetails };
