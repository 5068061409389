import './AssessmentsCards.scss';

import React, { useEffect, useState } from 'react';

import * as ApiCalls from 'api/ApiCalls';
import { AssessmentsCard } from 'components/assessments/AssessmentsCard/AssessmentsCard';
import { TRANSFORMATION_REQUIRED_STATUS } from 'constants/DataJobDetailsConstants';
import { canViewAssessment } from 'helpers/FlashAssessmentUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { joinNameToStr } from 'helpers/Utils';

/**
 * @returns One card for each job up to the @limit. Each card shows the job's assessments. Sorts assessments and jobs by most recent assessment.
 */
const AssessmentsCards = ({
  handleViewDataJob,
  setTotalAssessments,
  setAssessmentsLength,
  isLoading,
  setIsLoading,
  finalQueryString,
}) => {
  const [jobs, setJobs] = useState([]);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted.current) {
      setIsLoading(true);
    }
    const url = `/data-requests/assessments?${finalQueryString}`;
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: url,
      onSuccess: (res) => {
        const jobs = res.data.assessments;
        const total = res.data;

        if (isMounted.current && jobs !== null) {
          const modifiedJobs = jobs.map((job) => {
            return {
              id: job.id,
              name: job.name,
              attachments: job.attachments
                .filter((attachment) => canViewAssessment(attachment))
                .map((attachment) => {
                  return {
                    id: attachment.id,
                    fileName: attachment.original_name,
                    fileSize: attachment.size,
                    type:
                      attachment.transformation_required ===
                      TRANSFORMATION_REQUIRED_STATUS.DONE.value
                        ? 'Transformed'
                        : 'Original',
                    uploadedBy: {
                      name: joinNameToStr(attachment.user.first_name, attachment.user.last_name),
                      image: attachment.user?.profile?.picture,
                    },
                    createdAt: attachment.created_at,
                    flashAssessmentPublishedAt: attachment.flash_assessment_published_at,
                    flashAssessmentId: attachment.flash_assessment_id,
                  };
                }),
            };
          });
          setJobs(modifiedJobs);
          setTotalAssessments && setTotalAssessments(total?.total);
          setAssessmentsLength && setAssessmentsLength(modifiedJobs?.length ?? 0);
        }
      },
      onEnd: () => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      },
    });
  }, [isMounted, setTotalAssessments, setIsLoading, setAssessmentsLength, finalQueryString]);

  return (
    <section className="assessments-details">
      {!isLoading &&
        jobs.map((job) => (
          <AssessmentsCard key={job.id} handleViewDataJob={handleViewDataJob} data={job} />
        ))}
      {jobs.length === 0 && !isLoading && <p>No assessments found.</p>}
    </section>
  );
};

export { AssessmentsCards };
