import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BotRanIcon } from 'components/databots/BotRanIcon';
import { generateDownloadUrl } from 'helpers/AttachmentUtils';
import { formatMinDateStamp } from 'helpers/TimeUtils';
import { joinNameToStr, sortByKey, getDefaultTableColumnDef } from 'helpers/Utils';

export const SourceFilesTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('original_name', 'File Name'),
      headerStyle: () => {
        return { width: '377px', height: '40px', textAlign: 'center', overflowWrap: 'break-word' };
      },
      formatter: (_cell, row) => (
        <div className="assessments-table-file-details">
          <a
            href={generateDownloadUrl(row.id)}
            target="_blank"
            rel="noreferrer noopener"
            className="assessments-table-file-details__name"
          >
            {row.original_name}
          </a>
        </div>
      ),
    },
    {
      ...getDefaultTableColumnDef('uploaded_by', 'Uploaded By'),
      sort: false,
      formatter: (_cell, row) => {
        return (
          <div className="assessments-table-user-img-name">
            {joinNameToStr(row?.user?.first_name, row?.user?.last_name)}
          </div>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('created_at', 'Date'),
      headerStyle: () => {
        return { width: '96px', textAlign: 'center' };
      },
      formatter: (_cell, row) => {
        const formattedDate = formatMinDateStamp(row.created_at);

        return (
          <div className="assessments-table-uploaded-at">
            <span className="assessments-table-uploaded-at__date">{formattedDate}</span>
          </div>
        );
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      ...getDefaultTableColumnDef('data_request_ids', 'Data Job Ref ID'),
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      sort: false,
      formatter: (_cell, row) => {
        const ids = row.data_requests_ids;
        let value = '';

        if (ids) {
          value = ids.map((item, index) => (
            <a
              href={`/data-request/${item}`}
              target="_blank"
              key={`id_${item}_${index}`}
              rel="noreferrer noopener"
              id={`id_${item}_${index}`}
              className="sources-table-file-details__name"
            >
              {index < ids.length - 1 ? <span>{item},&nbsp;</span> : <span>{item}</span>}
            </a>
          ));
        }
        return <div className="sources-table-ref-details">{value}</div>;
      },
    },
    {
      ...getDefaultTableColumnDef('bots', 'Databots'),
      sort: false,
      formatter: (_cell, row) => {
        const dataBots = sortByKey(
          row.bots.filter((bot) => !bot.automated),
          'name'
        );
        let databotsUrl = '/databots';
        if (row.manufacturerId) databotsUrl += `?manufacturer_id=${row.manufacturerId}`;

        return (
          <div className="sources-table-bots">
            {dataBots.slice(0, 4).map((bot) => (
              <BotRanIcon
                key={bot.name}
                className="sources-table-bots__icon"
                withTooltip
                tooltipText={bot.name}
                bot={bot}
              />
            ))}
            <a href={databotsUrl}>
              {' '}
              <BotRanIcon
                withTooltip
                tooltipText="Click here to go to the databots dashboard"
                icon={<FontAwesomeIcon size="sm" icon={['fas', 'plus']} />}
              />
            </a>
          </div>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('assessment', 'Assessments'),
      sort: false,
      formatter: (_cell, row) => {
        return row.assessment ? (
          <button
            className="assessments-table-view-assessment"
            onClick={() => (window.location.href = `/flash-assessment/${row.assessment}`)}
            type="button"
          >
            View Assessment
          </button>
        ) : (
          ''
        );
      },
    },
    {
      ...getDefaultTableColumnDef('actions', 'File Download'),
      sort: false,
      formatter: (_cell, row) => {
        const downloadFile = () => {
          const downloadUrl = generateDownloadUrl(row.id) || null;
          if (!row.id) {
            return;
          }
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.target = '_blank';
          a.rel = 'noopener noreferrer';
          a.click();
        };
        return (
          <button className="sources-table-file-download" onClick={downloadFile} type="button">
            Download File
          </button>
        );
      },
    },
  ],
};
