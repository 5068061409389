import React, { useEffect, useState, useRef } from 'react';

import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { WsConstants } from 'api/WsConstants';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import * as QueryStringConstants from 'constants/QueryStringConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import './DataLakehouseDashboard.scss';
import './DataLakehouseDashboardAdmin.scss';
import { useWebsocket } from 'helpers/useWebsocket';

import * as DataLakehouseDashboardApiCalls from './private/DataLakehouseDashboardApiCalls';
import { SectionDatabots } from './private/SectionDatabots';
import { SectionDataJobs } from './private/SectionDataJobs';
import { SectionImageQuality } from './private/SectionImageQuality';
import { SectionImgBotEngine } from './private/SectionImgBotEngine';
import { SectionManufacturerSelect } from './private/SectionManufacturerSelect';
import { SectionSourceFiles } from './private/SectionSourceFiles';
import { SectionSummaryAdmin } from './private/SectionSummaryAdmin';
import { SectionTransformedFiles } from './private/SectionTransformedFiles';

// Parses URL query string to get selected mfr and child mfr ids
const getMfrStateFromSearchStr = (searchStr) => {
  const params = {};

  const queryData = queryString.parse(searchStr, QueryStringConstants.defaultQueryStringOptions);

  const parseNonZeroInt = (v) => {
    if (v && v.length) {
      const vNum = Number.parseInt(v);
      if (!Number.isNaN(vNum) && vNum >= 1) {
        return vNum;
      }
    }
    return null;
  };

  params.manufacturer_id = parseNonZeroInt(queryData.manufacturer_id) ?? null;
  params.child_company_id = parseNonZeroInt(queryData.child_company_id) ?? null;

  return params;
};

/**
 * Renders Data Lakehouse dashboard
 *
 * @return render
 */
const DataLakehouseDashboardAdmin = () => {
  const isMounted = useIsMounted();
  const location = useLocation();
  const history = useHistory();

  const { activeUser } = RootHooks.useActiveUser();

  const isAdmin = activeUser?.role === UserRoleConstants.ADMIN;

  // Selected company id(mfr or child company) either from admin selector or current user object
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  // Simply holds whatever params are parsed from the URL
  const [selectedMfrId, setSelectedMfrId] = useState(null);
  const [selectedMfrChildId, setSelectedMfrChildId] = useState(null);

  // Holds mfr data object for selected mfr
  const [mfrData, setMfrData] = useState(null);
  const [mfrDataStatus, setMfrDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds summary section data
  const [summaryData, setSummaryData] = useState(null);
  const [summaryDataStatus, setSummaryDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds databots data
  const [databotsData, setDatabotsData] = useState(null);
  const [databotsDataStatus, setDatabotsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds databots data
  const [dataJobsData, setDataJobsData] = useState(null);
  const [dataJobsDataStatus, setDataJobsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds image quality assessment data
  const [imageQualityAssessmentData, setImageQualityAssessmentData] = useState([]);
  const [imageQualityAssessmentDataStatus, setImageQualityAssessmentDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  // Updates URL with new mfr/mfrChild ids
  const updateUrlState = ({ mfrId, mfrChildId }) => {
    const params = {
      manufacturer_id: mfrId ?? null,
      child_company_id: mfrChildId ?? null,
    };

    const qStr = queryString.stringify(params, {
      ...QueryStringConstants.defaultQueryStringOptions,
      skipNull: true,
    });

    history.push({
      search: qStr?.length ? qStr : null,
    });
  };

  // Refresh dashboard data on mfr id set
  useEffect(() => {
    if (selectedCompanyId) {
      DataLakehouseDashboardApiCalls.doGetSummary({
        mfrId: selectedCompanyId,
        isMounted,
        setSummaryData,
        setSummaryDataStatus,
      });

      DataLakehouseDashboardApiCalls.doGetMfr({
        mfrId: selectedCompanyId,
        isMounted,
        setMfrData,
        setMfrDataStatus,
      });

      DataLakehouseDashboardApiCalls.doGetDatabots({
        mfrId: selectedCompanyId,
        isMounted,
        setDatabotsData,
        setDatabotsDataStatus,
      });

      DataLakehouseDashboardApiCalls.doGetDataJobs({
        mfrId: selectedCompanyId,
        isMounted,
        setDataJobsData,
        setDataJobsDataStatus,
      });
      DataLakehouseDashboardApiCalls.doGetImageQualityAssessment({
        id: selectedCompanyId,
        type: 'manufacturer',
        isMounted,
        setImageQualityAssessmentData,
        setImageQualityAssessmentDataStatus,
      });
    } else {
      setSummaryData(null);
      setMfrData(null);
    }
  }, [isMounted, selectedCompanyId]);

  // Initialize mfr id from user if user is mfr
  // If user is an admin, parse URL params to initialize mfr/child mfr id selection
  const currentUserMfrId = activeUser?.profile?.manufacturer?.id;
  useEffect(() => {
    if (isAdmin) {
      const urlState = getMfrStateFromSearchStr(location.search);
      setSelectedMfrId(urlState?.manufacturer_id ?? null);
      setSelectedMfrChildId(urlState?.child_company_id ?? null);
    } else if (currentUserMfrId) {
      setSelectedCompanyId(currentUserMfrId);
    }
  }, [isAdmin, isMounted, currentUserMfrId, location.search]);

  // Initialize company id from URL change
  useEffect(() => {
    if (isAdmin) {
      setSelectedCompanyId(selectedMfrChildId ?? selectedMfrId ?? null);
    }
  }, [isAdmin, selectedMfrId, selectedMfrChildId]);

  const mfrLoading = [mfrDataStatus, summaryDataStatus].includes(ActionStatusConstants.ISBUSY);

  const isLoading = [
    mfrDataStatus,
    summaryDataStatus,
    databotsDataStatus,
    dataJobsDataStatus,
    imageQualityAssessmentDataStatus,
  ].includes(ActionStatusConstants.ISBUSY);

  // websocket configuration
  const channel = WsConstants.WS_CHANNELS.BOTS;

  // Holds raw data between loads(previous value)
  const rawBotsData = useRef(null);

  // create websocket
  const ws = useWebsocket();
  const [wsLastUpdate, setWsLastUpdate] = useState(null);
  const { messages, messagesLastUpdate } = ws;

  // add websocket channel
  useEffect(() => {
    if (isMounted.current === true && ws?.isConnected && wsLastUpdate === null) {
      ws.addChannel(channel, { type: 'manufacturer', id: selectedMfrId });
      setWsLastUpdate(Date.now());
    }
  }, [isMounted, ws?.isConnected, ws, wsLastUpdate, selectedMfrId]);

  // reset on unmount
  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname !== `/data-lakehouse/dashboard?manufacturer_id=${selectedMfrId}`) {
        ws.disconnectChannel(channel, selectedMfrId);
      }
    });
    return () => unlisten();
  }, [ws, wsLastUpdate, isMounted, history, selectedMfrId]);

  // watch for new messages in that channel
  useEffect(() => {
    if (
      isMounted.current === true &&
      ws?.isConnected &&
      messages[channel]?.length > 0 &&
      wsLastUpdate < messagesLastUpdate[channel]
    ) {
      setWsLastUpdate(messagesLastUpdate[channel]);
      try {
        if (isMounted.current === true) {
          const _data = messages[channel];
          if (!_data || _data === undefined) {
            return;
          }

          rawBotsData.current = _data;
          setDatabotsData(rawBotsData.current);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [ws?.isConnected, isMounted, wsLastUpdate, ws, messages, messagesLastUpdate]);

  return (
    <div className="data-lakehouse-dashboard data-lakehouse-dashboard-admin">
      {mfrLoading && <LoadingView />}
      {isAdmin && (
        <SectionManufacturerSelect
          manufacturerSelection={{ mfrId: selectedMfrId, mfrChildId: selectedMfrChildId }}
          onChangeManufacturerSelection={({ mfrId, mfrChildId }) =>
            updateUrlState({ mfrId, mfrChildId })
          }
          isDisabled={isLoading}
        />
      )}

      {mfrData && summaryData && (
        <SectionSummaryAdmin summaryData={summaryData} manufacturerData={mfrData} />
      )}
      {/* details section */}
      {mfrLoading ? null : isLoading ? (
        <div className="details-loading-wrapper">
          <LoadingSpinner fast style={{ fontSize: '3em', marginTop: '3em' }} />
        </div>
      ) : mfrData ? (
        <>
          <SectionImgBotEngine />

          {imageQualityAssessmentData?.length > 0 ||
          (imageQualityAssessmentData?.length === 0 &&
            [ActionStatusConstants.ISBUSY, ActionStatusConstants.INITIAL].includes(
              imageQualityAssessmentDataStatus
            )) ? (
            <SectionImageQuality
              isAdmin={isAdmin}
              type="manufacturer"
              imageQualityAssessmentData={imageQualityAssessmentData}
              imageQualityAssessmentDataStatus={imageQualityAssessmentDataStatus}
              id={selectedMfrId}
            />
          ) : null}

          <SectionDatabots
            parentManufacturerId={selectedMfrChildId ? selectedMfrId : null}
            databotsData={databotsData}
            mfrId={mfrData?.id}
            databotsDataStatus={databotsDataStatus}
            isAdmin={isAdmin}
          />
          <SectionDataJobs
            dataJobsData={dataJobsData}
            mfrId={mfrData.id}
            dataJobsDataStatus={dataJobsDataStatus}
          />
          <SectionTransformedFiles mfrId={mfrData.id} />
          <SectionSourceFiles mfrId={mfrData.id} />
        </>
      ) : null}
    </div>
  );
};

export { DataLakehouseDashboardAdmin };
