import React, { useEffect, useReducer } from 'react';

import { useDefaultConfigData } from 'helpers/useDefaultConfigData';

import { CdsModels } from '../../CdsModels';
import { CdsAttributeCompressionForm } from './CdsAttributeCompressionForm';
import { GptDescriptionGeneratorForm } from './GptDescriptionGeneratorForm';
import { GptFeatureBenefitsGeneratorForm } from './GptFeatureBenefitsGeneratorForm';

import './CdsConfigurationForm.scss';

const stateChangeActions = {
  CONFIG_CHANGED: 'CONFIG_CHANGED',
  DEFAULT_CONFIG_CHANGED: 'DEFAULT_CONFIG_CHANGED',
  FORM_ERROR: 'FORM_ERROR',
  SET_LOADING: 'SET_LOADING',
};

const handleStateChanged = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case stateChangeActions.CONFIG_CHANGED:
      newState = {
        ...state,
        config: payload,
      };
      break;
    case stateChangeActions.DEFAULT_CONFIG_CHANGED:
      newState = {
        ...state,
        config: {
          ...payload,
          ...(state.config || {}),
        },
        defaultConfig: payload,
      };
      break;
    case stateChangeActions.FORM_ERROR:
      newState = {
        ...state,
        errors: {
          ...(state.errors || {}),
          ...payload,
        },
      };
      newState = {
        ...newState,
        hasErrors: Object.values(newState.errors).some((error) => !!error),
      };
      break;

    case stateChangeActions.SET_LOADING:
      newState = {
        ...state,
        isLoading: payload,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

const initializeState = ({ botJob, defaultConfigData, isLoading }) => {
  const configData = {
    ...defaultConfigData,
    ...(botJob?.config_data || {}),
  };

  return {
    config: configData,
    defaultConfig: defaultConfigData,
    isLoading,
    errors: {},
    hasErrors: false,
  };
};

export const CdsConfigurationForm = ({
  botJob,
  selectedMode,
  setConfig,
  setIsInvalid,
  disabled,
}) => {
  const defaultConfigData = useDefaultConfigData({
    defaultConfigType: selectedMode,
  });

  const [state, dispatchStateChange] = useReducer(
    handleStateChanged,
    {
      botJob,
      defaultConfig: defaultConfigData,
      isLoading: false,
    },
    initializeState
  );

  useEffect(() => {
    if (setIsInvalid) {
      setIsInvalid(state.hasErrors);
    }
  }, [state.hasErrors, setIsInvalid]);

  useEffect(() => {
    if (typeof setConfig === 'function') {
      setConfig(state.config);
    }
  }, [state.config, setConfig]);

  const onConfigChanged = (e) => {
    // TODO: this is a hack to avoid infinite loop...Must Fix
    if (JSON.stringify(state.config) !== JSON.stringify(e)) {
      dispatchStateChange({
        type: stateChangeActions.CONFIG_CHANGED,
        payload: e,
      });
    }
  };

  const onFormError = (key, error) => {
    // TODO: this is a hack to avoid infinite loop...Must Fix
    if (state.errors[key] === error) {
      return;
    }
    dispatchStateChange({
      type: stateChangeActions.FORM_ERROR,
      payload: {
        [key]: error,
      },
    });
  };

  useEffect(() => {
    dispatchStateChange({
      type: stateChangeActions.DEFAULT_CONFIG_CHANGED,
      payload: defaultConfigData,
    });
  }, [defaultConfigData]);

  return (
    <>
      {selectedMode === CdsModels.CDS_BOT_ITEM.gptDescription.name && (
        <GptDescriptionGeneratorForm
          headers={botJob?.additional_data?.[0]?.headers || []}
          config={state.config}
          setConfig={(e) => {
            onConfigChanged(e);
          }}
          errors={state.errors}
          onFormErrors={onFormError}
          disabled={disabled}
        />
      )}

      {selectedMode === CdsModels.CDS_BOT_ITEM.attributeCompression.name && (
        <CdsAttributeCompressionForm
          headers={botJob?.additional_data?.[0]?.headers || []}
          config={state.config}
          setConfig={(e) => {
            onConfigChanged(e);
          }}
          errors={state.errors}
          onFormErrors={onFormError}
          disabled={disabled}
        />
      )}

      {selectedMode === CdsModels.CDS_BOT_ITEM.gptFB.name && (
        <GptFeatureBenefitsGeneratorForm
          headers={botJob?.additional_data?.[0]?.headers || []}
          config={state.config}
          setConfig={(e) => {
            onConfigChanged(e);
          }}
          errors={state.errors}
          onFormErrors={onFormError}
          disabled={disabled}
        />
      )}
    </>
  );
};
