import './StyledMultiselect.scss';

import React, { useMemo, useEffect, useState, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { isIE } from 'react-device-detect';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';

import { useIsMounted } from 'helpers/useIsMounted';
import styleVars from 'scss/vars.scss';

/*
Component expects the following props.
id = html id of the element
options = array of objects in the form {label: "l", value, "v" }
values = state object to store values in
setOnChange = function to resolve on change
isDisabled = boolean
*/

const StyledMultiselect = ({
  id,
  options,
  optionLimit,
  allowUserAddCustomValue = false,
  optionsSort,
  optionsSortFunc,
  values,
  setOnChange,
  isDisabled,
  isInvalid = false,
  isMulti = true,
  isSearchable = false,
  placeholder,
  hideSelectedOptions = true,
  selectStyle,
  selectWrapperStyle,
  getOptionValue,
  getOptionLabel,
  backspaceRemovesValue = true,
  showDropdownIndicator = true,
  isClearable = false,
  customClearText,
  defaultValue,
  label,
  customComponents,
  closeMenuOnSelect = true,
  menuPlacement = 'bottom',
  menuPortalTarget,
  canReset = false,
  useOnBlur = true,
  errorMessage = 'This field is required',
}) => {
  const textAreaRef = useRef(null);
  const isMounted = useIsMounted();
  const [localValues, setLocalValues] = useState(values || defaultValue);
  const [localOptions, setLocalOptions] = useState(options);
  const [fontSize, setFontSize] = useState(styleVars?.typography_fontSizeRoot ?? '14px');
  const [guessedHeight, setGuessedHeight] = useState('3em');

  const CustomClearText = () => customClearText;
  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        {label && <span className="styled-multiselect-control-label">{label}</span>}
        {children}
      </components.Control>
    );
  };
  const ClearIndicator = (props) => {
    const {
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    if (!customClearText) {
      return (
        <components.ClearIndicator {...props}>
          <components.CrossIcon />
        </components.ClearIndicator>
      );
    }
    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <div style={{ padding: '0px 5px', cursor: 'pointer' }}>
          <CustomClearText />
        </div>
      </div>
    );
  };

  const DropdownIndicator = ({ selectProps: { menuIsOpen } }) => {
    if (showDropdownIndicator && menuIsOpen) {
      return <FontAwesomeIcon icon="caret-up" style={{ marginLeft: '8px', marginRight: '8px' }} />;
    }
    if (showDropdownIndicator) {
      return (
        <FontAwesomeIcon icon="caret-down" style={{ marginLeft: '8px', marginRight: '8px' }} />
      );
    }
    return <></>;
  };

  // Local sort for select options
  // using either custom func if available, or alphabetical sort
  useEffect(() => {
    let output = options;

    if (options?.length && optionsSort) {
      output = [...options];

      if (optionsSortFunc && typeof optionsSortFunc === 'function') {
        output = optionsSortFunc(output);
      } else {
        output.sort((a, b) => {
          const _a = a?.label?.toLowerCase() ?? '';
          const _b = b?.label?.toLowerCase() ?? '';
          return _a.localeCompare(_b);
        });
      }
    }

    setLocalOptions(output);
  }, [JSON.stringify(options), optionsSort, optionsSortFunc]);

  useEffect(() => {
    if (!isMulti && canReset) {
      setLocalValues(values);
    }
  }, [values, localValues, isMulti, canReset]);

  useEffect(() => {
    if (isMulti && !defaultValue && localValues?.length && canReset) {
      setLocalValues(null);
    }
  }, [defaultValue, localValues, isMulti, canReset]);

  // TODO: why was this defined like that???
  // useEffect(() => {
  //   if (!isMulti && values) {
  //     setLocalValues(values);
  //   }
  // }, [values, isMulti]);

  useEffect(() => {
    if (values) {
      setLocalValues(values);
    }
  }, [values]);

  useEffect(() => {
    if (isMounted.current && isIE && isMulti && textAreaRef.current && fontSize) {
      const el = window.getComputedStyle(textAreaRef.current);
      setFontSize(parseInt(el.getPropertyValue('font-size'), 10), 10);
    }
  }, [fontSize, isMulti, isInvalid, isMounted]);

  useMemo(() => {
    const charWidthWithCanvas = (text, count) => {
      const context = document.createElement('canvas').getContext('2d');
      context.font = context.font.replace(/\d+px/, parseInt(fontSize, 10));

      // add width of x
      // 22 is width of 'x' char at font 14px and padding of 4 in x dir
      return Math.ceil(context.measureText(text).width + 22 * count);
    };

    // check for a custom ValueContainer, this is the component that shows the count
    // of selected instead of the default for react-select
    if (
      isIE &&
      isMulti &&
      !customComponents?.ValueContainer &&
      (defaultValue?.length || localValues?.length) &&
      textAreaRef.current &&
      fontSize
    ) {
      // this hack is to approx flex resize on multiselect item add, because for the life of me, I could not get this thing styled right.
      // 2.8 is to approximate lineHeight, 0.4 is the spacing for multi cards
      // 0.4 is the amount of the control that the cards actually take up
      // mmmxm is because I needed spacing for the x and can pass a ref because its a passed in component
      let text = [];
      let textWidth = null;
      if (localValues?.length) {
        text = localValues.map((v) => (v && v.label ? v.label : null)).join('mmmxm');
        textWidth = charWidthWithCanvas(text, localValues?.length);
      } else {
        text = defaultValue.map((v) => (v && v.label ? v.label : null)).join('mmmxm');
        textWidth = charWidthWithCanvas(text, defaultValue?.length);
      }

      setGuessedHeight(
        `${
          Math.ceil(
            (Number.isNaN(textWidth) ? 1 : textWidth) /
              (textAreaRef.current.clientWidth - textAreaRef.current.clientWidth * 0.3)
          ) *
            2.8 +
          0.4
        }em`
      );
    } else {
      setGuessedHeight('3em');
    }
  }, [fontSize, isMulti, localValues, defaultValue, customComponents]);

  const setBrowserControlStyle = () => {
    if (isIE && !isMulti) {
      return { height: '1.7em' };
    }
    if (isIE) {
      return {
        flexGrow: 0,
        flexDirection: 'auto',
        width: '100%',
        minHeight: '2em',
        flexBasis: '3em',
        flexShrink: 0,
        display: 'flex',
        height: isIE && isMulti && guessedHeight ? guessedHeight : 'unset',
      };
    }
    return null;
  };

  const setChangeValues = (v, { action }) => {
    setLocalValues(v);
    switch (action) {
      case 'clear':
      case 'remove-value':
        setOnChange(v);
        break;
      case 'select-option':
        if (closeMenuOnSelect) {
          setOnChange(v);
        }
        break;
      default:
        break;
    }
  };

  const browserControlStyle = setBrowserControlStyle();

  const browserStyle = {
    flexGrow: isIE ? 0 : 1,
  };

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getNewOptionData = (inputValue, optionLabel) => ({
    label: titleCase(inputValue),
    value: titleCase(inputValue),
  });

  const customSearch = (option, searchText) => {
    // show all if no search is present
    if (searchText === null || searchText === '') {
      // if there is an option limit to the display, limit the results
      if (optionLimit) {
        return localOptions.indexOf(option.data) <= optionLimit;
      }
      return true;
    }

    const strings = option.label.split(' ');
    if (strings.find((item) => item.toLowerCase().startsWith(searchText.toLowerCase()))) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div
        className={`styled-multiselect-container${isInvalid ? '-invalid' : ''}`}
        style={{ ...browserStyle, ...selectWrapperStyle }}
        ref={textAreaRef}
      >
        <Creatable
          id={id}
          className="w-100"
          options={localOptions}
          defaultValue={defaultValue}
          backspaceRemovesValue={backspaceRemovesValue}
          value={localValues}
          onChange={isMulti ? setChangeValues : setOnChange}
          onMenuClose={() => {
            if (isMulti && localValues !== defaultValue && !closeMenuOnSelect) {
              setOnChange(localValues);
            }
          }}
          onBlur={() => {
            if (!useOnBlur) return;
            if (isMulti && !closeMenuOnSelect) {
              setOnChange(localValues);
            }
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: '.5rem',
            colors: {
              ...theme.colors,
              primary: null,
              primary25: styleVars.colors_selectOrangeLight,
            },
          })}
          styles={{
            ...selectStyle,
            control: (styles) => ({
              ...styles,
              borderWidth: 0,
              lineHeight: '1.8rem',
              '&:hover': {
                backgroundColor: `${styleVars.colors_designGreyLight}`,
                borderColor: `${styleVars.colors_designGreyLight}`,
              },
              ...(selectStyle && selectStyle.control ? selectStyle.control(styles) : null),
              ...browserControlStyle,
            }),
            menu: (provided) => {
              return {
                ...provided,
                marginTop: '.3rem',
                textAlign: 'left',
                ...(selectStyle && selectStyle.menu ? selectStyle.menu(provided) : null),
              };
            },
            multiValue: (provided) => {
              return {
                ...provided,
                backgroundColor: `${styleVars.colors_designWhite}`,
                border: '1px solid #e0e0e0',
                ...(selectStyle && selectStyle.multiValue
                  ? selectStyle.multiValue(provided)
                  : null),
              };
            },
            option: (provided, state) => {
              return {
                ...provided,
                backgroundColor: state.isSelected ? styleVars.colors_selectOrangeLight : null,
                color: styleVars.colors_designBlack,
                '&:hover': {
                  backgroundColor: styleVars.colors_selectOrangeLight,
                },
                ...(selectStyle && selectStyle.option ? selectStyle.option(provided, state) : null),
              };
            },
          }}
          isSearchable={isSearchable}
          isMulti={isMulti}
          hideSelectedOptions={hideSelectedOptions}
          isClearable={isClearable}
          isDisabled={isDisabled}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          closeMenuOnSelect={closeMenuOnSelect}
          getOptionValue={getOptionValue}
          getNewOptionData={getNewOptionData}
          isValidNewOption={(inputValue) => allowUserAddCustomValue && inputValue.trim() !== ''}
          filterOption={customSearch}
          components={{
            // the custom control causes a multiselect to lose focus, so don't use the custom control if there is no label...
            [label ? 'Control' : null]: label ? Control : null,
            DropdownIndicator,
            ClearIndicator,
            IndicatorSeparator: () => null,
            ...customComponents,
          }}
          menuPlacement={menuPlacement}
          menuPortalTarget={menuPortalTarget}
        />
      </div>
      {isInvalid && (
        <div className="error-note">
          <sup>*</sup>
          {errorMessage}
        </div>
      )}
    </>
  );
};

StyledMultiselect.defaultProps = {
  id: null,
  options: [],
  optionLimit: null,
  optionsSort: false,
  optionsSortFunc: null,
  values: null,
  isDisabled: false,
  isInvalid: false,
  isMulti: true,
  isSearchable: false,
  placeholder: 'Select...',
  allowUserAddCustomValue: false,
  hideSelectedOptions: true,
  selectStyle: null,
  selectWrapperStyle: null,
  backspaceRemovesValue: true,
  showDropdownIndicator: true,
  getOptionLabel: (option) => `${option.label}`,
  getOptionValue: (option) => `${option.value}`,
  isClearable: false,
  customClearText: null,
  customComponents: null,
  closeMenuOnSelect: true,
  menuPlacement: 'bottom',
  canReset: false,
};

StyledMultiselect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  optionLimit: PropTypes.number,
  optionsSort: PropTypes.bool,
  optionsSortFunc: PropTypes.func,
  allowUserAddCustomValue: PropTypes.bool,
  values: PropTypes.any,
  setOnChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  customClearText: PropTypes.string,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  selectStyle: PropTypes.object,
  selectWrapperStyle: PropTypes.object,
  showDropdownIndicator: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  customComponents: PropTypes.object,
  backspaceRemovesValue: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  menuPlacement: PropTypes.string,
  canReset: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export { StyledMultiselect };
