import React, { useCallback, useState } from 'react';

import * as ApiCalls from 'api/ApiCalls';
import { FileUpload } from 'components/common/FileUpload/FileUpload';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { sheetRegex } from 'constants/FileConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { checkFileExtAndSize } from 'helpers/Utils';

const UploadBotFile = ({
  dataJobId,
  botStatusId,
  modalTitle = 'Upload File',
  saveToDataJob = true,
  onUploadedFiles,
  disabled,
  typeValidation = sheetRegex,
}) => {
  const isMounted = useIsMounted();

  const [isVisible, setIsVisible] = useState(false);

  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('0');
  const [isUploading, setIsUploading] = useState(false);

  // Upload file depending on primary flag and call respective handlers
  const handleUpload = useCallback(
    (data) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: saveToDataJob ? '/data-requests/attachments/' : '/bots/upload',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
        isFileFormData: true,
        onProgress: (progressEvent) => {
          const percentage = ApiCalls.getProgressPercent(progressEvent);
          if (percentage >= 0) setUploadProgress(percentage.toFixed(0));
        },
        onSuccess: (res) => {
          if (isMounted.current) {
            setIsUploadComplete(true);
            setIsUploading(false);
            setUploadProgress('0');
            setIsVisible(false);

            if (res?.data?.length) {
              // TODO: In future iterations, improve BE architecture to have a unified
              // file object with stable properties for blobs. Furthermore every file
              // uploaded to Azure should have a corresponding DB record for management.
              onUploadedFiles(
                res.data.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    original_name: item.original_name,
                    location: item.location,
                  };
                })
              );
            } else {
              toast.error('There was an error uploading the file. Please try again.');
            }
          }
        },
        onError: (err) => {
          console.error(err);
          if (isMounted.current) {
            toast.error('There was an error uploading the file. Please try again.');
            setIsUploading(false);
          }
        },
      });
    },
    [isMounted, setUploadProgress, onUploadedFiles, saveToDataJob]
  );

  // Prepare upload data depending on primary flag and trigger upload
  const onFileClicked = useCallback(
    (e) => {
      const typeCallback = (name) => toast.error(`File type of "${name}" not allowed.`);
      const sizeCallback = () =>
        toast.error(
          'The file you are trying to upload exceeds the 2GB attachment limit. Try putting in a shared location and adding a link instead.'
        );

      const file = checkFileExtAndSize(e, typeCallback, sizeCallback);
      if (!file) {
        return;
      }

      if (!typeValidation.test(file.name)) {
        typeCallback(file.name);
        return;
      }

      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', file);

      if (dataJobId) {
        formData.append('data_request_id', dataJobId);
        formData.append('is_pass_thru_file', 0);
        formData.append('transformation_required', 1);
      } else if (botStatusId) {
        formData.append('bot_status_id', botStatusId);
      } else {
        console.error('Invalid arguments');
        return;
      }

      handleUpload(formData);
    },
    [handleUpload, setIsUploading, dataJobId, botStatusId, typeValidation]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // filter to only selected amount of files
      if (acceptedFiles.length > 0) {
        onFileClicked(acceptedFiles);
      }
    },
    [onFileClicked]
  );

  return (
    <>
      <ModalPanel
        header={modalTitle}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        body={
          <FileUpload
            onDrop={onDrop}
            isFileLoading={isUploading}
            uploadProgress={uploadProgress}
            isUploadComplete={isUploadComplete}
            setIsUploadComplete={setIsUploadComplete}
            onSuccessMessage="File successfully added!"
          />
        }
      />
      <button
        type="button"
        className="btn btn-lg btn-secondary"
        onClick={() => setIsVisible(true)}
        disabled={disabled}
      >
        Upload New File
      </button>
    </>
  );
};

export { UploadBotFile };
