import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

import { CardsGrid } from 'components/common/Card/CardsGrid';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { DataJobs } from 'components/data-job/data-jobs/DataJobs';
import { DataJobsContext } from 'components/data-job/data-jobs/DataJobsContext';
import { DataLakehouseDashboardDistributor } from 'components/data-lakehouse/DataLakehouseDashboard/DataLakehouseDashboardDistributor';
import { DataLakehouseDashboardSupplier } from 'components/data-lakehouse/DataLakehouseDashboard/DataLakehouseDashboardSupplier';
import { RootHooks } from 'helpers/RootHooks';
import { UserUtils } from 'helpers/UserUtils';
import * as Utils from 'helpers/Utils';
import './ViewHomeScreen.scss';
import { toast } from 'helpers/ToastUtils';

import { DataLakehouseDashboardSelfService } from '../data-lakehouse/DataLakehouseDashboard/DataLakehouseDashboardSelfService';
import { SectionImgBotEngine } from '../data-lakehouse/DataLakehouseDashboard/private/SectionImgBotEngine';
import { ImgBotEngineStats } from '../img-bot-engine/ImgBotEngineStats';

const ViewHomeScreen = () => {
  const { activeUser } = RootHooks.useActiveUser();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const isSupplier = UserUtils.isRoleSupplier(activeUser);
  const isDistributor = UserUtils.isRoleClient(activeUser);
  const isSelfService = UserUtils.isRoleSelfService(activeUser);

  // Renders homepage title or welcome message if needed.
  const renderTitle = () => {
    let output = null;

    const userName = Utils.joinNameToStr(activeUser?.first_name, activeUser?.last_name);

    if (userName?.length) {
      output = `Welcome, ${userName}!`;
    }

    // Welcome message or nothing for supplier dashboard
    if (!output && !isSupplier && !isDistributor) {
      output = 'Dashboard';
    }

    return output;
  };

  const renderDashboard = () => {
    if (isSupplier) {
      return <DataLakehouseDashboardSupplier />;
    }

    if (isDistributor) {
      return <DataLakehouseDashboardDistributor />;
    }

    if (isSelfService) {
      return <DataLakehouseDashboardSelfService />;
    }

    return (
      <>
        <div className="home-screen-top-links">
          <CardsGrid
            data={[
              {
                onClick: () => {
                  if (!UserUtils.isReadOnly(activeUser)) {
                    history.push('/data-job-create');
                  } else {
                    toast.error("You don't have permission to perform this action");
                  }
                },
                data: {
                  icon: ['fas', 'file-edit'],
                  title: 'Create Data Jobs',
                  subtitle: 'Send data to, or request data from, one of your partners.',
                },
              },
              {
                onClick: () => history.push('/data-jobs'),
                data: {
                  icon: ['fas', 'search-plus'],
                  title: 'View Data Jobs',
                  subtitle: 'Work with your current data jobs or view their progress.',
                },
              },
              {
                onClick: () => history.push('/products'),
                data: {
                  icon: ['fas', 'box-open'],
                  title: 'View Products',
                  subtitle: 'View your products and data in the Backbone Data Library.',
                },
              },
            ]}
          />
        </div>
        <div className="home-screen-ids">
          <ImgBotEngineStats showDescription={false} title="Image Databot Suite Usage" />
        </div>
        <div className="home-screen-data-jobs">
          <DataJobsContext.Provider value={{ isLoading, setIsLoading }}>
            <div className="view-data-jobs">
              <div className="title">
                <h1>All Data Jobs</h1>
                {!UserUtils.isReadOnly(activeUser) && (
                  <div>
                    <Link to="/data-job-create" style={{ marginRight: 10 }}>
                      <Button variant="primary">
                        <span className="icon" style={{ marginRight: '0.5em' }}>
                          <FontAwesomeIcon icon={['far', 'plus']} />
                        </span>
                        <span className="label">Create Data Job</span>
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
              <DataJobs />
              {isLoading ? <LoadingView /> : null}
            </div>
          </DataJobsContext.Provider>
        </div>
      </>
    );
  };

  const dashboardContent = renderDashboard();

  const titleContent = renderTitle();

  return (
    <div className="content content-fluid view-home-screen">
      <Helmet bodyAttributes={{ 'data-page': 'view-home-screen' }}>
        <title>Dashboard</title>
      </Helmet>

      {!!titleContent && (
        <div className="title">
          <h3>{titleContent}</h3>
        </div>
      )}

      {dashboardContent}
    </div>
  );
};

export { ViewHomeScreen };
