import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import BackboneLogo from 'assets/img/backbone-logo.svg';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { RootHooks } from 'helpers/RootHooks';

const Login = ({ initialLocation, userLoginResponse, submitUserLogin, setShowForgotPassword }) => {
  const { featureFlags } = RootHooks.useFeatureFlags();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPlaintext, setShowPlaintext] = useState(false);
  const doUserLogin = () => {
    submitUserLogin({
      username,
      password,
      nextLocation: initialLocation || null,
    });
  };
  const getPasswordIcon = () => (showPlaintext ? 'eye-slash' : 'eye');

  const renderLoginStatus = () => {
    const { status } = userLoginResponse;
    const { errorMessage } = userLoginResponse;
    let statusEl = null;

    if (status) {
      switch (status) {
        case ActionStatusConstants.SUCCESS:
          statusEl = (
            <Alert className="my-3" variant="success">
              Login successful
            </Alert>
          );
          break;
        case ActionStatusConstants.FAILURE:
          statusEl = (
            <Alert className="my-3" variant="danger">
              {errorMessage || 'Could not log in. Please try again.'}
            </Alert>
          );
          break;
        default:
      }
    }

    return statusEl;
  };

  return (
    <>
      <h1 className="view-login-heading">Automate Your Product Data</h1>
      <h2 className="view-login-subheading">Join the Fastest Growing Data Network</h2>
      {/* TODO: Design for hovering/focusing fields and buttons, form controls/per-field validation */}
      <div className="view-login-form">
        <div className="view-login-group">
          <label className="view-login-label">Email</label>
          <input
            className="view-login-input"
            type="email"
            placeholder="hello@company.com"
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>
        <div className="view-login-group">
          <label className="view-login-label">Password</label>
          <div className="view-login-group-addon">
            <input
              className="view-login-input"
              type={showPlaintext ? 'text' : 'password'}
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <FontAwesomeIcon
              onClick={() => setShowPlaintext(!showPlaintext)}
              className="password-toggle-icon"
              icon={['far', getPasswordIcon()]}
            />
          </div>
        </div>
        <div className="view-login-forgot">
          <button className="link" onClick={() => setShowForgotPassword(true)} type="button">
            Forgot Password
          </button>
        </div>
        {/* TODO: Update design/markup for login status */}
        <div>{renderLoginStatus()}</div>
        <button
          disabled={userLoginResponse.status === ActionStatusConstants.ISBUSY}
          onClick={() => doUserLogin()}
          className="view-login-submit"
          type="submit"
        >
          {userLoginResponse.status === ActionStatusConstants.ISBUSY ? <LoadingView /> : 'Login'}
        </button>
      </div>
      <div className="view-login-addon">
        <p>Don&apos;t have an account?</p>
        {featureFlags.ENABLE_SIGN_UP_PAGE || featureFlags.ENABLE_SIGN_UP_REQUEST_PAGE ? (
          <Link
            className="view-login-addon-cta"
            to={
              featureFlags.ENABLE_SIGN_UP_PAGE
                ? '/sign-up'
                : featureFlags.ENABLE_SIGN_UP_REQUEST_PAGE
                ? '/sign-up-request'
                : null
            }
          >
            Get Started
          </Link>
        ) : (
          <a className="view-login-addon-cta" href="https://backbone.ai/contact/">
            Get Started
          </a>
        )}
      </div>
      <div className="view-login-branding">
        <a href="https://www.backbone.ai/">
          <img alt="BackboneAI logo" src={BackboneLogo} />
        </a>
      </div>
    </>
  );
};

export { Login };
