import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';

import * as FullStory from '@fullstory/browser';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SingletonHooksContainer } from 'react-singleton-hook';

import { Root } from 'Root';
import { store as reduxStore, browserHistory } from 'store/store';

import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';

// Output build version
console.debug(`Starting Backbone Web App, rev. ${process.env.REACT_APP_BUILD_REVISION}`);

// Expose redux store globally
export const store = reduxStore;

// Init Fullstory SDK
if (process.env.REACT_APP_FULLSTORY_TRACKING_ID) {
  console.debug('FullStory init');
  FullStory.init({
    orgId: process.env.REACT_APP_FULLSTORY_TRACKING_ID,
    debug: process.env.REACT_APP_FULLSTORY_TRACKING_DEBUG === 'true',
  });
}

const StrictModeSwitch = ({ children }) =>
  process.env.REACT_APP_GLOBAL_STRICT_MODE === 'true' ? (
    <React.StrictMode>{children}</React.StrictMode>
  ) : (
    children
  );

// Inject redux-wrapped Root node and singlton container
ReactDOM.render(
  // Global strict mode wrapper if enabled
  <StrictModeSwitch>
    {/* Wrap app with redux store provider */}
    <Provider store={store}>
      {/* Context provider for singleton hooks */}
      <SingletonHooksContainer />
      {/* Redux routing + history */}
      <ConnectedRouter history={browserHistory}>
        <QueryParamProvider
          adapter={ReactRouter5Adapter}
          options={{
            searchStringToObject: parse,
            objectToSearchString: stringify,
          }}
        >
          <Root />
        </QueryParamProvider>
      </ConnectedRouter>
    </Provider>
  </StrictModeSwitch>,
  document.getElementById('root')
);
