import "./LoadingSpinner.scss";

import PropTypes from "prop-types";
import React from "react";

const LoadingSpinner = ({ style = null, fast = false }) => {
  return (
    <span className={`loading-spinner${fast ? " fast" : ""}`} style={style}>
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </span>
  );
};

LoadingSpinner.propTypes = {
  style: PropTypes.object,
  fast: PropTypes.bool,
};

export { LoadingSpinner };
