import React from 'react';

import './Paginator.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import range from 'lodash/range';
import PropTypes from 'prop-types';

/**
 * Simple numberic pagination that looks like the bootstrap one without being tied to its functionality
 *
 * @param {number} page the current page
 * @param {function} setPage the function to set a different page state
 * @param {number} pageSize number of items per page
 * @param {number} totalItems total items to paginate
 * @return render
 */

const Paginator = ({ page, setPage, pageSize = 25, totalItems }) => {
  // specify the number of buttons to show on each side of the current page
  const visible = 4;

  // min page is 1, min visible is based on the current page
  const minPage = 1;
  let minVisiblePage = page - visible > minPage ? page - visible : minPage;

  // max page is based on the total items and page size
  const maxPage = Math.ceil(totalItems / pageSize);
  let maxVisiblePage = maxPage > page + visible + 1 ? page + visible + 1 : maxPage;

  // check how many pages cannot be displayed on either side based on the location of page
  // if you are on page 2, then 3 pages can't be displayed on the left
  const maxOverflow = page + visible > maxVisiblePage ? page + visible - maxVisiblePage : 0;
  const minOverflow = page - visible < minPage ? visible + minPage - page : 0;

  // modify the other end based on overflow, but cap it at the actual min or max
  if (maxOverflow > 0) {
    if (minVisiblePage - maxOverflow >= minPage) {
      minVisiblePage -= maxOverflow;
    } else {
      minVisiblePage = minPage;
    }
  }

  if (minOverflow > 0) {
    if (maxVisiblePage + minOverflow <= maxPage) {
      maxVisiblePage += minOverflow;
    } else {
      maxVisiblePage = maxPage;
    }
  }

  const pageArr = range(minVisiblePage, maxVisiblePage + 1);

  return (
    <div className="paginator">
      {maxPage > 1 ? (
        <>
          <button
            type="button"
            className="btn btn-inherit"
            onClick={() => setPage(1)}
            disabled={page === 1 || minVisiblePage === minPage}
          >
            <FontAwesomeIcon icon={['far', 'chevron-double-left']} />
          </button>
          <button
            type="button"
            className="btn btn-inherit"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            <FontAwesomeIcon icon={['far', 'chevron-left']} />
          </button>
        </>
      ) : null}
      {pageArr.map((item) => {
        if (item === page) {
          return <div key={page}>{page}</div>;
        }
        return (
          <button
            type="button"
            className="btn btn-inherit"
            onClick={() => setPage(item)}
            key={item}
          >
            {item}
          </button>
        );
      })}
      {maxPage > 1 ? (
        <>
          <button
            type="button"
            className="btn btn-inherit"
            onClick={() => setPage(page + 1)}
            disabled={page === maxPage}
          >
            <FontAwesomeIcon icon={['far', 'chevron-right']} />
          </button>
          <button
            type="button"
            className="btn btn-inherit"
            onClick={() => setPage(maxPage)}
            disabled={maxVisiblePage === maxPage}
          >
            <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
          </button>
        </>
      ) : null}
    </div>
  );
};

Paginator.defaultProps = {
  pageSize: 25,
};

Paginator.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  totalItems: PropTypes.number.isRequired,
};

export { Paginator };
