import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './ValueBlock.scss';

/**
 * Simple label/value/tooltip block for displaying data items
 *
 * @param {element} as Render as
 * @param {string} title Block title
 * @param {string} subtitle Block subtitle/description
 * @param {any} value Value - a string is rendered as-is, numeric values are formatted
 * @param {number} decimalPlaces Decimal places to show on the value
 * @param {string} tooltip Tooltip content
 * @param {array} additionalData Array of additional data label-value pairs
 * @param {string} className Classname
 * @param {boolean} isBorderless Draw value block without frame
 * @return render
 */
const ValueBlock = ({
  as,
  title,
  subtitle,
  value,
  decimalPlaces,
  tooltip,
  additionalData,
  className,
  isBorderless,
}) => {
  // Render formatted numeric value
  const renderValue = (v, formatFractions) => {
    let output = 'N/A';

    if (![null, undefined, ''].includes(v)) {
      const fOpts = {};

      if (formatFractions && Number.isFinite(decimalPlaces)) {
        fOpts.minimumFractionDigits = decimalPlaces;
        fOpts.maximumFractionDigits = decimalPlaces;
      }

      output = Number.isFinite(v) ? v.toLocaleString('en-US', fOpts) : v;
    }

    return output;
  };

  // Render the list of additional block data
  const renderAdditionalData = () => {
    let output = null;

    if (Array.isArray(additionalData) && additionalData.length) {
      const nonEmptyItems = additionalData.filter(
        (item) => ![null, undefined, ''].includes(item.value)
      );

      if (nonEmptyItems?.length) {
        output = (
          <div className="additional-data">
            {nonEmptyItems.map((item, index) => (
              <div className="data-item" key={index}>
                <span className="value">{renderValue(item.value, false)}</span>{' '}
                <span className="label">{item.label}</span>
              </div>
            ))}
          </div>
        );
      }
    }
    return output;
  };

  return (
    <as.type className={classNames('bai-value-block', className, { borderless: isBorderless })}>
      <div className="title">
        <div className="label">{title}</div>
        {tooltip && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                <p>{tooltip}</p>
              </Tooltip>
            }
          >
            <div className="title-tooltip">
              <span className="icon">
                <FontAwesomeIcon className="icon-circle" icon={['fas', 'info']} />
              </span>
            </div>
          </OverlayTrigger>
        )}
      </div>
      <div className="value">{renderValue(value, true)}</div>
      {subtitle && <div className="subtitle">{subtitle}</div>}
      {renderAdditionalData()}
    </as.type>
  );
};

ValueBlock.defaultProps = {
  as: <div />,
  subtitle: null,
  value: null,
  tooltip: null,
  additionalData: null,
  className: null,
  isBorderless: false,
};

ValueBlock.propTypes = {
  as: PropTypes.element,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  value: PropTypes.any,
  tooltip: PropTypes.string,
  additionalData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
    })
  ),
  className: PropTypes.string,
  isBorderless: PropTypes.bool,
};

export { ValueBlock };
