// Use this helper to close a modal, menu, popup, etc
// when you click outside of it

import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

// Use this helper to close a modal, menu, popup, etc
// when you click outside of it

const useOutsideClickWithScroll = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('mousedown', handleClick, true);
    document.addEventListener('scroll', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('scroll', handleClick);
    };
  });
};

export { useOutsideClick, useOutsideClickWithScroll };

// EXAMPLE:
// import { useOutsideClick } from "helpers/useOutsideClick";

// function MyComponent() {
//   const ref = useRef();

//   useOutsideClick(ref, () => {
//     alert('You clicked outside')
//   });

//   return (
//     <div className="App">
//       <div ref={ref}}>
//         <h4>This is a menu</h4>
//         <p>This is another content</p>
//       </div>
//       <div>
//         This is a content outside the menu
//       </div>
//     </div>
//   );
// }
