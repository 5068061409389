import React, { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { StyledMultiselect } from '../../common/StyledMultiselect/StyledMultiselect';
import { ImgBotEngineConfigTableDef } from './ImgBotEngineConfigTableDef';

import './ImgbotEngineConfigTable.scss';

const ImgBotEngineConfigTable = ({
  configData,
  onSaveData,
  onDelete,
  onSetAsDefault,
  tableState,
  onTableStateChanged,
  isLoading,
}) => {
  const [decoratedConfigData, setDecoratedConfigData] = useState(null);
  const [queryStage, setQueryStage] = useState(null);
  const [queryConfigurationType, setQueryConfigurationType] = useState(null);

  const IDS_FILTER_CONFIG = {
    STAGE: [
      { label: 'Assessment', value: 'ids-initial-assessment' },
      { label: 'Enhancement', value: 'ids-enhancement' },
    ],

    CONFIGURATION_TYPE: [
      { label: 'User', value: 'user' },
      { label: 'Anonymous', value: 'anonymous' },
    ],
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      onTableStateChanged(page, sizePerPage);
    }
  };

  // const loadData = useCallback(() => {
  useEffect(() => {
    if (configData?.length > 0) {
      const filtered = configData.filter((data) => {
        const stageMatch = !queryStage || data?.bot?.slug === queryStage.value;
        const typeMatch =
          !queryConfigurationType || data?.configuration_type === queryConfigurationType.value;
        return stageMatch && typeMatch;
      });

      setDecoratedConfigData(
        filtered.length > 0
          ? filtered.map((item) => {
              return {
                id: item.id,
                item,
                onSaveData,
                onDelete,
                onSetAsDefault,
                isLoading,
              };
            })
          : null
      );
    }
  }, [configData, queryConfigurationType?.value, queryStage?.value]);

  useEffect(() => {
    if (!queryStage?.value && !queryConfigurationType?.value) {
      setDecoratedConfigData(
        configData?.length
          ? configData.map((item) => {
              return {
                id: item.id,
                item,
                onSaveData,
                onDelete,
                onSetAsDefault,
                isLoading,
              };
            })
          : null
      );
    }
  }, [configData, isLoading, onSaveData, onDelete, onSetAsDefault]);

  return (
    <div className="img-bot-engine-config-table">
      <div style={{ marginBottom: '1.7em', marginTop: '1.7em' }}>
        <div className="row align-items-end">
          <div className="col">
            <div className="note">Filter by Type</div>
            <StyledMultiselect
              isSearchable
              defaultValue={[]}
              values={queryConfigurationType}
              options={IDS_FILTER_CONFIG.CONFIGURATION_TYPE}
              setOnChange={setQueryConfigurationType}
              getOptionValue={(option) => option.value}
              isClearable
              isMulti={false}
              canReset
            />
          </div>

          <div className="col">
            <div className="note">Filter by Stage</div>
            <StyledMultiselect
              isSearchable
              defaultValue={[]}
              values={queryStage}
              options={IDS_FILTER_CONFIG.STAGE}
              setOnChange={setQueryStage}
              getOptionValue={(option) => option.value}
              isClearable
              isMulti={false}
              canReset
            />
          </div>
        </div>
      </div>
      {configData?.length ? (
        <BootstrapTable
          remote
          bordered={false}
          bootstrap4
          keyField="id"
          // data={decoratedConfigData?.length ? decoratedConfigData : []}
          data={decoratedConfigData || []}
          columns={ImgBotEngineConfigTableDef.columns}
          defaultSorted={[{ dataField: 'id', order: 'desc' }]}
          onTableChange={(type, newState) => onTableChange(type, newState)}
          pagination={paginationFactory({
            totalSize: tableState.total,
            page: tableState.page,
            sizePerPageList: [],
            sizePerPage: tableState.pageSize,
            hidePageListOnlyOnePage: true,
          })}
        />
      ) : (
        <div className="empty-notice">
          <Alert variant="primary">No IDS Configurations found.</Alert>
        </div>
      )}
    </div>
  );
};

export { ImgBotEngineConfigTable };
