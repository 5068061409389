import './FlashAssessmentOverview.scss';

import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

import { FULL_DATE } from 'constants/DateTimeConstants';

const FlashAssessmentOverview = ({ data }) => {
  const history = useHistory();
  const goToDataRequest = () => {
    history.push(`/data-request/${data.data_request.id}`);
  };

  const getScoreStatus = (score, isLabel = false) => {
    let output = null;

    if (typeof score === 'number') {
      if (score >= 95) {
        output = isLabel ? 'Good' : 'good';
      } else if (score >= 75) {
        output = isLabel ? 'Acceptable' : 'acceptable';
      } else {
        output = isLabel ? 'Poor' : 'poor';
      }
    }

    return output;
  };
  const scoreValue = data.aggregate_score;
  const scoreClass = getScoreStatus(data.aggregate_score);
  const scoreLabel = getScoreStatus(data.aggregate_score, true);

  return (
    <section className="section-overview">
      <div className="title">Overview</div>
      <div className="content">
        <Row>
          <Col sm={7}>
            <div className="summary-data-1">
              <div className="kv-pair job-name">
                <div className="label">Data Job Name</div>
                <div onClick={goToDataRequest} className="value cursor-pointer link-color">
                  {data.data_request.name}
                </div>
              </div>
              <div className="kv-pair publish-date">
                <div className="label">Publish Date</div>
                <div className="value">
                  {data.published_at ? (
                    <Moment format={FULL_DATE} date={data.published_at} />
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
            </div>
            <div className="summary-data-2">
              <div className="kv-pair total-files">
                <div className="label">Total # of Files</div>
                <div className="value">
                  {data.total_files >= 0 ? data.total_files.toLocaleString('en-US') : 'N/A'}
                </div>
              </div>
              <div className="kv-pair total-skus">
                <div className="label">Total SKUs</div>
                <div className="value">
                  {data.total_skus >= 0 ? data.total_skus.toLocaleString('en-US') : 'N/A'}
                </div>
              </div>
              <div className="kv-pair files-included">
                <div className="label">Files Included</div>
                <div className="value">
                  {data.processed_files >= 0 ? data.processed_files.toLocaleString('en-US') : 'N/A'}
                </div>
              </div>
              <div className="kv-pair mfr-id">
                <div className="label">Manufacturer ID</div>
                <div className="value">
                  {data.manufacturer_distributor_id >= 0 ? data.manufacturer_distributor_id : 'N/A'}
                </div>
              </div>
              <div className="kv-pair data-sources">
                <div className="label">Data Sources</div>
                <div className="value" key="sources">
                  {data.data_source.length > 0
                    ? data.data_source.map((_item, index) => (
                        <div key={`source-${index}`}>{_item.name}</div>
                      ))
                    : 'N/A'}
                </div>
              </div>
            </div>
          </Col>
          <Col sm={5}>
            <div className={`aggregate-score${scoreClass ? ` ${scoreClass}` : ''}`}>
              <div className="aggregate-score-value">
                <CircularProgressbarWithChildren value={scoreValue}>
                  <div className={`circular-progressbar-label${!scoreClass ? ` empty` : ''}`}>
                    {scoreClass ? (
                      <>
                        <div className="value">
                          {scoreValue}
                          <span className="unit">%</span>
                        </div>
                        <div className="label">Aggregate Score</div>
                        <div className="status-label-wrap">
                          <div className="status-label">{scoreLabel || 'N/A'}</div>
                        </div>
                      </>
                    ) : (
                      <span className="empty-label">N/A</span>
                    )}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default FlashAssessmentOverview;
