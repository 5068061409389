/* eslint-disable import/no-default-export */
import _cloneDeep from 'lodash/cloneDeep';

export default (args) => {
  const res = {
    data: {},
  };

  if (typeof args.onSuccess === 'function') {
    setTimeout(() => args.onSuccess(_cloneDeep(res)), 500);
  }
};
