import React from 'react';

import { Transforms } from 'slate';
import { useSelected, useFocused } from 'slate-react';

// function to build an @ sign mention
export const insertMention = (editor, tag, taggableUsers, taggedUsers, setTaggedUsers) => {
  // do nothing is the user is not within the taggable users list
  if (taggableUsers.filter((user) => user.id === tag.id).length === 0) {
    return;
  }

  const mention = {
    type: 'mention',
    tag,
    children: [{ text: `${tag.display}` }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
  if (taggedUsers.filter((u) => u.id === tag.id).length > 0) {
    return;
  }
  setTaggedUsers((taggedUsers) => [
    ...taggedUsers,
    taggableUsers.filter((u) => u.id === tag.id)[0],
  ]);
};

export const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.tag.id}`}
      style={{
        padding: '3px 3px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: '#eee',
        fontSize: '0.9em',
        fontWeight: '700',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        userSelect: 'none',
      }}
    >
      @{element.tag.display}
      {children}
    </span>
  );
};
