import { call, put, takeLeading } from 'redux-saga/effects';

import * as ApiCalls from 'api/ApiCalls';
import ActionTypeConstants from 'constants/ActionTypeConstants';
import * as ProductUtils from 'helpers/ProductUtils';

/**
 * Retrieves a list of filtered products for paginated display.
 *
 * @param { payload } Object containing offset, limit, sortField, sortOrder and filter object
 */
function* getAllProductsSaga({ payload }) {
  console.debug('getAllProductsSaga', payload);

  const apiResponse = yield call(ApiCalls.legacySyncDoCall, {
    urlPath: '/products/matches',
    method: ApiCalls.HTTP_METHODS.POST,
    data: payload,
  });

  // TODO Remove legacy support, transform logic left should be moved to backend
  // Support pre-release product model until it's rewritten properly in backend.
  if (apiResponse?.data?.products?.length) {
    apiResponse.data.products = apiResponse.data.products.map((item) =>
      ProductUtils.transformLegacyProduct(item)
    );
    apiResponse.data.sortField = 'product';
    apiResponse.data.sortOrder = 'asc';
  }

  if (apiResponse?.data) {
    yield put({ type: ActionTypeConstants.GET_ALL_PRODUCTS_SUCCESS, payload: apiResponse.data });
  } else {
    yield put({ type: ActionTypeConstants.GET_ALL_PRODUCTS_FAILURE });
  }
}

export default function* () {
  yield takeLeading(ActionTypeConstants.GET_ALL_PRODUCTS, getAllProductsSaga);
}
