import React from 'react';

import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';

import { BlockExportStatusTableTableDef } from './BlockExportStatusTableTableDef';

/**
 * Export status table to show state of existing exports
 *
 * @param {array} exportsStatusData List of export status objects
 * @return render
 */
const BlockExportStatusTable = React.memo(({ exportsStatusData }) => {
  return (
    <section className="block block-export-status-table">
      <div className="bootstrap-table-wrap">
        <BootstrapTable
          bordered={false}
          bootstrap4
          keyField="id"
          data={exportsStatusData || []}
          columns={BlockExportStatusTableTableDef.columns}
          defaultSorted={[{ dataField: 'created_at', order: 'desc' }]}
        />
      </div>
    </section>
  );
});

BlockExportStatusTable.defaultProps = {
  exportsStatusData: null,
};

BlockExportStatusTable.propTypes = {
  exportsStatusData: PropTypes.array,
};

export { BlockExportStatusTable };
