import React from 'react';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotAwaitingApproval = () => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="awaiting-approval"
      middle={
        <div className="description">
          This databot is awaiting approval. Please check back later.
        </div>
      }
    />
  );
};

export { DatabotAwaitingApproval };
