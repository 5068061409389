import React from 'react';

import axios from 'axios';
import _get from 'lodash/get';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import { MIN_DATE } from 'constants/DateTimeConstants';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { toast } from 'helpers/ToastUtils';
import { getDataRequestDeliveryFormats } from 'store/actions/Actions';

// TODO: Huge component, to be split to managable pieces

class ViewFlashAssessmentAttributes extends React.Component {
  state = {
    data: null,
    generalAttributes: [],
    additionalAttributes: [],
    stateToReset: {},
    isLoading: false,
    validationDetails: null,
    unsavedChanges: false,
    showUnsavedChangesModal: false,
    attachmentName: null,
  };

  async componentDidMount() {
    if (this.props.match.params.attachmentId) {
      await this.getAttachmentName();
    }
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      await this.getFlashAssessmentAttributes();
      this.props.getDataRequestDeliveryFormats();
    }
  }

  getAttachmentName = async () => {
    this.setState({ isLoading: true });
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/attachments/${this.props.match.params.attachmentId}`,
      onSuccess: (response) => {
        this.setState({ attachmentName: response.data.name });
      },
    });
  };

  // Unsaved Changes Modal handler
  handleShowUnsavedChangesModal = () =>
    this.setState({ showUnsavedChangesModal: !this.state.showUnsavedChangesModal });

  getFlashAssessmentAttributes = async () => {
    this.setState({ isLoading: true });
    const authToken = getAuthToken();
    let attributes = [];
    const data = {
      data_request_id: this.props.match.params.id,
      data_request_attachment_id: this.props.match.params.attachmentId,
    };
    const attrs_types = {
      general: 1,
      additional: 2,
    };

    const fetchUrl = this.props.match.params.attachmentId
      ? `attachment/${this.props.match.params.attachmentId}`
      : `job-id/${this.props.match.params.id}`;
    try {
      // TODO switch to ApiCalls.doCall
      const response = await axios({
        method: 'GET',
        url: `${ApiCalls.BASE_API_URL}/flash-assessments/${fetchUrl}`,
        headers: {
          Authorization: `Token ${authToken}`,
          ContentType: 'application/json',
        },
      });

      Object.assign(data, {
        manufacturer_id: _get(response.data, 'manufacturer.id') || null,
        manufacturerName: _get(response.data, 'manufacturer.name') || null,
        dataRequestName: _get(response.data, 'data_request.name') || null,
        ...response.data,
      });
      attributes = response.data.attributes;
    } catch (e) {
      // Flash Assessment not found, get job and default attributes
      // TODO: This should be fixed, exception handling should not be used for business logic.
      if (e.response.status === 404) {
        // TODO switch to ApiCalls.doCall
        const jobResponse = await axios({
          method: 'GET',
          url: `${ApiCalls.BASE_API_URL}/data-requests/${this.props.match.params.id}`,
          headers: {
            Authorization: `Token ${authToken}`,
            ContentType: 'application/json',
          },
        });

        Object.assign(data, {
          total_files: 0,
          total_skus: 1,
          processed_files: 0,
          manufacturer_id: _get(jobResponse.data, 'manufacturer.id') || null,
          manufacturerName: _get(jobResponse.data, 'manufacturer.name') || null,
          dataRequestName: _get(jobResponse.data, 'name') || null,
          data_request: jobResponse.data,
          data_source: _get(jobResponse.data, 'expected_delivery_format') || [],
          data_source_ids: (_get(jobResponse.data, 'expected_delivery_format') || []).map(
            (deliveryFormat) => deliveryFormat.id
          ),
        });
        if (!this.props.match.params.attachmentId) {
          Object.assign(data, {
            id: _get(jobResponse.data, 'flash_assessment.id') || null,
          });
        }
      } else {
        console.error(e);
        this.setState({ isLoading: false });
        return;
      }
    }

    if (!attributes.length) {
      // TODO switch to ApiCalls.doCall
      const response = await axios({
        method: 'GET',
        url: `${ApiCalls.BASE_API_URL}/flash-assessments/default-attributes`,
        headers: {
          Authorization: `Token ${authToken}`,
          ContentType: 'application/json',
        },
      });
      attributes = attributes = response.data;
    }

    const genAttributes = attributes
      ? attributes.filter((item) => item.attribute.type === attrs_types.general)
      : [];
    const additionalAttributes = attributes
      ? attributes.filter((item) => item.attribute.type === attrs_types.additional)
      : [];

    if (!data.id) {
      (genAttributes || []).forEach((attribute) => {
        if (attribute.attribute.criteria) {
          attribute.criteria = attribute.attribute.criteria;
        }
      });
      (additionalAttributes || []).forEach((attribute) => {
        if (attribute.attribute.criteria) {
          attribute.criteria = attribute.attribute.criteria;
        }
      });
    }

    if (data && !data.data_source) {
      this.decorateDataAttachmentType(data);
    }

    this.setState({
      data,
      isLoading: false,
      generalAttributes: genAttributes,
      additionalAttributes,
      stateToReset: {
        data: JSON.parse(JSON.stringify(data)),
        generalAttributes: JSON.parse(JSON.stringify(genAttributes)),
        additionalAttributes: JSON.parse(JSON.stringify(additionalAttributes)),
      },
    });
  };

  decorateDataAttachmentType(data) {
    // TODO: Workaround - attachments (can) come as a nested array for unknown reason.

    const attachmentNamesArray = _get(data, 'data_request.attachment_names');
    let attachmentType = null;

    const extractType = (filename) => {
      let output = null;

      if (typeof filename === 'string' && filename.length) {
        const currentType = `${filename.split('.').pop()}`.toLowerCase();

        if (['csv', 'xls', 'xlsx', 'json'].includes(currentType)) {
          output = currentType;
        }
      }

      return output;
    };

    if (
      attachmentNamesArray &&
      Array.isArray(attachmentNamesArray) &&
      attachmentNamesArray.length
    ) {
      iLoop: for (let i = 0; i < attachmentNamesArray.length; i++) {
        if (
          attachmentNamesArray[i] &&
          Array.isArray(attachmentNamesArray[i]) &&
          attachmentNamesArray[i].length
        ) {
          for (let k = 0; k < attachmentNamesArray[i].length; k++) {
            const currentType = extractType(attachmentNamesArray[i][k]);
            if (currentType) {
              attachmentType = currentType;
              console.debug(
                'Found data source type(nested)',
                attachmentType,
                attachmentNamesArray,
                i,
                k
              );
              break iLoop;
            }
          }
        } else {
          const currentType = extractType(attachmentNamesArray[i]);
          if (currentType) {
            attachmentType = currentType;
            console.debug('Found data source type', attachmentType, attachmentNamesArray, i);
            break;
          }
        }
      }
    }

    if (attachmentType) {
      switch (attachmentType) {
        case 'csv':
          data.data_source = 'csv';
          break;
        case 'xls':
          data.data_source = 'xls';
          break;
        case 'xlsx':
          data.data_source = 'xls';
          break;
        case 'json':
          data.data_source = 'json';
          break;
        default:
      }
    }
  }

  handleFaValueChange(key, value) {
    const { data } = this.state;
    data[key] = value;
    this.setState({
      data,
      unsavedChanges: true,
    });
  }

  handleValueChange(attrType, key, index, value) {
    const attrs =
      attrType === 'general' ? this.state.generalAttributes : this.state.additionalAttributes;
    if (key === 'name') {
      attrs[index].attribute[key] = value;
    } else if (key === 'suggestion') {
      if (!attrs[index].suggestion) {
        attrs[index].suggestion = {};
      }
      attrs[index].suggestion[key] = value;
    } else {
      attrs[index][key] = value;
    }

    if (attrType === 'general') {
      this.setState({ generalAttributes: attrs });
    } else {
      this.setState({ additionalAttributes: attrs });
    }
    this.setState({ unsavedChanges: true });
  }

  addDraftAttribute(attrType) {
    const attr = {
      flash_assessment_id: this.state.data.id,
      label: 'Custom Attribute Label',
      criteria: '',
      value: '',
      attribute: {
        name: 'Custom Attribute Name',
        type: attrType === 'general' ? 1 : 2,
      },
    };
    if (attrType === 'general') {
      const genAttrs = this.state.generalAttributes;
      genAttrs.push(attr);
      this.setState({ generalAttributes: genAttrs });
    } else {
      const addAttrs = this.state.additionalAttributes;
      addAttrs.push(attr);
      this.setState({ additionalAttributes: addAttrs });
    }
    this.setState({ unsavedChanges: true });
  }

  saveAttributes = async () => {
    this.setState({ isLoading: true });
    const authToken = getAuthToken();
    let data = {};
    try {
      // TODO switch to ApiCalls.doCall
      const faResponse = await axios({
        method: this.state.data.id ? 'PUT' : 'POST',
        url: `${ApiCalls.BASE_API_URL}/flash-assessments/${this.state.data.id || ''}`,
        data: this.state.data,
        headers: {
          Authorization: `Token ${authToken}`,
          ContentType: 'application/json',
        },
      });
      data = Object.assign(data, {
        data_request_id: this.props.match.params.id,
        data_request_attachment_id: this.props.match.params.attachmentId,
        manufacturer_id: _get(faResponse.data, 'manufacturer.id') || null,
        manufacturerName: _get(faResponse.data, 'manufacturer.name') || null,
        dataRequestName: _get(faResponse.data, 'data_request.name') || null,
        ...faResponse.data,
      });
      this.setState({
        data,
        validationDetails: null,
      });
    } catch (e) {
      const validationResponse = _get(e, 'response.data') || null;
      const msg =
        validationResponse && validationResponse.message ? validationResponse.message : null;
      const details =
        validationResponse.details && Object.keys(validationResponse.details).length > 0
          ? validationResponse.details
          : null;

      toast.error(`Unable to save flash assessment${msg ? `: ${msg}` : '.'}`);
      if (details) {
        this.setState({
          validationDetails: details,
        });
      } else {
        this.setState({
          validationDetails: null,
        });
      }
      console.error(e);
      this.setState({ isLoading: false });
      return;
    }
    // Update attributes of flash assessment
    try {
      // TODO switch to ApiCalls.doCall
      await axios({
        method: 'POST',
        url: `${ApiCalls.BASE_API_URL}/flash-assessments/${data.id}/attributes/`,
        data: [].concat(this.state.generalAttributes, this.state.additionalAttributes),
        headers: {
          Authorization: `Token ${authToken}`,
          ContentType: 'application/json',
        },
      });
      this.setState({
        stateToReset: {
          data: JSON.parse(JSON.stringify(data)),
          generalAttributes: JSON.parse(JSON.stringify(this.state.generalAttributes)),
          additionalAttributes: JSON.parse(JSON.stringify(this.state.additionalAttributes)),
        },
      });
    } catch (e) {
      toast.error(`Unable to save attributes: ${e.response.data.message}`);
      console.error(e);
    }
    this.getFlashAssessmentAttributes();
    this.setState({ unsavedChanges: false });
    this.setState({ isLoading: false });
  };

  manageFAPublication = async () => {
    this.setState({ isLoading: true });
    try {
      const authToken = getAuthToken();
      await this.saveAttributes();
      // TODO switch to ApiCalls.doCall
      await axios({
        method: 'PUT',
        url: `${ApiCalls.BASE_API_URL}/flash-assessments/${this.state.data.id}/publish`,
        headers: {
          Authorization: `Token ${authToken}`,
          ContentType: 'application/json',
        },
      });

      // If publish FA redirect to FA report page
      if (this.state.data.status !== 'published') {
        this.props.history.push(`/flash-assessment/${this.state.data.id}`);
      }
      this.getFlashAssessmentAttributes();
    } catch (e) {
      console.error(e);
    }
    this.setState({ unsavedChanges: false });
    this.setState({ isLoading: false });
  };

  handleTotalSkuFieldValue = (e) => {
    if (e.target.value && e.target.value > 0) {
      this.handleFaValueChange('total_skus', e.target.value);
    } else if (e.target.value && e.target.value < 1) {
      this.handleFaValueChange('total_skus', 1);
    } else {
      this.handleFaValueChange('total_skus', e.target.value);
    }
  };

  onTotalSkuBlur = (e) => {
    if (!e.target.value || e.target.value < 1) {
      this.handleFaValueChange('total_skus', 1);
    }
  };

  goToFlashAssessmentReport = () => {
    this.props.history.push(`/flash-assessment/${this.state.data.id}`);
  };

  isInvalidField = (key) => {
    let output = false;

    if (this.state.validationDetails) {
      if (key in this.state.validationDetails) {
        output = true;
      }
    }

    return output;
  };

  renderFieldErrors = (key) => {
    let output = null;

    if (
      this.state.validationDetails &&
      this.state.validationDetails[key] &&
      this.state.validationDetails[key].length
    ) {
      output = this.state.validationDetails[key].map((item, i) => {
        return <li key={i}>{item}</li>;
      });
    }

    if (output) {
      return <ul className="error-list">{output}</ul>;
    }
    return null;
  };

  onDeliveryFormatsChanged = (param1) => {
    const selectedDeliveryFormatsIds = (param1 || []).map((deliveryFormat) => {
      return deliveryFormat.value;
    });

    const selectedDeliveryFormats = (param1 || []).map((deliveryFormat) => {
      return deliveryFormat.obj;
    });

    this.handleFaValueChange('data_source', selectedDeliveryFormats);
    this.handleFaValueChange('data_source_ids', selectedDeliveryFormatsIds);
  };

  render() {
    if (!this.state.data) {
      return null;
    }

    const showViewReportBtn = this.state.data && this.state.data.status === 'published';
    const deliveryFormats = _get(this.props, 'getDataRequestDeliveryFormatsResponse.payload') || [];
    const selectedDeliveryFormats = this.state.data.data_source || [];

    return (
      <>
        <div className="content content-fluid view-flash-assessment">
          <Helmet bodyAttributes={{ 'data-page': 'view-flash-assessment' }}>
            <title>Flash Assessment: {this.state.data.manufacturerName || 'N/A'}</title>
          </Helmet>

          {this.state.data && !this.state.isLoading ? (
            <>
              <h1>Admin Flash Assessment: </h1>
              <h3>Manufacturer Name: {this.state.data.manufacturerName || 'N/A'}</h3>
              <h3>
                Data Job Name:{' '}
                <Link to={`/data-request/${this.props.match.params.id}`}>
                  {this.state.data.dataRequestName || 'N/A'}
                </Link>
              </h3>
              {this.state.attachmentName && <h3>Attachment Name: {this.state.attachmentName}</h3>}
              <Container fluid className="flash-assessment-grid pl-0 pr-0 mt-4">
                <Row>
                  <Col sm={12}>
                    <section className="section-overview">
                      <div className="title">Summary:</div>
                      <div className="content">
                        <Row className="ml-4">
                          <Col sm={2}>
                            <div className="label">Total # of Files:</div>
                          </Col>
                          <Col sm={2}>
                            <Form.Control
                              type="text"
                              size="sm"
                              onChange={(e) =>
                                this.handleFaValueChange('total_files', e.target.value)
                              }
                              value={this.state.data.total_files}
                              maxLength="50"
                              isInvalid={this.isInvalidField('total_files')}
                            />
                            {this.renderFieldErrors('total_files')}
                          </Col>
                        </Row>
                        <Row className="ml-4">
                          <Col sm={2}>
                            <div className="label">Total # of SKUs:</div>
                          </Col>
                          <Col sm={2}>
                            <Form.Control
                              type="text"
                              size="sm"
                              onChange={(e) => this.handleTotalSkuFieldValue(e)}
                              onBlur={(e) => this.onTotalSkuBlur(e)}
                              value={this.state.data.total_skus}
                              maxLength="50"
                              isInvalid={this.isInvalidField('total_skus')}
                            />
                            {this.renderFieldErrors('total_skus')}
                          </Col>
                        </Row>
                        <Row className="ml-4">
                          <Col sm={2}>
                            <div className="label"># of File(s) Included in FA:</div>
                          </Col>
                          <Col sm={2}>
                            <Form.Control
                              type="text"
                              size="sm"
                              onChange={(e) =>
                                this.handleFaValueChange('processed_files', e.target.value)
                              }
                              value={this.state.data.processed_files}
                              maxLength="50"
                              isInvalid={this.isInvalidField('processed_files')}
                            />
                            {this.renderFieldErrors('processed_files')}
                          </Col>
                        </Row>
                        <Row className="ml-4">
                          <Col sm={2}>
                            <div className="label">
                              Manufacturer ID: {this.state.data.manufacturer_id}
                            </div>
                          </Col>
                        </Row>
                        <Row className="ml-4">
                          <Col sm={2}>
                            <div className="label">Data Sources:</div>
                          </Col>
                          <Col sm={4}>
                            <Select
                              isMulti
                              defaultValue={selectedDeliveryFormats.map((deliveryFormat) => ({
                                value: deliveryFormat.id,
                                label: deliveryFormat.name.toUpperCase(),
                                obj: deliveryFormat,
                              }))}
                              options={deliveryFormats.map((deliveryFormat) => ({
                                value: deliveryFormat.id,
                                label: deliveryFormat.name.toUpperCase(),
                                obj: deliveryFormat,
                              }))}
                              onChange={this.onDeliveryFormatsChanged}
                            />
                            {this.renderFieldErrors('data_source')}
                          </Col>
                        </Row>
                        <Row className="ml-4">
                          <Col sm={3}>
                            <div className="label">
                              Publish Date:{' '}
                              {this.state.data.published_at ? (
                                <Moment format={MIN_DATE} date={this.state.data.published_at} />
                              ) : (
                                'N/A'
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </section>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12}>
                    <section className="section-general-attrs">
                      <div className="title">Attributes Analysis 1:</div>
                      <div className="content">
                        <Row>
                          <Col sm={3}>
                            <b>Name</b>
                          </Col>
                          <Col sm={3}>
                            <b>Display Label</b>
                          </Col>
                          <Col sm={2}>
                            <b>Value</b>
                          </Col>
                          <Col sm={2}>
                            <b>Criteria</b>
                          </Col>
                          <Col sm={2}>
                            <b>Suggestion</b>
                          </Col>
                        </Row>
                        {this.state.generalAttributes ? (
                          <ul className="attr-list">
                            {this.state.generalAttributes.map((item, index) => (
                              <li key={index}>
                                <Row className="mt-4">
                                  <Col sm={3}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'general',
                                          'name',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.attribute.name}
                                      paceholder="Custom Attribute Name"
                                      maxLength="50"
                                      readOnly={!!item.id}
                                      disabled={item.attribute.is_default}
                                    />
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'general',
                                          'label',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.label}
                                      placeholder="Custom Label Name"
                                      maxLength="50"
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'general',
                                          'value',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.value === null ? 'N/A' : item.value}
                                      maxLength="50"
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <Form.Control
                                      as="textarea"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'general',
                                          'criteria',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.criteria}
                                      maxLength="1000"
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <Form.Control
                                      type="textarea"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'general',
                                          'suggestion',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.suggestion ? item.suggestion.suggestion : ''}
                                      maxLength="1000"
                                    />
                                  </Col>
                                </Row>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No general attributes</p>
                        )}
                        <Button
                          className="mt-4"
                          variant="primary"
                          size="lg"
                          onClick={() => this.addDraftAttribute('general')}
                        >
                          +
                        </Button>
                      </div>
                    </section>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12}>
                    <section className="section-general-attrs">
                      <div className="title">Attributes Analysis 2:</div>
                      <div className="content">
                        <Row>
                          <Col sm={3}>
                            <b>Name</b>
                          </Col>
                          <Col sm={3}>
                            <b>Display Label</b>
                          </Col>
                          <Col sm={2}>
                            <b>Value</b>
                          </Col>
                          <Col sm={2}>
                            <b>Criteria</b>
                          </Col>
                          <Col sm={2}>
                            <b>Suggestion</b>
                          </Col>
                        </Row>
                        {this.state.additionalAttributes ? (
                          <ul className="attr-list">
                            {this.state.additionalAttributes.map((item, index) => (
                              <li key={index}>
                                <Row className="mt-4">
                                  <Col sm={3}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'additional',
                                          'name',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.attribute.name}
                                      maxLength="50"
                                      readOnly={!!item.id}
                                      disabled={item.attribute.is_default}
                                    />
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'additional',
                                          'label',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.label}
                                      maxLength="50"
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'additional',
                                          'value',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.value === null ? 'N/A' : item.value}
                                      maxLength="50"
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <Form.Control
                                      type="textarea"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'additional',
                                          'criteria',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.criteria}
                                      maxLength="1000"
                                    />
                                  </Col>
                                  <Col sm={2}>
                                    <Form.Control
                                      type="textarea"
                                      size="sm"
                                      onChange={(e) =>
                                        this.handleValueChange(
                                          'additional',
                                          'suggestion',
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={item.suggestion ? item.suggestion.suggestion : ''}
                                      maxLength="1000"
                                    />
                                  </Col>
                                </Row>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No additional attributes</p>
                        )}
                        <Button
                          className="mt-4"
                          variant="primary"
                          size="lg"
                          onClick={() => this.addDraftAttribute('additional')}
                        >
                          +
                        </Button>
                      </div>
                    </section>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={8}>
                    <Button
                      disabled={!this.state.unsavedChanges}
                      variant="primary"
                      className="mr-4"
                      size="lg"
                      onClick={() => {
                        this.setState({
                          data: JSON.parse(JSON.stringify(this.state.stateToReset.data)),
                          generalAttributes: JSON.parse(
                            JSON.stringify(this.state.stateToReset.generalAttributes)
                          ),
                          additionalAttributes: JSON.parse(
                            JSON.stringify(this.state.stateToReset.additionalAttributes)
                          ),
                          unsavedChanges: false,
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() =>
                        this.props.history.push(`/data-request/${this.props.match.params.id}`)
                      }
                    >
                      Cancel
                    </Button>
                    {showViewReportBtn ? (
                      <Button
                        variant="secondary"
                        className="ml-4"
                        size="lg"
                        onClick={
                          this.state.unsavedChanges
                            ? this.handleShowUnsavedChangesModal
                            : this.goToFlashAssessmentReport
                        }
                      >
                        View Published Report
                      </Button>
                    ) : null}
                  </Col>
                  <Col sm={4}>
                    <Button
                      disabled={!this.state.unsavedChanges}
                      variant="primary"
                      className="mr-4"
                      size="lg"
                      onClick={async () => {
                        await this.saveAttributes();
                        if (this.state.data.status === 'published') {
                          this.goToFlashAssessmentReport();
                        }
                      }}
                    >
                      {this.state.data.status === 'published' ? 'Publish Changes' : 'Save'}
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      disabled={!this.state.data.id}
                      onClick={this.manageFAPublication}
                    >
                      {this.state.data.status === 'published' ? 'Unpublish' : 'Publish'}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              {this.state.isLoading ? (
                <div className="top-status">
                  <LoadingSpinner style={{ fontSize: '1.5em', marginRight: '0.5em' }} /> Loading
                  data...
                </div>
              ) : null}
            </>
          )}
        </div>
        <UnsavedChangesModal
          show={this.state.showUnsavedChangesModal}
          handleClose={this.handleShowUnsavedChangesModal}
          viewReport={this.goToFlashAssessmentReport}
          saveChanges={() => {
            this.saveAttributes();
          }}
          onHide={() => this.setState({ showUnsavedChangesModal: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    getDataRequestDeliveryFormatsResponse: {
      status: state.GetDataRequestDeliveryFormatsReducer.status,
      payload: state.GetDataRequestDeliveryFormatsReducer.payload,
    },
  };
};

const mapDispatchToProps = {
  getDataRequestDeliveryFormats,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewFlashAssessmentAttributes);
