import './DatePicker.scss';

import React, { forwardRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const BAIDatePicker = ({ className, containerClassName, ...props }) => {
  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className={classNames('bai-date-picker-container', containerClassName)}>
      <Form.Control
        value={value}
        placeholder={props.placeholder || 'Select a date...'}
        className={className}
        onClick={onClick}
        onChange={() => {}}
        ref={ref}
        disabled={props.disabled}
      />
      {props.isClearable ? (
        !value && <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
      ) : (
        <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
      )}
    </div>
  ));

  return <DatePicker customInput={<DateInput />} {...props} />;
};

export { BAIDatePicker };
