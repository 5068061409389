import React, { useContext } from 'react';

import _get from 'lodash/get';

import { DataIngestionDataQualityContext } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQualityContext';

const DataIngestionDataQualityOverview = () => {
  const ctx = useContext(DataIngestionDataQualityContext);
  const { dataJobData, attachmentData } = ctx;

  const errorsCount = _get(attachmentData, 'assessment_score.error_skus');
  const warningsCount = _get(attachmentData, 'assessment_score.warning_skus');

  return (
    <div className="data-ingestion-data-quality-overview">
      <div className="left-side">
        <div className="title">Errors and Warnings Preview</div>
        <div className="subtitle">Download this file below to make corrections</div>
        <div className="data-job-name">
          <div className="label">Data Job Name:</div>
          <div className="value">
            <a href={`/data-request/${dataJobData?.id}`} rel="noopener noreferrer" target="_blank">
              {dataJobData?.name}
            </a>
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="errors-warnings-summary">
          <div className="item item-errors">
            <div className="label">SKUs with Error{errorsCount !== 1 && 's'}</div>
            <div className="value">{errorsCount || 0}</div>
          </div>
          <div className="item item-warnings">
            <div className="label">SKUs with Warning{warningsCount !== 1 && 's'}</div>
            <div className="value">{warningsCount || 0}</div>
          </div>
        </div>
        {/* TODO: Checkbox */}
      </div>
    </div>
  );
};

export { DataIngestionDataQualityOverview };
