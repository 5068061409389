import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';

import { SignUpRequest } from '../signup/SignUpRequest';

const ViewSignUpRequest = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_ID}>
      <div className="view-login">
        <Helmet bodyAttributes={{ 'log-in': 'log-in' }}>
          <title>Sign Up to Get Started</title>
        </Helmet>
        <SignUpRequest />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export { ViewSignUpRequest };
