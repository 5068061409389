import React from 'react';

import { BotFormHeaderSection } from '../../_components/BotFormHeaderSection';
import { CdsBotItem } from '../../_components/CdsBotItem';
import { CdsModels } from '../../CdsModels';

import './CdsJobTypeSelector.scss';

export const CdsJobTypeSelector = ({ selectedType, setSelectedType }) => {
  return (
    <>
      <div>
        <BotFormHeaderSection
          title={undefined}
          description={
            <>
              <p>
                You are about to start a new{' '}
                <strong className="cds-description-strong">Content Databot Suite</strong> run.
                First, select the type of automation you want to run, then click{' '}
                <strong className="cds-description-strong">Next</strong> to configure your run
                accordingly.
              </p>
            </>
          }
        />
        <span className="separator" />
        <CdsBotItem
          model={CdsModels.CDS_BOT_ITEM.gptDescription}
          selectModal={setSelectedType}
          isSelected={selectedType === CdsModels.CDS_BOT_ITEM.gptDescription.name}
        />
        <span className="separator" />
        <CdsBotItem
          model={CdsModels.CDS_BOT_ITEM.gptFB}
          selectModal={setSelectedType}
          isSelected={selectedType === CdsModels.CDS_BOT_ITEM.gptFB.name}
        />
        <span className="separator" />
        <CdsBotItem
          model={CdsModels.CDS_BOT_ITEM.attributeCompression}
          isSelected={selectedType === CdsModels.CDS_BOT_ITEM.attributeCompression.name}
          selectModal={setSelectedType}
        />
        <span className="separator" />
      </div>
    </>
  );
};
