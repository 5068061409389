import './SmallSelectFileSection.scss';
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { FileSelector } from 'components/databots/bots/common/FileSelector/FileSelector';
import { UploadBotFile } from 'components/databots/bots/common/UploadBotFile/UploadBotFile';
import { sheetRegex } from 'constants/FileConstants';

const SmallSelectFileSection = ({
  title,
  dataJobId,
  saveToDataJob = true,
  selectedFile,
  setSelectedFile,
  filesList,
  setFilesList,
  isLoading,
  typeValidation = sheetRegex,
  note = undefined,
  errorMessage = ""
}) => {
  const [recentUpload, setRecentUpload] = useState(null);

  useEffect(() => {
    if (recentUpload) {
      setSelectedFile(recentUpload);
      setRecentUpload(null);
    }
  }, [filesList, setSelectedFile, recentUpload, selectedFile]);

  return (
    <section className="file-selection">
      <div className="title">{title}</div>
      {note && <div className="note">{note}</div>}
      <div className="content">
        <FileSelector
          selectedFile={selectedFile}
          filesList={filesList}
          setSelectedFile={setSelectedFile}
          disabled={isLoading}
        />
        <UploadBotFile
          saveToDataJob={saveToDataJob}
          dataJobId={dataJobId}
          onUploadedFiles={(uploadedFiles) => {
            if (uploadedFiles?.length) {
              setFilesList([...filesList, ...uploadedFiles]);
              setRecentUpload(uploadedFiles[uploadedFiles.length - 1]);
            }
          }}
          typeValidation={typeValidation}
          disabled={isLoading}
        />
      </div>
      {errorMessage && <div className="error-note">{errorMessage}</div>}
    </section>
  );
};

SmallSelectFileSection.defaultProps = {
  selectedFile: null,
  filesList: null,
};

SmallSelectFileSection.propTypes = {
  title: PropTypes.string.isRequired,
  dataJobId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedFile: PropTypes.object,
  setSelectedFile: PropTypes.func.isRequired,
  filesList: PropTypes.array,
  setFilesList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export { SmallSelectFileSection };
