import React from 'react';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { Databot } from 'components/databots/Databot/Databot';
import { BOT_STATUSES } from 'constants/BotConstants';
import { parseDatabotData } from 'helpers/BotUtils';
import { sortByKey } from 'helpers/Utils';

const DatabotsLibrary = ({ manufacturerId, parentManufacturerId, isLoading, bots, botRuns }) => {
  if (!manufacturerId) return null;

  const getBotData = (bot) => {
    let data = {
      ...parseDatabotData(bot),
    };

    if (bot.automated === false) {
      data = {
        ...data,
        status: BOT_STATUSES.DEFAULT,
        completed: botRuns.completeBots,
        running: botRuns.runningBots,
      };
    }

    return data;
  };

  const sortBots = (bots) => {
    if (bots.length === 0) return [];

    const automatedBots = sortByKey(
      bots.filter((bot) => bot.automated),
      'name'
    );
    const manualBots = sortByKey(
      bots.filter((bot) => !bot.automated),
      'name'
    );

    return [...automatedBots, ...manualBots];
  };

  return (
    <section className="databots-section">
      <div className="databots-section-header">
        <h2 className="databots-section-heading">BackboneAI Databot Library</h2>
        {isLoading && <LoadingSpinner fast />}
      </div>
      <p className="databots-section-description">
        BackboneAI databots help you transform your data. Choose from Reports, Builders, or
        Validators to increase the quality of your data. Some databots run automatically on every
        file you upload!
      </p>
      {!isLoading &&
        bots.map((category) =>
          category.bots?.length > 0 ? (
            <div key={category.key} className="bot-category-card">
              <div className="bot-category-card-info">
                <h3 className="bot-category-card-info__heading">{category.name}</h3>
                <p className="bot-category-card-info__subheading">{category.databotsDescription}</p>
              </div>
              <div className="bot-category-card-list">
                {sortBots(category.bots).map((bot) => (
                  <Databot
                    key={bot.id}
                    parentManufacturerId={parentManufacturerId}
                    manufacturerId={manufacturerId}
                    bot={getBotData(bot)}
                  />
                ))}
              </div>
            </div>
          ) : null
        )}
    </section>
  );
};

export { DatabotsLibrary };
