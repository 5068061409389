import React from 'react';

import _get from 'lodash/get';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { UserDisplay } from 'components/common/UserProfileDisplay/UserDisplay';

const UserSelector = ({
  usersList,
  user,
  onEdit,
  isEditMode,
  name,
  disabled,
  isSearchable = true,
  maxWidth,
}) => {
  const onUserSelected = (user) => {
    onEdit(user, name);
  };

  const buildUserNameDisplay = (user) => {
    const firstName = _get(user, 'first_name') || '';
    const lastName = _get(user, 'last_name') || '';
    const jobTitle = _get(user, 'profile.job_title') || '';
    return `${firstName} ${lastName}${jobTitle && `, ${jobTitle}`}`;
  };

  if (isEditMode) {
    return (
      <StyledMultiselect
        values={[user]}
        options={usersList && Array.isArray(usersList) && usersList.length ? usersList : []}
        setOnChange={onUserSelected}
        selectWrapperStyle={{ minWidth: '200px', maxWidth: maxWidth || 'none' }}
        selectStyle={{
          singleValue: () => ({
            maxWidth: '100%',
          }),
          menuList: () => ({
            maxWidth: '100%',
          }),
        }}
        getOptionValue={(user) => (
          <UserDisplay
            name={buildUserNameDisplay(user)}
            picture={user && user.profile ? user.profile.picture : null}
            size="sm"
            showBorder={false}
          />
        )}
        getOptionLabel={(user) => (
          <UserDisplay
            name={buildUserNameDisplay(user)}
            picture={user && user.profile ? user.profile.picture : null}
            size="sm"
            showBorder={false}
          />
        )}
        isSearchable={isSearchable}
        isMulti={false}
        isDisabled={!!disabled}
      />
    );
  }

  return (
    <UserDisplay
      name={buildUserNameDisplay(user)}
      picture={user !== null && user.profile ? user.profile.picture : ''}
      size="sm"
    />
  );
};

export { UserSelector };
