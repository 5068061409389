import './BaseDataFillLogTable.scss';
import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import { BaseDataFillLogTableDef } from 'helpers/table-defs/ViewBotsTableDef';

/**
 * Log table for Bot
 *
 * @param {array} logData Table contents
 * @return render
 */
const BaseDataFillLogTable = ({ logData }) => {
  return (
    <section className="data-fill-log">
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="id"
        data={logData}
        columns={BaseDataFillLogTableDef.columns}
      />
    </section>
  );
};

export { BaseDataFillLogTable };
