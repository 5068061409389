import React from 'react';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';

import { AssessmentStatBarItem } from './AssessmentStatBarItem';
import { IdsModels } from './IdsModels';

/**
 * Renders list of percentage bars for available assessment data
 *
 * @param {object} statsData Assessment statistics data
 * @param {array} keysHidden List of keys of items to be hidden
 * @param {string} title Assessment title to be shown on top
 * @param {boolean} isLoadingIdsData Loading state
 *
 * @return render
 */
const AssessmentDetails = ({ statsData, keysHidden, title, isLoadingIdsData }) => {
  const renderStatBars = (statsData) => {
    if (!statsData?.total_items > 0) {
      return null;
    }

    // Traverses through each item in the static statistics model and renders
    // available and intended to be shown items
    const statsList = Object.keys(IdsModels.ASSESSMENT_MODEL)
      .map((item, index) =>
        item in statsData && !(keysHidden?.length && keysHidden.includes(item)) ? (
          <li className={`stat-bars-item stats-bars-item-${item}`} key={index}>
            <AssessmentStatBarItem
              label={IdsModels.ASSESSMENT_MODEL[item].label}
              tooltip={{
                title: `${IdsModels.ASSESSMENT_MODEL[item].label} (${statsData[item]})`,
                content: IdsModels.ASSESSMENT_MODEL[item].description,
              }}
              value={(statsData[item] / statsData.total_items) * 100}
              reversed={IdsModels.ASSESSMENT_MODEL[item].reversed}
            />
          </li>
        ) : null
      )
      .filter((item) => !!item);

    return <ul className="stat-bars-list">{statsList}</ul>;
  };

  return (
    <>
      {!!title && (
        <div className="title">
          <h2>
            {title}
            {!!isLoadingIdsData && <LoadingSpinner fast />}
          </h2>
        </div>
      )}
      {!!statsData && renderStatBars(statsData)}
    </>
  );
};

export { AssessmentDetails };
