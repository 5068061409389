import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams, useHistory, Link } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { CompanyMetricsCard } from 'components/metrics/CompanyMetricsCard/CompanyMetricsCard';
import { CompanyOverview } from 'components/metrics/CompanyOverview/CompanyOverview';
import { MetricsTable } from 'components/metrics/MetricsTable/MetricsTable';
import { PRODUCT_ASSET_TYPES } from 'constants/ProductConstants';
import {
  AssetsTableDef,
  IndividualDistributorsTableDef,
  ProductsTableDef,
  UsersTableDef,
} from 'helpers/table-defs/ViewMetricsTableDef';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

const ViewManufacturerMetrics = () => {
  const [overviewMetrics, setOverviewMetrics] = useState({});
  const [productMetrics, setProductMetrics] = useState([]);
  const [assetMetrics, setAssetMetrics] = useState([]);
  const [userMetrics, setUserMetrics] = useState([]);
  const [clientMetrics, setClientMetrics] = useState([]);
  const [dataJobMetrics, setDataJobMetrics] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState('Dashboard');

  const { id } = useParams();
  const isMounted = useIsMounted();
  const history = useHistory();

  useEffect(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/metrics/manufacturer/${id}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          const { overview, products, assets, users, distributors, data_jobs: dataJobs } = res.data;

          const assetMetrics = {};
          Object.keys(assets).forEach((key) => {
            assetMetrics[assets[key].type] = assets[key].number;
          });
          // ! Workaround for bootstrap requiring a key to always be present, even when an object is empty
          assetMetrics[PRODUCT_ASSET_TYPES.FILE] = assetMetrics[PRODUCT_ASSET_TYPES.FILE] ?? 0;
          setPageTitle(`${overview.name} Dashboard`);
          setOverviewMetrics(overview);
          setProductMetrics([products]);
          setAssetMetrics([assetMetrics]);
          setUserMetrics(sortByKey(users, 'last_name'));
          setClientMetrics({
            total: distributors.total,
            distributors: sortByKey(distributors.distributors, 'name'),
          });
          setDataJobMetrics(dataJobs);
        }
      },
      onEnd: () => {
        setIsLoading(false);
      },
    });
  }, [isMounted, id]);

  const handleClientRowSelected = (row) => {
    history.push(`/metrics/distributor/${row.id}`);
  };

  const renderHelmet = () => (
    <Helmet bodyAttributes={{ 'data-page': 'view-client-metrics' }}>
      <title>{pageTitle}</title>
    </Helmet>
  );

  return isLoading ? (
    <div className="content content-fluid view-manufacturer-metrics">
      {renderHelmet()}
      <LoadingView />
    </div>
  ) : (
    <div className="content content-fluid view-manufacturer-metrics">
      {renderHelmet()}
      <CompanyOverview showViewDataJobs overview={overviewMetrics}>
        <CompanyMetricsCard
          header="Total Data Jobs"
          total={dataJobMetrics.total}
          metrics={[
            {
              label: 'Drafted',
              total: dataJobMetrics.draft,
            },
            {
              label: 'Requested',
              total: dataJobMetrics.requested,
            },
            {
              label: 'Recipient',
              total: dataJobMetrics.recipient,
            },
            {
              label: 'Deleted',
              total: dataJobMetrics.deleted,
            },
          ]}
        />
        <CompanyMetricsCard
          header="Total Files"
          total={dataJobMetrics.total_files}
          metrics={[
            {
              label: 'From Manufacturer',
              total: dataJobMetrics.files_from_manufacturer,
            },
            {
              label: 'From Distributor',
              total: dataJobMetrics.files_from_distributor,
            },
            {
              label: 'From BackboneAI',
              total: dataJobMetrics.files_from_backbone,
            },
          ]}
        />
        <CompanyMetricsCard
          header="Total Comments"
          total={dataJobMetrics.total_comments}
          metrics={[
            {
              label: 'From Manufacturer',
              total: dataJobMetrics.manufacturer_comments,
            },
            {
              label: 'From Distributor',
              total: dataJobMetrics.distributor_comments,
            },
            {
              label: 'From BackboneAI',
              total: dataJobMetrics.backbone_comments,
            },
          ]}
        />
      </CompanyOverview>
      <section className="metrics-details">
        <div className="metrics-details-group">
          <MetricsTable
            header="Products Overview"
            metrics={productMetrics}
            columns={ProductsTableDef}
            showViewAll={false}
            keyField="total"
          />
          <Link
            className="metrics-details-group-item"
            to={`/products?mfr=${id}--${overviewMetrics.name}`}
          >
            Go to Product Listing
          </Link>
        </div>
        <MetricsTable
          header="Assets Overview"
          metrics={assetMetrics}
          columns={AssetsTableDef}
          showViewAll={false}
          keyField={PRODUCT_ASSET_TYPES.FILE}
        />
        <MetricsTable
          header="User Overview"
          metrics={userMetrics}
          keyField="id"
          usePagination
          columns={UsersTableDef}
        />
        <MetricsTable
          header="Distributor Overview"
          handleRowSelected={handleClientRowSelected}
          keyField="id"
          usePagination
          metrics={clientMetrics.distributors ?? []}
          columns={IndividualDistributorsTableDef}
        />
      </section>
    </div>
  );
};

export { ViewManufacturerMetrics };
