import React from 'react';

import PropTypes from 'prop-types';
import './BotFormHeaderSection.scss';

/**
 * Component for rendering a header section in the bot form
 * @param title {string} - Title of the section
 * @param description {string|JSX.Element} - Description of the section
 *
 * @returns {JSX.Element}
 */
const BotFormHeaderSection = ({ title, description }) => (
  <div className="bot-form-header-section">
    {title && <div className="bot-form-header-section-title">{title}</div>}
    {description && (
      <div className="bot-form-header-section-description helper-text">{description}</div>
    )}
  </div>
);

BotFormHeaderSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

BotFormHeaderSection.defaultProps = {
  title: undefined,
  description: undefined,
};

export { BotFormHeaderSection };
