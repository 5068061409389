import './WeekPicker.scss';
import React, { forwardRef, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import DatePicker, { CalendarContainer } from 'react-datepicker';

import { formatMinYearDateStamp } from 'helpers/TimeUtils';

const years = [...Array(moment().year() + 1 - 2017).keys()].map((v) => v + 2017);
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const WeekPicker = ({ setStartDate, setEndDate, endDate, startDate, isLoading }) => {
  const [dirtyStartDate, setDirtyStartDate] = useState(startDate);
  const [dirtyEndDate, setDirtyEndDate] = useState(endDate);
  const [year, setYear] = useState(moment(startDate).year());
  const [month, setMonth] = useState(months[moment(startDate).month()]);
  const datePickerRef = useRef();
  const maxDate = moment().day(1).toDate();

  const Input = forwardRef(({ onClick, startDate, endDate }, ref) => {
    startDate = moment(startDate).isValid() ? formatMinYearDateStamp(startDate) : '';
    endDate = moment(endDate).isValid() ? formatMinYearDateStamp(endDate) : '';
    const dateValue = `${startDate}-${endDate}`;

    return (
      <div className="weekpicker-input-wrapper">
        <FontAwesomeIcon size="sm" icon={['fas', 'calendar-alt']} />
        <input
          defaultValue={dateValue}
          placeholder="Select a date..."
          className="weekpicker-input-wrapper-input"
          onClick={onClick}
          ref={ref}
        />
      </div>
    );
  });

  const onChange = (date) => {
    setDirtyStartDate(date);
    setDirtyEndDate(moment(date).add(6, 'day').toDate());
    setMonth(months[date.getMonth()]);
    setYear(date.getUTCFullYear());
  };

  const isMonday = (date) => moment(date).day() === 1;

  const customHeader = ({ changeYear, changeMonth }) => {
    return (
      <div className="weekpicker-header">
        <div className="weekpicker-header-selectors">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-month"
              variant="light"
              size="sm"
              className="weekpicker-header-button"
            >
              {month} <FontAwesomeIcon icon={['fas', 'chevron-down']} size="xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="weekpicker-header-menu">
              {months.map((option) => (
                <Dropdown.Item
                  key={option}
                  id={option}
                  eventKey={option}
                  active={option === month}
                  onSelect={(m) => {
                    changeMonth(months.indexOf(m));
                    setMonth(m);
                  }}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-year"
              variant="light"
              size="sm"
              className="weekpicker-header-button"
            >
              {year} <FontAwesomeIcon icon={['fas', 'chevron-down']} size="xs" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="weekpicker-header-menu">
              {years.map((option) => (
                <Dropdown.Item
                  key={option}
                  id={option}
                  eventKey={option}
                  active={option === year}
                  onSelect={(y) => {
                    changeYear(y);
                    setYear(y);
                  }}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  const calendarContainer = ({ className, children }) => {
    return (
      <div className="weekpicker-wrapper">
        <CalendarContainer className={className}>
          <div className="weekpicker-header">{children}</div>
          <div className="weekpicker-actions">
            <button
              type="button"
              className="weekpicker-actions-btn weekpicker-actions-btn--cancel"
              id="cancel-select-weekpicker"
              onClick={() => {
                datePickerRef.current.setOpen(false);
                setDirtyStartDate(startDate);
                setDirtyEndDate(endDate);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="weekpicker-actions-btn weekpicker-actions-btn--save"
              onClick={() => {
                setStartDate(dirtyStartDate);
                setEndDate(dirtyEndDate);
              }}
              id="set-select-weekpicker"
            >
              Set Date
            </button>
          </div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <DatePicker
      id="weekRangeDatePicker"
      ref={datePickerRef}
      placeholderText="Select a date..."
      dateFormat="MM/dd/yy"
      focusSelectedMonth={false}
      renderCustomHeader={customHeader}
      calendarContainer={calendarContainer}
      filterDate={isMonday}
      selected={dirtyStartDate}
      maxDate={maxDate}
      selectsEnd={false}
      startDate={dirtyStartDate}
      endDate={dirtyEndDate}
      onChange={onChange}
      isDisabled={isLoading}
      shouldCloseOnSelect={false}
      popperPlacement="bottom-end"
      showPopperArrow={false}
      customInput={<Input startDate={dirtyStartDate} endDate={dirtyEndDate} />}
    />
  );
};

WeekPicker.defaultProps = {
  isLoading: false,
};

WeekPicker.propTypes = {
  isLoading: PropTypes.bool,
};

export { WeekPicker };
