import './GeneralInformation.scss';

import React from 'react';

import PropTypes from 'prop-types';
import Moment from 'react-moment';

import { Assets } from 'components/pdp/Assets';
import { Info } from 'components/pdp/Info';
import { MIN_DATE } from 'constants/DateTimeConstants';

const GeneralInformation = ({ product }) => {
  const renderProductName = () => {
    if (product?.name) {
      return product.name;
    }
    return [product?.code, product?.description, product?.description_new?.name]
      .filter((a) => !!a)
      .join(' ');
  };
  return (
    <div className="general-info">
      <div className="general-info-header">
        <h1>{renderProductName()}</h1>
        <div className="general-info-header-date">
          <span>Last Updated: </span>
          {product.updated_at ? <Moment format={MIN_DATE} date={product.updated_at} /> : 'N/A'}
        </div>
      </div>
      <div className="general-info-body">
        <Assets product={product} assets={product.assets} />
        <Info
          code={product.code}
          fallbackDescription={product.description}
          brandName={product.brand_name?.value}
          generalAttributes={product.general_attributes}
          id={product.id}
          manufacturer={product.manufacturer}
          upc={product.upc}
        />
      </div>
    </div>
  );
};

GeneralInformation.propTypes = {
  product: PropTypes.object.isRequired,
};

export { GeneralInformation };
