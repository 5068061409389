import React from 'react';

import { formatMinDateStamp } from 'helpers/TimeUtils';
import { getDefaultTableColumnDef, getIconWithPopover, wrapWithTooltip } from 'helpers/Utils';
import * as Utils from 'helpers/Utils';

import { CdsJobActions } from './CdsJobActions';
import { IdsUtils } from './IdsUtils';

const StatusField = ({ botName, processJob, phase, status, tooltipInfo }) => {
  const DEFAULT_BOT_NAME = 'Preprocessing';
  return (
    <div className="status-block">
      <div className="bot-name"> {botName ?? DEFAULT_BOT_NAME} </div>
      <div className="status-block-info">
        <div className="status-block-icon">{IdsUtils.getStatusIcon(processJob)} </div>
        <div>
          <div className="status-block-phase"> {phase}</div>
          <div className="status-block-status">
            <span>{status.label.status}</span>
            {status?.label?.progress ? (
              <>
                <span className="status-block-status-info-progress">
                  - {status?.label?.progress}%
                </span>
              </>
            ) : undefined}
            {tooltipInfo &&
              getIconWithPopover({
                iconProp: ['far', tooltipInfo.icon],
                content: tooltipInfo.msg,
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ContentBotEngineLogTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('job_id', 'ID'),
      sort: false,
      formatter: (_cell, row) => {
        return row?.process_job?.id ?? 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('file', 'File'),
      sort: false,
      formatter: (_cell, row) => {
        const [fName, fExt] = Utils.splitExt(
          row?.process_job?.additional_data[0]?.original_file_name
        );
        const itemsCount = row?.process_job?.statistics?.initial?.total_items;
        return (
          <>
            {wrapWithTooltip({
              target: (
                <div className="file-block">
                  <div className="file-block-name">
                    <span className="fname">{fName}</span>
                    <span className="fext">.{fExt}</span>
                  </div>
                  <div className="file-block-img-count">
                    {itemsCount && (
                      <>
                        <span className="label">Items:</span>
                        <span className="value">{itemsCount}</span>
                      </>
                    )}
                  </div>
                </div>
              ),
              content: row?.process_job?.additional_data[0]?.original_file_name ?? null,
            })}
          </>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('updated_at', 'Last Updated'),
      sort: false,
      formatter: (_cell, row) => {
        return row?.process_job?.updated_at
          ? formatMinDateStamp(row?.process_job?.updated_at)
          : 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('start_by', 'Started By'),
      sort: false,
      formatter: (_cell, row) => {
        let name = Utils.joinNameToStr(
          row?.requestor_user?.first_name ?? null,
          row?.requestor_user?.last_name ?? null
        );

        if (!name?.length) {
          name = row?.requestor_user?.email;
        }

        return name ?? 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('status', 'Status'),
      sort: false,
      formatter: (_cell, row) => {
        const phase = IdsUtils.getCdsPhaseLabel(row?.process_job);
        const status = IdsUtils.getCdsStatus(row?.process_job);
        const tooltipInfo = IdsUtils.getTooltipInfo(row?.process_job);
        const botName = IdsUtils.getBotName(row?.process_job);

        return (
          <StatusField
            botName={botName}
            phase={phase}
            status={status}
            tooltipInfo={tooltipInfo}
            processJob={row?.process_job}
          />
        );
      },
    },
    {
      ...getDefaultTableColumnDef('cds-actions'),
      sort: false,
      formatter: (_cell, row) => <CdsJobActions jobRow={row} />,
    },
  ],
};
