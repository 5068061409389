import './DataJobDetailsInput.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { Form, Col } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import DataJobCreateConstants from 'constants/DataJobCreateConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

/**
 * Populates the party selection for all non admins.
 * First checks if user has child companies, if so it will prompt the requestorSelect first.
 * NOTE: the reducers and user constants are still not unified for client/distributor and manufacturer/supplier:
 * DetailsReducer Requestor Type follows old BE pattern of 'client' or 'manufacturer'
 * UserRoleConstants use SUPPLIER vs CLIENT
 * DetailsReducer uses MANUFACTURER and DISTRIBUTOR for 'manufacturer' and 'client
 * CurrentUser profiles use 'manufacturer' and 'client'
 *
 * @param {} details - Reducer for grandparent
 * @param {} invalidCheck
 * @param {} setDetails
 * @param {} currentUser
 * @returns Dropdown selectors for distributor, manufacturer, and requestor
 */
const PartySelectUser = ({ details, invalidCheck, setDetails, currentUser }) => {
  const isMounted = useIsMounted();
  const [distributorList, setDistributorList] = useState(null);
  const [manufacturerList, setManufacturerList] = useState(null);
  const [requestorList, setRequestorList] = useState(null); // Only gets set if user's company has children
  const [selectedDistributor, setSelectedDistributor] = useState([details.distributor]);
  const [selectedManufacturer, setSelectedManufacturer] = useState([details.manufacturer]);
  const [selectedRequestor, setSelectedRequestor] = useState([details.requestor]);


  const getChildrenList = useCallback(() => {
    // Returns the child companies of the user's company
    // Sets the children to the requestorList state array
    if (!isMounted || !currentUser) return;
    const url =
      currentUser.role === UserRoleConstants.SUPPLIER
        ? `/manufacturers/${currentUser.profile.manufacturer.id}/children`
        : `/clients/${currentUser.profile.client.id}/children`;

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: url,
      onSuccess: (r) => {
        if (isMounted.current) {
          setRequestorList(
            requestorSort(
              r.data.map((item) => ({
                value: item.id,
                label: item.name,
                obj: item,
                has_distributors: Object.keys(item.code).length > 0,
              })),
              'label'
            )
          );
        }
      },
      onError: () => {
        toast.error({ message: 'Unable to get child company list' });
      },
    });

    function requestorSort(list) {
      // Alpha sort the list of possible requestors with parent company at the top
      list.sort((a, b) => a.name - b.name);
      const parentType = currentUser?.profile.client?.id
        ? DataJobCreateConstants.CLIENT
        : DataJobCreateConstants.MANUFACTURER;
      list.unshift({
        value: currentUser.profile[parentType].id,
        label: currentUser.profile[parentType].name,
        obj: currentUser.profile,
        has_distributors: true,
      });

      // Remove children without a distributor
      const onlyWithDistributors = list.filter((company) => company.has_distributors);
      return onlyWithDistributors;
    }
  }, [currentUser, isMounted]);

  const getManufacturerList = useCallback(() => {
    // Returns Manufacturer list for the user's company
    if (!currentUser || !isMounted.current) return;
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/manufacturers/`,
      onSuccess: (r) => {
        if (isMounted.current) {
          setManufacturerList(
            r.data.map((item) => ({
              value: item.id,
              label: item.name,
              obj: item,
            })),
            'label'
          );
        }
      },
      onError: () => {
        toast.error({ message: 'Unable to get manufacturer list' });
      },
    });
  }, [currentUser, isMounted]);

  const getDistributorList = useCallback(() => {
    // Returns Distributors for the user's company
    if (!currentUser || !isMounted.current) return;
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/distributors/`,
      onSuccess: (r) => {
        if (isMounted.current) {
          setDistributorList(
            r.data.map((item) => ({ value: item.id, label: item.name, obj: item })),
            'label'
          );
        }
      },
      onError: () => {
        toast.error({ message: 'Unable to get distributor list!' });
      },
    });
  }, [currentUser, isMounted]);

  // Starts here: first check company type and children
  useEffect(() => {
    if (!currentUser?.profile || !isMounted) return;
    if (
      // User agnostic check for children:
      currentUser.profile.manufacturer?.has_children ||
      currentUser.profile.client?.has_children
      // V2 NOTE: V1 does not require this check for clients yet. currently no clients have child companies on db.
      // The logic in place in this page will work for clients once a client with children is added.
    ) {
      getChildrenList();
    } else if (currentUser.role === UserRoleConstants.SUPPLIER) {
      // User is manufactuer and does not have children
      getDistributorList(null);
      setDetails({ type: DataJobCreateConstants.REQUESTOR, payload: currentUser.id });
      setDetails({
        type: DataJobCreateConstants.MANUFACTURER,
        payload: currentUser.profile.manufacturer.id,
      });
    } else {
      // User is distributor w/o children
      getManufacturerList(null);
      setDetails({ type: DataJobCreateConstants.REQUESTOR, payload: currentUser.id });
      setDetails({
        type: DataJobCreateConstants.DISTRIBUTOR,
        payload: currentUser.profile.client.id,
      });
    }
    // Register the user type with the reducer:
    setDetails({
      type: DataJobCreateConstants.REQUESTOR_TYPE,
      payload: `${
        currentUser.role === UserRoleConstants.SUPPLIER
          ? DataJobCreateConstants.MANUFACTURER
          : DataJobCreateConstants.CLIENT
      }`,
    });
    // setDetails is shared between siblings too often. Not required for functionality on this component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, getChildrenList, getDistributorList, getManufacturerList, isMounted]);

  // --------------------------------------------------- onSelects() --------------------------------------------------- //

  const onDistributorSelected = useCallback(
    (value) => {
      setSelectedDistributor([value]);
      setDetails({ type: DataJobCreateConstants.DISTRIBUTOR, payload: value });
      setDetails({ type: DataJobCreateConstants.RECIPIENT, payload: value });
    },
    [setDetails]
  );

  const onManufacturerSelected = useCallback(
    (value) => {
      setSelectedManufacturer([value]);
      setDetails({ type: DataJobCreateConstants.MANUFACTURER, payload: value });
      setDetails({ type: DataJobCreateConstants.RECIPIENT, payload: value });
    },
    [setDetails]
  );

  const onRequestorSelected = useCallback(
    // For users with child companies only
    (requestor) => {
      // update the recipient options for the selected company
      if (currentUser.role === UserRoleConstants.SUPPLIER) {
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/manufacturers/${requestor.value}/distributors`,
          onSuccess: (r) => {
            if (isMounted.current) {
              setDistributorList(
                r.data.map((item) => ({
                  value: item.id,
                  label: item.name,
                  obj: item,
                })),
                'label'
              );
            }
          },
          onError: (e) => {
            toast.error({ message: `Problem updating the companies recipients, ${e}` });
          },
        });
      } else {
        // updating manuf list
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/distributors/${requestor.value}`,
          onSuccess: (r) => {
            if (isMounted.current) {
              setManufacturerList(
                r.data.manufacturers.map((item) => ({
                  value: item.id,
                  label: item.name,
                  obj: item,
                })),
                'label'
              );
            }
          },
        });
      }

      setSelectedRequestor(requestor);
      onDistributorSelected(null);
      onManufacturerSelected(null);

      // Update reducer
      setDetails({ type: DataJobCreateConstants.REQUESTOR, payload: requestor });
      setDetails({
        type:
          currentUser.role === UserRoleConstants.SUPPLIER
            ? DataJobCreateConstants.MANUFACTURER
            : DataJobCreateConstants.CLIENT,
        payload: requestor.value,
      });
      // Set is vicaroius job
      if (
        ![currentUser.profile?.manufacturer?.id, currentUser.profile?.client?.id].includes(
          requestor.value
        )
      ) {
        setDetails({
          type: DataJobCreateConstants.VICARIOUS_JOB,
          payload: true,
        });
      }
    },
    [
      currentUser.profile?.client?.id,
      currentUser.profile?.manufacturer?.id,
      currentUser.role,
      isMounted,
      setDetails,
    ]
  );



  const SelectDistributor = useCallback(() => {
    return (
      <StyledMultiselect
        id="distribSelect"
        className={invalidCheck.distributor ? 'is-invalid' : null}
        values={selectedDistributor || []}
        options={distributorList}
        optionsSort
        setOnChange={onDistributorSelected}
        isSearchable
        isMulti={false}
        isDisabled={!distributorList}
        isInvalid={invalidCheck.distributor}
      />
    );
  }, [distributorList, invalidCheck.distributor, onDistributorSelected, selectedDistributor]);

  const SelectManufacturer = useCallback(() => {
    return (
      <StyledMultiselect
        id="mfrSelect"
        className={invalidCheck.manufacturer ? 'is-invalid' : null}
        values={selectedManufacturer || []}
        options={manufacturerList}
        optionsSort
        setOnChange={onManufacturerSelected}
        isSearchable
        isMulti={false}
        isDisabled={!manufacturerList}
        isInvalid={invalidCheck.manufacturer}
      />
    );
  }, [manufacturerList, invalidCheck.manufacturer, onManufacturerSelected, selectedManufacturer]);

  const SelectRequestor = useCallback(() => {
    // For user companies with child companies only
    return (
      <StyledMultiselect
        id="reqSelect"
        className={invalidCheck.requestor ? 'is-invalid' : null}
        values={selectedRequestor || []}
        options={requestorList}
        optionsSort
        setOnChange={onRequestorSelected}
        isSearchable
        isMulti={false}
        isDisabled={!requestorList}
        isInvalid={invalidCheck.requestor}
      />
    );
  }, [invalidCheck.requestor, onRequestorSelected, requestorList, selectedRequestor]);

  return (
    <>
      {requestorList && ( // IE: User has children
        <Form.Row>
          <Form.Group as={Col} controlId="request.controlInp">
            <Form.Label className="data-job-details-selection-text">Company *</Form.Label>
            <Form.Control as={SelectRequestor} />
            <Form.Control.Feedback type="invalid">
              Please select a company to make this request as!
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        {currentUser?.role === UserRoleConstants.SUPPLIER ? (
          <Form.Group as={Col} controlId="distributor.controlInp">
            <Form.Label className="data-job-details-selection-text">Recipient *</Form.Label>
            <Form.Control as={SelectDistributor} />
            <Form.Control.Feedback type="invalid">
              Please select a distributor!
            </Form.Control.Feedback>
          </Form.Group>
        ) : (
          // User is distributor
          <Form.Group as={Col} controlId="manufacturer.controlInp">
            <Form.Label className="data-job-details-selection-text">Manufacturer *</Form.Label>
            <Form.Control as={SelectManufacturer} />
            <Form.Control.Feedback type="invalid">
              {' '}
              Please select a manufacturer!
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </Form.Row>
    </>
  );
};

export { PartySelectUser };
