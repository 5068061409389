import './IngestionAssessmentOverview.scss';

import React, { useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import { Row, Col } from 'react-bootstrap';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Moment from 'react-moment';
import { useHistory, Link } from 'react-router-dom';

import { ImgLightbox } from 'components/common/ImgLightbox/ImgLightbox';
import { FULL_DATE } from 'constants/DateTimeConstants';
import { generateDownloadPreviewUrl, generateProcessedDownloadUrl } from 'helpers/AttachmentUtils';

const IngestionAssessmentOverview = ({ attachmentData, requestData, flashAssessmentData }) => {
  const history = useHistory();

  const goToAssessmentErrors = () => {
    history.push(`/data-ingestion/data-quality/${attachmentData.id}`);
  };

  const getScoreStatus = (score, isLabel = false) => {
    let output = null;

    if (typeof score === 'number') {
      if (score >= 95) {
        output = isLabel ? 'Good' : 'good';
      } else if (score >= 75) {
        output = isLabel ? 'Acceptable' : 'acceptable';
      } else {
        output = isLabel ? 'Poor' : 'poor';
      }
    }

    return output;
  };
  const scoreValue =
    attachmentData.assessment_score && Math.ceil(attachmentData.assessment_score.total_score * 100);
  const scoreClass = getScoreStatus(scoreValue);

  const downloadFile = useCallback(() => {
    if (attachmentData.id) {
      const downloadUrl = `data-ingestion/attachments/${attachmentData.id}/download-processed`;
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      a.click();
    }
  }, [attachmentData]);

  function download() {
    const downloadUrl = generateProcessedDownloadUrl(attachmentData.id) || null;

    if (downloadUrl !== null) window.open(downloadUrl, '_blank');
    downloadFile();
  }
  return (
    <section className="section-overview-automated-assessment">
      <div className="content">
        <Row>
          <Col sm={6}>
            <div className="summary-data-1">
              <div className="kv-pair job-name">
                <Link
                  target="_blank"
                  className="value cursor-pointer link-color"
                  to={`/data-request/${attachmentData.data_request}`}
                >
                  {requestData.name}
                </Link>
              </div>
            </div>
            <div className="summary-data-1">
              <div className="kv-pair file-name">
                <div className="label">File Name</div>
                <div onClick={() => download()} className="value cursor-pointer link-color">
                  {attachmentData.original_name}
                </div>
                <span className="preview-link">
                  {!!attachmentData?.thumbnail_name && (
                    <ImgLightbox
                      src={generateDownloadPreviewUrl(attachmentData.id)}
                      title={attachmentData.original_name ?? attachmentData.name}
                    >
                      {({ doOpen }) => (
                        <button type="button" className="link" onClick={() => doOpen()}>
                          <FontAwesomeIcon icon={['far', 'file-search']} />
                        </button>
                      )}
                    </ImgLightbox>
                  )}
                </span>
              </div>
              <div className="kv-pair publish-date">
                {/* TODO Update to Published At if data is available */}
                <div className="label">History</div>
                <div className="value">
                  {flashAssessmentData && flashAssessmentData.updated_at !== null ? (
                    <button
                      type="button"
                      onClick={() => alert('history TBD')}
                      className="btn btn-secondary"
                    >
                      <Moment format={FULL_DATE} date={flashAssessmentData.updated_at} />
                      <FontAwesomeIcon
                        style={{ marginLeft: '.5em' }}
                        className="checked"
                        icon={['fas', 'calendar-alt']}
                        size="sm"
                      />
                    </button>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
            </div>
            <div className="summary-data-2">
              <div className="kv-pair total-files">
                <div className="label"># of Referenced Files</div>
                <div className="value">
                  {/* TODO add total files */}
                  {attachmentData.assessment_score &&
                  attachmentData.assessment_score.total_files >= 0
                    ? attachmentData.assessment_score.total_files.toLocaleString('en-US')
                    : 'N/A'}
                </div>
              </div>
              <div className="kv-pair total-skus">
                <div className="label">Total SKUs</div>
                <div className="value">
                  {attachmentData.assessment_score &&
                  attachmentData.assessment_score.total_skus >= 0
                    ? attachmentData.assessment_score.total_skus.toLocaleString('en-US')
                    : 'N/A'}
                </div>
              </div>
              <div className="kv-pair data-sources">
                <div className="label">Date Created</div>
                <div className="value" key="sources">
                  {attachmentData && attachmentData.created_at ? (
                    <Moment format={FULL_DATE} date={attachmentData.created_at} />
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <div className={`aggregate-score${scoreClass ? ` ${scoreClass}` : ''}`}>
              <div className="aggregate-score-value">
                <CircularProgressbarWithChildren value={scoreValue}>
                  <div className={`circular-progressbar-label${!scoreClass ? ` empty` : ''}`}>
                    {scoreClass ? (
                      <>
                        <div className="value">
                          {scoreValue}
                          <span className="unit">%</span>
                        </div>
                        <div className="label">Aggregate Score</div>
                        <div className="status-label-wrap">
                          <div className="status-label">
                            {getScoreStatus(scoreValue, true) || 'N/A'}
                          </div>
                        </div>
                      </>
                    ) : (
                      <span className="empty-label">N/A</span>
                    )}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              {attachmentData.assessment_score ? (
                <div className="score-breakdown">
                  <div className="title">
                    <h4>Improve Data quality</h4>
                  </div>
                  <div className="kpis">
                    <div className="successful">
                      <span className="kpi-title">Successful SKUs</span>
                      <span className="kpi-value">
                        {_get(attachmentData, 'assessment_score.correct_skus')}
                      </span>
                    </div>
                    <div className="warnings">
                      <span className="kpi-title">SKUs with Warnings</span>
                      <span className="kpi-value">
                        {_get(attachmentData, 'assessment_score.warning_skus')}
                      </span>
                    </div>
                    <div className="errors">
                      <span className="kpi-title">SKUs with Errors</span>
                      <span className="kpi-value">
                        {_get(attachmentData, 'assessment_score.error_skus')}
                      </span>
                    </div>
                  </div>
                  <div className="kpi-recommendations">
                    <div>Download the updated file and view details to improve your score.</div>
                  </div>
                  <div className="kpi-actions">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        goToAssessmentErrors();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: '.5em' }}
                        className="checked"
                        icon={['far', 'eye']}
                        size="sm"
                      />
                      View Details
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={() => download()}>
                      <span
                        className="oi oi-data-transfer-download"
                        style={{ marginRight: '.5em' }}
                        aria-hidden
                      />
                      Download
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export { IngestionAssessmentOverview };
