import React, { useRef, useState, useEffect, useCallback } from 'react';

import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { isIE } from 'react-device-detect';

import * as ApiCalls from 'api/ApiCalls';
import { AddLinkButton } from 'components/common/AddLinkButton/AddLinkButton';
import { FileUpload } from 'components/common/FileUpload/FileUpload';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { OKModal as MessageModal } from 'components/common/ModalPanel/OKModal';
import Button from 'components/distributor-job/Button';
import { statusValueConstants as DataDetailsConstants } from 'constants/DataJobDetailsConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { isRoleAdmin } from 'helpers/UserUtils';
import { checkFileExtAndSize } from 'helpers/Utils';

// TODO: This component should be refactored/optimized in a future iteration
const UploadAttachment = ({
  transformationStatus = null,
  onFileUploaded,
  dataJobId,
  dataJobStatus,
  showLinkButton = false,
  confirmTransformation = false,
}) => {
  const fileInputRef = useRef();
  const [isUploadVisible, setIsUploadVisible] = React.useState(false);
  const [isApprvlWarnVisible, setIsApprvlWarnVisible] = React.useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');
  const [disabled, setDisabled] = useState(DataDetailsConstants.READY.includes(dataJobStatus));
  const [uploadProgress, setUploadProgress] = useState('0');

  const isMounted = useIsMounted();
  // only setIsUploadComplete to true in this component, child will turn itself off on a timer when uploadcomplete is set to true
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadId, setUploadId] = useState(null);

  useEffect(() => {
    if (dataJobStatus) {
      if (isRoleAdmin) {
        setDisabled(false);
        return;
      }
      setDisabled(DataDetailsConstants.READY.includes(dataJobStatus));
    }
  }, [dataJobStatus]);

  const handleUploadClick = () => {
    if (!disabled) {
      setIsUploadVisible(true);
    } else {
      setHeader(
        DataDetailsConstants.READY_FOR_APPROVAL === dataJobStatus
          ? 'Ready for Approval'
          : DataDetailsConstants.COMPLETE === dataJobStatus
          ? 'Data Accepted'
          : ''
      );
      setBody(
        DataDetailsConstants.READY_FOR_APPROVAL === dataJobStatus
          ? 'This data file is under review for approval.  Please start a new data job.'
          : DataDetailsConstants.COMPLETE === dataJobStatus
          ? 'This data file has been accepted.  Please start a new data job.'
          : ''
      );
      setIsApprvlWarnVisible(true);
    }
  };

  const handleUpload = useCallback(
    async (type, data) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/data-requests/attachments/`,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
        isFileFormData: true,
        onProgress: (progressEvent) => {
          const percentage = ApiCalls.getProgressPercent(progressEvent);
          if (percentage >= 0) setUploadProgress(percentage);
        },
        onSuccess: (response) => {
          if (isMounted.current) {
            if (onFileUploaded) {
              onFileUploaded(response.data);
            }
            setIsUploadComplete(true);
            setIsUploading(false);
            setUploadId(response.data.id);
          }
        },
        onError: (err) => {
          if (isMounted.current) {
            setIsUploading(false);
            toast.error(`Error while uploading the ${type} ${err.response.data.message}`);
          }
        },
      });
    },
    [isMounted, onFileUploaded]
  );

  const onAddLink = useCallback(
    async (link) => {
      setIsUploading(true);
      const dataRequestAttachment = {
        data_request_id: dataJobId,
        name: link,
        file_type: 'externalLink',
        size: 0,
        original_name: link,
        transformed_required: null,
      };
      const formData = new FormData();
      Object.keys(dataRequestAttachment).forEach((k) => {
        if (dataRequestAttachment[k] !== undefined) formData.append(k, dataRequestAttachment[k]);
      });
      handleUpload('link', formData);
    },
    [dataJobId, handleUpload]
  );

  const onFileClicked = useCallback(
    async (e) => {
      const typeCallback = (name) => toast.error(`File type of "${name}" not allowed.`);
      const sizeCallback = () =>
        toast.error(
          'The file you are trying to upload exceeds the 2GB attachment limit. Try putting in a shared location and adding a link instead.'
        );

     const files = []
      e.forEach((f) => {
        const file = checkFileExtAndSize([f], typeCallback, sizeCallback);
        if (!file) {
          return;
        }
        files.push(file)
      });
      setIsUploading(true);

      const formData = new FormData();
      files.forEach((file, i)=>{
        formData.append(`file[${i}]`, file)
      });
      if (isIE) {
        // IE11 doesn't allow .set even though that is the correct function to use
        formData.append('data_request_id', dataJobId);
        formData.append('is_pass_thru_file', 0);
        formData.append('transformation_required', transformationStatus);
      } else {
        formData.set('data_request_id', dataJobId);
        formData.append('is_pass_thru_file', 0);
        formData.set('transformation_required', transformationStatus);
      }
      handleUpload('file', formData);
    },
    [dataJobId, handleUpload, transformationStatus]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // filter to only selected amount of files
      if (acceptedFiles.length > 0) {
        onFileClicked(acceptedFiles);
      }
    },
    [onFileClicked]
  );

  return (
    <div className="upload-attachment">
      {/* TODO: Use standard button component and remove this one */}
      <Button
        onClick={handleUploadClick}
        className={classNames('upload-attachment__btn', { disabled })}
        icon={isUploading ? '' : 'plus'}
        label={isUploading ? <LoadingSpinner style={{ fontSize: '1em' }} /> : 'Add Files'}
      />
      <div>
        <ModalPanel
          isVisible={isUploadVisible}
          setIsVisible={setIsUploadVisible}
          header="Upload file"
          showCancel
          body={
            <FileUpload
              onDrop={onDrop}
              isFileLoading={isUploading}
              maxFileCount={10}
              uploadProgress={uploadProgress}
              isUploadComplete={isUploadComplete}
              setIsUploadComplete={setIsUploadComplete}
              onSuccessMessage="File Successfully Added!"
              uploadId={uploadId}
              confirmTransformation={confirmTransformation}
              onFileUploaded={onFileUploaded}
            >
              {!!showLinkButton && <AddLinkButton handleAddLink={onAddLink} />}
            </FileUpload>
          }
        />
        <input
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={onFileClicked}
          type="file"
        />
      </div>
      <MessageModal
        isVisible={isApprvlWarnVisible}
        setIsVisible={setIsApprvlWarnVisible}
        header={header}
        body={body}
        onOk={() => {
          setIsApprvlWarnVisible(false);
        }}
      />
    </div>
  );
};

UploadAttachment.propTypes = {
  transformationStatus: PropTypes.number,
  onFileUploaded: PropTypes.func,
  dataJobId: PropTypes.number,
  dataJobStatus: PropTypes.string,
  showLinkButton: PropTypes.bool,
};

export { UploadAttachment };
