import React from 'react';

import * as Utils from 'helpers/Utils';

import { CircularProgressBar } from '../../../common/CircularProgressBar/CircularProgressBar';
import { ValueBlock } from '../../../common/ValueBlock/ValueBlock';

export const IdsServiceStats = ({ statistics, isStatisticsLoading }) => {
  const getMarketReadinessStatusClass = (marketReadiness) => {
    if (Number.isFinite(marketReadiness)) {
      if (marketReadiness >= 0.8) {
        return CircularProgressBar.STATUSES.GOOD;
      }
      if (marketReadiness >= 0.55) {
        return CircularProgressBar.STATUSES.ACCEPTABLE;
      }
      return CircularProgressBar.STATUSES.POOR;
    }
    return null;
  };

  return (
    <div className="ids-summary-self-service">
      <div className="value-group">
        <ValueBlock
          title="Jobs"
          value={Utils.renderLoadingOrContent(
            (statistics?.jobs?.started || 0) +
              (statistics?.jobs?.canceled || 0) +
              (statistics?.jobs?.not_started || 0) +
              (statistics?.jobs?.rejected || 0),
            isStatisticsLoading,
            0
          )}
          additionalData={[
            {
              label: ``,
              value: `${statistics?.jobs?.started || 0} active | ${
                statistics?.jobs?.not_started || 0
              } pending | ${statistics?.jobs?.canceled || 0} canceled | ${
                statistics?.jobs?.rejected || 0
              } rejected`,
            },
          ]}
          className="ids-summary-jobs span-2r"
          tooltip="Overview of the jobs including pending, active, canceled and rejected."
        />
        <ValueBlock
          title="Assessment"
          value={Utils.renderLoadingOrContent(
            statistics?.jobs?.assessment_completed,
            isStatisticsLoading,
            0
          )}
          tooltip="Number of assessments completed."
          subtitle="Jobs Done"
        />
        <ValueBlock
          title="Enhancement"
          value={Utils.renderLoadingOrContent(
            statistics?.jobs?.enhancement_completed,
            isStatisticsLoading,
            0
          )}
          tooltip="Number of enhancements completed."
          subtitle="Jobs Done"
        />
      </div>

      <div className="value-group">
        <ValueBlock
          title="Pending Extraction"
          value={Utils.renderLoadingOrContent(
            statistics?.items?.pending_extraction,
            isStatisticsLoading,
            0
          )}
          subtitle="Images"
          className="ids-summary-items"
          tooltip="Total number of images to be extracted."
        />
        <ValueBlock
          title="Extracted"
          value={Utils.renderLoadingOrContent(
            statistics?.items?.extracted?.success + statistics?.items?.extracted?.canceled,
            isStatisticsLoading,
            0
          )}
          additionalData={[
            {
              label: ``,
              value: `${statistics?.items?.extracted?.success || 0} completed | ${
                statistics?.items?.extracted?.canceled || 0
              } canceled`,
            },
          ]}
          subtitle="Images"
          className="ids-summary-items"
          tooltip="Total number of images extracted."
        />

        <ValueBlock
          title="Assessed"
          value={Utils.renderLoadingOrContent(
            statistics?.items?.assessed?.success + statistics?.items?.assessed?.canceled,
            isStatisticsLoading,
            0
          )}
          additionalData={[
            {
              label: ``,
              value: `${statistics?.items?.assessed?.success || 0} completed | ${
                statistics?.items?.assessed?.canceled || 0
              } canceled`,
            },
          ]}
          subtitle="Images"
          className="ids-summary-items"
          tooltip="Total number of images assessed."
        />
        <ValueBlock
          title="Enhanced"
          value={Utils.renderLoadingOrContent(statistics?.items?.enhanced, isStatisticsLoading, 0)}
          subtitle="Images"
          className="ids-summary-items"
          tooltip="Total number of images enhanced."
        />
      </div>

      <section className="circular-progress-bar-wrapper">
        <div className="circular-progress-bar">
          <CircularProgressBar
            percentage={(statistics?.pass_rate?.initial || 0) * 100}
            label="Initial Market Ready"
            status={getMarketReadinessStatusClass(statistics?.pass_rate?.initial || 0)}
            isSemiCircle
            isShowPercentage
            percentageDecimalPlaces={2}
            isShowStatus
          />
        </div>

        <div className="circular-progress-bar">
          <CircularProgressBar
            percentage={(statistics?.pass_rate?.final || 0) * 100}
            label="Final Market Ready"
            status={getMarketReadinessStatusClass(statistics?.pass_rate?.final || 0)}
            isSemiCircle
            isShowPercentage
            percentageDecimalPlaces={2}
            isShowStatus
          />
        </div>
      </section>
    </div>
  );
};
