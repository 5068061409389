import * as ApiCalls from 'api/ApiCalls';
import { statusTransformFiles as STATUSCONST } from 'constants/DataJobDetailsConstants';
import styleVars from 'scss/vars.scss';

import { getAuthToken } from './AuthTokenUtils';

export const readableFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1000));
  return `${(size / 1000 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const getTransformationRequiredLabel = (transformationRequired) => {
  switch (transformationRequired) {
    case 0:
      return 'No';
    case 1:
      return 'Yes';
    case 2:
      return 'Completed';
    default:
      return null;
  }
};

export const getViewedCommentsFromLocalStorage = () => {
  const viewedComments = window.localStorage.getItem('files_viewed_comments_ids');
  if (viewedComments) {
    return JSON.parse(viewedComments);
  }

  return {};
};

export const setViewedCommentsFromLocalStorage = (viewedComments) => {
  window.localStorage.setItem('files_viewed_comments_ids', JSON.stringify(viewedComments));
};

export const hasFlashAssessment = (flashAssessment) => {
  return !!(
    flashAssessment &&
    flashAssessment.id > 0 &&
    ['published', 'completed'].includes(flashAssessment.status)
  );
};

export const generateDownloadUrl = (attachmentId) => {
  const authToken = getAuthToken();
  if (authToken && attachmentId) {
    return `${ApiCalls.BASE_API_URL}/data-requests/attachments/${attachmentId}/download?token=${authToken}`;
  }
  return null;
};

export const generatePassThruFileDownloadUrl = (fileId) => {
  const authToken = getAuthToken();
  if (authToken && fileId) {
    return `${ApiCalls.BASE_API_URL}/pass-thru-files/${fileId}/download?token=${authToken}`;
  }

  return null;
};

export const generateDownloadPreviewUrl = (attachmentId) => {
  const authToken = getAuthToken();
  if (authToken && attachmentId) {
    return `${ApiCalls.BASE_API_URL}/data-requests/attachments/${attachmentId}/download?token=${authToken}&preview=true`;
  }

  return null;
};

export const generateProcessedDownloadUrl = (attachmentId) => {
  const authToken = getAuthToken();
  if (authToken && attachmentId) {
    return `${ApiCalls.BASE_API_URL}/data-ingestion/attachments/${attachmentId}/processed/download?token=${authToken}`;
  }

  return null;
};

export const generateExportDownloadUrl = (attachmentId) => {
  const authToken = getAuthToken();
  if (authToken && attachmentId) {
    return `${ApiCalls.BASE_API_URL}/product-export-history/${attachmentId}/download?token=${authToken}`;
  }

  return null;
};

export const getStatusObject = (status) => {
  switch (status) {
    case STATUSCONST.PENDING:
      return { label: 'Pending', value: status, color: `${styleVars.colors_signalWarning}` };
    case STATUSCONST.FLASH_ASSESSMENT:
      return {
        label: 'Flash Assessment',
        value: status,
        color: `${styleVars.colors_signalWarning}`,
      };
    case STATUSCONST.READY_FOR_REVIEW:
      return {
        label: 'Ready for Review',
        value: status,
        color: `${styleVars.colors_signalWarning}`,
      };
    case STATUSCONST.NEEDS_UPDATE:
      return { label: 'Needs Updates', value: status, color: '#dc3545' };
    case STATUSCONST.CANCELED:
      return { label: 'Canceled', value: status, color: '#28a745' };
    case STATUSCONST.FILE_ACCEPTED:
      return { label: 'Accepted', value: status, color: '#28a745' };
    default:
      return null;
  }
};

export const isArchive = (file) => {
  return /\.(zip)$/i.test(file?.name);
};
