import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { RootHooks } from 'helpers/RootHooks';
import { UserUtils } from 'helpers/UserUtils';

import { SectionDataJobsTableDef } from './SectionDataJobsTableDef';

/**
 * Render Transformed files table section
 *
 * @param {object} dataJobsData Data Job Data object
 * @param {object} dataJobsDataStatus Data Job Status
 * @param {number} mfrId Mfr Id
 * @param {boolean} showCreateBtn Whether to show Create Data Job btn
 * @param {string} title Custom title string
 * @return render
 */
const SectionDataJobs = ({ dataJobsData, dataJobsDataStatus, mfrId, showCreateBtn, title }) => {
  const history = useHistory();

  const { activeUser } = RootHooks.useActiveUser();

  const isDistributor = UserUtils.isRoleClient(activeUser);

  const getColsForRole = () => {
    return SectionDataJobsTableDef.columns.filter(
      (item) =>
        (isDistributor && item.dataField !== 'distributor') ||
        (!isDistributor && item.dataField !== 'manufacturer')
    );
  };

  const columns = getColsForRole();

  return (
    <section className="data-jobs">
      <div className="heading">
        <div className="title">
          <span>{title?.length ? title : 'Data Jobs'}</span>
          {dataJobsDataStatus === ActionStatusConstants.ISBUSY && <LoadingSpinner fast />}
        </div>
        <div className="actions">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => history.push(`/data-jobs${mfrId ? `?supplier[]=${mfrId}` : ''}`)}
          >
            <span className="label">View All Data Jobs</span>
            <span className="icon" style={{ marginLeft: '0.5em' }}>
              <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
            </span>
          </button>
          {showCreateBtn && !UserUtils.isReadOnly(activeUser) && (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => history.push('/data-job-create')}
            >
              <span className="icon" style={{ marginRight: '0.5em' }}>
                <FontAwesomeIcon icon={['far', 'plus']} />
              </span>
              <span className="label">Create Data Job</span>
            </button>
          )}
        </div>
      </div>
      <div className="data-jobs-wrap">
        {dataJobsData?.data_requests?.length ? (
          <BootstrapTable
            bordered={false}
            bootstrap4
            keyField="id"
            data={dataJobsData.data_requests}
            columns={columns}
          />
        ) : (
          dataJobsDataStatus === ActionStatusConstants.SUCCESS && (
            <div className="empty-notice">
              <Alert variant="primary">No Data Jobs found.</Alert>
            </div>
          )
        )}
      </div>
    </section>
  );
};

SectionDataJobs.defaultProps = {
  dataJobsData: null,
  dataJobsDataStatus: ActionStatusConstants.INITIAL,
  mfrId: null,
  showCreateBtn: false,
  title: null,
};

SectionDataJobs.propTypes = {
  dataJobsData: PropTypes.shape({ data_requests: PropTypes.array }),
  dataJobsDataStatus: PropTypes.oneOf(Object.values(ActionStatusConstants)),
  mfrId: PropTypes.number,
  showCreateBtn: PropTypes.bool,
  title: PropTypes.string,
};

export { SectionDataJobs };
