import React, { useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { BotConstants } from '../../../../../constants/BotConstants';
import { StyledMultiselect } from '../../../../common/StyledMultiselect/StyledMultiselect';
import { BotFormHeaderSection } from '../../_components/BotFormHeaderSection';
import { BotFormLabel } from '../../_components/BotFormLabel';
import { CdsModels } from '../../CdsModels';

import './CdsConfigurationForm.scss';
import './CdsAttributeCompressionForm.scss';

const formFields = {
  columns: 'columns',
  selectedThreshold: 'selectedThreshold',
  customThresholdValue: 'customThresholdValue',
};

const validateState = (newState, field, value) => {
  let error = null;
  switch (field) {
    case formFields.columns:
      error = !value?.length ? 'Please select at least one header.' : null;
      break;
    case formFields.selectedThreshold:
      error = !value ? 'Please provide a length or select a predefined length option.' : null;
      break;
    case formFields.customThresholdValue:
      if (newState.selectedThreshold?.value === 'custom') {
        if (!value) {
          error = 'Please provide a length or select a predefined length option.';
        } else if (!/^\d+$/.test(value) || parseInt(value, 10) < 1) {
          error = 'Custom length must be a positive number.';
        }
      }
      break;
    default:
      break;
  }
  return error;
};

const handleFormStateUpdate = (state, payload) => {
  const { field, value } = payload;
  const error = validateState(state, field, value);
  const newState = {
    ...state,
    [field]: value,
    errors: {
      ...state.errors,
      [field]: error,
    },
  };

  if (field === formFields.selectedThreshold) {
    if (value?.value !== 'custom') {
      newState[formFields.customThresholdValue] = '';
    } else {
      newState.errors[formFields.customThresholdValue] = validateState(
        newState,
        formFields.customThresholdValue,
        ''
      );
    }
  }
  return newState;
};

const initializeFormState = ({ config, headers }) => {
  const foundThreshold = BotConstants.ATTRIBUTES_COMPRESSION_CHARACTERS_LENGTH.find(
    (option) => option.value === config.character_threshold
  );
  const isCustomOption = foundThreshold === undefined && config.character_threshold;
  let initialConfig = {
    columns: (config?.columns || []).map((item) => ({
      value: item,
      label: item,
    })),
    selectedThreshold:
      !foundThreshold || isCustomOption
        ? BotConstants.ATTRIBUTES_COMPRESSION_CHARACTERS_LENGTH[
            BotConstants.ATTRIBUTES_COMPRESSION_CHARACTERS_LENGTH.length - 1
          ]
        : foundThreshold,
    customThresholdValue: isCustomOption ? config.character_threshold : '',
    headerChoices: headers.map((header) => ({ label: header, value: header, name: header })),
    errors: {},
  };

  // Apply validation to the initial state
  initialConfig = {
    ...initialConfig,
    errors: {
      ...initialConfig.errors,
      [formFields.columns]: validateState(initialConfig, formFields.columns, initialConfig.columns),
      [formFields.selectedThreshold]: validateState(
        initialConfig,
        formFields.selectedThreshold,
        initialConfig.selectedThreshold
      ),
      [formFields.customThresholdValue]: validateState(
        initialConfig,
        formFields.customThresholdValue,
        initialConfig.customThresholdValue
      ),
    },
  };
  return initialConfig;
};

const hasErrors = (errors) => Object.values(errors).some((v) => v !== null);

const CdsAttributeCompressionForm = ({
  headers,
  config,
  setConfig,
  errors,
  onFormErrors,
  disabled = false,
}) => {
  const [formState, onFormStateUpdated] = useReducer(
    handleFormStateUpdate,
    { config, headers },
    initializeFormState
  );

  useEffect(() => {
    if (hasErrors(formState.errors)) {
      onFormErrors('form', true);
    } else {
      onFormErrors('form', false);
    }
  }, [errors, formState.errors, onFormErrors]);

  useEffect(() => {
    if (!hasErrors(formState.errors)) {
      const validSelectedLength =
        formState[formFields.selectedThreshold]?.value === 'custom'
          ? formState.customThresholdValue
          : formState.selectedThreshold?.value;
      const botConfigObj = {
        character_threshold: parseInt(validSelectedLength, 10),
        columns: formState[formFields.columns].map((column) => column.value),
      };

      setConfig(botConfigObj);
    }
  }, [formState, setConfig]);

  return (
    <>
      <div>
        <BotFormHeaderSection
          title="Attribute Compression"
          description={
            <>
              <p>
                Use <strong>automation</strong> to{' '}
                <u>reduce the character size of your attributes</u> to the desired lengths.
              </p>
              <p>
                Select the attributes you want to compress and specify the limit, and you’re all
                set!
              </p>
            </>
          }
        />
      </div>

      <div>
        <BotFormLabel label="Configuration" type="section" />
        {config && Object.keys(config).length > 0 && (
          <div className="cds-attribute-compression-configuration">
            <section className="columns-selector">
              <div className="content">
                <BotFormLabel
                  label="Columns"
                  type="field"
                  tooltip={CdsModels.CDS_TOOL_TIP.columnMapping}
                />
                <StyledMultiselect
                  canReset
                  isClearable
                  isMulti
                  closeMenuOnSelect={false}
                  defaultValue={formState.columns}
                  values={formState.columns}
                  options={formState.headerChoices}
                  setOnChange={(v) => {
                    onFormStateUpdated({
                      value: v,
                      field: formFields.columns,
                    });
                  }}
                  getOptionValue={(option) => option.value}
                  isDisabled={formState.isLoading || disabled}
                  isInvalid={formState[formFields.columns]?.length === 0}
                  errorMessage={formState?.errors?.[formFields.columns]}
                />
              </div>
            </section>

            <section className="length-threshold-selector">
              <div className="content">
                <BotFormLabel
                  label="Length Threshold"
                  type="field"
                  tooltip={CdsModels.CDS_TOOL_TIP.additionalColumns}
                />
                <div className="form-control-length-threshold">
                  <div className="form-control-length-threshold-select">
                    <StyledMultiselect
                      closeMenuOnSelect
                      canReset
                      isClearable
                      isMulti={false}
                      values={formState[formFields.selectedThreshold]}
                      options={BotConstants.ATTRIBUTES_COMPRESSION_CHARACTERS_LENGTH}
                      setOnChange={(v) => {
                        onFormStateUpdated({
                          value: v,
                          field: formFields.selectedThreshold,
                        });
                      }}
                      getOptionValue={(option) => option.value}
                      errorMessage={formState?.errors?.[formFields.selectedThreshold]}
                      isInvalid={!formState[formFields.selectedThreshold]}
                      isDisabled={formState.isLoading || disabled}
                    />
                  </div>

                  {formState[formFields.selectedThreshold]?.value === 'custom' && (
                    <div className="form-control-length-threshold-value">
                      <Form.Control
                        type="number"
                        min={1}
                        autoFocus
                        placeholder="Enter a positive integer"
                        value={formState[formFields.customThresholdValue]}
                        disabled={formState.isLoading || disabled}
                        onChange={(v) => {
                          onFormStateUpdated({
                            value: v.target.value,
                            field: formFields.customThresholdValue,
                          });
                        }}
                        className={
                          formState.errors?.[formFields.customThresholdValue] ? 'is-invalid' : ''
                        }
                      />
                      {formState.errors?.[formFields.customThresholdValue] && (
                        <div className="invalid-feedback">
                          {formState.errors?.[formFields.customThresholdValue]}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

CdsAttributeCompressionForm.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  config: PropTypes.object.isRequired,
  setConfig: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onFormErrors: PropTypes.func,
};

CdsAttributeCompressionForm.defaultProps = {
  errors: {},
  onFormErrors: () => {},
};

export { CdsAttributeCompressionForm };
