import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _get from 'lodash/get';
import { isIE } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { ForgotPassword } from 'components/login/ForgotPassword';
import { Login } from 'components/login/Login';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { RootHooks } from 'helpers/RootHooks';
import { submitUserLogin } from 'store/actions/Actions';

const ViewLogin = ({ userLoginResponse, initialLocation, submitUserLogin }) => {
  const { activeUser } = RootHooks.useActiveUser();
  const [isAlreadyLoggedIn, setIsAlreadyLoggedIn] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (activeUser.email && getAuthToken()) {
      setIsAlreadyLoggedIn(true);
    } else {
      setIsAlreadyLoggedIn(false);
    }
  }, [JSON.stringify(activeUser)]);

  useEffect(() => {
    if (location?.state?.showForgotPassword) setShowForgotPassword(true);
  }, [location]);
  const redirectIfLoggedIn = () => {
    console.debug('Already logged in');
    return <Redirect to="/" />;
  };

  const renderHelmet = () => (
    <Helmet bodyAttributes={{ 'log-in': 'log-in' }}>
      <title>Login</title>
    </Helmet>
  );

  return isAlreadyLoggedIn ? (
    redirectIfLoggedIn()
  ) : (
    <div className="view-login">
      {renderHelmet()}
      <div className="view-login-wrapper">
        <div className={classNames({ 'view-login-wrapper-isIE': isIE })}>
          <div className={classNames('view-login-card', { 'view-login-card-isIE': isIE })}>
            {showForgotPassword ? (
              <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
            ) : (
              <Login
                setShowForgotPassword={setShowForgotPassword}
                userLoginResponse={userLoginResponse}
                submitUserLogin={submitUserLogin}
                initialLocation={initialLocation}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userLoginResponse: {
      status: state.UserLoginReducer.status,
      errorMessage: _get(state.UserLoginReducer, 'payload.msg') || null,
    },
    initialLocation: state.LocationChangeReducer.initialLocation,
  };
};

const mapDispatchToProps = {
  submitUserLogin,
};

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(ViewLogin);

export { ConnectedLogin };
