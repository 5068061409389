import styleVars from 'scss/vars.scss';

export const EXTERNAL_LINK = 'external-link';

export const SERVER_TRY_LIMIT = 5;

// Flash assessment Statuses
export const statusValueConstants = {
  READY_TO_SHARE: 'ready_to_share',
  READY_FOR_APPROVAL: 'ready_for_approval',
  READY: ['ready_for_approval', 'ready_to_share', 'accepted'],
  NEW: 'new',
  IN_PROCESS: 'in_process',
  CANCELED: 'canceled',
  COMPLETE: 'accepted',
  CLOSED: 'closed',
  FLASH_ASSESSMENT_PENDING: 'flash_assessment_pending',
  CLARIFICATION_NEEDED: 'clarification_needed',
  REJECTED: 'rejected',
};

export const statusValuesEnum = {
  new: 'New',
  in_process: 'In Process',
  flash_assessment_pending: 'Flash Assessment',
  canceled: 'Canceled',
  closed: 'Closed',
  clarification_needed: 'Clarification Needed',
  ready_to_share: 'Ready To Share',
  ready_for_approval: 'Ready For Approval',
  completed: 'Completed',
  accepted: 'Data Accepted',
  rejected: 'Rejected',
};

export const statusTransformFiles = {
  PENDING: { value: 3, label: 'Pending', color: `${styleVars.colors_orangeMain}` },
  FLASH_ASSESSMENT: {
    value: 15,
    label: 'Assessment',
    color: `${styleVars.colors_orangeMain}`,
  },
  READY_FOR_REVIEW: {
    value: 14,
    label: 'Ready For Review',
    color: `${styleVars.colors_orangeMain}`,
  },
  NEEDS_UPDATE: {
    value: 13,
    label: 'Needs Updates',
    color: '#dc143c',
  },
  CANCELED: {
    value: 11,
    label: 'Canceled',
    color: `${styleVars.colors_designGreen}`,
  },
  FILE_ACCEPTED: {
    value: 12,
    label: 'Accepted',
    color: `${styleVars.colors_designGreen}`,
  },
  SUPERSEDED: {
    value: 16,
    label: 'Superseded',
    color: `${styleVars.colors_designRed}`,
  },
};

export const assessmentTypes = {
  MANUAL: 'manual',
  AUTOMATED: 'automated',
};

// TODO: fix that the endpoint expects an int for value while the db and all other places want string
export const TRANSFORMATION_REQUIRED_STATUS = {
  NO: { value: 0, label: 'No' },
  YES: { value: 1, label: 'Yes' },
  DONE: { value: 2, label: 'Transformed' },
};

// Job Types: for filtering job types on the homescreen
export const JOBTYPES = {
  DATA_JOBS_ALL: 'all_data_jobs',
  DATA_JOBS_SENT: 'sent_data_jobs',
  DATA_JOBS_RECEIVED: 'received_data_jobs',
  DATA_JOBS_INTERNAL: 'internal_data_jobs',
  PASS_THRU_FILES: 'pass_thru_files',
};

// Tab names for facelifted Data Job Details page
export const TAB_NAMES = {
  TRANSFORMED: 'TRANSFORMED',
  ORIGINAL: 'ORIGINAL',
};

export const STANDARD_PROCESSING_TIME_DAYS_NOTE = 14;

export const MIN_DUE_DATE_WORKDAYS_AHEAD = 10;
