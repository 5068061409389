import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import zxcvbn from 'zxcvbn';

import './ViewSignUp.scss';
import BackboneLogo from 'assets/img/backbone-logo.svg';
import ScoreScale from 'components/ScoreScale';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';

const validatorMsgs = {
  INVALID: () => 'Invalid value',
  REQUIRED: () => 'This field is required',
  LENGTH_OVER: (name, len) => `${name} should be at most ${len} characters`,
  LENGTH_UNDER: (name, len) => `${name} should be at least ${len} characters`,
};

const regFormFields = {
  first_name: { key: 'first_name', label: 'First Name' },
  last_name: { key: 'last_name', label: 'Last Name' },
  email: { key: 'email', label: 'Email' },
  password: { key: 'password', label: 'Password' },
  confirm_password: { key: 'confirm_password', label: 'Confirm Password' },
  captcha_token: { key: 'captcha_token', label: 'Captcha Token' },
};

const regFormYupShape = yup.object().shape({
  first_name: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .max(100, validatorMsgs.LENGTH_OVER(regFormFields.first_name.label, 100))
    .min(3, validatorMsgs.LENGTH_UNDER(regFormFields.first_name.label, 3)),
  last_name: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .max(100, validatorMsgs.LENGTH_OVER(regFormFields.last_name.label, 100))
    .min(3, validatorMsgs.LENGTH_UNDER(regFormFields.last_name.label, 3)),
  email: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .email()
    .max(150, validatorMsgs.LENGTH_OVER(regFormFields.email.label, 100))
    .min(5, validatorMsgs.LENGTH_UNDER(regFormFields.email.label, 5)),
  password: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .max(150, validatorMsgs.LENGTH_OVER(regFormFields.password.label, 100))
    .min(8, validatorMsgs.LENGTH_UNDER(regFormFields.password.label, 8))
    .test('not-only-numbers', 'Password must not contain only numbers', (v) => !/^\d+$/.test(v))
    .test(
      'password-score',
      'Password is too simple or common.',
      (v, ctx) => ctx?.options?.context?.passwordScore >= 50
    ),
  confirm_password: yup
    .string(validatorMsgs.INVALID())
    .required(validatorMsgs.REQUIRED())
    .oneOf([yup.ref('password'), null], 'The password does not match'),
  captcha_token: yup
    .string(validatorMsgs.INVALID())
    .nullable()
    .required('Please solve the captcha correctly.'),
});

const initRegFormData = Object.values(regFormFields).reduce((a, v) => {
  return { ...a, [v.key]: '' };
}, {});

// TODO: Refactor, optimize funcs
// TODO: /login?verified=success/failure/already to show relevant message when activating from email link.
// TODO: /login and reset password -  handle error message when user is not yet activated - 2 messages known/unknown
// TODO: /login and reset password - integrate endpoint and show link to re-send activation mail if relevant
const ViewSignUp = () => {
  const reCaptchaRef = useRef();
  const reCaptchaWidgetId = useRef(null);
  const [validationMsgs, setValidationMsgs] = useState({});

  const [passwordScore, setPasswordScore] = useState(null);

  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const history = useHistory();
  const [regForm, setRegForm] = useState({ ...initRegFormData });
  const [isPasswordPlaintext, setIsPasswordPlaintext] = useState(false);

  const [submitRegStatus, setSubmitRegStatus] = useState(ActionStatusConstants.INITIAL);

  const generateValidationMsgs = useCallback(
    (yupErrObj, field) => {
      const output = field?.length ? { ...validationMsgs } : {};

      if (field?.length && field in output) {
        delete output[field];
      }

      if (yupErrObj?.inner?.length) {
        yupErrObj.inner.forEach((item) => {
          if (!field || (field && field === item.path)) {
            if (item.path && !Object.keys(output).includes(item.path)) {
              output[item.path] = item;
            }
          }
        });
      }

      return output;
    },
    [JSON.stringify(validationMsgs)]
  );

  const doValidateForm = (field) => {
    let _validationMsgs = {};

    try {
      regFormYupShape.validateSync(regForm, { abortEarly: false, context: { passwordScore } });
    } catch (e) {
      _validationMsgs = generateValidationMsgs(e, field);
    }

    setValidationMsgs(_validationMsgs);

    return _validationMsgs;
  };

  const setRegFormField = useCallback(
    (f, v) => {
      if (Object.keys(regFormFields).includes(f)) {
        setRegForm({ ...regForm, [f]: v });
      }
    },
    [JSON.stringify(regForm)]
  );

  useEffect(() => {
    setRegForm({ ...regForm, [regFormFields.captcha_token.key]: reCaptchaToken });
  }, [reCaptchaToken, JSON.stringify(regForm)]);

  useEffect(() => {
    const customDict = [];
    if (regForm?.first_name) customDict.push(regForm.first_name);
    if (regForm?.last_name) customDict.push(regForm.last_name);
    if (regForm?.email?.length >= 5) {
      const splitMail = regForm.email.split('@');
      if (splitMail.length === 2 && splitMail[0]?.length > 3) {
        customDict.push(splitMail[0]);
      }
    }

    let score = null;

    const pwd = regForm?.password;

    if (pwd?.length) {
      score = Math.min(pwd.length * 2, 20);

      if (pwd.length >= 8 && !/^\d+$/.test(pwd)) {
        const pResult = zxcvbn(pwd, customDict);
        if (pResult?.score > 0) {
          score = pResult.score * 25;
        }
      }
    }

    setPasswordScore(score);
  }, [regForm?.first_name, regForm?.last_name, regForm?.email, regForm?.password, setRegFormField]);

  useEffect(() => {
    if (reCaptchaRef.current && !reCaptchaWidgetId.current) {
      if (process.env.REACT_APP_GOOGLE_RECAPTCHA_ID?.length && window.grecaptcha) {
        reCaptchaWidgetId.current = window.grecaptcha.enterprise.render(reCaptchaRef.current, {
          sitekey: process.env.REACT_APP_GOOGLE_RECAPTCHA_ID,
          callback: setReCaptchaToken,
          'expired-callback': () => setReCaptchaToken(null),
          'error-callback': () => setReCaptchaToken(null),
        });
      }
    }

    return () => {
      if (reCaptchaWidgetId.current) {
        window.grecaptcha.enterprise.reset(reCaptchaWidgetId.current);
      }
    };
  }, []);

  const passwordPlaintextSwitch = (
    <FontAwesomeIcon
      onClick={() => setIsPasswordPlaintext(!isPasswordPlaintext)}
      className="password-toggle-icon"
      icon={['far', isPasswordPlaintext ? 'eye-slash' : 'eye']}
    />
  );

  const doSubmitRegForm = () => {
    const _validationMsgs = doValidateForm();
    if (_validationMsgs && Object.keys(_validationMsgs).length) {
      toast.error('Please fill in all required fields correctly.');
      return;
    }

    const formData = {
      first_name: regForm.first_name,
      last_name: regForm.last_name,
      email: regForm.email,
      password: regForm.password,
      captcha_token: regForm.captcha_token,
    };

    setSubmitRegStatus(ActionStatusConstants.ISBUSY);
    setTimeout(() => {
      setReCaptchaToken(null);
      setRegForm({ ...initRegFormData });

      if (reCaptchaWidgetId.current) {
        window.grecaptcha.enterprise.reset(reCaptchaWidgetId.current);
      }

      setSubmitRegStatus(ActionStatusConstants.SUCCESS);
      console.debug('Mockup: Submitted reg form', formData);
    }, 2000);

    // TODO: Integrate to BE
    // TODO: On success display message from BE
  };

  const renderField = useCallback(
    ({ key, placeholder, type, afterField, labelAppend }) => {
      if (!(key && Object.keys(regFormFields).includes(key))) {
        console.error('No form key', key);
        return null;
      }

      return (
        <Form.Group className={`input-field input-field-${key}`} controlId={`regForm.${key}`}>
          <Form.Label>
            {regFormFields[key].label}
            {labelAppend}
          </Form.Label>
          <Form.Control
            as="input"
            type={type ?? 'text'}
            value={regForm[key]}
            placeholder={
              placeholder?.length ? placeholder : `Please enter ${regFormFields[key].label}`
            }
            onChange={(e) => setRegFormField(key, e.target.value)}
            onBlur={() => doValidateForm(key)}
            isInvalid={key in validationMsgs}
            maxLength="100"
          />
          {afterField}
          <Form.Control.Feedback type="invalid">
            {key in validationMsgs && validationMsgs[key]?.message}
          </Form.Control.Feedback>
        </Form.Group>
      );
    },
    [doValidateForm, JSON.stringify(regForm), setRegFormField, JSON.stringify(validationMsgs)]
  );

  const isInvalidCaptcha =
    regFormFields.captcha_token.key in validationMsgs &&
    validationMsgs[regFormFields.captcha_token.key]?.message;

  return (
    <div className="view-login">
      <Helmet bodyAttributes={{ 'log-in': 'log-in' }}>
        <title>Sign Up to Get Started</title>
      </Helmet>
      <div className="view-login-wrapper login-register">
        <div className="view-login-card">
          {submitRegStatus === ActionStatusConstants.SUCCESS ? (
            <>
              <h1 className="view-login-heading">Success!</h1>
              <h2 className="view-login-subheading">
                {/* TODO: Messsage depends on success response */}
                Your registration request was received successfully.
                <br />
                Check your email for further instructions.
              </h2>

              <div className="actions-links">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => history.push('/login')}
                >
                  Back to Login
                </button>
              </div>
            </>
          ) : (
            <>
              <h1 className="view-login-heading">Automate Your Product Data</h1>
              <h2 className="view-login-subheading">
                Please fill in your data
                <br /> to Join the Fastest Growing Data Network
              </h2>

              <div className="reg-form">
                {renderField({ key: regFormFields.first_name.key })}
                {renderField({ key: regFormFields.last_name.key })}
                {renderField({ key: regFormFields.email.key, type: 'email' })}
                {renderField({
                  key: regFormFields.password.key,
                  type: isPasswordPlaintext ? 'text' : 'password',
                  afterField: !!passwordScore && <ScoreScale value={passwordScore} />,
                  labelAppend: (
                    <>
                      {' '}
                      (
                      <span
                        className="link"
                        onClick={() => setIsPasswordPlaintext(!isPasswordPlaintext)}
                      >
                        {isPasswordPlaintext ? 'hide' : 'show'}
                      </span>
                      )
                    </>
                  ),
                })}
                {renderField({
                  key: regFormFields.confirm_password.key,
                  type: isPasswordPlaintext ? 'text' : 'password',
                  placeholder: 'Please confirm password',
                  fieldAppend: passwordPlaintextSwitch,
                })}
              </div>
              {/* TODO: Show captcha only if all other fields are valid(no err msgs for other fields) */}
              <div className="recaptcha-wrap">
                <div
                  className={classNames('recaptcha-container', { 'is-invalid': isInvalidCaptcha })}
                  ref={reCaptchaRef}
                />
                {isInvalidCaptcha && (
                  <div className="invalid-feedback">
                    {validationMsgs[regFormFields.captcha_token.key].message}
                  </div>
                )}
              </div>
              <div className="actions">
                <button
                  className="btn btn-primary btn-lg btn-sign-up"
                  type="button"
                  onClick={() => doSubmitRegForm()}
                >
                  Sign Up
                </button>
              </div>
              <div className="actions-links">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => history.push('/login')}
                >
                  Back to Login
                </button>
              </div>
            </>
          )}
          <div className="view-login-branding">
            <a href="https://www.backbone.ai/">
              <img alt="BackboneAI logo" src={BackboneLogo} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewSignUp };
