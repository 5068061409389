import React, { useCallback, useEffect, useState, useRef } from 'react';

import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingLogoSpinner } from 'components/common/LoadingSpinner/LoadingLogoSpinner';
import { Search } from 'components/common/Search/Search';
import { useIsMounted } from 'helpers/useIsMounted';

import { BeforeAfterImgTableDef } from './BeforeAfterImgTableDef';

import './BeforeAfterImgTable.scss';

/**
 * Renders a searchable list of before/after image links showing the intermediate steps
 * the image passess through.
 *
 * @param {number} id IDS job ID
 *
 * @return render
 */
const BeforeAfterImgTable = ({ id }) => {
  const isMounted = useIsMounted();

  // Before/After data
  const [imgVersionData, setImgVersionData] = useState(null);
  const [isLoadingImgVersionData, setIsLoadingImgVersionData] = useState(false);
  const [isEmptyImgVersionData, setIsEmptyImgVersionData] = useState(false);

  // Search component helper state
  const [localSearch, setLocalSearch] = useState('');
  const [search, setSearch] = useState('');

  // Used to check whether table data is empty on first load(w/o filtering)
  const isInitialLoad = useRef(true);

  const doLoadImgVersionData = useCallback((_id, textSearch, _isMounted) => {
    if (!_isMounted.current) {
      return;
    }

    if (!_id) {
      setImgVersionData(null);
      setIsLoadingImgVersionData(false);
      return;
    }

    setIsLoadingImgVersionData(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/process-job/before-after-result/${_id}`,
      params: textSearch?.length ? { name: textSearch } : null,
      onSuccess: (res) => {
        if (_isMounted.current) {
          if (res?.data?.length) {
            isInitialLoad.current = false;
            setImgVersionData(res.data.map((item, index) => ({ id: index, ...item })));
          } else {
            setImgVersionData(null);
            if (isInitialLoad.current) {
              isInitialLoad.current = false;
              setIsEmptyImgVersionData(true);
            }
          }
        }
      },
      onEnd: () => {
        if (_isMounted.current) {
          setIsLoadingImgVersionData(false);
        }
      },
    });
  }, []);

  // Load img version data on load
  useEffect(() => {
    doLoadImgVersionData(id, null, isMounted);
  }, [doLoadImgVersionData, id, isMounted]);

  return (
    <div
      className={classNames('before-after-img-table-wrap', {
        'is-loading': isLoadingImgVersionData,
        'is-empty': isEmptyImgVersionData,
      })}
    >
      <div className="title">
        <h2>Before/After Image Samples</h2>
      </div>
      {isEmptyImgVersionData ? (
        <div className="empty-notice">
          <Alert variant="primary">No sample data available.</Alert>
        </div>
      ) : (
        <>
          <div className="before-after-img-search">
            <Search
              setLocalSearch={setLocalSearch}
              search={search}
              setSearch={setSearch}
              placeholder="Search for a file by name"
              withButton
              withClear
              onClear={() => {
                setSearch('');
                setLocalSearch('');
                doLoadImgVersionData(id, null, isMounted);
              }}
              onSearchSubmit={() => doLoadImgVersionData(id, localSearch, isMounted)}
            />
          </div>
          {!isLoadingImgVersionData && !imgVersionData?.length ? (
            <div className="empty-notice">
              <Alert variant="primary">No images found.</Alert>
            </div>
          ) : (
            <div className="before-after-img-table">
              <BootstrapTable
                bordered={false}
                bootstrap4
                keyField="id"
                data={imgVersionData?.length ? imgVersionData : []}
                columns={BeforeAfterImgTableDef.columns}
                defaultSorted={[{ dataField: 'id', order: 'asc' }]}
              />
            </div>
          )}
        </>
      )}
      {isLoadingImgVersionData && (
        <div className="loading-spinner-wrap">
          <LoadingLogoSpinner />
        </div>
      )}
    </div>
  );
};

export { BeforeAfterImgTable };
