import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { DraggableTable } from 'components/common/TableDragRows/DraggableTable';

/**
 * Component to select and arrange headers
 *
 * @param selectedHeaders {{value: string, label: string}[]} List of selected headers
 * @param setSelectedHeaders Callback to set the selected headers
 * @param headerChoices {string[]} List of header choices
 * @param disabled {boolean} If true, the header selector cannot be changed
 * @param canMove {boolean} If true, the headers can be rearranged
 * @param isInvalid {boolean} If true, the header selector is invalid
 * @param showOrderControl {boolean} If true, the ordering control is shown
 * @param errorMessage {string} Error message to display
 *
 * @returns {JSX.Element} Component
 */
const HeaderSelector = ({
  selectedHeaders,
  setSelectedHeaders,
  headerChoices,
  disabled,
  canMove = true,
  isInvalid = false,
  showOrderControl = true,
  errorMessage = undefined,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (headerChoices?.length) {
      setOptions(
        headerChoices.map((item) => {
          return { value: item, label: item, name: item };
        })
      );
    } else {
      setOptions([]);
    }
  }, [headerChoices, setSelectedHeaders]);

  return (
    <>
      <div className="header-selector">
        <StyledMultiselect
          defaultValue={selectedHeaders}
          values={selectedHeaders}
          options={options}
          setOnChange={setSelectedHeaders}
          getOptionValue={(option) => option.value}
          isMulti
          isClearable
          isSearchable
          closeMenuOnSelect={false}
          canReset
          isDisabled={disabled}
          placeholder="Select a column to add and arrange."
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
      </div>
      {showOrderControl && (
        <div className="table-wrapper">
          <DraggableTable
            headers={[{ header: 'Column Name', key: 'name', id: 1 }]}
            data={selectedHeaders}
            setData={setSelectedHeaders}
            actions={{ move: canMove, delete: false }}
          />
        </div>
      )}
    </>
  );
};

HeaderSelector.propTypes = {
  selectedHeaders: PropTypes.array.isRequired,
  setSelectedHeaders: PropTypes.func.isRequired,
  headerChoices: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  canMove: PropTypes.bool,
  isInvalid: PropTypes.bool,
  showOrderControl: PropTypes.bool,
  errorMessage: PropTypes.string,
};

HeaderSelector.defaultProps = {
  canMove: true,
  isInvalid: false,
  showOrderControl: true,
  errorMessage: undefined,
};

export { HeaderSelector };
