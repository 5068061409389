import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _indexOf from 'lodash/indexOf';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';

import { generalAttributeSections, generalAttributeColumns } from 'constants/ProductConstants';

/**
 * Returns an array of all attribute section labels.
 *
 * @returns array
 */
export const getAllLabels = () => {
  return Object.keys(generalAttributeSections).map((key) => generalAttributeSections[key].label);
};

/**
 * Sorts attribute sections based on their labels in the specified order (asc, desc, default)
 *
 * @param {array} sections
 * @param {string} direction
 * @returns array
 */
export const sortAttributeSections = (sections, direction) => {
  let _sections = _cloneDeep(sections);

  switch (direction) {
    case 'asc':
      _sections.sort((a, b) => (a.label > b.label ? 1 : -1));
      break;
    case 'desc':
      _sections.sort((a, b) => (a.label < b.label ? 1 : -1));
      break;
    case 'default': {
      const order = getAllLabels();

      _sections = _sortBy(_sections, (obj) => {
        return _indexOf(order, obj.label);
      });
      break;
    }
    default:
      break;
  }

  return _sections;
};

/**
 * Returns all columns that fall under "general attributes" and should be modified
 *
 * @returns array
 */
const getAllColumns = () => {
  const columns = _cloneDeep(generalAttributeColumns);
  const sections = _cloneDeep(generalAttributeSections);

  Object.keys(sections).forEach((key) => columns.push(...sections[key].columns));

  return columns;
};

/**
 * Modifies attributes passed into the function to match the "key, value, name" structure to be parsed in the front-end.
 *
 * @see BC-2637
 * @param {object} data - The data to morph
 * @param {array} columns - The columns/attributes to check against (general object)
 * @param {bool} asAttribute - Specify whether this is a nested attribute or a higher-level database column
 * @returns
 */
const morphAttributes = (data, columns, asAttribute = false) => {
  let _columns = _cloneDeep(columns);
  const _data = _cloneDeep(data);

  if (!columns) return _data;

  _columns = asAttribute
    ? Object.keys(_data).map((entry) => {
        if (entry.startsWith('country')) return entry;
        return _columns.find((column) => column.key === entry);
      })
    : _columns.filter((column) => column._column);

  _columns.forEach((column) => {
    if (!column) return;

    let _column = _cloneDeep(column);

    // NOTE: This was written before displaying attributes was built in, so it handles any number of countries, not just up to 5 like explicitly specified.
    if (typeof _column === 'string' && _column.startsWith('country')) {
      const split = _column.split('_');
      const country = `${split[0].charAt(0).toUpperCase() + split[0].slice(1)} ${split[1]}`;
      _column = {
        key: _column,
        value: _data[_column],
        name: country,
      };
    }

    const { key } = _column;
    let value,
      morphedData = null;
    if (_data[key]) {
      value = _data[key];
      morphedData = {
        name: _column.name,
        key: _column.key,
        value,
      };
      if (morphedData.value.constructor === Object) {
        // Because we are iterating through a column's own nested data, we want to specify that these are "attributes" of a higher level object, and not primary columns themselves.
        morphedData.value = morphAttributes(morphedData.value, getAllColumns(), true);
      }
    } else {
      morphedData = {
        name: _column.name,
        key: _column.key,
        value,
      };
    }

    _data[key] = morphedData;
  });

  return _data;
};

/**
 * Modify individual sections after morphing base data
 *
 * @param {array} sections
 * @param {array} additionalData
 * @returns array
 */
const modifySections = (sections, additionalData) => {
  let _sections = _cloneDeep(sections);

  // Specifying the "_hide" property with a value of true will prevent it from being rendered in the table, but still include it in the attributes object to be used by other components
  _sections = _sections.map((section) => {
    const _section = section;
    switch (_section.label) {
      case generalAttributeSections.PRODUCT_ID.label:
        _section._hide = true;
        break;
      case generalAttributeSections.CATEGORIZATION.label:
        _section._hide = true;
        break;
      case generalAttributeSections.PRICE_INFO.label:
        _section._hide = true;
        break;
      case generalAttributeSections.ADDITIONAL_DATA.label:
        _section.columns = additionalData;
        break;
      default:
        break;
    }
    return _section;
  });

  return _sections;
};

/**
 * Flattens an object with keys, returning a new object with strings representing nested proprties
 * Ex:
 * {id: 3, info: { name: "Roman", email: "roman@backbone.ai" }}
 * ->
 * {"id": 3, "info.name": "Roman", "info.email": "roman@backbone.ai"}
 *
 * @param {object} obj
 * @param {string} prefix
 * @returns object
 */
const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (typeof obj[k] === 'object' && obj[k] !== null && Object.keys(obj[k]).length > 0)
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

/**
 * Returns all general and processed attributes from a specified product/model
 *
 * @param {object} data - The product to pull values from
 * @returns array
 */
const getGeneralAttributes = (data) => {
  const flattenedData = flattenObject(data);

  const sections = Object.keys(generalAttributeSections).map((section) => {
    const sectionObj = {
      label: generalAttributeSections[section].label,
      columns: [],
    };

    const { columns } = generalAttributeSections[section];
    sectionObj.columns = columns.map((column) => {
      const _column = _cloneDeep(column);
      const attributeString = `${_column.key}.value`;

      Object.keys(flattenedData).forEach((key) => {
        if (key.includes(attributeString)) _column.value = flattenedData[key];
      });

      return _column;
    });

    return sectionObj;
  });

  return sections;
};

/**
 * Returns the root node (object) within a nested array
 *
 * @param {array} data
 * @returns object
 */
const getRootNode = (data) => {
  if (Array.isArray(data[0])) return getRootNode(data[0]);

  return data[0];
};

/**
 * Modifies/parses the additional_data object to return an array of values that are in proper "key, name, value" format for the front-end table.
 *
 * @param {*} data
 * @returns
 */
const morphAdditionalData = (dataVar) => {
  let data = dataVar;
  if (typeof data === 'undefined' || data === null || Object.keys(data).length === 0) return [];
  if (typeof data === 'object') {
    data = [data];
  }
  const additionalData = [];
  const rootNode = getRootNode(data);
  const miscellaneousAttributes = [
    { key: 'source_info', name: 'Source Info' },
    { key: 'url', name: 'URL' },
  ];

  miscellaneousAttributes.forEach((attribute) => {
    if (rootNode && rootNode.hasOwnProperty(attribute.key))
      additionalData.push({
        key: attribute.key,
        name: attribute.name,
        value: rootNode[attribute.key],
      });
  });

  // Map over the "attributes" array within the additional_data object
  const mapAttributes = (attributes) => {
    attributes.forEach((attribute) => {
      // Check if the given attribute has its own key/name pair
      if (attribute.hasOwnProperty('key'))
        additionalData.push({
          key: attribute.key,
          name: attribute.name,
          value: attribute.value,
        });
      else additionalData.push(attribute);

      // Check if the attribute has nested children attributes
      if (attribute.hasOwnProperty('children')) {
        const { children } = attribute;
        let hasArray = false;

        // Sometimes children can also be nested deeply so this check is made to map over the array entries in the case an object isn't the child
        children.forEach((child) => {
          if (Array.isArray(child)) hasArray = true;
        });

        if (hasArray) {
          children.forEach((child) => {
            mapAttributes(child);
          });
        } else {
          mapAttributes(children);
        }
      }
    });
  };

  Object.keys(rootNode).forEach((key) => {
    if (key === 'attributes') {
      const attributes = rootNode[key];
      mapAttributes(attributes);
    }
  });

  return additionalData;
};

/**
 * @deprecated
 * Transforms legacy product model into proper model.
 * // TODO: Remove after refactoring backend
 * // TODO: Do not add more logic here!
 *
 * @param legacyItem
 */
export const transformLegacyProduct = (item) => {
  const legacyItem = item._legacy_product;

  // validate
  if (
    !(
      !_isEmpty(legacyItem.code) &&
      legacyItem.id >= 0 &&
      legacyItem.status >= 0 &&
      // && !_isEmpty(legacyItem.updated_at)
      !_isEmpty(legacyItem.created_at) &&
      legacyItem.manufacturer &&
      legacyItem.manufacturer.id >= 0 &&
      !_isEmpty(legacyItem.manufacturer.name)
    )
  ) {
    console.error('invalid legacy item', legacyItem);
    return null;
  }

  let newItem = _cloneDeep(item);

  // TODO: This logic should be moved to backend
  newItem = morphAttributes(newItem, getAllColumns());
  // This must be called after morphing the attributes, since it relies on a proper object/attribute structure.
  newItem.general_attributes = modifySections(
    getGeneralAttributes(newItem),
    morphAdditionalData(newItem.additional_data)
  );

  return newItem;
};
