import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';

import { LoadingLogoSpinner } from 'components/common/LoadingSpinner/LoadingLogoSpinner';

import { ContentBotEngineLogTableDef } from './ContentBotEngineLogTableDef';

import './ContentBotEngineLogTable.scss';

/**
 * Renders the main log table that's showing the existing IDS jobs.
 *
 * @param {array} logData List of IDS data job objects
 * @param {boolean} isLogDataEmpty Whether the result from the server is empty
 * @param {function} onRunJob Handler
 * @param {function} onCancelJob Handler
 * @param {boolean} isLoading Loading state
 * @param {object} tableState object containing table state properties
 * @param {function} onTableStateChanged callback for whenever the state of the table is changing
 * @param onGetStarted callback for when the user clicks the "Get Started" button
 *
 * @return render
 */
export const ContentBotEngineLogTable = ({
  logData,
  isLogDataEmpty,
  onCancelJob,
  onRunJob,
  isLoading,
  tableState,
  onTableStateChanged,
  onGetStarted = undefined,
}) => {
  const history = useHistory();

  // Log data decorated with needed functionality
  const [logDataDecorated, setLogDataDecorated] = useState(null);

  const goToDetails = useCallback(
    ({ jobId }) => history.push(`/image-databot-suite/${jobId}`, { from: history.location }),
    [history]
  );
  const onTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      onTableStateChanged(page, sizePerPage);
    }
  };

  // Decorate log data with needed functionality - handlers, states, etc.
  useEffect(() => {
    setLogDataDecorated(
      logData?.length
        ? logData.map((item) => {
            return {
              ...item,
              onRunJob,
              onCancelJob,
              goToDetails,
              isLoading,
            };
          })
        : null
    );
  }, [logData, onCancelJob, onRunJob, goToDetails, isLoading]);

  return (
    <div
      className={classNames('img-bot-engine-log-table', {
        'is-loading': isLoading && logDataDecorated?.length === 0,
        'is-empty': isLogDataEmpty,
      })}
    >
      {isLogDataEmpty ? (
        <div className="empty-notice">
          <Alert variant="primary">
            No CDS Jobs found.
            {onGetStarted && (
              <span>
                <button
                  type="button"
                  className="btn btn-primary btn-request-data btn-start-first-ids-job"
                  onClick={onGetStarted}
                >
                  Get Started!
                </button>
              </span>
            )}
          </Alert>
        </div>
      ) : (
        <BootstrapTable
          remote
          bordered={false}
          bootstrap4
          keyField="id"
          data={logDataDecorated?.length ? logDataDecorated : []}
          columns={ContentBotEngineLogTableDef.columns}
          defaultSorted={[{ dataField: 'id', order: 'desc' }]}
          onTableChange={(type, newState) => onTableChange(type, newState)}
          pagination={paginationFactory({
            totalSize: Math.min(tableState.total, tableState.itemsLimit),
            page: tableState.page,
            sizePerPageList: [],
            sizePerPage: tableState.pageSize,
            hidePageListOnlyOnePage: true,
          })}
        />
      )}
      {isLoading && logDataDecorated?.length === 0 && (
        <div className="loading-spinner-wrap">
          <LoadingLogoSpinner />
        </div>
      )}
    </div>
  );
};
