import React from 'react';

import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import { BOT_STATUSES } from 'constants/BotConstants';

import './AltTextCreatorBot.scss';
import { AltTextCreatorLog } from './AltTextCreatorLog';

/**
 * Alt Text Creator databot config panel
 *
 * @param {string} botStatusId Databot status (run) ID
 * @param {string} slug Databot slug
 * @param {string} status Databot run status
 * @param {bool} requiresApproval Whether or not the databot requires approval for changes
 * @param {bool} hasDownloadedReport Whether or not the active user has downloaded the changes report
 * @param {bool} reportName The report's file name
 * @param {function} handleRunBot Event handler
 * @param {function} handleCancelBot Event handler
 * @param {object} additionalData Databot additional data
 * @param {function} handleApproveChanges Event handler for product auxiliary process
 * @param {function} handleRejectChanges Event handler for product auxiliary process
 * @param {object} configData Databot config object
 * @param {object} setConfigData Databot config object setter
 * @param {object} botData Complete bot data
 * @return render
 */
const AltTextCreatorBot = ({
  botStatusId,
  slug,
  status,
  hasDownloadedReport,
  reportName,
  handleRunBot,
  handleCancelBot,
  additionalData,
  details,
  handleApproveChanges,
  handleRejectChanges,
}) => {
  return (
    <div className="alt-text-creator-bot-wrap">
      <DatabotConfigPanel
        headingStatsData={[
          { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
          {
            label: 'Total Products',
            value: additionalData?.total_products ?? 'N/A',
            tooltip: 'Number of unique products on BackboneAI',
          },
        ]}
        botStatusId={botStatusId}
        slug={slug}
        status={status}
        requiresApproval={false}
        hasDownloadedReport={hasDownloadedReport}
        reportName={reportName}
        bodyContent={
          <p className="description">
            The Alt Text Creator databot builds an alternate description for each image that is
            stored in the database by using the description tied to the image&apos;s product.
            <br />
            If there is not a description present for the product&apos;s image, the Alt Text
            won&apos;t be created for the associated images.
          </p>
        }
        title="Alt Text Creator Databot"
        subtitle={
          <>
            Start your data automation journey by automatically generating your image&apos;s alt
            text. Our Alt Text Creator Databot generates a report for you to approve the newly
            generated alternate descriptions.
          </>
        }
        hideBodyActions={[
          BOT_STATUSES.PENDING,
          BOT_STATUSES.RUNNING,
          BOT_STATUSES.AWAITING_APPROVAL,
        ].includes(status)}
        confirmDialogTitle="Confirm Alt Text Creator Databot"
        confirmDialogBody={
          <p>
            You are about to:
            <br />
            <b>Accept changes being made to your image&apos;s alternate descriptions</b>
          </p>
        }
        onApproveChanges={handleApproveChanges}
        onRejectChanges={handleRejectChanges}
        onRun={() => handleRunBot(true)}
        onCancel={() => handleCancelBot()}
        enableIncorrectDataReport
      />
      {details?.length > 0 && <AltTextCreatorLog details={details} />}
    </div>
  );
};

export { AltTextCreatorBot };
