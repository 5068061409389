const WS_CHANNELS = {
  NOTIFICATION: 'notification',
  COMMENT: 'comment',
  BOTS: 'bots',
};

const WsConstants = {
  WS_CHANNELS,
};

export { WsConstants };
