import './DistributorJobLinks.scss';

import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { priorityValuesEnum, statusValuesEnum } from 'helpers/RenderUtils';

/**
 * DistributorJobLinks.
 *
 * @param {object} - props
 * @param {object} - props.data - data object for parent data request object
 * @callback - props.onUpdate - function called to update dist job links - param is array of distributor job ids
 * @param {boolean} - props.editable - allowed to perform actions
 */
const DistributorJobLinks = ({ data, onUpdate, editable, confirmDeletePrompt }) => {
  const doRemoveLink = (item) => {
    if (!(item && data && data.length));
    const updatedData = data.filter((_item) => {
      if (_item.link_type === 'target') return _item.target_id !== item.id;
      return _item.source_id !== item.id;
    });
    if (typeof onUpdate === 'function') {
      onUpdate(updatedData);
    }
  };

  if (!(data && data.length)) {
    return null;
  }

  const priorityVariant = (item) => {
    switch (priorityValuesEnum[item.priority]) {
      case priorityValuesEnum[1]:
        return 'success';
      case priorityValuesEnum[2]:
        return 'warning';
      case priorityValuesEnum[3]:
        return 'danger';
      default:
        return 'primary';
    }
  };

  /**
   * DataRequestCard - this is an object representing an internal data request link item - created to wrap wether each link is clickable based on endpoint response
   *
   * @param {object} props
   * @param {int} props.i - integer key value in index array
   * @param {object} props.item - data request link object
   */
  const DataRequestCard = ({ i, item }) => {
    const [linkInfo, setLinkInfo] = useState({});
    const [canViewModifyLink, setCanViewModifyLink] = useState(
      item && item.link_type === 'source' ? item.source_clickable : item.target_clickable
    );

    useEffect(() => {
      let clickableKey;
      if (item.link_type === 'source') {
        const {
          source_id: id,
          source_name: name,
          source_priority: priority,
          source_status: status,
          source_clickable: clickable,
        } = item;
        clickableKey = 'source_clickable';

        setLinkInfo({
          type: 'source',
          id,
          name,
          priority,
          status,
          clickable,
        });
      } else {
        const {
          target_id: id,
          target_name: name,
          target_priority: priority,
          target_status: status,
          target_clickable: clickable,
        } = item;
        clickableKey = 'target_clickable';

        setLinkInfo({
          type: 'target',
          id,
          name,
          priority,
          status,
          clickable,
        });
      }

      if (item && !Object.keys(item).includes(clickableKey)) {
        setCanViewModifyLink(true);
      }
    }, [item]);

    // convenience class so that children el didn't have to be copy pasted
    const LinkWrapper = ({ item, canViewModifyLink, children }) =>
      canViewModifyLink ? (
        <a
          className="job-link"
          id="job-link"
          key={`${item.id}_job-link`}
          href={`/data-request/${linkInfo.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      ) : (
        <span className="job-link--disable" id="job-link" key={`${item.id}_job-link-disabled`}>
          {children}
        </span>
      );

    return (
      <li className="job-link-item" key={i}>
        {canViewModifyLink ? (
          <LinkWrapper item={item} canViewModifyLink={canViewModifyLink} key={i}>
            <div className="job-link-badge-wrapper">
              <Badge className="job-link-badge" variant={priorityVariant(linkInfo)}>
                {priorityValuesEnum[linkInfo.priority]}
              </Badge>
              <Badge className="job-link-badge-status">{statusValuesEnum[linkInfo.status]}</Badge>
            </div>
            <span className="job-link-text" htmlFor="job-link">
              {`${linkInfo.id} - ${linkInfo.name}`}
            </span>
          </LinkWrapper>
        ) : null}
        {canViewModifyLink && editable && (
          <ConfirmDialog
            onConfirm={() => doRemoveLink(linkInfo)}
            headerContent="Remove Link?"
            bodyContent={`Are you sure you want to remove link to job "${linkInfo.id} - ${linkInfo.name}"?`}
          >
            {({ onClick }) => (
              <button
                type="button"
                title="Remove link"
                className="btn btn-secondary btn-sm action action-delete mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  if (confirmDeletePrompt) {
                    onClick();
                  } else {
                    doRemoveLink(linkInfo);
                  }
                }}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={['far', 'unlink']} />
                </span>
              </button>
            )}
          </ConfirmDialog>
        )}
      </li>
    );
  };

  return (
    <section className="distributor-job-links">
      <ul className="job-links-list">
        {data.map((item, i) => (
          <DataRequestCard key={i} item={item} i={i} />
        ))}
      </ul>
    </section>
  );
};

DistributorJobLinks.defaultProps = {
  data: null,
  editable: true,
  confirmDeletePrompt: true,
};

DistributorJobLinks.propTypes = {
  data: PropTypes.array,
  onUpdate: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  confirmDeletePrompt: PropTypes.bool,
};

export { DistributorJobLinks };
