import React, { useEffect, useState, useCallback } from 'react';

import queryString from 'query-string';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import * as ApiCalls from '../../../../api/ApiCalls';
import ChagGptLogo from '../../../../assets/img/chatgpt-logo.svg';
import { BOT_SLUGS, BotConstants } from '../../../../constants/BotConstants';
import { toast } from '../../../../helpers/ToastUtils';
import { useIsMounted } from '../../../../helpers/useIsMounted';
import { getRootMenuPortalTarget } from '../../../../helpers/Utils';
import { StyledMultiselect } from '../../../common/StyledMultiselect/StyledMultiselect';
import { DatabotConfigPanel } from '../../DatabotConfigPanel/DatabotConfigPanel';
import { SmallSelectFileSection } from '../common/SmallSelectFileSection/SmallSelectFileSection';
import { HeaderSelector } from '../DescriptionBuilderBot/private/HeaderSelector';
import './GptDescriptionGeneratorBot.scss';
import { MappingComponent } from './private/MappingComponent';

const MANDATORY_COLUMN_NAMES = [
  'Item Number',
  'Manufacturer',
  'Brand',
  'Product Type',
  'Product Series',
];

const GptDescriptionGeneratorBot = ({
  botStatusId,
  slug,
  status,
  handleRunBot,
  handleCancelBot,
  additionalData,
  setConfigData,
}) => {
  // TODO: this is an prototype version. Must review and rework.
  const menuPortalTarget = getRootMenuPortalTarget();

  const location = useLocation();
  const dataJobId = queryString.parse(location?.search).data_request_id;

  const isMounted = useIsMounted();

  // Holds the values users are able to select from
  const [filesList, setFilesList] = useState(null);
  const [originalHeadersList, setOriginalHeadersList] = useState(null);
  const [headerChoices, setHeaderChoices] = useState(null);
  const [selectedFileHasError, setSelectedFileHasError] = useState(false);
  const [uploadedFilesMessageError, setUploadedFilesMessageError] = useState('');

  // Information holding user selections
  const [selectedFile, setSelectedFile] = useState(null);
  const [newDescription, setNewDescription] = useState('');
  const [newDescriptionError, setNewDescriptionError] = useState(undefined);
  const [selectedTone, setSelectedTone] = useState(null);
  const [selectedLength, setSelectedLength] = useState(null);
  const [additionalLanguages, setAdditionalLanguages] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [headerMappingChoices, setHeaderMappingChoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validSelections, setValidSelections] = useState(false);
  const [headerMapping, setHeaderMapping] = useState({});

  // Reset file lists on props change
  useEffect(() => {
    setFilesList(additionalData?.attachments ?? null);
  }, [additionalData]);

  const doLoadHeaders = useCallback(
    (file) => {
      if (file?.id) {
        setIsLoading(true);

        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.GET,
          urlPath: `/data-requests/attachment/${file.id}/headers`,
          params: {
            bot_slug: BOT_SLUGS.GPT_DESCRIPTION_GENERATOR, // if not provided, will not validate row count
          },
          onSuccess: (res) => {
            if (isMounted.current && res?.data?.headers?.length) {
              setOriginalHeadersList(res?.data?.headers?.map((item) => item));
            } else {
              setOriginalHeadersList(null);
            }
          },
          onError: (res) => {
            if (res?.response?.data?.code === 'err_max_rows_exceeded') {
              setSelectedFileHasError(true);
              const errorMessage = (
                <>
                  The selected file contains <strong>{res.response.data.rows_number}</strong> rows,
                  which exceeds the maximum number of rows allowed.
                  <br />
                  Please select a file with{' '}
                  <u>
                    up to <strong>{res.response.data.max_allowed}</strong> rows
                  </u>{' '}
                  to proceed.
                </>
              );
              setUploadedFilesMessageError(errorMessage);
            }
          },
          onEnd: () => setIsLoading(false),
        });
      } else if (file?.headers?.length) {
        setOriginalHeadersList(file?.headers);
      } else {
        setOriginalHeadersList(null);
      }
    },
    [isMounted]
  );

  // Update bot config on form changes
  useEffect(() => {
    // is valid header mapping
    const isValidHeaderMapping =
      Object.keys(headerMapping).length === MANDATORY_COLUMN_NAMES.length &&
      Object.values(headerMapping).every((value) => value !== null);
    if (
      selectedFile?.location &&
      newDescription &&
      selectedHeaders.length > 0 &&
      selectedTone &&
      selectedLength &&
      selectedGoal &&
      !newDescriptionError &&
      isValidHeaderMapping
    ) {
      const botConfigObj = {
        container_name: selectedFile?.location,
        file_name: selectedFile?.name,
        new_column_name: newDescription,
        columns_to_concat: selectedHeaders.map((header) => header.name),
        tone: selectedTone?.value,
        length: selectedLength?.value,
        goal: selectedGoal?.value,
        header_mapping: headerMapping,
        additional_languages: additionalLanguages
          ? additionalLanguages.map((language) => language.value)
          : [],
      };
      setValidSelections(true);
      setConfigData(botConfigObj);
    } else {
      setValidSelections(false);
    }
  }, [
    selectedFile?.location,
    selectedFile?.name,
    newDescription,
    selectedHeaders,
    selectedGoal,
    selectedTone,
    selectedLength,
    additionalLanguages,
    setConfigData,
    newDescriptionError,
    headerMapping,
  ]);

  // Load primary column headers for selected file
  useEffect(() => {
    setOriginalHeadersList([]);
    setSelectedHeaders([]);
    setHeaderChoices(undefined);
    setHeaderMappingChoices(undefined);
    setSelectedFileHasError(false);
    setUploadedFilesMessageError('');
    doLoadHeaders(selectedFile);
  }, [selectedFile, doLoadHeaders]);

  useEffect(() => {
    const filteredHeaders = [...(originalHeadersList || [])];

    setHeaderChoices(filteredHeaders);
    setHeaderMappingChoices(
      [...filteredHeaders].map((header) => ({ label: header, value: header }))
    );
  }, [originalHeadersList]);

  const handleNewDescription = ({ target }) => {
    setNewDescription(target.value);
  };

  // whenever the description changes, reset the error and check if the column name exists in the file
  useEffect(() => {
    setNewDescriptionError(null);
    if (selectedFile?.location && !newDescription) {
      setNewDescriptionError('This field is required');
    }
    if (originalHeadersList?.includes(newDescription)) {
      setNewDescriptionError('Column name already exists in the file');
    }
  }, [newDescription, originalHeadersList]);

  // Run handler assembles config object and triggers bot run
  const onRunBot = () => {
    handleRunBot(false, `/data-request/${dataJobId}`);
  };

  const onMappingChanged = useCallback(
    (mapping) => {
      if (!mapping) {
        return;
      }
      //   filter out the mapped columns from the list of headers
      const mappingValues = Object.values(mapping);
      const filteredHeaders = originalHeadersList?.filter(
        (header) => !mappingValues.includes(header)
      );
      setHeaderChoices(filteredHeaders);
      setHeaderMapping(mapping);
    },
    [originalHeadersList]
  );

  const onSetSelectedHeaders = useCallback(
    (values) => {
      if (!originalHeadersList) {
        return;
      }
      const valueNames = values.map((value) => value.name);
      const filteredHeaders = originalHeadersList.filter((header) => !valueNames.includes(header));

      if (filteredHeaders.length !== originalHeadersList.length) {
        setHeaderMappingChoices(
          filteredHeaders.map((header) => ({ label: header, value: header }))
        );
      }
      setSelectedHeaders(values);
    },
    [originalHeadersList]
  );

  const renderBotBody = () => (
    <>
      <div className="config-panel">
        <div className="column">
          <SmallSelectFileSection
            title="Step 1: Select Your File"
            note={
              <>
                <sup>*</sup>Only the first sheet of the file will be updated
              </>
            }
            dataJobId={dataJobId}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            filesList={filesList}
            setFilesList={setFilesList}
            isLoading={isLoading}
            errorMessage={uploadedFilesMessageError}
          />
          <section className="header-mapping">
            <div className="title">Step 2: Map the columns</div>
            <div className="note">
              <sup>*</sup>These columns are required by the bot to run
            </div>
            <div className="content">
              <MappingComponent
                mapping={headerMapping}
                targets={MANDATORY_COLUMN_NAMES}
                choices={headerMappingChoices}
                setMapping={onMappingChanged}
                disabled={isLoading || !selectedFile || selectedFileHasError}
              />
            </div>
          </section>

          <section className="header-selection">
            <div className="title">Step 3: Select additional columns you want to consider</div>
            <div className="note">
              <span>
                <sup>*</sup>These columns are used by the bot for additional context
              </span>
            </div>
            <div className="content">
              <HeaderSelector
                selectedHeaders={selectedHeaders}
                setSelectedHeaders={onSetSelectedHeaders}
                headerChoices={headerChoices}
                disabled={isLoading || !selectedFile || selectedFileHasError}
                canMove={false}
                isInvalid={
                  !isLoading &&
                  !uploadedFilesMessageError &&
                  selectedFile?.location &&
                  selectedHeaders.length === 0
                }
              />
            </div>
          </section>
        </div>
        <div className="column">
          <section>
            <div className="title">Step 4: Name Your New Description</div>
            <div className="note">
              <sup>*</sup>This will be the name of the new column in your file
            </div>
            <div className="content">
              <Form.Group>
                <Form.Control
                  as="input"
                  value={newDescription}
                  placeholder="Name your new description (ex: SEO Description 1)"
                  onChange={handleNewDescription}
                  maxLength="100"
                  disabled={isLoading || !selectedFile || selectedFileHasError}
                  isInvalid={
                    !isLoading &&
                    !uploadedFilesMessageError &&
                    newDescriptionError &&
                    newDescriptionError.length > 0
                  }
                />
                <Form.Control.Feedback type="invalid">
                  <sup>*</sup>
                  {newDescriptionError}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </section>
          <section className="configuration">
            <div className="title">Step 5: Additional Languages</div>
            <div className="note">
              <sup>*</sup>This bot defaults to producing product descriptions in English. If you add
              more languages, the original English descriptions will be translated into those
              languages and incorporated into the output file
            </div>
            <section>
              <StyledMultiselect
                values={additionalLanguages}
                options={BotConstants.GPT_GENERATOR_LANGUAGES}
                setOnChange={setAdditionalLanguages}
                getOptionValue={(option) => option.value}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                isDisabled={isLoading || !selectedFile || selectedFileHasError}
                menuPortalTarget={menuPortalTarget}
              />
            </section>
          </section>
          <section className="configuration">
            <div className="title">Step 6: Configure your Description</div>
            <div className="note">These settings will be applied to the descriptions</div>

            <section className="content">
              <section>
                <div className="label">Length</div>
                <div className="note">
                  <sup>*</sup>The overall length of the descriptions
                </div>
                <StyledMultiselect
                  defaultValue={BotConstants.GPT_DESCRIPTION_GENERATOR_LENGTHS[0]}
                  values={selectedLength}
                  options={BotConstants.GPT_DESCRIPTION_GENERATOR_LENGTHS}
                  setOnChange={setSelectedLength}
                  getOptionValue={(option) => option.value}
                  closeMenuOnSelect
                  canReset
                  isClearable
                  isMulti={false}
                  isDisabled={isLoading || !selectedFile || selectedFileHasError}
                  menuPortalTarget={menuPortalTarget}
                  isInvalid={
                    !isLoading &&
                    !uploadedFilesMessageError &&
                    selectedFile?.location &&
                    !selectedLength
                  }
                />
              </section>
              <section>
                <div className="label">Tone</div>
                <div className="note">
                  <sup>*</sup>The tone of the descriptions
                </div>
                <StyledMultiselect
                  values={selectedTone}
                  options={BotConstants.GPT_DESCRIPTION_GENERATOR_TONES}
                  setOnChange={setSelectedTone}
                  getOptionValue={(option) => option.value}
                  closeMenuOnSelect
                  canReset
                  isClearable
                  isMulti={false}
                  isDisabled={isLoading || !selectedFile || selectedFileHasError}
                  menuPortalTarget={menuPortalTarget}
                  isInvalid={
                    !isLoading &&
                    !uploadedFilesMessageError &&
                    selectedFile?.location &&
                    !selectedTone
                  }
                />
                {selectedTone && <div className="helper">{selectedTone.helper}</div>}
              </section>
              <section>
                <div className="label">Goal</div>
                <div className="note">
                  <sup>*</sup>The intended use of the descriptions
                </div>
                <StyledMultiselect
                  values={selectedGoal}
                  options={BotConstants.GPT_DESCRIPTION_GENERATOR_GOALS}
                  setOnChange={setSelectedGoal}
                  getOptionValue={(option) => option.value}
                  closeMenuOnSelect
                  canReset
                  isClearable
                  isMulti={false}
                  isDisabled={isLoading || !selectedFile || selectedFileHasError}
                  menuPortalTarget={menuPortalTarget}
                  isInvalid={
                    !isLoading &&
                    !uploadedFilesMessageError &&
                    selectedFile?.location &&
                    !selectedGoal
                  }
                />
                {selectedGoal && <div className="helper">{selectedGoal.helper}</div>}
              </section>
            </section>
          </section>
        </div>
      </div>
      <div className="databot-config-panel-footer">
        <span /> {/* This is a spacer for the footer */}
        <div className="powered-by-wrapper">
          <span>
            Powered by <strong>ChatGPT</strong>
            <img src={ChagGptLogo} alt="" />
          </span>
        </div>
      </div>
    </>
  );
  return (
    <DatabotConfigPanel
      headingStatsData={[
        { label: 'Manufacturer', value: additionalData?.name ?? 'N/A' },
        {
          label: 'Total Products',
          value: additionalData?.total_products ?? 'N/A',
          tooltip: 'Number of unique products on BackboneAI',
        },
      ]}
      botStatusId={botStatusId}
      slug={slug}
      status={status}
      title="GPT Description Generator"
      subtitle={
        <span>
          Leverage the power of <strong>AI</strong> and <strong>ChatGPT</strong> to generate high
          quality, SEO-optimized product descriptions. Select the attributes you want to include in
          your description and the bot will generate a description for each product in your file.
        </span>
      }
      bodyContent={renderBotBody()}
      requiresApproval
      preRunValidate={() => {
        if (!(selectedFile?.location && selectedHeaders.length > 0)) {
          toast.error('Please select a file and select which columns will be used');
          return false;
        }
        return true;
      }}
      disableActions={{ run: !validSelections }}
      onRun={onRunBot}
      onCancel={() => handleCancelBot(`/data-request/${dataJobId}`)}
      confirmDialogTitle="Run GPT Description Generator Databot?"
      confirmDialogBody={
        <>
          <p>You are about to:</p>
          Run the GPT Description Generator Databot
        </>
      }
    />
  );
};
export { GptDescriptionGeneratorBot };
