import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { Customer } from 'components/metrics/Customer/Customer';
import { MetricsTable } from 'components/metrics/MetricsTable/MetricsTable';
import { PRODUCT_ASSET_TYPES } from 'constants/ProductConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import {
  AssetsTableDef,
  IndividualDistributorsTableDef,
  ProductsTableDef,
  UsersTableDef,
} from 'helpers/table-defs/ViewMetricsTableDef';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

const ViewCustomerManufacturerMetrics = () => {
  const [overviewMetrics, setOverviewMetrics] = useState({});
  const [productMetrics, setProductMetrics] = useState([]);
  const [assetMetrics, setAssetMetrics] = useState([]);
  const [userMetrics, setUserMetrics] = useState([]);
  const [clientMetrics, setClientMetrics] = useState([]);
  const [isOverviewLoading, setIsOverviewLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const { id } = useParams();
  const isMounted = useIsMounted();

  useEffect(() => {
    setIsOverviewLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/metrics/manufacturer/${id}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          const { overview, products, assets, users, distributors } = res.data;

          const assetMetrics = {};
          Object.keys(assets).forEach((key) => {
            assetMetrics[assets[key].type] = assets[key].number;
          });
          // ! Workaround for bootstrap requiring a key to always be present, even when an object is empty
          assetMetrics[PRODUCT_ASSET_TYPES.FILE] = assetMetrics[PRODUCT_ASSET_TYPES.FILE] ?? 0;
          setPageTitle(`${overview.name} Dashboard`);
          setOverviewMetrics(overview);
          setProductMetrics([products]);
          setAssetMetrics([assetMetrics]);
          setUserMetrics(sortByKey(users, 'last_name'));
          setClientMetrics({
            total: distributors.total,
            distributors: sortByKey(distributors.distributors, 'name'),
          });
        }
      },
      onEnd: () => {
        setIsOverviewLoading(false);
      },
    });
  }, [id, isMounted]);

  return (
    <Customer
      pageTitle={pageTitle}
      type={UserRoleConstants.MANUFACTURER.toLowerCase()}
      isOverviewLoading={isOverviewLoading}
      overviewMetrics={overviewMetrics}
    >
      <div className="metrics-details-group">
        <MetricsTable
          header="Products Overview"
          metrics={productMetrics}
          columns={ProductsTableDef}
          showViewAll={false}
          keyField="total"
        />
        <Link
          className="metrics-details-group-item"
          to={`/products?mfr=${id}--${overviewMetrics.name}`}
        >
          Go to Product Listing
        </Link>
      </div>
      <MetricsTable
        header="Assets Overview"
        metrics={assetMetrics}
        columns={AssetsTableDef}
        showViewAll={false}
        keyField={PRODUCT_ASSET_TYPES.FILE}
      />
      <MetricsTable
        header="User Overview"
        metrics={userMetrics}
        usePagination
        keyField="id"
        columns={{
          columns: UsersTableDef.columns.filter((column) => column.dataField !== 'is_active'),
        }}
      />
      <MetricsTable
        header="Distributor Overview"
        usePagination
        keyField="id"
        metrics={clientMetrics.distributors ?? []}
        columns={IndividualDistributorsTableDef}
      />
    </Customer>
  );
};

export { ViewCustomerManufacturerMetrics };
