import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { StyledMultiselect } from '../../../../common/StyledMultiselect/StyledMultiselect';

import './MappingComponent.scss';

/**
 * Component to map a list of targets to a list of choices
 *
 * @param targets {string[]} List of targets
 * @param choices {{value: string, label: string}[]} List of choices
 * @param mapping {Object} Object with the mapping
 * @param onSetMapping {function} Callback to set the mapping
 * @param setInvalid {function} Callback to set if the mapping is invalid
 * @param disabled {boolean} If true, the mapping cannot be changed
 * @param targetOptions {Object} Object with options for each target
 * @returns {JSX.Element} Component
 */
const MappingComponent = ({
  targets,
  choices,
  mapping,
  setMapping,
  setInvalid = undefined,
  disabled = false,
  targetOptions = {},
}) => {
  // Initialize mapping with direct matches
  useEffect(() => {
    if (choices?.length) {
      if (!mapping || Object.keys(mapping).length === 0) {
        const initialMapping = {};

        targets.forEach((target) => {
          const directMatch = choices?.find(
            (choice) => choice.value.toString().toLowerCase() === target.toString().toLowerCase()
          );
          if (directMatch) {
            if (targetOptions?.[target]?.multi) {
              initialMapping[target] = [directMatch.value];
            } else {
              initialMapping[target] = directMatch.value;
            }
          }
        });
        if (!_.isEqual(initialMapping, mapping)) {
          setMapping(initialMapping);
        }
      }
    } else {
      setMapping(undefined);
    }
  }, [targetOptions, targets, mapping, choices, setMapping]); // Run effect when choices change

  const handleChange = (target, selectedOption) => {
    const newMapping = { ...mapping };
    let mappingChanged = false;
    if (selectedOption) {
      if (targetOptions?.[target]?.multi) {
        const newSelected = selectedOption.map((option) => option.value);
        if (JSON.stringify(newSelected) !== JSON.stringify(newMapping[target] || [])) {
          newMapping[target] = newSelected;
          mappingChanged = true;
        }
      } else {
        newMapping[target] = selectedOption.value;
        mappingChanged = true;
      }
    } else {
      delete newMapping[target];
      mappingChanged = true;
    }

    if (mappingChanged) {
      setMapping(newMapping);
    }
  };

  useEffect(() => {
    if (setInvalid) {
      if (mapping) {
        let isValid = true;
        targets.forEach((target) => {
          if (!mapping[target] && !targetOptions?.[target]?.optional) {
            isValid = false;
          }
        });
        setInvalid(!disabled && !isValid);
      }
    }
  }, [disabled, mapping, targets, targetOptions, setInvalid]);

  const getAvailableChoices = () => {
    const mappedValues = [];
    Object.values(mapping || {})?.forEach((value) => {
      if (Array.isArray(value)) {
        mappedValues.push(...value);
      } else {
        mappedValues.push(value);
      }
    });

    return choices?.filter((choice) => !mappedValues.includes(choice.value));
  };

  const handlerValues = (target) => {
    let values = null;
    if (targetOptions?.[target]?.multi) {
      values = choices?.filter((choice) => mapping?.[target]?.includes(choice.value));
    } else {
      values = mapping?.[target]
        ? choices?.find((choice) => choice.value === mapping?.[target])
        : null;
    }
    return values;
  };

  const isMappingItemValid = (target) => {
    return !disabled && mapping?.[target] === undefined && !targetOptions?.[target]?.optional;
  };

  return (
    <table className="header-mapping-table">
      <tbody>
        {targets.map((target) => (
          <tr
            className={classNames('header-mapping-row', {
              'header-mapping-row--invalid': isMappingItemValid(target),
            })}
            key={target}
          >
            <td className="header-mapping-row-cell--target">
              {target}
              {!targetOptions?.[target]?.optional ? (
                <>
                  <sup>*</sup>
                </>
              ) : (
                ''
              )}
            </td>
            <td className="header-mapping-row-cell--separator">
              <FontAwesomeIcon icon={['fa', 'angle-right']} />
            </td>
            <td className="header-mapping-row-cell--field">
              <StyledMultiselect
                defaultValue={targetOptions?.[target]?.multi ? [] : null}
                values={handlerValues(target)}
                options={getAvailableChoices()}
                setOnChange={(selectedOption) => handleChange(target, selectedOption)}
                isClearable
                isMulti={!!targetOptions?.[target]?.multi}
                closeMenuOnSelect={!targetOptions?.[target]?.multi}
                isSearchable
                canReset
                isDisabled={disabled}
                placeholder="Select a column"
                isInvalid={isMappingItemValid(target)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

MappingComponent.propTypes = {
  targets: PropTypes.arrayOf(PropTypes.string).isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }))
    .isRequired,
  mapping: PropTypes.object.isRequired,
  setMapping: PropTypes.func.isRequired,
  setInvalid: PropTypes.func,
  disabled: PropTypes.bool,
  targetOptions: PropTypes.object,
};

// define default props
MappingComponent.defaultProps = {
  disabled: false,
  setInvalid: undefined,
  targetOptions: {},
};

export { MappingComponent };
