import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

/**
 * Input field proxy component to defer triggering onchange handlers until
 * blur event. It will keep an intermediate state for buffering the inner
 * input component onChange events
 *
 * @param {String} value Parent value state. Will reset inner state if changed. Prefer empty string over null.
 * @param {function} onChange Parent onChange handler
 * @param {function} children Wrapper function for children elements
 * @return {object} Render
 */
const OnBlurInputProxy = React.memo(({ value, onChange, children }) => {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    // Reset inner state if outer value changes
    setInnerValue(value);
  }, [value]);

  const innerOnBlur = () => {
    // Only trigger parent onChange if values differ
    if (innerValue !== value) {
      typeof onChange === 'function' && onChange(innerValue);
    }
  };

  return (
    typeof children === 'function' &&
    children({ value: innerValue, onChange: setInnerValue, onBlur: innerOnBlur })
  );
});

OnBlurInputProxy.defaultProps = {
  value: '',
};

OnBlurInputProxy.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export { OnBlurInputProxy };
