import React from 'react';

import { getIconWithPopover } from 'helpers/Utils';
import * as Utils from 'helpers/Utils';

import { ValueBlock } from '../../../common/ValueBlock/ValueBlock';

export const IdsRegularStats = ({ statistics, isStatisticsLoading }) => {
  const formatPercentage = (value) => {
    if (value === 'N/A') {
      return '(N/A)';
    }

    const signal = value > 0 ? '+' : '';
    const formatted = `${signal + value?.toFixed(1) ?? 0} %`;
    return `(${formatted})`;
  };

  const TOOLTIP_INFO = {
    assessment: {
      job: 'Overview of the assessed jobs ran in the selected month.',
      image: 'Overview of the images that were assessed in the selected month."',
    },
    enhancement: {
      job: 'Overview of the enhanced jobs ran in the selected month.',
      image: 'Overview of the images that were enhanced in the selected month.',
    },
    na: {
      title: 'No Previous Runs',
      content: (
        <>
          Percentual difference is not applicable as there were <strong>0</strong> runs in the past
          period.
        </>
      ),
    },
  };

  const selectPercentageClassName = ({ percentage }) => {
    let output;
    if (percentage < 0) {
      output = 'percentage-negative';
    } else if (percentage > 0) {
      output = 'percentage-positive';
    } else {
      output = 'percentage-zero';
    }

    return `percentage ${output}`;
  };

  const renderValue = ({ value, percentage }) => {
    return (
      <div>
        <span className="value">{value || 0}</span>{' '}
        <span className={selectPercentageClassName({ percentage })}>
          {formatPercentage(percentage)}
        </span>
        {percentage === 'N/A' && (
          <span className="icon">
            {getIconWithPopover({
              placement: 'top',
              iconProp: ['far', 'question-circle'],
              content: TOOLTIP_INFO.na.content,
              title: TOOLTIP_INFO.na.title,
            })}
          </span>
        )}
      </div>
    );
  };

  const { assessment, enhancement } = statistics;
  return (
    <div className="ids-summary-regular-user">
      <div className="value-group-columns">
        <ValueBlock
          title="Assessment"
          subtitle="JOBS"
          value={Utils.renderLoadingOrContent(
            renderValue({
              value: assessment?.jobs?.total,
              percentage: assessment?.jobs?.percentage,
            }),
            isStatisticsLoading,
            0
          )}
          additionalData={[
            {
              label: ``,
              value: `${assessment?.jobs?.pending || 0} pending | ${
                assessment?.jobs?.active || 0
              } active | ${assessment?.jobs?.cancelled || 0} cancelled`,
            },
          ]}
          className="ids-summary-jobs span-2r"
          tooltip={TOOLTIP_INFO.assessment.job}
        />

        <ValueBlock
          title="Enhancement"
          subtitle="JOBS"
          value={Utils.renderLoadingOrContent(
            renderValue({
              value: enhancement?.jobs?.total,
              percentage: enhancement?.jobs?.percentage,
            }),
            isStatisticsLoading,
            0
          )}
          additionalData={[
            {
              label: ``,
              value: `${enhancement?.jobs?.active || 0} active | ${
                enhancement?.jobs?.cancelled || 0
              } cancelled `,
            },
          ]}
          className="ids-summary-jobs span-2r"
          tooltip={TOOLTIP_INFO.enhancement.job}
        />

        <ValueBlock
          title="Assessment"
          subtitle="IMAGES"
          value={Utils.renderLoadingOrContent(
            renderValue({
              value: assessment?.images?.total,
              percentage: assessment?.images?.percentage,
            }),
            isStatisticsLoading,
            0
          )}
          additionalData={[
            {
              label: ``,
              value: `${assessment?.images?.pending || 0} pending | ${
                assessment?.images?.active || 0
              } completed | ${assessment?.images?.cancelled || 0} cancelled `,
            },
          ]}
          className="ids-summary-jobs span-2r"
          tooltip={TOOLTIP_INFO.assessment.image}
        />

        <ValueBlock
          title="Enhancement"
          subtitle="IMAGES"
          value={Utils.renderLoadingOrContent(
            renderValue({
              value: enhancement?.images?.total,
              percentage: enhancement?.images?.percentage,
            }),
            isStatisticsLoading,
            0
          )}
          additionalData={[
            {
              label: ``,
              value: `${enhancement?.images?.active || 0} completed`,
            },
          ]}
          className="ids-summary-jobs span-2r"
          tooltip={TOOLTIP_INFO.enhancement.image}
        />
      </div>
    </div>
  );
};
