import React from 'react';

import classNames from 'classnames';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';

const priorityDetails = {
  1: {
    id: 1,
    code: 'low',
    label: 'Low',
  },
  2: {
    id: 2,
    code: 'medium',
    label: 'Medium',
  },
  3: {
    id: 3,
    code: 'high',
    label: 'High',
  },
};

const PrioritySelector = ({ value, onChange, isEditMode, isInvalid }) => {
  const getSelectedOption = (_value) => {
    if (_value && priorityDetails[_value]) {
      return {
        value: _value,
        label: priorityDetails[_value].label,
      };
    }

    return null;
  };

  const selectedOption = getSelectedOption(value);
  const priorityOptions = Object.values(priorityDetails).map((item) => {
    return { value: item.id, label: item.label };
  });

  return (
    <>
      {isEditMode ? (
        <StyledMultiselect
          values={selectedOption ? [selectedOption] : []}
          options={priorityOptions}
          setOnChange={(v) => onChange(v?.value ?? null)}
          isMulti={false}
          isInvalid={isInvalid}
        />
      ) : (
        <div className="form-control-plaintext">
          <span
            className={classNames('priority', {
              [`priority-${priorityDetails[selectedOption?.value]?.id}`]: !!selectedOption,
            })}
          >
            {selectedOption?.label ?? 'N/A'}
          </span>
        </div>
      )}
    </>
  );
};

export { PrioritySelector };
