import React, { useCallback, useEffect, useRef, useState } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Prompt, useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { WsConstants } from 'api/WsConstants';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { AltTextCreatorBot } from 'components/databots/bots/AltTextCreatorBot/AltTextCreatorBot';
import { BaseDataFillBot } from 'components/databots/bots/BaseDataFillBot/BaseDataFillBot';
import { BrandNameAssignmentBot } from 'components/databots/bots/BrandNameAssignmentBot/BrandNameAssignmentBot';
import { CADImageRenderFifBot } from 'components/databots/bots/CADImageRenderFifBot/CADImageRenderFifBot';
import { GenericFifBot } from 'components/databots/bots/common/GenericFifBot/GenericFifBot';
import { DescriptionBuilderBot } from 'components/databots/bots/DescriptionBuilderBot/DescriptionBuilderBot';
import { DetailedAttributesBot } from 'components/databots/bots/DetailedAttributesBot/DetailedAttributesBot';
import { GapAnalysisFifBot } from 'components/databots/bots/GapAnalysisFifBot/GapAnalysisFifBot';
import { GptDescriptionGeneratorBot } from 'components/databots/bots/GptGeneratorBot/GptDescriptionGeneratorBot';
import { GptFeatureAndBenefitsGenerator } from 'components/databots/bots/GptGeneratorBot/GptFeatureAndBenefitsGenerator';
import { AttributeCompression } from 'components/databots/bots/AttributeCompressionBot/AttributeCompression';
import { ImageQualityReportBot } from 'components/databots/bots/ImageQualityReportBot/ImageQualityReportBot';
import { OrderDetailsBot } from 'components/databots/bots/OrderDetailsBot/OrderDetailsBot';
import { PricingUpdateBot } from 'components/databots/bots/PricingUpdateBot/PricingUpdateBot';
import { PricingValidationBot } from 'components/databots/bots/PricingValidationBot/PricingValidationBot';
import { ReclassifyAttributesBot } from 'components/databots/bots/ReclassifyAttributesBot/ReclassifyAttributesBot';
import { ShortDescriptionCreatorBot } from 'components/databots/bots/ShortDescriptionCreatorBot/ShortDescriptionCreatorBot';
import { ShortDescriptionCreatorFifBot } from 'components/databots/bots/ShortDescriptionCreatorFifBot/ShortDescriptionCreatorFifBot';
import { BotConstants, BOT_SLUGS, BOT_STATUSES } from 'constants/BotConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { useWebsocket } from 'helpers/useWebsocket';

const SCREEN_MAPPINGS = {
  [BOT_SLUGS.DETAILED_ATTRIBUTE_LABEL_CORRECTION]: DetailedAttributesBot,
  [BOT_SLUGS.BRAND_NAME_ASSIGNMENT]: BrandNameAssignmentBot,
  [BOT_SLUGS.IMAGE_QUALITY_REPORT]: ImageQualityReportBot,
  [BOT_SLUGS.PRICING_VALIDATION]: PricingValidationBot,
  [BOT_SLUGS.SHORT_DESCRIPTION_CREATOR]: ShortDescriptionCreatorBot,
  [BOT_SLUGS.ATTRIBUTE_RECLASSIFICATION]: ReclassifyAttributesBot,
  [BOT_SLUGS.PRICING_UPDATE]: PricingUpdateBot,
  [BOT_SLUGS.ATTRIBUTES_FILL]: BaseDataFillBot,
  [BOT_SLUGS.ORDER_DETAILS]: OrderDetailsBot,
  [BOT_SLUGS.GAP_ANALYSIS_FIF]: GapAnalysisFifBot,
  [BOT_SLUGS.SHORT_DESCRIPTION_CREATOR_FIF]: ShortDescriptionCreatorFifBot,
  [BOT_SLUGS.DESCRIPTION_BUILDER]: DescriptionBuilderBot,
  [BOT_SLUGS.CAD_IMAGE_RENDER]: CADImageRenderFifBot,
  [BOT_SLUGS.IMAGE_QUALITY_FIF]: GenericFifBot,
  [BOT_SLUGS.ACTION_SHOT_DETECTION]: GenericFifBot,
  [BOT_SLUGS.IMAGE_CROPPING]: GenericFifBot,
  [BOT_SLUGS.PLACEHOLDER_IMAGE_DETECTION]: GenericFifBot,
  [BOT_SLUGS.BACKGROUND_REMOVAL]: GenericFifBot,
  [BOT_SLUGS.DUPLICATED_IMAGE_DETECTION]: GenericFifBot,
  [BOT_SLUGS.IMAGE_ENLARGEMENT]: GenericFifBot,
  [BOT_SLUGS.WATERMARK_DETECTION_FIF]: GenericFifBot,
  [BOT_SLUGS.TAXONOMY_FILL]: BaseDataFillBot,
  [BOT_SLUGS.DIGITAL_ASSET_LINK_VALIDATOR_FIF]: GenericFifBot,
  [BOT_SLUGS.ALT_TEXT_CREATOR]: AltTextCreatorBot,
  [BOT_SLUGS.GPT_DESCRIPTION_GENERATOR]: GptDescriptionGeneratorBot,
  [BOT_SLUGS.GPT_FEATURE_AND_BENEFITS_GENERATOR]: GptFeatureAndBenefitsGenerator,
  [BOT_SLUGS.ATTRIBUTE_COMPRESSION]: AttributeCompression,
};

const getDatabotForSlug = (slug) => {
  let output = () => null;

  if (slug && Object.keys(SCREEN_MAPPINGS).includes(slug)) {
    output = SCREEN_MAPPINGS[slug];
  } else {
    console.error('Slug not defined', slug);
  }

  return output;
};

const ViewDatabotsConfig = () => {
  // WS params
  const channel = WsConstants.WS_CHANNELS.BOTS;
  const ws = useWebsocket();
  const wsLastUpdate = useRef(null);
  const { messages, messagesLastUpdate } = ws;

  const loadCount = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  // Represents the actual "bot" object
  const [botData, setBotData] = useState({});
  // Represents the bot "run" or, in other words, an instantiated bot
  const [botStatusData, setBotStatusData] = useState({});
  // Represents the configuration object to be sent to k8s for processing; configurable databots MUST assign this
  const [configData, setConfigData] = useState(null);
  // Represents any extra information to be displayed on the UI, to avoid making extraneous API calls for miscellaneous data (product/attribute counts, more specific queries)
  const [additionalData, setAdditionalData] = useState({});
  // Represents the historical details or changes that should be displayed for the bot. Like additionalData, this is defined on a case basis
  const [details, setDetails] = useState([]);
  // Provide the ability to explicitly disable the "exit" prompt
  const [disablePrompt, setDisablePrompt] = useState(false);
  const { slug } = useParams();
  const location = useLocation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();
  const isAdmin = activeUser?.role === UserRoleConstants.ADMIN;

  const paramsMfrId = queryString.parse(location?.search).manufacturer_id;

  const manufacturerId =
    isAdmin || paramsMfrId ? paramsMfrId : activeUser.profile?.manufacturer?.id;
  const parentManufacturerId = location?.state?.parentManufacturerId;
  const dataJobId = queryString.parse(location?.search).data_request_id;
  const botType = queryString.parse(location?.search).type;

  const setLoading = useCallback(
    (_isLoading) => {
      if (!isMounted.current) {
        return;
      }

      if (_isLoading === true) loadCount.current++;
      else if (_isLoading === false) loadCount.current--;

      if (loadCount.current > 0) setIsLoading(true);
      else if (loadCount.current === 0) setIsLoading(false);
      else {
        setIsLoading(false);
        console.warn('Invalid load count', loadCount.current);
      }
    },
    [setIsLoading, loadCount, isMounted]
  );

  /**
   * By default, builds a URL with the manufacturer ID as a query parameter. If the parent manufacturer ID is supplied, the query string is updated to reflect the lakehouse/databots pattern for choosing parent/child manufacturers.
   */
  const urlWithMfrQuery = useCallback(
    (baseUrl, parentMfrId) => {
      let url = baseUrl;
      let params = { data_request_id: dataJobId };

      if (parentMfrId)
        params = {
          ...params,
          manufacturer_id: parentMfrId,
          child_company_id: manufacturerId,
        };
      else
        params = {
          ...params,
          manufacturer_id: manufacturerId,
        };

      if (params) url += `?${queryString.stringify(params)}`;
      return url;
    },
    [manufacturerId, dataJobId]
  );

  /**
   * Redirect the user to the databots screen. If the parent manufacturer ID exists in the location state, supply it to our URL builder so that we can retain parent/child selection.
   */
  const redirectToDatabots = useCallback(() => {
    history.push(urlWithMfrQuery('/databots', parentManufacturerId));
  }, [history, urlWithMfrQuery, parentManufacturerId]);

  /**
   * Redirects the user from the current databot screen to the databots manager page or a specific route.
   *
   * @param {string} customRedirectPath The path to redirect to, if not the databots manager page
   */
  const doRedirectFromDatabot = (customRedirectPath = null) => {
    // If a databot status doesn't exist, we don't want to attempt an XHR; instead, we'll redirect immediately
    if (customRedirectPath) {
      history.push(customRedirectPath);
    } else {
      redirectToDatabots();
    }
  };

  const getBotDetails = useCallback(
    (slug) => {
      setLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: urlWithMfrQuery(`/bots/details/${slug}`),
        onSuccess: (res) => {
          if (isMounted.current) {
            setDetails(res.data);
          }
        },
        onEnd: () => {
          if (isMounted.current) {
            setLoading(false);
          }
        },
      });
    },
    [isMounted, urlWithMfrQuery, setLoading]
  );

  const getBotAdditionalData = useCallback(
    (slug) => {
      setLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: urlWithMfrQuery(`/bots/additional-data/${slug}`),
        onSuccess: (res) => {
          if (isMounted.current) {
            setAdditionalData(res.data);
          }
        },
        onEnd: () => {
          if (isMounted.current) {
            setLoading(false);
          }
        },
      });
    },
    [isMounted, urlWithMfrQuery, setLoading]
  );

  const getAvailableBots = useCallback(
    (query, slug) => {
      const createDatabotStatus = (_botData) => {
        const reqData = {
          bot_id: _botData.id,
        };

        if (parentManufacturerId) reqData.manufacturer_id = parentManufacturerId;
        if (manufacturerId) reqData.child_company_id = manufacturerId;
        if (dataJobId) reqData.data_request_id = dataJobId;

        setLoading(true);
        ApiCalls.doCall({
          method: ApiCalls.HTTP_METHODS.POST,
          urlPath: `/bots/status`,
          data: reqData,
          onSuccess: (res) => {
            if (isMounted.current && res?.data) {
              setBotData({ ..._botData, bot_status: res.data });
              setBotStatusData(res.data);

              if (res.data.has_details) {
                getBotDetails(slug);
              }
            }
          },
          onEnd: () => {
            if (isMounted.current) {
              setLoading(false);
            }
          },
        });
      };

      setLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/available-bots?${queryString.stringify(query)}`,
        onSuccess: (res) => {
          if (isMounted.current && res?.data?.id) {
            setBotData(res.data);

            if (!res.data?.bot_status) {
              createDatabotStatus(res.data);
            } else {
              setBotStatusData(res.data.bot_status);

              if (res.data.has_details) {
                getBotDetails(slug);
              }
            }
          }
        },
        onEnd: () => {
          if (isMounted.current) {
            setLoading(false);
          }
        },
      });
    },
    [parentManufacturerId, manufacturerId, dataJobId, isMounted, getBotDetails, setLoading]
  );

  const getAllBotData = useCallback(() => {
    const query = {
      slug,
    };

    if (manufacturerId) query.manufacturer_id = manufacturerId;
    if (dataJobId) query.data_request_id = dataJobId;
    if (botType) query.type = botType;

    getAvailableBots(query, slug, activeUser.id);
    getBotAdditionalData(slug);
  }, [
    manufacturerId,
    slug,
    dataJobId,
    botType,
    activeUser.id,
    getBotAdditionalData,
    getAvailableBots,
  ]);

  const handleRunBot = (isStayOnPage, customRedirectPath = null) => {
    let botStatusId = botStatusData?.id;

    // This is where databots are officially run and queued
    const doPatchStatus = () => {
      setLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.PATCH,
        urlPath: `/bots/status/${botStatusId}`,
        data: {
          config_data: configData,
        },
        onSuccess: (res) => {
          if (isMounted.current) {
            setDisablePrompt(true);
            setBotStatusData(res.data);
            if (isStayOnPage) {
              if ([BOT_STATUSES.PENDING, BOT_STATUSES.RUNNING].includes(res?.data?.status)) {
                toast.success('Databot is running');
              } else if (res?.data?.status === BOT_STATUSES.FAILED) {
                toast.error('Databot failed to run');
              }
              getAllBotData();
            } else {
              doRedirectFromDatabot(customRedirectPath);
            }
          }
        },
        errorMessage: 'Data Bot was unable to run. Please try again later',
        onEnd: () => {
          if (isMounted.current) {
            setLoading(false);
          }
        },
      });
    };

    // TODO: Verify if this handler is still needed with the inclusion of the fallback createDatabotStatus
    // If the databot config page has been accessed directly or through the "details" entry point, we want to create a databot status (or "run") object before PATCHing and queueing
    if (
      !botStatusId ||
      [BOT_STATUSES.FAILED, BOT_STATUSES.CANCELED].includes(botStatusData?.status)
    ) {
      setLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/bots/status`,
        data: {
          ...(manufacturerId && {
            manufacturer_id: manufacturerId,
          }),
          bot_id: botData.id,
        },
        onSuccess: (res) => {
          if (isMounted.current) {
            botStatusId = res.data.id;
            doPatchStatus();
          }
        },
        errorMessage: 'Data Bot was unable to run. Please try again later',
        onEnd: () => {
          if (isMounted.current) {
            setLoading(false);
          }
        },
      });
    } else {
      doPatchStatus();
    }
  };

  const handleCancelBot = (customRedirectPath = null) => {
    if (!botStatusData?.id) doRedirectFromDatabot(customRedirectPath);

    setLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/bots/status/${botStatusData.id}/cancel`,
      onSuccess: (res) => {
        toast.success(res.data.message);
        doRedirectFromDatabot(customRedirectPath);
      },
      onError: (res) => {
        toast.error(res.data.message);
      },
      onEnd: () => {
        if (isMounted.current) {
          setLoading(false);
        }
      },
    });
  };

  const handleApproveChanges = () => {
    setLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/product-asset-auxiliary/${botStatusData.id}/manage-auxiliary-data`,
      data: { product_auxiliary: 'approve', asset_auxiliary: 'approve' },
      onSuccess: () => {
        redirectToDatabots();
      },
      onEnd: () => {
        if (isMounted.current) {
          setLoading(false);
        }
      },
    });
  };

  const handleRejectChanges = (data) => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/product-asset-auxiliary/${botStatusData.id}/manage-auxiliary-data`,
      data: { product_auxiliary: 'reject', asset_auxiliary: 'reject' },
      onSuccess: () => {
        redirectToDatabots();
      },
      onEnd: () => {
        if (isMounted.current) {
          setLoading(false);
        }
      },
    };

    if (data?.feedback) {
      reqCfg.data = {
        feedback: data.feedback,
      };
    }

    setLoading(true);
    ApiCalls.doCall(reqCfg);
  };

  const showExitPrompt = () => {
    if (disablePrompt) return null;

    // If the bot is in a state where the user cannot control it, we don't want to show the prompt
    if (
      !botStatusData?.status ||
      ![BOT_STATUSES.RUNNING, BOT_STATUSES.AWAITING_APPROVAL, BOT_STATUSES.PENDING].includes(
        botStatusData?.status
      )
    )
      return (
        <Prompt message="You are about to navigate away from this page without running this databot. Are you sure?" />
      );
    return null;
  };

  const DatabotScreen = getDatabotForSlug(slug);

  useEffect(() => {
    getAllBotData();
  }, [getAllBotData]);

  // WS subscribe/unsubscribe
  useEffect(() => {
    // extra config
    const wsExtra = {
      type: BotConstants.BOT_WS_CHANNEL_TYPES.STATUS,
      id: botStatusData?.id,
    };

    // Unsubscribe func on unmount
    const unmountFunc = () => {
      if (botStatusData?.id && !isMounted.current) {
        ws.disconnectChannel(channel, wsExtra);
        wsLastUpdate.current = null;
      }
    };

    // Subscribe logic
    if (botStatusData?.id && isMounted.current && ws?.isConnected && !wsLastUpdate.current) {
      ws.addChannel(channel, wsExtra);
      wsLastUpdate.current = Date.now();
    }
    return () => unmountFunc();
  }, [isMounted, channel, ws, botStatusData?.id, wsLastUpdate]);

  // WS messages handler
  useEffect(() => {
    if (!(isMounted.current && wsLastUpdate.current < messagesLastUpdate[channel])) {
      return;
    }

    const _data = messages[channel] ?? null;

    if (_data?.id && botData?.bot_status?.id === _data?.id) {
      wsLastUpdate.current = messagesLastUpdate[channel];
      const newData = _cloneDeep(_data);
      setBotStatusData(newData);
      setBotData({ ...botData, bot_status: newData });
    } else {
      console.debug('Unsupported msg', _data);
    }
  }, [isMounted, channel, ws?.isConnected, messages, wsLastUpdate, messagesLastUpdate, botData]);

  return (
    <div className="content content-fluid view-databots-config">
      <Helmet bodyAttributes={{ 'data-page': 'view-databots-config' }}>
        <title>{botData.name}</title>
      </Helmet>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          {showExitPrompt()}
          <DatabotScreen
            botStatusId={botStatusData?.id}
            slug={slug}
            status={botStatusData?.status}
            requiresApproval={botData.requires_approval}
            hasDownloadedReport={botStatusData?.has_downloaded}
            reportName={botStatusData?.report_name}
            setConfigData={setConfigData}
            setBotStatusData={setBotStatusData}
            configData={configData}
            additionalData={additionalData}
            details={details}
            handleRunBot={handleRunBot}
            handleCancelBot={handleCancelBot}
            handleApproveChanges={handleApproveChanges}
            handleRejectChanges={handleRejectChanges}
            manufacturerId={manufacturerId}
            botData={botData}
          />
        </>
      )}
    </div>
  );
};

export { ViewDatabotsConfig };
