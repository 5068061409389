import React from 'react';

import { Helmet } from 'react-helmet';

import { SectionImgBotEngineHelp } from '../data-lakehouse/DataLakehouseDashboard/private/SectionImgBotEngineHelp';

const ViewImgBotEngineAbout = () => {
  return (
    <>
      <Helmet>
        <title>Image Databot Suite - About</title>
      </Helmet>
      <section className="content content-fluid view-img-bot-engine-about">
        <SectionImgBotEngineHelp />
      </section>
    </>
  );
};

export { ViewImgBotEngineAbout };
