import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import * as Utils from 'helpers/Utils';

const RequestDataModalPanel = ({ children }) => {
  const [sendStatus, setSendStatus] = useState(ActionStatusConstants.INITIAL);
  const [fieldEmailVal, setFieldEmailVal] = useState('');
  const [fieldMsgVal, setFieldMsgVal] = useState('');
  const [fieldEmailValid, setFieldEmailValid] = useState(true);

  const isMounted = useIsMounted();

  const onClickSend = (setIsVisible) => {
    // Split emails from input field by comma, semicolon or space
    const emailsArray = fieldEmailVal
      ? fieldEmailVal
          .toLowerCase()
          .split(/(?:;|,|\s)*(?:;|,|\s)/g)
          .filter((_item) => _item && _item.length)
      : [];
    setFieldEmailValid(true);
    let isValid = true;

    if (!(emailsArray && emailsArray.length)) {
      setFieldEmailValid(false);
      isValid = false;
    }

    for (let i = 0; i < emailsArray.length; i++) {
      if (!Utils.isEmailValid(emailsArray[i])) {
        setFieldEmailValid(false);
        isValid = false;
      }
    }

    if (!isValid) {
      toast.error(
        'One or more of the emails entered is invalid. Please check your email string again.'
      );

      return;
    }

    if (isMounted.current) {
      setSendStatus(ActionStatusConstants.ISBUSY);

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/emails/send-email',
        data: {
          to_emails: emailsArray,
          content: fieldMsgVal ?? '',
        },
        onSuccess: () => {
          if (isMounted.current) {
            toast.success('Message was sent successfully.');
            setSendStatus(ActionStatusConstants.SUCCESS);
            setIsVisible(false);
          }
        },
        onError: () => {
          if (isMounted.current) {
            setSendStatus(ActionStatusConstants.FAILURE);
          }
        },
      });
    }
  };

  const doResetState = () => {
    setSendStatus(ActionStatusConstants.INITIAL);
    setFieldEmailVal('');
    setFieldMsgVal('');
  };

  const isBusy = sendStatus === ActionStatusConstants.ISBUSY;

  const renderSendButtonFunc = ({ setIsVisible }) => (
    <>
      {isBusy && <LoadingSpinner style={{ marginRight: '0.5em' }} fast />}
      <Button variant="primary" onClick={() => onClickSend(setIsVisible)} disabled={isBusy}>
        Send
      </Button>
    </>
  );

  return (
    <ModalPanel
      header="Request Data"
      body={
        <>
          <Form.Group controlId="send_email" className="send_email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="user@company.com"
              value={fieldEmailVal}
              onChange={(e) => setFieldEmailVal(e.target.value)}
              isInvalid={!fieldEmailValid}
              disabled={isBusy}
            />
            {!fieldEmailValid && (
              <div className="invalid-feedback">Please enter a valid email format.</div>
            )}
          </Form.Group>
          <Form.Group controlId="send_message" className="send_message">
            <Form.Label>
              Message
              {fieldMsgVal && fieldMsgVal.length > 0 ? (
                <span className="char-count" style={{ marginLeft: '0.25em' }}>
                  ({fieldMsgVal.length}/1000)
                </span>
              ) : null}
            </Form.Label>
            <Form.Control
              as="textarea"
              value={fieldMsgVal}
              onChange={(e) => setFieldMsgVal(e.target.value)}
              rows="5"
              maxLength="1000"
              style={{ resize: 'vertical' }}
              disabled={isBusy}
            />
            <div className="subtitle">Send a custom message to the email recipient.</div>
          </Form.Group>
        </>
      }
      footer={renderSendButtonFunc}
      className="request-data-modal-panel"
      onClose={doResetState}
    >
      {({ setIsVisible }) => typeof children === 'function' && children({ setIsVisible })}
    </ModalPanel>
  );
};

RequestDataModalPanel.propTypes = {
  children: PropTypes.func.isRequired,
};

export { RequestDataModalPanel };
