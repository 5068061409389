import './TutorialSheetColumns.scss';
import React, { useState, useContext, useEffect, useCallback } from 'react';

import * as ApiCalls from 'api/ApiCalls';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { ProgressDots } from 'components/common/ProgressDots/ProgressDots';
import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import { TUTORIALSTEPS } from 'constants/DataIngestionConstants';
import { useIsMounted } from 'helpers/useIsMounted';

/**
 * Runs the ingestion columns tutorial
 *
 * @returns object
 */

const TutorialSheetColumns = () => {
  const { tutorial } = useContext(DataIngestionContext);
  const [visible, setVisible] = useState(true);
  const isMounted = useIsMounted();

  const calculateLocation = useCallback(() => {
    // TODO find a new modal library built for what designs we're receiving(arrows and ability to set position)
    const modal = document.getElementsByClassName('modal-dialog')[0];
    if (modal !== undefined && tutorial.step === TUTORIALSTEPS.LABELS) {
      const target = document.getElementById('row-backbone');
      const location = target.getBoundingClientRect();
      const top = location.top - modal.childNodes[0].offsetHeight - 40;
      const left = location.left + 450;
      modal.style.position = 'absolute';
      modal.style.top = `${top}px`;
      modal.style.left = `${left}px`;
      modal.className += ' arrow-bottom';
    } else if (modal !== undefined && tutorial.step === TUTORIALSTEPS.DISABLE) {
      const target = document.getElementById('row-backbone').childNodes[1];
      const location = target.getBoundingClientRect();
      const top = location.top - 140;
      const left = location.left + target.offsetWidth + 20;
      modal.style.position = 'absolute';
      modal.style.top = `${top}px`;
      modal.style.left = `${left}px`;
      modal.className += ' arrow-left';
      modal.classList.remove('arrow-bottom');
    } else if (modal !== undefined && tutorial.step === TUTORIALSTEPS.SCROLL) {
      const target = document.getElementsByClassName('scroll-actions')[0];
      const location = target.getBoundingClientRect();
      const top = location.top - 40;
      const left = location.left - modal.childNodes[0].offsetWidth - 20;
      modal.style.position = 'absolute';
      modal.style.top = `${top}px`;
      modal.style.left = `${left}px`;
      modal.className += ' arrow-right';
      modal.classList.remove('arrow-left');
    } else if (modal !== undefined && tutorial.step === TUTORIALSTEPS.CONFIRM) {
      modal.style.position = 'relative';
      modal.style.top = ``;
      modal.style.left = ``;
      modal.classList.remove('arrow-left');
    }
  }, [tutorial.step]);

  useEffect(() => {
    if (isMounted && tutorial) calculateLocation();
  }, [calculateLocation, tutorial, isMounted]);

  const completeTutorial = () => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/users/status`,
      data: { col_tutorial_done: true },
      onSuccess: () => {
        tutorial.setColumnTutorialDone(true);
        tutorial.setStep(TUTORIALSTEPS.COLUMNS_DONE);
      },
    });
  };

  const restartColumnTutorial = () => {
    tutorial.setStep(TUTORIALSTEPS.LABELS);
  };

  return (
    <>
      {tutorial.step === TUTORIALSTEPS.LABELS ? (
        <ModalPanel
          className="column-edit-panel ingestion-tutorial-modal"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          backdrop="static"
          keyboard={false}
          header="Verify Column Labels"
          body={
            <div className="body-tutorial">
              <p>We've standardized your original header labels.</p>
              <p>Click the pencil icon to select a different value.</p>
            </div>
          }
          footer={
            <>
              <div className="progress-wrapper">
                <ProgressDots
                  values={['labels', 'disable', 'scroll', 'confirm']}
                  current="labels"
                />
              </div>
              <div className="edit-footer">
                <button type="button" onClick={() => tutorial.setStep(TUTORIALSTEPS.DISABLE)}>
                  <span className="oi oi-arrow-right" aria-hidden />
                  Next
                </button>
              </div>
            </>
          }
          centered={false}
        />
      ) : tutorial.step === TUTORIALSTEPS.DISABLE ? (
        <ModalPanel
          className="column-edit-panel ingestion-tutorial-modal"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          backdrop="static"
          keyboard={false}
          header="Remove Column"
          body={<div className="body-tutorial">Click the trash can icon to disable a column.</div>}
          footer={
            <>
              <div className="progress-wrapper">
                <ProgressDots
                  values={['labels', 'disable', 'scroll', 'confirm']}
                  current="disable"
                />
              </div>
              <div className="edit-footer">
                <button
                  type="button"
                  onClick={() => {
                    tutorial.setStep(TUTORIALSTEPS.SCROLL);
                  }}
                >
                  <span className="oi oi-arrow-right" aria-hidden />
                  Next
                </button>
              </div>
            </>
          }
          centered={false}
        />
      ) : tutorial.step === TUTORIALSTEPS.SCROLL ? (
        <ModalPanel
          className="column-edit-panel ingestion-tutorial-modal"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          header="Confirm All Labels"
          backdrop="static"
          keyboard={false}
          body={
            <div className="body-tutorial">
              <p>Scroll right to confirm all labels.</p>
              <p>Use your trackpad or the scroll icons here.</p>
            </div>
          }
          footer={
            <>
              <div className="progress-wrapper">
                <ProgressDots
                  values={['labels', 'disable', 'scroll', 'confirm']}
                  current="scroll"
                />
              </div>
              <div className="edit-footer">
                <button
                  type="button"
                  onClick={() => {
                    tutorial.setStep(TUTORIALSTEPS.CONFIRM);
                  }}
                >
                  <span className="oi oi-check" aria-hidden />
                  Okay
                </button>
              </div>
            </>
          }
          centered={false}
        />
      ) : tutorial.step === TUTORIALSTEPS.CONFIRM ? (
        <ModalPanel
          className="column-edit-panel ingestion-tutorial-modal"
          isVisible={visible}
          setIsVisible={setVisible}
          showCancel
          backdrop="static"
          keyboard={false}
          header="Tutorial Complete!"
          body={
            <div className="body-tutorial">
              <p>Click "Restart" to go through this tutorial again.</p>
              <p>Click "Start" to confirm your header labels.</p>
            </div>
          }
          footer={
            <>
              <div className="progress-wrapper">
                <ProgressDots
                  values={['labels', 'disable', 'scroll', 'confirm']}
                  current="confirm"
                />
              </div>
              <div className="edit-footer">
                <button
                  type="button"
                  className="tutorial-reload"
                  onClick={() => {
                    restartColumnTutorial();
                  }}
                >
                  <span className="oi oi-reload" aria-hidden />
                  Restart
                </button>
                <button
                  type="button"
                  onClick={() => {
                    completeTutorial();
                    setVisible(false);
                  }}
                >
                  <span className="oi oi-check" aria-hidden />
                  Start Onboarding
                </button>
              </div>
            </>
          }
          centered
        />
      ) : null}
    </>
  );
};

export { TutorialSheetColumns };
