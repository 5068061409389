import './FeedbackModalPanel.scss';

import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { isIE } from 'react-device-detect';

import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';
import { SlateRTE } from 'components/common/TextEditor/SlateRTE';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';

const FeedbackModalPanel = ({
  title,
  subtitle,
  submitBtnLabel,
  cancelBtnLabel,
  onFeedbackSubmit,
  children,
}) => {
  const [feedbackStatus, setFeedbackStatus] = useState(ActionStatusConstants.INITIAL);
  const [feedback, setFeedback] = useState('');

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  });

  const onFeedbackSuccess = () => {
    setFeedbackStatus(ActionStatusConstants.SUCCESS);
  };

  const onFeedbackFailure = () => {
    setFeedbackStatus(ActionStatusConstants.FAILURE);
  };

  const onClickSubmit = (setIsVisible) => {
    if (!feedback.length) {
      toast.error('Please provide feedback.');

      return;
    }

    if (typeof onFeedbackSubmit === 'function') {
      setFeedbackStatus(ActionStatusConstants.BUSY);
      onFeedbackSubmit({
        feedbackData: { feedback },
        onSuccess: onFeedbackSuccess,
        onFailure: onFeedbackFailure,
        setIsVisible,
      });
    } else {
      console.error('No onFeedbackSubmit function');
      // Should never be called, but adding this in here in case it occurs for debugging
      toast.error('No onFeedbackSubmit function');
    }
  };

  const doResetState = () => {
    setFeedbackStatus(ActionStatusConstants.INITIAL);
    setFeedback('');
  };

  const isBusy = feedbackStatus === ActionStatusConstants.ISBUSY;

  return (
    <ModalPanel
      className="feedback-modal-panel"
      onClose={doResetState}
      header={title ?? 'Help us improve'}
      body={
        <div className="feedback-form">
          <div className="label">{subtitle ?? 'Please add your comments below'}</div>
          {isIE ? (
            <div className="feedback-form-textarea__wrapper">
              <textarea
                className="feedback-form-textarea"
                onChange={(e) => setFeedback(e.target.value)}
                maxLength={4000}
                disabled={feedbackStatus === ActionStatusConstants.ISBUSY}
                placeholder="Enter some text..."
              />
              <span className="feedback-form-textarea-counter">{feedback.length} / 4000</span>
            </div>
          ) : (
            <SlateRTE
              value={feedback}
              setConvertedValue={(v) => setFeedback(v)}
              resetSentinel={feedback === ''}
              maxLength={4000}
              disabled={feedbackStatus === ActionStatusConstants.ISBUSY}
            />
          )}
        </div>
      }
      footer={({ setIsVisible }) => (
        <>
          <button
            type="button"
            onClick={() => setIsVisible(false)}
            className="footer-btn footer-btn--secondary"
          >
            {cancelBtnLabel ?? 'Cancel'}
          </button>
          <button
            type="button"
            className="footer-btn footer-btn--primary"
            onClick={() => onClickSubmit(setIsVisible)}
            disabled={isBusy}
          >
            {submitBtnLabel ?? 'Submit'}
          </button>
        </>
      )}
    >
      {({ setIsVisible }) => typeof children === 'function' && children({ setIsVisible })}
    </ModalPanel>
  );
};

FeedbackModalPanel.propTypes = {
  onFeedbackSubmit: PropTypes.func,
  children: PropTypes.func,
};

export { FeedbackModalPanel };
