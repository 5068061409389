import React from 'react';

import { formatMinDateStamp } from 'helpers/TimeUtils';
import { getDefaultTableColumnDef, toTitleCase } from 'helpers/Utils';

import { CustomBotConfigUtils } from './CustomBotConfigUtils';
import { IdsConfigActions } from './IdsConfigActions';

const ImgBotEngineConfigTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('id', 'ID'),
      sort: false,
      formatter: (_cell, row) => {
        return row?.item?.id ?? 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('name', 'Name'),
      sort: false,
      formatter: (_cell, row) => {
        return row?.item?.name ?? 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('updated_at', 'Last Updated'),
      sort: false,
      formatter: (_cell, row) => {
        return row?.item?.created_at || row?.item?.updated_at
          ? formatMinDateStamp(row?.item?.updated_at ?? row?.item?.created_at)
          : 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('stage', 'Stage'),
      sort: false,
      formatter: (_cell, row) => {
        return CustomBotConfigUtils.getConfigTypeConst(row?.item)?.label || 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('type', 'Type'),
      sort: false,
      formatter: (_cell, row) => {
        return toTitleCase(row?.item?.configuration_type?.toUpperCase()) || 'N/A';
      },
    },
    {
      ...getDefaultTableColumnDef('actions'),
      sort: false,
      formatter: (_cell, row) => (
        <IdsConfigActions
          configObj={row.item}
          onSaveData={row.onSaveData}
          onSetAsDefault={row.onSetAsDefault}
          onDelete={row.onDelete}
          isLoading={row.isLoading}
        />
      ),
    },
  ],
};

export { ImgBotEngineConfigTableDef };
