import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { useIsMounted } from 'helpers/useIsMounted';

import './ExportFunctionalityAdvanced.scss';
import { CreateTemplateModalPanel } from './CreateTemplateModalPanel';
import * as ExportFunctionalityAdvancedApiCalls from './ExportFunctionalityAdvancedApiCalls';

/**
 * Actions for advanced export screen.
 *
 * @param {object} templateData Current template obj
 * @param {object} mfrSelectValue Selected mfr value obj
 * @param {boolean} canStartExport Export enable flag
 * @param {boolean} canResetTemplate Reset enable flag
 * @param {function} onSaveTemplate Save Template click handler
 * @param {function} onResetTemplate Reset Template click handler
 * @param {function} onStartExport Start Export click handler
 * @param {boolean} isDisabled Disable actions flag
 * @return render
 */
const BlockActions = ({
  templateData,
  mfrSelectValue,
  canStartExport,
  canResetTemplate,
  onSaveTemplate,
  onResetTemplate,
  onStartExport,
  isDisabled,
}) => {
  const isMounted = useIsMounted();

  const [saveTemplateStatus, setSaveTemplateStatus] = useState(ActionStatusConstants.INITIAL);
  const [startExportStatus, setStartExportStatus] = useState(ActionStatusConstants.INITIAL);

  const doSaveTemplate = () => {
    ExportFunctionalityAdvancedApiCalls.doSaveTemplate({
      template: templateData,
      isMounted,
      onSaveTemplate,
      setSaveTemplateStatus,
    });
  };

  const doStartExport = () => {
    const reqTemplateData = _cloneDeep(templateData);
    if (mfrSelectValue.manufacturerChild?.id !== undefined) {
      reqTemplateData.manufacturer_id = mfrSelectValue?.manufacturerChild?.id;
    } else {
      reqTemplateData.manufacturer_id = mfrSelectValue?.manufacturer?.id;
    }
    reqTemplateData.distributor_id = templateData?.distributor?.id ?? null;
    delete reqTemplateData.manufacturer;
    delete reqTemplateData.distributor;

    ExportFunctionalityAdvancedApiCalls.doStartExport({
      template: reqTemplateData,
      isMounted,
      onStartExport,
      setStartExportStatus,
    });
  };

  const _isDisabled =
    isDisabled ||
    saveTemplateStatus === ActionStatusConstants.ISBUSY ||
    startExportStatus === ActionStatusConstants.ISBUSY;

  return (
    <section className="block block-actions">
      <div className="template-actions">
        {!!templateData?.id && (
          <ConfirmDialog
            onConfirm={doSaveTemplate}
            headerContent="Save Template?"
            bodyContent="Are you sure you want to save this template?"
          >
            {({ onClick }) => (
              <button
                type="button"
                title="Save Template"
                className="btn btn-secondary btn-save-template"
                disabled={_isDisabled || !canResetTemplate}
                onClick={onClick}
              >
                <span className="icon">
                  {saveTemplateStatus === ActionStatusConstants.ISBUSY ? (
                    <LoadingSpinner fast />
                  ) : (
                    <FontAwesomeIcon icon={['far', 'save']} />
                  )}
                </span>
                Save Template
              </button>
            )}
          </ConfirmDialog>
        )}
        <CreateTemplateModalPanel
          templateData={templateData}
          initMfrSelectValue={mfrSelectValue}
          onSaveTemplate={onSaveTemplate}
        >
          {({ setIsVisible }) => (
            <button
              type="button"
              title="Create New Template"
              className="btn btn-secondary btn-create-template"
              disabled={_isDisabled}
              onClick={() => setIsVisible(true)}
            >
              <span className="icon">
                <FontAwesomeIcon icon={['far', 'file-plus']} />
              </span>
              Create New Template
            </button>
          )}
        </CreateTemplateModalPanel>
        {canResetTemplate && (
          <ConfirmDialog
            onConfirm={onResetTemplate}
            headerContent="Reset Template?"
            bodyContent="Are you sure you want to revert all changes to template?"
          >
            {({ onClick }) => (
              <button
                type="button"
                title="Reset Template"
                className="btn btn-secondary btn-reset-template"
                disabled={_isDisabled}
                onClick={onClick}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={['far', 'undo']} />
                </span>
                Reset Template
              </button>
            )}
          </ConfirmDialog>
        )}
      </div>
      <div className="export-actions">
        <ConfirmDialog
          onConfirm={doStartExport}
          headerContent="Start Export?"
          bodyContent="Are you sure you want to start the export?"
        >
          {({ onClick }) => (
            <button
              type="button"
              title="Start Export"
              className="btn btn-primary btn-lg btn-start-export"
              disabled={!canStartExport || _isDisabled}
              onClick={onClick}
            >
              <span className="icon">
                {startExportStatus === ActionStatusConstants.ISBUSY ? (
                  <LoadingSpinner fast />
                ) : (
                  <FontAwesomeIcon icon={['far', 'play-circle']} />
                )}
              </span>
              Start Export
            </button>
          )}
        </ConfirmDialog>
      </div>
    </section>
  );
};

BlockActions.defaultProps = {
  templateData: null,
  mfrSelectValue: null,
  canStartExport: false,
  canResetTemplate: false,
  isDisabled: false,
};

BlockActions.propTypes = {
  templateData: PropTypes.object,
  mfrSelectValue: PropTypes.object,
  canStartExport: PropTypes.bool,
  canResetTemplate: PropTypes.bool,
  onSaveTemplate: PropTypes.func.isRequired,
  onResetTemplate: PropTypes.func.isRequired,
  onStartExport: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export { BlockActions };
