import './SectionImageQuality.scss';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ImageQualityRow } from 'components/image-quality-assessment/ImageQualityRow/ImageQualityRow';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import UserRoleConstants from 'constants/UserRoleConstants';

/**
 * Render Image Quality section
 *
 * @param {object} imageQualityAssessmentData Image Quality Assessment Data object
 * @param {object} imageQualityAssessmentDataStatus Status
 * @param {number} id manufacturer or supplier id
 * @param {string} type 'manufacturer' or 'distributor'
 * @param {string} title Custom title string
 * @return render
 */
const SectionImageQuality = ({
  imageQualityAssessmentData,
  imageQualityAssessmentDataStatus,
  id,
  type,
  title,
}) => {
  return (
    <section className="image-quality">
      <div className="heading">
        <div className="title">
          <span>{title?.length ? title : 'Image Quality Assessments'}</span>
          {[ActionStatusConstants.ISBUSY, ActionStatusConstants.INITIAL].includes(
            imageQualityAssessmentDataStatus
          ) ? (
            <LoadingSpinner fast />
          ) : null}
        </div>
        <div className="actions">
          {[ActionStatusConstants.ISBUSY, ActionStatusConstants.INITIAL].includes(
            imageQualityAssessmentDataStatus
          ) ? null : (
            <Link to={`/image-quality/assessment/${type}/${id}`}>
              <button type="button" className="btn btn-secondary btn-sm">
                <span className="label">View All Image Quality Assessments</span>
                <span className="icon" style={{ marginLeft: '0.5em' }}>
                  <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
                </span>
              </button>
            </Link>
          )}
        </div>
      </div>
      <div className="image-quality-wrap">
        {imageQualityAssessmentData?.map((assessment) => (
          <ImageQualityRow key={assessment.id} type={type} data={assessment} />
        ))}
      </div>
    </section>
  );
};

SectionImageQuality.defaultProps = {
  id: null,
  title: null,
};

SectionImageQuality.propTypes = {
  id: PropTypes.number,
  type: PropTypes.oneOf([
    UserRoleConstants.MANUFACTURER.toLowerCase(),
    UserRoleConstants.DISTRIBUTOR.toLowerCase(),
  ]).isRequired,
  title: PropTypes.string,
};

export { SectionImageQuality };
