import React, { useEffect, useRef, useState } from 'react';

import queryString from 'query-string';

import * as ApiCalls from 'api/ApiCalls';
import { useIsMounted } from 'helpers/useIsMounted';
import { useOutsideClick } from 'helpers/useOutsideClick';

import { Search } from './common/Search/Search';
import { SearchSuggestions } from './common/SearchSuggestions/SearchSuggestions';

const MfrSearch = ({ value = '', onSearchSubmit, selectedMfrs }) => {
  const [search, setSearch] = useState('');
  const [localSearch, setLocalSearch] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
  const [areSuggestionsLoading, setAreSuggestionsLoading] = useState(false);
  const searchWrapperRef = useRef(null);
  const isMounted = useIsMounted();

  const handleSearchSubmit = (value) => {
    const mfrName = value ?? localSearch;
    const mfrId = searchSuggestions.find((suggestion) => suggestion.value === mfrName)?.id;
    if (mfrId === null || mfrId === undefined) return;

    onSearchSubmit({
      value: mfrId,
      label: mfrName,
    });
    setAreSuggestionsVisible(false);
    setLocalSearch('');
    setSearch('');
  };

  useEffect(() => {
    setSearch(value);
    setLocalSearch(value);
  }, [value]);

  useEffect(() => {
    const query = { suggest: true };
    if (search) query.search = search;
    else {
      setSearchSuggestions([]);
      setAreSuggestionsLoading(false);
      return;
    }
    const url = `/manufacturers/?${queryString.stringify(query)}`;

    setAreSuggestionsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: url,
      onSuccess: (res) => {
        const manufacturers = res.data;
        const suggestions = manufacturers
          .filter((mfr) => !selectedMfrs.find((selectedMfr) => selectedMfr.label === mfr.name))
          .map((mfr) => {
            return { value: mfr.name, id: mfr.id };
          });

        if (isMounted.current) setSearchSuggestions(suggestions);
      },
      onEnd: () => {
        setAreSuggestionsLoading(false);
      },
    });
  }, [search, isMounted, selectedMfrs]);

  useOutsideClick(searchWrapperRef, () => {
    if (areSuggestionsVisible) setAreSuggestionsVisible(false);
  });

  return (
    <div ref={searchWrapperRef} className="mfr-search">
      <Search
        onFocus={() => setAreSuggestionsVisible(true)}
        search={search}
        withButton={false}
        setLocalSearch={setLocalSearch}
        setSearch={setSearch}
        onSearchSubmit={handleSearchSubmit}
        placeholder="Manufacturer Name"
        onKeyDown={(e) => {
          if (e && e.key === 'Enter') {
            handleSearchSubmit();
          }
        }}
      />
      <SearchSuggestions
        search={search}
        setLocalSearch={setLocalSearch}
        setSearch={setSearch}
        searchSuggestions={searchSuggestions}
        localSearch={localSearch}
        setAreSuggestionsVisible={setAreSuggestionsVisible}
        areSuggestionsVisible={areSuggestionsVisible}
        areSuggestionsLoading={areSuggestionsLoading}
        onSuggestionSelected={handleSearchSubmit}
      />
    </div>
  );
};

export { MfrSearch };
