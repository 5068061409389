import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { OnBlurInputProxy } from 'components/common/OnBlurInputProxy/OnBlurInputProxy';
import { getDefaultTableColumnDef } from 'helpers/Utils';

export const DetailedAttributesTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('export', 'Select Label'),
      sort: false,
      formatter: (cell, row) => {
        const output = (
          <>
            <Form.Check
              type="checkbox"
              className={classNames({
                'is-changed':
                  row?.templateValue?.is_changed !== row?.originalTemplateValue?.is_changed,
              })}
              checked={!!row?.templateValue?.is_changed}
              readOnly
              onClick={() => {
                typeof row.onChangeRowValues === 'function' &&
                  row.onChangeRowValues(
                    row.id,
                    row.key,
                    !row?.templateValue?.is_changed,
                    row?.templateValue?.corrected_label
                  );
              }}
            />
          </>
        );

        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('name', 'Current Label'),
       sort: true,
      formatter: (cell, row) => (
        <div className="attribute-name-key-cell">
          <div className="attribute-name">{row.name}</div>
          <div className="attribute-key">
            <span className="label">key:</span> <span className="value">{row.key}</span>
            {!!row.isDuplicateKey && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Multiple attributes use this key</Tooltip>}
                // trigger={isChanged ? ['hover', 'focus'] : null}
              >
                <span className="icon duplicate-icon">
                  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                </span>
              </OverlayTrigger>
            )}
          </div>
        </div>
      ),
    },
    {
      ...getDefaultTableColumnDef('product_count', 'Product Count'),
    },
    {
      ...getDefaultTableColumnDef('custom_label', 'Corrected Label'),
      sort: false,
      formatter: (cell, row) => {
        const isChanged =
          row?.templateValue?.corrected_label !== row?.originalTemplateValue?.corrected_label;

        const output = (
          <div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Changed from {!row?.originalTemplateValue?.corrected_label && 'default'}{' '}
                  <b>
                    &quot;
                    {row?.originalTemplateValue?.corrected_label
                      ? row.templateValue.corrected_label
                      : row.name}
                    &quot;
                  </b>
                </Tooltip>
              }
              trigger={isChanged ? ['hover', 'focus'] : null}
            >
              <OnBlurInputProxy
                value={row?.templateValue?.corrected_label || ''}
                onChange={(v) =>
                  typeof row.onChangeRowValues === 'function' &&
                  row.onChangeRowValues(row.id, row.key, row?.templateValue?.is_changed, v)
                }
              >
                {({ value, onChange, onBlur }) => (
                  <Form.Control
                    type="text"
                    className={classNames({
                      'is-changed': isChanged,
                    })}
                    maxLength="101"
                    value={value || ''}
                    onChange={(e) => onChange(e.target.value)}
                    isInvalid={row?.templateValue?.is_invalid}
                    onBlur={() => onBlur()}
                    disabled={row?.isDisabled || !row?.templateValue?.is_changed}
                  />
                )}
              </OnBlurInputProxy>
            </OverlayTrigger>
            <div className="invalid-feedback">{row?.templateValue?.errors}</div>
          </div>
        );

        return output;
      },
    },
  ],
};
