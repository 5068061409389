import React, { useState, createContext, useEffect, useCallback } from 'react';

import moment from 'moment';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { Search } from 'components/common/Search/Search';
import { WeeklyCompanyCards } from 'components/metrics/WeeklyCompanyCards/WeeklyCompanyCards';
import { WeeklyOverview } from 'components/metrics/WeeklyOverview/WeeklyOverview';
import { formatDate8601, convertIso8601ToDate } from 'helpers/TimeUtils';

const WeeklyMetricsContext = createContext(null);

const ViewWeeklyMetrics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(moment().day(1).toDate());
  const [endDate, setEndDate] = useState(moment().day(7).toDate());
  const [metricsSortDir, setMetricsSortDir] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const tier = 1;
  const history = useHistory();
  const urlParams = window.location.search;

  const handleViewDashboard = (type, id) => {
    const route = type === 'manufacturer' ? 'manufacturer' : 'distributor';

    history.push({
      pathname: `/weekly-metrics/${route}/${id}`,
    });
  };

  const updateHistoryState = useCallback(
    (search) => {
      history.replace({ search });
    },
    [history]
  );

  useEffect(() => {
    const params = queryString.parse(urlParams);
    setIsLoading(true);

    if (isInitialLoad) {
      const { search, startDate, endDate } = params;

      if (search) setSearch(search);
      if (startDate) setStartDate(convertIso8601ToDate(startDate));
      if (endDate) setEndDate(convertIso8601ToDate(endDate));

      setIsInitialLoad(false);
    } else {
      const state = {
        startDate: params.startDate,
        endDate: params.endDate,
        search: params.search,
      };
      const isoStartDate = formatDate8601(startDate);
      const isoEndDate = formatDate8601(endDate);

      if (search !== params.search && search.length > 0) state.search = search;
      else if (search.length === 0) delete state.search;

      if (isoStartDate !== params.startDate) state.startDate = isoStartDate;
      if (isoEndDate !== params.endDate) state.endDate = isoEndDate;

      updateHistoryState(queryString.stringify(state));
    }

    setIsLoading(false);
  }, [search, startDate, endDate, updateHistoryState, isInitialLoad, urlParams]);

  useEffect(() => {
    const params = queryString.parse(urlParams);
    const { sortDir } = params;
    if (sortDir) setMetricsSortDir(sortDir);
  }, [urlParams]);

  return (
    <WeeklyMetricsContext.Provider value={{ isLoading, setIsLoading }}>
      <div className="content content-fluid view-weekly-metrics">
        <Helmet bodyAttributes={{ 'data-page': 'weekly-metrics' }}>
          <title>Weekly Dashboard Insights</title>
        </Helmet>
        {isLoading ? (
          <LoadingView />
        ) : (
          <>
            <WeeklyOverview
              tier={tier}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <Search
              placeholder="Search Customers... (3M, Ammega)"
              search={search}
              setSearch={setSearch}
            />
            <WeeklyCompanyCards
              sortDir={metricsSortDir}
              handleViewDashboard={handleViewDashboard}
              tier={tier}
              startDate={startDate}
              endDate={endDate}
              search={search}
            />
          </>
        )}
      </div>
    </WeeklyMetricsContext.Provider>
  );
};

export { ViewWeeklyMetrics };
