import React, { useState } from 'react';

import { OKModal } from 'components/common/ModalPanel/OKModal';
import { SelectionModal } from 'components/common/ModalPanel/SelectionModal';
import { DATA_JOB_DIRECTION_CONSTANTS } from 'constants/DataJobCreateConstants';

// Direction values
const requestJob = DATA_JOB_DIRECTION_CONSTANTS.REQUEST_DATA_JOB;
const sendJob = DATA_JOB_DIRECTION_CONSTANTS.SEND_DATA_JOB;

/**
 * Modal for publishing a draft data job. User selects job direction then confirms publish.
 *
 * @param {function} setShowPublishModal
 * @param {number} showPublishModal  - id is job id
 * @param {function} doPublishJob
 * @return render
 */
const ModalPublishDraftJob = ({ setShowPublishModal, showPublishModal, doPublishJob }) => {
  const [directionValue, setDirectionValue] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showDirectionModal, setShowDirectionModal] = useState(showPublishModal);

  const selectRequestData = (value) => {
    setDirectionValue(value);
  };

  const onClose = (selection) => {
    setShowDirectionModal(false);
    setShowWarningModal(true);
    setDirectionValue(selection);
  };

  const selectionModalOptions = [
    {
      title: 'Request Data',
      key: requestJob,
      description: 'You need information about a product or something else...',
      callback: () => selectRequestData(requestJob),
      ischecked: directionValue === requestJob,
    },
    {
      title: 'Send Data',
      key: sendJob,
      description: 'You want to publish about a product or explain something else...',
      callback: () => setDirectionValue(sendJob),
      ischecked: directionValue === sendJob,
    },
  ];

  const doShowWarning = () => {
    const onCloseWarning = () => {
      setShowWarningModal(false);
      setShowPublishModal(false);
    };

    const onContinueWarning = () => {
      setShowPublishModal(false);
      setShowDirectionModal(false);
      doPublishJob(showPublishModal, directionValue.key);
    };

    return (
      <OKModal
        header="Publish Draft Job - Confirm"
        body={
          <span className="confirm-publish-job-modal">
            <p>
              You are about to publish this Data Job to the selected Recipient. All this information
              will now be visible to that company.
            </p>
          </span>
        }
        isVisible={showWarningModal}
        setIsVisible={() => onCloseWarning()}
        onOk={() => onContinueWarning()}
        showCancel
        backdrop="static"
      />
    );
  };

  return (
    <>
      <SelectionModal
        onContinue={(selection) => {
          typeof onClose === 'function' && onClose(selection);
        }}
        dialogClassName="pass-thru-modal-dialog"
        header="Publish Draft Job - Select Job Type"
        options={selectionModalOptions}
        isVisible={showPublishModal && showDirectionModal}
        setIsVisible={(v) => setShowPublishModal(v)}
        backdrop="static"
      />
      {showWarningModal && doShowWarning()}
    </>
  );
};

export { ModalPublishDraftJob };
