import './BotCategoryCard.scss';

import React from 'react';

import { BotInformationalCard } from 'components/databots/BotInformationalCard';

const BotCategoryCard = ({ category, ran }) => {
  return (
    <div className="bot-category-card">
      <div className="bot-category-card-info">
        <h3 className="bot-category-card-info__heading">{category.name}</h3>
        <p className="bot-category-card-info__subheading">{category.description}</p>
      </div>
      <div className="bot-category-card-list">
        {category.bots.map((bot) => (
          <BotInformationalCard ran={ran} key={bot.name} bot={bot} />
        ))}
      </div>
    </div>
  );
};

export { BotCategoryCard };
