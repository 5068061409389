import React from 'react';

import { StatusSelector } from './StatusSelector';

const TitleBlock = ({ dataJobData, setDataJobData }) => {
  return (
    <div className="panel-block data-job-panel-title-block">
      <div className="block-content">
        <div className="title">
          <span className="label">Data Job Details:</span>{' '}
          <span className="value">{dataJobData?.id ? `#${dataJobData.id}` : 'N/A'}</span>
        </div>
        <div className="actions">
          <StatusSelector dataJobData={dataJobData} setDataJobData={setDataJobData} />
        </div>
      </div>
    </div>
  );
};

export { TitleBlock };
