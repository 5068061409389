import styleVars from 'scss/vars.scss';
import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButton } from "react-bootstrap";

export const Button = React.forwardRef(
  ({ children, className, active, reversed, ...props }, ref) => {
    return (
      <ToggleButton
        {...props}
        type="checkbox"
        checked={active}
        ref={ref}
        size="sm"
        variant="outline-secondary"
        className={className}
        style={{
          borderColor: styleVars.colors_designGrey50,
          borderRadius: "0",
          width: "3rem",
        }}
      >
        {children}
      </ToggleButton>
    );
  }
);

export const EditorValue = React.forwardRef(({ className, value, ...props }, ref) => {
  const textLines = value.document.nodes
    .map((node) => node.text)
    .toArray()
    .join("\n");
  return (
    <div
      ref={ref}
      {...props}
      style={{
        margin: "30px -20px 0",
      }}
      className={className}
    >
      <div
        style={{
          fontSize: "14px",
          padding: "5px 20px",
          color: "#404040",
          borderTop: `2px solid ${styleVars.colors_designGrey200}`,
          background: "#f8f8f8",
        }}
      >
        Slate's value as text
      </div>
      <div
        style={{
          color: "#404040",
          font: "12px monospace",
          whiteSpace: "pre-wrap",
          padding: "10px 20px",
          margin: "0 0 0.5em",
        }}
      >
        {textLines}
      </div>
    </div>
  );
});

export const Icon = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <span
      {...props}
      ref={ref}
      style={{
        fontSize: "15px",
        verticalAlign: "text-bottom",
      }}
      className={className}
    >
      <FontAwesomeIcon size="xs" icon={["far", props.children]} />
    </span>
  );
});

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={className} />
));

export const Portal = ({ children }) => {
  return typeof document === "object" ? ReactDOM.createPortal(children, document.body) : null;
};

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu
    {...props}
    ref={ref}
    style={{
      borderBottom: `2px solid ${styleVars.colors_designGrey200}`,
      paddingBottom: "5px",
      margin: "auto",
      display: "inline-flex",
      width: "100%",
      justifyContent: "center",
    }}
    className={className}
  />
));
