import React from 'react';

import noNotificationImage from 'assets/img/no-notifications.svg';

import '../DataJobPanel.scss';

const JobNotFound = (status) => (
  <div>
    <div className="job-not-found">
      <img className="job-not-found" src={noNotificationImage} alt="Backbone" />
      <h3 className="job-not-found">
        {status === 403
          ? 'You do not have permission to view the requested Data Job'
          : 'The requested Data Job does not exist'}
      </h3>
    </div>
  </div>
);

export { JobNotFound };
