import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { useIsMounted } from 'helpers/useIsMounted';

import * as DataLakehouseDashboardApiCalls from './DataLakehouseDashboardApiCalls';

/**
 * Mfr and child company selector
 *
 * @param {object} manufacturerSelection State object for manufacturer/child company selection { mfrId, mfrChildId }
 * @param {function} onChangeManufacturerSelection Event handler for selection change
 * @param {boolean} isDisabled Disabled state
 * @return render
 */
const SectionManufacturerSelect = ({
  manufacturerSelection,
  onChangeManufacturerSelection,
  isDisabled,
}) => {
  const isMounted = useIsMounted();

  // Holds list of manufacturers
  const [mfrsData, setMfrsData] = useState(null);
  const [mfrsDataStatus, setMfrsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds list of child mfrs per selected mfr
  const [childMfrsData, setChildMfrsData] = useState(null);
  const [childMfrsDataStatus, setChildMfrsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Init mfrs list
  useEffect(() => {
    DataLakehouseDashboardApiCalls.doGetMfrs({
      isMounted,
      setMfrsData,
      setMfrsDataStatus,
    });
  }, [isMounted]);

  // Int child mfrs list if newly selected mfr has_children
  const manufacturerSelectionMfrId = manufacturerSelection?.mfrId ?? null;
  useEffect(() => {
    let hasChildren = false;

    if (mfrsData?.length && manufacturerSelectionMfrId) {
      const mfrDataItem = mfrsData.find((item) => item.id === manufacturerSelectionMfrId);

      hasChildren = !!mfrDataItem?.has_children;
    }

    if (hasChildren) {
      DataLakehouseDashboardApiCalls.doGetChildMfrs({
        mfrId: manufacturerSelectionMfrId,
        isMounted,
        setChildMfrsData,
        setChildMfrsDataStatus,
      });
    } else {
      setChildMfrsData(null);
    }
  }, [isMounted, mfrsData, manufacturerSelectionMfrId]);

  // Generates a Select value object from object in data list, by id
  const getSelectValue = (list, id) => {
    if (id && list?.length) {
      const dataItem = list.find((item) => item.id === id);

      if (dataItem) {
        return { value: dataItem.id, label: dataItem.name, obj: dataItem };
      }
    }

    return [];
  };

  const _isBusy = [mfrsDataStatus, childMfrsDataStatus].includes(ActionStatusConstants.ISBUSY);

  const _isDisabled = isDisabled || _isBusy;

  return (
    <section className="manufacturer-select">
      <div className="manufacturer">
        <div className="title">
          <span>Manufacturer</span>
          {_isBusy && <LoadingSpinner fast />}
        </div>
        <StyledMultiselect
          values={getSelectValue(mfrsData, manufacturerSelection?.mfrId)}
          options={
            mfrsData
              ? mfrsData.map((item) => {
                  return { value: item.id, label: item.name, obj: item };
                })
              : []
          }
          setOnChange={(v) => {
            onChangeManufacturerSelection({
              ...manufacturerSelection,
              mfrId: v?.value ?? null,
              mfrChildId: null,
            });
          }}
          getOptionValue={(option) => option.value}
          closeMenuOnSelect
          isSearchable
          isDisabled={_isDisabled}
          isMulti={false}
          isClearable
          canReset
        />
      </div>
      {!!manufacturerSelection?.mfrId && !!childMfrsData?.length && (
        <div className="child-company">
          <div className="title">Level 1 Brand</div>
          <StyledMultiselect
            values={getSelectValue(childMfrsData, manufacturerSelection?.mfrChildId)}
            options={
              childMfrsData
                ? childMfrsData.map((item) => {
                    return { value: item.id, label: item.name, obj: item };
                  })
                : []
            }
            setOnChange={(v) => {
              onChangeManufacturerSelection({
                ...manufacturerSelection,
                mfrChildId: v?.value ?? null,
              });
            }}
            getOptionValue={(option) => option.value}
            closeMenuOnSelect
            isSearchable
            isDisabled={_isDisabled}
            isMulti={false}
            isClearable
            canReset
          />
        </div>
      )}
    </section>
  );
};

SectionManufacturerSelect.defaultProps = {
  manufacturerSelection: null,
  isDisabled: false,
};

SectionManufacturerSelect.propTypes = {
  manufacturerSelection: PropTypes.shape({ mfrId: PropTypes.number, mfrChildId: PropTypes.number }),
  onChangeManufacturerSelection: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export { SectionManufacturerSelect };
