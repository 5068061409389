import './PassThruFileDetailSelection.scss';

import React, { useRef, useCallback, useEffect, useState } from 'react';

import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Form, Col } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import PassThruFileCreateConstants from 'constants/PassThruFileCreateConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';
import { RootHooks } from 'helpers/RootHooks';

const saveAttributes = (id, attr) => {
  window.localStorage.setItem(
    `${id}_attrList`,
    JSON.stringify({ data: attr.data, date: moment() })
  );
};

const PassThruFileDetailSelection = ({ details, invalidCheck, setDetails }) => {
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();

  const nameWordtimer = useRef(null);
  const descWordtimer = useRef(null);
  const [attrList, setAttrList] = useState(null);
  const [adminDistributorList, setAdminDistributorList] = useState(null);
  const [adminManufacturerList, setAdminManufacturerList] = useState(null);
  const [cachedManufacturerLists, setCachedManufacturerLists] = useState([]);
  const [senderList, setSenderList] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState([details.recipient]);
  const [selectedDistributor, setSelectedDistributor] = useState([details.distributor]);
  const [selectedManufacturer, setSelectedManufacturer] = useState([details.manufacturer]);
  const [selectedSender, setSelectedSender] = useState([details.sender]);

  const updateManufacturerList = useCallback(
    (fallbackList) => {
      if (!activeUser || !isMounted.current) return;
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/manufacturers/`,
        onSuccess: (r) => {
          saveAttributes(activeUser.id, r);
          if (isMounted.current) {
            setAttrList(r.data);
          }
        },
        onError: () => {
          if (isMounted.current) {
            if (fallbackList) {
              setAttrList(fallbackList);
            } else {
              setAttrList(null);
            }
          }
        },
      });
    },
    [activeUser, isMounted]
  );

  const updateDistributorList = useCallback(
    (fallbackList) => {
      if (!activeUser || !isMounted.current) return;
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/distributors/`,
        onSuccess: (r) => {
          saveAttributes(activeUser.id, r);
          if (isMounted.current) {
            activeUser.role === UserRoleConstants.ADMIN
              ? setAdminDistributorList(r.data)
              : setAttrList(r.data);
          }
        },
        onError: () => {
          if (isMounted.current) {
            if (fallbackList) {
              setAttrList(fallbackList);
            } else {
              setAttrList(null);
            }
          }
        },
      });
    },
    [activeUser, isMounted]
  );

  useEffect(() => {
    return () => {
      if (nameWordtimer.current) {
        clearTimeout(nameWordtimer.current);
        nameWordtimer.current = null;
      }
      if (descWordtimer.current) {
        clearTimeout(descWordtimer.current);
        descWordtimer.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // first check local storage for user id and see if list is more than 3 days out of date
    // this can be independent of user type
    if (!activeUser || !isMounted.current) return;
    const attrListString = window.localStorage.getItem(`${activeUser.id}_attrList`);
    let attrListLocal = null;
    if (attrListString) {
      try {
        attrListLocal = JSON.parse(attrListString);
      } catch {
        console.warn('Trouble parsing attribute list for data job create');
      }
    }
    if (activeUser.role === UserRoleConstants.CLIENT) {
      updateManufacturerList(attrListLocal ? attrListLocal.data : null);
    } else if (activeUser.role === UserRoleConstants.SUPPLIER) {
      updateDistributorList(attrListLocal ? attrListLocal.data : null);
    } else {
      updateManufacturerList(null);
      updateDistributorList(null);
    }
  }, [activeUser, isMounted, updateDistributorList, updateManufacturerList]);

  const getRecipientDropOptions = useCallback(
    () =>
      (attrList &&
        sortByKey(
          attrList.map((item) => ({ value: item.id, label: item.name, obj: item })),
          'label'
        )) ||
      [],
    [attrList]
  );

  const getDistributorDropOptions = useCallback(
    () =>
      (adminDistributorList &&
        sortByKey(
          adminDistributorList.map((item) => ({ value: item.id, label: item.name, obj: item })),
          'label'
        )) ||
      [],
    [adminDistributorList]
  );

  const getManufacturerDropOptions = useCallback(
    () =>
      // Appended the "manufacturer" tag since across the manufacturer and client objects, some records may have the same id, causing some options to not be displayed in the dropdown
      (adminManufacturerList &&
        sortByKey(
          adminManufacturerList.map((item) => ({
            value: `manufacturer-${item.id}`,
            label: item.name,
            obj: item,
          })),
          'label'
        )) ||
      [],
    [adminManufacturerList]
  );

  const getSenderDropOptions = useCallback(() => {
    return sortByKey(senderList, 'label') || [];
  }, [senderList]);

  const onRecipientSelected = (value) => {
    setSelectedRecipient([value]);
    setDetails({
      type: PassThruFileCreateConstants.RECIPIENT,
      payload: value.id ? value.id : value.value,
    });
    if (activeUser.role !== UserRoleConstants.ADMIN) {
      // Distributors and Manufacutrers only have to set the recipient. Fill their other data here:
      if ([UserRoleConstants.CLIENT, UserRoleConstants.DISTRIBUTOR].includes(activeUser.role)) {
        setDetails({ type: PassThruFileCreateConstants.DISTRIBUTOR, payload: activeUser.id });
        setDetails({ type: PassThruFileCreateConstants.MANUFACTURER, payload: value });
      } else {
        setDetails({ type: PassThruFileCreateConstants.DISTRIBUTOR, payload: value });
        setDetails({ type: PassThruFileCreateConstants.MANUFACTURER, payload: activeUser.id });
      }
      setDetails({ type: PassThruFileCreateConstants.SENDER, payload: activeUser });

    }
  };

  const onSenderSelected = (value) => {
    setSelectedSender(value);
    if (value !== null && typeof value.value === 'string') {
      value = {
        ...value,
        value: Number(value.value.replace('manufacturer-', '')),
      };
      setDetails({ type: PassThruFileCreateConstants.SENDER_TYPE, payload: 'manufacturer' });
      setDetails({
        type: PassThruFileCreateConstants.RECIPIENT,
        payload: details.distributor.obj.id,
      });
    } else if (value !== null && typeof value.value !== 'string') {
      setDetails({
        type: PassThruFileCreateConstants.RECIPIENT,
        payload: details.manufacturer.obj.id,
      });
    } else {
      setDetails({ type: PassThruFileCreateConstants.SENDER_TYPE, payload: 'client' });
    }
    setDetails({ type: PassThruFileCreateConstants.SENDER, payload: value });
  };

  const updateSenderList = (type, value) => {
    let newSenderList = [];
    if (type === PassThruFileCreateConstants.MANUFACTURER) {
      newSenderList = [...selectedDistributor, value].filter((el) => el !== null);
    } else if (type === PassThruFileCreateConstants.DISTRIBUTOR) {
      setSelectedManufacturer([]);
      newSenderList = [value].filter((el) => el !== null);
    }

    if (selectedSender !== null && !newSenderList.some((el) => el.label === selectedSender.label)) {
      onSenderSelected(null);
    }
    setSenderList(newSenderList);
  };

  const onDistributorSelected = (value) => {
    setSelectedDistributor([value]);

    const clientIndex = cachedManufacturerLists.findIndex((client) => client.id === value.value);
    if (clientIndex === -1)
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/distributors/${value.value}`,
        onSuccess: (r) => {
          if (isMounted.current) {
            setCachedManufacturerLists([...cachedManufacturerLists, r.data]);
            setAdminManufacturerList(r.data.manufacturers);
          }
        },
      });
    else {
      setAdminManufacturerList(cachedManufacturerLists[clientIndex].manufacturers);
    }

    setDetails({ type: PassThruFileCreateConstants.DISTRIBUTOR, payload: value });
    updateSenderList(PassThruFileCreateConstants.DISTRIBUTOR, value);
  };

  const onManufacturerSelected = (value) => {
    setSelectedManufacturer([value]);
    updateSenderList(PassThruFileCreateConstants.MANUFACTURER, value);

    value = value ? { ...value, value: Number(value.value.replace('manufacturer-', '')) } : null;
    setDetails({ type: PassThruFileCreateConstants.MANUFACTURER, payload: value });
  };

  const isDisabled = () => !attrList;

  const SelectRecipient = () => {
    return (
      <StyledMultiselect
        id="recipSelect"
        className={invalidCheck.recipient ? 'is-invalid' : null}
        values={selectedRecipient || []}
        options={getRecipientDropOptions()}
        setOnChange={onRecipientSelected}
        isSearchable
        isMulti={false}
        isDisabled={isDisabled()}
        isInvalid={invalidCheck.recipient}
      />
    );
  };

  const SelectDistributor = () => {
    return (
      <StyledMultiselect
        id="distribSelect"
        className={invalidCheck.distributor ? 'is-invalid' : null}
        values={selectedDistributor || []}
        options={getDistributorDropOptions()}
        setOnChange={onDistributorSelected}
        isSearchable
        isMulti={false}
        isDisabled={!adminDistributorList}
        isInvalid={invalidCheck.distributor}
      />
    );
  };

  const SelectManufacturer = () => {
    return (
      <StyledMultiselect
        id="mfrSelect"
        className={invalidCheck.manufacturer ? 'is-invalid' : null}
        values={selectedManufacturer || []}
        options={getManufacturerDropOptions()}
        setOnChange={onManufacturerSelected}
        isSearchable
        isMulti={false}
        isDisabled={!adminManufacturerList}
        isInvalid={invalidCheck.manufacturer}
      />
    );
  };

  const SelectSender = () => {
    return (
      <StyledMultiselect
        id="reqSelect"
        className={invalidCheck.sender ? 'is-invalid' : null}
        values={selectedSender || []}
        options={getSenderDropOptions()}
        setOnChange={onSenderSelected}
        isSearchable
        isMulti={false}
        isDisabled={!senderList}
        isInvalid={invalidCheck.sender}
      />
    );
  };

  const renderSelectFields = () => {
    return activeUser.role === UserRoleConstants.ADMIN ? (
      <Form.Row>
        <Form.Group as={Col} controlId="distributor.controlInp">
          <Form.Label className="data-job-details-selection-text">Distributor *</Form.Label>
          <Form.Control as={SelectDistributor} />
          <Form.Control.Feedback type="invalid">Please select a distributor!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="manufacturer.controlInp">
          <Form.Label className="data-job-details-selection-text">Manufacturer *</Form.Label>
          <Form.Control as={SelectManufacturer} />
          <Form.Control.Feedback type="invalid">
            Please select a manufacturer!
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="request.controlInp">
          <Form.Label className="data-job-details-selection-text">Define Sender *</Form.Label>
          <Form.Control as={SelectSender} />
          <Form.Control.Feedback type="invalid">Please select a sender!</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    ) : (
      <Form.Group controlId="recipient.controlInp">
        <Form.Label className="data-job-details-selection-text">Recipient *</Form.Label>
        <Form.Control as={SelectRecipient} />
        <Form.Control.Feedback type="invalid">Please select a recipient!</Form.Control.Feedback>
      </Form.Group>
    );
  };

  return (
    <section className="data-job-details-selection-wrapper">
      <Form noValidate>{renderSelectFields()}</Form>
    </section>
  );
};

PassThruFileDetailSelection.propTypes = {
  setDetails: PropTypes.func.isRequired,
  invalidCheck: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
};

export { PassThruFileDetailSelection };
