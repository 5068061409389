import "./DistributorJobLinkSearch.scss";

import React, { useEffect, useCallback, useRef, useState } from "react";

import _debounce from "lodash/debounce";
import PropTypes from "prop-types";

import * as ApiCalls from "api/ApiCalls";
import { SearchBarWDropdown } from "components/common/SearchBarWDropdown/SearchBarWDropdown";

const sortData = (res, viewingId, excludeValues) =>
  res.reduce((arr, v) => {
    if (v.id !== viewingId && !excludeValues[v.id]) {
      return [
        ...arr,
        {
          value: v.id,
          label: `${v.id} - ${v.name}`,
          obj: v,
        },
      ];
    }
    return arr;
  }, []);

/**
 * JobLinkSearch.
 *
 * @param {Object} - props
 * @callback - props.onEdit - function called when data object is changed
 * @param - {bool} - props.showSelection - bool whether to disiplay linked data object values
 * @param - {string} - props.viewingId - currently viewed - current page -  data job/data request id
 */
const JobLinkSearch = ({ onEdit, data, showSelection = false, viewingId = "", className }) => {
  const isMounted = useRef(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [excludeValues, setExcludeValues] = useState({ target: [], source: [] });
  const [suggestedDataJob, setSuggestedDataJob] = useState([]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // create hashmap so have to dont nested loop in component
    setExcludeValues(
      data.reduce((arr, cur) => {
        let val;
        cur.link_type === "target"
          ? (val = { ...arr, target: { ...arr.target, [cur.target_id]: cur.target_name } })
          : (val = { ...arr, source: { ...arr.source, [cur.source_id]: cur.source_name } });
        return val;
      }, {})
    );
  }, [data]);

  const getSuggestList = useCallback(() => {
    if (suggestedDataJob.length) {
      return;
    }
    let urlPath = `/data-requests/data-job/suggest`;
    if (viewingId) {
      urlPath = `/data-requests/data-job/suggest?id=${viewingId}`;
    }
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath,
      onSuccess: (res) => {
        if (isMounted.current) {
          setSuggestedDataJob(sortData(res.data, viewingId, {}));
        }
      },
    });
  }, [suggestedDataJob.length, viewingId]);

  const getSearchList = useCallback(
    (term, callback) => {
      if (!term || !term.length) {
        callback([]);
        return;
      }
      let urlPath = '';
      const matches = term ? term.match(/^([0-9])+|(\w)+/g) : null;
      if (matches && matches.length) {
        if (Number.isFinite(parseInt(matches[0]))) {
          urlPath = `/data-requests/data-job/match?id=${matches[0]}`;
          if (viewingId) {
            urlPath += `&source_id=${viewingId}`;
          }
          if (matches.length > 1) {
            urlPath += `&name=${matches.slice(1)}`;
          } else {
            urlPath += `&name=${matches[0]}`;
          }
        } else {
          urlPath = `/data-requests/data-job/match?name=${term}`;
        }
      } else if (term) {
        urlPath = `/data-requests/data-job/match?name=${term}`;
      }
      if (viewingId) {
        urlPath += `&source_id=${viewingId}`;
      }
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath,
        onSuccess: (r) => {
          if (isMounted.current) {
            callback(sortData(r.data, viewingId, excludeValues));
          }
        },
        onError: () => {
          callback([]);
        },
      });
    },
    [excludeValues, viewingId]
  );

  const loadOptions = useCallback(
    _debounce((value, callback) => {
      getSearchList(value, callback);
    }, 200),
    [getSearchList]
  );

  useEffect(() => {
    // fetch the initial suggestion list form server
    getSuggestList();
  }, [getSuggestList]);

  const handleSelect = useCallback(
    (e) => {
      onEdit(
        e.reduce((arr, v) => {
          if (v.obj.link_type && v.obj.link_type === "source") {
            if (excludeValues && excludeValues.source[v.value]) return arr;
            return [
              ...arr,
              {
                link_type: "source",
                source_id: v.value,
                source_name: v.obj.source_name,
                source_priority: v.obj.source_priority,
                source_status: v.obj.source_status,
              },
            ];
          }

          if (excludeValues.target && excludeValues.target[v.value]) return arr;
          return [
            ...arr,
            {
              link_type: "target",
              target_id: v.value,
              target_name: v.obj.name,
              target_priority: v.obj.priority,
              target_status: v.obj.status,
            },
          ];
        }, data),
        "data_request_link"
      );
    },
    [data, excludeValues, onEdit]
  );

  useEffect(() => {
    setSelectedValues(
      data.map((v) => {
        return v.link_type && v.link_type === "source"
          ? {
              value: v.source_id,
              label: `${v.source_id} - ${v.source_name}`,
              obj: v,
            }
          : {
              value: v.target_id,
              label: `${v.target_id} - ${v.target_name}`,
              obj: v,
            };
      })
    );
  }, [data]);

  return (
    <div className={className || "job-link-modal-search-input"}>
      <SearchBarWDropdown
        setOnChange={handleSelect}
        loadOptions={loadOptions}
        defaultOptions={suggestedDataJob}
        placeholder="Search for Data Jobs"
        isInvalid={false}
        feedbackText="Please select a valid Data Job."
        selectedValues={selectedValues}
      >
        {showSelection && (
          <>
            {selectedValues.map((v) => (
              <span key={v.value}>{v.label}</span>
            ))}
          </>
        )}
      </SearchBarWDropdown>
    </div>
  );
};

JobLinkSearch.propTypes = {
  onEdit: PropTypes.func,
  data: PropTypes.array,
  showSelection: PropTypes.bool,
  viewingId: PropTypes.string,
  className: PropTypes.string,
};

export { JobLinkSearch as DistributorJobLinkSearch };
