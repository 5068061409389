import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { DetailedAttributesKeysTableDef } from './DetailedAttributesKeysTableDef';
import { DetailedAttributesTableDef } from './DetailedAttributesTableDef';
import * as DetailedAttributesUtils from './DetailedAttributesUtils';

const isRowValuesChanged = (templateValue, originalTemplateValue) => {
  return (
    templateValue &&
    originalTemplateValue &&
    !(
      templateValue?.is_changed === originalTemplateValue?.is_changed &&
      templateValue?.corrected === originalTemplateValue?.corrected
    )
  );
};
const SORTTYPES = {
  keys: { dataField: 'key', order: 'asc' },
  labels: { dataField: 'name', order: 'asc' },
};

const mergeToTableData = ({
  attributesData,
  editableTemplateData,
  originalTemplateData,
  onChangeRowValues,
  isDisabled,
}) => {
  let output = [];
  if (Array.isArray(attributesData) && attributesData?.length) {
    // All keys list to find duplicates
    const keyList = [];

    output =
      attributesData.map((item) => {
        // Get template states for row
        const templateValue =
          (editableTemplateData?.output_format?.length &&
            editableTemplateData.output_format.find((_item) => _item?.id === item.id)) ||
          null;
        const originalTemplateValue =
          (originalTemplateData?.output_format?.length &&
            originalTemplateData.output_format.find((_item) => _item?.id === item.id)) ||
          null;

        // Table data object
        return {
          id: item.id,
          key: item.key,
          name: item.name,
          type: item.type,
          product_count: item.product_count,
          templateValue,
          originalTemplateValue,
          onChangeRowValues,
          isRowValuesChanged: isRowValuesChanged(templateValue, originalTemplateValue),
          isDuplicateKey: keyList.reduce((_a, _v) => (_v === item.key ? _a + 1 : _a), 0) > 1,
          isDisabled,
        };
      }) || [];
  }

  return output;
};

const DetailedAttributesTable = React.memo(
  ({
    attributesData,
    editableTemplateData,
    originalTemplateData,
    onChange,
    isDisabled,
    columnsType,
  }) => {
    const [tableData, setTableData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [sortField, setSortField] = useState('key');
    const [sortOrder, setSortOrder] = useState('asc');
    const [tableColumns, setTableColumns] = useState(DetailedAttributesKeysTableDef.columns);
    const [defaultSort, setDefaultSort] = useState(SORTTYPES.keys);
    const onTableChange = (
      type,
      { page, sizePerPage, sortField: _sortField, sortOrder: _sortOrder }
    ) => {
      if (
        currentPage === page &&
        pageSize === sizePerPage &&
        _sortField === sortField &&
        _sortOrder === sortOrder
      ) {
        return;
      }
      if (['pagination', 'sort'].includes(type)) {
        const resetPage = !(
          pageSize === sizePerPage &&
          _sortField === sortField &&
          ((!_sortField && !sortOrder) || _sortOrder === sortOrder)
        );
        if (columnsType === 'labels') {
          setSortField('name');
        } else {
          setSortField('key');
        }
        setCurrentPage(resetPage ? 1 : page);
        setPageSize(sizePerPage);

        setSortOrder(_sortOrder);
      }
    };

    // Memoize generated table data
    useEffect(() => {
      // Row change handler
      const onChangeRowValues = (id, key, isExported, outputName) => {
        const newTemplateData = editableTemplateData ? _cloneDeep(editableTemplateData) : {};
        const existingAttr = newTemplateData.output_format?.find((item) => item.id === id);
        if (existingAttr) {
          existingAttr.is_changed = !!isExported;
          if (outputName && outputName.length > 0) {
            outputName = outputName.trim();
          }
          existingAttr.corrected = outputName || null;
          DetailedAttributesUtils.validateData(newTemplateData, columnsType);
        } else {
          if (!newTemplateData.output_format?.length) {
            newTemplateData.output_format = [];
          }

          newTemplateData.output_format.push({
            id,
            key,
            is_changed: !!isExported,
            corrected: outputName.trim() || null,
            isInValid: false,
            errors: '',
          });
        }
        onChange(newTemplateData);
      };

      // Generate table data state
      setTableData(
        mergeToTableData({
          attributesData,
          editableTemplateData,
          originalTemplateData,
          onChangeRowValues,
          isDisabled,
        })
      );
    }, [attributesData, editableTemplateData, originalTemplateData, onChange, isDisabled]);
    useEffect(() => {
      if (columnsType !== 'keys') {
        setDefaultSort(SORTTYPES.labels);
        setTableColumns(DetailedAttributesTableDef.columns);
      } else {
        setTableColumns(DetailedAttributesKeysTableDef.columns);
        setDefaultSort(SORTTYPES.keys);
      }
    }, [columnsType]);

    return (
      <section className="block block-attributes-table">
        <div className="bootstrap-table-wrap">
          <BootstrapTable
            bordered={false}
            bootstrap4
            keyField="id"
            data={tableData}
            columns={tableColumns}
            onTableChange={onTableChange}
            pagination={paginationFactory({
              page: currentPage,
              sizePerPage: pageSize,
              totalSize: tableData?.length,
              showTotal: true,
              sizePerPageList: [25, 50, 100],
            })}
            rowClasses={(row) =>
              classNames({
                'is-exported': !!row?.templateValue?.is_changed,
                'is-disabled': !!row?.isDisabled,
                'is-changed': !!row?.isRowValuesChanged,
              })
            }
          />
        </div>
      </section>
    );
  }
);

DetailedAttributesTable.defaultProps = {
  attributesData: null,
  editableTemplateData: null,
  originalTemplateData: null,
  isDisabled: false,
};

DetailedAttributesTable.propTypes = {
  attributesData: PropTypes.array,
  editableTemplateData: PropTypes.object,
  originalTemplateData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export { DetailedAttributesTable };
