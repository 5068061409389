import React from 'react';

import Moment from 'react-moment';

import { statusValuesEnum } from 'constants/DataJobDetailsConstants';
import { MIN_DATE, TIME } from 'constants/DateTimeConstants';
import * as Utils from 'helpers/Utils';

export const PricingUpdateLogTableDef = {
  columns: [
    {
      ...Utils.getDefaultTableColumnDef('date', 'Date'),
      formatter: (_cell, row) => {
        if (row.date) {
          return <Moment format={`${MIN_DATE} ${TIME}`} date={row.date} />;
        }
        return 'N/A';
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('user', 'User'),
    },
    {
      ...Utils.getDefaultTableColumnDef('data_job', 'Data Job ID'),
      formatter: (_cell, row) => {
        if (row.data_job) {
          return (
            <a href={`/data-request/${row.data_job}`} onClick={(e) => e.stopPropagation()}>
              {row.data_job}
            </a>
          );
        }
        return 'N/A';
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('status', 'Status'),
      formatter: (_cell, row) => statusValuesEnum[row.status],
    },
    {
      ...Utils.getDefaultTableColumnDef('sent_to', 'Sent To'),
    },
    {
      ...Utils.getDefaultTableColumnDef('actions', ''),
      formatter: (_cell, row) => {
        if (row.data_job) {
          return (
            <a
              className="btn btn-secondary"
              href={`/data-request/${row.data_job}`}
              onClick={(e) => e.stopPropagation()}
            >
              View Data Job
            </a>
          );
        }
        return 'N/A';
      },
      sort: false,
    },
  ],
};
