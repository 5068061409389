import { log, event } from '@fullstory/browser';

import { formatDateTime8601 } from './TimeUtils';

export const LOG_LEVELS = {
  LOG: 'log',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
};

export const buildEventBody = () => {
  const timestamp = formatDateTime8601(new Date());

  return {
    timestamp,
  };
};

export const EVENTS = {
  DATABOT_REPORT_DOWNLOADED: 'Databot Report Downloaded',
  IMQA_IMAGES_DOWNLOADED: 'IMQA Images Downloaded',
};

/**
 * Send a log message to the FullStory console.
 *
 * @param {string} message The message to be logged.
 * @param {string} [logLevel=LOG_LEVELS.LOG] The log level of the message. Must be one of LOG_LEVELS.
 * @returns {void}
 */
export const FSLog = (message, logLevel = LOG_LEVELS.LOG) => {
  if (!process.env.REACT_APP_FULLSTORY_TRACKING_ID) return;

  console.debug('FullStory logging');
  log(logLevel, message);
};

/**
 * Records an event to FullStory.
 *
 * @param {string} eventName The name of the event to capture.
 * @param {object} eventProperties The data to be logged under this event.
 * @returns {void}
 */
export const FSEvent = (eventName, eventProperties) => {
  if (!process.env.REACT_APP_FULLSTORY_TRACKING_ID) return;

  const eventBody = { ...buildEventBody(), ...eventProperties };

  console.debug('FullStory event');
  event(eventName, eventBody);
};
