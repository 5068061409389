import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IdsConfigActions.scss';
import classNames from 'classnames';

import { useIsMounted } from '../../../helpers/useIsMounted';
import { ConfirmDialog } from '../../common/ConfirmDialog/ConfirmDialog';
import { ModalPanel } from '../../common/ModalPanel/ModalPanelWButton';
import { CustomBotConfigUtils } from './CustomBotConfigUtils';
import { IdsConstants } from './IdsConstants';
import { ImgBotEngineModalConfigManage } from './ImgBotEngineModalConfigManage';

const IdsConfigActions = ({
  configObj = undefined,
  onSaveData,
  onSetAsDefault,
  onDelete,
  isLoading,
}) => {
  const isMounted = useIsMounted();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);

  useEffect(() => {
    setIsAnonymous(configObj?.configuration_type === 'anonymous');
    setIsEditMode(configObj?.id !== undefined);
    setIsDefault(!!configObj?.is_default);
  }, [configObj, isMounted]);

  return (
    <div className="ids-config-action-btns">
      <ModalPanel
        header={
          isEditMode
            ? `Edit ${
                IdsConstants.IDS_CONFIG_TYPES[configObj.bot.slug.replaceAll('-', '_')].label
              } Configuration`
            : 'Create New Configuration'
        }
        body={({ setIsVisible }) => (
          <ImgBotEngineModalConfigManage
            configObj={configObj}
            onSaveData={onSaveData}
            setIsVisible={setIsVisible}
          />
        )}
        className="img-bot-modal-panel"
        centered
        onOpen={() => {}}
        onClose={() => {}}
      >
        {({ setIsVisible }) => (
          <>
            {isEditMode ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setIsVisible(true)}
                title={isAnonymous ? 'View' : 'Edit'}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={['far', isAnonymous ? 'eye' : 'edit']} />
                <span className="label">Edit</span>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setIsVisible(true)}
                title="New"
                disabled={isLoading}
              >
                <span style={{ marginRight: '0.5em' }}>
                  <FontAwesomeIcon icon={['far', 'plus']} />
                </span>
                <span className="label">New</span>
              </button>
            )}
          </>
        )}
      </ModalPanel>

      <ConfirmDialog
        onConfirm={() => onSetAsDefault({ configId: configObj?.id })}
        headerContent="Set Default?"
        bodyContent={`Set this as a default ${CustomBotConfigUtils.getConfigTypeConst(
          configObj
        )?.label?.toLowerCase()} config?`}
      >
        {({ onClick }) => (
          <>
            {isEditMode ? (
              <button
                type="button"
                className={classNames('btn', 'btn-primary', 'btn-default')}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                disabled={isDefault || isLoading || isAnonymous}
                title={isDefault ? 'Is Default' : 'Set Default'}
              >
                <FontAwesomeIcon icon={['far', isDefault ? 'check' : 'check-circle']} />{' '}
                <span className="label">{isDefault ? 'Is Default' : 'Set Default'}</span>
              </button>
            ) : null}
          </>
        )}
      </ConfirmDialog>

      <ConfirmDialog
        onConfirm={() => onDelete({ configId: configObj?.id })}
        headerContent="Delete IDS config?"
        bodyContent="Are you sure you want to delete this IDS config?"
      >
        {({ onClick }) => (
          <>
            {isEditMode ? (
              <button
                type="button"
                className={classNames('btn', 'btn-primary', 'btn-delete')}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                title="Delete"
                disabled={isLoading || isAnonymous}
              >
                <FontAwesomeIcon icon={['far', 'trash-alt']} />{' '}
                <span className="label">Cancel</span>
              </button>
            ) : null}
          </>
        )}
      </ConfirmDialog>
    </div>
  );
};

export { IdsConfigActions };
