import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';

import { useIsMounted } from '../../../helpers/useIsMounted';
import { LoadingSpinner } from '../../common/LoadingSpinner/LoadingSpinner';
import { StyledMultiselect } from '../../common/StyledMultiselect/StyledMultiselect';
import { IdsAssessmentConfigForm } from './_ids_configs/IdsAssessmentConfigForm';
import { IdsEnhancementConfigForm } from './_ids_configs/IdsEnhancementConfigForm';
import './ImgBotEngineModal.scss';
import { CustomBotConfigUtils } from './CustomBotConfigUtils';
import { IdsConstants } from './IdsConstants';

const ImgBotEngineModalConfigManage = ({
  setIsVisible,
  configObj = { name: '', bot_slug: '', configuration: null },
  onSaveData,
}) => {
  const isMounted = useIsMounted();

  const [isEditMode] = useState(configObj?.id !== undefined);
  const [selectedConfigType, setSelectedConfigType] = useState(
    CustomBotConfigUtils.getConfigTypeConst(configObj)
  );
  const [configData, setConfigData] = useState(configObj);

  const [isLoading, setIsLoading] = useState(false);
  const [defaultConfig, setDefaultConfig] = useState(null);
  const [formValidationResult, setFormValidationResult] = useState(null);

  const doLoadDefaultConfig = useCallback(() => {
    setIsLoading(true);
    CustomBotConfigUtils.loadDefaultConfig({
      onSuccess: (res) => {
        if (res?.data) {
          setDefaultConfig(res.data);
        } else {
          setDefaultConfig(null);
        }
      },
      onEnd: () => setIsLoading(false),
    });
  }, []);

  useEffect(() => {
    if (!isMounted.current || isEditMode) {
      return;
    }
    doLoadDefaultConfig();
  }, [isMounted, doLoadDefaultConfig, isEditMode]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    if (defaultConfig) {
      setConfigData((cfg) => ({
        ...cfg,
        bot_slug: selectedConfigType?.slug,
        configuration: defaultConfig[selectedConfigType?.value],
      }));
    }
  }, [isMounted, selectedConfigType, defaultConfig]);

  const doSaveData = () => {
    onSaveData({ data: configData, onSuccess: () => setIsVisible(false) });
  };

  return (
    <div className="img-bot-modal-panel-body">
      <section className="custom-config-block">
        <Form.Group className="ids-custom-config-field" controlId="custom-config-name">
          <Form.Label>Name</Form.Label>

          <Form.Control
            type="text"
            value={configData.name ?? ''}
            onChange={(e) => setConfigData({ ...configData, name: e.target.value })}
            name="custom-config-name"
            disabled={isLoading || configData?.configuration_type === 'anonymous'}
          />
        </Form.Group>
      </section>
      {isEditMode ? null : (
        <section>
          <Form.Group className="ids-custom-config-field" controlId="custom-config-type">
            <Form.Label>Type</Form.Label>
            <StyledMultiselect
              values={selectedConfigType}
              options={Object.values(IdsConstants.IDS_CONFIG_TYPES)}
              isMulti={false}
              setOnChange={(v) => {
                setConfigData({ ...configData, configuration: null });
                setSelectedConfigType(v);
              }}
              isDisabled={isLoading}
            />
          </Form.Group>
        </section>
      )}

      {selectedConfigType && configData.configuration ? (
        <>
          {selectedConfigType?.value === 'assessment' ? (
            <IdsAssessmentConfigForm
              isAnonymous={configData?.configuration_type === 'anonymous'}
              config={configData.configuration}
              isLoading={isLoading}
              setCustomConfig={(c) => setConfigData({ ...configData, configuration: c })}
              setValidationResult={setFormValidationResult}
            />
          ) : null}
          {selectedConfigType?.value === 'enhancement' ? (
            <IdsEnhancementConfigForm
              isAnonymous={configData?.configuration_type === 'anonymous'}
              config={configData.configuration}
              isLoading={isLoading}
              setCustomConfig={(c) => setConfigData({ ...configData, configuration: c })}
              setValidationResult={setFormValidationResult}
            />
          ) : null}
          <section className="actions">
            <button
              type="button"
              className="btn btn-link btn-cancel"
              onClick={() => setIsVisible(false)}
              disabled={isLoading}
              title="Cancel"
            >
              Cancel
            </button>
            {configData?.configuration_type !== 'anonymous' && (
              <button
                type="button"
                className="btn btn-primary btn-assess"
                onClick={() => doSaveData()}
                disabled={isLoading || !formValidationResult?.isValid}
                title="Save"
              >
                {isLoading ? (
                  <LoadingSpinner fast />
                ) : (
                  <FontAwesomeIcon icon={['far', 'analytics']} />
                )}{' '}
                Save
              </button>
            )}
            {formValidationResult?.message ? <p>{formValidationResult.message}</p> : null}
          </section>
        </>
      ) : null}
    </div>
  );
};

export { ImgBotEngineModalConfigManage };
