import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import './CdsConfigurationForm.scss';
import { getIconWithPopover } from '../../../../../helpers/Utils';
import { BotFormHeaderSection } from '../../_components/BotFormHeaderSection';
import { BotFormLabel } from '../../_components/BotFormLabel';
import { CdsGptContentForm } from '../_base/CdsGptContentForm';
import { CdsGptSourcesForm } from '../_base/CdsGptSourcesForm';

const MANDATORY_COLUMN_NAMES = [
  'Item Number',
  'Manufacturer',
  'Brand',
  'Product Type',
  'Product Series',
];
const GptDescriptionGeneratorForm = ({
  headers,
  config,
  setConfig,
  errors,
  onFormErrors,
  disabled = false,
}) => {
  return (
    <>
      <div>
        <BotFormHeaderSection
          title="GPT Description Generator"
          description={
            <>
              <p>
                Leverage the power of <strong className="cds-description-strong">AI</strong> and{' '}
                <strong className="cds-description-strong">ChatGPT</strong> to generate high
                quality, SEO-optimized product descriptions.
              </p>
              <p>
                Select the attributes you want to include in your description and the bot will
                generate a description for each product in your file.
              </p>
            </>
          }
        />
      </div>

      <div>
        <BotFormLabel label="Configuration" type="section" />
        {config && Object.keys(config).length > 0 && (
          <div className="cds-content-configuration">
            <div className="cds-section">
              <Tabs defaultActiveKey="source">
                <Tab
                  eventKey="source"
                  className={classNames({ 'mb-3': true, 'cds-config-tabs': true })}
                  title={
                    <div className="tab-title">
                      <div>Source</div>
                      {errors?.sources && (
                        <div className="invalid">
                          {getIconWithPopover({
                            placement: 'top',
                            iconProp: ['far', 'exclamation-circle'],
                            content: 'This form has validation errors.',
                            title: 'Source Form',
                          })}
                        </div>
                      )}
                    </div>
                  }
                >
                  <CdsGptSourcesForm
                    columns={headers}
                    disabled={disabled}
                    state={config.sources || {}}
                    setState={(v) => {
                      setConfig({
                        ...config,
                        sources: v,
                      });
                    }}
                    mandatoryColumns={MANDATORY_COLUMN_NAMES}
                    setInvalid={(v) => {
                      onFormErrors('sources', v);
                    }}
                  />
                </Tab>

                <Tab
                  eventKey="content"
                  title={
                    <div className="tab-title">
                      <div>Content</div>
                      {errors?.content && (
                        <div className="invalid">
                          {getIconWithPopover({
                            placement: 'top',
                            iconProp: ['far', 'exclamation-circle'],
                            content: 'This form has validation errors.',
                            title: 'Content Form',
                          })}
                        </div>
                      )}
                    </div>
                  }
                >
                  <CdsGptContentForm
                    state={config.content || {}}
                    disabled={disabled}
                    setState={(v) => {
                      setConfig({
                        ...config,
                        content: v,
                      });
                    }}
                    columnNames={headers}
                    setInvalid={(v) => {
                      onFormErrors('content', v);
                    }}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

GptDescriptionGeneratorForm.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  config: PropTypes.object.isRequired,
  setConfig: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onFormErrors: PropTypes.func,
};

GptDescriptionGeneratorForm.defaultProps = {
  errors: {},
  onFormErrors: () => {},
};

export { GptDescriptionGeneratorForm };
