import React from 'react';

import { FileResultItem } from './FileResultItem';
import './FileResult.scss';

const handlerCallback = (fileName, callback, downloadType, files, index) => {
  if (typeof callback === 'function' && downloadType === 'output_image_archives') {
    return callback(index, files.length);
  }

  if (typeof callback === 'function') {
    return callback(fileName);
  }

  if (typeof callback === 'string') {
    return callback;
  }
  return fileName;
};

const FileResult = ({ title, files, callback, downloadType }) => (
  <div className="file-container-result">
    <div className="file-result-type">{title}</div>
    <div className="file-result-group">
      {files?.map((item, index) => (
        <FileResultItem
          key={index}
          link={item.url}
          createdAt={item?.created_at}
          tip={item?.name}
          fileName={handlerCallback(item?.name, callback, downloadType, files, index)}
        />
      ))}
    </div>
  </div>
);

export { FileResult };
