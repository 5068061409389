import React, { useEffect, useState } from 'react';

import { DropdownButton } from 'react-bootstrap';

import { Checkbox } from 'components/common/Checkbox/Checkbox';

import './FilterCompanies.scss';

const FilterCompanies = ({
  title = 'Filter Level 1 Brands',
  companies,
  onCompanySelect,
  selectedCompanies,
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [localCompanies, setLocalCompanies] = useState([]);

  const toggleFilterDropdown = (isOpen) => {
    setIsFilterVisible(isOpen);
  };

  const selectCompany = (company) => {
    const companies = localCompanies.slice();

    if (companies.includes(company.id)) {
      companies.splice(companies.indexOf(company.id), 1);
    } else {
      companies.push(company.id);
    }

    setLocalCompanies(companies);
  };

  const cancelCompanySelect = () => {
    setLocalCompanies(selectedCompanies);
    setIsFilterVisible(!isFilterVisible);
  };

  const isCompanyChecked = (company) => {
    return localCompanies.includes(company.id);
  };

  const doSelectCompanies = () => {
    onCompanySelect(localCompanies);
    setIsFilterVisible(!isFilterVisible);
  }

  useEffect(() => {
    setLocalCompanies(selectedCompanies);
  }, [selectedCompanies]);

  return (
    <DropdownButton
      alignRight
      className="filter-companies"
      variant="secondary"
      title={<span className="label">{title}</span>}
      show={isFilterVisible}
      onToggle={(isOpen) => toggleFilterDropdown(isOpen)}
    >
      <ul className="filter-companies-body">
        {companies.map((company) => (
          <li key={company.name} className="filter-companies-item">
            <button
              className="filter-companies-input"
              type="button"
              onClick={() => selectCompany(company)}
            >
              <Checkbox checked={isCompanyChecked(company)} />
              <span className="name">{company.name}</span>
            </button>
          </li>
        ))}
      </ul>
      <div className="filter-companies-footer">
        <button
          type="button"
          onClick={cancelCompanySelect}
          className="filter-companies-footer-btn btn btn-secondary"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={doSelectCompanies}
          className="filter-companies-footer-btn btn btn-primary"
        >
          Apply
        </button>
      </div>
    </DropdownButton>
  );
};

export { FilterCompanies };
