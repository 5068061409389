import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import _get from 'lodash/get';
import { Button, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

import * as ApiCalls from 'api/ApiCalls';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

import './ExportFunctionalityPanel.scss';
import { ExportFunctionalityPanelTableDef, EXPORT_TYPES } from './ExportFunctionalityPanelTableDef';

/**
 * Export functionality panel main view
 *
 * @return render
 */
const ExportFunctionalityPanel = () => {
  const isMounted = useIsMounted();

  const EXPORT_FILE_TYPES = {
    csv: { value: 'csv', label: 'CSV' },
    xlsx: { value: 'xlsx', label: 'XLSX' },

  };
  const [startExportStatus, setStartExportStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds exports status data
  const [exportsData, setExportsData] = useState(null);

  // Holds mfrs list
  const [manufacturersData, setManufacturersData] = useState(null);
  const [manufacturersDataStatus, setManufacturersDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );


  // Holds selected mfr id and export type selected
  const [selectedMfrId, setSelectedMfrId] = useState(null);
  const [selectedExportType, setSelectedExportType] = useState(EXPORT_TYPES.general.value);

  const [selectedFileType, setSelectedFileType] = useState(EXPORT_FILE_TYPES.xlsx.value);
  const pollInterval = useRef(null);

  const doGetExports = useCallback(() => {
    if (isMounted.current) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/product-export-history/',
        onSuccess: (res) => {
          if (isMounted.current) {
            const _data = _get(res, 'data') || null;
            setExportsData(_data);
          }
        },
      });
    }
  }, [isMounted]);

  const doGetManufacturers = useCallback(() => {
    setManufacturersDataStatus(ActionStatusConstants.ISBUSY);
    if (isMounted.current) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/product-export-history/manufacturers/',
        onSuccess: (res) => {
          if (isMounted.current) {
            setManufacturersDataStatus(ActionStatusConstants.SUCCESS);
            const _data = _get(res, 'data') || null;
            setManufacturersData(sortByKey(_data, 'name'));
          }
        },
        onError: () => {
          setManufacturersDataStatus(ActionStatusConstants.FAILURE);
        },
      });
    }
  }, [isMounted]);

  const doStartExport = useCallback(() => {
    if (!(selectedMfrId && selectedExportType)) {
      return;
    }

    setStartExportStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: '/product-export-history/general/export',
      data: {
        manufacturer_id: selectedMfrId,
        type: selectedExportType,
        file_type:selectedFileType
      },
      successMessage: 'Export started. It will appear in the table below shortly.',
      onSuccess: () => {
        if (isMounted.current) {
          setStartExportStatus(ActionStatusConstants.SUCCESS);
        }
      },
      onError: () => {
        setStartExportStatus(ActionStatusConstants.FAILURE);
      },
    });
  }, [isMounted, selectedExportType, selectedMfrId, selectedFileType]);

  // Exports status poll
  useEffect(() => {
    doGetManufacturers();
    doGetExports();

    if (!pollInterval.current) {
      pollInterval.current = setInterval(() => {
        if (isMounted.current === true) {
          doGetExports();
        } else {
          clearInterval(pollInterval.current);
          pollInterval.current = null;
        }
      }, 7000);
    }
  }, [doGetExports, doGetManufacturers, isMounted, pollInterval]);

  // Convert mfr object to Select option
  const getSelectedManufacturerOption = () => {
    const vObj = manufacturersData?.find((item) => item.id === selectedMfrId);
    return vObj ? [{ value: vObj.id, label: vObj.name }] : [];
  };

  const getSelectedFileTypeOption = () => {
    if (selectedFileType === EXPORT_FILE_TYPES.xlsx.value){
      return EXPORT_FILE_TYPES.xlsx
    }
    return EXPORT_FILE_TYPES.csv
  };

  const isStartBtnDisabled =
    startExportStatus === ActionStatusConstants.ISBUSY || !selectedMfrId || !selectedExportType;

  return (
    <div className="export-functionality-panel">
      <div className="export-functionality-filter">
        <section className="filter-mfr-select">
          <div className="title">Manufacturer</div>
          <StyledMultiselect
            values={getSelectedManufacturerOption()}
            options={
              manufacturersData
                ? manufacturersData.map((item) => {
                    return { value: item.id, label: item.name };
                  })
                : []
            }
            setOnChange={(v) => {
              setSelectedMfrId(v.value);
            }}
            getOptionValue={(option) => option.value}
            closeMenuOnSelect
            isSearchable
            isDisabled={
              startExportStatus === ActionStatusConstants.ISBUSY ||
              manufacturersDataStatus === ActionStatusConstants.ISBUSY
            }
            isMulti={false}
            isClearable={false}
            canReset
          />
        </section>
        <section className="filter-export-type">
          <div className="title">Export Type</div>
          <Form.Check
            type="radio"
            label={EXPORT_TYPES.general.label}
            checked={selectedExportType === EXPORT_TYPES.general.value}
            readOnly
            disabled={startExportStatus === ActionStatusConstants.ISBUSY}
            onClick={() => setSelectedExportType(EXPORT_TYPES.general.value)}
          />
          <Form.Check
            type="radio"
            label={EXPORT_TYPES.detailed.label}
            checked={selectedExportType === EXPORT_TYPES.detailed.value}
            readOnly
            disabled={startExportStatus === ActionStatusConstants.ISBUSY}
            onClick={() => setSelectedExportType(EXPORT_TYPES.detailed.value)}
          />
          <Form.Check
            type="radio"
            label={EXPORT_TYPES.asset.label}
            checked={selectedExportType === EXPORT_TYPES.asset.value}
            readOnly
            disabled={startExportStatus === ActionStatusConstants.ISBUSY}
            onClick={() => setSelectedExportType(EXPORT_TYPES.asset.value)}
          />
          <Form.Check
            type="radio"
            label={EXPORT_TYPES.taxonomy.label}
            checked={selectedExportType === EXPORT_TYPES.taxonomy.value}
            readOnly
            disabled={startExportStatus === ActionStatusConstants.ISBUSY}
            onClick={() => setSelectedExportType(EXPORT_TYPES.taxonomy.value)}
          />
          <Form.Check
            type="radio"
            label={EXPORT_TYPES.detailed_new.label}
            checked={selectedExportType === EXPORT_TYPES.detailed_new.value}
            readOnly
            disabled={startExportStatus === ActionStatusConstants.ISBUSY}
            onClick={() => setSelectedExportType(EXPORT_TYPES.detailed_new.value)}
          />
          <Form.Check
            type="radio"
            label={EXPORT_TYPES.price_info.label}
            checked={selectedExportType === EXPORT_TYPES.price_info.value}
            readOnly
            disabled={startExportStatus === ActionStatusConstants.ISBUSY}
            onClick={() => setSelectedExportType(EXPORT_TYPES.price_info.value)}
          />
        </section>
         <section className="filter-export-type">
           <div className="title">Output File Type</div>

          <StyledMultiselect
            options={[EXPORT_FILE_TYPES.xlsx,EXPORT_FILE_TYPES.csv]}
            values={getSelectedFileTypeOption()}
            getOptionValue={(option) => option.value}
            closeMenuOnSelect
            setOnChange={(v) => {
              setSelectedFileType(v.value);
            }}
            isDisabled={
              startExportStatus === ActionStatusConstants.ISBUSY ||
              manufacturersDataStatus === ActionStatusConstants.ISBUSY
            }
            isMulti={false}
            isClearable={false}
            canReset
          />
        </section>
        <section className="actions">
          <Button
            className="btn btn-primary btn-lg"
            onClick={() => doStartExport()}
            disabled={isStartBtnDisabled}
          >
            Start Export
          </Button>
        </section>
      </div>
      {exportsData?.length ? (
        <div className="exports-table">
          <div className="bootstrap-table-wrap">
            <BootstrapTable
              bordered={false}
              bootstrap4
              keyField="id"
              data={exportsData}
              columns={ExportFunctionalityPanelTableDef.columns}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export { ExportFunctionalityPanel, EXPORT_TYPES };
