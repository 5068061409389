import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { mandatoryExportColumns } from 'constants/ExportConstants';

import { BlockAttributesTableTableDef } from './BlockAttributesTableTableDef';

/**
 * Compares row state between editable and original template row values
 *
 * @param {object} templateValue Editable template row value
 * @param {object} originalTemplateValue Original template row value
 * @return {boolean} Row is changed
 */
const isRowValuesChanged = (templateValue, originalTemplateValue) => {
  return (
    templateValue &&
    originalTemplateValue &&
    !(
      templateValue?.is_exported === originalTemplateValue?.is_exported &&
      templateValue?.output_name === originalTemplateValue?.output_name
    )
  );
};

/**
 * Generates table data from the attributes list and the applied template.
 *
 * @param {array} attributesData Attributes list
 * @param {object} editableTemplateData Editable template object
 * @param {object} originalTemplateData Original template object
 * @param {function} onChangeRowValues Change handler
 * @param {boolean} isDisabled Disabled state
 * @return {array} Table data
 */
const mergeToTableData = ({
  attributesData,
  editableTemplateData,
  originalTemplateData,
  onChangeRowValues,
  isDisabled,
}) => {
  let output = [];
  if (Array.isArray(attributesData) && attributesData?.length) {
    // All keys list to find duplicates
    const keyList = [];

    output =
      attributesData
        .filter(
          // List only selected attribute types
          // Item # is always static per spec, so we always include it it
          (item) => {
            const isIncluded =
              editableTemplateData?.attribute_types?.includes(item.type) ||
              mandatoryExportColumns.includes(item.key);
            if (isIncluded) {
              keyList.push(item.key);
            }
            return isIncluded;
          }
        )
        .map((item) => {
          // Get template states for row
          const templateValue =
            (editableTemplateData?.output_format?.length &&
              editableTemplateData.output_format.find((_item) => _item?.id === item.id)) ||
            null;
          const originalTemplateValue =
            (originalTemplateData?.output_format?.length &&
              originalTemplateData.output_format.find((_item) => _item?.id === item.id)) ||
            null;

          // Table data object
          return {
            id: item.id,
            key: item.key,
            name: item.name,
            type: item.type,
            product_count: item.product_count,
            templateValue,
            originalTemplateValue,
            onChangeRowValues,
            isRowValuesChanged: isRowValuesChanged(templateValue, originalTemplateValue),
            isDuplicateKey: keyList.reduce((_a, _v) => (_v === item.key ? _a + 1 : _a), 0) > 1,
            isDisabled,
          };
        }) || [];
  }

  return output;
};

/**
 * Attributes table widget
 *
 * @param {array} attributesData Attributes list
 * @param {object} editableTemplateData Editable template object
 * @param {object} originalTemplateData Original template object
 * @param {function} onChange Change handler
 * @param {boolean} isDisabled Disabled state
 * @return render
 */
const BlockAttributesTable = React.memo(
  ({ attributesData, editableTemplateData, originalTemplateData, onChange, isDisabled }) => {
    const [tableData, setTableData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');

    const onTableChange = (
      type,
      { page, sizePerPage, sortField: _sortField, sortOrder: _sortOrder }
    ) => {
      if (
        currentPage === page &&
        pageSize === sizePerPage &&
        _sortField === sortField &&
        _sortOrder === sortOrder
      ) {
        return;
      }
      if (['pagination', 'sort'].includes(type)) {
        const resetPage = !(
          pageSize === sizePerPage &&
          _sortField === sortField &&
          ((!_sortField && !sortOrder) || _sortOrder === sortOrder)
        );

        setCurrentPage(resetPage ? 1 : page);
        setPageSize(sizePerPage);
        setSortField(_sortField);
        setSortOrder(_sortOrder);
      }
    };

    // Memoize generated table data
    useEffect(() => {
      // Row change handler
      const onChangeRowValues = (id, key, isExported, outputName) => {
        const newTemplateData = editableTemplateData ? _cloneDeep(editableTemplateData) : {};

        const existingAttr = newTemplateData.output_format?.find((item) => item.id === id);

        if (existingAttr) {
          existingAttr.is_exported = !!isExported;
          existingAttr.output_name = outputName || null;
        } else {
          // TODO: Probably this case would not exist as the template is always initialized
          // at this point
          console.error('Template has not been initialized properly', editableTemplateData);

          if (!newTemplateData.output_format?.length) {
            newTemplateData.output_format = [];
          }

          newTemplateData.output_format.push({
            id,
            key,
            is_exported: !!isExported,
            outputName: outputName || null,
          });
        }

        onChange(newTemplateData);
      };

      // Generate table data state
      setTableData(
        mergeToTableData({
          attributesData,
          editableTemplateData,
          originalTemplateData,
          onChangeRowValues,
          isDisabled,
        })
      );
    }, [attributesData, editableTemplateData, originalTemplateData, onChange, isDisabled]);

    return (
      <section className="block block-attributes-table">
        <div className="bootstrap-table-wrap">
          <BootstrapTable
            bordered={false}
            bootstrap4
            keyField="id"
            data={tableData}
            columns={BlockAttributesTableTableDef.columns}
            onTableChange={onTableChange}
            pagination={paginationFactory({
              page: currentPage,
              sizePerPage: pageSize,
              totalSize: tableData?.length,
              showTotal: true,
            })}
            rowClasses={(row) =>
              classNames({
                'is-exported': !!row?.templateValue?.is_exported,
                'is-disabled': !!row?.isDisabled,
                'is-changed': !!row?.isRowValuesChanged,
              })
            }
          />
        </div>
      </section>
    );
  }
);

BlockAttributesTable.defaultProps = {
  attributesData: null,
  editableTemplateData: null,
  originalTemplateData: null,
  isDisabled: false,
};

BlockAttributesTable.propTypes = {
  attributesData: PropTypes.array,
  editableTemplateData: PropTypes.object,
  originalTemplateData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export { BlockAttributesTable };
