import { ATTRIBUTE_TYPES, EXPORT_STATUSES } from './ExportFunctionalityAdvancedConstants';

/**
 * Augment eventually-partial template with the rest of the attributes available.
 *
 * @param {array} attributesData Full list of attributes
 * @param {object} templateData Basis template
 * @return {object} Cloned templateData object augmented with all attributes
 */
const generateFullTemplateData = (attributesData, templateData) => {
  const output = {
    template_name: 'Custom Template',
    attribute_types: Object.keys(ATTRIBUTE_TYPES),
    ...templateData,
  };

  if (attributesData?.length) {
    output.output_format = attributesData.map((item) => {
      let templateAttr = templateData?.output_format?.find((_item) => item.id === _item.id);

      if (templateAttr) {
        templateAttr = {
          ...templateAttr,
        };
      } else {
        templateAttr = {
          id: item.id,
          key: item.key,
          output_name: null,
          is_exported: !templateData,
        };
      }

      if (item.key === 'code') {
        templateAttr.is_exported = true;
      }

      return templateAttr;
    });
  }

  console.debug('Generated full template data', attributesData, templateData, output);

  return output;
};

/**
 * Gets mfr id(parent or child) that's going to be used for exporting data
 *
 * @param {object} mfrSelectValue Selection object
 * @return {number} Id of selected mfr
 */
const getExportMfrId = (mfrSelectValue) => {
  return mfrSelectValue?.manufacturerChild?.id || mfrSelectValue?.manufacturer?.id || null;
};

/**
 * Checks if mfr is already being processed in exports list
 *
 * @param {object} mfrSelectValue Selected mfr object
 * @param {array} exportsStatusData Exports status list
 * @return {boolean} Is mfr in progress
 */
const checkIsSelectedMfrAlreadyInProgress = (mfrSelectValue, exportsStatusData) => {
  let output = false;

  const selectedMfrId = getExportMfrId(mfrSelectValue);

  if (selectedMfrId && exportsStatusData.length) {
    output = exportsStatusData?.some(
      (item) =>
        selectedMfrId === item.manufacturer.id && item.status === EXPORT_STATUSES.in_progress.value
    );
  }

  return output;
};

export { generateFullTemplateData, getExportMfrId, checkIsSelectedMfrAlreadyInProgress };
