import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { toast } from 'helpers/ToastUtils';

import { getIconWithPopover } from '../../../../helpers/Utils';
import { StyledMultiselect } from '../../../common/StyledMultiselect/StyledMultiselect';
import { CustomBotConfigForm } from '../CustomBotConfigForm';
import { CustomBotConfigUtils } from '../CustomBotConfigUtils';
import { IdsConstants } from '../IdsConstants';
import { IdsModels } from '../IdsModels';

const IdsExternalDeliveryForm = ({ config, setCustomConfig, isLoading, setValidationResult }) => {
  const [isTestConnectionLoading, setIsTestConnectionLoading] = useState(false);
  const [selectedExtDeliveryDestination, setSelectedExtDeliveryDestination] = useState(null);
  const [isValidExternalConnection, setIsValidExternalConnection] = useState(null);
  const [invalidForm, setInvalidForm] = useState(null);
  const [externalDeliveryModel, setExternalDeliveryModel] = useState(null);

  useEffect(() => {
    setIsValidExternalConnection(null);
  }, [config?.credentials]);

  useEffect(() => {
    let hasError;
    let message;
    if (selectedExtDeliveryDestination === null) {
      hasError = true;
      message = IdsConstants.FORM_ERROR_MESSAGES.external_delivery_destination_not_selected;
    } else {
      hasError = CustomBotConfigUtils.containsError(config);
      message = hasError ? IdsConstants.FORM_ERROR_MESSAGES.invalid_config_form : undefined;
      setInvalidForm(hasError);
    }

    if (!hasError) {
      if (isValidExternalConnection === null) {
        hasError = true;
        message = IdsConstants.FORM_ERROR_MESSAGES.external_delivery_connection_test_not_run;
      } else if (isValidExternalConnection === false) {
        hasError = true;
        message = IdsConstants.FORM_ERROR_MESSAGES.external_delivery_connection_test_failed;
      }
    }
    setValidationResult?.({ isValid: !hasError, message });
  }, [config, setValidationResult, selectedExtDeliveryDestination, isValidExternalConnection]);

  const onSelectedDeliveryChanged = (v) => {
    const deliveryModel = IdsModels.IDS_EXTERNAL_DELIVERY_MODELS[v.value];
    setSelectedExtDeliveryDestination(v);
    let configModel = {
      type: v.value,
      credentials: cloneDeep(deliveryModel?.credentials) ?? {},
    };
    Object.keys(deliveryModel.configModel).forEach((it) => {
      const { attrKey, valueKey } = deliveryModel.configModel[it];
      configModel = deliveryModel.configModel[it].update(
        configModel,
        configModel[it],
        attrKey,
        valueKey
      );
    });
    setCustomConfig(configModel);
    setExternalDeliveryModel(deliveryModel);
  };

  const handleButtonClick = () => {
    setIsTestConnectionLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/external-connections/test/${config.type}`,
      data: config.credentials,
      onSuccess: (res) => {
        let message = 'Connection test successful';
        if (res?.data?.message) {
          message = res.data.message;
          setIsValidExternalConnection(true);
        }
        toast.success(message);
      },
      onError: () => {
        setIsTestConnectionLoading(false);
        setIsValidExternalConnection(false);
      },
      onEnd: () => {
        setIsTestConnectionLoading(false);
      },
    });
  };

  return (
    <>
      <section className="custom-bot-config-selector">
        <div className="custom-bot-config-selector-header">
          <div className="title">
            <span>Destinations</span>{' '}
            {getIconWithPopover({
              content: (
                <>
                  Select the delivery destination from the options below. Once selected, you will
                  have to provide additional information that will allow us to successfully connect
                  and deliver the assets.
                </>
              ),
              iconProp: ['far', 'question-circle'],
            })}
          </div>
        </div>
        <div className="custom-bot-config-selector-body">
          <div className="custom-bot-config-selector-body-select">
            <StyledMultiselect
              placeholder="Delivery Type"
              id="ids-external-delivery-config-select"
              options={IdsConstants.IDS_EXTERNAL_DELIVERY_DESTINATIONS}
              values={selectedExtDeliveryDestination}
              isMulti={false}
              getOptionValue={(option) => option.value}
              setOnChange={onSelectedDeliveryChanged}
              isInvalid={selectedExtDeliveryDestination === null}
              errorMessage={
                IdsConstants.FORM_ERROR_MESSAGES.external_delivery_destination_not_selected
              }
            />
          </div>
          <button
            type="button"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            className="btn btn-primary"
            disabled={
              !selectedExtDeliveryDestination ||
              !!invalidForm ||
              isLoading ||
              isTestConnectionLoading
            }
            title="Test"
            onClick={handleButtonClick}
          >
            {isTestConnectionLoading ? (
              <LoadingSpinner fast />
            ) : (
              <FontAwesomeIcon icon={['fas', 'network-wired']} />
            )}{' '}
            Test
          </button>
        </div>
      </section>
      {externalDeliveryModel && (
        <CustomBotConfigForm
          customConfig={config}
          setCustomConfig={setCustomConfig}
          isLoading={isLoading}
          configGroups={externalDeliveryModel.configGroup ?? {}}
          configModel={externalDeliveryModel.configModel ?? {}}
        />
      )}
    </>
  );
};

export { IdsExternalDeliveryForm };
