import './DataJobDetailsInput.scss';

import React, { useRef, useCallback, useEffect, useState } from 'react';

import _debounce from 'lodash/debounce';
import { PropTypes } from 'prop-types';
import { Form } from 'react-bootstrap';

import DataJobCreateConstants from 'constants/DataJobCreateConstants';

const DataJobNameDesc = ({ details, invalidCheck, setDetails }) => {
  const nameWordTimer = useRef(null);
  const descWordtimer = useRef(null);
  const [name, setName] = useState(details.jobName);
  const [desc, setDesc] = useState(details.desc);
  const [descWordLimit, setDescWordLimit] = useState(false);
  const [nameWordLimit, setNameWordLimit] = useState(false);

  useEffect(() => {
    return () => {
      if (nameWordTimer.current) {
        clearTimeout(nameWordTimer.current);
        nameWordTimer.current = null;
      }
      if (descWordtimer.current) {
        clearTimeout(descWordtimer.current);
        descWordtimer.current = null;
      }
    };
  }, []);

  const onJobNameSelected = _debounce(
    (value) => setDetails({ type: DataJobCreateConstants.JOBNAME, payload: value }),
    200
  );

  const onDescSelected = _debounce(
    (value) => setDetails({ type: DataJobCreateConstants.DESCRIPTION, payload: value }),
    200
  );

  const handleDescChange = useCallback(
    ({ target: { value } }) => {
      setDesc(value);
      onDescSelected(value);
      setDescWordLimit(false);
      if (value && value.length === 4000) {
        if (descWordtimer.current) {
          clearTimeout(descWordtimer.current);
          descWordtimer.current = null;
        }
        setDescWordLimit(true);
        descWordtimer.current = setTimeout(() => {
          setDescWordLimit(false);
        }, 2000);
      }
    },
    [onDescSelected]
  );

  const handleNameChange = useCallback(
    ({ target: { value } }) => {
      setName(value);
      onJobNameSelected(value);
      setNameWordLimit(false);
      if (value && value.length === 100) {
        setNameWordLimit(true);
        if (nameWordTimer.current) {
          clearTimeout(nameWordTimer.current);
          nameWordTimer.current = null;
        }
        nameWordTimer.current = setTimeout(() => {
          setNameWordLimit(false);
        }, 2000);
      }
    },
    [onJobNameSelected]
  );

  return (
    <section className="data-job-details-selection-wrapper">
      <Form noValidate>
        <Form.Group controlId="dataJob.controlInp">
          <Form.Label className="data-job-details-selection-text">Data Job Name *</Form.Label>
          <Form.Control
            as="input"
            value={name}
            placeholder="Name your job"
            onChange={handleNameChange}
            isInvalid={nameWordLimit || invalidCheck.jobName}
            maxLength="100"
          />
          {nameWordLimit ? (
            <Form.Control.Feedback type="invalid">Max characters reached.</Form.Control.Feedback>
          ) : (
            <Form.Control.Feedback type="invalid">Please enter a job name!</Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group controlId="desc.controlInp">
          <Form.Label className="data-job-details-selection-text">Description *</Form.Label>
          <Form.Control
            className={`data-job-details-selection-textarea${
              desc && desc.length === 4000 ? '-overflow' : ''
            }`}
            as="textarea"
            value={desc}
            rows={10}
            placeholder="Provide specific details for your data job."
            onChange={handleDescChange}
            isInvalid={descWordLimit || invalidCheck.desc}
            maxLength="4000"
          />
          {descWordLimit ? (
            <Form.Control.Feedback type="invalid">Max characters reached.</Form.Control.Feedback>
          ) : (
            <Form.Control.Feedback type="invalid">
              Please enter a description!
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form>
    </section>
  );
};

DataJobNameDesc.propTypes = {
  setDetails: PropTypes.func.isRequired,
  invalidCheck: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
};

export { DataJobNameDesc };
