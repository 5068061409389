import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

const ImageQualityAssessmentsFaqFeedback = ({ additionalData, children }) => {
  const [sendStatus, setSendStatus] = useState(ActionStatusConstants.INITIAL);
  const [fieldMsgVal, setFieldMsgVal] = useState('');
  const [fieldMsgValid, setFieldMsgValid] = useState(true);

  const isMounted = useIsMounted();

  const onClickSend = (setIsVisible) => {
    setFieldMsgValid(true);
    let isValid = true;

    if (!(fieldMsgVal && fieldMsgVal.length)) {
      setFieldMsgValid(false);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    if (isMounted.current) {
      setSendStatus(ActionStatusConstants.ISBUSY);

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/emails/send-email',
        data: {
          to_emails: ['data@backbone.ai'],
          additional_data: {
            content: fieldMsgVal ?? '',
            ...additionalData,
          },
        },
        onSuccess: () => {
          if (isMounted.current) {
            toast.success('Message was sent successfully.');
            setSendStatus(ActionStatusConstants.SUCCESS);
            setIsVisible(false);
          }
        },
        onError: () => {
          if (isMounted.current) {
            setSendStatus(ActionStatusConstants.FAILURE);
          }
        },
      });
    }
  };

  const doResetState = () => {
    setSendStatus(ActionStatusConstants.INITIAL);
    setFieldMsgVal('');
  };

  const isBusy = sendStatus === ActionStatusConstants.ISBUSY;

  const renderSendButtonFunc = ({ setIsVisible }) => (
    <>
      {isBusy && <LoadingSpinner style={{ marginRight: '0.5em' }} fast />}
      <Button variant="primary" onClick={() => onClickSend(setIsVisible)} disabled={isBusy}>
        Send
      </Button>
    </>
  );

  return (
    <ModalPanel
      header="Give feedback on Image Quality Assessment"
      body={
        <Form.Group controlId="send_message" className="send_message">
          <Form.Label>
            Message
            {fieldMsgVal && fieldMsgVal.length > 0 ? (
              <span className="char-count" style={{ marginLeft: '0.25em' }}>
                ({fieldMsgVal.length}/1000)
              </span>
            ) : null}
          </Form.Label>
          <Form.Control
            as="textarea"
            value={fieldMsgVal}
            onChange={(e) => setFieldMsgVal(e.target.value)}
            rows="5"
            maxLength="1000"
            style={{ resize: 'none' }}
            isInvalid={!fieldMsgValid}
            disabled={isBusy}
          />

          {!fieldMsgValid && <div className="invalid-feedback">Please provide a message.</div>}
          <div className="subtitle">
            Send feedback to our team on the Image Quality Assessment Databot.
          </div>
        </Form.Group>
      }
      footer={renderSendButtonFunc}
      className="request-data-modal-panel"
      onClose={doResetState}
    >
      {({ setIsVisible }) => typeof children === 'function' && children({ setIsVisible })}
    </ModalPanel>
  );
};

ImageQualityAssessmentsFaqFeedback.defaultProps = {
  additionalData: null,
};

ImageQualityAssessmentsFaqFeedback.propTypes = {
  children: PropTypes.func.isRequired,
  additionalData: PropTypes.object,
};

export { ImageQualityAssessmentsFaqFeedback };
