import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatTimeStamp, formatMinDateStamp } from 'helpers/TimeUtils';
import { wrapWithTooltip } from 'helpers/Utils';
import './FileResultItem.scss';

const FileResultItem = ({ fileName, createdAt, link, tip }) => (
  <div className="file-container-group">
    <div className="file-info">
      <div className="file-name">
        {wrapWithTooltip({
          target: <div className="file-name">{fileName}</div>,
          content: tip,
        })}
      </div>
      {createdAt && (
        <>
          <span className="file-date-created-at">{formatMinDateStamp(createdAt)}</span>{' '}
          <span className="file-time-created-at">{formatTimeStamp(createdAt)}</span>
        </>
      )}
    </div>

    {link && (
      <div className="download-item">
        <a
          href={link}
          target="about:_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            if (!link) e.preventDefault();
          }}
          style={{ pointerEvents: link ? 'auto' : 'none', opacity: link ? 1 : 0.5 }}
        >
          <FontAwesomeIcon icon={['fa', 'download']} />
        </a>
      </div>
    )}
  </div>
);

export { FileResultItem };
