import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import './FilesTables.scss';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinnerForTable } from 'components/common/LoadingSpinner/LoadingSpinnerForTable';
import {
  statusValueConstants as DataDetailsConstants,
  statusTransformFiles as TransformedFileStatusConst,
  TAB_NAMES,
  TRANSFORMATION_REQUIRED_STATUS,
} from 'constants/DataJobDetailsConstants';
import { getViewedCommentsFromLocalStorage } from 'helpers/AttachmentUtils';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { isRoleAdmin, UserUtils } from 'helpers/UserUtils';

import { FileRow } from './FileRow';
import { UploadAttachment } from './UploadAttachment';

const STATUS_HASH = Object.values(TransformedFileStatusConst).reduce((arr, curr) => {
  arr[curr.value] = curr;
  return { ...arr, [curr.value]: curr };
}, {});

// TODO: This component should be refactored/optimized in a future iteration
/**
 *
 * @param {string} activeTab
 * @param {Object} dataJobData
 * @param {array} dataJobCommentsData
 * @param {Object} dataJobCommentsDataStatus
 * @param {array} transformedFiles
 * @param {array} originalFiles
 * @param {array} selectedFiles
 * @param {function} doSetSelectedFiles
 * @param {function} getAttachmentData
 * @param {bool} areAttachmentsLoading
 * @returns Render
 */

const FilesTable = ({
  activeTab,
  dataJobData,
  setDataJobData,
  dataJobCommentsData,
  dataJobCommentsDataStatus,
  transformedFiles,
  originalFiles,
  selectedFiles,
  doSetSelectedFiles,
  getAttachmentData,
  areAttachmentsLoading,
}) => {
  const { featureFlags } = RootHooks.useFeatureFlags();

  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();
  const [viewedComments, setViewedComments] = useState({});
  const [statusData, setStatusData] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  // Edit Mode = can change job status and/or comments
  const [isEditMode, setIsEditMode] = useState(false);
  const [dataJobStatus, setDataJobStatus] = useState(dataJobData?.status);
  const [dataJobId, setDataJobId] = useState(dataJobData?.id);
  const [bots, setBots] = useState([]);
  const [uploadDone, setUploadDone] = useState(0);

  // get bots
  useEffect(() => {
    const doGetBots = () => {
      const reqCfg = {
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/available-bots?automated=false`,
        onSuccess: (res) => {
          if (isMounted.current) {
            const botsByAlphabet = res.data.sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            );
            setBots(botsByAlphabet);
          }
        },
      };
      ApiCalls.doCall(reqCfg);
    };

    if (dataJobData) {
      doGetBots();
    }
  }, [isMounted, dataJobData]);

  useEffect(() => {
    // Check edit mode
    const checkisEditMode = () => {
      if (['closed', 'canceled'].includes(dataJobStatus)) setIsEditMode(false);
      else if (activeTab === TAB_NAMES.TRANSFORMED && !isRoleAdmin(activeUser))
        setIsEditMode(false);
      else setIsEditMode(true);
    };
    checkisEditMode();

    // TODO: This has to be reworked at some point with seen state management on BE
    const viewedCommentsFromStorage = getViewedCommentsFromLocalStorage();
    if (viewedCommentsFromStorage) {
      setViewedComments(viewedCommentsFromStorage);
    }
  }, [activeTab, dataJobStatus, activeUser]);

  useEffect(() => {
    if (dataJobData) {
      setDataJobStatus(dataJobData?.status);
      setDataJobId(dataJobData?.id);
    }
  }, [dataJobData]);

  const decorateStatusData = useCallback((statuses) => {
    const tempStatuses = statuses.map((status) => ({
      label: status.name,
      value: status?.id,
      baseColor: STATUS_HASH[status.id]?.color,
    }));
    return tempStatuses;
  }, []);

  const buildStatusData = useCallback(() => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/attachments/statuses/`,
      params: {
        type: 'data-job',
        data_request_id: dataJobData?.id,
      },
      onSuccess: (res) => {
        const data = decorateStatusData(res.data);
        setStatusData(data.length ? data : [null]);
      },
      onError: () => {
        // set a null first array value so that use effect doesnt call this func when isBusy changes constantly
        setStatusData([null]);
      },
      onEnd: () => setIsBusy(false),
    };

    if (dataJobId && statusData.length === 0) {
      setIsBusy(true);
      ApiCalls.doCall(reqCfg);
    }
  }, [dataJobId, decorateStatusData, statusData, dataJobData?.id]);

  useEffect(() => {
    // if the comp is mounted, and since we set statusData to [], if the first item is null, and if the endpoint hasn't been called already: call the function
    if (isMounted.current && !statusData[0] && !isBusy) {
      buildStatusData();
    }
  }, [buildStatusData, isBusy, statusData, isMounted]);

  const onFileUploaded = () => {
    setUploadDone(uploadDone + 1);
    getAttachmentData();
  };

  const onFileDeleted = () => {
    getAttachmentData();
  };

  const checkAllFilesAccepted = useCallback(
    (id) => {
      if (dataJobData?.status !== DataDetailsConstants.COMPLETE) {
        // CHECK OTHER TRANSFORMED FILES FOR ALL BEING ACCEPTED
        const files = transformedFiles.filter((file) => file.id !== id);
        if (
          files.every((file) => file.status.name === TransformedFileStatusConst.FILE_ACCEPTED.label)
        ) {
          setDataJobData({ ...dataJobData, status: DataDetailsConstants.COMPLETE });
          ApiCalls.doCall({
            method: ApiCalls.HTTP_METHODS.PATCH,
            urlPath: `/data-requests/${dataJobData?.id}`,
            data: { status: DataDetailsConstants.COMPLETE },
            onSuccess: () => {
              if (isMounted.current) {
                toast.success(`Successfully saved Job Status to '${DataDetailsConstants.COMPLETE}`);
              }
            },
            onError: (err) => {
              if (isMounted.current) {
                console.error(err.message);
              }
            },
          });
        }
      }
    },
    [dataJobData, isMounted, setDataJobData, transformedFiles]
  );

  // RENDER ROWS
  const renderFiles = () => {
    const files = activeTab === TAB_NAMES.TRANSFORMED ? transformedFiles : originalFiles || [];
    return files.map((file) => {
      return (
        <div className="file-row" key={file.id}>
          <FileRow
            activeTab={activeTab}
            file={file}
            dataJobCommentsData={
              dataJobCommentsData?.filter(
                (commentItem) => commentItem?.attachment?.id === file.id
              ) || []
            }
            dataJobCommentsDataStatus={dataJobCommentsDataStatus}
            viewedComments={viewedComments}
            onFileDeleted={onFileDeleted}
            isDisabled={DataDetailsConstants.COMPLETE.includes(dataJobStatus)}
            statusData={statusData}
            bots={bots}
            selectedFiles={selectedFiles}
            doSetSelectedFiles={doSetSelectedFiles}
            checkAllFilesAccepted={checkAllFilesAccepted}
            editable={dataJobData?.editable}
          />
        </div>
      );
    });
  };

  // RENDER HEADERS
  return (
    <>
      <div>
        <div className="files-table-container">
          {/* TABLE HEADER: COLUMN TITLES */}
          <div className="files-table-header-row">
            {featureFlags.ENABLE_BATCH_ATTACHMENT_DOWNLOAD ? (
              <div className="files-table select-column select-header">Select</div>
            ) : null}
            <div className="files-table filename-column filename-header">File Name</div>
            <div className="files-table date-column date-header">Upload By / Time</div>
            <div className="files-table comment-column">Comments</div>
            <div className="files-table bots-column">Databots</div>
            {activeTab === TAB_NAMES.TRANSFORMED ? (
              <div className="files-table status-column">File Status</div>
            ) : null}
            <div className="files-table assessment-column" />
            {featureFlags.ENABLE_PRIVACY_DISPLAY && (
              <div className="files-table privacy-column">Privacy</div>
            )}
            <div className="files-table actions-column">Actions</div>
          </div>
          {areAttachmentsLoading ? <LoadingSpinnerForTable /> : renderFiles()}
        </div>
      </div>
      {isEditMode && !UserUtils.isReadOnly(activeUser) && (
        <UploadAttachment
          transformationStatus={
            activeTab === TAB_NAMES.TRANSFORMED
              ? TRANSFORMATION_REQUIRED_STATUS.DONE.value
              : TRANSFORMATION_REQUIRED_STATUS.YES.value
          }
          showLinkButton
          onFileUploaded={onFileUploaded}
          dataJobId={dataJobId}
          dataJobStatus={dataJobStatus}
          onLinkUploaded={onFileUploaded}
        />
      )}
    </>
  );
};

FilesTable.defaultProps = {
  dataJobData: null,
  dataJobCommentsData: null,
  dataJobCommentsDataStatus: null,
  transformedFiles: null,
  originalFiles: null,
  selectedFiles: null,
  doSetSelectedFiles: () => {},
  getAttachmentData: () => {},
  areAttachmentsLoading: true,
};

FilesTable.propTypes = {
  dataJobData: PropTypes.object,
  dataJobCommentsData: PropTypes.array,
  dataJobCommentsDataStatus: PropTypes.string,
  transformedFiles: PropTypes.array,
  originalFiles: PropTypes.array,
  selectedFiles: PropTypes.array,
  doSetSelectedFiles: PropTypes.func,
  getAttachmentData: PropTypes.func,
  areAttachmentsLoading: PropTypes.bool,
};

export { FilesTable };
