import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as ApiCalls from 'api/ApiCalls';
import { generateExportDownloadUrl } from 'helpers/AttachmentUtils';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import { formatDateTimeStamp } from 'helpers/TimeUtils';
import { downloadFile, getDefaultTableColumnDef } from 'helpers/Utils';

export const BrandNameTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('current_brand_name', 'Current brand name'),
      formatter: (_cell, row) => row.brand_name ?? 'N/A',
      sort: false,
      editable: false,
    },
    {
      ...getDefaultTableColumnDef('number_of_skus', 'Number of SKUs'),
      formatter: (_cell, row) => row.product_count,
      sort: false,
      editable: false,
    },
    {
      ...getDefaultTableColumnDef('updated_brand_name', 'Update or add brand name'),
      formatter: (_cell, row) => (
        <div className="assign-brand-names-table-editor form-control">
          {row.updated_brand_name?.length > 0 ? row.updated_brand_name : <span>N/A</span>}
        </div>
      ),
      sort: false,
    },
  ],
};

export const ApprovalPanelTableDef = ({ onDownload }) => {
  return {
    columns: [
      {
        ...getDefaultTableColumnDef('file_name', 'File'),
        formatter: (_cell, row) => (
          <button className="link" type="button" onClick={() => onDownload()}>
            {row.file_name}
          </button>
        ),
        sort: false,
      },
      {
        ...getDefaultTableColumnDef('actions', ''),
        formatter: () => (
          <button onClick={() => onDownload()} type="button">
            Download File
          </button>
        ),
        sort: false,
      },
    ],
  };
};

export const PricingValidationLogTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('created_by', 'User'),
    },
    {
      ...getDefaultTableColumnDef('approved_finished_at', 'Date'),
      formatter: (_cell, row) => formatDateTimeStamp(row.finished_at),
    },
    {
      ...getDefaultTableColumnDef('fill_rate', 'Price Data Fill Rate'),
      formatter: (_cell, row) => (row.fill_rate ? `${(row.fill_rate * 100).toFixed(0)}%` : '0%'),
    },
    {
      ...getDefaultTableColumnDef('file_name', 'File Name'),
      formatter: (_cell, row) => (
        <div className="reports-table-file-details">
          <a
            href={row.file_download_url}
            target="_blank"
            rel="noreferrer noopener"
            className="reports-table-file-details__name"
            download={row.file_name}
          >
            {row.file_name}
          </a>
        </div>
      ),
    },
    {
      ...getDefaultTableColumnDef('actions', 'File Download'),
      formatter: (_cell, row) => (
        <button
          className="reports-table-download-file"
          onClick={() => downloadFile(row.file_download_url, row.file_name)}
          type="button"
        >
          Download File
        </button>
      ),
      sort: false,
    },
  ],
};

export const AltTextCreatorLogTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('created_by', 'User'),
    },
    {
      ...getDefaultTableColumnDef('approved_finished_at', 'Date'),
      formatter: (_cell, row) => formatDateTimeStamp(row.finished_at),
    },
    {
      ...getDefaultTableColumnDef('file_name', 'File Name'),
      formatter: (_cell, row) => (
        <div className="reports-table-file-details">
          <a
            href={row.file_download_url}
            target="_blank"
            rel="noreferrer noopener"
            className="reports-table-file-details__name"
            download={row.file_name}
          >
            {row.file_name}
          </a>
        </div>
      ),
    },
    {
      ...getDefaultTableColumnDef('actions', 'File Download'),
      formatter: (_cell, row) => (
        <button
          className="reports-table-download-file"
          onClick={() => downloadFile(row.file_download_url, row.file_name)}
          type="button"
        >
          Download File
        </button>
      ),
      sort: false,
    },
  ],
};

const EXPORT_TYPES = {
  general: { value: 'general', label: 'General Attributes' },
  detailed: { value: 'detailed', label: 'Detailed Attributes' },
  asset: { value: 'asset', label: 'Digital Assets' },
};

export const BaseDataFillExportTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('started', 'Started At'),
      sort: false,
      formatter: (_cell, row) => (row.created_at ? formatDateTimeStamp(row.created_at) : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('status', 'Status'),
      sort: false,
      formatter: (_cell, row) => {
        let output = 'N/A';

        if (['in_progress', 'failed', 'done'].includes(row.status)) {
          output = { in_progress: 'In Progress', failed: 'Failed', done: 'Done' }[row.status];
        }
        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('created_by', 'User'),
      sort: false,
      formatter: (_cell, row) => row.user?.email || 'N/A',
    },
    {
      ...getDefaultTableColumnDef('type', 'Export Type'),
      sort: false,
      formatter: (_cell, row) => {
        let output = 'N/A';
        if (Object.keys(EXPORT_TYPES).includes(row.type)) {
          output = EXPORT_TYPES[row.type].label;
        }
        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('file', 'Download'),
      sort: false,
      formatter: (_cell, row) => {
        const downloadUrl = generateExportDownloadUrl(row.id) || null;
        return row.status === 'done' ? (
          <a
            className="btn btn-primary btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href={downloadUrl}
          >
            <span className="icon" style={{ marginRight: '0.5em' }}>
              <FontAwesomeIcon icon={['far', 'file-download']} />
            </span>
            Download
          </a>
        ) : (
          <div>Please wait</div>
        );
      },
    },
  ],
};

export const BaseDataFillLogTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('type', 'Type'),
      sort: false,
      formatter: (_cell, row) => (row.type ? row.type : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('exported_at', 'Exported At'),
      sort: false,
      formatter: (_cell, row) => (row.exported_at ? formatDateTimeStamp(row.exported_at) : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('export', 'Exported File'),
      sort: false,
      formatter: (_cell, row) => {
        const downloadUrl = generateExportDownloadUrl(row.export_id) || null;
        return (
          <a
            className="btn btn-primary btn-sm"
            target="_blank"
            rel="noopener noreferrer"
            href={downloadUrl}
          >
            <span className="icon" style={{ marginRight: '0.5em' }}>
              <FontAwesomeIcon icon={['far', 'file-download']} />
            </span>
            Download
          </a>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('updated_at', 'Updated At'),
      sort: false,
      formatter: (_cell, row) => (row.updated_at ? formatDateTimeStamp(row.updated_at) : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('uploaded_file', 'Corrected File'),
      sort: false,
      formatter: (_cell, row) =>
        row.uploaded_file ? (
          <a
            href={row.uploaded_file_url}
            target="_blank"
            rel="noreferrer noopener"
            className="btn btn-primary btn-sm"
            download={row.uploaded_file}
          >
            <span className="icon" style={{ marginRight: '0.5em' }}>
              <FontAwesomeIcon icon={['far', 'file-download']} />
            </span>
            Download
          </a>
        ) : (
          'N/A'
        ),
    },
    {
      ...getDefaultTableColumnDef('created_by', 'User'),
      sort: false,
      formatter: (_cell, row) => row.user || 'N/A',
    },
    {
      ...getDefaultTableColumnDef('status', 'Status'),
      sort: false,
      formatter: (_cell, row) => row.status || 'N/A',
    },
    {
      ...getDefaultTableColumnDef('before', 'Before Databot Run'),
      sort: false,
      formatter: (_cell, row) => row.before || 'N/A',
    },
    {
      ...getDefaultTableColumnDef('after', 'After Databot Run'),
      sort: false,
      formatter: (_cell, row) => row.after || 'N/A',
    },
  ],
};
