export const evaluateTaggedUsers = (body, taggableUsers, setTaggedUsers) => {
  // loop through the comment and find all tags to make sure only correct ones are present
  let bodyText = body;

  // new function for mentions v2 update
  const mentionRegex = `[mention\\-id="]+[0-9]+[">]`;
  let matchMention = bodyText.search(mentionRegex);
  const newUserArray = [];
  if (matchMention !== -1) {
    while (matchMention !== -1) {
      // slice the string until right beofre the id number
      bodyText = bodyText.slice(matchMention + 12);
      // find the closing tag
      const end = bodyText.search(`">`);
      // get the user id
      const user = parseInt(bodyText.slice(0, end));
      const userObject = taggableUsers.filter((u) => u.id === user)[0];
      // make sure the user name isn't already set
      if (newUserArray.filter((u) => u.id === user).length === 0) {
        newUserArray.push(userObject);
      }
      // find the next user
      matchMention = bodyText.search(mentionRegex);
    }
    setTaggedUsers(newUserArray);
    return newUserArray;
  }
  // old function that is still around to avoid breaking old mentions
  const regex = `[<span]+[\\s]+[class="comment\\-mention">]+[@]+[a-zA-Z0-9@+-_.]+[</span>]`;
  let match = bodyText.search(regex);

  while (match !== -1) {
    // slice the string until we reach a username
    bodyText = bodyText.slice(match + 31);
    // find the closing tag
    const end = bodyText.search(`</span>`);
    // get the user name
    const user = bodyText.slice(0, end);
    // make sure the user name isn't already set
    if (newUserArray.filter((u) => u.username === user).length === 0) {
      newUserArray.push(taggableUsers.filter((u) => u.username === user)[0]);
    }
    // find the next user
    match = bodyText.search(regex);
  }
  setTaggedUsers(newUserArray);
  return newUserArray;
};

const removeEmptyDescendants = (parent) => {
  // for each child
  if (parent.childNodes.length > 0) {
    // loop from end to beginning of children
    for (let i = parent.children.length - 1; i >= 0; i--) {
      const child = parent.children[i];
      // check children of children
      removeEmptyDescendants(child);

      // remove if empty
      if (child.matches(':empty')) {
        child.remove();
      }
    }
  }
};

export const checkCommentStringForEmptyTags = (string) => {
  const temp = document.createElement('div');
  temp.innerHTML = string;
  removeEmptyDescendants(temp);
  return temp.innerHTML;
};
