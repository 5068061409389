import './ImageQualityRow.scss';
import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import Moment from 'react-moment';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { RequestUpdateCard } from 'components/common/RequestUpdateCard/RequestUpdateCard';
import { BOT_SLUGS, IMAGE_QUALITY_ASSESSMENT_TYPES } from 'constants/BotConstants';
import { MIN_DATE } from 'constants/DateTimeConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { EVENTS, FSEvent } from 'helpers/logging';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { UserUtils } from 'helpers/UserUtils';
import { downloadExcelFile, downloadFile, isConstantEqual } from 'helpers/Utils';

import { ImageDataCard } from '../ImageDataCard/ImageDataCard';
import { ImageQualityAssessmentConfiguration } from '../ImageQualityAssessmentConfiguration';

/**
 * Render Image Quality Assessment Data
 *
 * @param {object} data Image quality report data object
 * @param {string} type 'manufacturer' or 'distributor'
 * @return render
 */

const ImageQualityRow = ({
  assessmentType = IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY,
  data,
  type,
}) => {
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [configurationData, setConfigurationData] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const isMounted = useIsMounted();

  const { activeUser } = RootHooks.useActiveUser();
  const isSupplier = UserUtils.isRoleSupplier(activeUser);
  const getConfig = useCallback(() => {
    setIsLoadingConfig(true);
    // get config data
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/get-score-config/${BOT_SLUGS.IMAGE_QUALITY}/${data.client_id}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setConfigurationData(res.data.configuration);
          setIsLoadingConfig(false);
        }
      },
    });
  }, [data, isMounted]);

  // FIXME: Don't use the lifecycle method for a side-effect like this. Create an event handler and fetch the config there.
  // Not currently fixable due to the way the modal is rendered.
  useEffect(() => {
    if (showConfigModal) {
      getConfig();
    }
  }, [showConfigModal, getConfig]);

  const doDownloadFile = (name) => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/status/${data.bot_status}/report?name=${encodeURIComponent(name)}`,
      responseType: 'arraybuffer',
      onSuccess: (res) => {
        downloadExcelFile(res.data, res.headers);
        FSEvent(EVENTS.DATABOT_REPORT_DOWNLOADED, {
          botStatusId: data.bot_status,
          downloaderId: activeUser?.id,
          downloaderEmail: activeUser?.email,
        });
      },
      onError: () => {
        toast.error('Something went wrong with your download.');
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const doDownloadImages = (name) => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/bots/status/${data.bot_status}/images?name=${encodeURIComponent(name)}`,
      onSuccess: (res) => {
        if ('message' in res.data) {
          toast.success(res.data.message);
        } else {
          downloadFile(res.data.url, res.data.filename);
        }

        FSEvent(EVENTS.IMQA_IMAGES_DOWNLOADED, {
          botStatusId: data.bot_status,
          downloaderId: activeUser?.id,
          downloaderEmail: activeUser?.email,
        });
      },
      onError: () => {
        toast.error('Something went wrong with your download.');
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const imageData =
    assessmentType === IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY
      ? data.additional_data?.images
      : data.additional_data?.secondary_images;

  const assessmentTypeLabel =
    assessmentType === IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY ? 'Primary' : 'Secondary';

  const reportData = data.additional_data?.reports;

  const renderNewImagesCard = () => {
    // Only show new images if a historical total images exists
    if (!imageData?.new_images?.old || imageData?.new_images?.total === 0) return null;

    return (
      <ImageDataCard
        data={imageData?.total_images}
        title={`New ${assessmentTypeLabel} Images`}
        report={reportData[`New ${assessmentTypeLabel} Images`]}
        doDownloadFile={doDownloadFile}
        doDownloadImages={doDownloadImages}
      />
    );
  };

  const renderMissingImagesCard = () => {
    if (assessmentType !== IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY) return null;

    return (
      <div className="assessment-data">
        <div className="data-title">Missing Primary Images</div>
        <div className="data-stats">
          <span className="value">{imageData?.missing_images?.toLocaleString()}</span>
        </div>
        {imageData?.missing_images > 0 && (
          <div className="actions">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => doDownloadFile(reportData['Missing Primary Images'])}
              disabled={!reportData['Missing Primary Images']}
            >
              Download Detailed Report
            </button>
          </div>
        )}
      </div>
    );
  };

  if (!imageData && reportData) return null;

  return (
    <div className="image-quality-row">
      <div className="image-quality-header">
        <span className="title">
          {isConstantEqual(type, UserRoleConstants.MANUFACTURER)
            ? data?.client_name
            : data?.manufacturer_name}
        </span>
        {isConstantEqual(type, UserRoleConstants.MANUFACTURER) ? (
          <span className="action">
            {showConfigModal && (
              <div id="modal-container">
                <ModalPanel
                  className="image-quality-config-modal"
                  isVisible={showConfigModal}
                  setIsVisible={setShowConfigModal}
                  showCancel
                  header={
                    <span className="title">
                      {isConstantEqual(type, UserRoleConstants.MANUFACTURER)
                        ? data?.client_name
                        : data?.manufacturer_name}
                    </span>
                  }
                  body={
                    <div className="configuration-data-wrapper">
                      {!isLoadingConfig ? (
                        <ImageQualityAssessmentConfiguration
                          configurationData={configurationData}
                        />
                      ) : (
                        <LoadingSpinner />
                      )}
                    </div>
                  }
                  centered
                />
              </div>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowConfigModal(true)}
            >
              View Distributor Databot Configuration
            </button>
          </span>
        ) : null}
        <span className="date">
          Last Updated: <Moment format={MIN_DATE} date={imageData?.date} />
        </span>
      </div>
      <div className="image-quality-data">
        <div className="total-products">
          <div className="assessment-data">
            <div className="data-title">Total Products</div>
            <div className="data-stats">
              <span className="value">{imageData?.total_products?.toLocaleString()}</span>
              {imageData?.old_total_products
                ? `from ${imageData.old_total_products?.toLocaleString()}`
                : null}
            </div>
          </div>
          <div className="assessment-data">
            <div className="data-title">% With {assessmentTypeLabel} Images</div>
            <div className="data-stats">
              <span className="value">
                {imageData?.percent_with_image?.toLocaleString('en', { style: 'percent' })}
              </span>
              {imageData?.old_percent_with_image
                ? `from ${imageData.old_percent_with_image?.toLocaleString('en', {
                    style: 'percent',
                  })}`
                : null}
            </div>
          </div>
        </div>
        <ImageDataCard
          data={imageData?.total_images}
          title={`Total ${assessmentTypeLabel} Images`}
          report={reportData[`Total ${assessmentTypeLabel} Images`]}
          doDownloadFile={doDownloadFile}
          doDownloadImages={doDownloadImages}
        />
        {renderNewImagesCard()}
        {renderMissingImagesCard()}
        {!UserUtils.isReadOnly(activeUser) && (
          <div className="update-wrapper">
            {isSupplier ? (
              <RequestUpdateCard
                title={`Send ${assessmentTypeLabel} Image Update`}
                description="Send updated images to this distributor."
                actionText="Create Data Job"
              />
            ) : (
              <RequestUpdateCard
                title={`Request ${assessmentTypeLabel} Image Update`}
                description="Let this manufacturer know you need images."
                actionText="Create Data Job"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ImageQualityRow.propTypes = {
  data: PropTypes.shape({ additional_data: PropTypes.object }).isRequired,
  type: PropTypes.oneOf([
    UserRoleConstants.MANUFACTURER.toLowerCase(),
    UserRoleConstants.DISTRIBUTOR.toLowerCase(),
  ]).isRequired,
};

export { ImageQualityRow };
