import React from 'react';

import _get from 'lodash/get';
import Moment from 'react-moment';

import { MIN_DATE } from 'constants/DateTimeConstants';
import { statusValuesEnum } from 'helpers/RenderUtils';
import * as Utils from 'helpers/Utils';

export const SectionDataJobsTableDef = {
  columns: [
    {
      ...Utils.getDefaultTableColumnDef('id', 'Data Job Number'),
    },

    {
      ...Utils.getDefaultTableColumnDef('updated_at', 'Last Updated'),
      formatter: (_cell, row) => {
        if (row.created_at) {
          return <Moment format={MIN_DATE} date={row.updated_at} />;
        }
        return 'N/A';
      },
    },

    {
      ...Utils.getDefaultTableColumnDef('status', 'Status'),
      formatter: (_cell, row) => {
        return <span className="status">{statusValuesEnum[row?.status] || 'N/A'}</span>;
      },
    },

    {
      ...Utils.getDefaultTableColumnDef('name', 'Job Title'),
      formatter: (_cell, row) => {
        if (row.name) {
          return (
            <a href={`/data-request/${row.id}`} onClick={(e) => e.stopPropagation()}>
              {row.name}
            </a>
          );
        }
        return 'N/A';
      },
    },

    {
      ...Utils.getDefaultTableColumnDef('distributor', 'Distributor'),
      formatter: (_cell, row) => {
        const mfrId = _get(row, 'manufacturer.id') || null;
        let clientName = _get(row, 'client.name') || null;
        let clientCode = _get(row, `client.code[${mfrId}]`) || null;

        if (clientName === null) {
          if (row.requestor_user.profile && row.requestor_user.profile.client) {
            clientName = row.requestor_user.profile.client.name;
            clientCode = row.requestor_user.profile.client.code[mfrId];
          }
        }
        return (
          <div className="company-info company-info-distributor">
            <div className="name">{clientName || 'N/A'}</div>
            {clientCode && <div className="organization">({clientCode})</div>}
          </div>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const getDistributorName = (row) => {
          const distObj = _get(row, 'client');
          const nameStr = Utils.joinNameToStr(_get(distObj, 'name'));
          return nameStr;
        };
        return (
          (order === 'asc' ? 1 : -1) *
          `${getDistributorName(rowA) || ''}`.localeCompare(`${getDistributorName(rowB) || ''}`)
        );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('manufacturer', 'Manufacturer'),
      formatter: (_cell, row) => {
        const clientId = _get(row, 'client.id') || null;
        const mfrName = _get(row, 'manufacturer.name') || null;
        const mfrCode = _get(row, `manufacturer.code[${clientId}]`) || null;

        return (
          <div className="company-info company-info-manufacturer">
            <div className="name">{mfrName || 'N/A'}</div>
            {mfrCode && <div className="organization">({mfrCode})</div>}
          </div>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const getManufacturerName = (row) => {
          const manufObj = _get(row, 'manufacturer');
          const nameStr = Utils.joinNameToStr(_get(manufObj, 'name'));
          return nameStr;
        };
        return (
          (order === 'asc' ? 1 : -1) *
          `${getManufacturerName(rowA) || ''}`.localeCompare(`${getManufacturerName(rowB) || ''}`)
        );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('recipient', 'Recipient'),
      formatter: (_cell, row) => {
        const userObj = _get(row, 'assignee_user');
        const nameStr = Utils.joinNameToStr(
          _get(userObj, 'first_name'),
          _get(userObj, 'last_name')
        );

        let objOrgId, mfrId, clientId;
        if (userObj?.profile?.client) {
          objOrgId = _get(row, 'manufacturer.id') || null;
          mfrId = _get(row, 'manufacturer.id') || null;
        } else if (userObj?.profile?.manufacturer) {
          objOrgId = _get(row, 'client.id') || null;
          clientId = _get(row, 'client.id') || null;
        }

        const orgObj =
          _get(userObj, 'profile.client') || _get(userObj, 'profile.manufacturer') || null;

        const orgCode = _get(orgObj, `code[${objOrgId}]`) || null;

        const currentUserOrgCode =
          _get(row, `currentUserProfile.profile.client.code[${mfrId}]`) ||
          _get(row, `currentUserProfile.profile.manufacturer.code[${clientId}]`) ||
          null;

        return (
          <div className="user-info user-info-recipient">
            <div className="name">{nameStr || 'N/A'}</div>
            {orgObj ? (
              <div className="organization">
                {orgObj.name}
                {orgCode && orgCode !== currentUserOrgCode ? ` (${orgCode})` : null}
              </div>
            ) : null}
          </div>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const getRecipientName = (row) => {
          const userObj = _get(row, 'assignee_user');
          const nameStr = Utils.joinNameToStr(
            _get(userObj, 'first_name'),
            _get(userObj, 'last_name')
          );

          return nameStr;
        };

        return (
          (order === 'asc' ? 1 : -1) *
          `${getRecipientName(rowA) || ''}`.localeCompare(`${getRecipientName(rowB) || ''}`)
        );
      },
    },
  ],
};
