import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ValueBlock } from 'components/common/ValueBlock/ValueBlock';
import { ImageQualityAssessmentsFaqPanel } from 'components/image-quality-assessment/ImageQualityAssessmentsFaqPanel';
import { BOT_SLUGS } from 'constants/BotConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';
import { isConstantEqual } from 'helpers/Utils';
import './ViewImageQualityAssessmentsFaq.scss';

const ViewImageQualityAssessmentsFaq = ({ type, id }) => {
  const history = useHistory();

  const [orgName, setOrgName] = useState(null);
  const [headerCount, setHeaderCount] = useState(null);
  const [configurationData, setConfigurationData] = useState({});
  const [isLoadingHeader, setIsLoadingHeader] = useState(true);

  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();

  const isAdmin = activeUser?.role === UserRoleConstants.ADMIN;

  const slug = BOT_SLUGS.IMAGE_QUALITY;

  // get data for distributors
  const getDistributorData = useCallback(() => {
    // get header data
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/get-score-config/${slug}/${id}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setOrgName(res.data.client.name);
          setHeaderCount(res.data.total_manufacturers);
          setConfigurationData(res.data.configuration);
        }
      },
    });
  }, [id, slug, isMounted]);

  // get data for manufacturers
  const getManufacturerData = useCallback(() => {
    // get header data
    if (isAdmin) {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/manufacturers/${id}`,
        onSuccess: (res) => {
          if (isMounted.current) {
            setOrgName(res.data.name);
          }
        },
      });
    } else {
      setOrgName(activeUser?.profile?.manufacturer?.name);
    }

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/dashboard/details`,
      params: { manufacturer_id: id },
      onSuccess: (res) => {
        if (isMounted.current) {
          setHeaderCount(res.data.total_products);
        }
      },
    });
  }, [id, isMounted, isAdmin, activeUser?.profile?.manufacturer?.name]);

  // set header load based on param values
  useEffect(() => {
    if (
      headerCount &&
      orgName &&
      (isConstantEqual(type, UserRoleConstants.MANUFACTURER) || configurationData !== {})
    ) {
      setIsLoadingHeader(false);
    }
  }, [headerCount, orgName, configurationData, type]);

  useEffect(() => {
    if (isConstantEqual(type, UserRoleConstants.MANUFACTURER)) {
      getManufacturerData();
    } else {
      getDistributorData();
    }
  }, [isMounted, type, id, getDistributorData, getManufacturerData]);

  return (
    <div className="content content-fluid view-image-quality-assessments-faq">
      <Helmet>
        <title>Image Quality Assessments FAQ</title>
      </Helmet>
      <section className="heading-stats">
        <ul className="data-items">
          <ValueBlock
            as={<li />}
            className="data-item"
            title="Company"
            value={isLoadingHeader ? <LoadingSpinner /> : orgName}
          />

          <ValueBlock
            as={<li />}
            className="data-item"
            title={`Total ${type === 'manufacturer' ? 'Products' : 'Manufacturers'}`}
            value={isLoadingHeader ? <LoadingSpinner /> : headerCount}
            tooltip="The number of manufacturers you receive data from."
          />
        </ul>
      </section>
      <section className="title">
        <div className="label">Why was an image flagged incorrectly?</div>
        <div className="actions">
          <button
            type="button"
            className="btn btn-secondary btn-give-feedback"
            onClick={() => history.push(`/image-quality/assessment/${type}/${id}`)}
          >
            Back to Image Quality Assessments
            <FontAwesomeIcon style={{ marginLeft: '0.5em' }} icon={['far', 'long-arrow-right']} />
          </button>
        </div>
      </section>
      <section className="subtitle">
        BackboneAI uses state-of-the-art technology to assess your images to distributor
        requirements. We use a neural network to assess your images and train our algorithms refine
        our image assessment services. Sometimes, our technology is incorrect. Here you will find a
        list of reasons and why an image has been flagged that does not pass your inspection.
      </section>
      <section className="faq-list">
        <ImageQualityAssessmentsFaqPanel companyId={id} type={type} />
      </section>
    </div>
  );
};

export { ViewImageQualityAssessmentsFaq };
