import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { UserProfileCircle } from 'components/common/UserProfileCircle/UserProfileCircle';
import * as TimeUtils from 'helpers/TimeUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import * as Utils from 'helpers/Utils';

// TODO: Comments, refactor
const renderHistoryItems = (activityEvents) => {
  let output = null;

  if (activityEvents?.length) {
    output = (
      <ul className="history-items">
        {activityEvents?.map((item, index) => (
          <li key={index} className="history-item-wrap">
            <div className="history-item">
              <div className="user-circle-block">
                <UserProfileCircle
                  name={item.user.first_name}
                  imageSrc={item.user.profile.picture}
                  size="md"
                  singleInitial
                />
              </div>
              <div className="info-block">
                <div className="action-text-wrap">
                  <span className="user-name">
                    {Utils.joinNameToStr(item.user.first_name, item.user.last_name)}
                  </span>{' '}
                  <span className="action-text">{item.action}</span>
                </div>
                <div className="action-date">
                  {TimeUtils.formatDateTimeStamp(item.created_at, ' [at]')}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return output;
};

const HistoryBlock = React.memo(({ dataJobData }) => {
  const isMounted = useIsMounted();

  const [activityEvents, setActivityEvents] = useState([]);
  const [isActivityLoading, setIsActivityLoading] = useState(true);

  const [isExpanded, setIsExpanded] = useState(false);

  const commentsPollInterval = useRef();

  const clearCommentInterval = () => {
    if (commentsPollInterval.current !== null) {
      clearInterval(commentsPollInterval.current);
      commentsPollInterval.current = null;
    }
  };

  const loadActivity = useCallback((jobId, isMounted) => {
    if (!(jobId && isMounted?.current)) {
      return;
    }

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/${jobId}/history`,
      onSuccess: (res) => {
        if (isMounted.current && res?.data) {
          setActivityEvents(res.data);
        }
      },
      onEnd: () => setIsActivityLoading(false),
    });
  }, []);

  useEffect(() => {
    loadActivity(dataJobData?.id, isMounted);

    if (isMounted.current && dataJobData?.id && !commentsPollInterval.current) {
      commentsPollInterval.current = setInterval(() => {
        if (isMounted.current) {
          loadActivity(dataJobData?.id);
        } else {
          clearCommentInterval();
        }
      }, 7654);
    }
    return () => {
      clearCommentInterval();
    };
  }, [dataJobData?.id, loadActivity, isMounted]);

  return (
    <div
      className={classNames('panel-block', 'data-job-panel-history-block', {
        expanded: isExpanded,
      })}
    >
      <div className="block-title">
        <div className="title">
          <span className="label">View Job History</span>
          {isActivityLoading && (
            <div className="loadingspinner">
              <LoadingSpinner />
            </div>
          )}
        </div>
        {!!activityEvents?.length && (
          <div className="actions">
            <button
              type="button"
              title={isExpanded ? 'Collapse' : 'Expand'}
              className="btn btn-secondary btn-lg action action-expand"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </button>
          </div>
        )}
      </div>
      <div className="block-content">{renderHistoryItems(activityEvents)}</div>
    </div>
  );
});

export { HistoryBlock };
