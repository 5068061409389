import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { dispatch } from 'use-bus';

import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';

import { BOT_STATUSES } from '../../../constants/BotConstants';
import { EventTypeConstants } from '../../../constants/EventTypeConstants';
import { CdsConfigurationForm } from './_cds_configs/_forms/CdsConfigurationForm';
import { CdsTriggerModal } from './_cds_configs/_modals/CdsTriggerModal';
import { BotModalHeader } from './_components/BotModalHeader';
import { ImgBotEngineDownloadModal } from './ImgBotEngineDownloadModal';
import './JobActions.scss';

/**
 * List of buttons of actions that can be currently performed on an IDS job.
 *
 * @param {object} jobRow Job row object, containing process_job and decorated with handlers
 * @param {boolean} isLargeBtn If true, renders large buttons
 *
 * @return render
 */
export const CdsJobActions = ({ jobRow, isLargeBtn }) => {
  // Extract decorated items
  const { onRunJob, onCancelJob } = jobRow;

  const jobId = jobRow?.process_job?.id ?? null;

  const onModalStateChanged = (isOpen) => {
    dispatch({
      type: EventTypeConstants.IDS_MODAL_STATE_CHANGED,
      state: isOpen ? 'open' : 'closed',
    });
  };

  const getBotStatus = (processJob) => {
    const { subtype } = processJob?.bot_statuses;
    const slug = subtype?.replaceAll('-', '_');
    return processJob?.bot_statuses[slug]?.status;
  };

  const runJobIsEnabled = ({ processJob, isLoading }) => {
    const { subtype } = processJob.bot_statuses;
    const status = getBotStatus(processJob);
    const validStates = [BOT_STATUSES.FAILED, BOT_STATUSES.NOT_RUN, BOT_STATUSES.PENDING];
    return (
      isLoading ||
      processJob?.set_for_run ||
      !processJob?.additional_data?.[0]?.file_name ||
      (!validStates.includes(status) && subtype)
    );
  };

  const viewFilesIsEnabled = ({ processJob, isLoading }) => {
    return isLoading || !processJob?.additional_data?.[0]?.file_name;
  };

  const cancelJobIsEnabled = ({ processJob, isLoading }) => {
    const status = getBotStatus(processJob);
    const validStates = [BOT_STATUSES.COMPLETED, BOT_STATUSES.FAILED];
    return validStates.includes(status) || isLoading || !processJob;
  };

  const viewConfigurationIsEnabled = ({ processJob, isLoading }) => {
    return (
      isLoading ||
      !processJob?.bot_statuses?.subtype ||
      !processJob?.additional_data?.[0]?.file_name
    );
  };

  return (
    <div className="ids-job-action-btns">
      <ModalPanel
        backdrop="static"
        header={
          <>
            <BotModalHeader botId={jobRow?.process_job?.id} />
          </>
        }
        body={({ setIsVisible }) => (
          <CdsTriggerModal
            job={jobRow?.process_job}
            setIsVisible={setIsVisible}
            onSuccess={onRunJob}
          />
        )}
        className="img-bot-modal-panel cds-job-modal-panel"
        centered
        onOpen={() => onModalStateChanged(true)}
        onClose={() => onModalStateChanged(false)}
      >
        {({ setIsVisible }) => (
          <>
            <button
              type="button"
              className={classNames('btn', 'btn-primary', 'btn-enhance', { 'btn-lg': isLargeBtn })}
              onClick={() => setIsVisible(true)}
              disabled={runJobIsEnabled({
                isLoading: jobRow?.isLoading,
                processJob: jobRow?.process_job,
              })}
              title="Start Run"
            >
              <FontAwesomeIcon icon={['far', 'flask-potion']} />
              <span className="label">Start Run</span>
            </button>
          </>
        )}
      </ModalPanel>
      <ConfirmDialog
        backdrop="static"
        onConfirm={() => onCancelJob({ jobId })}
        headerContent="Cancel this CDS job?"
        bodyContent="Are you sure you want to cancel this CDS job? All files will be scrapped."
      >
        {({ onClick }) => (
          <button
            type="button"
            className={classNames('btn', 'btn-primary', 'btn-cancel', { 'btn-lg': isLargeBtn })}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            disabled={cancelJobIsEnabled({
              isLoading: jobRow?.isLoading,
              processJob: jobRow?.process_job,
            })}
            title="Cancel Run"
          >
            <FontAwesomeIcon icon={['far', 'trash-alt']} />{' '}
            <span className="label">Cancel Run</span>
          </button>
        )}
      </ConfirmDialog>
      <ModalPanel
        backdrop="static"
        header="Job Files"
        body={<ImgBotEngineDownloadModal className="icon" imgJob={jobRow} />}
        className="img-bot-download-output-modal-panel"
        centered
        onOpen={() => onModalStateChanged(true)}
        onClose={() => onModalStateChanged(false)}
      >
        {({ setIsVisible }) => (
          <button
            type="button"
            className={classNames('btn', 'btn-primary', 'btn-files', { 'btn-lg': isLargeBtn })}
            onClick={() => setIsVisible(true)}
            disabled={viewFilesIsEnabled({
              processJob: jobRow?.process_job,
              isLoading: jobRow?.isLoading,
            })}
            title="View Files"
          >
            <FontAwesomeIcon icon={['far', 'copy']} /> <span className="label">View Files</span>
          </button>
        )}
      </ModalPanel>
      <ModalPanel
        backdrop="static"
        header="Job Configuration"
        className="img-bot-modal-panel cds-job-modal-panel"
        body={
          <CdsConfigurationForm
            botJob={jobRow?.process_job}
            selectedMode={jobRow?.process_job?.bot_statuses?.subtype}
            disabled
          />
        }
      >
        {({ setIsVisible }) => (
          <button
            type="button"
            className={classNames('btn', 'btn-primary', 'btn-config', { 'btn-lg': isLargeBtn })}
            onClick={() => setIsVisible(true)}
            disabled={viewConfigurationIsEnabled({
              processJob: jobRow?.process_job,
              isLoading: jobRow?.isLoading,
            })}
            title="View Configuration"
          >
            <FontAwesomeIcon icon={['far', 'cogs']} />{' '}
            <span className="label">View Configuration</span>
          </button>
        )}
      </ModalPanel>
    </div>
  );
};
