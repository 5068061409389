import './Databot.scss';

import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { BOT_STATUSES, BOT_SLUGS } from 'constants/BotConstants';
import { RootHooks } from 'helpers/RootHooks';

import { DatabotApplyingChanges } from './status/DatabotApplyingChanges';
import { DatabotAwaitingApproval } from './status/DatabotAwaitingApproval';
import { DatabotCompleted } from './status/DatabotCompleted';
import { DatabotDefault } from './status/DatabotDefault';
import { DatabotFailed } from './status/DatabotFailed';
import { DatabotNotRun } from './status/DatabotNotRun';
import { DatabotRecentlyFinished } from './status/DatabotRecentlyFinished';
import { DatabotRequiresApproval } from './status/DatabotRequiresApproval';
import { DatabotRunning } from './status/DatabotRunning';
import { DatabotStatic } from './status/DatabotStatic';

const DatabotCard = ({ data, runBot, downloadReport, viewDetails, isLoading, checkStatus }) => {
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [hasExceededTimeRemaining, setHasExceededTimeRemaining] = useState(null);
  const [hasCheckedStatus, setHasCheckedStatus] = useState(false);

  const { activeUser } = RootHooks.useActiveUser();

  // The databots which skip the first databot card (estimated time)
  const skipsInitialCard = [BOT_SLUGS.PRICING_UPDATE];
  // The databots which should show the "in progress" badge instead of downloads
  const showsInProgress = [BOT_SLUGS.PRICING_UPDATE];

  const isStatus = (val) => {
    return data.status === val;
  };

  const didUserStartDatabot = () => {
    return data.startedBy?.id === activeUser?.id;
  };

  const calculateTimeElapsed = () => {
    const start = moment(data.queuedAt).utc();
    const end = moment(data.queuedAt).utc().add(data.avgRuntime, 'seconds');
    const endDiff = end.utc().diff(start, 'seconds');
    const currentDiff = moment().utc().diff(start, 'seconds');

    return currentDiff / endDiff;
  };

  const calculateTimeRemaining = () => {
    const interval = 1000;
    const end = moment(data.queuedAt).utc().add(data.avgRuntime, 'seconds');
    const time = end.utc().diff(moment().utc(), 'seconds');
    let duration = moment.duration(time * 1000, 'milliseconds');
    // Before showing the exceeded message, check if this databot has finished
    if (duration.asMilliseconds() <= 0) {
      setHasExceededTimeRemaining(true);
      if (!hasCheckedStatus) {
        checkStatus();
        setHasCheckedStatus(true);
      }
      return 0;
    }

    setHasExceededTimeRemaining(false);
    duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');
    return duration.format('hh[h]:mm[m]:ss[s]');
  };

  const getDescription = () => {
    let { description } = data;

    if (isStatus(BOT_STATUSES.RUNNING) || isStatus(BOT_STATUSES.PENDING))
      description = data.descriptionRunning?.length > 0 ? data.descriptionRunning : description;
    else if (isStatus(BOT_STATUSES.COMPLETED))
      description = data.descriptionCompleted?.length > 0 ? data.descriptionCompleted : description;

    return <p>{description}</p>;
  };

  const renderRunningClass = () => {
    // Check more complex conditionals outside of the 'some'
    if (!data.hasDownloaded && isStatus(BOT_STATUSES.COMPLETED) && data.reportable) return true;
    return [
      BOT_STATUSES.RUNNING,
      BOT_STATUSES.PENDING,
      BOT_STATUSES.FAILED,
      BOT_STATUSES.AWAITING_APPROVAL,
      BOT_STATUSES.APPROVED,
    ].some((status) => isStatus(status));
  };

  const renderCardStatus = () => {
    const description = getDescription();

    switch (true) {
      // If the databot is awaiting approval, the current user was the original user who started the bot, and the bot requires approval for changes.
      case isStatus(BOT_STATUSES.AWAITING_APPROVAL) &&
        didUserStartDatabot() &&
        data.requiresApproval:
        return <DatabotRequiresApproval runBot={runBot} />;
      // If the databot is awaiting approval, the current user was NOT the original user who started the bot, and the bot requires approval for changes.
      case isStatus(BOT_STATUSES.AWAITING_APPROVAL) &&
        !didUserStartDatabot() &&
        data.requiresApproval:
        return <DatabotAwaitingApproval />;
      // If the databot has been approved for its changes and is applying them to the database.
      case isStatus(BOT_STATUSES.APPROVED):
        return <DatabotApplyingChanges />;
      // If the databot is running or pending (queued).
      case isStatus(BOT_STATUSES.RUNNING) || isStatus(BOT_STATUSES.PENDING):
        return (
          <DatabotRunning
            description={description}
            hasExceededTimeRemaining={hasExceededTimeRemaining}
            timeElapsed={timeElapsed}
            timeRemaining={timeRemaining}
          />
        );
      // If the databot has not been downloaded by the current user, the databot has been completed, and the bot generates reports for download.
      case !data.hasDownloaded && isStatus(BOT_STATUSES.COMPLETED) && data.reportable:
        return (
          <DatabotRecentlyFinished description={description} downloadReport={downloadReport} />
        );
      // If the databot has been completed, or the databot "skips" the first default card (with est. runtime) and has not failed
      case isStatus(BOT_STATUSES.COMPLETED) ||
        isStatus(BOT_STATUSES.REJECTED) ||
        (skipsInitialCard.includes(data.slug) && !isStatus(BOT_STATUSES.FAILED)):
        return (
          <DatabotCompleted
            hasDetails={data.hasDetails}
            reportable={data.reportable}
            inProgress={data.inProgress}
            showInProgress={showsInProgress.includes(data.slug)}
            downloads={data.downloads}
            completed={data.completed}
            queuedAt={data.queuedAt}
            viewDetails={viewDetails}
            runBot={runBot}
            downloadReport={downloadReport}
            description={description}
          />
        );
      case isStatus(BOT_STATUSES.STATIC):
        return <DatabotStatic description={description} />;
      case isStatus(BOT_STATUSES.DEFAULT):
        return (
          <DatabotDefault
            description={description}
            running={data.running}
            completed={data.completed}
          />
        );
      case isStatus(BOT_STATUSES.FAILED):
        return <DatabotFailed runBot={runBot} />;
      default:
        return (
          <DatabotNotRun description={description} runBot={runBot} avgRuntime={data.avgRuntime} />
        );
    }
  };

  useEffect(() => {
    if (!isStatus(BOT_STATUSES.RUNNING) && !isStatus(BOT_STATUSES.PENDING)) return () => {};
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
      setTimeElapsed(calculateTimeElapsed());
    }, 1000);
    return () => clearInterval(timer);
  });

  return (
    <div
      className={classNames('databot', {
        'databot--running': renderRunningClass(),
      })}
    >
      <div className="databot-header">
        <FontAwesomeIcon className="cubes-icon" icon={['far', 'cubes']} />
        <h2>{data.name}</h2>
      </div>
      <div className="databot-details">
        {isLoading ? (
          <span className="databot-details--loading">
            <LoadingSpinner />
          </span>
        ) : (
          renderCardStatus()
        )}
      </div>
    </div>
  );
};

export { DatabotCard };

DatabotCard.propTypes = {
  data: PropTypes.shape({
    queuedAt: (props, propName) => {
      let error;
      if (props.queuedAt === null || props.queuedAt === undefined) return error;
      // Validate that the time passed in for "queuedAt" is an earlier time, so that countdowns will work
      const now = moment().utc();
      if (now < moment(props.queuedAt).utc())
        error = new Error(`${propName} cannot be set in the future`);
      return error;
    },
  }).isRequired,
};
