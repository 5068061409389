import UserRoleConstants from 'constants/UserRoleConstants';

/**
 * Checks whether a user belongs to a role or an array of roles
 *
 * @param {Object} user User object
 * @param {String|Array} role Role or array of roles
 * @returns User belongs to role
 */
export const isRole = (user, role) => {
  if (user?.role && role) {
    if (Array.isArray(role) && role.length) {
      if (role.includes(user.role)) {
        return true;
      }
    } else if (user.role === role) {
      return true;
    }
  }

  return false;
};

export const hasUserFeatureAccess = (user, features) => {
  if (Array.isArray(user.profile.platform_features_access)) {
    if (Array.isArray(features)) {
      if (features.every((r) => user.profile.platform_features_access.includes(r))) {
        return true;
      }
    } else if (typeof features === 'string') {
      if (user.profile.platform_features_access.includes(features)) {
        return true;
      }
    }
  }
  return false;
};

export const isRoleAdmin = (user) => {
  return isRole(user, UserRoleConstants.ADMIN);
};

export const isRoleClient = (user) => {
  return isRole(user, UserRoleConstants.CLIENT);
};

export const isRoleSupplier = (user) => {
  return isRole(user, UserRoleConstants.SUPPLIER);
};

export const isRoleSelfService = (user) => {
  return isRole(user, UserRoleConstants.SELF_SERVICE);
};

export const setShouldShowHomeWelcome = (shouldShow) => {
  window.localStorage.setItem('should_show_home_welcome', shouldShow ? '1' : '0');
};

export const getShouldShowHomeWelcome = () => {
  return !!(window.localStorage.getItem('should_show_home_welcome') === '1');
};

export const getCompany = (user) => {
  if (user?.profile?.client?.id) {
    return user.profile.client;
  }
  if (user.profile?.manufacturer?.id) {
    return user.profile.manufacturer;
  }
  return null;
};

const isReadOnly = (user) => !!user?.profile?.is_read_only;

const UserUtils = {
  isRole,
  isRoleAdmin,
  isRoleClient,
  isRoleSupplier,
  setShouldShowHomeWelcome,
  getShouldShowHomeWelcome,
  getCompany,
  isReadOnly,
  isRoleSelfService,
  hasUserFeatureAccess,
};

export { UserUtils };
