import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _get from 'lodash/get';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { DataIngestionDataQualityContext } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQualityContext';

const summaryItemData = {
  errors: { icon: ['fas', 'ban'], label: 'Errors', class: 'errors' },
  warnings: { icon: ['fas', 'exclamation-triangle'], label: 'Warnings', class: 'warnings' },
  ok: { icon: ['fas', 'check-circle'], label: 'No Issues', class: 'ok' },
};

const DataIngestionDataQualitySheetSelect = () => {
  const ctx = useContext(DataIngestionDataQualityContext);
  const { attachmentSheetsData, activeSheet, setActiveSheet } = ctx;

  const renderSummaryItem = (type, value) => {
    if (!summaryItemData[type]) {
      console.error('Invalid type', type);
      return null;
    }

    const itemData = summaryItemData[type];

    const tabIcon = (
      <span className="icon">
        <FontAwesomeIcon icon={itemData.icon} />
      </span>
    );

    const content =
      type === 'ok' ? (
        tabIcon
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              className={`tooltip tooltip-data-ingestion-data-quality tooltip-data-ingestion-data-quality-${type}`}
            >
              {tabIcon} <span className="label">{itemData.label}:</span>{' '}
              <span className="value">{value}</span>
            </Tooltip>
          }
        >
          {tabIcon}
        </OverlayTrigger>
      );

    return <span className={`item item-${type}`}>{content}</span>;
  };

  const renderSummaryItems = (errorsCount, warningsCount) => {
    return (
      <span className="errors-warnings-summary">
        {errorsCount > 0 || warningsCount > 0 ? (
          <>
            {errorsCount > 0 && renderSummaryItem('errors', errorsCount)}
            {warningsCount > 0 && renderSummaryItem('warnings', warningsCount)}
          </>
        ) : (
          <>{renderSummaryItem('ok')}</>
        )}
      </span>
    );
  };

  const renderSheetTab = (sheet, index) => {
    if (!(sheet && sheet.id && sheet.name)) {
      console.error('Invalid sheet', sheet);
      return null;
    }

    const errorsCount = _get(sheet, 'assessment_score.error_skus');
    const warningsCount = _get(sheet, 'assessment_score.warning_skus');

    const output = (
      <li
        className={classNames('sheet-select-item', {
          active: activeSheet && sheet.id === activeSheet.id,
        })}
        key={index}
      >
        <button
          type="button"
          onClick={() =>
            ((activeSheet && sheet.id !== activeSheet.id) || !activeSheet) && setActiveSheet(sheet)
          }
        >
          <span className="sheet-name">{_get(sheet, 'name')}</span>
          {renderSummaryItems(errorsCount, warningsCount)}
        </button>
      </li>
    );

    return output;
  };

  return (
    <div className="data-ingestion-data-quality-sheet-select">
      <ul className="sheet-select-list">
        {attachmentSheetsData &&
          attachmentSheetsData.length &&
          attachmentSheetsData.map((item, index) => renderSheetTab(item, index))}
      </ul>
    </div>
  );
};

export { DataIngestionDataQualitySheetSelect };
