import './DataJobUploadFileList.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { Button, ListGroup } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import DataJobCreateConstants from 'constants/DataJobCreateConstants';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { buildTextWithUrl, formatBytes } from 'helpers/Utils';

const DataJobUploadFileList = ({ fileList, linkList, setFileOrLink, uploadId, setUploadId }) => {
  const { activeUser } = RootHooks.useActiveUser();

  const [attachCount, setAttachCount] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(ActionStatusConstants.INITIAL);
  const [deleteProgFile, setDeleteProgFile] = useState(null);

  useEffect(() => {
    setAttachCount(fileList.length + linkList.length);
  }, [fileList, fileList.length, linkList.length]);

  const canDeleteFile = useCallback(
    (fileItem) => {
      if (!fileItem) {
        return false;
      }

      const ownerId = fileItem?.user?.id ?? null;
      const currUserId = activeUser?.id ?? null;

      return !!(ownerId && currUserId && ownerId === currUserId);
    },
    [activeUser?.id]
  );

  const doDeleteFile = useCallback(
    (delFile) => {
      if (!(delFile && delFile.id)) {
        return;
      }
      setDeleteProgFile({ id: delFile.id, type: delFile.type });

      setUploadStatus(ActionStatusConstants.ISBUSY);

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.DELETE,
        urlPath: `/data-requests/attachments/${delFile.id}`,
        onSuccess: () => {
          setFileOrLink({
            type:
              DataJobCreateConstants.FILE === delFile.type
                ? DataJobCreateConstants.DELETE_FILE
                : DataJobCreateConstants.DELETE_LINK,
            payload: delFile.id,
          });

          setUploadStatus(ActionStatusConstants.SUCCESS);
          toast.success(`File successfully removed.`);
        },
        onError: () => {
          setUploadStatus(ActionStatusConstants.FAILURE);
        },
        onEnd: () => {
          if (uploadId === delFile.id) {
            setUploadId(null);
          }
          setDeleteProgFile(null);
        },
      });
    },
    [setFileOrLink, setUploadId, uploadId]
  );

  const deleteButtonRender = useCallback(
    (item) => {
      return canDeleteFile(item) ? (
        <ConfirmDialog
          onConfirm={() => doDeleteFile(item)}
          headerContent="Delete File?"
          bodyContent={
            <span className="item-deletebutton-confirmbody">
              {`Are you sure you want to delete file "${item.original_name ?? item.name}"?`}
            </span>
          }
        >
          {({ onClick }) => (
            <Button
              variant="outline-danger"
              title="Delete"
              className="item-deletebutton"
              disabled={uploadStatus === ActionStatusConstants.ISBUSY}
              onClick={() => {
                return uploadStatus !== ActionStatusConstants.ISBUSY && onClick();
              }}
            >
              {!deleteProgFile || (deleteProgFile && item.id !== deleteProgFile.id) ? (
                <FontAwesomeIcon icon={['far', 'trash-alt']} />
              ) : (
                <LoadingSpinner style={{ fontSize: '1em' }} />
              )}
            </Button>
          )}
        </ConfirmDialog>
      ) : null;
    },
    [canDeleteFile, deleteProgFile, doDeleteFile, uploadStatus]
  );

  // usecallback to memoize render of list items and only rerender when deps change
  const renderListItems = useCallback(() => {
    return [
      ...fileList.map((f) => {
        f.type = DataJobCreateConstants.FILE;
        return (
          <ListGroup.Item className="item-listgroup" key={f.id}>
            <span className="itemtext">{f.original_name ?? f.name}</span>
            <span className="itemdetail">{formatBytes(f.size)}</span>
            {deleteButtonRender(f)}
          </ListGroup.Item>
        );
      }),
      ...linkList.map((l) => {
        l.type = DataJobCreateConstants.LINK;
        return (
          <ListGroup.Item className="item-listgroup" key={l.id}>
            <span className="itemlink">{buildTextWithUrl(l.original_name ?? l.name, true)}</span>
            <span className="itemdetail">External Link</span>
            {deleteButtonRender(l)}
          </ListGroup.Item>
        );
      }),
    ];
  }, [deleteButtonRender, fileList, linkList]);

  return (
    <div className="data-job-create-upload-list">
      <div className="headertext">Files{` (${attachCount})`}</div>
      <ListGroup variant="flush">{renderListItems()}</ListGroup>
    </div>
  );
};

DataJobUploadFileList.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.object),
  linkList: PropTypes.arrayOf(PropTypes.object),
  setFileOrLink: PropTypes.func,
  uploadId: PropTypes.number,
  setUploadId: PropTypes.func,
};

export { DataJobUploadFileList };
