import React from 'react';

import PropTypes from 'prop-types';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';

// Null/custom template select item
const customTemplateOption = {
  value: null,
  label: 'Custom Template',
};

/**
 * Template selection widget
 *
 * @param {array} templatesData Templates list
 * @param {object} value Current selected item
 * @param {function} onChange Change handler
 * @param {boolean} isDisabled Disabled state
 * @return render
 */
const BlockTemplateSelect = ({ templatesData, value, onChange, isDisabled }) => {
  // Generate Select component options and inject custom template item
  const getTemplateOptions = () => {
    const _templatesData = templatesData
      ? templatesData.map((item) => {
          return { value: item.id, label: item.template_name };
        })
      : [];

    _templatesData.unshift(customTemplateOption);

    return _templatesData;
  };

  // Transform template object to Select option
  const getSelectedTemplateOption = () => {
    return value ? [{ value: value.id, label: value.template_name }] : [customTemplateOption];
  };

  // Select handler
  const doSelectTemplate = (id) => {
    const templateObj = id && templatesData?.find((item) => item.id === id);

    onChange(templateObj ? { ...templateObj } : null);
  };

  return (
    <section className="block block-template-select">
      <div className="title">Template</div>
      <StyledMultiselect
        values={getSelectedTemplateOption()}
        options={getTemplateOptions()}
        setOnChange={(v) => {
          doSelectTemplate(v.value);
        }}
        getOptionValue={(option) => option.value}
        closeMenuOnSelect
        isSearchable
        isDisabled={isDisabled}
        isMulti={false}
        isClearable={false}
        canReset
      />
    </section>
  );
};

BlockTemplateSelect.defaultProps = {
  templatesData: null,
  value: null,
  isDisabled: false,
};

BlockTemplateSelect.propTypes = {
  templatesData: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export { BlockTemplateSelect };
