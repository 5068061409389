import React, { useCallback, useEffect, useRef, useState } from 'react';

import _get from 'lodash/get';
import { isIE } from 'react-device-detect';

import * as ApiCalls from 'api/ApiCalls';
import { UserCommentsPanel } from 'components/common/UserCommentsPanel/UserCommentsPanel';
import { UserCommentsPanelIE } from 'components/common/UserCommentsPanelIE/UserCommentsPanelIE';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { checkCommentStringForEmptyTags, evaluateTaggedUsers } from 'helpers/CommentUtils';
import { RootHooks } from 'helpers/RootHooks';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import * as Utils from 'helpers/Utils';

// TODO: Refactor; This should be extracted as a commont component and split between comments list and editor
/** @deprecated */
const DistributorJobComments = ({
  dataJobCommentsData,
  dataJobCommentsDataStatus,
  jobId,
  attachmentId = null,
  canEditExistingComments = true,
}) => {
  const isMounted = useIsMounted();
  const { activeUser } = RootHooks.useActiveUser();
  const [taggableUsers, setTaggableUsers] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState([]);

  const getTaggableUsers = useCallback(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/users/data-request/${jobId}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          setTaggableUsers(res.data);
        }
      },
    });
  }, [jobId, isMounted]);

  useEffect(() => {
    getTaggableUsers();
  }, [getTaggableUsers]);

  const deleteComment = ({ commentId, onFailure, onSuccess }) => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.DELETE,
      urlPath: `/data-requests/${jobId}/comments/`,
      data: {
        comment_id: commentId,
        attachment_id: attachmentId,
      },
      onSuccess: (res) => {
        onSuccess(res);
      },
      onError: (err) => {
        onFailure(err);
        toast.error('There was an error while deleting the comment.');
      },
    });
  };

  const editComment = ({ commentId, commentBody, onFailure, onSuccess }) => {
    const tempBody = checkCommentStringForEmptyTags(commentBody);
    if (tempBody === null || tempBody === '') {
      toast.error('Trying to save empty comment');
      onFailure();
      return;
    }
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/data-requests/${jobId}/comments/`,
      data: {
        comment_id: commentId,
        content: tempBody,
        attachment_id: attachmentId,
        tagged_users: evaluateTaggedUsers(tempBody, taggableUsers, setTaggedUsers),
      },
      onSuccess: (res) => {
        onSuccess(res);
      },
      onError: (err) => {
        onFailure(err);
      },
    });
  };

  const doSaveNewComment = ({ commentBody, onFailure, onSuccess }) => {
    if (!commentBody || !/\S+?/g.test(commentBody)) {
      toast.error('Trying to save empty comment');
      onFailure();
      return;
    }

    const tempBody = checkCommentStringForEmptyTags(commentBody);
    if (tempBody === null || tempBody === '') {
      toast.error('Trying to save empty comment');
      onFailure();
      return;
    }

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/data-requests/${jobId}/comments/`,
      data: {
        content: tempBody,
        attachment_id: attachmentId,
        tagged_users: evaluateTaggedUsers(tempBody, taggableUsers, setTaggedUsers),
      },
      onSuccess: (res) => {
        onSuccess(res);
        toast.success('Comment saved successfully.');
        commentBody = '';
      },
      onError: (err) => {
        onFailure(err);
      },
    });
  };

  return (
    <section className="distributor-job-comments-2">
      <div className="content">
        {dataJobCommentsDataStatus === ActionStatusConstants.SUCCESS ||
        Array.isArray(dataJobCommentsData) ? (
          isIE ? (
            <UserCommentsPanelIE
              data={
                dataJobCommentsData?.length
                  ? dataJobCommentsData?.map((item) => {
                      return {
                        id: item.id,
                        authorName: Utils.joinNameToStr(
                          _get(item, 'user.first_name') || null,
                          _get(item, 'user.last_name') || null
                        ),
                        // TODO: BE doesn't send expected profile picture
                        authorImageSrc: _get(item, 'user.profile.picture') || null,
                        commentBody: item.content,
                        createdAt: item.created_at,
                        jobId,
                      };
                    })
                  : null
              }
              deleteComment={deleteComment}
              editComment={editComment}
              newCommentOnAdd={({ commentBody, onFailure, onSuccess }) => {
                doSaveNewComment({ commentBody, onFailure, onSuccess });
              }}
              authorName={
                Utils.joinNameToStr(
                  activeUser?.first_name ?? null,
                  activeUser?.last_name ?? null
                ) || '?'
              }
              authorImageSrc={activeUser?.profile?.picture ?? null}
              textBold
              jobId={jobId}
              attachmentId={attachmentId}
              canEditExistingComment={canEditExistingComments}
            />
          ) : (
            <UserCommentsPanel
              data={
                dataJobCommentsData?.length
                  ? dataJobCommentsData.map((item) => {
                      return {
                        id: item.id,
                        authorId: item?.user?.id ?? null,
                        authorName: Utils.joinNameToStr(
                          item?.user?.first_name ?? null,
                          item?.user?.last_name ?? null
                        ),
                        // TODO: BE doesn't send expected profile picture
                        authorImageSrc: item?.user?.profile?.picture ?? null,
                        commentBody: item.content,
                        createdAt: item.created_at,
                        jobId,
                      };
                    })
                  : null
              }
              deleteComment={deleteComment}
              editComment={editComment}
              newCommentOnAdd={({ commentBody, onFailure, onSuccess }) => {
                doSaveNewComment({ commentBody, onFailure, onSuccess });
              }}
              authorId={activeUser?.id}
              authorName={
                Utils.joinNameToStr(
                  activeUser?.first_name ?? null,
                  activeUser?.last_name ?? null
                ) || '?'
              }
              authorImageSrc={activeUser?.profile?.picture ?? null}
              textBold
              canEditExistingComments={canEditExistingComments}
              taggableUsers={taggableUsers}
              taggedUsers={taggedUsers}
              setTaggedUsers={(v) => setTaggedUsers(v)}
            />
          )
        ) : null}
      </div>
    </section>
  );
};

export { DistributorJobComments };
