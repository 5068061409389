import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import BackboneLogo from 'assets/img/BackboneAI-Short-logo.svg';
import { generalAttributeSections } from 'constants/ProductConstants';

const Info = ({
  code,
  brandName,
  fallbackDescription,
  generalAttributes,
  id,
  manufacturer,
  upc,
}) => {
  // The number of characters before adding ellipsis to the description and trimming the displayed data
  const descBreakpointCount = 305;
  const [expanded, setExpanded] = useState(false);
  const [productInfo, setProductInfo] = useState({});

  useEffect(() => {
    const productUpc = upc ?? null;
    const productCode = code ?? null;
    const backboneId = id ?? null;
    const brandMfrName = brandName;
    const manufacturerName = manufacturer.name;
    const manufacturerLogo = manufacturer.logo ?? BackboneLogo;
    const productIdColumns = generalAttributes.find(
      (section) => section.label === generalAttributeSections.PRODUCT_ID.label
    ).columns;

    let productDescription = productIdColumns
      .filter((column) => column.key === 'description_1' || column.key === 'description_2')
      .map((column) => column.value)
      .join(' ');

    if (productDescription === ' ' && fallbackDescription && fallbackDescription.length)
      productDescription = fallbackDescription;

    setProductInfo({
      upc: productUpc,
      code: productCode,
      id: backboneId,
      description: productDescription,
      brandName: brandMfrName,
      manufacturerName,
      manufacturerLogo,
    });
  }, [
    brandName,
    code,
    fallbackDescription,
    generalAttributes,
    id,
    manufacturer.logo,
    manufacturer.name,
    upc,
  ]);

  const renderDescription = () => {
    let description = productInfo.description ?? '';

    if (!expanded && description.length > descBreakpointCount) {
      description = `${description.slice(0, descBreakpointCount)}...`;
    }

    const icon = expanded ? (
      <span className="oi oi-chevron-top" title="chevron top" aria-hidden="true" />
    ) : (
      <span className="oi oi-chevron-bottom" title="chevron bottom" aria-hidden="true" />
    );

    return (
      <>
        {description}
        {description.length > descBreakpointCount && (
          <span className="show-more" onClick={() => setExpanded(!expanded)}>
            Show {expanded ? 'less' : 'more'} {icon}
          </span>
        )}
      </>
    );
  };

  return (
    <div className="general-info-body-info">
      <div className="general-info-body-info-row">
        <div className="general-info-body-info-col__label">Backbone ID</div>
        <div className="general-info-body-info-col__value">#{productInfo.id}</div>
      </div>
      <div className="general-info-body-info-row">
        <div className="general-info-body-info-col__label">Manufacturer ID Number (SKU)</div>
        <div className="general-info-body-info-col__value">{productInfo.code}</div>
      </div>
      <div className="general-info-body-info-row">
        <div className="general-info-body-info-col__label">UPC / EAN / GTIN</div>
        <div className="general-info-body-info-col__value">{productInfo.upc}</div>
      </div>
      <div className="general-info-body-info-row">
        <div className="general-info-body-info-col__label">Manufacturer Name</div>
        <div className="general-info-body-info-col__value">{productInfo.manufacturerName}</div>
      </div>
      <div className="general-info-body-info-row">
        <div className="general-info-body-info-col__label">Brand Name</div>
        <div className="general-info-body-info-col__value">{productInfo.brandName}</div>
      </div>
      <div className="general-info-body-info-row">
        <div className="general-info-body-info-col__label">Description</div>
        <div className="general-info-body-info-col__value general-info-body-info-col__value--description">
          {renderDescription()}
        </div>
      </div>
    </div>
  );
};

Info.propTypes = {
  code: PropTypes.string,
  brandName: PropTypes.string,
  generalAttributes: PropTypes.array,
  id: PropTypes.number,
  manufacturer: PropTypes.object,
  upc: PropTypes.string,
  fallbackDescription: PropTypes.string,
};

export { Info };
