import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingView } from 'components/common/LoadingView/LoadingView';
import { MetricsTable } from 'components/metrics/MetricsTable/MetricsTable';
import { Overview } from 'components/metrics/Overview/Overview';
import {
  OverallManufacturersTableDef,
  OverallClientsTableDef,
} from 'helpers/table-defs/ViewMetricsTableDef';
import { useIsMounted } from 'helpers/useIsMounted';
import { sortByKey } from 'helpers/Utils';

const ViewMetrics = () => {
  const MANUFACTURER = 'manufacturer';
  const CLIENT = 'client';

  const [overviewMetrics, setOverviewMetrics] = useState({});
  const [mfrMetrics, setMfrMetrics] = useState([]);
  const [clientMetrics, setClientMetrics] = useState([]);
  const [activeTab, setActiveTab] = useState(MANUFACTURER);
  const [activeMetrics, setActiveMetrics] = useState([]);
  const [activeTableDef, setActiveTableDef] = useState(OverallManufacturersTableDef);
  const [isLoading, setIsLoading] = useState(true);
  const [renderKey, setRenderKey] = useState(0);

  const isMounted = useIsMounted();
  const history = useHistory();

  useEffect(() => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/metrics',
      onSuccess: (res) => {
        if (isMounted.current) {
          const { total_data: totalData, manufacturers, distributors } = res.data;

          setOverviewMetrics(totalData);
          setMfrMetrics(sortByKey(manufacturers, 'manufacturer_name'));
          setClientMetrics(sortByKey(distributors, 'client_name'));
          setActiveMetrics(sortByKey(manufacturers, 'manufacturer_name'));
        }
      },
      onEnd: () => {
        setIsLoading(false);
      },
    });
  }, [isMounted]);

  const handleRowSelected = (row) => {
    const backboneId = row.backbone_id;
    const url = row.manufacturer_name
      ? `/metrics/manufacturer/${backboneId}`
      : `/metrics/distributor/${backboneId}`;

    history.push(url);
  };

  const handleHeaderTabSelected = (key) => {
    setActiveMetrics(key === MANUFACTURER ? mfrMetrics : clientMetrics);
    setActiveTableDef(key === MANUFACTURER ? OverallManufacturersTableDef : OverallClientsTableDef);
    setActiveTab(key);
    setRenderKey(renderKey + 1);
  };

  const renderHeaderTabs = () => {
    const tabs = [
      {
        label: 'Manufacturers',
        key: MANUFACTURER,
      },
      { label: 'Distributors', key: CLIENT },
    ];

    return (
      <div>
        {tabs.map((tab) => (
          <button
            type="button"
            key={tab.key}
            onClick={() => handleHeaderTabSelected(tab.key)}
            className={classNames('metrics-table-main-header-tab', {
              'metrics-table-main-header-tab--active': activeTab === tab.key,
            })}
          >
            {tab.label}
          </button>
        ))}
      </div>
    );
  };

  const MetricsTableSearch = ({ onSearch }) => {
    const [value, setValue] = useState('');

    const handleSearch = (value) => {
      setTimeout(() => {
        if (value || value === '') {
          onSearch(value);
          setValue(value);
        }
      }, 1000);
    };

    return (
      <div className="metrics-table-search">
        <input
          onChange={(e) => handleSearch(e.target.value)}
          placeholder={`Search ${activeTab === MANUFACTURER ? 'Manufacturers' : 'Distributors'}`}
          className="metrics-table-search-input"
          type="text"
        />
        <button
          onClick={() => handleSearch(value)}
          className="metrics-table-search-btn"
          type="button"
        >
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'search']} />
          </span>
        </button>
      </div>
    );
  };

  const renderHelmet = () => (
    <Helmet bodyAttributes={{ 'data-page': 'view-metrics' }}>
      <title>Metrics</title>
    </Helmet>
  );

  return isLoading ? (
    <div className="content content-fluid view-metrics">
      {renderHelmet()}
      <LoadingView />
    </div>
  ) : (
    <div className="content content-fluid view-metrics">
      {renderHelmet()}
      <Overview manufacturer={mfrMetrics} client={clientMetrics} overview={overviewMetrics} />
      <ToolkitProvider
        key={renderKey}
        keyField="backbone_id"
        data={activeMetrics}
        columns={activeTableDef.columns}
        search={{ searchFormatted: true }}
      >
        {(props) => (
          <>
            <div className="metrics-table-main-header">
              {renderHeaderTabs()} <MetricsTableSearch {...props.searchProps} />
            </div>
            <MetricsTable
              usePagination
              baseProps={props.baseProps}
              handleRowSelected={handleRowSelected}
              metrics={props.baseProps.data}
              columns={{ columns: props.baseProps.columns }}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
};

export { ViewMetrics };
