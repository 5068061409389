import React, { useCallback, useState } from 'react';

import { PropTypes } from 'prop-types';

import * as ApiCalls from 'api/ApiCalls';
import { FileUpload } from 'components/common/FileUpload/FileUpload';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';
import { checkFileExtAndSize } from 'helpers/Utils';

const PricingUpdateFileUpload = ({ isUploading, setIsUploading, files, setFiles }) => {
  const isMounted = useIsMounted();
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('0');
  const [uploadId, setUploadId] = useState(null);

  const handleUpload = useCallback(
    async (data) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/data-requests/attachments/`,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
        isFileFormData: true,
        onProgress: (progressEvent) => {
          const percentage = ApiCalls.getProgressPercent(progressEvent);
          if (percentage >= 0) setUploadProgress(percentage.toFixed(0));
        },
        onSuccess: (response) => {
          if (isMounted.current) {
            setIsUploadComplete(true);
            setIsUploading(false);
            setUploadId(response.data.id);
            setFiles([...files, ...response.data]);
          }
        },
        onError: () => {
          if (isMounted.current) {
            setIsUploading(false);
          }
        },
      });
    },
    [isMounted, setIsUploading, setUploadId, files, setFiles]
  );

  const onFileClicked = useCallback(
    async (e) => {
      const typeCallback = (name) => toast.error(`File type of "${name}" not allowed.`);
      const sizeCallback = () =>
        toast.error(
          'The file you are trying to upload exceeds the 2GB attachment limit. Try putting in a shared location and adding a link instead.'
        );

      const file = checkFileExtAndSize(e, typeCallback, sizeCallback);
      if (!file) {
        return;
      }
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('is_pass_thru_file', 0);
      formData.append('transformation_required', 1);
      handleUpload(formData);
    },
    [handleUpload, setIsUploading]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // filter to only selected amount of files
      if (acceptedFiles.length > 0) {
        onFileClicked(acceptedFiles);
      }
    },
    [onFileClicked]
  );

  return (
    <section>
      <FileUpload
        onDrop={onDrop}
        isFileLoading={isUploading}
        maxFileCount={1}
        uploadProgress={uploadProgress}
        isUploadComplete={isUploadComplete}
        uploadId={uploadId}
        setIsUploadComplete={setIsUploadComplete}
        onSuccessMessage="File Successfully Added!"
      />
    </section>
  );
};

PricingUpdateFileUpload.propTypes = {
  setIsUploading: PropTypes.func,
  isUploading: PropTypes.bool,
};

export { PricingUpdateFileUpload };
