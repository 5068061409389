import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotRecentlyFinished = ({ downloadReport, description }) => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="recently-finished"
      middle={<div className="description">{description}</div>}
      bottom={
        <div className="databot-actions__recently-finished">
          <button
            onClick={downloadReport}
            className="databot-action databot-action--alt"
            type="button"
          >
            Download
            <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} />
          </button>
        </div>
      }
    />
  );
};

export { DatabotRecentlyFinished };
