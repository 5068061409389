import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Template for rendering data bot card status
 *
 * @param {object} top Top section
 * @param {object} middle Middle centered section
 * @param {object} bottom Bottom section
 * @param {object} classNameSuffix Append class suffix for custom styling
 * @param {object} children Override default layout and use only wrapper
 * @return render
 */
const DatabotStatusTemplate = ({ top, middle, bottom, classNameSuffix, children }) => {
  const _class = 'databot-status-template';

  return (
    <div
      className={classNames(_class, {
        [`${_class}-${classNameSuffix}`]: classNameSuffix?.length,
      })}
    >
      {children ?? (
        <>
          <div className={classNames(`${_class}-top`, { empty: !top })}>{top}</div>
          <div className={classNames(`${_class}-middle`, { empty: !middle })}>{middle}</div>
          <div className={classNames(`${_class}-bottom`, { empty: !bottom })}>{bottom}</div>
        </>
      )}
    </div>
  );
};

DatabotStatusTemplate.defaultProps = {
  top: null,
  middle: null,
  bottom: null,
  classNameSuffix: null,
  children: null,
};

DatabotStatusTemplate.propTypes = {
  top: PropTypes.any,
  middle: PropTypes.any,
  bottom: PropTypes.any,
  classNameSuffix: PropTypes.any,
  children: PropTypes.any,
};

export { DatabotStatusTemplate };
