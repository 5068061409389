import React from 'react';

import _get from 'lodash/get';
import Moment from 'react-moment';

import { MIN_DATE } from 'constants/DateTimeConstants';
import { statusValuesEnum } from 'helpers/RenderUtils';
import * as Utils from 'helpers/Utils';

import { DataJobsActionsDropdown } from './DataJobsActionsDropdown';

export const ViewDataJobsTableDef = ({ setShowPublishModal }) => {
  return {
    columns: [
      {
        ...Utils.getDefaultTableColumnDef('id', 'Req. ID'),
      },
      {
        ...Utils.getDefaultTableColumnDef('created_at', 'Created'),
        formatter: (_cell, row) => {
          if (row.created_at) {
            return <Moment format={MIN_DATE} date={row.created_at} />;
          }
          return 'N/A';
        },
      },
      {
        ...Utils.getDefaultTableColumnDef('updated_at', 'Last Updated'),
        formatter: (_cell, row) => {
          if (row.created_at) {
            return <Moment format={MIN_DATE} date={row.updated_at} />;
          }
          return 'N/A';
        },
      },
      {
        ...Utils.getDefaultTableColumnDef('name', 'Job Title'),
        formatter: (_cell, row) => {
          if (row.name) {
            return (
              <a href={`/data-request/${row.id}`} onClick={(e) => e.stopPropagation()}>
                {row.name}
              </a>
            );
          }
          return 'N/A';
        },
      },
      {
        ...Utils.getDefaultTableColumnDef('manufacturer', 'Manufacturer'),
        formatter: (_cell, row) => {
          const clientId = _get(row, 'client.id') || null;
          const mfrName = _get(row, 'manufacturer.name') || null;
          const mfrCode = _get(row, `manufacturer.code[${clientId}]`) || null;
          return (
             <div className="company-info company-info-manufacturer">
              <div className="name">{mfrName || 'N/A'}</div>
              {mfrCode && <div className="organization">({mfrCode})</div>}
            </div>
          );
        },
      },
      {
        ...Utils.getDefaultTableColumnDef('distributor', 'Distributor'),
        formatter: (_cell, row) => {
          const mfrId = _get(row, 'manufacturer.id') || null;
          let clientName = _get(row, 'client.name') || null;
          let clientCode = _get(row, `client.code[${mfrId}]`) || null;

          if (clientName === null) {
            if (
              row.requestor_user !== undefined &&
              row.requestor_user.profile &&
              row.requestor_user.profile.client
            ) {
              clientName = row.requestor_user.profile.client.name;
              clientCode = row.requestor_user.profile.client.code[mfrId];
            }
          }
          return (
            <div className="company-info company-info-distributor">
              <div className="name">{clientName || 'N/A'}</div>
              {clientCode && <div className="organization">({clientCode})</div>}
            </div>
          );
        },
      },
      {
        ...Utils.getDefaultTableColumnDef('requestor', 'Requestor'),
        formatter: (_cell, row) => {
          // TODO: Extract reusable logic
          const userObj = _get(row, 'requestor_user');
          const nameStr = Utils.joinNameToStr(
            _get(userObj, 'first_name'),
            _get(userObj, 'last_name')
          );

          let objOrgId, mfrId, clientId;
          if (userObj?.profile?.client) {
            objOrgId = _get(row, 'manufacturer.id') || null;
            mfrId = _get(row, 'manufacturer.id') || null;
          } else if (userObj?.profile?.manufacturer) {
            objOrgId = _get(row, 'client.id') || null;
            clientId = _get(row, 'client.id') || null;
          }

          const orgObj =
            _get(userObj, 'profile.client') || _get(userObj, 'profile.manufacturer') || null;

          const orgCode = _get(orgObj, `code[${objOrgId}]`) || null;

          const currentUserOrgCode =
            _get(row, `currentUserProfile.profile.client.code[${mfrId}]`) ||
            _get(row, `currentUserProfile.profile.manufacturer.code[${clientId}]`) ||
            null;
          return (
            <div className="user-info user-info-requestor">
              <div className="name">{nameStr || 'N/A'}</div>
              {orgObj ? (
                <div className="organization">
                  {orgObj.name}
                  {orgCode && orgCode !== currentUserOrgCode ? ` (${orgCode})` : null}
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        ...Utils.getDefaultTableColumnDef('recipient', 'Recipient'),
        formatter: (_cell, row) => {
          const userObj = _get(row, 'assignee_user');
          const nameStr = Utils.joinNameToStr(
            _get(userObj, 'first_name'),
            _get(userObj, 'last_name')
          );

          let objOrgId, mfrId, clientId;
          if (userObj?.profile?.client) {
            objOrgId = _get(row, 'manufacturer.id') || null;
            mfrId = _get(row, 'manufacturer.id') || null;
          } else if (userObj?.profile?.manufacturer) {
            objOrgId = _get(row, 'client.id') || null;
            clientId = _get(row, 'client.id') || null;
          }

          const orgObj =
            _get(userObj, 'profile.client') || _get(userObj, 'profile.manufacturer') || null;

          const orgCode = _get(orgObj, `code[${objOrgId}]`) || null;

          const currentUserOrgCode =
            _get(row, `currentUserProfile.profile.client.code[${mfrId}]`) ||
            _get(row, `currentUserProfile.profile.manufacturer.code[${clientId}]`) ||
            null;

          return (
            <div className="user-info user-info-recipient">
              <div className="name">{nameStr || 'N/A'}</div>
              {orgObj ? (
                <div className="organization">
                  {orgObj.name}
                  {orgCode && orgCode !== currentUserOrgCode ? ` (${orgCode})` : null}
                </div>
              ) : null}
            </div>
          );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('status', 'Status'),
      formatter: (_cell, row) => {
        return <span className="status">{statusValuesEnum[row?.status] || 'N/A'}</span>;
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('priority', 'Priority'),
      formatter: (_cell, row) => {
          let output = 'N/A';
          const priority = _get(row, 'priority');

          if (priority >= 0) {
            // prettier-ignore
            switch (priority) {
            case 1: output = "Low"; break;
            case 2: output = "Medium"; break;
            case 3: output = "High"; break;
            default: output = "N/A"; break;
            }
          }
          return (
            <span className={`priority${priority > 0 ? ` priority-${priority}` : ''}`}>
              {output}
            </span>
          );
        },
      },
      {
        ...Utils.getDefaultTableColumnDef('actions', 'Actions'),
        sort: false,
        formatter: (_cell, row) => {
          return (
            <DataJobsActionsDropdown
              row={row}
              setShowPublishModal={(v) => setShowPublishModal(v)}
            />
          );
        },
      },
    ],
  };
};
