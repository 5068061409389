import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { ImgBotEngineConfigDashboard } from 'components/img-bot-engine/ImgBotEngineConfigDashboard';

const ViewImgBotEngineConfig = () => {
  const history = useHistory();

  const goBack = () => {
    if (history.location.state?.from?.pathname?.includes('/image-databot-suite')) {
      history.goBack();
    } else {
      history.push(`/image-databot-suite`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Image Databot Suite - Configurations</title>
      </Helmet>
      <div className="content content-fluid view-img-bot-engine-config-dashboard">
        <div className="top-actions">
          <button type="button" onClick={goBack} className="btn btn-secondary btn-sm">
            <span className="icon" style={{ marginRight: '0.5em' }}>
              <FontAwesomeIcon icon={['far', 'long-arrow-left']} />
            </span>
            <span className="label">IDS Dashboard</span>
          </button>
        </div>

        <ImgBotEngineConfigDashboard />
      </div>
    </>
  );
};

export { ViewImgBotEngineConfig };
