import './SearchBarWDropdown.scss';

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

import { getRootMenuPortalTarget } from 'helpers/Utils';
import styleVars from 'scss/vars.scss';

const SearchBarWDropdown = ({
  setOnChange,
  isInvalid,
  loadOptions,
  defaultOptions,
  placeholder = 'Search',
  feedbackText = 'Please select a value.',
  selectedValues,
  children,
  selectWrapperStyle,
}) => {
  const Search = ({
    id,
    loadOptions,
    defaultOptions,
    placeholder,
    setOnChange,
    selectedValues,
  }) => {
    const menuPortalTarget = getRootMenuPortalTarget();
    // docs said to render this component seperate than parent select comp
    return (
      <div
        className={`styled-search-bar-container${isInvalid ? '-invalid' : ''}`}
        style={{ flexGrow: 1 }}
      >
        <AsyncSelect
          id={id}
          isClearable={false}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          value={selectedValues}
          isMulti
          hideSelectedOptions
          cacheOptions
          menuPlacement="auto"
          menuPortalTarget={menuPortalTarget}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          onChange={setOnChange}
          theme={(theme) => ({
            ...theme,
            borderRadius: '.5rem',
            colors: {
              ...theme.colors,
              primary: null,
              primary25: styleVars.colors_selectOrangeLight,
            },
          })}
          styles={{
            control: (styles) => ({
              ...styles,
              borderWidth: 0,
            }),
            container: (base) => ({
              ...base,
            }),
          }}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          }}
        />
      </div>
    );
  };

  return (
    <div style={selectWrapperStyle}>
      <InputGroup className={`search-bar-with-dropdown-template${isInvalid ? '-invalid' : ''}`}>
        <InputGroup.Prepend className="search-bar-with-dropdown-template-icon-wrapper">
          <InputGroup.Text
            className="search-bar-with-dropdown-template-icon-wrapper-icon"
            id="searchIcon"
          >
            <FontAwesomeIcon icon={['far', 'search']} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          id="search_with_dropdown"
          aria-describedby="searchIcon"
          as={Search}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          placeholder={placeholder}
          setOnChange={setOnChange}
          isInvalid={isInvalid}
          selectedValues={selectedValues}
        />
        <div className="invalid-feedback">{feedbackText}</div>
      </InputGroup>
      <Form.Group>{children}</Form.Group>
    </div>
  );
};

SearchBarWDropdown.propTypes = {
  setOnChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.array,
  placeholder: PropTypes.string,
  feedbackText: PropTypes.string,
  selectedValues: PropTypes.array,
  children: PropTypes.node,
  selectWrapperStyle: PropTypes.object,
};

export { SearchBarWDropdown };
