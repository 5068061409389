import React, { useEffect, useState, useCallback, useRef } from 'react';

import PropTypes from 'prop-types';

import * as ApiCalls from 'api/ApiCalls';
import { DatabotConfigPanel } from 'components/databots/DatabotConfigPanel/DatabotConfigPanel';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { BOT_STATUSES } from 'constants/BotConstants';
import { useIsMounted } from 'helpers/useIsMounted';

import { DataFillDefinitions } from './_DataFillDefinitions';
import { BaseDataFillBotOverview } from './BaseDataFillBotOverview';
import { BaseDataFillLogTable } from './BaseDataFillLogTable';

import './BaseDataFillBot.scss';

/**
 * Attributes Fill databot config panel
 *
 * This is an example of using the default DatabotConfigPanel template,
 * providing a custom body and confirm dialog content.

 * @param {string} botStatusId Databot status (run) ID
 * @param {string} slug Databot slug
 * @param {string} status Databot run status
 * @param {bool} requiresApproval Whether or not the databot requires approval for changes
 * @param {bool} hasDownloadedReport Whether or not the active user has downloaded the changes report
 * @param {bool} reportName The report's file name
 * @param {function} handleRunBot Event handler
 * @param {function} handleCancelBot Event handler
 * @param {object} additionalData Databot additional data
 * @param {function} handleApproveChanges Event handler for product auxiliary process
 * @param {function} handleRejectChanges Event handler for product auxiliary process
 * @param {object} configData Databot config object
 * @param {object} setConfigData Databot config object setter
 * @param {object} botData Complete bot data
 * @return render
 */
const BaseDataFillBot = ({
  botStatusId,
  slug,
  status,
  details,
  manufacturerId,
  hasDownloadedReport,
  reportName,
  handleRunBot,
  handleCancelBot,
  additionalData,
  handleApproveChanges,
  handleRejectChanges,
  setConfigData,
  botData, // TODO: Should clean-up code to use full objects
}) => {
  const { name: mfrName = null, total_products: totalProducts = null } = additionalData ?? {};
  const [exportData, setExportData] = useState([]);
  const [startExportStatus, setStartExportStatus] = useState(ActionStatusConstants.INITIAL);
  const [exportLoading, setExportLoading] = useState(true);
  const [exportHeaders, setExportHeaders] = useState([]);
  const [validUpload, setValidUpload] = useState(false);
  const [logData, setLogData] = useState([]);
  const intervalRef = useRef();

  const { name, displayLog } = DataFillDefinitions[slug];

  // initialize setting export headers
  useEffect(() => {
    setExportHeaders(additionalData?.headers || []);
  }, [additionalData]);

  useEffect(() => {
    if (details?.length > 0 && displayLog) {
      const newLogData = details
        // filter for only valid reports
        .filter((item) => {
          if (item.report_data && item.report_data?.before && item.report_data?.after) {
            return true;
          }
          return false;
        })
        .map((item) => {
          return {
            type: item.report_data?.type || 'N/A',
            status: item.status,
            exported_at: item.report_data?.exported_at,
            export_name: item.report_data?.exported_file,
            export_id: item.report_data?.export_id,
            updated_at: item.report_data?.uploaded_at,
            uploaded_file: item.uploaded_file,
            uploaded_file_url: item.uploaded_file_url,
            user: item.created_by,
            before: `${item.skus} SKUs, ${item.report_data?.before?.attribute_count} General Attributes, ${item.report_data?.before?.attribute_sum} Attribute Values`,
            after: `${item.skus} SKUs, ${item.report_data?.after?.attribute_count} General Attributes, ${item.report_data?.after?.attribute_sum} Attribute Values`,
          };
        });
      setLogData(newLogData);
    }
  }, [details, displayLog]);

  const isMounted = useIsMounted();

  const doGetExports = useCallback(() => {
    if (isMounted.current && botStatusId) {
      setExportLoading(true);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: '/product-export-history/',
        params: { bot_status_id: botStatusId },
        onSuccess: (res) => {
          if (isMounted.current) {
            // get first record only
            setExportData(res.data.slice(0, 1));
            setExportLoading(false);
          }
        },
      });
    }
  }, [isMounted, botStatusId]);

  useEffect(() => {
    if (botStatusId && isMounted.current) {
      doGetExports();
    }
  }, [botStatusId, doGetExports, isMounted, startExportStatus]);

  // use effect to trigger a periodic export refresh for user experience
  useEffect(() => {
    // set a timeout if we have an inprogress export
    if (
      isMounted.current &&
      botStatusId &&
      exportData?.length > 0 &&
      exportData[0]?.status === 'in_progress'
    ) {
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          if (isMounted.current) {
            doGetExports();
          } else {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }
        }, 3000);
      }
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [isMounted, botStatusId, exportData, doGetExports]);
  return (
    <>
      <DatabotConfigPanel
        headingStatsData={[
          { label: 'Manufacturer', value: mfrName ?? 'N/A' },
          {
            label: 'Total Products',
            value: totalProducts ?? 'N/A',
            tooltip: 'Number of unique products on BackboneAI',
          },
        ]}
        botStatusId={botStatusId}
        slug={slug}
        status={status}
        requiresApproval={false}
        hasDownloadedReport={hasDownloadedReport}
        reportName={reportName}
        title={`${name} Fill Databot`}
        bodyContent={
          <BaseDataFillBotOverview
            manufacturerId={manufacturerId}
            validUpload={validUpload}
            setValidUpload={setValidUpload}
            botStatusId={botStatusId}
            exportHeaders={exportHeaders}
            setExportHeaders={setExportHeaders}
            startExportStatus={startExportStatus}
            exportData={exportData}
            exportLoading={exportLoading}
            setStartExportStatus={setStartExportStatus}
            setConfigData={setConfigData}
            slug={slug}
          />
        }
        hideBodyActions={[
          BOT_STATUSES.PENDING,
          BOT_STATUSES.RUNNING,
          BOT_STATUSES.AWAITING_APPROVAL,
        ].includes(status)}
        confirmDialogTitle={`Run ${name} Fill Databot?`}
        confirmDialogBody={
          <>
            <p>You are about to:</p>
            Run the {name} Fill Databot
          </>
        }
        onApproveChanges={handleApproveChanges}
        onRejectChanges={handleRejectChanges}
        disableActions={{ run: !validUpload }}
        onRun={() => handleRunBot(false)}
        onCancel={() => handleCancelBot()}
      />
      {logData?.length > 0 && displayLog && <BaseDataFillLogTable logData={logData} />}
    </>
  );
};

DatabotConfigPanel.defaultProps = {
  additionalData: null,
  requiresApproval: false,
  hasDownloadedReport: false,
  reportName: null,
  botData: null,
  botStatusId: null,
  status: null,
};

DatabotConfigPanel.propTypes = {
  botStatusId: PropTypes.number,
  slug: PropTypes.string.isRequired,
  status: PropTypes.string,
  requiresApproval: PropTypes.bool,
  hasDownloadedReport: PropTypes.bool,
  reportName: PropTypes.string,
  handleRunBot: PropTypes.func,
  handleCancelBot: PropTypes.func,
  additionalData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    total_products: PropTypes.number,
    with_description: PropTypes.number,
    will_create: PropTypes.number,
    will_update: PropTypes.number,
    without_description: PropTypes.number,
  }),
  handleApproveChanges: PropTypes.func,
  handleRejectChanges: PropTypes.func,
  botData: PropTypes.any,
};

export { BaseDataFillBot };
