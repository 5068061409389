import './SheetSelect.scss';
import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelDetach';
import { DataIngestionContext } from 'components/data-ingestion/DataIngestionContext';
import DataIngestionFooter from 'components/data-ingestion/DataIngestionFooter/DataIngestionFooter';
import {
  MODALTYPES,
  TUTORIALSTEPS,
  SHEETSTATUS,
  COLUMNSTATUS,
} from 'constants/DataIngestionConstants';
import { toast } from 'helpers/ToastUtils';
import { isFunction } from 'helpers/Utils';
import styleVars from 'scss/vars.scss';

import { SheetTabColumns } from '../DataIngestionSheetTabColumns/SheetTabColumns';
import { SheetTabRows } from '../DataIngestionSheetTabRows/SheetTabRows';
import {
  ModalThankYou,
  ModalProcessing,
  ModalRestart,
  ModalDisableSheet,
  ModalColumnDisable,
} from './SheetModals';
import { Tabs } from './TabButtons';

// TODO: Huge component, to be split to managable pieces

const SheetSelect = ({ attachmentId }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [oldActiveTab, setOldActiveTab] = useState(null);
  const [activeSheetId, setActiveSheetId] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [disabledSheets, setDisabledSheets] = useState([]);
  const [dataChanged, setDataChanged] = useState([]);
  const [columnsChanged, setColumnsChanged] = useState(0);
  const [activeSheetData, setActiveSheetData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [modalFooter, setModalFooter] = useState(null);
  const [completedSheets, setCompletedSheets] = useState([]);
  const [path, setPath] = useState([]);
  const [columnsComplete, setColumnsComplete] = useState(false);
  const [isLastSheet, setIsLastSheet] = useState(false);
  const [userChangedRows, setUserChangedRows] = useState(false);
  const [userChangedHeader, setUserChangedHeader] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hideOtherColumns, setHideOtherColumns] = useState(false);
  const [hideNonErrorColumns, setHideNonErrorColumns] = useState(false);

  const {
    tutorial,
    userIsBacktracking,
    setUserIsBacktracking,
    backtrackingChanges,
    setBacktrackingChanges,
    rowStep,
    setRowStep,
    isColumnPhaseAccessible,
    setIsColumnPhaseAccessible,
    setIsAssessmentAccessible,
    isLoading,
    setIsLoading,
    isChangingStages,
    setIsChangingStages,
    sheetLoading,
    setSheetLoading,
    firstLoad,
    setFirstLoad,
    setIsNextDisabled,
  } = useContext(DataIngestionContext);

  const history = useHistory();
  const location = useLocation();

  const rowEndStatuses = [
    SHEETSTATUS.USER_EDITED_ROWS,
    SHEETSTATUS.USER_VERIFIED_ROWS,
    SHEETSTATUS.DISABLED_DURING_ROWS,
    SHEETSTATUS.PENDING_COLUMN,
  ];

  const columnEndStatuses = [
    SHEETSTATUS.USER_VERIFIED_COLUMNS,
    SHEETSTATUS.INTEGRITY_CHECKED,
    SHEETSTATUS.DISABLED_DURING_COLUMNS,
  ];

  useEffect(() => {
    setPath(location.pathname.includes('rows') ? 'rows' : 'columns');
    setIsLastSheet(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const changeUrl = (phase) => {
    history.push(`/data-ingestion/${phase}/${attachmentId}`);
  };

  // Check if user is backtracking
  const checkIfUserisBacktracking = () => {
    if (location.pathname.includes('rows')) {
      if (
        sheets.some((sheet) =>
          [
            SHEETSTATUS.PENDING,
            SHEETSTATUS.PENDING_COLUMN,
            SHEETSTATUS.USER_EDITED_COLUMNS,
            SHEETSTATUS.USER_VERIFIED_COLUMNS,
            SHEETSTATUS.INTEGRITY_CHECKED,
          ].includes(sheet.status)
        )
      ) {
        setUserIsBacktracking(true);
        setIsNextDisabled(false);
      }
    } else if (location.pathname.includes('columns')) {
      if (
        sheets.some((sheet) =>
          [SHEETSTATUS.PENDING, SHEETSTATUS.INTEGRITY_CHECKED].includes(sheet.status)
        )
      ) {
        setUserIsBacktracking(true);
        setIsNextDisabled(false);
      }
      if (
        !sheets.every((sheet) =>
          [SHEETSTATUS.USER_VERIFIED_COLUMNS, SHEETSTATUS.INTEGRITY_CHECKED].includes(sheet.status)
        )
      ) {
        setIsAssessmentAccessible(false);
      }
    }
  };

  const checkIsColumnPhaseAccessible = () => {
    if (sheets.some((sheet) => sheet.status === SHEETSTATUS.USER_EDITED_ROWS) || userChangedRows) {
      setIsColumnPhaseAccessible(false);
    }
  };

  useEffect(() => {
    checkIfUserisBacktracking();
    // the boolean needs two tests in case the page was reloaded or user is backtracking
    // isColumnPhaseAccessible is the only contextual state required for the header's buttons.
    if (location.pathname.includes('rows')) {
      checkIsColumnPhaseAccessible();
    }
    if (
      location.pathname.includes('columns') &&
      sheets.some((sheet) => sheet.status === SHEETSTATUS.USER_EDITED_COLUMNS)
    ) {
      setIsAssessmentAccessible(false);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheets, backtrackingChanges]);

  useEffect(() => {
    if (activeTab === sheets.length - 1) {
      setIsLastSheet(true);
    }
    if (sheets.length > 0 && activeTab !== null) {
      setActiveSheetId(sheets[activeTab]?.id);
      setActiveSheetData(sheets[activeTab]);
    }
  }, [activeTab, sheets]);

  const contextData = useContext(DataIngestionContext);
  const { attachmentData } = contextData;
  const jobId = attachmentData.data_request;

  // Redirect if not ready for column phase
  const checkCorrectURL = useCallback(
    (data, history) => {
      const invalidColumnPhaseStatuses = [
        SHEETSTATUS.PENDING_ROW,
        SHEETSTATUS.USER_EDITED_ROWS,
        SHEETSTATUS.USER_VERIFIED_ROWS,
        SHEETSTATUS.PENDING,
      ];
      if (
        location.pathname.includes('columns') &&
        !isChangingStages &&
        data.some((sheet) => invalidColumnPhaseStatuses.includes(sheet.status))
      ) {
        history.push(`/data-ingestion/rows/${attachmentId}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, attachmentId]
  );

  // ----------------------------------------- LOAD DATA ----------------------------------------- //

  const checkCompletedSheets = (sheetsArray) => {
    // check which sheets were complete on load to set them to be ready and change active tab
    if (sheetsArray.length > 0) {
      const completed = sheetsArray.filter((element) => {
        if (location.pathname.includes('rows')) {
          return [
            // Sheet statuses that count as 'completed' during rows phase
            SHEETSTATUS.USER_VERIFIED_ROWS,
            SHEETSTATUS.USER_EDITED_ROWS,
            SHEETSTATUS.DISABLED_DURING_COLUMNS,
            SHEETSTATUS.PENDING_COLUMN,
            SHEETSTATUS.USER_EDITED_COLUMNS,
            SHEETSTATUS.DISABLED_DURING_ROWS,
          ].includes(element.status);
        }
        return [
          // Sheet statuses that count as completed during column phase
          SHEETSTATUS.USER_VERIFIED_COLUMNS,
          SHEETSTATUS.DISABLED_DURING_COLUMNS,
        ].includes(element.status);
      });
      // Skip to latest incomplete tab
      if (completed.length > 0) {
        const completedArr = [];
        for (let i = 0; i < completed.length; i++) {
          // Do not count a tab as completed if there is a catalog item error and it isn't disabled
          if (
            completed[i].errors.length > 0 &&
            ![SHEETSTATUS.DISABLED_DURING_ROWS, SHEETSTATUS.DISABLED_DURING_COLUMNS].includes(
              completed[i].status
            )
          ) {
            break;
          } else {
            completedArr[i] = i;
          }
        }
        setCompletedSheets(completedArr);
        if (activeTab === null) {
          let target = 0;
          if (completedArr.length === sheetsArray.length) {
            target = completedArr.length - 1;
          } else {
            target = completedArr.length;
          }
          setActiveTab(target);
          setFirstLoad(false);
        }
      } else {
        setActiveTab(0);
        setFirstLoad(false);
      }
    }
    setIsLoading(false);
  };

  const checkDisabledSheets = (sheetsArray) => {
    const disabled = sheetsArray?.filter((element) =>
      [SHEETSTATUS.DISABLED_DURING_ROWS, SHEETSTATUS.DISABLED_DURING_COLUMNS].includes(
        element.status
      )
    );
    setDisabledSheets(disabled);
  };

  const checkInitialData = (sheetsArray) => {
    setInitialData(sheetsArray);
  };

  const _getSheets = async () => {
    // use the smallest relevent loading icon
    if (!sheetLoading) setIsLoading(true);

    // TODO V2: Use Constans
    const statuses = [
      SHEETSTATUS.PENDING_ROW,
      SHEETSTATUS.PENDING_COLUMN,
      SHEETSTATUS.PENDING,
      SHEETSTATUS.INTEGRITY_CHECKED,
      SHEETSTATUS.USER_EDITED_ROWS,
      SHEETSTATUS.USER_EDITED_COLUMNS,
      SHEETSTATUS.USER_VERIFIED_COLUMNS,
      SHEETSTATUS.DISABLED_DURING_COLUMNS,
    ];
    // Include Disabled Sheets for row phase
    if (location.pathname.includes('rows')) {
      statuses.push(SHEETSTATUS.USER_VERIFIED_ROWS);
      statuses.push(SHEETSTATUS.DISABLED_DURING_ROWS);
      statuses.push(SHEETSTATUS.USER_VERIFIED_ROWS);
      statuses.push(SHEETSTATUS.FAILED);
    }
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/attachments/${attachmentId}/sheets`,
      onSuccess: (res) => {
        const sheetsArray = res.data.filter((sheet) => {
          return statuses.includes(sheet.status);
        });
        checkDisabledSheets(sheetsArray);
        setSheets(sheetsArray);
        checkCompletedSheets(sheetsArray);
        if (firstLoad) checkInitialData(sheetsArray);
        checkCorrectURL(res.data, history);
      },
      onError: () => {
        setSheets([]);
        setIsLoading(false);
      },
    };
    await ApiCalls.doCall(reqCfg);
    setIsChangingStages(false);
  };
  // Separate path useEffect for jumping between phases in Header Component.
  useEffect(() => {
    if (path === 'rows' && location.pathname.includes('columns')) {
      getSheets();
      setPath(location.pathname.includes('rows') ? 'rows' : 'columns');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location]);

  const checkIfNextDisabled = useCallback(() => {
    // Enable Next button if sheet is disabled
    if (
      sheets &&
      sheets[activeTab] &&
      [SHEETSTATUS.DISABLED_DURING_ROWS, SHEETSTATUS.DISABLED_DURING_COLUMNS].includes(
        sheets[activeTab].status
      )
    ) {
      setIsNextDisabled(false);
    }
  }, [sheets, setIsNextDisabled, activeTab]);

  useEffect(() => {
    if (activeTab !== oldActiveTab) {
      setOldActiveTab(activeTab);
      if (isFunction(setUserChangedHeader)) setUserChangedHeader(false);
      checkIfNextDisabled();
      if (isFunction(setColumnsChanged)) setColumnsChanged(0);
    }
  }, [activeTab, checkIfNextDisabled, setOldActiveTab, oldActiveTab]);

  // ----------------------------------------------------------------------------------------------- //

  // ---------------------------------------- MODAL SELECTOR ---------------------------------------- //
  const redirectToJobDetails = () => {
    history.push(`/data-request/${attachmentData.data_request}`);
  };

  const doDisableNext = () => {
    setIsNextDisabled(true);
  };

  const showModal = (
    modal,
    type = null,
    value = null,
    direction = null,
    columnData = null,
    message = null
  ) => {
    let details = null;
    if (modal === MODALTYPES.PROCESSING) {
      details = ModalProcessing(redirectToJobDetails);
    } else if (
      [MODALTYPES.RESTART_FROM_COLUMN, MODALTYPES.RESTART_FROM_ASSESSMENT].includes(modal)
    ) {
      details = ModalRestart(
        type,
        value,
        modal,
        setModalVisible,
        setIsLoading,
        isLastSheet,
        goToNextSheet,
        attachmentId,
        updateRows,
        updateColumns,
        location,
        message,
        null,
        doDisableNext
      );
    } else if (modal === MODALTYPES.THANK_YOU) {
      details = ModalThankYou(redirectToJobDetails);
    } else if (modal === MODALTYPES.DISABLE_SHEET) {
      details = ModalDisableSheet(
        value,
        activeSheetData.name,
        setModalVisible,
        sheets,
        attachmentId,
        jobId,
        getSheets,
        onDisableSuccess,
        setIsLoading,
        updateRows
      );
    } else if (modal === MODALTYPES.COLUMN_DISABLE) {
      details = ModalColumnDisable(
        setModalVisible,
        isLoading,
        value,
        direction,
        columnData,
        updateColumns
      );
    }
    if (details !== null) {
      setModalVisible(true);
      setModalBody(details.body);
      setModalFooter(details.footer);
    }
  };
  // ----------------------------------------------------------------------------------------- //

  // Create Flash Assessment when columns are verified
  const startMLAssessment = () => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/restricted/data-ingestion/ml/user_verified_columns/${attachmentId}`,
      onSuccess: () => {
        showModal(MODALTYPES.PROCESSING);
      },
    });
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/attachments/${attachmentId}/integrity-checks`,
    });
  };

  // ------------------------------------ SHEET NAVIGATION ------------------------------------ //
  const changeActiveTab = (requestedTab) => {
    setSheetLoading(true);
    // If backtracking, update sheets state from db:
    if (requestedTab < activeTab) {
      setSheetLoading(true);
      _getSheets();
      setSheetLoading(false);
    }
    if (
      requestedTab > activeTab &&
      ((location.pathname.includes('rows') &&
        !rowEndStatuses.includes(activeSheetData.status) &&
        !userChangedHeader &&
        !userIsBacktracking) ||
        (location.pathname.includes('columns') &&
          !columnEndStatuses.includes(activeSheetData.status)))
    ) {
      // verifySheet();
    }
    // TODO: V2 add tooltip here  "Please Verify Current Sheet Data First..."
    setActiveTab(requestedTab);

    // Only allow next sheet if current sheet is verified:
    if (isLastSheet) {
      setIsLastSheet(false);
    }
    setSheetLoading(false);
  };
  // ----------------------------------------------------------------------------------------- //
  // --------------------------------- Next & Go Back Buttons---------------------------------//
  const notifyMlOfRowChanges = () => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/attachments/${attachmentId}/ml-definitions`,
      onError: (e) => {
        console.error(e);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const goToColumnPhase = () => {
    // Check no other edits were made, update sheet status to pending_column
    if (
      !userChangedRows &&
      !userIsBacktracking &&
      !sheets.some((sheet) => sheet.status === SHEETSTATUS.USER_EDITED_ROWS)
    ) {
      doFinishRows();
    } else if (userIsBacktracking && backtrackingChanges === 0) {
      // allow to advance if user is backtracking and made no changes. Update sheet status to ensure that data is valid.
      doFinishRows();
      history.push(`/data-ingestion/columns/${attachmentId}`);
      setActiveTab(0);
      setIsLastSheet(false);
      setUserIsBacktracking(false);
    }
  };

  // Handle all "next" button clicks
  const goToNextSheet = () => {
    setCompletedSheets([...completedSheets, activeTab]);
    // if not last sheet go to next tab
    if (activeTab < sheets.length - 1) {
      changeActiveTab(activeTab + 1);
    } else if (location.pathname.includes('columns')) {
      startMLAssessment();
    } else if (location.pathname.includes('rows')) {
      checkIsColumnPhaseAccessible();
      // If changes were made that require reprocessing
      if (
        sheets.some((sheet) => sheet.status === SHEETSTATUS.USER_EDITED_ROWS) ||
        userChangedRows
      ) {
        notifyMlOfRowChanges();
        showModal(MODALTYPES.PROCESSING);
      } else {
        goToColumnPhase();
      }
    }
    setIsLoading(false);
  };

  const goToPreviousSheet = () => {
    if (activeTab === 0) {
      const url = location.pathname.includes('columns')
        ? `/data-ingestion/rows/${attachmentId}`
        : `/data-request/${contextData.attachmentData.data_request}`;
      history.push(url);
      setIsChangingStages(true);
      _getSheets();
      checkDisabledSheets();
    } else {
      setSheetLoading(true);
      _getSheets();
      setSheetLoading(false);
      changeActiveTab(activeTab - 1);
    }
  };

  // ----------------------------------------------------------------------------------------- //

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSheets = useCallback(_getSheets, [attachmentId, location.pathname, goToNextSheet]);

  useEffect(() => {
    getSheets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentId, dataChanged, setActiveTab, location]);

  const doColumnValidate = (id) => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/${id}/columns/validate`,
      onSuccess: () => {
        verifySheet();
      },
    };
    ApiCalls.doCall(reqCfg);
    // TODO Set state for next button enabled
  };

  // save some of this logic for if we swap to the other error handling
  const checkColumnErrors = () => {
    const errSheets = sheets.filter((sheet) => sheet.error === 'e_sheet_missing_product_code');

    if (errSheets.length > 0) {
      // create modal for user confirming that they are okay with this sheet being disabled
      const body = (
        <div key="handleDisable">
          You are about to enable this column. This means that the data contained in this column
          will be processed. Continue?
        </div>
      );
      const footer = (
        <div>
          <button type="button" variant="outline-dark" onClick={() => setModalVisible(false)}>
            Cancel
          </button>
          <button
            type="button"
            className="ml-2"
            variant="warning"
            onClick={() => startMLAssessment()}
          >
            Confirm
          </button>
        </div>
      );
      setModalBody(body);
      setModalFooter(footer);
      setModalVisible(true);
    } else {
      startMLAssessment();
    }
  };

  // --------------------------------------------- UPDATE DATABASE --------------------------------------------- //

  // All sheet changes should use this update call:
  function doUpdateSheet(
    data,
    sheetId = activeSheetData.id,
    message = 'Sheet updated',
    onSuccessFunction = () => null,
    doToast = true
  ) {
    setSheetLoading(true);
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/${sheetId}`,
      data,
      onSuccess: () => {
        if (doToast) toast.success(message);
        onSuccessFunction();
        setIsLoading(false);
        setSheetLoading(false);
      },
    };
    ApiCalls.doCall(reqCfg);
  }

  function doUpdateSheetAndGoToNext(
    data,
    sheetId = activeSheetData.id,
    message = 'Sheet updated',
    onSuccessFunction = () => null,
    doToast = true
  ) {
    setSheetLoading(true);
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/${sheetId}`,
      data,
      onSuccess: () => {
        if (doToast) toast.success(message);
        setIsLoading(false);
        setSheetLoading(false);
        goToNextSheet();
        onSuccessFunction();
      },
    };
    ApiCalls.doCall(reqCfg);
  }

  function verifySheet() {
    if (
      [
        SHEETSTATUS.DISABLED_DURING_COLUMNS,
        SHEETSTATUS.DISABLED_DURING_ROWS,
        SHEETSTATUS.USER_EDITED_ROWS,
      ].includes(activeSheetData.status)
    ) {
      return;
    }

    let data = null;
    if (location.pathname.includes('rows')) {
      // if not backtracking...
      if (
        ![SHEETSTATUS.PENDING_COLUMN, SHEETSTATUS.USER_EDITED_COLUMNS].includes(
          activeSheetData.status
        )
      ) {
        data = { status: SHEETSTATUS.USER_VERIFIED_ROWS };
      }
    } else {
      data = { status: SHEETSTATUS.USER_VERIFIED_COLUMNS };
    }

    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/${activeSheetData.id}`,
      data,
    };
    ApiCalls.doCall(reqCfg);
  }

  // Finalaize row selection
  function doFinishRows() {
    setIsLoading(true);
    setIsChangingStages(true);
    setIsLastSheet(false);
    // Ignore disabled/failed sheets
    const finishedSheets = sheets.filter(
      (sheet) =>
        ![
          SHEETSTATUS.DISABLED_DURING_COLUMNS,
          SHEETSTATUS.DISABLED_DURING_ROWS,
          SHEETSTATUS.FAILED,
        ].includes(sheet.status)
    );

    // Update sheet statuses
    // TODO V2: change this to a single batch update endpoint
    for (let i = 0; i < finishedSheets.length; i++) {
      const reqCfg = {
        method: ApiCalls.HTTP_METHODS.PUT,
        urlPath: `/attachments/sheets/${finishedSheets[i].id}`,
        data: { status: SHEETSTATUS.PENDING_COLUMN },
        onSuccess: () => {
          if (i === finishedSheets.length - 1) {
            setActiveTab(null);
            setCompletedSheets([]);
            // setSheets(finishedSheets);
            setUserIsBacktracking(false);
            changeUrl('columns');
          }
        },
      };
      ApiCalls.doCall(reqCfg);
    }
  }

  const doDisableSheet = (value, isLastSheetToDisable) => {
    setSheetLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PATCH,
      urlPath: `/attachments/sheets/disable/${value}`,
      params: { final: isLastSheetToDisable, phase: path },
      onSuccess: () => {
        toast.success('Sheet Disabled');
        onDisableSuccess();
        setModalVisible(false);
        setSheetLoading(false);
      },
      onError: () => {
        setModalVisible(false);
        setIsLoading(false);
        setSheetLoading(false);
      },
    });
    if (isLastSheetToDisable) {
      // Add Comment
      const commentBody = `All sheets on ${attachmentData.name} have been excluded from automatic data onboarding. User is requesting custom intervention for this file`;
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/data-requests/${jobId}/comments/`,
        data: {
          content: commentBody,
          attachment_id: attachmentId,
        },
        onSuccess: () => {
          redirectToJobDetails();
        },
      });
    }
  };

  // ------------------------------------------ Update Rows ------------------------------------------ //
  // Type is action type, [header_idx, row_idx, or disable_sheet]. Value is user selected index.
  function updateRows(type, values, modalWasConfirmed = false, callbackFunc = () => null) {
    // TODO: fix the return statements by removing surpurfulous ones
    // Ignore if in tutorial
    if (!tutorial.rowTutorialDone) {
      return true;
    }
    const initialValue = initialData[activeTab][type];
    const dataIndex = type === 'data_idx' ? 1 : 0;
    // If User's selected value is same as ML's predicted db value...
    if (type !== 'disable_sheet' && initialValue === values[dataIndex] && !userChangedHeader) {
      if (!userIsBacktracking && type === 'data_idx') {
        const data = { status: SHEETSTATUS.USER_VERIFIED_ROWS };
        doUpdateSheetAndGoToNext(
          data,
          activeSheetData.id,
          'Sheet Verified',
          callbackFunc,
          type === 'data_idx'
        );
        return true;
      }
      if (userIsBacktracking) {
        // Don't do anything if they are backtracking and selected value is same as DB
        return true;
      }
      return true;
    }

    // User is backtracking and making first change: show modal prompt, no changes
    if (
      (sheets.some((sheet) => sheet.status === SHEETSTATUS.USER_EDITED_COLUMNS) &&
        !modalWasConfirmed) ||
      (userIsBacktracking && backtrackingChanges === 0 && !modalWasConfirmed)
    ) {
      showModal(MODALTYPES.RESTART_FROM_COLUMN, type, values[dataIndex]);
      return false;
    }

    // Else the change is different from db and is pure
    const data = {
      header_idx: values[0],
      data_idx: values[1],
      status: SHEETSTATUS.USER_EDITED_ROWS,
    };
    if (location.pathname.includes('rows') && type !== 'disable_sheet') {
      setUserChangedRows(true);
      if (type === 'header_idx') {
        setUserChangedHeader(true);
      }
    }
    if (type === 'disable_sheet') {
      const isLastSheetToDisable = sheets
        .filter((sheet) => sheet.id !== activeSheetId)
        .every((item) =>
          [SHEETSTATUS.DISABLED_DURING_ROWS, SHEETSTATUS.DISABLED_DURING_COLUMNS].includes(
            item.status
          )
        );
      doDisableSheet(values, isLastSheetToDisable);
    } else if (type === 'data_idx') {
      doUpdateSheetAndGoToNext(data, activeSheetData.id, null, null, true, 'go to next');
    }
    if (userIsBacktracking) {
      setBacktrackingChanges(backtrackingChanges + 1);
      setIsColumnPhaseAccessible(false);
      if (rowStep === 0) {
        setRowStep(1);
      } else if (rowStep === 1) {
        setRowStep(0);
      }
    }
    if (isLastSheet && rowStep === 1 && !isColumnPhaseAccessible) {
      // Required for edgecase to prevent auto redirect to columns.
      showModal(MODALTYPES.PROCESSING);
      return false;
    }
    return true;
  }

  // ----------------------------------------------------------------------------------------------------- //
  // ------------------------------------------ Update Columns ------------------------------------------ //

  const doDisableColumn = (value) => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/columns/${value}`,
      data: { status: COLUMNSTATUS.DISABLED },
      onSuccess: () => {
        // Now doUpdateSheet with status
        const sheetData = { disabled_column: value, status: SHEETSTATUS.USER_EDITED_COLUMNS };
        doUpdateSheet(sheetData, activeSheetData.id, 'Column disabled');
        setIsLoading(false);
        setModalVisible(false);
        setBacktrackingChanges(backtrackingChanges + 1);
        setColumnsChanged((e) => e + 1);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  const doEnableColumn = (value) => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/columns/${value}`,
      data: { status: COLUMNSTATUS.PENDING },
      onSuccess: () => {
        const sheetData = { column_enabled: value, status: SHEETSTATUS.USER_EDITED_COLUMNS };
        doUpdateSheet(sheetData, activeSheetData.id, 'Column enabled');
        setIsLoading(false);
        setModalVisible(false);
        setBacktrackingChanges(backtrackingChanges + 1);
        setColumnsChanged((e) => e + 1);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  // All Column disables/enables go through this function, as it checks for backtracking.
  // Column edits go through EditModal since it has more complex functionality.
  const updateColumns = (type, value, modalWasConfirmed = false) => {
    // types are of ['disable_column', 'enable_column', 'edit_column']
    // bypass in tutorial mode
    if (!tutorial.columnTutorialDone) {
      return;
    }
    const initialIndex = initialData[activeTab][type];
    // If User's selected value is same as ML's predicted db value
    if (initialIndex === value && !userIsBacktracking) {
      const data = { status: SHEETSTATUS.USER_VERIFIED_COLUMNS };
      !userIsBacktracking && doUpdateSheet(data);
      return true;
    }

    // Show modal if backtracking and no backtracking changes yet. Confirming the modal once will do backtrackingChanges+=1.
    if (userIsBacktracking && backtrackingChanges === 0 && !modalWasConfirmed) {
      showModal(MODALTYPES.RESTART_FROM_ASSESSMENT, type, value);
      return false;
    }
    // Else change differs from db and is not first change in backtracking
    if (type === 'disable_column') {
      doDisableColumn(value);
    }
    if (type === 'enable_column') {
      doEnableColumn(value);
    }
    // Update backtracking changes, disable 'Jump to assessment' button in header
    if (userIsBacktracking) {
      setBacktrackingChanges(backtrackingChanges + 1);
      setIsAssessmentAccessible(false);
    }
    return true;
  };

  const restoreSheet = () => {
    let data;
    let restoredSheet;
    let newSheets;
    if (location.pathname.includes('rows')) {
      data = { status: SHEETSTATUS.PENDING_ROW };
      restoredSheet = [activeSheetData];
      restoredSheet[0].status = 'pending_row';
      newSheets = sheets.map((obj) => restoredSheet.find((o) => o.id === obj.id) || obj);
    } else {
      data = { status: SHEETSTATUS.PENDING_COLUMN };
      restoredSheet = [activeSheetData];
      restoredSheet[0].status = 'pending_column';
      newSheets = sheets.map((obj) => restoredSheet.find((o) => o.id === obj.id) || obj);
    }

    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/attachments/sheets/${activeSheetData.id}`,
      data,
      onSuccess: () => {
        toast.success('Sheet Restored');
        setDataChanged((e) => e + 1);
        setActiveTab(activeTab);
        setSheets(newSheets);
      },
    };
    ApiCalls.doCall(reqCfg);
  };

  // --------------------------------------------------------------------------------------------- //

  // ------------------------------------- ERROR BANNERS-------------------------------------- //

  const handleErrorClick = (id) => {
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/attachments/sheets/${id}/error`,
      onSuccess: () => {
        setDataChanged((e) => e + 1);
      },
    });
    showModal(MODALTYPES.THANK_YOU);
  };

  // Generate error message based on error type
  const renderErrorMessage = () => {
    // Not enough rows:
    if (activeSheetData?.errors?.includes('e_sheet_not_enough_rows')) {
      return (
        <div>
          <p>It appears this sheet is empty...</p>
          <p>
            If you think this is an error
            <button
              type="button"
              className="btn-link"
              onClick={() => handleErrorClick(activeSheetData.id)}
            >
              click here
            </button>
            and we'll investigate further.
          </p>
          <p>Otherwise, please disable this sheet to continue.</p>
        </div>
      );
    }
    // else use generic
    return (
      <>
        <p>Something went wrong with analyzing this file...</p>
        <p>
          <button
            type="button"
            className="btn-link"
            onClick={() => handleErrorClick(activeSheetData.id)}
          >
            click here
          </button>
          and we'll investigate further,
        </p>
        <p>Or disable this sheet to continue</p>
      </>
    );
  };

  const errorBanner = (activeSheetData) => {
    if (activeSheetData !== null) {
      if (
        _get(activeSheetData, 'status') === SHEETSTATUS.DISABLED_DURING_ROWS ||
        _get(activeSheetData, 'status') === SHEETSTATUS.DISABLED_DURING_COLUMNS
      ) {
        const banner = (
          <Fragment key="sheet-error-banner">
            <div className="sheet-error-banner disabled-banner">
              <div className="error-icon disabled-icon fa-layers fa-fw">
                <FontAwesomeIcon icon={['fas', 'circle']} size="lg" />
                <FontAwesomeIcon
                  style={{ color: `${styleVars.colors_designWhite}` }}
                  icon={['fas', 'exclamation-triangle']}
                  size="sm"
                />
              </div>
              <div className="error-header">Sheet Disabled</div>
            </div>
            <div className="sheet-error-body">
              <div className="error-message">
                This sheet is currently being disabled.
                <button
                  type="button"
                  className="btn-link"
                  onClick={() => restoreSheet(activeSheetData)}
                >
                  Click here
                </button>
                to restore this sheet
              </div>
            </div>
          </Fragment>
        );
        return banner;
      }
      if (_get(activeSheetData, 'errors').length > 0 || activeSheetData.status === 'failed') {
        const banner = (
          // TODO: V2 make this a component
          <Fragment key="sheet-error-banner">
            <div className="sheet-error-banner">
              <div className="error-icon fa-layers fa-fw">
                <FontAwesomeIcon icon={['fas', 'circle']} size="lg" />
                <FontAwesomeIcon
                  style={{ color: `${styleVars.colors_designWhite}` }}
                  icon={['fas', 'exclamation-triangle']}
                  size="sm"
                />
              </div>
              <div className="error-header">
                An Error Has Occurred, your sheet cannot be processed
              </div>
            </div>
            <div className="sheet-error-body">
              <div className="error-message">{renderErrorMessage()}</div>
            </div>
          </Fragment>
        );
        return banner;
      }
      const banner = (
        // TODO: V2 make this a component
        <Fragment key="sheet-error-banner">
          <div className="sheet-error-banner">
            <div className="error-icon fa-layers fa-fw">
              <FontAwesomeIcon icon={['fas', 'circle']} size="lg" />
              <FontAwesomeIcon
                style={{ color: `${styleVars.colors_designWhite}` }}
                icon={['fas', 'exclamation-triangle']}
                size="sm"
              />
            </div>
            <div className="error-header">
              An Error Has Occurred, your sheet cannot be processed
            </div>
          </div>
          <div className="sheet-error-body">
            <div className="error-message">
              There is a data error, please
              <button
                type="button"
                className="btn-link"
                onClick={() => handleErrorClick(activeSheetData.id)}
              >
                click here
              </button>
              and we'll investigate further.
            </div>
          </div>
        </Fragment>
      );
      return banner;
    }
    return null;
  };
  // ----------------------------------------------------------------------------------------- //
  const onDisableSuccess = () => {
    getSheets();
  };

  const restartTutorial = useCallback(() => {
    tutorial.setStep(TUTORIALSTEPS.HEADER);
    setActiveTab(0);
    setIsLastSheet(false);
    setCompletedSheets([]);
  }, [tutorial]);

  const doStartOnboarding = useCallback(() => {
    tutorial.setStep(TUTORIALSTEPS.ROWS_DONE);
    setCompletedSheets([]);
    setActiveTab(0);
    setIsLastSheet(false);
  }, [tutorial]);

  const renderTable = () => {
    if (sheetLoading === true || isLoading === true || activeSheetId === null) {
      return (
        <div className="loading-placeholder">
          <LoadingSpinner style={{ fontSize: '5em', marginLeft: '5em' }} />
        </div>
      );
    }

    if (
      (!isLoading &&
        !isChangingStages &&
        activeSheetData &&
        disabledSheets?.includes(activeSheetData)) ||
      activeSheetData.status === SHEETSTATUS.FAILED ||
      activeSheetData.errors.length > 0
    ) {
      return <>{errorBanner(activeSheetData)}</>;
    }

    if (
      location.pathname.includes('rows') &&
      _get(activeSheetData, 'smart_sample.smart_head') !== undefined
    ) {
      return (
        <SheetTabRows
          activeTab={activeTab}
          activeSheetData={activeSheetData}
          goToNextSheet={() => goToNextSheet()}
          updateRows={(type, value) => updateRows(type, value)}
          isLastSheet={isLastSheet}
          restartTutorial={() => restartTutorial()}
          doStartOnboarding={() => doStartOnboarding()}
          completedSheets={completedSheets}
          userChangedRows={userChangedRows}
          userIsBacktracking={userIsBacktracking}
        />
      );
    }

    if (_get(activeSheetData, 'smart_sample.smart_col') !== undefined) {
      return (
        <SheetTabColumns
          activeTab={activeTab}
          activeSheetData={activeSheetData}
          goToNextSheet={() => goToNextSheet(activeSheetData.id)}
          updateColumns={(type, value, modalWasConfirmed, message) =>
            updateColumns(type, value, modalWasConfirmed, message)
          }
          onSheetComplete={() => {
            doColumnValidate(activeSheetData.id);
            if (!completedSheets.includes(activeTab)) {
              setCompletedSheets([...completedSheets, activeTab]);
              setColumnsComplete(true);
            } else if (sheets.length > 0 && isLastSheet) {
              setColumnsComplete(true);
              // also include last sheet changes
            }
          }}
          showModal={(modal, type, value, direction, columnData) =>
            showModal(modal, type, value, direction, columnData)
          }
          // make sheettab columns call tryDisableColumn which will live in this component
          isLastSheet={isLastSheet}
          sheets={sheets}
          doUpdateSheet={(data, activeSheetId, detail) => {
            doUpdateSheet(data, activeSheetId, detail);
            setColumnsChanged((e) => e + 1);
          }}
          columnsChanged={columnsChanged}
          setColumnsChanged={() => setColumnsChanged()}
          scrollPosition={scrollPosition}
          setScrollPosition={(e) => setScrollPosition(e)}
          hideOtherColumns={hideOtherColumns}
          setHideOtherColumns={(e) => setHideOtherColumns(e)}
          hideNonErrorColumns={hideNonErrorColumns}
          setHideNonErrorColumns={(e) => setHideNonErrorColumns(e)}
        />
      );
    }

    return <>{errorBanner(activeSheetData)}</>;
  };

  return (
    <Fragment key="sheets'">
      {sheets !== null && isChangingStages === false ? (
        <>
          {modalVisible === true ? (
            <div id="modal-container">
              <ModalPanel
                className="modal-on-top"
                isVisible={modalVisible}
                setIsVisible={setModalVisible}
                showCancel
                body={modalBody}
                footer={modalFooter}
              />
            </div>
          ) : null}
          <div className="sheet-tab-container">
            <Tabs
              path={path}
              sheets={sheets}
              activeTab={activeTab}
              completedSheets={completedSheets}
              changeActiveTab={(index) => changeActiveTab(index)}
              handleDisable={(value) => showModal(MODALTYPES.DISABLE_SHEET, null, value)}
            />
          </div>
          {renderTable()}
          <DataIngestionFooter
            activeTab={activeTab}
            completedSheets={completedSheets}
            goToNextSheet={() => goToNextSheet()}
            goToPreviousSheet={() => goToPreviousSheet()}
            numberOfSheets={sheets.length}
            path={path}
            sheets={sheets}
            changeActiveTab={(index) => changeActiveTab(index)}
          />
        </>
      ) : (
        <div className="loading-placeholder">
          <LoadingSpinner style={{ fontSize: '5em', marginLeft: '5em' }} />
        </div>
      )}
    </Fragment>
  );
};

SheetSelect.propTypes = {
  attachmentId: PropTypes.string.isRequired,
};

export { SheetSelect };
