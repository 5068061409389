import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import './SectionImgBotEngineHelp.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import { Accordion, Card, Button, useAccordionToggle, AccordionContext } from 'react-bootstrap';

import { useIsMounted } from '../../../../helpers/useIsMounted';
import { Search } from '../../../common/Search/Search';
import { YouTubeVideo } from '../../../common/YouTubeVideo/YouTubeVideo';
import { IdsModels } from '../../../img-bot-engine/_private/IdsModels';

const extractTextContent = (jsxElement) => {
  if (!jsxElement) {
    return '';
  }

  if (typeof jsxElement === 'string') {
    return jsxElement;
  }
  if (Array.isArray(jsxElement)) {
    return jsxElement.map((child) => extractTextContent(child)).join(' ');
  }

  return extractTextContent(jsxElement.props.children);
};

const ContextAwareToggle = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button
      variant="link"
      className={`accordion-toggle${isCurrentEventKey ? ' active' : ''}`}
      onClick={decoratedOnClick}
    >
      <FontAwesomeIcon icon={['fas', isCurrentEventKey ? 'chevron-up' : 'chevron-down']} />
    </Button>
  );
};

const SectionImgBotEngineHelp = () => {
  const isMounted = useIsMounted();
  const [searchTimeoutState, setSearchTimeoutState] = useState(null);
  // Extract text content from JSX elements or handle plain text recursively

  const processedFaqList = useMemo(
    () =>
      IdsModels.FAQ_ITEMS.map((item) => {
        const { question, answer } = item;
        const answerText = extractTextContent(answer);
        const questionText = extractTextContent(question);

        return {
          question,
          answer,
          answerText,
          questionText,
        };
      }),
    [IdsModels.FAQ_ITEMS]
  );

  const [localSearch, setLocalSearch] = useState('');
  const [search, setSearch] = useState('');
  const [faqData, setFaqData] = useState(processedFaqList);

  const fuse = useMemo(
    () =>
      new Fuse(processedFaqList, {
        keys: ['questionText', 'answerText'],
        isCaseSensitive: false,
        includeScore: true,
        threshold: 0.1,
        findAllMatches: true,
        ignoreLocation: true,
        includeMatches: true,
        shouldSort: true,
      }),
    [processedFaqList]
  );

  const doFilterFaq = useCallback(
    (searchQuery) => {
      if (!isMounted.current) {
        return;
      }
      if (!searchQuery || !searchQuery.length) {
        setFaqData(processedFaqList);
        return;
      }
      const filterRes = fuse.search(searchQuery);
      setFaqData(filterRes.map((item) => item.item));
    },
    [fuse, processedFaqList, isMounted]
  );

  useEffect(() => {
    if (searchTimeoutState) clearTimeout(searchTimeoutState);
    setTimeout(() => {
      setSearchTimeoutState(doFilterFaq(localSearch));
    }, 400);
  }, [doFilterFaq, localSearch]);

  return (
    <>
      <div className="title">
        <h3>BackboneAI Help Center</h3>
      </div>
      <section className="img-bot-engine-about-section what-is">
        <div className="title">What Is BackboneAI?</div>
        <div className="content">
          <YouTubeVideo videoId="ws8REkAODmk" />
        </div>
      </section>
      <section className="img-bot-engine-about-section hot-questions">
        <div className="title">Hot Questions</div>
        <div className="content">
          <div className="video-wrapper">
            <div className="title">Start in 60 seconds</div>
            <div className="video">
              <YouTubeVideo videoId="ws8REkAODmk" />
            </div>
          </div>
          <div className="video-wrapper">
            <div className="title">Configuring IDS</div>
            <div className="video">
              <YouTubeVideo videoId="ws8REkAODmk" />
            </div>
          </div>
          <div className="video-wrapper">
            <div className="title">Refining Results</div>
            <div className="video">
              <YouTubeVideo videoId="ws8REkAODmk" />
            </div>
          </div>
          <div className="video-wrapper">
            <div className="title">ChatGPT - Powered</div>
            <div className="video">
              <YouTubeVideo videoId="ws8REkAODmk" />
            </div>
          </div>
        </div>
      </section>
      <section className="img-bot-engine-about-section faq">
        <div className="title">FAQ</div>
        <div className="content">
          <div className="question">
            <p>
              Have questions? Check out our frequently asked questions below. Can&apos;t find what
              you&apos;re looking for?{' '}
              <a
                className="btn btn-primary btn-request-data"
                href="mailto:data@backbone.ai?subject=Help%20Center%20Question&body=Hi%20BackboneAI%20Team%2C%0A%0A"
              >
                Contact Us!
              </a>
            </p>
          </div>
          <div className="faq-wrapper">
            <div className="search-wrapper">
              <Search
                setLocalSearch={setLocalSearch}
                search={search}
                setSearch={setSearch}
                placeholder="Search FAQs"
                withButton={false}
                withClear
                onClear={() => {
                  setSearch('');
                  setLocalSearch('');
                  doFilterFaq(null);
                }}
                onSearchSubmit={() => doFilterFaq(localSearch)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    doFilterFaq(localSearch);
                  }
                }}
              />
            </div>
            <div className="faq-items">
              <Accordion>
                {faqData.map((item, index) => {
                  return (
                    <Card key={index} className="faq-item">
                      <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                        <div className="faq-title">{item.question}</div>
                        <ContextAwareToggle eventKey={index.toString()} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>{item.answer}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { SectionImgBotEngineHelp };
