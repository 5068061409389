import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import './TipsCarousel.scss';

const TipsCarousel = ({ data, onClickClose }) => {
  const [tipsCarouselIndex, setTipsCarouselIndex] = useState(0);

  const renderItems = () => {
    let output = null;

    if (data && data.length) {
      output = data.map((item) => (
        <Carousel.Item key={item}>
          <div className="bc-carousel-item-wrap">
            <div
              className="bc-carousel-item-close"
              onClick={() => typeof onClickClose === 'function' && onClickClose()}
            >
              <FontAwesomeIcon icon={['fas', 'times']} />
            </div>
            <div className="bc-carousel-item">
              <div className="bc-carousel-item-icon">
                <FontAwesomeIcon icon={['fas', 'lightbulb']} />
              </div>
              <div className="bc-carousel-item-content">
                <span className="label">Tip:</span>
                <span className="value">{item}</span>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ));
    }

    return output;
  };

  return (
    <div className="tips-carousel">
      <Carousel
        activeIndex={tipsCarouselIndex}
        onSelect={(i) => setTipsCarouselIndex(i)}
        controls={false}
        fade
      >
        {renderItems()}
      </Carousel>
    </div>
  );
};

TipsCarousel.propTypes = {
  data: PropTypes.array.isRequired,
};

export { TipsCarousel };
