import { fork } from 'redux-saga/effects';

import DataRequestSagas from './DataRequestSagas';
import ProductSagas from './ProductSagas';
import StatisticsSagas from './StatisticsSagas';
import UserSagas from './UserSagas';

/**
 * Initialize/run all sagas
 */
export default function* () {
  yield fork(ProductSagas);
  yield fork(UserSagas);
  yield fork(StatisticsSagas);
  yield fork(DataRequestSagas);
}
