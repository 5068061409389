import React, { useEffect, useRef, useState } from 'react';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { useIsMounted } from 'helpers/useIsMounted';
import { useOutsideClick } from 'helpers/useOutsideClick';

import { Search } from './common/Search/Search';
import { SearchSuggestions } from './common/SearchSuggestions/SearchSuggestions';

const ProductsSearch = ({ onChangeText, value, onSearchSubmit, applyFilter }) => {
  const [search, setSearch] = useState('');
  const [localSearch, setLocalSearch] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
  const [areSuggestionsLoading, setAreSuggestionsLoading] = useState(false);
  const searchWrapperRef = useRef(null);
  const isMounted = useIsMounted();
  const [timeoutStatus, setTimeoutStatus] = useState(0);
  const history = useHistory();

  const shouldClear = useRef(false);

  const handleSearchChange = (value) => {
    if (timeoutStatus) clearTimeout(timeoutStatus);
    setTimeoutStatus(setTimeout(() => onChangeText(value), 500));
  };

  const handleSuggestionSelected = async (value) => {
    const id = searchSuggestions.find((suggestion) => suggestion.value === value)?.id;
    if (id) history.push(`/product/${id}`);
    else {
      await onChangeText(value);
      applyFilter();
    }
  };

  useEffect(() => {
    setSearch(value);
    setLocalSearch(value);
  }, [value]);

  useEffect(() => {
    const query = {};
    if (search) query.search_param = search;
    else {
      setSearchSuggestions([]);
      setAreSuggestionsLoading(false);
      return;
    }
    const url = `/pdp-search?${queryString.stringify(query)}`;

    setAreSuggestionsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: url,
      onSuccess: (res) => {
        const { products } = res.data;
        const suggestions = products.map((product) => {
          return { value: product.name, id: product.id };
        });

        if (isMounted.current) setSearchSuggestions(suggestions);
      },
      onEnd: () => {
        setAreSuggestionsLoading(false);
      },
    });
  }, [search, isMounted]);

  useOutsideClick(searchWrapperRef, () => {
    if (areSuggestionsVisible) setAreSuggestionsVisible(false);
  });

  useEffect(() => {
    if (isMounted.current && value === '' && !!shouldClear.current) {
      shouldClear.current = false;
      onSearchSubmit();
    }
  }, [value, shouldClear, isMounted, onSearchSubmit]);

  const onClearSearch = async () => {
    shouldClear.current = true;
    setAreSuggestionsVisible(false);
    onChangeText('');
  };

  return (
    <div ref={searchWrapperRef} className="products-search">
      <Search
        onFocus={() => setAreSuggestionsVisible(true)}
        search={search}
        setLocalSearch={handleSearchChange}
        setSearch={setSearch}
        onSearchSubmit={() => {
          onSearchSubmit();
          setAreSuggestionsVisible(false);
        }}
        placeholder="Search by keyword (UPC, Name, Code)"
        onKeyDown={(e) => {
          if (e && e.key === 'Enter') {
            onSearchSubmit();
            setAreSuggestionsVisible(false);
          }
        }}
        onClear={() => onClearSearch()}
      />
      <SearchSuggestions
        search={search}
        setLocalSearch={handleSearchChange}
        setSearch={setSearch}
        searchSuggestions={searchSuggestions}
        localSearch={localSearch}
        setAreSuggestionsVisible={setAreSuggestionsVisible}
        areSuggestionsVisible={areSuggestionsVisible}
        areSuggestionsLoading={areSuggestionsLoading}
        onSuggestionSelected={handleSuggestionSelected}
      />
    </div>
  );
};

export { ProductsSearch };
