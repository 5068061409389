import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import Moment from 'react-moment';

import * as ApiCalls from 'api/ApiCalls';
import ImgWithPlaceholder from 'components/ImgWithPlaceholder';
import { MIN_DATE } from 'constants/DateTimeConstants';
import { PRODUCT_ASSET_TYPES } from 'constants/ProductConstants';
import { getAuthToken } from 'helpers/AuthTokenUtils';
import * as Utils from 'helpers/Utils';

const renderIdentifiers = (row) => {
  const identifiersArray = [];

  if (row.code) {
    identifiersArray.push(
      <div key="code" className="kv-pair">
        <span>Manufacturer ID Number (SKU):</span> <span>{row.code}</span>
      </div>
    );
  }

  if (row.upc) {
    identifiersArray.push(
      <div key="upc" className="kv-pair">
        <span>UPC:</span> <span>{row.upc}</span>
      </div>
    );
  }

  if (row.mino) {
    identifiersArray.push(
      <div key="ext-code" className="kv-pair">
        <span>Ext. Product Code:</span> <span>{`${row.mino}`.toUpperCase()}</span>
      </div>
    );
  }

  return <>{identifiersArray}</>;
};

export default {
  columns: [
    {
      ...Utils.getDefaultTableColumnDef('name', 'Product'),
      formatter: (cell, row, rowIndex) => {
        const primaryImage = row.assets.find(
          (asset) => asset.type === PRODUCT_ASSET_TYPES.PRIMARY_IMAGE
        );
        return (
          <div className="product-block">
            <div className="product-image">
              <ImgWithPlaceholder
                src={
                  row && row.id
                    ? `${ApiCalls.BASE_API_URL}/product/${
                        row.id
                      }/assets/?type=primary_image&token=${getAuthToken()}&name=${
                        primaryImage?.name
                      }`
                    : null
                }
                placeholder={<FontAwesomeIcon icon={['far', 'box-full']} />}
              />
            </div>
            <div className="product-info">
              <div className="product-name">
                <a
                  onClick={(e) => {
                    // http://www.quirksmode.org/js/events_access.html
                    const evt = e || window.event;

                    // https://developer.mozilla.org/en-US/docs/DOM/MouseEvent
                    if (evt.ctrlKey || evt.metaKey) {
                      evt.stopPropagation();
                      return true;
                    }
                    evt.preventDefault();
                    return false;
                  }}
                  href={`/product/${row.id}`}
                >
                  {row.name ||
                    [row?.code, row?.description, row?.description_new?.name]
                      .filter((a) => !!a)
                      .join(' ')}
                </a>
              </div>
              <div className="product-identifiers">{renderIdentifiers(row)}</div>
            </div>
          </div>
        );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('manufacturer', 'Manufacturer'),
      formatter: (cell, row, rowIndex) => {
        return _get(row, 'manufacturer.name') || 'N/A';
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('un', 'UN Code'),
      formatter: (cell, row, rowIndex) => {
        return (
          _get(
            row,
            'regulatory_data.value.sds_data.value.transport_information.value.dot.value.un_number.value'
          ) || 'N/A'
        );
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('status', 'Status'),
      formatter: (cell, row, rowIndex) => {
        let status = `Unknown(${row.status >= 0 ? row.status : 'N/A'})`;

        if (row.status >= 0) {
          // prettier-ignore
          switch (row.status) {
            case 2: status = 'Processed'; break;
            case 5: status = 'Under Review'; break;
            default: console.error("Unknown status", row);
          }
        }

        return status;
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('updated_at', 'Updated'),
      formatter: (cell, row, rowIndex) => {
        if (row.updated_at) {
          return (
            <>
              <Moment format={MIN_DATE} date={row.updated_at} />
            </>
          );
        }

        return null;
      },
    },
    {
      ...Utils.getDefaultTableColumnDef('actions', null),
      sort: false,
      formatter: (cell, row, rowIndex) => {
        if (row.id) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`/product/${row.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={['far', 'external-link']} />
            </a>
          );
        }
        return null;
      },
    },
  ],
};
