import React from 'react';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';

const options = [
  { value: null, label: 'All' },
  { value: 7, label: '7 Days' },
  { value: 14, label: '14 Days' },
  { value: 30, label: '30 Days' },
];

const DataJobsFilterPeriodSelect = ({ value, onChange, error }) => {
  const selected =
    options.find((item) => {
      return item.value === value;
    }) || [];

  return (
    <StyledMultiselect
      isSearchable={false}
      isClearable={false}
      options={options}
      values={selected}
      getOptionValue={(option) => option.value}
      isInvalid={error}
      label="Last Updated:"
      isMulti={false}
      setOnChange={onChange}
      selectWrapperStyle={{ width: '200px' }}
      selectStyle={{
        control: () => ({
          height: '33.5px',
          minHeight: '33.5px',
        }),
      }}
    />
  );
};

export { DataJobsFilterPeriodSelect };
