import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import * as ApiCalls from 'api/ApiCalls';
import { DataIngestionDataQuality } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQuality';
import { DataIngestionDataQualityContext } from 'components/data-ingestion/DataIngestionDataQuality/DataIngestionDataQualityContext';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { DEFAULT_NUM_ROWS, SHEETSTATUS } from 'constants/DataIngestionConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

const ViewDataIngestionDataQuality = ({ attachmentId }) => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const location = useLocation();

  const [attachmentData, setAttachmentData] = useState(null);
  const [attachmentSheetsData, setAttachmentSheetsData] = useState(null);
  const [dataJobData, setDataJobData] = useState(null);
  const [attachmentDataStatus, setAttachmentDataStatus] = useState(ActionStatusConstants.INITIAL);
  const [attachmentSheetsDataStatus, setAttachmentSheetsDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );
  const [dataJobDataStatus, setDataJobDataStatus] = useState(ActionStatusConstants.INITIAL);

  const [activeSheet, setActiveSheet] = useState(null);
  const [sheetHasNoErrors, setSheetHasNoErrors] = useState(false);
  const [showGoodRows, setShowGoodRows] = useState(false);
  const [allRowsHaveErrors, setAllRowsHaveErrors] = useState(false);
  const [numRowsInSheet, setNumRowsInSheet] = useState(null);
  const [maxNumRowsToShow, setMaxNumRowsToShow] = useState(DEFAULT_NUM_ROWS);
  const [inconsistentRows, setInconsistentRows] = useState([]);
  const [sheetsMessage, setSheetsMessage] = useState(null);

  const doLoadDataJob = useCallback(
    (jobId) => {
      if (!jobId) {
        console.error('Invalid job id', jobId);
        return;
      }
      setDataJobDataStatus(ActionStatusConstants.ISBUSY);
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/data-requests/${jobId}`,
        onSuccess: (res) => {
          if (isMounted.current) {
            if (res && res.data) {
              setDataJobDataStatus(ActionStatusConstants.SUCCESS);
              setDataJobData(res.data);
            } else {
              setDataJobDataStatus(ActionStatusConstants.FAILURE);
              toast.error(`Could not parse data job`);
              console.error(res);
            }
          }
        },
        onError: () => {
          setDataJobDataStatus(ActionStatusConstants.FAILURE);
        },
      });
    },
    [isMounted]
  );

  const doLoadAttachmentSheets = useCallback(() => {
    const statuses = [
      SHEETSTATUS.INTEGRITY_CHECKED,
      SHEETSTATUS.USER_EDITED_COLUMNS,
      SHEETSTATUS.USER_VERIFIED_COLUMNS,
    ];

    setAttachmentSheetsDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/attachments/${attachmentId}/sheets`,
      onSuccess: (res) => {
        if (isMounted.current) {
          if (res && res.data && Array.isArray(res.data)) {
            const sheetsArray = res.data.filter((sheet) => {
              return statuses.includes(sheet.status);
            });
            if (sheetsArray.length === 0) {
              if (res.data.filter((sheet) => sheet.status === SHEETSTATUS.FAILED).length > 0) {
                setSheetsMessage('All sheets failed.');
              } else {
                setSheetsMessage('No sheets returned.');
              }
            }
            setAttachmentSheetsDataStatus(ActionStatusConstants.SUCCESS);
            setAttachmentSheetsData(sheetsArray);
          } else {
            setAttachmentSheetsDataStatus(ActionStatusConstants.FAILURE);
            toast.error(`Could not parse attachment sheets data`);
            console.error(res);
          }
        }
      },
      onError: () => {
        setAttachmentSheetsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }, [isMounted, attachmentId]);

  const doLoadAttachment = useCallback(() => {
    setAttachmentDataStatus(ActionStatusConstants.ISBUSY);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/attachments/${attachmentId}`,
      onSuccess: (res) => {
        if (isMounted.current) {
          if (res && res.data) {
            setAttachmentDataStatus(ActionStatusConstants.SUCCESS);
            setAttachmentData(res.data);
            const jobId = _get(res, 'data.data_request');
            doLoadDataJob(jobId);
          } else {
            setAttachmentDataStatus(ActionStatusConstants.FAILURE);
            toast.error(`Could not parse attachment data`);
            console.error(res);
          }
        }
      },
      onError: () => {
        setAttachmentSheetsDataStatus(ActionStatusConstants.FAILURE);
      },
    });
  }, [isMounted, doLoadDataJob, attachmentId]);

  const loadData = useCallback(() => {
    doLoadAttachment(attachmentId);
    doLoadAttachmentSheets();
  }, [doLoadAttachment, doLoadAttachmentSheets, attachmentId]);

  const downloadFile = useCallback(() => {
    if (attachmentId) {
      const downloadUrl = `data-ingestion/attachments/${attachmentId}/download-processed`;
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      a.click();
    }
  }, [attachmentId]);

  const goToAssessment = useCallback(() => {
    history.push(`/data-ingestion/assessment/${attachmentId}`);
  }, [attachmentId, history]);

  const onStartIngestionSuccess = () => {
    if (location.pathname.includes('data-quality')) {
      goToAssessment();
    }
    toast.success("Ingestion Started - we'll notify you as soon as it's finished.");
  };

  const startIngestion = () => {
    console.debug('Starting data ingestion...');
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/data-ingestion/process-data`,
      data: {
        manufacturer_id: dataJobData.manufacturer.id,
        file_name: attachmentData.name,
        data_request_id: dataJobData.id,
      },
      onSuccess: () => {
        onStartIngestionSuccess();
      },
      onError: (res) => {
        toast.error('Something went wrong with ingestion.');
        console.error(res);
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!activeSheet && attachmentSheetsData && attachmentSheetsData[0]) {
      setActiveSheet(attachmentSheetsData[0]);
    }
  }, [attachmentSheetsData, activeSheet]);

  const getLoadingStatus = () => {
    let output = ActionStatusConstants.INITIAL;

    if (
      attachmentDataStatus === ActionStatusConstants.ISBUSY ||
      attachmentSheetsDataStatus === ActionStatusConstants.ISBUSY ||
      dataJobDataStatus === ActionStatusConstants.ISBUSY
    ) {
      output = ActionStatusConstants.ISBUSY;
    } else if (
      attachmentDataStatus === ActionStatusConstants.FAILURE ||
      attachmentSheetsDataStatus === ActionStatusConstants.FAILURE ||
      dataJobDataStatus === ActionStatusConstants.FAILURE
    ) {
      output = ActionStatusConstants.FAILURE;
    } else if (
      attachmentDataStatus === ActionStatusConstants.SUCCESS &&
      attachmentSheetsDataStatus === ActionStatusConstants.SUCCESS &&
      dataJobDataStatus === ActionStatusConstants.SUCCESS
    ) {
      output = ActionStatusConstants.SUCCESS;
    }

    return output;
  };

  const loadingStatus = getLoadingStatus();

  return (
    <DataIngestionDataQualityContext.Provider
      value={{
        attachmentData,
        attachmentSheetsData,
        dataJobData,
        loadingStatus,
        activeSheet,
        setActiveSheet: (sheetId) => setActiveSheet(sheetId),
        reloadAttachmentSheetsData: () => doLoadAttachmentSheets(),
        downloadFile: () => downloadFile(),
        startIngestion: () => startIngestion(),
        sheetHasNoErrors,
        setSheetHasNoErrors: (v) => setSheetHasNoErrors(v),
        showGoodRows,
        setShowGoodRows: (v) => setShowGoodRows(v),
        allRowsHaveErrors,
        setAllRowsHaveErrors: (v) => setAllRowsHaveErrors(v),
        maxNumRowsToShow,
        setMaxNumRowsToShow: (v) => setMaxNumRowsToShow(v),
        numRowsInSheet,
        setNumRowsInSheet: (int) => setNumRowsInSheet(int),
        inconsistentRows,
        setInconsistentRows: (int) => setInconsistentRows(int),
      }}
    >
      <div className="content content-fluid view-data-ingestion-data-quality">
        <Helmet bodyAttributes={{ 'data-page': 'data-ingestion-data-quality' }}>
          <title>Data Quality</title>
        </Helmet>
        <button type="button" className="btn btn-secondary" onClick={() => goToAssessment()}>
          <FontAwesomeIcon icon={['far', 'long-arrow-left']} /> Back to Assessment
        </button>
        <DataIngestionDataQuality sheetsMessage={sheetsMessage} />
      </div>
    </DataIngestionDataQualityContext.Provider>
  );
};

export { ViewDataIngestionDataQuality };
