import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { TooltipIcon } from 'components/common/TooltipIcon/TooltipIcon';
import { IMAGE_QUALITY_ASSESSMENT_TYPES } from 'constants/BotConstants';
import UserRoleConstants from 'constants/UserRoleConstants';
import { isConstantEqual } from 'helpers/Utils';

import { ImageQualityAssessmentConfiguration } from './ImageQualityAssessmentConfiguration';
import './ImageQualityAssessmentHeader.scss';

/**
 * Render Image Quality Assessment Header
 *
 * @param {string} name name of accessing org
 * @param {string} userType manufacturer or supplier
 * @param {number} count number of associated orgs to be reported
 * @param {object} configurationData configuration data object for display
 * @return render
 */
const ImageQualityAssessmentHeader = ({
  id,
  name,
  assessmentType,
  userType,
  count,
  configurationData,
  isLoadingHeader,
  childMfrs,
  childMfr,
  childMfrSelFunc,
  showChildMfrs,
}) => {
  const history = useHistory();

  const onSelectChildMfr = (e) => {
    if (e?.value) {
      const mfrObj = childMfrs?.find((item) => item.id === e.value);
      childMfrSelFunc(mfrObj ?? null);
    } else {
      childMfrSelFunc(null);
    }
  };

  const renderViewOtherAssessments = () => {
    const otherType =
      assessmentType === IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY ? 'Secondary' : 'Primary';

    let assessmentUrl = `/image-quality/assessment/${userType}/${id}`;
    if (assessmentType === IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY)
      assessmentUrl += `?${new URLSearchParams({ type: otherType.toLowerCase() })}`;

    return (
      <button
        type="button"
        className="btn btn-primary btn-view-other-assessments"
        onClick={() => history.push(assessmentUrl)}
      >
        View {otherType} Image Quality Assessments
      </button>
    );
  };

  return (
    <div className="image-quality-assessment-top">
      <div className="image-quality-assessment-top-cards">
        <div className="image-quality-company">
          <div className="image-quality-header">
            <span className="title">Company</span>
          </div>
          <div className="value">
            <div className="name">{isLoadingHeader ? <LoadingSpinner /> : name}</div>

            {showChildMfrs && childMfrs?.length && (
              <div className="child-select">
                <StyledMultiselect
                  values={childMfr ? [{ value: childMfr.id, label: childMfr.name }] : []}
                  options={
                    childMfrs?.length
                      ? childMfrs.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        })
                      : null
                  }
                  optionsSort
                  setOnChange={onSelectChildMfr}
                  placeholder="Select Child Company..."
                  isMulti={false}
                  isClearable
                />
              </div>
            )}
          </div>
        </div>
        <div className="image-quality-partner">
          <div className="image-quality-header">
            <span className="title">
              Total{' '}
              {userType === UserRoleConstants.MANUFACTURER.toLowerCase()
                ? 'Products'
                : 'Manufacturers'}
            </span>
            <TooltipIcon tooltip="The number of manufacturers you receive data from. " />
          </div>
          <div className="value">
            {!isLoadingHeader ? count?.toLocaleString() : <LoadingSpinner />}
          </div>
        </div>
      </div>
      <div className="title">
        <div className="label">
          {assessmentType === IMAGE_QUALITY_ASSESSMENT_TYPES.PRIMARY ? 'Primary' : 'Secondary'}{' '}
          Image Quality Assessments
        </div>
        <div className="actions">
          {renderViewOtherAssessments()}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => history.push(`/image-quality/assessment/${userType}/${id}/faq`)}
          >
            Why was an image flagged incorrectly?
            <FontAwesomeIcon style={{ marginLeft: '0.5em' }} icon={['far', 'long-arrow-right']} />
          </button>
        </div>
      </div>
      {/* TODO update link target once we have a page to change configuration data */}
      <span className="sub-title">
        {isConstantEqual(userType, UserRoleConstants.DISTRIBUTOR) ? (
          <>
            BackboneAI&apos;s Image Quality Assessments score your manufacturer&apos;s primary
            images based on standards your team provided.{' '}
            <a href="mailto:data@backbone.ai">Click here</a> to update your configuration.
          </>
        ) : (
          <>
            BackboneAI&apos;s Image Quality Assessments score your primary images based on standards
            that have been provided.
          </>
        )}
      </span>
      {isConstantEqual(userType, UserRoleConstants.DISTRIBUTOR) ? (
        <div className="image-quality-configuration">
          <div className="image-quality-header">
            <span className="title">Company Configuration</span>
            <TooltipIcon tooltip="The provided image requirements for this company." />
          </div>
          <div className="configuration-data-wrapper">
            {!isLoadingHeader ? (
              <ImageQualityAssessmentConfiguration configurationData={configurationData} />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

ImageQualityAssessmentHeader.defaultProps = {
  name: null,
  count: null,
};

ImageQualityAssessmentHeader.propTypes = {
  name: PropTypes.string,
  userType: PropTypes.oneOf([
    UserRoleConstants.MANUFACTURER.toLowerCase(),
    UserRoleConstants.DISTRIBUTOR.toLowerCase(),
  ]).isRequired,
  count: PropTypes.number,
  configurationData: PropTypes.object.isRequired,
};

export { ImageQualityAssessmentHeader };
