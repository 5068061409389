import './ReportsTable.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { ReportsTableDef } from 'helpers/table-defs/ViewReportsTableDef';

const ReportsTable = ({ reports, baseProps = {}, usePagination = true, className }) => {
  const defaultPage = 1;
  const defaultPageSize = 10;
  const [showPagination, setShowPagination] = useState(false);
  const [tableRef, setTableRef] = useState({
    paginationContext: {
      currPage: defaultPage,
      currSizePerPage: defaultPageSize,
    },
  });

  useEffect(() => {
    if (!showPagination) return;
    tableRef.paginationContext.currPage = defaultPage;
    tableRef.paginationContext.currSizePerPage = defaultPageSize;
  }, [tableRef, showPagination]);

  useEffect(() => {
    setShowPagination(usePagination);
    if (reports?.length === 0) setShowPagination(false);
  }, [reports, usePagination]);

  return (
    <div className={classNames('reports-table', className)}>
      <BootstrapTable
        {...baseProps}
        pagination={
          showPagination
            ? paginationFactory({
                showTotal: true,
              })
            : null
        }
        bordered={false}
        ref={(n) => setTableRef(n)}
        bootstrap4
        defaultSorted={[
          {
            dataField: 'file_name',
            order: 'desc',
          },
        ]}
        keyField="id"
        data={reports}
        columns={ReportsTableDef.columns}
      />
    </div>
  );
};

export { ReportsTable };
