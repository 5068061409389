import React from 'react';

import { formatDateStamp } from 'helpers/TimeUtils';
import { getDefaultTableColumnDef, wrapWithTooltip } from 'helpers/Utils';

import * as Utils from '../../helpers/Utils';

const STATUS = {
  in_progress: 'In Progress',
  failed: 'Failed',
  done: 'Done',
  pending: 'Pending',
};

const IMPORT_TYPES = {
  general: { value: 'general', label: 'General Attributes' },
  detailed: { value: 'detailed', label: 'Detailed Attributes' },
};

const ImportFunctionalityPanelTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('id', 'ID'),
      sort: false,
      formatter: (cell, row) => row.id,
    },
    {
      ...getDefaultTableColumnDef('file', 'File'),
      sort: false,
      formatter: (_cell, row) => {
        const [fName, fExt] = Utils.splitExt(row.file_name);

        return (
          <>
            {wrapWithTooltip({
              target: (
                <a
                  className="file-block-name"
                  href={row.file_link}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="fname">{fName}</span>
                  <span className="fext">.{fExt}</span>
                </a>
              ),
              content: row.file_name,
            })}
          </>
        );
      },
    },
    {
      ...getDefaultTableColumnDef('manufacturer', 'Manufacturer'),
      sort: false,
      formatter: (cell, row) => row.manufacturer?.name || 'N/A',
    },
    {
      ...getDefaultTableColumnDef('type', 'Import Type'),
      sort: false,
      formatter: (cell, row) => {
        let output = 'N/A';
        if (Object.keys(IMPORT_TYPES).includes(row.type?.toLowerCase())) {
          output = IMPORT_TYPES[row.type?.toLowerCase()].label;
        }
        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('started', 'Started At'),
      sort: false,
      formatter: (_cell, row) => (row.created_at ? formatDateStamp(row.created_at) : 'N/A'),
    },
    {
      ...getDefaultTableColumnDef('status', 'Status'),
      sort: false,
      formatter: (cell, row) => {
        let output = 'N/A';

        if (Object.keys(STATUS).includes(row.status?.toLowerCase())) {
          output = STATUS[row.status?.toLowerCase()];
        }
        if (row.progress_data?.progress !== undefined) {
          const formattedProgress = (row.progress_data?.progress * 100).toFixed(0);

          output = `${output} - ${formattedProgress}%`;
        }
        return output;
      },
    },
    {
      ...getDefaultTableColumnDef('created_by', 'Created By'),
      sort: false,
      formatter: (_cell, row) => row.user?.email || 'N/A',
    },
  ],
};

export { ImportFunctionalityPanelTableDef, IMPORT_TYPES };
