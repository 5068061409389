import React, { useState } from 'react';

import { useHistory, useLocation } from 'react-router';

import { ManufacturerSelect } from 'components/common/ManufacturerSelect/ManufacturerSelect';
import { SourceData } from 'components/data-sources/SourceData/SourceData';
import { TransformedData } from 'components/data-sources/TransformedData/TransformedData';
import { RootHooks } from 'helpers/RootHooks';
import { getMfrStateFromSearchStr, updateUrlState } from 'helpers/UrlUtils';
import { isRoleAdmin } from 'helpers/UserUtils';

const ViewDataSources = () => {
  const location = useLocation();
  const history = useHistory();
  const { activeUser } = RootHooks.useActiveUser();
  const isAdmin = isRoleAdmin(activeUser);
  const currentUserMfrId = activeUser?.profile?.manufacturer?.id;

  // set url params for manufacturer hierarchy
  const mfrId = isAdmin
    ? getMfrStateFromSearchStr(location.search)?.manufacturer_id
    : currentUserMfrId || null;
  const brandId =
    isAdmin || currentUserMfrId
      ? getMfrStateFromSearchStr(location.search)?.child_company_id
      : null;
  const companyId = brandId || mfrId;

  const [limit, setLimit] = useState(25);

  return (
    <div className="content content-fluid view-assessments">
      {(isAdmin || currentUserMfrId) && (
        <div className="databots-section">
          <div className="bot-category-card">
            <ManufacturerSelect
              selectedManufacturer={mfrId}
              onSelectManufacturer={(v) => updateUrlState(history, location, v, null)}
              selectedBrand={brandId}
              onSelectBrand={(v) => updateUrlState(history, location, mfrId, v)}
              isDisabled={false}
            />
          </div>
        </div>
      )}
      {companyId && (
        <>
          <section className="assessments-overview">
            <div className="assessments-overview-header">
              <h1>Data Sources</h1>
            </div>
            <div>
              <h3>Transformed Files</h3>
              {companyId && (
                <TransformedData pageSize={limit} hasPagination manufacturer={companyId} />
              )}
            </div>
          </section>
          <section className="assessments-overview">
            <div>
              <h3>Source Files</h3>
              {companyId && <SourceData pageSize={limit} hasPagination manufacturer={companyId} />}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export { ViewDataSources };
