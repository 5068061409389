import React, { useEffect, useState } from 'react';

import './ImgBotEngineDashboard.scss';

import * as ApiCalls from 'api/ApiCalls';
import { useIsMounted } from 'helpers/useIsMounted';

import { LoadingSpinner } from '../common/LoadingSpinner/LoadingSpinner';
import { IdsConfigActions } from './_private/IdsConfigActions';
import { ImgBotEngineConfigTable } from './_private/ImgBotEngineConfigTable';
import { CustomBotConfigUtils } from './_private/CustomBotConfigUtils';

const ImgBotEngineConfigDashboard = ({ pageSize = 25 }) => {
  const isMounted = useIsMounted();

  const [isLoading, setIsLoading] = useState(false);
  const [configData, setConfigData] = useState(null);

  // set url params for manufacturer hierarchy
  const [tableState, setTableState] = useState({
    page: 1,
    total: 0,
    pageSize: Number.isInteger(pageSize) && pageSize > 0 ? pageSize : 20,
  });
  const resetTableState = () => {
    setTableState({
      page: 1,
      pageSize: Number.isInteger(pageSize) && pageSize > 0 ? pageSize : 20,
      total: 0,
    });
  };

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    setIsLoading(true);
    CustomBotConfigUtils.loadIdsConfigs({
      limit: tableState.pageSize,
      offset: (tableState.page - 1) * tableState.pageSize,
      onSuccess: (res) => {
        if (res?.data?.results?.length) {
          setConfigData(res.data.results);
          tableState.total = res.data.count;
        }
      },
      onEnd: () => setIsLoading(false),
    });
  }, [isMounted, tableState]);

  const onTableStateChanged = (page, pageSize) => {
    setTableState({ page, pageSize, total: tableState.total });
  };

  const onSaveData = ({ data, onSuccess }) => {
    setIsLoading(true);
    let method = ApiCalls.HTTP_METHODS.POST;
    let url = '/bots/score-config';
    if (data.id !== undefined) {
      method = ApiCalls.HTTP_METHODS.PUT;
      url = `${url}/${data.id}`;
    }
    ApiCalls.doCall({
      method,
      urlPath: url,
      data,
      onSuccess: (res) => {
        if (res.data) {
          if (typeof onSuccess === 'function') onSuccess();
          resetTableState();
        }
      },
    });
  };

  const onDeleteConfig = ({ configId, onSuccess }) => {
    setIsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.DELETE,
      urlPath: `/bots/score-config/${configId}`,
      onSuccess: (res) => {
        if (typeof onSuccess === 'function') onSuccess();
        resetTableState();
      },
      onEnd: () => setIsLoading(false),
    });
  };

  const onSetDefault = ({ configId, onSuccess }) => {
    setIsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PATCH,
      urlPath: `/bots/score-config/${configId}/set-default`,
      onSuccess: (res) => {
        if (typeof onSuccess === 'function') onSuccess();
        resetTableState();
      },
      onEnd: () => setIsLoading(false),
    });
  };

  return (
    <>
      <div className="title">
        <h3>
          Image Databot Suite - Configurations
          {isLoading && <LoadingSpinner fast style={{ marginLeft: '0.5em', fontSize: '0.75em' }} />}
        </h3>
        <IdsConfigActions
          configObj={undefined}
          onSaveData={onSaveData}
          onDelete={onDeleteConfig}
          onSetAsDefault={onSetDefault}
          isLoading={isLoading}
        />
      </div>
      <ImgBotEngineConfigTable
        configData={configData}
        tableState={tableState}
        onSaveData={onSaveData}
        isLoading={isLoading}
        onDelete={onDeleteConfig}
        onSetAsDefault={onSetDefault}
        onTableStateChanged={onTableStateChanged}
      />
    </>
  );
};

export { ImgBotEngineConfigDashboard };
