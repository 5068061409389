import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DatabotStatusTemplate } from './DatabotStatusTemplate';

const DatabotRequiresApproval = ({ runBot }) => {
  return (
    <DatabotStatusTemplate
      classNameSuffix="requires-approval"
      middle={
        <div className="description">
          This databot requires your approval. Press the button to review the changes.
        </div>
      }
      bottom={
        <div className="databot-actions__recently-finished">
          <button
            onClick={runBot}
            className="databot-action databot-action--alt databot-action--failed"
            type="button"
          >
            Pending Approval
            <FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} />
          </button>
        </div>
      }
    />
  );
};

export { DatabotRequiresApproval };
