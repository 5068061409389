import React from 'react';

const DataIngestionDataQualityContext = React.createContext({
  attachmentData: null,
  attachmentSheetsData: null,
  dataJobData: null,
  loadingStatus: null,
  activeSheet: null,
  setActiveSheet: null,
  reloadAttachmentSheetsData: null,
  downloadFile: null,
  startIngestion: null,
});
export { DataIngestionDataQualityContext };
