import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { TipsCarousel } from 'components/common/TipsCarousel/TipsCarousel';
import { BotFileChip } from 'components/databots/BotFileChip';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';

import { DataJobNameDesc } from './DataJobDetailsInput/DataJobNameDesc';
import { DataJobParties } from './DataJobDetailsInput/DataJobParties';
import './DataJobDetailsSection.scss';
import { DataJobUploadFile } from './DataJobUploadFile/DataJobUploadFile';
import { DataJobUploadFileList } from './DataJobUploadFileList/DataJobUploadFileList';

const DataJobDetailsSection = ({
  isUploading,
  setIsUploading,
  dispatchChange,
  state,
  invalidCheck,
  Footer,
}) => {
  const { activeUser } = RootHooks.useActiveUser();
  const [uploadId, setUploadId] = useState(null);
  const [tipsCarouselVisible, setTipsCarouselVisible] = useState(true);
  const [bots, setBots] = useState([]);
  const [showBotDetails, setShowBotDetails] = useState(false);
  const [showAllBots, setShowAllBots] = useState(false);
  const isMounted = useIsMounted();
  const maxDefaultBots = 16;

  const tipsData = [
    'Remove any rows that do not contain product data.',
    'Remove any macros or formulas from spreadsheets.',
    'Please use standard 2 character abbreviations for Units of Measure and Country of Origin.',
    'If providing links to images, please make sure to provide the entire link.',
    'Be sure you provide data for each unique SKU.',
    'Make sure UPC codes and Item numbers are formatted to keep any necessary leading zeroes.',
    'For currency values, be sure the value is listed with two decimal places.',
  ];

  const getUniqueBots = () => {
    return [...new Map(bots.map((bot) => [bot.name.toLowerCase(), bot])).values()];
  };

  const renderBots = () => {
    let bots = getUniqueBots();
    if (!process.env.REACT_APP_FEATURE_FLAG_ENABLE_MANUAL_BOTS === 'true' || !showBotDetails)
      return [];

    if (!showAllBots) bots = bots.slice(0, maxDefaultBots);
    return bots.map((bot) => (
      <BotFileChip key={bot.name} bot={bot} withTooltip tooltipText={bot.name} />
    ));
  };

  const renderBotInfo = () => {
    let info = '';

    switch (state.dataJobDirection) {
      case 1:
        info = 'The following bots will run for the file(s) that the recipient will provide:';
        break;
      case 2:
        info = 'The following bots will run for the file(s) that you required transformation for:';
        break;
      default:
        info = `To improve the quality of your data, we will be running the following on the files
      provided:`;
    }

    return info;
  };

  const renderBotActions = () => {
    const label = showAllBots ? 'Show Less Databots' : 'Show More Databots';
    const icon = showAllBots ? 'caret-up' : 'caret-down';

    return (
      getUniqueBots().length > maxDefaultBots && (
        <button
          onClick={() => setShowAllBots(!showAllBots)}
          type="button"
          className="data-job-file-bots-expand"
        >
          {label}
          <FontAwesomeIcon icon={['fas', icon]} />
        </button>
      )
    );
  };

  useEffect(() => {
    const { files } = state;
    const transformationRequired = files.some((file) =>
      [1, 2].includes(file.transformation_required)
    );
    setShowBotDetails(transformationRequired);
  }, [state]);

  useEffect(() => {
    const reqCfg = {
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/available-bots`,
      onSuccess: (res) => {
        if (isMounted.current) setBots(res.data);
      },
    };
    ApiCalls.doCall(reqCfg);
  }, [isMounted]);

  return (
    <div className="data-job-details-container">
      <Row>
        <Col>
          <h2>Details</h2>
          <h3>* Required Fields</h3>
          <DataJobParties
            currentUser={activeUser}
            setDetails={dispatchChange}
            invalidCheck={invalidCheck}
            details={state}
          />
          <DataJobNameDesc
            currentUser={activeUser}
            setDetails={dispatchChange}
            invalidCheck={invalidCheck}
            details={state}
          />
        </Col>
        <Col className="data-job-create-right-section">
          <div className="data-job-uploadfile-container">
            <DataJobUploadFile
              currentUser={activeUser}
              setIsUploading={setIsUploading}
              isUploading={isUploading}
              setFileOrLink={dispatchChange}
              setUploadId={setUploadId}
              uploadId={uploadId}
              bots={bots}
            />
          </div>
          {renderBots() != null && renderBots().length > 0 && (
            <div className="data-job-file-bots-container">
              <p className="data-job-file-bots-info">{renderBotInfo()}</p>
              <div className="data-job-file-bots-list">{renderBots()}</div>
              {renderBotActions()}
            </div>
          )}
          <div className="data-job-file-list-container">
            <DataJobUploadFileList
              currentUser={activeUser}
              fileList={state.files}
              linkList={state.links}
              setFileOrLink={dispatchChange}
              setUploadId={setUploadId}
              uploadId={uploadId}
            />
          </div>
          {tipsCarouselVisible ? (
            <TipsCarousel data={tipsData} onClickClose={() => setTipsCarouselVisible(false)} />
          ) : null}
        </Col>
      </Row>
      <div className="data-job-create-details-footer">
        <Footer />
      </div>
    </div>
  );
};

DataJobDetailsSection.defaultProps = {
  isUploading: null,
  setIsUploading: () => {},
};

DataJobDetailsSection.propTypes = {
  isUploading: PropTypes.bool,
  setIsUploading: PropTypes.func,
  dispatchChange: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  invalidCheck: PropTypes.object.isRequired,
  Footer: PropTypes.func.isRequired,
};

export { DataJobDetailsSection };
