import React from 'react';

import { Form } from 'react-bootstrap';

import { getIconWithPopover } from '../../../helpers/Utils';
import { ColorPickerSelect } from './ColorPickerSelect';
import { IdsModels } from './IdsModels';

/**
 * Renders custom bot config specific input field depending on current data item model.
 *
 * @param {object} model CUSTOM_BOT_CFG_MODEL
 * @param {object} value Input Value
 * @param {function} onChange Change handler
 * @param {boolean} disabled Disabled state
 * @param {string} invalidMsg Error message for field
 * @param {object} customConfig Current customConfig state
 *
 * @return JSX.Element
 */

const CustomBotConfigField = ({ model, value, onChange, disabled, invalidMsg, customConfig }) => {
  const handleOnChange = (e, model) => {
    let newValue = null;
    let updatedConfig = null;

    switch (model.type) {
      case IdsModels.CUSTOM_BOT_CFG_TYPES.checkbox.key:
        newValue = e.target.checked;
        break;
      case IdsModels.CUSTOM_BOT_CFG_TYPES.color_picker_select.key:
        newValue = e;
        break;
      default:
        newValue = e.target.value;
        break;
    }

    updatedConfig = model.update(customConfig, newValue, model.attrKey, model.valueKey);
    onChange(updatedConfig);
  };
  const getFieldByModel = () => {
    let output = null;

    if (model?.type === IdsModels.CUSTOM_BOT_CFG_TYPES.checkbox.key) {
      // Checkbox field for checkbox type

      if (model?.children?.length > 0) {
        const childrenElements = model.children.map((element) => (
          <div key={element.key}>
            <Form.Control
              type={element.type}
              name={`ids-custom-config-${element.key}`}
              value={element.get(customConfig)}
              onChange={(e) => handleOnChange(e, element)}
              isInvalid={element.getErrMsg?.(customConfig)}
              disabled={disabled}
            />
            <Form.Control.Feedback type="invalid">
              {element.getErrMsg?.(customConfig)}
            </Form.Control.Feedback>
          </div>
        ));

        output = (
          <Form.Group
            className={`ids-custom-config-field type-${model.type} ids-custom-config-field-${model.key}`}
            controlId={`ids-custom-config-group-${model.key}`}
          >
            <label
              className={`type-${model.type} ids-custom-config-field-${model.key} ids-custom-config-children`}
              htmlFor={`ids-custom-config-${model.key}`}
            >
              <input
                onChange={(e) => handleOnChange(e, model)}
                checked={customConfig[model.attrKey][model.valueKey]}
                type="checkbox"
                name={`ids-custom-config-${model.key}`}
                id={`ids-custom-config-${model.key}`}
                disabled={disabled}
              />
              <span className="label">
                {model.label}
                {getIconWithPopover({
                  iconProp: ['far', 'question-circle'],
                  content: model.tooltip,
                  title: model.title || model.label,
                })}
              </span>
            </label>
            {childrenElements}
          </Form.Group>
        );
      } else {
        output = (
          <label
            className={`ids-custom-config-field type-${model.type} ids-custom-config-field-${model.key}`}
            htmlFor={`ids-custom-config-${model.key}`}
          >
            <input
              onChange={(e) => handleOnChange(e, model)}
              checked={customConfig[model.attrKey][model.valueKey]}
              readOnly
              type="checkbox"
              name={`ids-custom-config-${model.key}`}
              id={`ids-custom-config-${model.key}`}
              disabled={disabled}
            />
            <span className="label">
              {model.label}
              {getIconWithPopover({
                iconProp: ['far', 'question-circle'],
                content: model.tooltip,
                title: model.title || model.label,
              })}
            </span>
          </label>
        );
      }
    } else if (
      [
        IdsModels.CUSTOM_BOT_CFG_TYPES.number.key,
        IdsModels.CUSTOM_BOT_CFG_TYPES.percentage.key,
      ].includes(model?.type)
    ) {
      output = (
        // Input field for number and percentage types
        <Form.Group
          className={`ids-custom-config-field type-${model.type} ids-custom-config-field-${model.key}`}
          controlId={`ids-custom-config-${model.key}`}
        >
          <Form.Label>
            <span>
              <span className="label">{model.label}</span>
              {!!model.unitLabel && (
                <>
                  <span className="unit">({model.unitLabel})</span>
                </>
              )}
              {getIconWithPopover({
                iconProp: ['far', 'question-circle'],
                content: model.tooltip,
                title: model.title || model.label,
              })}
            </span>
          </Form.Label>

          <Form.Control
            type="text"
            value={value ?? ''}
            onChange={(e) => handleOnChange(e, model)}
            name={`ids-custom-config-${model.key}`}
            isInvalid={!!invalidMsg}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">{invalidMsg}</Form.Control.Feedback>
        </Form.Group>
      );
    } else if (model?.type === IdsModels.CUSTOM_BOT_CFG_TYPES.option.key) {
      output = (
        <Form.Group
          className={`ids-custom-config-field type-${model.type} ids-custom-config-field-${model.key}`}
          controlId={`ids-custom-config-${model.key}`}
        >
          <Form.Label>
            <span>
              <span className="label">{model.label}</span>
              {!!model.unitLabel && (
                <>
                  <span className="unit">({model.unitLabel})</span>
                </>
              )}
            </span>
            {getIconWithPopover({
              iconProp: ['far', 'question-circle'],
              content: model.tooltip,
              title: model.title || model.label,
            })}
          </Form.Label>
          <Form.Control
            as="select"
            value={value ?? ''}
            onChange={(e) => handleOnChange(e, model)}
            isInvalid={false}
            disabled={disabled}
          >
            {model.options &&
              model.options.map((option, i) => (
                <option value={option.value} key={i}>
                  {option.label}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      );
    } else if (model?.type === IdsModels.CUSTOM_BOT_CFG_TYPES.color_picker_select.key) {
      output = (
        <>
          <Form.Group
            className={`ids-custom-config-field type-${model.type} ids-custom-config-field-${model.key}`}
            controlId={`ids-custom-config-${model.key}`}
          >
            <Form.Label>
              <span>
                <span className="label">{model.label}</span>
                {!!model.unitLabel && (
                  <>
                    <span className="unit">({model.unitLabel})</span>
                  </>
                )}
              </span>
              {getIconWithPopover({
                iconProp: ['far', 'question-circle'],
                content: model.tooltip,
                title: model.title || model.label,
              })}
            </Form.Label>
            <ColorPickerSelect
              value={value ?? ''}
              disabled={disabled}
              onChange={(e) => handleOnChange(e, model)}
              options={model.options}
            />
          </Form.Group>
        </>
      );
    } else if (model?.type === IdsModels.CUSTOM_BOT_CFG_TYPES.textfield.key) {
      output = (
        <Form.Group
          className={`ids-custom-config-field type-${model.type} ids-custom-config-field-${model.key}`}
          controlId={`ids-custom-config-${model.key}`}
        >
          <Form.Label>
            <span>
              <span className="label">{model.label}</span>
              {!!model.unitLabel && (
                <>
                  <span className="unit">({model.unitLabel})</span>
                </>
              )}
              {getIconWithPopover({
                iconProp: ['far', 'question-circle'],
                content: model.tooltip,
                title: model.title || model.label,
              })}
            </span>
          </Form.Label>

          <Form.Control
            type={`${model?.subtype || 'text'}`}
            value={value ?? ''}
            onChange={(e) => handleOnChange(e, model)}
            name={`ids-custom-config-${model.key}`}
            isInvalid={!!invalidMsg}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">{invalidMsg}</Form.Control.Feedback>
        </Form.Group>
      );
    }

    return output;
  };

  return <>{getFieldByModel()}</>;
};

export { CustomBotConfigField };
